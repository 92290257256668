import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SortCarets } from '../assessmentList';
import {sortDate, sortCaseInsensitive} from '../../lib/utils';

const FilesHistoryLogTable = (props) => {
  const { data, page = 1, sizePerPage = 10, sort = [{ dataField: 'date', order: 'desc' }] } = props;

  const style = {
    columns: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };

  const columnFormatter = (cell) => {
    return <span title={cell}>{cell}</span>;
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Date Viewed',
      sort: true,
      sortFunc: sortDate,
      sortCaret: SortCarets,
      classes: 'col-4',
      headerAttrs: {
        role: 'button',
      },
      style: style.columns,
      formatter: columnFormatter,
    },
    {
      dataField: 'user',
      text: 'User',
      sort: true,
      sortFunc: sortCaseInsensitive,
      sortCaret: SortCarets,
      classes: 'col-4',
      headerAttrs: {
        role: 'button',
      },
      style: style.columns,
      formatter: columnFormatter,
    },
    {
      dataField: 'emailAddress',
      text: 'Email',
      sort: true,
      sortFunc: sortCaseInsensitive,
      sortCaret: SortCarets,
      classes: 'col-4',
      headerAttrs: {
        role: 'button',
      },
      style: style.columns,
      formatter: columnFormatter,
    },
  ];

  return (
    <div className="col-12">
      {data && data.length ? (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          defaultSorted={sort}
          pagination={paginationFactory({ page, sizePerPage, hideSizePerPage: true })}
        />
      ) : (
        <div>No file history</div>
      )}
    </div>
  );
};

export default FilesHistoryLogTable;
