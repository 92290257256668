import React from 'react';
import { Link } from 'react-router-dom';

import { ColorizedCard, CsvDownloadButton } from '../../components';

import config from '../../config';

import Chart from 'chart.js';

import { Tooltip, tooltip } from '../../tooltips.js';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { textColumnFilter } from '../../components/reactTable';

const scores = {
  dns: {
    name: 'avg_dns_score',
    backgroundColor: '#4cae4c',
    label: <h3>DNS:</h3>,
    icon: 'fas fa-server',
    tooltip: tooltip('DNS'),
  },
  ssl: {
    name: 'avg_ssl_score',
    backgroundColor: '#fdc431',
    label: <h3>SSL:</h3>,
    icon: 'fab fa-expeditedssl',
    tooltip: tooltip('SSL'),
  },
  appsec: {
    name: 'avg_web_app_score',
    backgroundColor: '#357abd',
    label: <h3>App Security:</h3>,
    icon: 'fas fa-globe',
    tooltip: tooltip('App Sec'),
  },
  domainsec: {
    name: 'avg_dns_security',
    backgroundColor: '#303030',
    label: <h3>Domain Security:</h3>,
    icon: 'fas fa-lock',
    tooltip: tooltip('Domain Sec'),
  },
};

const scoreNames = Object.keys(scores);

const tierNames = ['global', 'portfolio', 'provider'];

const parseProps = (props) => {
  const { match = { params: {} }, location = { query: {} } } = props;

  const { tier: requestedTier, score: requestedScore } = match.params;

  const tier = props.tier ? props.tier : tierNames.indexOf(requestedTier) > -1 ? requestedTier : tierNames[0];
  const score = props.score ? props.score : scoreNames.indexOf(requestedScore) > -1 ? requestedScore : scoreNames[0];
  const id = props.id ? props.id : location.query.id;

  let actualTier = tier;
  if (id === 'all') {
    actualTier = 'global';
  }

  return {
    tier: actualTier,
    score,
    id,
    targetGroupName: location.query.targetGroupName || '',
  };
};

class ScoreBreakdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      isBusy: true,
      lineChartReady: false,
      score: {},
      table: {
        columns: [],
        data: [],
      },
      certificatesTable: {
        data: [],
      },
      targetGroupName: '',
    };
  }

  componentDidMount() {
    const { tier, score, id, targetGroupName } = parseProps(this.props);
    this.doScore(tier, score, id);
    this.lineChart(tier, score, id);
    this.doTable(1, tier, score, id);
    this.setState({ targetGroupName });

    if (score === 'ssl' && tier === 'provider' && id) {
      this.doCertificatesTable(1, tier, id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { tier: pTier, score: pScore, id: pID } = parseProps(prevProps);
    const { tier: cTier, score: cScore, id: cID } = parseProps(this.props);

    if (cTier === pTier && cScore === pScore && cID === pID) {
      return;
    }

    this.doScore(cTier, cScore, cID);
    this.lineChart(cTier, cScore, cID);
    this.doTable(1, cTier, cScore, cID);

    if (cScore === 'ssl' && cTier === 'provider' && cID) {
      this.doCertificatesTable(1, cTier, cID);
    }
  }

  doScore(tier, score, id) {
    let params = '';
    switch (tier) {
      case 'provider':
      case 'portfolio':
        params = '?id=' + id;
        break;

      default:
        tier = 'global';
        break;
    }

    this.setState({ isBusy: true });

    let url = `${config.api.urlFor('scores', { tier: `${tier}` })}${params}${
      this.props.location.query.targetGroupId ? `&targetGroupId=${this.props.location.query.targetGroupId}` : ``
    }`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const scoreData = scores[score || 'risk'];

        scoreData.value = data[scoreData.name] || null;
        this.setState({ score: scoreData, name: data.name || 'Provider', isBusy: false });
      })
      .catch((err) => {
        throw err;
      });
  }

  lineChart(tier, score, id) {
    const ctx = document.getElementById('lineChart');
    const chart = {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            fill: 'disabled',
            label: 'Score',
            backgroundColor: 'rgba(2,117,216,0.2)',
            borderColor: 'rgba(2,117,216,1)',
            pointRadius: 5,
            pointBackgroundColor: 'rgba(2,117,216,1)',
            pointBorderColor: 'rgba(255,255,255,0.8)',
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(2,117,216,1)',
            pointHitRadius: 50,
            pointBorderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
              gridLines: {
                color: 'rgba(255, 255, 255, 1)',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
              },
              gridLines: {
                color: 'rgba(255, 255, 255, .5)',
              },
            },
          ],
        },
        legend: {
          display: true,
        },
      },
    };

    this.setState({ lineChartReady: false });

    let params = '';
    switch (tier) {
      case 'provider':
      case 'portfolio':
        params += `?id=${id}`;
        break;

      default:
        tier = 'global';
        break;
    }

    let url = config.api.urlFor('scoresOverTime', { tier: tier }) + params;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        chart.data.datasets[0].data = data.map((row) => ({
          t: new Date(row.date_finished),
          y: row[scores[score].name],
        }));

        new Chart(ctx, chart) && this.setState({ lineChartReady: true });
      })
      .catch((err) => {
        throw err;
      });
  }

  doTable(page, tier, score, id, filter, sort) {
    page = parseInt(page || 1, 10);
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];

    const table = {
      tier,
      score,
      id: !id || id === 'all' ? null : id,
      columns: [],
      data: [],
    };

    this.setState({ tableIsBusy: true });

    let url = config.api.urlFor('scanData', { tier: tier, score: score });
    url = id ? `${url}?id=${id}&page=${page}` : `${url}?page=${page}`;

    if (filter && filter.length) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        table.csvUrl = config.api.urlFor('scanDataCsv', { tier: table.tier, score: table.score });
        table.csvUrl = id ? `${table.csvUrl}?id=${id}` : table.csvUrl;

        table.pages = Math.ceil(data.totalRecords / 10);

        switch (score.toLowerCase()) {
          case 'ssl':
            table.columns = [
              { Header: <Tooltip message="Target">Target</Tooltip>, accessor: 'label', Filter: textColumnFilter },
              { Header: <Tooltip message="Collected">Collected</Tooltip>, accessor: 'collected', filterable: false },
              {
                Header: <Tooltip message="SSL v2">SSL V2</Tooltip>,
                accessor: 'total_sslv2',
                Cell: ({ row }) => <div className="text-center">{row.total_sslv2}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="SSL v3">SSL v3</Tooltip>,
                accessor: 'total_sslv3',
                Cell: ({ row }) => <div className="text-center">{row.total_sslv3}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="TLS v1.0">TLS v1.0</Tooltip>,
                accessor: 'total_tlsv10',
                Cell: ({ row }) => <div className="text-center">{row.total_tlsv10}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="TLS v1.1">TLS v1.1</Tooltip>,
                accessor: 'total_tlsv11',
                Cell: ({ row }) => <div className="text-center">{row.total_tlsv11}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="TLS v1.2">TLS v1.2</Tooltip>,
                accessor: 'total_tlsv12',
                Cell: ({ row }) => <div className="text-center">{row.total_tlsv12}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="ciphers">ciphers</Tooltip>,
                accessor: 'total_ciphers',
                Cell: ({ row }) => <div className="text-center">{row.total_ciphers}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="ciphers a">"a" grade</Tooltip>,
                accessor: 'total_ciphers_a',
                Cell: ({ row }) => <div className="text-center">{row.total_ciphers_a}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="ciphers b">"b"</Tooltip>,
                accessor: 'total_ciphers_b',
                Cell: ({ row }) => <div className="text-center">{row.total_ciphers_b}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="ciphers c">"c"</Tooltip>,
                accessor: 'total_ciphers_c',
                Cell: ({ row }) => <div className="text-center">{row.total_ciphers_c}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="ciphers d">"d"</Tooltip>,
                accessor: 'total_ciphers_d',
                Cell: ({ row }) => <div className="text-center">{row.total_ciphers_d}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="ciphers e">"e"</Tooltip>,
                accessor: 'total_ciphers_e',
                Cell: ({ row }) => <div className="text-center">{row.total_ciphers_e}</div>,
                filterable: false,
                sortable: false,
              },
              {
                Header: <Tooltip message="ciphers f">"f"</Tooltip>,
                accessor: 'total_ciphers_f',
                Cell: ({ row }) => <div className="text-center">{row.total_ciphers_f}</div>,
                filterable: false,
                sortable: false,
              },
            ];

            table.data = data.rows.map((row) => {
              return {
                label: row.hostname,
                collected: new Date(row.date_collected).toLocaleDateString(),
                total_sslv2:
                  row.total_sslv2 > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_sslv3:
                  row.total_sslv3 > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_tlsv10:
                  row.total_tlsv10 > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_tlsv11:
                  row.total_tlsv11 > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_tlsv12:
                  row.total_tlsv12 > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                total_ciphers:
                  row.total_ciphers > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                total_ciphers_a:
                  row.total_ciphers_a > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                total_ciphers_b:
                  row.total_ciphers_b > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_ciphers_c:
                  row.total_ciphers_c > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_ciphers_d:
                  row.total_ciphers_d > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_ciphers_e:
                  row.total_ciphers_e > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
                total_ciphers_f:
                  row.total_ciphers_f > 0 ? (
                    <span className="text-danger">Yes</span>
                  ) : (
                    <span className="text-success">No</span>
                  ),
              };
            });
            break;

          case 'appsec':
            table.columns = [
              { Header: <Tooltip message="Target">Target</Tooltip>, accessor: 'label', Filter: textColumnFilter },
              { Header: <Tooltip message="Collected">Collected</Tooltip>, accessor: 'collected', filterable: false },
              {
                Header: <Tooltip message="Cookies">Cookies</Tooltip>,
                accessor: 'cookies',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.cookies}</div>,
              },
              {
                Header: <Tooltip message="CSP">CSP</Tooltip>,
                accessor: 'csp',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.csp}</div>,
              },
              {
                Header: <Tooltip message="XSS">XSS</Tooltip>,
                accessor: 'xss',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.xss}</div>,
              },
              {
                Header: <Tooltip message="XFO">XFO</Tooltip>,
                accessor: 'xfo',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.xfo}</div>,
              },
              {
                Header: <Tooltip message="PKP">PKP</Tooltip>,
                accessor: 'pkp',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.pkp}</div>,
              },
              {
                Header: <Tooltip message="CTO">CTO</Tooltip>,
                accessor: 'cto',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.cto}</div>,
              },
              {
                Header: <Tooltip message="STS">STS</Tooltip>,
                accessor: 'sts',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.sts}</div>,
              },
              {
                Header: <Tooltip message="E-refs">E-refs</Tooltip>,
                accessor: 'erefs',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.erefs}</div>,
              },
              {
                Header: <Tooltip message="Vulns">Vulns</Tooltip>,
                accessor: 'vulns',
                filterable: false,
                sortable: false,
                Cell: (x) => (
                  <div className="text-center">
                    <Link
                      to={`/reports/vulnerabilities/provider/1?id=${id}&hostname=${encodeURIComponent(x.row.label)}`}
                    >
                      {x.row.vulns}
                    </Link>
                  </div>
                ),
              },
            ];

            table.data = data.rows.map((row) => {
              return {
                label: row.hostname,
                collected: new Date(row.date_collected).toLocaleDateString(),
                cookies:
                  row.total_cookies > 0 ? (
                    <span className="fa-stack fa-1x">
                      <i className="fas fa-circle fa-stack-2x text-info" style={{ opacity: 0.75 }}></i>
                      <strong className="fa-stack-1x fa-inverse">{row.total_cookies || '-'}</strong>
                    </span>
                  ) : (
                    '-'
                  ),
                csp:
                  row.total_csp > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                xss:
                  row.total_xss_protection > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                xfo:
                  row.total_xfo > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                pkp:
                  row.total_pkp > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                cto:
                  row.total_cto > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                sts:
                  row.total_sts > 0 ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-danger">No</span>
                  ),
                erefs:
                  row.total_external_refs > 0 ? (
                    <span className="fa-stack fa-1x">
                      <i className="fas fa-circle fa-stack-2x text-info" style={{ opacity: 0.75 }}></i>
                      <strong className="fa-stack-1x fa-inverse">{row.total_external_refs}</strong>
                    </span>
                  ) : (
                    '-'
                  ),
                vulns:
                  row.total_vulns > 0 ? (
                    <span className="fa-stack fa-1x">
                      <i className="fas fa-circle fa-stack-2x text-danger" style={{ opacity: 0.75 }}></i>
                      <strong className="fa-stack-1x fa-inverse">{row.total_vulns}</strong>
                    </span>
                  ) : (
                    '-'
                  ),
              };
            });
            break;

          case 'domainsec':
            table.columns = [
              { Header: <Tooltip message="Target">Target</Tooltip>, accessor: 'label', Filter: textColumnFilter },
              { Header: <Tooltip message="Collected">Collected</Tooltip>, accessor: 'collected', filterable: false },
              {
                Header: <Tooltip message="Doppelgangers">Doppelgangers</Tooltip>,
                accessor: 'doppelgangers',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.doppelgangers}</div>,
              },
              {
                Header: <Tooltip message="SPF">SPF</Tooltip>,
                accessor: 'spf_enabled',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.spf_enabled}</div>,
              },
              {
                Header: <Tooltip message="DMARC">DMARC</Tooltip>,
                accessor: 'dmarc_enabled',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.dmarc_enabled}</div>,
              },
            ];

            table.data = data.rows.map((row) => {
              return {
                label: row.hostname,
                collected: new Date(row.date_collected).toLocaleDateString(),
                doppelgangers: row.doppelgangers ? (
                  <span className="text-danger">Yes</span>
                ) : row.doppelgangers === 0 ? (
                  <span className="text-success">No</span>
                ) : (
                  <strong>-</strong>
                ),
                spf_enabled: row.spf_enabled ? (
                  <span className="text-success">Yes</span>
                ) : row.spf_enabled === false ? (
                  <span className="text-danger">No</span>
                ) : (
                  <strong>-</strong>
                ),
                dmarc_enabled: row.dmarc_enabled ? (
                  <span className="text-success">Yes</span>
                ) : row.dmarc_enabled === false ? (
                  <span className="text-danger">No</span>
                ) : (
                  <strong>-</strong>
                ),
              };
            });
            break;

          default:
            table.columns = [
              { Header: <Tooltip message="Target">Target</Tooltip>, accessor: 'label', Filter: textColumnFilter },
              { Header: <Tooltip message="Collected">Collected</Tooltip>, accessor: 'collected', filterable: false },
              {
                Header: <Tooltip message="DNSSEC">DNSSEC</Tooltip>,
                accessor: 'dnssec_enabled',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.dnssec_enabled}</div>,
              },
              {
                Header: <Tooltip message="NSEC">NSEC</Tooltip>,
                accessor: 'nsec_enabled',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.nsec_enabled}</div>,
              },
              {
                Header: <Tooltip message="Encryption">Encryption</Tooltip>,
                accessor: 'encryption_enabled',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.encryption_enabled}</div>,
              },
              {
                Header: <Tooltip message="Wildcard">Wildcard</Tooltip>,
                accessor: 'wildcard_enabled',
                filterable: false,
                sortable: false,
                Cell: (x) => <div className="text-center">{x.row.wildcard_enabled}</div>,
              },
            ];

            table.data = data.rows.map((row) => {
              return {
                label: row.hostname,
                collected: new Date(row.date_collected).toLocaleDateString(),
                dnssec_enabled: row.dnssec_enabled ? (
                  <span className="text-success">Yes</span>
                ) : row.dnssec_enabled === false ? (
                  <span className="text-danger">No</span>
                ) : (
                  <strong>-</strong>
                ),
                nsec_enabled: row.nsec_enabled ? (
                  <span className="text-success">Yes</span>
                ) : row.nsec_enabled === false ? (
                  <span className="text-danger">No</span>
                ) : (
                  <strong>-</strong>
                ),
                encryption_enabled: row.encryption_enabled ? (
                  <span className="text-success">Yes</span>
                ) : row.encryption_enabled === false ? (
                  <span className="text-danger">No</span>
                ) : (
                  <strong>-</strong>
                ),
                wildcard_enabled: row.wildcard_enabled ? (
                  <span className="text-danger">Yes</span>
                ) : row.wildcard_enabled === false ? (
                  <span className="text-success">No</span>
                ) : (
                  <strong>-</strong>
                ),
              };
            });
            break;
        }

        this.setState({ tableIsBusy: false, table: table });
      })
      .catch((err) => {
        throw err;
      });
  }

  doCertificatesTable(page, tier, id, filter, sort) {
    this.setState({ certificatesTableIsBusy: true });

    const table = {
      id: id,
      tier: tier,
      data: [],
    };

    let url = config.api.urlFor('providersCertificates', { id: id }) + `?page=${page}`;

    if (filter && filter.length) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        table.pages = Math.ceil(data.totalRecords / 10);
        table.data = data.rows.map((x) => {
          return {
            domain: x.domain,
            expiration: new Date(x.ssl_expiration).toDateString(),
          };
        });

        this.setState({ certificatesTableIsBusy: false, certificatesTable: table });
      })
      .catch((err) => {
        throw err;
      });
  }

  capitalizeFirstLetter = (str) => {
    if (typeof str === 'string') {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  };

  render() {
    const { certificatesTable, table } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <h1>
              {this.capitalizeFirstLetter(this.state.name) +
                `${this.state.name && this.state.targetGroupName && ` - ${this.state.targetGroupName}`}`}
            </h1>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-3">
            <div className="row">
              <div className="col">
                <ColorizedCard
                  label={this.state.score.label}
                  icon={this.state.score.icon}
                  value={this.state.score.value}
                  tooltip={this.state.score.tooltip}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <small>{this.state.score.tooltip}</small>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="card">
              <div className="card-header">
                <i className="fas fa-chart-area mr-1"></i>
                Score History
              </div>
              <div className="card-body text-center align-middle">
                <canvas
                  id="lineChart"
                  height="60"
                  style={{ display: this.state.lineChartReady === true ? 'block' : 'none' }}
                ></canvas>
                {!this.state.lineChartReady && <i className="fas fa-circle-notch fa-spin fa-5x"></i>}
              </div>
              <div className="card-footer small text-muted"></div>
            </div>
          </div>
        </div>
        {/* HaCkY */}
        {table.score === 'ssl' && certificatesTable.tier === 'provider' && certificatesTable.id && (
          <div className="row mb-3">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="float-left">Certificates</div>
                  <div className="float-right">
                    <CsvDownloadButton
                      url={config.api.urlFor('providersCertificatesCsv', {
                        tier: certificatesTable.tier,
                        id: certificatesTable.id,
                      })}
                      filename={`${this.state.name.toLowerCase().replace(/ /g, '-')}-ssl-certificates`}
                    />
                  </div>
                </div>
                <div className="card-body align-middle">
                  <ReactTable
                    manual
                    defaultSorted={[{ id: 'domain', desc: 0 }]}
                    filterable
                    multiSort={false}
                    showPageSizeOptions={false}
                    pageSize={0}
                    pages={certificatesTable.pages}
                    loading={this.state.certificatesTableIsBusy}
                    columns={[
                      { Header: 'Domain', accessor: 'domain', Filter: textColumnFilter },
                      { Header: 'Expiration', accessor: 'expiration', filterable: false },
                    ]}
                    data={certificatesTable.data}
                    onFetchData={(state) =>
                      this.doCertificatesTable(
                        state.page + 1,
                        certificatesTable.tier,
                        certificatesTable.id,
                        state.filtered,
                        state.sorted,
                      )
                    }
                    className="-striped -highlight hide-arrows"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-header">
                <div>
                  <i className="fas fa-chart-area mr-1"></i>
                  Collected Data
                </div>
                <div className="float-right">
                  <CsvDownloadButton
                    url={table.csvUrl}
                    filename={`${this.state.name.toLowerCase().replace(/ /g, '-')}-data-${table.score}`}
                  />
                </div>
              </div>
              <div className="card-body align-middle">
                <ReactTable
                  manual
                  defaultSorted={[{ id: 'label', desc: false }]}
                  filterable
                  multiSort={false}
                  showPageSizeOptions={false}
                  pageSize={0}
                  pages={table.pages}
                  loading={this.state.tableIsBusy}
                  columns={table.columns}
                  data={table.data}
                  onFetchData={(state) =>
                    table.tier &&
                    table.score &&
                    this.doTable(state.page + 1, table.tier, table.score, table.id, state.filtered, state.sorted)
                  }
                  className="-striped -highlight hide-arrows"
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScoreBreakdown;
