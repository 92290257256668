import React from 'react';
import ForeignInfluenceTable from '../foreignInfluenceTable';
import SeverityCircle from '../../postern/reusable/severityCircle';
import { generateSymbol, generateColor } from '../../../constants/postern/riskIconGenerator.js';

function SummaryTable(props) {
  return (
    <div className="summary-section-container fi-summary">
      <ForeignInfluenceTable
        tableName="ForeignInfluence-Summary"
        headers={[
          'Location',
          'Cyber Presence',
          'Physical Locations',
          'Corporate Families',
          'Foreign Ownership',
          'Mergers & Acquisitions',
          'Manufacturing',
        ]}
      >
        {Object.keys(props.summary).map((country, i) => {
          const countryData = props.summary[country];
          return (
            <React.Fragment key={'sga-overview-outer-' + i}>
              <tr className="provenance-table-row no-border">
                <th className="row-padding" scope="row">
                  {' '}
                  {country}{' '}
                </th>
                {Object.keys(countryData).map((riskSection, i) => {
                  const riskSectionValue = countryData[riskSection];
                  return (
                    <React.Fragment key={'sga-overview-inner-' + i}>
                      <td>
                        <SeverityCircle
                          customClasses={'m-mar-a'}
                          color={generateColor(riskSectionValue)}
                          symbol={generateSymbol(riskSectionValue)}
                        />
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>
            </React.Fragment>
          );
        })}
      </ForeignInfluenceTable>
    </div>
  );
}

export default SummaryTable;
