import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { Tooltip } from '../tooltips.js';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { CsvDownloadButton } from '.';

import { textColumnFilter, numericColumnFilter } from './reactTable';

import config from '../config';

class ProvidersTableDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      providers: [],
    };

    this.doTable = this.doTable.bind(this);
  }

  componentDidMount() {
    const { portfolio = '%25' } = this.props.match.params;
    this.doTable(1, portfolio);
  }

  componentDidUpdate(prevProps) {
    const { portfolio = '%25' } = this.props.match.params;
    const { portfolio: pPortfolio = '%25' } = prevProps.match.params;

    if (portfolio === pPortfolio) {
      return;
    }

    this.doTable(1, portfolio);
  }

  doTable(page, portfolio, filter, sort) {
    page = parseInt(page || 1, 10);
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];

    this.setState({ tableIsBusy: true });

    let url = config.api.urlFor('providerScores', { page: page });

    if (portfolio && portfolio !== 'all') {
      url += `&portfolio=${portfolio}`;
    }

    if (filter) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const providers = (data.rows || []).map((provider) => ({
          name: <Link to={`/reports/provider/${provider.id}`}>{provider.name}</Link>,
          risk: provider.avg_risk_score,
          dns: provider.avg_dns_score,
          web_app: provider.avg_web_app_score,
          auth_security: provider.avg_auth_security,
          session_security: provider.avg_session_security,
          ssl: provider.avg_ssl_score,
          cipher: provider.avg_cipher_score,
        }));

        this.setState({
          providers,
          pages: Math.ceil(data.totalRecords / 10),
          tableIsBusy: false,
        });
      })
      .catch((err) => this.setState({ tableIsBusy: false }));
  }

  render() {
    const {
      params: { portfolio = 'all' },
    } = this.props.match;
    const { pages, providers, tableIsBusy } = this.state;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <i className="fas fa-chart-area mr-1"></i>
          Providers Monitored
          <div className="float-right">
            <CsvDownloadButton
              url={config.api.urlFor('providersByPortfolioCsv', { portfolio })}
              filename={`portfolio-provider-scores-${portfolio}`}
            />
          </div>
        </div>
        <div className="card-body align-middle">
          <ReactTable
            manual
            filterable
            multiSort={false}
            showPageSizeOptions={false}
            pageSize={0}
            pages={pages}
            loading={tableIsBusy}
            defaultSorted={[{ id: 'name', desc: false }]}
            columns={[
              { Header: 'Name', accessor: 'name', minWidth: 200, Filter: textColumnFilter },
              {
                Header: <Tooltip message="Cyber Risk Total">Risk</Tooltip>,
                accessor: 'risk',
                Cell: ({ row }) => <div className="text-center">{row.risk}</div>,
                Filter: numericColumnFilter,
              },
              {
                Header: <Tooltip message="DNS">DNS</Tooltip>,
                accessor: 'dns',
                Cell: ({ row }) => <div className="text-center">{row.dns}</div>,
                Filter: numericColumnFilter,
              },
              {
                Header: <Tooltip message="App Sec">App Sec</Tooltip>,
                accessor: 'web_app',
                Cell: ({ row }) => <div className="text-center">{row.web_app}</div>,
                Filter: numericColumnFilter,
              },
              {
                Header: <Tooltip message="Auth">Auth</Tooltip>,
                accessor: 'auth_security',
                Cell: ({ row }) => <div className="text-center">{row.auth_security}</div>,
                Filter: numericColumnFilter,
              },
              {
                Header: <Tooltip message="Session">Session</Tooltip>,
                accessor: 'session_security',
                Cell: ({ row }) => <div className="text-center">{row.session_security}</div>,
                Filter: numericColumnFilter,
              },
              {
                Header: <Tooltip message="SSL">SSL</Tooltip>,
                accessor: 'ssl',
                Cell: ({ row }) => <div className="text-center">{row.ssl}</div>,
                Filter: numericColumnFilter,
              },
              {
                Header: <Tooltip message="Ciphers">Cipher</Tooltip>,
                accessor: 'cipher',
                Cell: ({ row }) => <div className="text-center">{row.cipher}</div>,
                Filter: numericColumnFilter,
              },
            ]}
            data={providers}
            onFetchData={(state) => this.doTable(state.page + 1, portfolio, state.filtered, state.sorted)}
            className="-striped -highlight hide-arrows"
          />
        </div>
        <div className="card-footer small text-muted"></div>
      </div>
    );
  }
}

export default ProvidersTableDetail;
