import React, { Component } from 'react';
import SeverityFlag from './../../../reusable/severityFlag';
import SafeHyperlink from '../../../../safeHyperlink';

import './complianceAlerts.css';

export default class ComplianceAlerts extends Component {
  render() {
    const {
      redData = {},
      doe_cyber_presence = [],
      doe_countries = [],
      eo_cyber_presence = [],
      eo_countries = [],
    } = this.props;

    const { noRed = true, bannedRelationships = {}, regulations = [] } = redData;

    return (
      <React.Fragment>
        <h5>Related Entity Discovery</h5>
        <p>
          The Fortress Related Entity Discovery (RED) tool is used to quickly identify where relationships between a
          target company and a list of banned companies may exist. Results should be validated as there are many
          relationships that would not warrant a compliance concern.
        </p>
        {noRed ? (
          <p>RED analysis not available for this organization.</p>
        ) : (
          <table className="ddrr-flag-table">
            <tbody>
              {Object.keys(bannedRelationships).map((keyName, i) => {
                const bannedComps = bannedRelationships[keyName];
                return (
                  <tr key={'bc-parent-table-' + i}>
                    <td>
                      <SeverityFlag color={'#ffc107'} />
                    </td>
                    <td>
                      <b>{keyName}</b>
                    </td>
                    <td>
                      Possible relationship with &nbsp;
                      {bannedComps.map((bc, i) => {
                        return (
                          <React.Fragment key={'bc-table-' + i}>
                            {bc.bannedCompany}
                            {i === bannedComps.length - 1 ? (
                              <React.Fragment>
                                <SafeHyperlink url={bc.evidence}> [link]</SafeHyperlink>.
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <SafeHyperlink url={bc.evidence}> [link]</SafeHyperlink>,{' '}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
              {regulations
                .filter((row) => !Object.keys(bannedRelationships).includes(row.regulation))
                .map((row, i) => {
                  return (
                    <tr key={'reg-table-' + i}>
                      <td>
                        <SeverityFlag color={'#1ab394'} />
                      </td>
                      <td>
                        <b>{row.regulation}</b>
                      </td>
                      <td>No relationships identified.</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
        <br />
        <h5>Internet Protocol Geolocation</h5>
        <p>
          The Fortress Overseer cyber scanning tool identifies related IP addresses of an organization. Those IP
          addresses are then converted to latitude and longitude values and assigned to countries. This is useful to
          prioritize diligence efforts for certain compliance regulations.
        </p>
        <table className="ddrr-flag-table">
          <tbody>
            <tr>
              <td>
                <SeverityFlag color={doe_cyber_presence.length == 0 ? '#1ab394' : '#ffc107'} />
              </td>
              <td>
                <b> DoE Prohibition Order </b>
              </td>
              <td>
                {doe_cyber_presence.map((row, i) => {
                  return (
                    <React.Fragment key={'doe_cyber_presence' + i}>
                      {row.countryname} ({row.ip_count} IP addresses).&nbsp;
                    </React.Fragment>
                  );
                })}
                {doe_countries.length > 0 ? (
                  <React.Fragment>
                    No IP addresses were discovered in
                    {doe_countries.map((country, i) => {
                      if (i === doe_countries.length - 1) {
                        return <React.Fragment key={'doe_countries' + i}> {country}</React.Fragment>;
                      } else {
                        return <React.Fragment key={'doe_countries' + i}> {country}, </React.Fragment>;
                      }
                    })}
                    .
                  </React.Fragment>
                ) : (
                  ''
                )}
              </td>
            </tr>
            <tr>
              <td>
                <SeverityFlag color={eo_cyber_presence.length == 0 ? '#1ab394' : '#ffc107'} />
              </td>
              <td>
                <b> EO 13920 </b>
              </td>
              <td>
                {eo_cyber_presence.map((row, i) => {
                  return (
                    <React.Fragment key={'eo_cyber_presence' + i}>
                      {row.countryname} ({row.ip_count} IP addresses).&nbsp;
                    </React.Fragment>
                  );
                })}
                {eo_countries.length > 0 && (
                  <React.Fragment>
                    No IP addresses were discovered in
                    {eo_countries.map((country, i) => {
                      if (i === eo_countries.length - 1) {
                        return <React.Fragment key={'eo_countries' + i}> {country}</React.Fragment>;
                      } else {
                        return <React.Fragment key={'eo_countries' + i}> {country}, </React.Fragment>;
                      }
                    })}
                    .
                  </React.Fragment>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
      </React.Fragment>
    );
  }
}
