import React, { Component } from 'react';

import HexSeverity from './../../../reusable/hexSeverity';
import SeverityPill from './../../../reusable/severityPill';

import './printCoverPage.css';

export default class PrintCoverPage extends Component {
  render() {
    const date = new Date();
    const { vendor = {}, scores = {}, compliance = {}, template = '' } = this.props;
    const { total_risk = '' } = compliance;
    const { name = '', domain = '', description = '' } = vendor;
    const {
      overall = '',
      manufacturing = '',
      foreignOwnership = '',
      mergerAcquisition = '',
      physicalPresence = '',
      corporateFamilies = '',
      cyberPresence = '',
      overall_security = '',
      watchlist = '',
      cyberHygiene = '',
      breaches = '',
      vulnSolution = '',
    } = scores;

    return (
      <div id="provenance-print-cover-page">
        <h2 className="p-title">Vendor Continuous Monitoring Report</h2>
        <p className="m-ital">{template === 'cmrs' ? 'Standard Version' : 'Enhanced Version'}</p>
        <hr />
        <h3 className="p-name">{name}</h3>
        <p className="">
          Website: <a href={domain}>{domain}</a> | Printed Date: {date.toLocaleDateString()}
        </p>
        <h4 className="p-description-title">Company Description</h4>
        <p className="">{description}</p>
        <div className="provenance-risk">
          <span className="move-hex-right">
            <HexSeverity letter={overall[0]} />
          </span>
          <h3>Provenance Risk</h3>
          {template === 'cmrs' ? null : (
            <span>
              Manufacturing <SeverityPill severity={manufacturing} />
            </span>
          )}
          {template === 'cmrs' ? null : (
            <span>
              Foreign Ownership <SeverityPill severity={foreignOwnership} />
            </span>
          )}
          <span>
            Mergers and Acquisitions <SeverityPill severity={mergerAcquisition} />
          </span>
          {template === 'cmrs' ? null : (
            <span>
              Physical Presence <SeverityPill severity={physicalPresence} />
            </span>
          )}
          {template === 'cmrs' ? null : (
            <span>
              Corporate Families <SeverityPill severity={corporateFamilies} />
            </span>
          )}
          <span>
            Cyber Presence <SeverityPill severity={cyberPresence} />
          </span>
        </div>
        <div className="security-risk">
          <span className="move-hex-right">
            <HexSeverity letter={overall_security[0]} />
          </span>
          <h3>Security Risk</h3>
          {template === 'cmrs' ? null : (
            <span>
              Watchlist <SeverityPill severity={watchlist} />
            </span>
          )}
          <span>
            Cyber Hygiene <SeverityPill severity={cyberHygiene} />
          </span>
          <span>
            Breaches <SeverityPill severity={breaches} />
          </span>
          {template === 'cmrs' ? null : (
            <span>
              Compliance <SeverityPill severity={total_risk} />
            </span>
          )}
          <span>
            Vulnerability Solution <SeverityPill severity={vulnSolution} />
          </span>
        </div>
      </div>
    );
  }
}
