/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

import { Busy } from '../../components';
import config from '../../config';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

import { urlFromPath, pageIsValid } from '../../lib/utils';
import { withPrincipal } from '../../components';

import ActionButton from '../../components/actionButton';
import { subscriberTypeIDToName } from './helpers';
import { round } from 'lodash';
import { SortCarets } from '../../components/assessmentList';
import { MdDeleteForever } from 'react-icons/md';

import '../subscriberAndUserLists.css';

const Subscribers = ({
  isBusy,
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  sort,
  canEditSubscribers,
  canDeleteSubscribers,
  reloadData,
}) => {
  const columnFormatter = (cell) => {
    return (
      <span title={cell && cell.props ? cell.props.children : cell}>
        {cell && cell.props ? cell.props.children : cell}
      </span>
    );
  };

  const columns = [
    {
      dataField: 'name',
      text: 'name',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '22%' },
      classes: 'subscribers-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'subscriber_type_name',
      text: 'subscriber category',
      sort: false,
      sortCaret: SortCarets,
      editable: false,
      classes: 'subscribers-table-columns',
    },
    {
      dataField: 'website',
      text: 'website',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '22%' },
      formatter: columnFormatter,
      classes: 'subscribers-table-columns',
    },
    {
      dataField: 'email_address',
      text: 'email address',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '30%' },
      formatter: columnFormatter,
      classes: 'subscribers-table-columns',
    },
    {
      dataField: 'tokens',
      text: 'tokens',
      sort: false,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '8%' },
      classes: 'subscribers-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'is_deleted',
      text: 'Is Deleted',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      formatter: (cell, row) => {
        return row.is_deleted ? <MdDeleteForever className="deleted-instance-icon" title="Deleted" /> : '';
      },
      headerStyle: { width: '7%' },
      classes: 'subscribers-table-columns subscribers-table-is-deleted-column',
    },
    {
      dataField: 'actions',
      text: 'Action',
      editable: false,
      formatter:
        canEditSubscribers || canDeleteSubscribers
          ? (cell, row) => {
              return !row.is_deleted ? (
                <div className="btn-group">
                  {canEditSubscribers && <ActionButton subscriberID={row.id} action="editSubscriber" />}
                  {canDeleteSubscribers && (
                    <ActionButton subscriberID={row.id} action="deleteSubscriber" onAction={reloadData} />
                  )}
                </div>
              ) : (
                ''
              );
            }
          : null,
    },
  ];

  const rowStyle = (row) => {
    return row.is_deleted ? { backgroundColor: '#ff8080' } : {};
  };

  return (
    <div className="subscribers-table mb-5">
      <BootstrapTable
        noDataIndication={() => (isBusy ? <Busy isBusy={true} /> : <div className="text-center">no data</div>)}
        remote
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={sort}
        pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true })}
        onTableChange={onTableChange}
        cellEdit={cellEditFactory({ mode: 'click' })}
        rowStyle={rowStyle}
      />
    </div>
  );
};

class SubscriberList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: true,
      model: [],
      search: '',
      pageSize: 10,
      page: 1,
      totalRecords: 0,
      needsDataReload: false,
      canCreateSubscribers: false,
      canEditSubscribers: false,
      canDeleteSubscribers: false,
    };

    this.reloadData = this.reloadData.bind(this);
  }

  componentDidMount() {
    const { search } = this.props.location.query;
    const { page, orderBy, dir } = this.props.match.params;
    const { pageSize } = this.state;
    const { principal } = this.props;

    this.setState({
      canCreateSubscribers: principal.roles.some((r) => r.permissions.some((p) => p === 'subscription.new')),
      canEditSubscribers: principal.roles.some((r) =>
        r.permissions.some((p) => p === 'subscription.save' || p === 'subscription.manageTokenBalance'),
      ),
      canDeleteSubscribers: principal.roles.some((r) => r.permissions.some((p) => p === 'subscription.delete')),
    });

    this.getData(search, page, pageSize, orderBy, dir);
  }

  componentDidUpdate(prevProps) {
    const { search: cSearch = '' } = this.props.location.query;
    const { page: cPage, orderBy: cOrderBy, dir: cDir } = this.props.match.params;

    const { search: pSearch = '' } = prevProps.location.query;
    const { page: pPage, orderBy: pOrderBy, dir: pDir } = prevProps.match.params;

    const { pageSize, needsDataReload, totalRecords } = this.state;

    let updatedPage = cPage,
      pageUpdated = false;

    if (!pageIsValid(cPage, totalRecords, pageSize)) {
      updatedPage = '1';
      pageUpdated = true;
    }

    if (
      !pageUpdated &&
      updatedPage === pPage &&
      cOrderBy === pOrderBy &&
      cDir === pDir &&
      cSearch === pSearch &&
      !needsDataReload
    ) {
      return;
    }

    this.getData(cSearch, updatedPage, pageSize, cOrderBy, cDir);
  }

  reloadData() {
    this.setState({ needsDataReload: true });
  }

  getData(search = '', page = 1, pageSize = 10, orderBy = 'name', dir = 'asc') {
    page = parseInt(page || 1, 10);
    pageSize = parseInt(pageSize || this.state.pageSize, 10);
    let asc = dir === 'desc' ? 0 : 1;

    this.setState({ isBusy: true, needsDataReload: false, model: [] });

    // get the subscriber_type list
    fetch(config.api.urlFor('subscriberTypesList'))
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          subscriberTypesList: data ? data : [],
        });
      })
      .catch(() => this.setState({ subscriberTypesList: [] }));

    // get the subscribers' data
    fetch(
      config.api.urlFor('subscribers', {
        search,
        page,
        pageSize,
        orderBy,
        asc,
        includeBalance: true,
        showDeleted: true,
      }),
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          search,
          page,
          orderBy,
          dir,
          model: data.rows.map((row) => ({
            id: row.id,
            name: <Link to={`/admin/subscriptions/${row.id}`}>{row.name}</Link>,
            email_address: row.email_address,
            website: row.website,
            tokens: round(row.tokens, 3),
            subscriber_type_id: row.subscriber_type_id,
            subscriber_type_name: subscriberTypeIDToName(row.subscriber_type_id, this.state.subscriberTypesList),
            is_deleted: row.is_deleted,
          })),
          totalRecords: data.totalRecords || 0,
          isBusy: false,
        });
      })
      .catch(() => this.setState({ isBusy: false }));
  }

  handleTableChange(type, { page, sortField, sortOrder }) {
    const {
      params: { page: cPage, orderBy: cOrderBy, dir: cDir },
      path,
    } = this.props.match;
    const { search: cSearch = '' } = this.props.location.query;

    if (cPage === page && cOrderBy === sortField && cDir === sortOrder) {
      return;
    }

    let pathname = urlFromPath(path, { page, orderBy: sortField || cOrderBy, dir: sortOrder || cDir });

    if (cSearch) {
      pathname = pathname + '?search=' + cSearch;
    }

    this.props.history.push(pathname);
  }

  onSearchSubmit(e) {
    e.preventDefault();

    const { search, orderBy, dir } = this.state;
    const { location, match, history } = this.props;

    const pathname = urlFromPath(match.path, { page: 1, orderBy, dir });
    const query = search ? `?search=${search}` : '';

    if (pathname !== location.pathname || query !== location.search) {
      history.push(pathname + query);
    }
  }

  render() {
    const { match } = this.props;
    const { orderBy, dir, page } = match.params;
    const {
      isBusy,
      model,
      totalRecords,
      search,
      pageSize,
      canCreateSubscribers,
      canEditSubscribers,
      canDeleteSubscribers,
    } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light mb-3">
          <div className="row collapse navbar-collapse" id="navbarTogglerDemo01">
            {canCreateSubscribers && (
              <div className="col">
                <ul className="navbar-nav mt-2 mt-lg-0">
                  <li className="nav-item active">
                    <Link className="btn btn-outline-primary" to={`/admin/subscriptions/new`}>
                      New
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            <div className="col">
              <form className="form-inline float-right" onSubmit={this.onSearchSubmit.bind(this)}>
                <input
                  id="search"
                  type="text"
                  className="form-control mr-2"
                  placeholder="search"
                  value={search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                />
              </form>
            </div>
          </div>
        </nav>
        <Subscribers
          isBusy={isBusy}
          data={model}
          page={parseInt(page, 10)}
          sizePerPage={pageSize}
          totalSize={parseInt(totalRecords, 10)}
          onTableChange={this.handleTableChange.bind(this)}
          sort={[{ dataField: orderBy, order: dir }]}
          canCreateSubscribers={canCreateSubscribers}
          canEditSubscribers={canEditSubscribers}
          canDeleteSubscribers={canDeleteSubscribers}
          reloadData={this.reloadData.bind(this)}
        />
      </div>
    );
  }
}

export default withPrincipal(SubscriberList);
