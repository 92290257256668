import React from 'react';
import ReactDOM from 'react-dom';

const CatalogReportGenericInfo = (props) => {
  const { title, bold, purchaseDate, reportDescriptions = {} } = props;

  const handleOnClick = (event) => {
    event.stopPropagation();
  };

  if (!title) return <></>;

  return (
    <div className="modal-report-title">
      {bold ? <b>{title}</b> : title}
      <div className="modal-report-generic-info" onClick={handleOnClick}>
        <div className="modal-report-generic-info-title">
          <span>{title}:</span>
          {purchaseDate ? (
            <span className="modal-report-generic-last-purchase">{'Last purchased ' + purchaseDate}</span>
          ) : null}
        </div>
        <p className="modal-report-generic-info-description">
          {reportDescriptions.hasOwnProperty(title)
            ? reportDescriptions[title].description
            : 'Description currently unavailable...'}
        </p>
      </div>
    </div>
  );
};

export default CatalogReportGenericInfo;
