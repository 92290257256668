import React, { useState } from 'react';
import Modal from 'react-modal';
import { DragDropContext } from 'react-beautiful-dnd';
import Busy from '../../busy';
import FileAccessRequestColumn from './fileAccessRequestColumn';
import * as TrustCenterAction from './actions';
import { useTrustCenterStore } from './context';
import { RequestStatus } from './lib';
import config from '../../../config';
import { toastError } from '../../../lib/utils';

import './fileAccessRequestManager.css';

export const FileAccessRequestManagerModal = () => {
  //Context
  const { store, dispatch } = useTrustCenterStore();
  const { pendRequestsFileData = {} } = store;
  const { fileID } = pendRequestsFileData;

  //State
  const [isBusy, setIsBusy] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [data, setData] = useState({});

  //State-Based Consts
  const pendingRequests =
    data &&
    data.requests &&
    data.columns &&
    data.columns[RequestStatus.PENDING] &&
    data.columns[RequestStatus.PENDING].requestIds
      ? data.columns[RequestStatus.PENDING].requestIds.map((requestId) => data.requests[requestId])
      : [];
  const accessGranted =
    data &&
    data.requests &&
    data.columns &&
    data.columns[RequestStatus.APPROVED] &&
    data.columns[RequestStatus.APPROVED].requestIds
      ? data.columns[RequestStatus.APPROVED].requestIds.map((requestId) => data.requests[requestId])
      : [];
  const accessDenied =
    data &&
    data.requests &&
    data.columns &&
    data.columns[RequestStatus.DENIED] &&
    data.columns[RequestStatus.DENIED].requestIds
      ? data.columns[RequestStatus.DENIED].requestIds.map((requestId) => data.requests[requestId])
      : [];

  //Effects
  React.useEffect(() => {
    if (fileID) {
      setIsBusy(true);

      //Load data from server
      fetch(config.api.urlFor('fileItemAccessRequests', { fileID }))
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, data, message = 'Error loading file item requests.' } = response;

          if (!isSuccess) {
            toastError(message);
            return;
          }

          const fileRequests = ((data || {}).data || {}).requests || [];
          let requestsData = {
            requests: {},
            columns: {
              //Column names here need to be the same as the droppable ids of the RequestColumns in the render
              [RequestStatus.PENDING]: {
                id: RequestStatus.PENDING,
                title: 'Pending Requests',
                requestIds: [],
              },
              [RequestStatus.APPROVED]: {
                id: RequestStatus.APPROVED,
                title: 'Access Granted',
                requestIds: [],
              },
              [RequestStatus.DENIED]: {
                id: RequestStatus.DENIED,
                title: 'Access Denied',
                requestIds: [],
              },
            },
          };

          for (let fileRequest of fileRequests) {
            const { status, userID } = fileRequest;
            const requestID = userID;

            requestsData.requests[requestID] = {
              id: userID,
              content: fileRequest,
            };

            switch (status) {
              case RequestStatus.PENDING:
                requestsData.columns[RequestStatus.PENDING].requestIds.push(requestID);
                break;
              case RequestStatus.APPROVED:
                requestsData.columns[RequestStatus.APPROVED].requestIds.push(requestID);
                break;
              case RequestStatus.DENIED:
                requestsData.columns[RequestStatus.DENIED].requestIds.push(requestID);
                break;
              default:
                break;
            }
          }

          setData(requestsData);
        })
        .catch(() => {
          setData({});
          handleModalClose();
          toastError('An error occurred while attempting to load the file access requests.');
        })
        .finally(() => setIsBusy(false));
    }
  }, [fileID]);

  //Functions
  const handleModalClose = () => {
    dispatch(TrustCenterAction.togglePendingRequestsModal());
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      //Re-arranging items within one column
      const newTaskIds = Array.from(start.requestIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        requestIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newState);
    } else {
      // Moving from one column to another
      setIsUpdating(true);

      const startRequestIds = Array.from(start.requestIds);
      startRequestIds.splice(source.index, 1);

      const newStart = {
        ...start,
        requestIds: startRequestIds,
      };

      const finishRequestIds = Array.from(finish.requestIds);
      finishRequestIds.splice(destination.index, 0, draggableId);

      const newFinish = {
        ...finish,
        requestIds: finishRequestIds,
      };

      let updatedRequests = Object.assign({}, data.requests);
      updatedRequests[draggableId].content.status = newFinish.id;

      const newState = {
        ...data,
        requests: updatedRequests,
        columns: {
          ...data.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };

      //This is done prior to the fetch to update the display as it's processing without any visual weirdness
      //If this is done in the callback, the entry will "jump back" to its original status  and then "jump to" the updated status
      const oldState = data;
      setData(newState);

      const { userID, subscriberID } = updatedRequests[draggableId].content;

      fetch(config.api.urlFor('fileItemAccessRequests', { fileID }), {
        method: 'PUT',
        body: { ...updatedRequests[draggableId].content, fileID },
      })
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, message = 'Error updating access request.' } = response;

          if (isSuccess) {
            dispatch(
              TrustCenterAction.adjustFileItemRequestCount(newStart.id, newFinish.id, 1, { userID, subscriberID }),
            );
          } else {
            toastError(message);
          }
        })
        .catch(() => {
          setData(oldState); //An error occured, so revert the state so the display is accurate as no updates were officially made
          toastError('An error occurred while attempting to update the status of the file access request.');
        })
        .finally(() => setIsUpdating(false));
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={!!fileID}
      className="file-access-request-modal"
      shouldCloseOnOverlayClick={!isUpdating}
      onRequestClose={handleModalClose}
    >
      <Busy isBusy={isBusy}>
        <div className="sa-modal-btn-container">
          <div className="sa-modal-close" onClick={isUpdating ? undefined : handleModalClose}>
            {isUpdating ? (
              <div className="mini-busy">
                <Busy isBusy={true} />
              </div>
            ) : (
              'x'
            )}
          </div>
        </div>
        <div className="header">
          <h5>Manage requests for private files by drag-and-dropping parties into the Access bucket.</h5>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="file-access-request-groups">
            <FileAccessRequestColumn id={RequestStatus.PENDING} title="Pending Requests" requests={pendingRequests} />
            <FileAccessRequestColumn id={RequestStatus.APPROVED} title="Access Granted" requests={accessGranted} />
            <FileAccessRequestColumn id={RequestStatus.DENIED} title="Access Denied" requests={accessDenied} />
          </div>
        </DragDropContext>
        <div className="sa-modal-btn-container">
          <div className="sa-modal-btns-right">
            {isUpdating ? (
              <div className="mini-busy">
                <Busy isBusy={true} />
              </div>
            ) : (
              <button
                data-test-id="UploadFile-button-cancel"
                className="btn btn-outline-primary btn-nav sa-modal-cancel-btn"
                onClick={handleModalClose}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </Busy>
    </Modal>
  );
};

export default FileAccessRequestManagerModal;
