import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useUpdateState, useSignalEffect } from '../../components';

import { Table, Pagination, ChipAlert, Spinner, useQuery } from '../../components/';
import { pageIsValid } from '../../lib/utils';

import config from '../../config';

const statusInfo = [
  { name: 'Pending', class: 'text-success' },
  { name: 'Registered', class: 'text-primary' },
  { name: 'Invitation Expired', class: 'text-danger' },
];

const getData = async (search, page, signal) => {
  search = search || '';
  page = parseInt(page, 10, 1);

  let url = config.api.urlFor('invitations') + `?page=${page}`;
  url = search ? url + `&search=${search}` : url;

  try {
    const response = await fetch(url, { signal });

    return response.json();
  } catch {
    /* noop */
  }
};

const sendInvitations = async (selectedInvitations) => {
  let sent = [];

  for (let invite of selectedInvitations.filter(Boolean)) {
    try {
      await fetch(config.api.urlFor('sendInvitation', { id: invite.id }));

      sent.push(invite);
    } catch {
      /* noop */
    }
  }

  return sent;
};

const InvitationList = () => {
  const [state, setState] = useUpdateState({
    alerts: [],
    model: [],
    isBusy: false,
    associations: [],
    selectedInvitations: [],
    sendIsBusy: false,
    page: 1,
    search: '',
    pageSize: 10,
  });

  const match = useRouteMatch();
  const history = useHistory();
  const queryParams = useQuery();

  const { search } = queryParams;
  const { page } = match.params;

  useSignalEffect(
    async (signal) => {
      setState({ isBusy: true });

      const isPageValid = pageIsValid(page, totalRecords, state.pageSize);
      let updatedPage = page,
        isPageUpdated = false;

      if (!isPageValid || (search && search !== state.search)) {
        updatedPage = 1;
        isPageUpdated = true;
      }

      const data = await getData(search, updatedPage, signal);

      if (isPageUpdated) {
        history.push(
          window.mapPath(match.path, { ...match.params, page: updatedPage }) + (search ? `?search=${search}` : ''),
        );
      }

      const invitationData = {
        search: search || '',
        page,
        model: data.rows.map((row, i) => ({
          checkbox: (
            <input
              key={i}
              type="checkbox"
              checked={row.isSelected}
              onChange={(e) => (row.isSelected = e.target.checked) & toggleSelectInvitation(row)}
            />
          ),
          name: `${row.firstName} ${row.lastName} (${row.emailAddress})`,
          type: row.type,
          associate: row.associateName,
          creator: row.creator,
          created:
            new Date(row.created).getTime() < new Date('01/01/1900').getTime()
              ? ''
              : new Date(row.created).toDateString(),
          expires:
            new Date(row.expiration).getTime() < new Date('01/01/1900').getTime()
              ? ''
              : new Date(row.expiration).toDateString(),
          status: <span className={statusInfo[row.statusIndex].class}>{statusInfo[row.statusIndex].name}</span>,
        })),
        totalRecords: data.totalRecords,
        isBusy: false,
      };

      setState({ ...invitationData, isBusy: false });
    },
    [search, page],
  );

  const sendSelectedInvitations = async () => {
    setState({ sendIsBusy: true });

    await sendInvitations(state.selectedInvitations);

    setState({
      sendIsBusy: false,
      alerts: [`Sent invitations to ${selectedInvitations.length} recipient(s).`],
      alertIsError: false,
    });
  };

  const toggleSelectInvitation = async (invitation) => {
    setState((state) => {
      let { selectedInvitations } = state;

      const found = selectedInvitations.find((i) => i.id === invitation.id);

      if (found) {
        selectedInvitations = selectedInvitations.filter((i) => i.id !== found.id);
      } else {
        selectedInvitations.push(invitation);
      }

      return {
        ...state,
        selectedInvitations,
      };
    });
  };

  const { selectedInvitations, model, totalRecords, isBusy, sendIsBusy, alertIsError, alerts } = state;

  return (
    <div>
      <div className="card mb-3">
        <div className="card-header">
          <i className="far fa-envelope mr-1"></i>
          Invitations
        </div>
        <div className="card-body">
          <ChipAlert
            isError={alertIsError}
            alerts={alerts}
            style={{ marginTop: '15px' }}
            onDelete={() => setState({ alerts: [] })}
          />
          <nav className="navbar navbar-expand navbar-light bg-light mb-3">
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item active">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => sendSelectedInvitations()}
                    disabled={!selectedInvitations.length || sendIsBusy}
                  >
                    Send <Spinner style={{ marginLeft: '5px' }} isVisible={sendIsBusy} />
                  </button>
                </li>
              </ul>
              <form
                className="form-inline"
                onSubmit={(e) => {
                  e.preventDefault();
                  history.push(
                    window.mapPath(match.path, { ...match.params, page: 1 }) +
                      (state.search ? `?search=${state.search}` : ''),
                  );
                }}
              >
                <input
                  id="search"
                  type="text"
                  className="form-control mr-2"
                  placeholder="search"
                  value={state.search}
                  onChange={(e) => setState({ search: e.target.value })}
                />
              </form>
            </div>
          </nav>
          <Table
            columns={[
              { label: '-', source: 'checkbox' },
              { label: 'name', source: 'name' },
              { label: 'type', source: 'type' },
              { label: 'associate', source: 'associate' },
              { label: 'creator', source: 'creator' },
              { label: 'created', source: 'created' },
              { label: 'expires', source: 'expires' },
              { label: 'status', source: 'status' },
            ]}
            data={model}
            isBusy={isBusy}
          />
          <Pagination
            page={page}
            totalRecords={totalRecords}
            onPageChange={(page) => {
              history.push(window.mapPath(match.path, { ...match.params, page }) + (search ? `?search=${search}` : ''));
            }}
          />
        </div>
        <div className="card-footer small text-muted"></div>
      </div>
    </div>
  );
};

export default InvitationList;
