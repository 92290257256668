import React from 'react';
import ProtectedFileItem from './protectedFileItem';

import './vendor/securityAttestation.css';

const FileGroupDownload = (props) => {
  //Props
  const {
    groupName,
    filesList,
    canEdit = false,
    canCreate = false,
    canShare = false,
    canRequestAccess = true,
    onPendingRequestsClick = () => {},
    onRequestAccessClick = () => {},
    onPlusSignClickHandler = () => {},
    onDownloadsClickHandler = () => {},
    onModifyClickHandler = () => {},
    onDownloadFileClick = () => {},
    onShareFileClick = () => {},
    onUploadRequestClick = () => {},
  } = props;

  //Render
  return (
    <div className="pb-3">
      <b>{groupName}</b>
      <div className="row sa-cat-section-row">
        {canCreate && (
          <div className="sa-default-box" onClick={onPlusSignClickHandler}>
            <i className="fas fa-plus sa-default-box-plus"></i>
          </div>
        )}
        <div className={'sa-files-row' + (canCreate ? '' : ' no-create')}>
          {filesList &&
            filesList.map((elem, i) => (
              <ProtectedFileItem
                fileItem={elem}
                canEdit={canEdit}
                canCreate={canCreate}
                canShare={canShare}
                canRequestAccess={canRequestAccess}
                key={i}
                onDownloadClick={() => onDownloadFileClick(elem)}
                onPendingRequestsClick={() => onPendingRequestsClick(elem.id)}
                onRequestAccessClick={() => onRequestAccessClick(elem.id)}
                onDownloadsClick={() => onDownloadsClickHandler(elem.id)}
                onModifyClick={() => {
                  onModifyClickHandler(elem.id);
                }}
                onShareClick={() => onShareFileClick(elem.id)}
                onUploadRequestClick={() => onUploadRequestClick(elem.id)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FileGroupDownload;
