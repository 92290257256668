import React from 'react';
import SeverityLabel from './severityLabel';
import '../../routes/vendor/catalogVendor.css';

const statusMessages = {
  checkingStatus: 'Retrieving scan status',
  doingQuickScan: 'Vendor quick scan is currently-in progress.',
  doingFullScan: 'Vendor quick scan is complete. Full scan is in progress.',
  finishedFullScan: 'Full scan is complete.',
  awaitingImport: 'Awaiting import from scanner.',
};

const contextMessages = {
  doingQuickScan:
    "Please check back momentarily. Once the quick scan is finished, progress for the vendor's full scan will be shown to the right.",
  doingFullScan: 'The progress of each scan task is shown to the right.',
  finishedFullScan: 'Scan scores can be viewed in the Vendor Overview.',
};

const infoContainer = {
  borderRadius: '5px',
  padding: '15px',
  paddingBottom: '15px',
  background: '#f6f6f6',
};

const scanCard = {
  padding: '0px 125px 0px 0px',
  margin: '10px 0px',
  textAlign: 'right',
  marginTop: '10px',
};

const scanRowOne = {
  padding: '20px 0px 10px 30px',
};

const scanRowTwo = {
  padding: '10px 0px 10px 30px',
};

const scanRwoThree = {
  padding: '10px 0px 20px 30px',
};

const ScanProgress = ({ finishedFullScan, doingQuickScan, fullScanTasks, hasScanData }) => {
  if (hasScanData === undefined)
    return (
      <div className="row my-3" style={infoContainer}>
        <div className="col float-left">
          <h5 style={{ marginBottom: '0px' }}>
            <i className="animated-ellipsis">{statusMessages.checkingStatus}</i>
          </h5>
        </div>
      </div>
    );

  const { discoverSubs, discoverPorts, scanDNS, doppleganger, scanWeb, scanSSL, scoring } = fullScanTasks || {};

  return (
    <React.Fragment>
      {finishedFullScan ? (
        <div className="row my-3" style={infoContainer}>
          <div className="col-4">
            <h5 style={{ marginBottom: '0px' }}>
              <b>{statusMessages.finishedFullScan}</b>
            </h5>
            {!hasScanData && <em>{statusMessages.awaitingImport}</em>}
          </div>
        </div>
      ) : (
        <div className="row" style={infoContainer}>
          <div className="col-4">
            {/* {doingQuickScan ? <h5><b>Vendor Quick Scan is currently in-progress.</b></h5> : <h5><b>Vendor Full Scan is currently in-progress.</b></h5>} */}
            <h5>
              <b>
                {' '}
                {doingQuickScan
                  ? statusMessages.doingQuickScan
                  : finishedFullScan
                  ? statusMessages.finishedFullScan
                  : statusMessages.doingFullScan}{' '}
              </b>
            </h5>
            <p>
              <b>
                {' '}
                {doingQuickScan
                  ? contextMessages.doingQuickScan
                  : finishedFullScan
                  ? contextMessages.finishedFullScan
                  : contextMessages.doingFullScan}{' '}
              </b>
            </p>
          </div>
          <div className="card col-8" style={scanCard}>
            {/* {doingQuickScan ? null :  */}
            <>
              <div className="row" style={scanRowOne}>
                <div className="col">
                  <b>Discovering Subdomains: </b>
                  <SeverityLabel label={discoverSubs} detailedFindingsScan={true} />
                </div>
                <div className="col">
                  <b>Probing Ports: </b>
                  <SeverityLabel label={discoverPorts} detailedFindingsScan={true} />
                </div>
                <div className="col">
                  <b>Scanning DNS: </b>
                  <SeverityLabel label={scanDNS} detailedFindingsScan={true} />
                </div>
              </div>
              <div className="row" style={scanRowTwo}>
                <div className="col">
                  <b>Resolving Dopplegangers: </b>
                  <SeverityLabel label={doppleganger} detailedFindingsScan={true} />
                </div>
                <div className="col">
                  <b>Indexing Websites: </b>
                  <SeverityLabel label={scanWeb} detailedFindingsScan={true} />
                </div>
                <div className="col">
                  <b>Validating SSL: </b>
                  <SeverityLabel label={scanSSL} detailedFindingsScan={true} />
                </div>
              </div>
              <div className="row" style={scanRwoThree}>
                <div className="col">
                  <b>Scoring: </b>
                  <SeverityLabel label={scoring} detailedFindingsScan={true} />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
            </>
            {/* } */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ScanProgress;
