import React, { useState, useEffect } from 'react';

import config from '../../config';
import Busy from '../busy';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { multiSelectFilter } from 'react-bootstrap-table2-filter';
import { withPrincipal } from '../../components';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './bootstrapTable.css';

const MyFindingsTable = (props) => {
  const { forSpecificVendor, vendorID, principal, numFindings } = props;

  const canEdit = principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.findings.save'));

  const [findingsData, setFindingsData] = useState([]);
  const [statusSelectOptions, setStatusSelectOptions] = useState({});
  const [statusEditorOptions, setStatusEditorOptions] = useState([]);
  const [resolutionSelectOptions, setResolutionSelectOptions] = useState({});
  const [resolutionEditorOptions, setResolutionEditorOptions] = useState([]);
  const [vendorSelectOptions, setVendorSelectOptions] = useState({});
  const [findingTypeSelectOptions, setFindingTypeSelectOptions] = useState({});
  const [isBusy, setIsBusy] = useState(true);
  const [hideTable, setHideTable] = useState('d-none'); // Utilized to stop table refreshing with isBusy wrapper

  const handleNumFindingsChange = (findings) => {
    if (numFindings) {
      props.numFindings(findings.length);
    }
  };

  useEffect(() => {
    if (!forSpecificVendor || (forSpecificVendor && vendorID)) {
      const statusFetch = fetch(config.api.urlFor('subscriberFindingsOptions', { type: 'status' })).then((res) =>
        res.json(),
      );
      const resolutionFetch = fetch(config.api.urlFor('subscriberFindingsOptions', { type: 'resolution' })).then(
        (res) => res.json(),
      );

      const findingsFetchPath =
        forSpecificVendor && vendorID
          ? config.api.urlFor('vendorFindings', { id: vendorID })
          : config.api.urlFor('subscriberFindings');

      const findingsFetch = fetch(findingsFetchPath).then((res) => res.json());

      Promise.all([statusFetch, resolutionFetch, findingsFetch]).then((data) => {
        const statusFetchOptions = {};

        data[0].forEach((status) => {
          statusFetchOptions[status['status']] = status['status'];

          Object.assign(status, { ['value']: status['status'] });
          delete Object.assign(status, { ['label']: status['status'] })['status'];
        });

        const resolutionFetchOptions = {};
        data[1].forEach((resolution) => {
          resolutionFetchOptions[resolution['resolution']] = resolution['resolution'];

          Object.assign(resolution, { ['value']: resolution['resolution'] });
          delete Object.assign(resolution, { ['label']: resolution['resolution'] })['resolution'];
        });

        const vendorFetchOptions = {};
        const findingFetchOptions = {};

        data[2].forEach((finding) => {
          finding.longDescription = finding.description;
          finding.description =
            finding.description.length > 78 ? finding.description.substring(0, 75) + '...' : finding.description;
          finding.comment = finding.comment || '';

          if (finding.statusTypeID) {
            finding.status = data[0].find((x) => x.id == finding.statusTypeID).label;
          } else {
            finding.status = 'New';
          }

          if (finding.resolutionTypeID) {
            finding.resolution = data[1].find((x) => x.id == finding.resolutionTypeID).label;
          } else {
            finding.resolution = 'Unresolved';
          }

          vendorFetchOptions[finding.vendorName] = finding.vendorName;
          findingFetchOptions[finding.findingTypeName] = finding.findingTypeName;
        });

        setStatusSelectOptions(statusFetchOptions);
        setResolutionSelectOptions(resolutionFetchOptions);
        setVendorSelectOptions(vendorFetchOptions);
        setFindingTypeSelectOptions(findingFetchOptions);

        setStatusEditorOptions(data[0]);
        setResolutionEditorOptions(data[1]);
        setFindingsData(data[2]);
        handleNumFindingsChange(data[2]);

        setIsBusy(false);
        setHideTable('d-table');
      });
    }
  }, [vendorID]);

  const NoFindingsIndication = () => (
    <div className="text-center">
      <h4>No findings found.</h4>
    </div>
  );

  const updateTableFinding = async (oldValue, newValue, row, column) => {
    if (oldValue != newValue) {
      if (column.dataField == 'resolution') {
        if (newValue == 'Ignore') {
          row.status = 'Ignore';
        } else if (newValue != 'Unresolved') {
          row.status = 'Closed';
        }
      } else if (column.dataField == 'status') {
        if (newValue == 'Ignore') {
          row.resolution = 'Ignore';
        } else if (newValue == 'In Progress') {
          row.resolution = 'Unresolved';
        }
      }

      await fetch(config.api.urlFor('subscriberFindings'), {
        method: 'PUT',
        body: {
          findingID: row.findingID,
          status: row.status,
          resolution: row.resolution,
          comment: column.dataField == 'comment' ? newValue : row.comment,
        },
      })
        .then(() => {
          setHideTable('d-table');
          setIsBusy(false);
        })
        .catch(() => {
          setHideTable('d-table');
          setIsBusy(false);
        });
    } else {
      setHideTable('d-table');
      setIsBusy(false);
    }
  };

  const vendorFormatter = (cell, row) => {
    return <a href={`#/catalog/vendor/${row.vendorID}/Overview`}>{cell}</a>;
  };

  const findingTypeFormatter = (cell, row) => {
    let tab,
      scrollToSectionName = null;

    switch (cell) {
      case 'Compliance Finding':
        tab = 'Compliance';
        break;
      case 'Foreign Influence':
        tab = 'ForeignInfluence';
        break;
      case 'Related Entity Discovery':
        tab = 'RiskRank';
        break;
      case 'Data Breach':
        tab = 'Overview';
        scrollToSectionName = 'BreachMonitoring';
        break;
      default:
        tab = 'Overview';
        break;
    }
    return (
      <a href={`#/catalog/vendor/${row.vendorID}/${tab + (scrollToSectionName ? '#' + scrollToSectionName : '')}`}>
        {cell}
      </a>
    );
  };

  const findingFormatter = (cell) => {
    return <div onClick={handleRowExpansion}>{cell}</div>;
  };

  const handleRowExpansion = (event) => {
    event.target.closest('.bootstrap-table-row').querySelector('.expand-cell').click();
  };

  const statusFormatter = (cell) => {
    let spanStyle = { minWidth: 'max-content' };
    const spanClassName = 'bootstrap-cell-formatter' + (canEdit ? ' canEdit' : '');

    switch (cell) {
      case 'In Progress':
        spanStyle = { ...spanStyle, color: '#ef5a43', border: '1px solid #ef5a43' };
        break;
      case 'Closed':
        spanStyle = { ...spanStyle, color: '#ffffff', backgroundColor: '#002752' };
        break;
      case 'Ignore':
        spanStyle = { ...spanStyle, color: '#ffffff', backgroundColor: '#30383B' };
        break;
      default:
        spanStyle = { ...spanStyle, color: '#30383B', border: '1px solid #30383B' };
        break;
    }

    return (
      <span className={spanClassName} style={spanStyle}>
        {cell}
        {canEdit && (
          <span style={{ marginLeft: '15px' }}>
            <i className="fas fa-chevron-down"></i>
          </span>
        )}
      </span>
    );
  };

  const resolutionFormatter = (cell) => {
    let spanStyle = { minWidth: 'max-content' };
    const spanClassName = 'bootstrap-cell-formatter' + (canEdit ? ' canEdit' : '');

    switch (cell) {
      case 'Unresolved':
        spanStyle = { ...spanStyle, color: '#30383B', border: '1px solid #30383B' };
        break;
      case 'Ignore':
        spanStyle = { ...spanStyle, color: '#ffffff', backgroundColor: '#30383B' };
        break;
      default:
        spanStyle = { ...spanStyle, color: '#0b8a70', border: '1px solid #0b8a70' };
        break;
    }

    return (
      <span className={spanClassName} style={spanStyle}>
        {cell}
        {canEdit && (
          <span style={{ marginLeft: '15px' }}>
            <i className="fas fa-chevron-down"></i>
          </span>
        )}
      </span>
    );
  };

  const commentFormatter = (cell) => {
    return <span style={{ wordWrap: 'break-word' }}>{cell}</span>;
  };

  const expandRowFormat = (row) => {
    return (
      <div>
        <p>{row.longDescription}</p>
      </div>
    );
  };

  const getColumns = () => {
    const organizeDataEnabled = !!findingsData && findingsData.length > 1;

    let columns = [
      {
        dataField: 'findingID',
        text: 'Finding ID',
        hidden: true,
        editable: false,
      },
    ];

    if (!forSpecificVendor) {
      columns.push({
        dataField: 'vendorName',
        text: 'Vendor',
        sort: organizeDataEnabled, // If there's no data or one result, sorting isn't needed
        editable: false,
        formatter: vendorFormatter,
        filter: organizeDataEnabled
          ? multiSelectFilter({
              // same goes for filtering
              options: vendorSelectOptions,
            })
          : undefined,
      });
    }

    columns.push(
      {
        dataField: 'findingTypeName',
        text: 'Finding Type',
        sort: organizeDataEnabled,
        editable: false,
        formatter: findingTypeFormatter,
        filter: organizeDataEnabled
          ? multiSelectFilter({
              options: findingTypeSelectOptions,
            })
          : undefined,
      },
      {
        dataField: 'description',
        text: 'Finding',
        editable: false,
        formatter: findingFormatter,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: organizeDataEnabled,
        formatter: statusFormatter,
        editor: canEdit
          ? {
              type: Type.SELECT,
              getOptions: () => {
                return statusEditorOptions;
              },
            }
          : undefined,
        filter: organizeDataEnabled
          ? multiSelectFilter({
              options: statusSelectOptions,
            })
          : undefined,
      },
      {
        dataField: 'resolution',
        text: 'Resolution',
        sort: organizeDataEnabled,
        formatter: resolutionFormatter,
        editor: canEdit
          ? {
              type: Type.SELECT,
              getOptions: () => {
                return resolutionEditorOptions;
              },
            }
          : undefined,
        filter: organizeDataEnabled
          ? multiSelectFilter({
              options: resolutionSelectOptions,
            })
          : undefined,
      },
      {
        dataField: 'comment',
        text: 'Comment',
        editor: {
          type: Type.TEXTAREA,
          maxLength: '160',
        },
        formatter: commentFormatter,
      },
    );

    return columns;
  };

  return (
    <React.Fragment>
      <Busy isBusy={isBusy} />
      <div id="findings-table" className={hideTable}>
        <BootstrapTable
          bootstrap4
          keyField="findingID"
          data={findingsData}
          columns={getColumns()}
          expandRow={{
            renderer: expandRowFormat,
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expandByColumnOnly: true,
          }}
          headerClasses="bootstrap-table-header"
          rowClasses="bootstrap-table-row"
          pagination={
            !!findingsData && findingsData.length > 0 ? paginationFactory({ hideSizePerPage: true }) : undefined
          }
          noDataIndication={() => <NoFindingsIndication />}
          cellEdit={
            canEdit
              ? cellEditFactory({
                  mode: 'click',
                  blurToSave: true, // Utilized to enable saving (no actual blur)
                  beforeSaveCell: () => {
                    setIsBusy(true);
                    setHideTable('d-none');
                  },
                  afterSaveCell: updateTableFinding,
                })
              : undefined
          }
          filter={filterFactory()}
          filterPosition="top"
        />
      </div>
    </React.Fragment>
  );
};

export default withPrincipal(MyFindingsTable);
