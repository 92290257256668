import React from 'react';

const TopModalAddVendor = (props) => {
  const { id, name, handleInputBox, domain, errorFields } = props;

  return (
    <div id="add-vendor-top-modal" className="modal-vendor-info-box">
      <div className="m-flex-around">
        <div className="">
          <div className="input-title-text">Company Name*</div>
          {errorFields.name && <span className="m-error"> Please enter a Company Name </span>}
          <input
            id="add-vendor"
            className={'form-control' + (errorFields.name ? ' is-invalid' : '')}
            type="text"
            name="add-vendor"
            placeholder="Add Vendor"
            onChange={(e) => handleInputBox(e, 'vendorName')}
            defaultValue={name}
            disabled={!!id}
            maxLength="255"
          />
        </div>
        <div className="">
          <div className="input-title-text">Company Website*</div>
          {errorFields.domain && <span className="m-error"> Please enter a valid website </span>}
          <input
            id="add-vendor-website"
            className={'form-control' + (errorFields.domain ? ' is-invalid' : '')}
            type="text"
            name="add-vendor"
            defaultValue={domain}
            placeholder="https://vendor-domain.com"
            onChange={(e) => handleInputBox(e, 'vendorDomain')}
            disabled={!!id}
            maxLength="255"
          />
        </div>
      </div>
      {errorFields.domain && (
        <div className="add-vendor-error">
          Please contact support using the <strong>Need Help?</strong> button at the bottom right of the page if the
          company does not have a domain.
        </div>
      )}
    </div>
  );
};

export default TopModalAddVendor;
