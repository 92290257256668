import React, { Component } from 'react'

class Cybermap extends Component {

  render() {
    return <div id="cybermap">
      I am a cyber map!
    </div>
  }
}

export default Cybermap;