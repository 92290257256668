import React from 'react';
import Modal from 'react-modal';
import { Busy, useUpdateState } from '..';
import config from '../../config';
import './modalStyles.css';

const ConfirmDeleteModal = (props) => {
  // Props
  const {
    isOpen = false,
    onModalClose = () => {},
    refreshData = () => {},
    itemsToDelete = [],
    deletionType = 0,
  } = props;

  // State
  const [state, setState] = useUpdateState({
    processingDelete: false,
    showDeleteSuccess: false,
    showDeleteFailure: false,
    multipleItems: false,
  });

  const { processingDelete, showDeleteSuccess, showDeleteFailure, multipleItems } = state;

  // Effects
  React.useEffect(() => {
    setState({
      multipleItems: itemsToDelete.length > 1 ? true : false,
    });
  }, [itemsToDelete]);

  // Helper Functions
  const closeAndRefresh = () => {
    onModalClose();
    refreshData();
  };

  const handleDeleteConfirmation = (itemsToDelete) => {
    // TODO: if needed, add logic for possibly multiple deletions at once
    setState({ processingDelete: true });
    const { uuid } = itemsToDelete[0];
    processDeleteRequest(uuid);
  };

  const processDeleteRequest = (uuid) => {
    // uuid because this could be a productID, sbomHash, etc.
    switch (deletionType) {
      case 1:
        // TODO add fetch for product deletion
        break;
      case 2:
        fetch(config.api.urlFor('sbomDelete'), {
          method: 'DELETE',
          body: {
            hash: uuid,
          },
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.isSuccess) {
              setState({ showDeleteSuccess: true });
            } else {
              setState({ showDeleteFailure: true });
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setState({ processingDelete: false });
          });
        break;
      default:
        break;
    }
  };

  const dTypeConvert = (n) => {
    switch (n) {
      case 1:
        return 'product';
      case 2:
        return 'SBOM';
      default:
        return 'item';
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="delete-confirmation-modal"
      ariaHideApp={false}
      contentLabel="Confirm Delete"
      shouldCloseOnOverlayClick={false}
      onRequestClose={onModalClose}
    >
      {showDeleteFailure ? (
        <div className="del-conf-modal-inner">
          <button className="btn-no-show btn-right-side" onClick={onModalClose}>
            <div className="modal-exit-btn"></div>
          </button>
          <div className="modal-failure-big"></div>
          <p>Unable to delete {dTypeConvert(deletionType)} at this time. Please try again later.</p>
          <div>
            <button className="btn btn-modal-ok" onClick={onModalClose}>
              OK
            </button>
          </div>
        </div>
      ) : (
        <>
          {showDeleteSuccess ? (
            <div className="del-conf-modal-inner">
              <button className="btn-no-show btn-right-side" onClick={closeAndRefresh}>
                <div className="modal-exit-btn"></div>
              </button>
              <div className="modal-success-big"></div>
              <p>Selected {dTypeConvert(deletionType)}(s) successfully deleted.</p>
              <div>
                <button className="btn btn-modal-ok" onClick={closeAndRefresh}>
                  OK
                </button>
              </div>
            </div>
          ) : (
            <>
              {processingDelete ? (
                <div className="del-conf-modal-inner">
                  <Busy isBusy={true} />
                  <div className="committing-changes-txt section-inner-title animated-ellipsis">Deleting</div>
                </div>
              ) : (
                <div className="del-conf-modal-inner">
                  <button className="btn-no-show btn-right-side" onClick={onModalClose}>
                    <div className="modal-exit-btn"></div>
                  </button>
                  <div className="delete-trashcan"></div>
                  <p className="modal-delete-p">
                    Deleted {dTypeConvert(deletionType)}s will be removed from the A2V marketplace without the ability
                    to retrieve them. Do you confirm the deletion of
                    {multipleItems
                      ? ` ${itemsToDelete.length} `
                      : ` ${
                          itemsToDelete[0] && itemsToDelete[0].name
                            ? itemsToDelete[0].name
                            : `this ${dTypeConvert(deletionType)}`
                        } 
                    `}
                    from your list of {dTypeConvert(deletionType)}s?
                  </p>
                  <div className="side-buttons-container">
                    <div>
                      <button className="btn btn-modal-cancel" onClick={onModalClose}>
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button className="btn btn-modal-delete" onClick={() => handleDeleteConfirmation(itemsToDelete)}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ConfirmDeleteModal;
