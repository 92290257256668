import React from 'react';
import DDRR from '../postern/reports/ddrr/ddrr.jsx';
import PrintButton from '../postern/reusable/printButton';
import SectionContainer from '../sectionContainer';
import InfoHygiene from './infoHygiene';
import RiskRankSection from './riskRankSection';
import SectionLocked from '../sectionLocked';
import SectionDetailsWithFlags from '../sectionDetailsWithFlags';
import { withPrincipal } from '../../components';
import { assessmentTypes } from '../../constants/assessmentTypes.js';

/** Functional component for displaying Risk Rank content */
const RiskRankContent = (props) => {
  //Props
  const {
    isUnlockedDDRR,
    isLoadingVendorRisk = true,
    vendorData,
    ddrrScoresData = {},
    ddrrReportData,
    reportTypeDDRR,
    handleUnlockButtonClick,
  } = props;

  const { bannedRelationships = {} } = (ddrrReportData && ddrrReportData.redData) || {};

  //Render
  return (
    <div className="ddrr-content">
      <div className="info-and-scan">
        <InfoHygiene isLoadingVendorRisk={isLoadingVendorRisk} vendorData={vendorData}>
          <SectionContainer label="Risk Summary">
            <RiskRankSection
              vendorName={vendorData.name}
              isUnlockedDDRR={isUnlockedDDRR}
              ddrrScoresData={ddrrScoresData}
              reportTypeDDRR={reportTypeDDRR}
              handleUnlockButtonClick={handleUnlockButtonClick}
            />
            <div className="section-main-title">Related Entity Discovery Findings</div>
            {isUnlockedDDRR ? (
              <SectionDetailsWithFlags
                title="Potential Relationship with Banned Companies"
                data={bannedRelationships}
                isLoading={!ddrrReportData}
              />
            ) : (
              <SectionLocked
                title="Unlock Findings"
                buttonCaption="Unlock Data-Driven Risk Rank"
                info={`View Related Entity Discovery Findings for ${vendorData.name} by purchasing a ${assessmentTypes.DDRR.name}.`}
                details="The Data-Driven Risk Rank is used to classify a vendor's risk tier by examining risk accross 9 dimensions of a company:"
                bullets={[
                  'System Access',
                  'Cloud Security',
                  'Data Access',
                  'Financials',
                  'Fourth-Party',
                  'Offshore Presence',
                  'Physical Access',
                  'Reputation',
                  'Strategic',
                ]}
                canUnlockSection={props.principal.roles.some((r) =>
                  r.permissions.some((p) => p === 'subscriber.purchaseReports'),
                )}
                handleUnlockButtonClick={handleUnlockButtonClick}
                reportType={reportTypeDDRR}
              />
            )}
          </SectionContainer>
        </InfoHygiene>
      </div>
      {isUnlockedDDRR ? (
        <SectionContainer label={assessmentTypes.DDRR.name} className="row">
          {ddrrReportData ? (
            <PrintButton
              printOption="ddrr"
              vendorDomain={ddrrReportData.vendor?.domain}
              vendorName={ddrrReportData.vendor?.companyName}
            />
          ) : null}
          <div className="ddrr-report-section">
            <DDRR fetchData={false} data={ddrrReportData} query={{ showDrillDown: false }} />
          </div>
        </SectionContainer>
      ) : null}
    </div>
  );
};

export default withPrincipal(RiskRankContent);
