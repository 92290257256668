import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import './layoutBase.css';

import withPrincipal from '../components/withPrincipal';
import useUpdateState from './use-update-state';

const LayoutBase = (props) => {
  const {
    principal,
    history: {
      location: { query = {} },
    },
  } = props;

  const [state, setState] = useUpdateState({ optionsCollapsed: false });
  const { optionsCollapsed } = state;

  const optionsRef = React.useRef(null);
  const optionsWidthRef = React.useRef(null);
  const collapsedRef = React.useRef(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //This event listener function is initalized once and copies the current state
  //State changes will NOT be reflected in this function, althought it can UPDATE the state (but any refrences to state will still be the original state)
  //Refs are needed as a workaround to this limitation - collapsedRef can't be used as a conditional for rendering but setState is being used as a workaround
  //Because it is initially not collapsed, we can grab the expected width it needs and have a reference to it when needed
  //If we do this at the time of this function, the width returned will be the hamburger menu instead of the options side-by-side width
  const handleResize = () => {
    if (optionsRef && optionsRef.current) {
      let spaceTaken = optionsWidthRef && optionsWidthRef.current ? optionsWidthRef.current : 0;
      const availableSpace = optionsRef.current.getBoundingClientRect().width;

      if (!collapsedRef.current && !spaceTaken) {
        const optionsList = optionsRef.current.getElementsByClassName('dl-menu');
        if (optionsList && optionsList.length) {
          const childOptionsStyles = getComputedStyle(optionsList[0]);
          spaceTaken = parseFloat(childOptionsStyles.width);
          optionsWidthRef.current = spaceTaken;
        }
      }

      const collapseStatus = availableSpace - spaceTaken - 15 <= 0; //Added a buffer for when it line breaks to still be flagged for collapsed

      collapsedRef.current = collapseStatus;
      setState({ optionsCollapsed: collapseStatus });
    }
  };

  const lastSuccessfulLogon = window.$app.getState('lastSuccessfulLogon');

  if (query.embed) {
    window.$session.setState({ isEmbedded: query.embed === 'false' ? false : true });
  }

  const mainContainer = (
    <div id="layout-base" className="container-fluid">
      {!window.$session.getState('isEmbedded') ? (
        <div id="guardian-nav" className="row">
          <div className="header-logo" style={{ padding: '15px' }}>
            <a href="/">
              <img
                className="logo"
                alt="A2V Logo"
                style={{
                  maxWidth: '300px',
                }}
                src="/static/images/A2V_logo_negative.png"
                onLoad={handleResize}
              />
            </a>
          </div>
          <div className={optionsCollapsed ? 'options-collapsed' : undefined} ref={optionsRef}>
            {props.content || props.children}
          </div>
          {principal && (
            <div className="col-sm text-right name-logout">
              <div className="layout-info-element">{principal.emailAddress}&nbsp;|</div>
              {!!lastSuccessfulLogon && (
                <div className="layout-info-element">
                  <span>Last login: </span>
                  <br />
                  <span>
                    {new Date(lastSuccessfulLogon).toLocaleString({}, { dateStyle: 'short', timeStyle: 'short' })}
                  </span>
                </div>
              )}
              <div className="layout-info-element">
                |&nbsp;
                <Link to="/logout">
                  <i className="fas fa-sign-out-alt" style={{ paddingRight: '5px' }}></i>Logout
                </Link>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12">{props.content || props.children}</div>
        </div>
      )}
    </div>
  );

  return mainContainer;
};

export default withPrincipal(withRouter(LayoutBase));
