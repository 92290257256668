import React from 'react';
import { Link } from 'react-router-dom';

const dcmaNotice = () => (
  <React.Fragment>
    <div className="row justify-content-center mb-lg-2">
      <div className="col-8">
        <h5>
          <h3 className="pb-5">COPYRIGHT POLICY</h3>
          <p>
            Fortress Information Security LLC and its subsidiaries, divisions, and affiliates (collectively, “Fortress”)
            comply with the copyright notice-and-takedown procedures set out in the United States Digital Millennium
            Copyright Act (DMCA), which applies to content reported and removed for violating U.S. copyrights. Please
            note that any notice or counter-notice you submit must be truthful and must be submitted under penalty of
            perjury. A false notice or counter-notice may give rise to personal liability. You may therefore want to
            seek the advice of legal counsel before submitting a notice or a counter-notice. We may share any notices
            and counter-notices submitted to us with others including your contact information, and by submitting any
            notices, you agree you have no expectation of privacy in your submission.
          </p>
          <p>
            If we determine that you are a repeat infringer, we may terminate your access to our service, remove or ban
            you (and any account you created or control), and take other appropriate action in our sole discretion.
          </p>
          <p>
            <b className="pl-5"></b>DMCA Takedown Notices{' '}
          </p>
          <p>
            Content owners of copyrighted material or their representing agents may submit a DMCA notice to our
            registered Copyright Agent if they believe that infringing activity has taken place on our service. The
            abuse team will only consider valid reports of infringement, and you may submit a complete DMCA notice that
            features all of the points described below only if the representing party sending the request is the content
            owner or the authorized agent acting on behalf of the copyright owner. If you are not sure if Fortress has
            control over the allegedly infringed content, please obtain legal representation before contacting us. To be
            effective under the DMCA, any notification of claimed infringement must be in a written communication that
            includes substantially the following which must include a certification made under penalty of perjury:
          </p>
          <ol type="i">
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
              right that is allegedly infringed, as well as information sufficient for Fortress to determine the
              legitimacy of the signature and the identity of the signatory;{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              Identification of the copyrighted work claimed to have been infringed, or, if a single notification covers
              multiple copyrighted works at a single online site, a representative list of such works at that site,
              including citation to the applicable copyright registrations where available;{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              Identification of the material that is claimed to be infringing or to be the subject of infringing
              activity and that is to be removed or access to which is to be disabled, and information reasonably
              sufficient to permit Fortress to locate the material, including a timestamp and visible identification of
              the material in a screenshot or comparable medium, with all metadata intact, as well as a hyperlink or URL
              to the website or online content at issue;{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              Information reasonably sufficient to permit Fortress to contact the complaining party, including an email
              address, telephone number, and, if available, physical mail address;{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              A statement that the complaining party has a good faith belief that use of the material in the manner
              complained of is not authorized by the copyright owner, its agent, or the law; and{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              A statement that the information in the notification is accurate, and under penalty of perjury, that the
              complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly
              infringed.{' '}
            </li>
          </ol>
          <p>
            Any such notifications of claimed infringement must be sent to the below contact with the subject line “DMCA
            NOTICE:”
          </p>
          <p>
            <a className="pl-5"> Fortress Copyright Agent</a>
          </p>
          <p>
            <a className="pl-5"> Losey PLLC</a>
          </p>
          <p>
            <a className="pl-5"> 1420 Edgewater Drive</a>
          </p>
          <p>
            <a className="pl-5"> Orlando, FL 32804</a>
          </p>
          <p>
            <a className="pl-5"> dmca@losey.law</a>
          </p>
          <p>
            Please note if any notification of claimed infringement does not meet the above requirements, Fortress has
            no responsibility to respond to or act on any such defective notification of claimed infringement.{' '}
          </p>
          <p>
            <b className="pl-5"></b>DMCA Counter Notification
          </p>
          <p>
            If you receive a notification of claimed infringement, you may submit a counter notification to us under the
            DMCA. It must include the following, which includes a certification made under penalty of perjury:
          </p>
          <ol type="i">
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              Your physical or electronic signature, as well as information sufficient for Fortress to determine the
              legitimacy of the signature and the identity of the signatory;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              Identification of the material that has been removed or to which access has been disabled and the location
              at which the material appeared before it was removed or access to it was disabled;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              A statement under penalty of perjury that you have a good faith belief that the material was removed or
              disabled as a result of mistake or misidentification of the material to be removed or disabled; and
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the
              Federal District court (i) in the judicial district where your address is located if the address is in the
              United States, or (ii) located in the Middle District of Florida, if your address is located outside the
              United States, and that you will accept service of process from the Complainant submitting the notice or
              the Complainant’s authorized agent.
            </li>
          </ol>
          <p>Any such counter notification must be sent to:</p>
          <p className="pl-5"> Fortress Copyright Agent</p>
          <p className="pl-5"> Losey PLLC</p>
          <p className="pl-5"> 1420 Edgewater Drive</p>
          <p className="pl-5"> Orlando, FL 32804</p>
          <p className="pl-5"> dmca@losey.law</p>
        </h5>
      </div>
    </div>
  </React.Fragment>
);

export default dcmaNotice;
