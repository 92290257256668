import React from 'react';
import { Link } from 'react-router-dom';
import { useUpdateState } from '../../';
import config from '../../../config';
import { assessmentTypes } from '../../../constants/assessmentTypes';
import CatalogReportGenericInfo from './catalogReportGenericInfo';
import './productModal.css';

const BottomModalStage1Products = (props) => {
  const [state, setState] = useUpdateState({
    requestedProductReports: {
      requestedProductArray: [],
    },
    hasErrors: {
      hasReport: false,
    },
  });

  const handleKeydownCheckbox = (data) => {
    return (event) => {
      if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
        event.preventDefault();
        props.handleCheckbox(data);
      }
    };
  };

  const authorizeNextStage = (topModalState, name, domain, productName) => {
    let returnValue = true;

    if (name.length === 0) {
      props.setInputError('name', true);
      returnValue = false;
    } else {
      props.setInputError('name', false);
    }

    let reportCount = 0;

    let requestedReports = props.requestedReports;

    Object.keys(requestedReports).map((report) => {
      reportCount += requestedReports[report];
    });

    if (reportCount === 0 && !props.hasProductReports) {
      setState({ hasErrors: { hasReport: true } });
      return false;
    }

    setState({ hasErrors: { hasReport: false } });

    let arrayOfRequestedReports = [];
    Object.keys(requestedReports).map((reportType) => {
      arrayOfRequestedReports.push(reportType);
    });

    const productImageFile = new File([props.modalProductData.image], productName + '.png');

    let requestedProductReports = state.requestedProductReports;

    requestedProductReports['requestedProductArray'].push({
      productName: productName,
      fileArray: [productImageFile],
      reportsArray: arrayOfRequestedReports,
    });

    setState({ requestedProductReports: requestedProductReports });
    return returnValue;
  };

  const {
    requestedReports,
    handleCheckbox,
    name,
    topModalState,
    domain,
    reportDescriptions = {},
    reportOfferings = {},
    downloadableReports = {},
    freeReports = {},
    modalProductData = {},
    setParentState = () => {},
    setBottomModalState,
  } = props;
  const { assetReports = [] } = reportOfferings;
  const { hasErrors, requestedProductReports } = state;

  if (!modalProductData) {
    return <div>Missing product data!</div>;
  }

  return (
    <div className="modal-report-info-box">
      <p className="modal-report-info-title">Assessments &amp; Reports</p>
      <p className="modal-report-info-description">
        Disclaimer: Turntime of assessments and reports is dependent on availability of assessment or report, and
        pending sharing agreements with company. If the company chooses not to cooperate (for A2V), we may not be able
        to deliver the assessment.
      </p>

      {hasErrors.hasReport && <p className="m-error">Please select a report to request</p>}
      <p className="report-section-title mt-4">
        <i className="fas fa-check"></i> &nbsp; Purchased
      </p>
      <p className="report-section-title">
        <i className="fas fa-bolt"></i> &nbsp; Available immediately
      </p>
      <div className="modal-report-info-list">
        <div className="product-image-name">
          <div className="product-image-wrapper">
            <img
              src={config.api.urlFor('productImage', { id: modalProductData.id })}
              alt={
                modalProductData.name.length > 18
                  ? modalProductData.name.substring(0, 15) + '...'
                  : modalProductData.name
              }
              className="logo product-image"
            />
          </div>
          <div className="modal-vendor-name product-name">{modalProductData.name}</div>
        </div>
        <div className="product-description-link-wrapper">
          <div className="modal-vendor-name product-description-link">
            <div className="m-bold">Product Description</div>
            {modalProductData.description}
          </div>
          {!!(downloadableReports[`${assessmentTypes.DDPA.name}`] && modalProductData.name) && (
            <div className="modal-vendor-name product-description-link">
              <span className="m-bold">Link to DDPA Report </span>
              <br></br>
              <span className="product-link">
                <Link to={`/reports/postern/ddpa?name=${modalProductData.name}`}>DDPA Report</Link>
              </span>
            </div>
          )}
        </div>
        <div className="asset-reports-list">
          <p className="report-section-title">Product Reports</p>
          {assetReports.length ? (
            assetReports.map((data, i) => {
              const purchaseDate = freeReports[`${data}`] ? freeReports[`${data}`] : null;
              return (
                <div
                  className="modal-report-info product-reports"
                  onClick={() => handleCheckbox(data)}
                  onKeyDown={handleKeydownCheckbox(data)}
                  tabIndex={0}
                  key={i}
                >
                  <input
                    className="modal-report-tick-box"
                    type="checkbox"
                    checked={requestedReports[data] || false}
                    tabIndex={-1}
                    readOnly
                  />
                  <CatalogReportGenericInfo
                    title={data}
                    reportDescriptions={reportDescriptions}
                    purchaseDate={purchaseDate}
                  />
                  {downloadableReports[`${data}`] ? <i className="fas fa-bolt"></i> : null}
                  {purchaseDate ? <i className="fas fa-check"></i> : null}
                </div>
              );
            })
          ) : (
            <p>No product reports found for {name}</p>
          )}
        </div>
      </div>
      <span>
        <button
          onClick={() => {
            if (authorizeNextStage(topModalState, name, domain, modalProductData.name)) {
              setBottomModalState('NEXT');
              setParentState('requestedProductReports', requestedProductReports);
            }
          }}
          className="modal-report-info-btn btn"
        >
          Add to Cart
        </button>
      </span>
      {/* <p className="modal-report-info-email-note">Please email sales@fortressinfosec.com to purchase an assessment.</p> */}
      {/* <button className="btn btn-dark" onClick={() => setShowModal(false)}>Close</button> */}
    </div>
  );
};

export default BottomModalStage1Products;
