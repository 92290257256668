import React from 'react';

const clRed = '#F81818';
const clPaleRed = '#F4CCCC';
const clOrange = '#FF7800';
const clPaleOrange = '#FCE5CD';
const clYellow = '#F1C232';
const clPaleYellow = '#FFF2CC';
const clGreen = '#31AF31';
const clPaleGreen = '#D9EAD3';
const clGrey = '#999999';
const clPaleGrey = '#D9D9D9';

const FindingsSummaryItem = (props) => {
  const label = String(props.label).toLowerCase();
  let mainColor, paleColor;

  switch (label) {
    case 'critical':
      mainColor = clRed;
      paleColor = clPaleRed;
      break;
    case 'high':
      mainColor = clOrange;
      paleColor = clPaleOrange;
      break;
    case 'moderate':
      mainColor = clYellow;
      paleColor = clPaleYellow;
      break;
    case 'low':
      mainColor = clGreen;
      paleColor = clPaleGreen;
      break;
    default:
      mainColor = clGrey;
      paleColor = clPaleGrey;
      break;
  }

  const itemStyle = {
    color: 'black',
    backgroundColor: paleColor,
    padding: '6px',
    margin: '6px 0px',
    borderLeft: '6px solid ' + mainColor,
    height: '38px',
  };
  const buttonStyle = {
    color: 'white',
    backgroundColor: mainColor,
    borderColor: mainColor,
    borderRadius: '6px',
    float: 'right',
    cursor: 'pointer',
    padding: '2px 4px',
  };

  return (
    <div style={itemStyle}>
      <b>{props.children}</b>
      {props.showingVulns ? (
        <div
          style={buttonStyle}
          onClick={() => props.columnSortHandler(props.label.toLowerCase() === 'moderate' ? 'medium' : props.label)}
        >
          View Finding
        </div>
      ) : (
        <div style={buttonStyle} onClick={() => document.getElementById(props.tagId).scrollIntoView()}>
          View Finding
        </div>
      )}
    </div>
  );
};

export default FindingsSummaryItem;
