import React from 'react';
// import YoutubeEmbed from './YoutubeEmbed';
import WistiaEmbed from './WistiaEmbed';

const EmbeddedVideoBox = (props) => {
  const { title, embedID } = props;
  return (
    <div className="video-flex-item">
      <h4>{title}</h4>
      {/* <YoutubeEmbed id={embedID}></YoutubeEmbed> */}
      <WistiaEmbed id={embedID}></WistiaEmbed>
    </div>
  );
};

export default EmbeddedVideoBox;
