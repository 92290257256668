import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { tabToPath } from '../lib/utils';

class TabItem extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this;

    const btnStyle = {
      fontSize: '1rem',
      fontWeight: '700',
      borderRadius: '5px',
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    };

    return (
      <li
        className={'tab-list-item' + (activeTab === tabToPath(label) ? ' tab-list-active' : '')}
        style={btnStyle}
        onClick={onClick}
      >
        {label}
      </li>
    );
  }
}

TabItem.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TabItem;
