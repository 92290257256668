import React from 'react';

import config from '../../config';

import { Table } from '../../components';

class RiskHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      lineChart: {
        id: 'lineChart',
        icon: 'fas fa-chart-area',
        label: 'Risk History',
        height: '200',
      },
    };
  }

  componentDidMount() {
    this.getRiskOverTime();
  }

  getRiskOverTime() {
    const _self = this;
    _self.setState({ isBusy: true });

    fetch(config.api.urlFor('riskOverTime', { tier: 'global' }))
      .then((response) => response.json())
      .then((data) => {
        let map = data.map((row) => ({ risk: row.risk, time: new Date(row.date_finished).toLocaleDateString() }));

        _self.setState({ riskOverTime: map, isBusy: false });
      })
      .catch((err) => console.log(err.stack));
  }

  render() {
    const { riskOverTime } = this.state;

    return (
      <React.Fragment>
        <h1>Risk History</h1>
        <div className="row">
          <div className="col-12">
            <div className="card o-hidden h-100">
              <div className="card-header">
                <i className={`fa-fw fas fa-exclamation-triangle`}></i> Providers by Risk
              </div>
              <div className="card-body">
                <Table headers={['Risk', 'Time']} data={riskOverTime} isBusy={this.state.isBusy} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiskHistory;
