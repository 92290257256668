import React, { Component } from 'react';
import SeverityFlag from './../../../reusable/severityFlag';
import { assessmentTypes } from '../../../../../constants/assessmentTypes';

import './printCoverPage.css';

export default class PrintCoverPage extends Component {
  render() {
    const { vendor = {}, category = [], riskCountryData = [], safeCountryData = [] } = this.props;
    const {
      scoreDate,
      companyName,
      companyLogo,
      scoreIndustryName,
      naicsDescription,
      revenue,
      employeeCount,
      companyDescription,
      manualReviewResult,
    } = vendor;
    const date = new Date();
    const lastDate = new Date(scoreDate);

    const renderHighRiskAreas = () => {
      let hasHighRisk = false;
      category.forEach((area) => {
        if (area.riskscoretext) {
          if (area.riskscoretext.toLowerCase() === 'high') {
            hasHighRisk = true;
          }
        }
      });

      if (hasHighRisk) {
        return category.map((area, i) => {
          if (area.riskscoretext) {
            if (area.riskscoretext.toLowerCase() === 'high') {
              if (area.riskCategory == 'Application/Hardware') {
                return (
                  <span key={area + i} className="m-auto-c">
                    System Access
                  </span>
                );
              } else
                return (
                  <span key={area + i} className="m-auto-c">
                    {area.riskCategory}
                  </span>
                );
            } else return '';
          }
        });
      }
      return <span className="m-auto-c">No specific category labeled high risk</span>;
    };

    return (
      <div id="ddrr-print-cover-page">
        <div className="report-info-div">
          <div className="report-info">
            <div className="vendor-name">{companyName}</div>
            <div className="report-name">{assessmentTypes.DDRR.name}</div>
            <div className="last-reported">Last reported: {lastDate.toLocaleDateString()}</div>
            <div className="printed-date">Printed Date: {date.toLocaleDateString()}</div>
          </div>
        </div>

        <br />
        <img className="ddrr-vendor-logo" src={companyLogo} alt="Vendor Logo" />
        <h4>
          <b>{scoreIndustryName}</b>
          <br />
        </h4>
        <p>
          NAICS: {naicsDescription}
          <br />
          <small>
            <i>
              Note: actual scope of services consumed may differ
              <br />
              from the company&apos;s primary NAICS code.
            </i>
          </small>
        </p>
        <p>
          Revenue: ${revenue} <br /> Employees: {employeeCount}
        </p>
        <p className="company-description">{companyDescription}</p>
        <div id="executive-order-status-print">
          <div className="compliance-alert-heder">Compliance Alerts: </div>
        </div>

        <div className="print-table">
          <div className="print-row">
            <p className="print-col-sm-1 m-tal-c">
              <strong>Inherent Risk Score*</strong>
            </p>
            <p className="print-col-sm-1 m-tal-c">
              <strong>High Risk Factors</strong>
            </p>
          </div>
          <div className="print-row">
            <div id="ddrr-inherent-risk-score-print-td" className="print-col-sm-1">
              <div id="ddrr-inherent-risk-score-print" className="print-col-align"></div>
            </div>
            <div className="print-col-sm-1">
              <span className="m-auto-c m-tal-c manual-option-text print-col-align">
                {manualReviewResult}
                <br />
              </span>
              {renderHighRiskAreas()}
            </div>
          </div>
        </div>
        <p className="ddrr-cover-risk-footnote">
          *Inherent risk is an indicator of predicted business impact risk (i.e., the consequences of a potential
          security incident). This should be evaluated separately from likelihood risk (i.e., the chance of a security
          incident occurring).
        </p>

        {riskCountryData.length > 0 || safeCountryData.length > 0 ? (
          <React.Fragment>
            <span>Vendor&apos;s presence:</span>
            <br />
            {riskCountryData.length > 0 && (
              <React.Fragment>
                <SeverityFlag color={'#e31a1c'} />
                {riskCountryData.map((row, i) => {
                  if (i == riskCountryData.length - 1) {
                    return i == 0 ? ` ${row.countryName}` : `and ${row.countryName}`;
                  } else return ` ${row.countryName},`;
                })}
              </React.Fragment>
            )}
            <br />
            {safeCountryData.length > 0 && (
              <React.Fragment>
                <SeverityFlag color={'#1ab394'} />
                {safeCountryData.map((row, i) => {
                  if (i == safeCountryData.length - 1) {
                    return i == 0 ? ` ${row.countryName}` : `and ${row.countryName}`;
                  } else return ` ${row.countryName},`;
                })}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          ''
        )}
      </div>
    );
  }
}
