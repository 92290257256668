import React from 'react';
import { withRouter } from 'react-router-dom';
import TabList from '../tabList';
import withPrincipal from '../withPrincipal';
import InfoHygiene from './infoHygiene';

import ForeignInfluenceSummary from './foreignInfluenceTabs/foreignInfluenceSummary';
import Manufacturing from './foreignInfluenceTabs/manufacturing';
import PhysicalPresence from './foreignInfluenceTabs/physicalPresence';
import CyberPresence from './foreignInfluenceTabs/cyberPresence';
import MergersAcquisitions from './foreignInfluenceTabs/mergersAcquisitions';
import ForeignOwnership from './foreignInfluenceTabs/foreignOwnership';
import CorporateFamilies from './foreignInfluenceTabs/corporateFamilies';

import './foreignInfluenceContent.css';
import SectionContainer from '../sectionContainer';
import SectionLocked from '../sectionLocked';

/** Functional component for displaying Foreign Influence data in a tabular format */
const ForeignInfluenceContent = (props) => {
  //Props
  const {
    isLoadingVendorRisk = true,
    provenanceData = {},
    vendorData,
    history,
    pathBase,
    isUnlockedCMRE,
    reportTypeCMRE,
    handleUnlockButtonClick,
    canPurchaseReports,
    hasData,
  } = props;

  const {
    mergerAcquisition = [],
    foreignOwnership = [],
    corporateFamilies = [],
    scores = {},
    manufacturing = [],
    physicalPresence = [],
    cyberPresence = [],
    watchlist = [],
    summary = {},
  } = provenanceData;

  //Prop-Dependant Consts
  const FIPathAppend = 'ForeignInfluence';
  const FIPathBase = pathBase + (pathBase.endsWith('/') ? '' : '/') + FIPathAppend;

  const isLoading = !(Object.keys(summary).length > 0);
  const currentSelectedTab = props.match.params.selectedTab;
  const isTabActive = currentSelectedTab === FIPathAppend;

  //State
  const [forceSelected, setForceSelected] = React.useState(
    currentSelectedTab === FIPathAppend ? props.match.params.subsection : undefined,
  );

  /* ----------------------------------- */
  /*              Effects                */
  /* ----------------------------------- */
  // Note: Both effects relate to updating and mainting the URL and selected tab for this content display

  React.useEffect(() => {
    const subsection = props.match.params.subsection;

    if (props.match.params.selectedTab === FIPathAppend) {
      if (subsection && subsection !== forceSelected) {
        setForceSelected(subsection);
      } else if (!props.match.params.subsection) {
        history.replace(FIPathBase + '/' + (forceSelected || 'Summary'));
      }
    }
  }, [props.match.params.selectedTab]);

  React.useEffect(() => {
    const subsection = props.match.params.subsection;
    if (props.match.params.selectedTab === FIPathAppend && subsection && subsection !== forceSelected) {
      setForceSelected(subsection);
    }
  }, [props.match.params.subsection]);

  /* ----------------------------------- */
  /*             Render                  */
  /* ----------------------------------- */

  return (
    <div id="fi-content">
      <InfoHygiene isLoadingVendorRisk={isLoadingVendorRisk} vendorData={vendorData}>
        {isUnlockedCMRE ? (
          hasData ? (
            <TabList forceSelected={forceSelected} history={history} pathBase={FIPathBase}>
              <div label="Summary">
                <ForeignInfluenceSummary
                  manufacturing={manufacturing}
                  physicalPresence={physicalPresence}
                  cyberPresence={cyberPresence}
                  foreignOwnership={foreignOwnership}
                  corporateFamilies={corporateFamilies}
                  watchlist={watchlist}
                  summary={summary}
                  scores={scores}
                  isLoading={isLoading}
                  isActive={isTabActive && forceSelected === 'Summary'}
                />
              </div>
              <div label="Cyber Presence">
                <CyberPresence
                  cyberPresence={cyberPresence}
                  vendorName={vendorData.name}
                  highRisks={Object.keys(summary)}
                  isLoading={isLoading}
                  isActive={isTabActive && forceSelected === 'CyberPresence'}
                />
              </div>
              <div label="Physical Locations">
                <PhysicalPresence
                  physicalPresence={physicalPresence}
                  isLoading={isLoading}
                  isActive={isTabActive && forceSelected === 'PhysicalLocations'}
                />
              </div>
              <div label="Corporate Families">
                <CorporateFamilies
                  corporateFamilies={corporateFamilies}
                  corporateFamiliesScore={scores.corporateFamilies}
                  isLoading={isLoading}
                  isActive={isTabActive && forceSelected === 'CorporateFamilies'}
                />
              </div>
              <div label="Foreign Ownership">
                <ForeignOwnership
                  foreignOwnership={foreignOwnership}
                  foreignOwnershipScore={scores.foreignOwnership}
                  isLoading={isLoading}
                  isActive={isTabActive && forceSelected === 'ForeignOwnership'}
                />
              </div>
              <div label="Mergers &amp; Acquisitions">
                <MergersAcquisitions
                  mergerAcquisition={mergerAcquisition}
                  mergerAcquisitionScore={scores.mergerAcquisition}
                  isLoading={isLoading}
                  isActive={isTabActive && forceSelected === 'Mergers&Acquisitions'}
                />
              </div>
              <div label="Manufacturing">
                <Manufacturing
                  manufacturing={manufacturing}
                  manufacturingScores={scores.manufacturing}
                  isLoading={isLoading}
                  isActive={isTabActive && forceSelected === 'Manufacturing'}
                />
              </div>
            </TabList>
          ) : (
            <div className="summary-section-container">
              <div className="section-inner-title animated-ellipsis">Currently Processing</div>
              <div className="section-info-text">Continuous Monitoring of {vendorData.name} is in progress.</div>
            </div>
          )
        ) : (
          <SectionContainer label="Foreign Influence Alerts">
            <SectionLocked
              title="Unlock Foreign Influence Alerts"
              buttonCaption="Unlock Foreign Influence Alerts"
              info={`Subscribe to Continuous Monitoring for ${vendorData.name} by purchasing a Continuous Monitoring Report - Enhanced.`}
              details="Be alerted to events that may increase the risk of doing business with your vendors:"
              bullets={['Compromise', 'Compliance', 'Foreign Influence', 'Mergers & Acquisitions']}
              canUnlockSection={canPurchaseReports}
              handleUnlockButtonClick={handleUnlockButtonClick}
              reportType={reportTypeCMRE}
            />
          </SectionContainer>
        )}
      </InfoHygiene>
    </div>
  );
};

export default withPrincipal(withRouter(ForeignInfluenceContent));
