import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import config from '../../../config';
import * as TrustCenterAction from './actions';
import { useTrustCenterStore } from './context';
import { emailIsValid, toastSuccess, toastError } from '../../../lib/utils';
import { Spinner } from '../..';

const ShareFileModal = (props) => {
  //Context
  const { store, dispatch } = useTrustCenterStore();
  const { shareFileModalFileID } = store;

  //Props
  const { setReloadFilesFlag } = props;

  //State
  const [userEmailAddress, setUserEmailAddress] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);

  //Effects
  useEffect(() => {
    if (shareFileModalFileID) {
      setUserEmailAddress(userEmailAddress);
      setIsSubmitted(false);
      chkSubmitEnabled();
    }
  }, [userEmailAddress, shareFileModalFileID]);

  //Functions
  const chkSubmitEnabled = () => {
    setIsEnabled(userEmailAddress.length > 0);
  };

  const submitRequest = (event) => {
    event.preventDefault();

    setIsEnabled(false);
    setIsSubmitted(true);

    if (!emailIsValid(userEmailAddress)) {
      setIsValidEmailAddress(false);
      setIsSubmitted(false);
    } else {
      fetch(config.api.urlFor('inviteGuest'), {
        method: 'POST',
        body: {
          fileID: shareFileModalFileID,
          email: userEmailAddress,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          chkSubmitEnabled();
          setIsSubmitted(false);

          if (res && res.isSuccess) {
            handleModalClose();
            setReloadFilesFlag(true);
            toastSuccess('Sending Invitation Succeeded.');
          } else {
            toastError('Sending Invitation Failed.');
          }
        })
        .catch(() => {
          chkSubmitEnabled();
          setIsSubmitted(false);
          toastError('An error occurred. Please try again and contact support if you encounter additional issues.');
        });
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter' && isEnabled) {
      submitRequest(event);
    }
  };

  const handleModalClose = () => {
    setUserEmailAddress('');
    setIsValidEmailAddress(true);
    chkSubmitEnabled();
    dispatch(TrustCenterAction.toggleShareFileModal());
  };

  const onEmailValueChange = (event) => {
    const userEmailAddress = event.target.value;
    userEmailAddress.length <= 160 ? setUserEmailAddress(userEmailAddress) : null;
    setIsValidEmailAddress(true);
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={shareFileModalFileID ? true : false}
      className="sa-modal-style col-md-3"
      contentLabel="Share This File"
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
    >
      <h5>
        <b>Share This File</b>
      </h5>
      <hr />
      <h6 className="ml-1">
        <b>Invite Person</b>
      </h6>
      <form data-test-id="ShareFile-modal-1">
        <div className="sa-modal-btn-container">
          <div className="sa-modal-close" onClick={handleModalClose}>
            x
          </div>
        </div>
        <div className="sa-entry-form">
          <div className="pb-3">
            <input
              type="text"
              placeholder="Enter A2V Member's Email"
              id="ShareFile-userEmailAddress"
              className="form-control sa-modal-input"
              required="required"
              name="user_email_address"
              onChange={(e) => onEmailValueChange(e)}
              value={userEmailAddress}
              maxLength={160}
              onKeyPress={handleEnterKeyPress}
            />
            {!isValidEmailAddress && <span className="m-error">Please enter a valid email address.</span>}
          </div>
        </div>

        <div className="sa-modal-btn-container">
          <div className="sa-modal-btns-right">
            <button
              data-test-id="ShareFile-button-cancel"
              className="btn btn-outline-primary btn-nav sa-modal-cancel-btn"
              onClick={handleModalClose}
            >
              Cancel
            </button>
            <button
              data-test-id="ShareFile-button-send"
              className="btn btn-outline-primary btn-nav sa-modal-submit-btn"
              onClick={submitRequest}
              disabled={!isEnabled || isSubmitted}
            >
              Send Invite
              <Spinner style={{ marginLeft: '5px' }} isVisible={isSubmitted} />
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ShareFileModal;
