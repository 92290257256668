import {
  instances,
  requestBanner,
  contactSupport,
  passwordRequiredMinLength,
  shouldAgreeToAccessConditionsToLogIn,
} from './config.local';

/**
 * @file Manages api endpoint configuration based upon environment that code is running in.
 * @copyright Copyright Fortress Information Security, LLC, 2015-2018.  All rights reserved.
 * All information within this electronic file is and remains the property of Fortress Information Security, LLC.
 * The work contained herein is proprietary to Fortress Information Security, LLC and is considered trade secrets and/or confidential.
 * Reproduction or distribution, in whole or in part, is forbidden unless prior written permission is obtained from Fortress Information Security, LLC.
 */

const hostname = window && window.location && window.location.hostname;

let backendHost = (instances.find((i) => i.host === hostname) || { api: process.env.REACT_APP_BACKEND_HOST || '' }).api,
  downloadHost =
    (instances.find((i) => i.host === hostname) || { download: process.env.REACT_APP_DOWNLOAD_HOST }).download ||
    backendHost;

/**
 * @description Returns the root fqdn of the application API endpoint
 * @namespace
 * @module api_config/API_ROOT
 * @property {string} API_ROOT - A variable that contains the root fqdn of the api
 * @public
 * @returns API Root Hostname
 */
export const API_ROOT = `${backendHost}`;

/**
 * @namespace
 * @property {object} api - API object that stored API configs
 * @property {string} baseURL - API base url based of of API_ROOT
 * @property {string} 'login.google' - Google Login API Endpoint
 * @property {string} 'login.local' - Internal Login API Endpoint
 * @property {string} 'scores.orgs' - API Endpoint that returns Scores for all Orgs
 * @property {string} 'scores.orgs.overview' - API endpoint that returns overview data for Scorecard
 * @property {string} 'organizations.orgs' - API endpoint that returns Paginated list of Overseer Organizations
 * @property {string} 'targets.targets' - API endpoint that returns the targets of a specific organization
 * @property {string} 'client.errors' = API endpoint to ship client errors to for logging and forwarding to splunk
 * @property {string} 'import.status' = API endpoint to get import status for progress bar
 * @public
 * @module api_config/config
 * @description Used for providing api endpoint to be attached to any api Fetch request
 */
export const config = {
  api: {
    baseURL: API_ROOT,
    assessmentByRequest: '/assessment/by-request/:assessmentRequestID/data',
    breachMonitoring: '/postern/provenance/breaches/:domain',
    posternProvenance: '/postern/provenance/:vendorID',
    posternProvenanceScores: '/postern/provenance/scores/:vendorID',
    posternDDRRByDDRRID: '/postern/ddrr/lookup/by-ddrr-id/:id',
    posternDDRRByVendorID: '/postern/ddrr/lookup/by-vendor-id/:id',
    posternDDPA: '/postern/ddpa/:name',
    posternDDRR: '/postern/ddrr/:name/:domain',
    posternDDRRviaName: '/postern/ddrr/:name',
    posternProxySBOM: '/postern/sbom/:id',
    posternProxySBOMHTML: '/postern/sbom/:id/html',
    posternProxyCMRE: '/postern/cmre/:domain',
    forgotPassword: '/forgotPassword/',
    updatePassword: '/forgotPassword/updatePassword',
    verifyPasswordToken: '/forgotPassword/checkTokenTime',
    login: '/login/local/auth',
    logout: '/login/local/deauth',
    providersCsv: '/csv/providers',
    providersByPortfolioCsv: '/csv/providers/:portfolio',
    users:
      '/admin/users?search=:search&page=:page&pageSize=:pageSize&orderBy=:orderBy&asc=:asc&showDeleted=:showDeleted',
    usersWithRoles:
      '/admin/users/with-roles?search=:search&page=:page&pageSize=:pageSize&orderBy=:orderBy&asc=:asc&subscriberID=:subscriberID&showDeleted=:showDeleted',
    userDetails: '/admin/users/:id',
    userSelf: '/admin/users/self',
    saveUser: '/admin/users',
    grantRoles: '/admin/users/:id/grant-roles',
    inviteUser: '/admin/invitations/invite',
    inviteGuest: '/admin/invitations/invite-guest',
    invitations: '/admin/invitations',
    sendInvitation: '/admin/invitations/send/:id',
    resendInvitation: '/admin/invitations/resend/:userID',
    setPassword: '/login/setPassword',
    verifyInviteToken: '/register/verify-invite',
    generate2fa: '/register/qr-code/:id',
    finishRegistration: '/register/finish',
    check2fa: '/login/2fa',
    revokeRoles: '/admin/users/:id/revoke-roles',
    roles: '/admin/roles?search=:search&page=:page&pageSize=:pageSize&orderBy=:orderBy&asc=:asc',
    roleDetails: '/admin/roles/:id',
    userRoles: '/admin/roles/listByUserID/:id?page=:page',
    saveRole: '/admin/roles',
    catalogAvailableReports: '/assessment/vendor/availability/:id',
    catalogAvailableProductReports: '/assessment/product/availability/:id',
    catalogReportTypes: '/assessment/types',
    permissions: '/admin/permissions',
    permissionDetails: '/admin/permissions/:id',
    rolePermissions: '/admin/permissions/listByRoleID/:id?page=:page',
    savePermission: '/admin/permissions',
    grantPermissions: '/admin/roles/grantPermissions',
    revokePermissions: '/admin/roles/revokePermissions',
    principal: '/login/principal',
    organizations: '/api/scores/orgs?search=:search&page=:page&pageSize=:pageSize&orderBy=:orderBy&asc=:asc',
    scores: '/scores/:tier/aggregate',
    operationalScores: '/opscores/:tier',
    operationalScoresByVendor: '/opscores/vendor/:id',
    riskOverTime: '/reports/riskOverTime/:tier',
    scoresOverTime: '/reports/scoresOverTime/:tier',
    targets: '/targets/:tier',
    // DDRR Routes
    ddrrContainer: '/ddrr/container/:id',
    ddrrOrganization: '/ddrr/organization/:id',
    ddrrVendor: '/ddrr/vendor/:offset/:limit',
    // Provider Routes
    providerDisputes: '/providers/dispute',
    allProviders: '/providers/all',
    providerRequests: '/providers/newRequest',
    inviteProvider: '/admin/invitations/enqueue',
    providerGeoIP: '/providers/geoIP/:id',
    providerGeoIPCsv: '/csv/geoIP/:id',
    providerGeoIPTable: '/providers/geoIPLocations/:id',
    provider: '/providers/:id',
    providers: '/providers',
    providers4p: '/providers/:id/4p',
    providersAssets: '/providers/:id/assets',
    providersAllAssets: '/providers/:id/allAssets',
    providersAssetsCsv: '/csv/assets/:id',
    providersCertificates: '/providers/:id/certificates',
    providersCertificatesCsv: '/csv/certificates/:tier/:id',
    providerScores: '/scores/providers?page=:page',
    portfolioNames: '/providers/portfolioNames',
    subscribers: '/admin/subscriptions',
    subscribe: '/admin/subscriptions/subscribe',
    saveSubscriber: '/admin/subscriptions/:subscriberID',
    subscriberApiKey: '/admin/subscriptions/apikey',
    subscriberById: '/admin/subscriptions/:id',
    claimedDDRRidsBySubscriberID: '/admin/subscriptions/:id/claimed-ddrr-ids',
    claimVendorPageOptions: '/admin/subscriptions/claim-vendor-page/options',
    subscriberClaimDDRRid: '/admin/subscriptions/:id/claim-ddrr-id',
    subscriberRemoveDDRRid: '/admin/subscriptions/:id/remove-ddrr-id/:ddrrID',
    subscriberAdjustTokens: '/admin/subscriptions/:id/adjust-tokens',
    subscriberFeatures: '/admin/subscriptions/:id/features',
    subscriberFeaturesManage: '/admin/subscriptions/:id/features/:featureID',
    subscriberTypesList: '/admin/subscriptions/sub-types-list',
    updateSubscriber: '/admin/subscriptions/update/:id',
    deleteSubscriber: '/admin/subscriptions/delete/:id',
    scans: '/reports/scansBySubscriber/:id',
    findingsCsv: '/csv/findings/:tier/:score',
    scanData: '/data/:tier/:score',
    scanDataCsv: '/csv/data/:tier/:score',
    scanDatafindings: '/findings/by-vendor/:id',
    launchQuickScan: '/findings/scan/by-vendor/:id',

    subscriberFindings: '/data-driven-findings/by-subscriber',
    subscriberFindingsOptions: '/data-driven-findings/by-subscriber/options/:type',
    vendorFindings: '/data-driven-findings/by-vendor/:id',

    scanDataStats: '/data/findings/stats/aggregate',
    providersByRisk: '/reports/providersByRisk',
    providerCountsByRisk: '/reports/providerCountsByRisk',
    providerCountsByRiskAndPortfolio: '/reports/providerCountsByRiskAndPortfolio',
    // lookerList: '/reports/looker/list',
    threatReportJSON: '/reports/threatReportJSON',
    breachReportJSON: '/reports/breachReportJSON',
    specialReportJSON: '/reports/specialReportJSON',
    cmrReportJSON: '/reports/cmrReportJSON',
    threatReportList: '/reports/threatReportList',
    reportPresignedURL: '/reports/reportPresignedURL/:fileName',
    vulnerabilities: '/vulnerabilities/:tier?page=:page&pageSize=:pageSize',
    vulnerabilitiesCsv: '/csv/vulnerabilities/details/:id',
    vulnerabilitiesCve: '/vulnerabilities/cve/rollup/:id',
    vulnerabilitySummary: '/vulnerabilities/:tier/summary?page=:page&pageSize=:pageSize',
    vulnerabilitySummaryCsv: '/csv/vulnerabilities/:tier/:id',
    targetGroups: '/admin/targetGroups',
    targetGroupsEdit: '/admin/targetGroups/edit/:id',
    targetGroupsDelete: '/admin/targetGroups/delete/:id',
    targetGroupsProvider: '/admin/targetGroups/provider',
    targetGroupsProviders: '/admin/targetGroups/provider/:id',
    targetGroupsAsset: '/admin/targetGroups/assets',
    targetGroupsAssets: '/admin/targetGroups/assets/:id',
    // Help Routes
    contact: '/register/contact',
    requestSupport: '/help/requestSupport',
    // A2V Routes
    productDropdown: '/products/enumerations/:dropdown/:vendorID',
    product: '/products/:id',
    productsByVendor: '/products/vendor/:vendorId',
    products: '/products',
    productImage: {
      base: downloadHost,
      path: '/products/:id/image',
    },
    productContributors: '/products/:id/contributors',
    productsByProvider: '/products/provider/:id',
    productScores: '/products/:productID/scores',
    productVersions: '/products/:productID/versions',
    sbomUpload: '/products/sbom/upload',
    sbomDelete: '/products/sbom/delete',
    sbomReceiptsByHash: '/products/sbom/receipts/by-hash',
    sbomByProductID: '/products/sbom/:productID',
    getSbomAccessRecord: '/products/get-sbom-access/:hash',
    createSbomAccessRecord: '/products/create-sbom-access',
    vexUpload: '/products/vex/upload',
    vulnerabilitiesA2V: '/vulnerabilities/:tier?page=:page&pageSize=:pageSize&id=:id',
    vendor: '/vendors/:id',
    vendorDdrrSummary: '/vendors/ddrr/summary/:id',
    vendorByName: '/vendors/name/:name',
    vendorHome: '/catalog/vendor',
    vendorSharingPropensity: '/vendors/sharing-propensity/:id',
    vendorClaimStatus: '/vendors/is-claimed-with-admins',
    vendors: '/vendors',
    catalog: '/catalog',
    catalogProducts: '/catalog/products',
    catalogSubmitRequest: '/catalog/request-reports',
    claimCatalogPage: '/catalog/claim-page',
    requestPageUpdates: '/catalog/request-page-updates',
    catalogCreateNewVendor: '/catalog/create-new-vendor',
    // myVendors: '/vendors/my',
    vendorCSVUpload: '/imports/upload',
    vendorCSVProcess: '/imports/process',
    vendorCSVCommit: '/imports/commit',
    vendorCSVList: '/imports?page=:page',
    vendorCSVResults: '/imports/results/:id?page=:page',
    vendorRisks: '/scores/vendor/risks',
    vendorScores: '/scores/vendor/:id',
    vendorGeoIP: '/vendors/geo-ip/by-ddrr-id/:id',
    vendorGeoIPTable: '/vendors/geo-ip-locations/by-ddrr-id/:id',
    vendorGeoIPCsv: '/csv/geo-ip/by-ddrr-id/:id',
    selectImportMatch: '/imports/select/:importID',
    pdfFromS3: '/reports/pdf/:key',
    assessment: '/assessment',
    assessmentMetadata: '/assessment/metadata/:id',
    assessmentRequestData: '/assessment/assessmentRequest/:assessmentRequestId',
    createAssessmentRequest: '/assessment/assessmentRequest',
    fullScanTasks: '/findings/scan-status/by-vendor/:id',
    assessmentTypes: '/assessment/types',
    allVendorsCount: '/login/all-vendors-count',
    // allProductsCount: '/login/all-products-count',   // Temporarily disabled until we have more products.
    createOrUpdateUserFromFP: '/register/fp-user/create-or-update',
    vendorCyberHygieneFindings: '/vendors/cyber-hygiene-findings',

    // Trust Center File Items
    fileItems: '/file-items/:fileID',
    fileItemsByVendor: '/polling/file-items-by-vendor/:vendorID',
    fileItemsClasses: '/file-items/classes',
    fileItemsCategories: '/file-items/categories',
    fileItemAccessRequests: '/file-items/:fileID/access-requests', // existing file
    fileItemRequest: '/file-items/request-file-item', // potentially non-existent file (cart modal flow)
    fileItemAccessRequestsByVendorID: '/polling/file-items-by-vendor/:vendorID/access-requests',
    fileItemViews: '/file-items/:fileID/views',
    fileItemDownload: '/file-items/:fileID/download',
    purchasableFileItemsClasses: '/file-items/purchasable-classes',

    // Token Transactions
    tokenTotal: '/transaction/total/:id',
    creditHistory: '/transaction/credits/:id',
    debitHistory: '/transaction/debits/:id',
    accountHistory: '/transaction/history/:id',
    createTransaction: '/transaction/create',
    tokenBalance: '/transaction/totals',
    tokenBalanceBySubscriber: '/transaction/totals/by-subscriber/:subscriberID',
    transactionList: '/transaction/list',
    transactionBreakdown: '/transaction/breakdown',
    transaction: '/transaction/:transactionID',
    transactionHistory: '/transaction/:transactionID/history',
    transactionsBySubscriber: '/transaction/by-subscriber/:subscriberID',

    // enumerations
    productVendors: '/enumerations/product/vendors',
    productLicenses: '/enumerations/product/licenses',
    productCVEs: '/enumerations/product/cves',
    productComponents: '/enumerations/product/components',

    urlFor: function (name, params) {
      params = params || {};
      const data = this[name];
      const url = data && data.path ? `${data.base || this.baseURL}${data.path}` : `${this.baseURL}${data || name}`;

      return window.mapPath(url, params);
    },

    getHostname: function () {
      return this.baseURL;
    },
  },
  googleAnalyticsID: 'UA-128986990-1',
  requestBanner: requestBanner,
  contactSupport: contactSupport,
  passwordRequiredMinLength: passwordRequiredMinLength,
  shouldAgreeToAccessConditionsToLogIn: shouldAgreeToAccessConditionsToLogIn,
  fetchTimeout: 1000 * 60 * 5,
  trustCenter: {
    fileTypes: '.csv,.doc,.docx,.dotx,.pdf,.jpg,.png,.ppt,.pptx,.tiff,.tsv,.txt,.xls,.xlsx',
  },
  nav: {
    vendors: [
      { label: 'My Vendors', to: '/vendor/my/name/asc/1' },
      { label: 'Directory', to: '/vendor/search' },
      { label: 'Catalog', to: '/catalog/name/asc/1' },
    ],
    reports: [
      { label: 'Most Vulnerable', to: '/reports/mostVulnerable' },
      { label: 'CMR Reports', to: '/reports/cmrReport' },
      { label: 'Threat Reports', to: '/reports/threatReport' },
      { label: 'Breach Reports', to: '/reports/breachReport' },
      { label: 'Special Request Reports', to: '/reports/specialRequestReport' },
    ],
    account: [
      { label: 'Your Info', to: '/account/yourinfo' },
      { label: 'Email and App Settings', to: '/account/yourinfo1' },
      { label: 'Sign-In Options', to: '/account/yourinfo2' },
      { label: 'Integration Information', to: '/account/yourinfo3' },
      { label: 'Account History', to: '/account/yourinfo4' },
      { label: 'Privacy Settings', to: '/account/yourinfo5' },
    ],
    organization: [
      { label: 'User Management', to: '/organization/userManagement' },
      { label: 'Account Balance', to: '/organization/accountBalance' },
      { label: 'Payment/Billing', to: '/organization/payment' },
      { label: 'Order History', to: '/organization/orderhistory' },
      { label: 'Credit History', to: '/organization/credithistory' },
      { label: 'Organization Settings', to: '/organization/organizationsettings' },
      { label: 'Account History', to: '/organization/accounthistory' },
      { label: 'Privacy Settings', to: '/organization/privacysettings' },
      { label: 'CSV Upload', to: '/admin/vendorUpload' }, // not in-use?
    ],
    aboutUs: { label: 'About Us', to: '/help/about' },
    contactUs: { label: 'Contact Us', to: '/help/contact' },
    network: { label: 'Network', to: '/catalog/name/asc/1' },
    products: { label: 'Products', to: '/catalog/products/name/asc/1' },
    privacyPolicy: { label: 'Privacy Policy', to: '/help/privacyPolicy' },
    termsOfUse: { label: 'Terms Of Use', to: '/help/termsOfUse' },
    copyrightNotice: { label: 'Copyright Notice', to: '/help/dmcaNotice' },
    releaseNotes: { label: 'Release Notes', to: '/help/releaseNotes' },
  },

  routes: {
    // Admin
    admin: { label: 'Admin', to: '/admin' },
    users: { label: 'Users', to: '/admin/users' },
    roles: { label: 'Roles', to: '/admin/roles' },
    providers: { label: 'Providers', to: '/admin/providers' },
    invitations: { label: 'Invitations', to: '/admin/invitations' },
    targetGroups: { label: 'Target Groups', to: '/admin/targetGroups' },
    subscribers: { label: 'Subscribers', to: '/admin/subscriptions' },

    // Subscriber
    subscriberUsers: { label: 'subscriberUsers', to: '/subscriber/users' },
    subscriberIntegrations: { label: 'Integrations', to: '/subscriber/integrations' },

    // Account
    yourinfo: { label: 'Your Info', to: '/account/yourinfo' },

    // Organization
    billing: { label: 'Account Balance', to: '/organization/accountBalance' },
    userManagement: { label: 'User Management', to: '/organization/userManagement' },

    // Reports
    posternReport: { label: 'Postern Report', to: '/reports/postern/:report' },
    cmrReport: { label: 'CMR Report', to: '/reports/cmrReport' },
    threatReport: { label: 'Threat Report', to: '/reports/threatReport' },
    breachReport: { label: 'Breach Report', to: '/reports/breachReport' },
    specialRequestReport: { label: 'Special Request Report', to: '/reports/specialRequestReport' },
    looker: { label: 'Looker Report', to: '/reports/looker/:id' },
    scoreBreakdown: { label: 'Score Breakdown', to: '/reports/score/:tier/:score' },
    opScoreBreakdown: { label: 'Op Score Breakdown', to: '/reports/opscore/:tier/:score' },
    providerTargets: { label: 'Provider Targets', to: '/reports/provider/:id/targets' },
    providerReport: { label: 'Provider Report', to: '/reports/provider/:id' },
    vulnerabilities: { label: 'Vulnerabilities', to: '/reports/vulnerabilities/:tier/:page' },
    mostVulnerable: { label: 'Most Vulnerable', to: '/reports/mostVulnerable' },
    riskHistory: { label: 'Risk History', to: '/reports/risk/history' },
    vendorsRisk: { label: 'Vendors Risk', to: '/reports/vendorRisk/:risk?/:value?' },
    portfoliosRisk: { label: 'Portfolios Risk', to: '/reports/portfolioRisk/:risk?/:value?' },
    reports: { label: 'Reports', to: '/reports' },

    // Portfolios
    portfolioList: { label: 'Portfolio List', to: '/portfolios/:portfolio' },
    portfolios: { label: 'Portfolios', to: '/portfolios' },

    // Assessments
    vendorImports: { label: 'Process Imports', to: '/subscriber/imports/:id?' },
    catalogShort: { label: 'Catalog', to: '/catalog' },
    catalogVendor: { label: 'Vendor Overview', to: '/catalog/vendor/:id/:selectedTab?/:subsection?' }, //When the selectedTab is 'TrustCenter', the subsection, if available, is the ID of a product.
    catalogProduct: { label: 'Product Overview', to: '/catalog/product/:id/:hash?' },
    catalogFull: { label: 'Catalog', to: '/catalog/:orderBy/:dir/:page' },
    catalogProductsShort: { label: 'Products', to: '/catalog/products/' },
    catalogProductsFull: { label: 'Products', to: '/catalog/products/:orderBy/:dir/:page' },
    productDetails: { label: 'Product Details', to: '/product/:id' },
    pdfviewer: { label: 'PDFViewer', to: '/pdf/:id' },

    // Misc
    logout: { label: 'Logout', to: '/logout' },
    help: { label: 'Help', to: '/help' },
    generate: { label: 'Generate', to: '/generate' },
    // home: { label: "Home", to: "/" },
    home: { label: 'Home', to: '/catalog/vendorOverview' },
    vendorHome: { label: 'Home', to: '/catalog/vendorOverview' },

    riskRankOrg: { label: 'Data Driven Risk Rank', to: '/ddrr/:id' },
    riskRank: { label: 'Data Driven Risk Rank', to: '/ddrr' },

    trustCenter: { label: 'Trust Center', to: '/trustCenter' },
  },
  excludeCredentialsFetchPaths: ['https://fg8vvsvnieiv3ej16jby.litix.io'],
  ddrrTierMaxValue: '2000',
  posternReportsDDRR: 'https://postern.fortressis.com/reports/ddrr/',
};

export default config;
