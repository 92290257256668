import React, { Component } from 'react';
import Modal from 'react-modal';
import Busy from './busy';

import { withEvents, events } from './withEvents';

class CsvDownloadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.getCSV = this.getCSV.bind(this);
  }

  getCSV() {
    const today = new Date();
    // this.setState({isOpen: true});

    this.props.events.emit(events.DOWNLOAD_FILE, {
      url: this.props.url,
      filename: `${today.toISOString().substring(0, 10)}-${this.props.filename || 'guardian-export'}.csv`,
    });

    return;

    // fetch(this.props.url)
    // .then((res) => {
    //   return res.blob()
    // })
    // .then(blob =>{
    //   const filename = `${today.toISOString().substring(0,10)}-${this.props.filename || 'guardian-export'}.csv`;

    //   download(blob, filename, 'application/csv');
    //   this.setState({isOpen: false});
    // })
  }

  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn btn-primary fas fa-file-export" onClick={this.getCSV}></button>
        <Modal isOpen={this.state.isOpen} className="card card-login mx-auto mt-5">
          <div className="card-body" style={{ paddingTop: '30px' }}>
            Due to the large amount of data that exported. This export can take up to 1 minute. This window will close
            when the data has been successfully exported.
            <Busy isBusy={true} />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withEvents(CsvDownloadButton);
