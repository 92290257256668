import React from 'react';
import { riskRankColorByTier } from '../../constants';

export const InfoHygieneBadge = ({ ddrrTier }) => {
  return (
    <span
      id="info-hygiene-badge"
      style={{ backgroundColor: riskRankColorByTier(ddrrTier) }}
      className="badge info-hygiene-badge"
    >
      {'Tier: ' + (ddrrTier > 0 ? ddrrTier : 'N/A')}
    </span>
  );
};

export default InfoHygieneBadge;
