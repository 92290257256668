import React, { useState, useEffect } from 'react';

import Busy from '../../components/busy';
import config from '../../config';

const getPdfFromS3 = async (key) => {
  const data = await fetch(config.api.urlFor('pdfFromS3', { key }));
  const s3Url = await data.json();
  return s3Url;
};

const ViewPDF = (props) => {
  const { id } = props.match.params;
  const [url, setUrl] = useState('');
  useEffect(() => {
    async function get() {
      const s3Url = await getPdfFromS3(id);
      // if id given to API is not found in s3, redirect to home
      if (typeof s3Url === 'object' && s3Url.success === false) {
        return (window.location = '/');
      }
      setUrl(s3Url);
    }
    get();
  }, []);

  return url !== '' ? <embed src={url} height="600" width={window.innerWidth - 30} /> : <Busy isBusy={true} />;
};

export default ViewPDF;
