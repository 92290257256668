import React from 'react';
import queryString from 'query-string';

const withQueryString = (Wrapped) => {
  return (props) => {
    const location = props.location || {};

    if (location && location.search) {
      location.query = queryString.parse(location.search);
    }

    location.query = location.query || {};

    return React.createElement(Wrapped, props);
  };
};

export default withQueryString;
