import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { MdVerifiedUser } from 'react-icons/md';
import 'react-table/react-table.css';
import ReactTooltip from 'react-tooltip';
import { Busy, useUpdateState, withPrincipal, SafeHyperlink } from '../../components';
import ClaimPageButton from '../../components/claimPageButton';
import AddRemoveMyVendorButton from '../../components/addRemoveMyVendorButton';
import ModalStagesContainer from '../../components/vendor/catalog/modalStagesContainer';
import CyberHygieneContent from '../../components/vendor/cyberHygieneContent';
import ComplianceContent from '../../components/vendor/compliance/complianceContent';
import ForeignInfluenceContent from '../../components/vendor/foreignInfluenceContent';
import OverviewContent from '../../components/vendor/overviewContent';
import RiskRankContent from '../../components/vendor/riskRankContent';
import TrustCenterContent from '../../components/vendor/trustCenter/trustCenterContent';
import config from '../../config';
import { findObjectByInnerKeyValue } from '../../lib/utils';
import {
  _generateDNSFindingsFromCRUX,
  _generateSSLFindingsFromCRUX,
  _generateAppSecFindingsFromCRUX,
} from '../reports/generateFindings';
import { TrustCenterStoreProvider } from '../../components/vendor/trustCenter/context';
import { riskTierBySeverity } from '../../constants/string';
import { currencyFormatter } from '../../lib/utils';
import { CatalogVendorContext } from './context';
import { assessmentTypes } from '../../constants/assessmentTypes';

import './catalogVendor.css';

//Mapping of path to tab display name
let PATH_TO_TAB_NAME = {
  Overview: 'Overview',
  RiskRank: 'Risk Rank',
  CyberHygiene: 'Cyber Hygiene',
  Compliance: 'Compliance',
  ForeignInfluence: 'Foreign Influence',
};

const CatalogVendorOverview = (props) => {
  // Check to see if trust center is enabled for this instance, if so, enable it
  const { principal, match, history } = props;

  const showOnlyTrustCenterContent = principal?.roles?.some((r) =>
    r.permissions.some((p) => p === 'accessOnlyTrustCenterContent'),
  );

  showOnlyTrustCenterContent
    ? (PATH_TO_TAB_NAME = {})
    : principal.isTrustCenterEnabled
    ? (PATH_TO_TAB_NAME.TrustCenter = 'Trust Center')
    : null;

  //Prop-Dependant Consts
  const { id: vendorID, selectedTab } = useParams();
  const isPageClaimedByCurrentUser = !!(
    principal &&
    Array.isArray(principal.claimedPageVendorIds) &&
    principal.claimedPageVendorIds.indexOf(vendorID) >= 0
  );

  //State
  const [state, setState] = useUpdateState({
    ddrrScoresData: {},
    breachMonitoring: {},
    dnsFindings: {},
    sslFindings: {},
    oshaFindings: [],
    epaFindings: [],
    hipaaFindings: [],
    whdFindings: [],
    warnFindings: [],
    appSecFindings: {},
    vulnsFindings: {},
    selectedTab:
      match.params.selectedTab in PATH_TO_TAB_NAME
        ? match.params.selectedTab
        : showOnlyTrustCenterContent
        ? 'TrustCenter'
        : 'Overview',
    isLoadingProvenanceData: false,
    isLoadingCHFData: false,
    findingsCounts: {},
    cyberHygieneScore: {},
    selectedVendorReports: {},
    reportTypeCMRE: findObjectByInnerKeyValue(props.reportDescriptions, 'rawType', assessmentTypes.CMRE.name).name,
    reportDescriptions: {},
    reportTypeDDRR: '',
    reportTypeCyberHygiene: '',
    linkedReports: [],
    hasScanData: false,
    hasCMREData: false,
    reportOfferings: { vendorReports: [], assetReports: [] },
    freeReports: [],
    shareableReports: [],
    availableReports: [],
    historicalDataReports: {},
  });

  const {
    vendorName = '',
    ddrrScoresData,
    ddrrReportData,
    breachMonitoring,
    provenanceData = {},
    dnsFindings,
    sslFindings,
    oshaFindings,
    epaFindings,
    hipaaFindings,
    whdFindings,
    warnFindings,
    appSecFindings,
    vulnsFindings,
    isLoadingVendorRisk,
    isLoadingBreaches,
    isLoadingProvenanceData,
    isLoadingCHFData,
    findingsCounts,
    cyberHygieneScore,
    doingQuickScan,
    fullScanTasks,
    showModal,
    isLoading = true,
    isUnlockedDDRR,
    isUnlockedCyberHygiene,
    isUnlockedCMRE,
    selectedVendorReports,
    reportTypeCMRE,
    reportDescriptions,
    reportTypeDDRR,
    reportTypeCyberHygiene,
    linkedReports,
    hasScanData,
    hasCMREData,
    reportOfferings,
    availableReports,
    historicalDataReports,
    freeReports,
    downloadableReports,
    shareableReports,
    canPurchaseSharedReports,
  } = state;

  const vendorData = state.vendorData || {};
  const { isPageClaimed = false } = vendorData;
  const { myVendor = undefined } = vendorData;

  /* ----------------------------------- */
  /*              Effects                */
  /* ----------------------------------- */
  //Note: these effects are processed in order

  useEffect(() => {
    getAssessmentCatalog();
  }, []);

  useEffect(() => {
    getVendorScores(vendorID);
  }, [vendorID]);

  useEffect(() => {
    if (showOnlyTrustCenterContent || !vendorData.id || !linkedReports || linkedReports.length == 0) return;

    getAvailableReports(vendorData.id);
  }, [vendorData.id, linkedReports]);

  useEffect(() => {
    handleSelectedTabChange();
  }, [selectedTab]);

  useEffect(() => {
    if (isUnlockedCyberHygiene) {
      getCyberHygieneFindingsData(vendorData.domain, vendorID);
    }
  }, [isUnlockedCyberHygiene, vendorData.domain, vendorID]);

  useEffect(() => {
    if (isUnlockedDDRR && vendorData.ddrrID) {
      getDDRRReportData(vendorData.ddrrID);
    }
  }, [isUnlockedDDRR, vendorData.ddrrID]);

  useEffect(() => {
    if (isUnlockedDDRR && vendorID) {
      getDdrrScoresData(vendorID);
    }
  }, [isUnlockedDDRR, vendorID]);

  useEffect(() => {
    if (vendorData.domain && !showOnlyTrustCenterContent) {
      getBreachMonitoring(vendorData.domain);
    }
  }, [vendorData.domain]);

  useEffect(() => {
    if (isUnlockedCMRE) {
      getProvenanceData(vendorID);
    }
  }, [isUnlockedCMRE, vendorID]);

  useEffect(() => {
    // Checking for false to avoid execution on initial page load.
    if (showModal === false && !showOnlyTrustCenterContent) {
      getAvailableReports(vendorID);
      setState({ selectedVendorReports: {} });
    }
  }, [showModal]);

  const copyVendorPageLink = () => {
    var vendorPageLink = window.location.href;
    navigator.clipboard.writeText(vendorPageLink);
    document.getElementById('onHoverCopyLink').style.display = 'none';
    document.getElementById('linkHoverText').innerHTML = 'Copied!';
    document.getElementById('onHoverCopyLink').style.marginLeft = '50px';
    document.getElementById('onHoverCopyLink').style.display = 'block';
  };

  const linkIconOnMouseOut = () => {
    document.getElementById('onHoverCopyLink').style.marginLeft = '0px';
    document.getElementById('linkHoverText').innerHTML = 'Copy link of vendor page.';
  };

  const handleTabClick = (selectedNewTab) => {
    if (selectedTab !== selectedNewTab) {
      history.push('/catalog/vendor/' + vendorID + '/' + selectedNewTab.replace(/ /g, '').replace(/\//g, '-'));
      setState({ selectedTab: selectedNewTab });
    }
  };

  const handlePurchaseButtonClick = (reportType) => {
    if (reportType) {
      setState({
        selectedVendorReports: { [`${reportType}`]: 1 },
        showModal: true,
      });
    }
  };

  const handleReportDateChange = (selectedOption, action) => {
    const idx = availableReports.findIndex((item) => item.typeID === action.name);
    if (idx >= 0) {
      setState({ historicalDataReports: { ...historicalDataReports, [action.name]: selectedOption } });
    }
  };

  const handleSelectedTabChange = () => {
    showOnlyTrustCenterContent && selectedTab !== 'TrustCenter'
      ? history.push('/catalog/vendor/' + vendorID + '/TrustCenter')
      : setState({ selectedTab });
  };

  const getAssessmentCatalog = () => {
    fetch(config.api.urlFor('catalogReportTypes') + '?pageSize=0')
      .then((res) => res.json())
      .then((data) => {
        let { rows = [] } = data;
        let reportDescriptions = {};
        let linkedReports = [];
        let reportOfferings = { vendorReports: [], assetReports: [] };

        rows.forEach((r) => {
          reportDescriptions[r.name] = {
            name: r.name,
            abvName: r.abbreviation,
            rawType: r.rawType,
            price: r.price,
            description: r.description,
            id: r.id,
            parentID: r.parent_id,
            autodeliver: r.autodeliver,
          };

          if (r.class === 'product') {
            reportOfferings.assetReports.push(r.name);
          }

          if (r.class === 'vendor') {
            if (r.parent_id) {
              const parentReportName = rows
                .filter((report) => report.id === r.parent_id)
                .map((report) => report.name)[0];
              linkedReports.push({ report: r.name, parentReport: parentReportName });
            } else {
              reportOfferings.vendorReports.push(r.name);
            }
          }
        });

        setState({
          reportDescriptions,
          reportTypeDDRR: findObjectByInnerKeyValue(reportDescriptions, 'rawType', assessmentTypes.DDRR.name).name,
          reportTypeCyberHygiene: findObjectByInnerKeyValue(reportDescriptions, 'rawType', assessmentTypes.CHS.name)
            .name,
          reportTypeCMRE: findObjectByInnerKeyValue(reportDescriptions, 'rawType', assessmentTypes.CMRE.name).name,
          linkedReports,
          reportOfferings,
        });
      })
      .catch(() => {
        setState({
          linkedReports: [],
          reportDescriptions: {},
        });
      });
  };

  const getAvailableReports = (vendorID) => {
    setState({ isLoading: true });

    // The parameter { distinct: 1 } is critical for proper UI functionality! Without it, lockscreens don't open on purchase
    fetch(
      config.api.urlFor('catalogAvailableReports', {
        id: vendorID,
        rawType: true,
        distinct: 1, // DO NOT REMOVE!!! Or at least test unlock on purchase if you do :)
        pageSize: 0,
      }),
    )
      .then((res) => res.json())
      .then((data) => {
        let { rows = [], canPurchaseSharedReports } = data;
        let freeReports = {};
        let downloadableReports = {};
        let shareableReports = {};
        const historicalDataReports = {};

        rows = rows.sort((a, b) => {
          let aDate = a.completedDate,
            bDate = b.completedDate,
            aPriority = a.priority,
            bPriority = b.priority;

          switch (true) {
            case !aDate && !bDate: {
              return 0;
            }

            case aDate && !bDate: {
              return -1;
            }

            case !aDate && bDate: {
              return 1;
            }

            default: {
              aDate = new Date(aDate);
              bDate = new Date(bDate);

              return aDate > bDate ? -1 : aDate < bDate ? 1 : aPriority < bPriority ? -1 : 1;
            }
          }
        });
        rows = rows.filter((v, i, a) => a.findIndex((x) => x.rawType === v.rawType) === i).reverse();

        const rawTypes = rows.map(({ rawType, hasAccess }) => ({ rawType, hasAccess }));
        const isUnlockedDDRR = rawTypes.find((x) => x.rawType === assessmentTypes.DDRR.name)?.hasAccess,
          isUnlockedCyberHygiene = rawTypes.find((x) => x.rawType === assessmentTypes.CHS.name)?.hasAccess,
          isUnlockedCMRE = rawTypes.find((x) => x.rawType === assessmentTypes.CMRE.name)?.hasAccess;

        for (let r of rows) {
          const hasDate = r.completedDate === null ? false : true;

          const purchaseDate = r.purchaseDate ? new Date(r.purchaseDate).toDateString() : '';
          const requestedDate = r.requestedDate ? new Date(r.requestedDate).toDateString() : '';
          const completedDate = r.completedDate ? new Date(r.completedDate).toDateString() : '';
          const parentReport = linkedReports && linkedReports.filter((item) => item.report === r.type);
          const actualReportType = parentReport && parentReport.length ? parentReport[0].parentReport : r.type;

          if (r.hasAccess) {
            freeReports[`${actualReportType}`] = purchaseDate ? purchaseDate : requestedDate;
          }

          // VSCA reports could be shared only if the vendor has agreed.
          if (hasDate && (actualReportType !== assessmentTypes.A2VQA.name || vendorData.canShareVSCA)) {
            downloadableReports[`${actualReportType}`] = completedDate;

            if (r.canShare) {
              shareableReports[`${actualReportType}`] = 1;
            }
          }
        }

        let cmre = rows.filter((row) => row.rawType == assessmentTypes.CMRE.name && row.status == 'available');

        canPurchaseSharedReports = canPurchaseSharedReports && Object.keys(shareableReports).length;

        setState({
          isLoading: false,
          isUnlockedDDRR,
          isUnlockedCyberHygiene,
          isUnlockedCMRE,
          availableReports: rows,
          hasCMREData: !!cmre.length,
          freeReports,
          downloadableReports,
          shareableReports,
          canPurchaseSharedReports,
          historicalDataReports,
        });
      });
  };

  const getVendorScores = async (id) => {
    if (showOnlyTrustCenterContent && !isPageClaimedByCurrentUser) history.push('/404');

    setState({ isLoadingVendorRisk: true });

    fetch(`${config.api.urlFor('vendorScores', { id })}`)
      .then((res) => res.json())
      .then((data) => {
        if (!data || data.id === undefined) {
          history.push('/404');
        }

        const riskTierNumber = riskTierBySeverity(data.riskTier);

        setState({
          vendorName: data.name,
          vendorData: {
            id,
            domain: data.domain,
            name: data.name,
            ddrrID: data.ddrrID,
            description: data.description,
            logo: data.logoURL,
            industry: data.industry,
            revenue: `$${currencyFormatter(data.revenue || 0)}`,
            employees: data.employees,
            providerID: data.providerID,
            riskTierSeverity: data.riskTier,
            riskTierNumber: riskTierNumber,
            naics: data.naics ? data.naics.split(' ')[0] : null,
            isPageClaimed: data.isPageClaimed,
            isMember: data.isMember || null,
            myVendor: data.myVendor,
            canShareVSCA: data.canShareVSCA,
          },
        });
      })
      .then(() => {
        setState({
          isLoadingVendorRisk: false,
        });
        if (showOnlyTrustCenterContent) setState({ isLoading: false });
      });
  };

  const getDDRRReportData = (id) => {
    fetch(`${config.api.urlFor('posternDDRRByDDRRID', { id })}`)
      .then((response) => response.json())
      .then((data) => {
        setState({
          ddrrReportData: data,
        });
      })
      .catch(() => {
        setState({ ddrrReportData: null });
      });
  };

  const getDdrrScoresData = (vendorID) => {
    const url = config.api.urlFor('vendorDdrrSummary', { id: vendorID });

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (Object.keys(data).length === 0) {
          // no score data found
          setState({
            ddrrScoresData: {},
          });
        } else {
          const avgScoreValue = riskTierBySeverity(data.totalScoreLabel);

          setState({ ddrrScoresData: { ...data, averageScore: avgScoreValue } });
        }
      })
      .catch(() => {
        setState({ ddrrScoresData: {} });
      });
  };

  const getBreachMonitoring = (domain) => {
    setState({ isLoadingBreaches: true });

    fetch(`${config.api.urlFor('breachMonitoring', { domain })}`)
      .then((res) => res.json())
      .then((data) => {
        data.forEach((object) => {
          object.evidence_link = <SafeHyperlink url={object.evidence}>[Link]</SafeHyperlink>;
        });
        setState({ breachMonitoring: data });
      })
      .catch(() => {
        setState({ breachMonitoring: {} });
      })
      .finally(() => setState({ isLoadingBreaches: false }));
  };

  const getProvenanceData = (vendorID) => {
    if (vendorID && isUnlockedCMRE) {
      setState({ isLoadingProvenanceData: true });

      fetch(`${config.api.urlFor('posternProvenance', { vendorID })}`)
        .then((res) => res.json())
        .then((data) => {
          setState({
            provenanceData: data,
            oshaFindings: data.compliance.OSHA.findings.length > 0 ? data.compliance.OSHA.findings : [],
            epaFindings: data.compliance.EPA.findings.length > 0 ? data.compliance.EPA.findings : [],
            hipaaFindings: data.compliance.HIPAA.findings.length > 0 ? data.compliance.HIPAA.findings : [],
            whdFindings: data.compliance.WHD.findings.length > 0 ? data.compliance.WHD.findings : [],
            warnFindings: data.compliance.WARN.findings.length > 0 ? data.compliance.WARN.findings : [],
            isLoadingProvenanceData: false,
          });
        })
        .catch(() => {
          setState({
            oshaFindings: [],
            isLoadingProvenanceData: false,
          });
        });
    }
  };

  const getCyberHygieneFindingsData = (domain, vendorID) => {
    if (!domain || !vendorID) return;

    setState({ isLoadingCHFData: true });

    fetch(`${config.api.urlFor('vendorCyberHygieneFindings', { domain, vendorID })}`)
      .then((res) => res.json())
      .then((data) => {
        const { rootDomain, score, findingsCounts, findingsDetail } = data || {};
        const { totalScanned, dns, ssl, appSec, vulnerabilities } = findingsDetail || {};

        const dnsFindings = _generateDNSFindingsFromCRUX(rootDomain, dns, findingsCounts);
        const sslFindings = _generateSSLFindingsFromCRUX(ssl, findingsCounts);
        const appSecFindings = _generateAppSecFindingsFromCRUX(appSec, findingsCounts);

        setState({
          hasScanData: totalScanned?.count > 0,
          cyberHygieneScore: score,
          findingsCounts,
          dnsFindings,
          sslFindings,
          appSecFindings,
          vulnsFindings: vulnerabilities,
        });
      })
      .catch(() => {
        setState({
          hasScanData: false,
          cyberHygieneScore: {},
          findingsCounts: {},
          dnsFindings: {},
          sslFindings: {},
          appSecFindings: {},
          vulnsFindings: {},
        });
      })
      .finally(() => {
        setState({ isLoadingCHFData: false });
      });
  };

  const pathBase = '/catalog/vendor/' + vendorID;
  const hasSelectedVendorReports = selectedVendorReports && Object.keys(selectedVendorReports).length > 0;
  const canPurchaseReports =
    !showOnlyTrustCenterContent &&
    principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.purchaseReports'));

  const contextData = {
    vendorID,
    vendorData,
    reportDescriptions,
    reportTypeDDRR,
    reportTypeCyberHygiene,
    reportTypeCMRE,
    linkedReports,
    reportOfferings,
    hasCMREData,
    availableReports,
    historicalDataReports,
    freeReports,
    downloadableReports,
    shareableReports,
    canPurchaseSharedReports,
    findingsCounts,
    cyberHygieneScore,
  };

  if (isLoading) return <Busy isBusy={true} />;

  return (
    <CatalogVendorContext.Provider value={contextData}>
      {canPurchaseReports && (
        <ModalStagesContainer
          fetchData={false}
          fetchReportTypes={false}
          fetchVendorReportData={false}
          mode={hasSelectedVendorReports ? 'purchaseOnly' : 'vendorOverview'}
          modalData={vendorData}
          showModal={showModal}
          predefinedVendorReports={hasSelectedVendorReports ? selectedVendorReports : undefined}
          onRequestClose={() => setState({ showModal: false })}
        />
      )}
      <div className="page-container">
        <div className="overview-head">
          <div className="overview-wrapper">
            <h3 className="overview-label">
              {vendorName ? (
                <span title={vendorName}>
                  {vendorName.length > 87 ? vendorName.substring(0, 75) + '...' : vendorName}
                </span>
              ) : (
                ''
              )}{' '}
              Overview{' '}
              {!!isPageClaimed && (
                <>
                  <MdVerifiedUser className="claimed-page-icon" data-tip data-for="onHoverClaimedPage" />
                  <ReactTooltip id="onHoverClaimedPage" className="on-hover-tooltip" place="top" effect="solid">
                    <p className="on-hover-text">This vendor has claimed this page.</p>
                  </ReactTooltip>
                </>
              )}{' '}
            </h3>
          </div>
          {!showOnlyTrustCenterContent && (
            <div className="claim-page-head-btns">
              <div className="claim-page-button">
                {selectedTab === 'Overview' &&
                  (isPageClaimed === false || isPageClaimedByCurrentUser) &&
                  principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.claimVendorPage')) && (
                    <ClaimPageButton
                      vendorName={vendorName}
                      vendorID={vendorID}
                      vendorDDRRid={vendorData.ddrrID}
                      isPageClaimed={isPageClaimedByCurrentUser}
                    />
                  )}
              </div>

              <div className="add-remove-my-vendor-head-btns-wrapper">
                <div className="add-remove-my-vendor-button">
                  {selectedTab === 'Overview' && (
                    <AddRemoveMyVendorButton vendorID={vendorID} vendorName={vendorName} myVendor={myVendor} />
                  )}
                </div>

                <div className="head-btns-wrapper">
                  <div className="head-btns">
                    {Object.keys(PATH_TO_TAB_NAME).map((pathName) => {
                      const tabName = PATH_TO_TAB_NAME[pathName];
                      const btnClassName = 'btn head-btn ' + (selectedTab === pathName ? 'chosen' : 'not-chosen');
                      return (
                        <button className={btnClassName} onClick={() => handleTabClick(pathName)} key={pathName}>
                          {tabName}
                        </button>
                      );
                    })}

                    <div className="link-icon-div">
                      <i
                        className="fas fa-link link-icon link-icon"
                        data-tip
                        data-for="onHoverCopyLink"
                        onClick={copyVendorPageLink}
                        onMouseOut={linkIconOnMouseOut}
                      />
                      <ReactTooltip id="onHoverCopyLink" className="on-hover-tooltip" place="top" effect="solid">
                        <p id="linkHoverText" className="on-hover-text">
                          Copy link of vendor page.
                        </p>
                      </ReactTooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!showOnlyTrustCenterContent && (
          <>
            <div className={selectedTab === 'Overview' ? '' : 'hide-component'}>
              <OverviewContent
                isLoadingVendorRisk={isLoadingVendorRisk || isLoadingCHFData}
                vendorData={vendorData}
                ddrrScoresData={ddrrScoresData}
                provenanceScoresData={provenanceData.scores}
                isUnlockedDDRR={isUnlockedDDRR}
                isUnlockedCyberHygiene={isUnlockedCyberHygiene}
                isUnlockedCMRE={isUnlockedCMRE}
                handlePurchaseButtonClick={handlePurchaseButtonClick}
                reportTypeCMRE={reportTypeCMRE}
                reportDescriptions={reportDescriptions}
                reportTypeDDRR={reportTypeDDRR}
                reportTypeCyberHygiene={reportTypeCyberHygiene}
                setShowModal={(showModal) => setState({ showModal })}
                pathBase={pathBase}
                breachMonitoring={breachMonitoring}
                isLoadingBreaches={isLoadingBreaches}
                linkedReports={linkedReports}
                dnsFindings={dnsFindings}
                sslFindings={sslFindings}
                appSecFindings={appSecFindings}
                vulnsFindings={vulnsFindings?.counts}
                hasScanData={hasScanData}
                hasCMREData={hasCMREData}
                handleReportDateChange={handleReportDateChange}
              />
            </div>
            <div className={selectedTab === 'RiskRank' ? '' : 'hide-component'}>
              <RiskRankContent
                isLoadingVendorRisk={isLoadingVendorRisk}
                vendorData={vendorData}
                ddrrScoresData={ddrrScoresData}
                ddrrReportData={ddrrReportData}
                isUnlockedDDRR={isUnlockedDDRR}
                reportTypeDDRR={reportTypeDDRR}
                handleUnlockButtonClick={handlePurchaseButtonClick}
              />
            </div>
            <div className={selectedTab === 'CyberHygiene' ? '' : 'hide-component'}>
              <CyberHygieneContent
                isLoadingData={isLoadingVendorRisk || isLoadingCHFData}
                vendorData={vendorData}
                vulnsFindings={vulnsFindings}
                dnsFindings={dnsFindings}
                sslFindings={sslFindings}
                appSecFindings={appSecFindings}
                fullScanTasks={fullScanTasks}
                doingQuickScan={doingQuickScan}
                pathBase={pathBase}
                hasScanData={hasScanData}
                isUnlockedCyberHygiene={isUnlockedCyberHygiene}
                reportTypeCyberHygiene={reportTypeCyberHygiene}
                handleUnlockButtonClick={handlePurchaseButtonClick}
              />
            </div>
            <div className={selectedTab === 'Compliance' ? '' : 'hide-component'}>
              <ComplianceContent
                isLoadingVendorRisk={isLoadingVendorRisk}
                vendorData={vendorData}
                oshaFindings={oshaFindings}
                epaFindings={epaFindings}
                hipaaFindings={hipaaFindings}
                whdFindings={whdFindings}
                warnFindings={warnFindings}
                pathBase={pathBase}
                isUnlockedCMRE={isUnlockedCMRE}
                reportTypeCMRE={reportTypeCMRE}
                handleUnlockButtonClick={handlePurchaseButtonClick}
                canPurchaseReports={canPurchaseReports}
                hasData={hasCMREData}
                isLoadingProvenanceData={isLoadingProvenanceData}
              />
            </div>
            <div className={selectedTab === 'ForeignInfluence' ? '' : 'hide-component'}>
              <ForeignInfluenceContent
                isLoadingVendorRisk={isLoadingVendorRisk}
                vendorData={vendorData}
                pathBase={pathBase}
                provenanceData={provenanceData}
                isUnlockedCMRE={isUnlockedCMRE}
                reportTypeCMRE={reportTypeCMRE}
                handleUnlockButtonClick={handlePurchaseButtonClick}
                canPurchaseReports={canPurchaseReports}
                hasData={hasCMREData}
              />
            </div>
          </>
        )}
        {principal.isTrustCenterEnabled && (
          <div className={selectedTab === 'TrustCenter' ? '' : 'hide-component'}>
            <TrustCenterStoreProvider>
              <TrustCenterContent
                isLoadingVendorRisk={isLoadingVendorRisk}
                vendorData={vendorData}
                canModify={isPageClaimedByCurrentUser}
                canViewFileAccessRequests={isPageClaimedByCurrentUser}
                showOnlyTrustCenterContent={showOnlyTrustCenterContent}
              />
            </TrustCenterStoreProvider>
          </div>
        )}
      </div>
    </CatalogVendorContext.Provider>
  );
};

export default withPrincipal(CatalogVendorOverview);
