import styled from 'styled-components';
import PropTypes from 'prop-types';

const Flex = styled.div`
  ${(props) => (props.display ? `display: ${props.display};` : `display: flex;`)}
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems};` : null)}
    ${(props) => (props.justifyContent ? `justify-content: ${props.justifyContent};` : null)}
    ${(props) => (props.flexDirection ? `flex-direction: ${props.flexDirection};` : null)}
    ${(props) => (props.width ? `width: ${props.width};` : null)}
    ${(props) => (props.height ? `height: ${props.height};` : null)}
    ${(props) => (props.flexWrap ? `flex-wrap: ${props.flexWrap};` : null)}
		@media screen and (max-width: 767px) {
    ${(props) => (props.mobileWidth ? `width: ${props.mobileWidth};` : null)}
    ${(props) => (props.mobileDisplay ? `display: ${props.mobileDisplay};` : null)}
  }
`;

Flex.propTypes = {
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
  width: PropTypes.string,
  flexWrap: PropTypes.string,
};

export default Flex;
