import React, { Component } from 'react';
import { generateColor, generateSymbol } from '../../../../../constants/postern/riskIconGenerator.js';
import { makeGoogleMapsUrl, prependHTTPStoLink } from '../../../../../lib/utils';
import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';

export default class ForeignOwnership extends Component {
  render() {
    const { scores = {}, foreignOwnership = [] } = this.props;
    const { foreignOwnership: sForeignOwnership = '' } = scores;

    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          {sForeignOwnership === 'high'
            ? 'During our review of the current owners, some were found to have headquarters in countries of adversarial presence.  Further investigation concludes that the foreign influence from ownership is likely significant.'
            : 'During our review of the current owners, none were found to have headquarters in countries of adversarial presence.  Further investigation concludes that the foreign influence from ownership is not likely significant, and thus no finding is raised.'}
        </div>

        {foreignOwnership.length > 0 ? (
          <ReusableProvenanceTable
            tableName="ForeignOwnershipTable-"
            headers={['', 'Owner', 'HQ Address', 'City', 'Country', 'Domain']}
          >
            {foreignOwnership
              .filter((row) => row.country && row.country.toLowerCase() != 'united states')
              .map((row, i) => {
                const updatedDomain = prependHTTPStoLink(row.owner_domain);
                return (
                  <React.Fragment key={'ForeignOwnershipTable-inner-' + i}>
                    <tr className="provenance-table-row">
                      <th className="row-padding" scope="row">
                        {' '}
                        <SeverityCircle
                          symbol={generateSymbol(row.highRisk)}
                          color={generateColor(row.highRisk)}
                        />{' '}
                      </th>
                      <td> {row.owner_name} </td>
                      <td>
                        {' '}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={makeGoogleMapsUrl(row.country, row.city, row.hq_address)}
                        >
                          {row.hq_address}
                        </a>{' '}
                      </td>
                      <td> {row.city} </td>
                      <td> {row.country} </td>
                      <td>
                        {' '}
                        {row.owner_domain != null ? (
                          <a href={updatedDomain} target="_blank" rel="noopener noreferrer">
                            {updatedDomain}
                          </a>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </ReusableProvenanceTable>
        ) : (
          ''
        )}

        <footer className="footer-p-wrapper">
          <p>
            Foreign ownership of public corporate entities is assessed from 13D &#38; 13G filings. These filings signify
            a &gt;5% threshold of ownership and whether the invested entity intends to be a proactive or passive
            shareholder, respectively. Using this register any ownership information is recorded. Information is then
            gathered about the significant foreign institutional and individual shareholders. Headquarters/person
            location is then evaluated. For private companies, the company website and additional third-party tools are
            assessed for direct ownership, indirect ownership, and major investors. Headquarters locations of these
            entities are then evaluated.
          </p>
        </footer>
      </React.Fragment>
    );
  }
}
