import React from 'react';
import EmbeddedVideoBox from '../../components/video/embeddedVideoBox';

const embeddedVideos = [
  { title: 'Registration', embedID: '' },
  { title: 'Login', embedID: 'wemdeimsaz' },
  { title: 'Search and Filter Vendors', embedID: 'mtoxoy9k2o' },
  { title: 'Order Reports', embedID: 'hw3z07m476' },
  { title: 'Vendor Profile - Overview', embedID: 'o45l9ebhse' },
  { title: 'Vendor Profile - Risk Rank', embedID: 'xe9q4m153n' },
  { title: 'Vendor Profile - Cyber Hygiene', embedID: 'swj7p7h2jj' },
  { title: 'Vendor Profile - Compliance', embedID: '' },
  { title: 'Vendor Profile - Foreign Influence', embedID: 'co5f1t49xv' },
  { title: 'Vendor Profile - Trust Center', embedID: 'lqmtltn092' },
  { title: 'User Profile - Claim Page', embedID: '' },
  { title: 'User Profile - Add Vendors', embedID: '' },
  { title: 'User Profile - Request Update', embedID: 'tjba7cadnz' },
  { title: 'User Profile - Purchase Tokens', embedID: '' },
  { title: 'User Profile - Home', embedID: 'g3bttbmrlp' },
  { title: 'User Profile - Trust Center', embedID: 'f27co5fhpa' },
];

const getStarted = () => {
  return (
    <div className="col-12">
      <h3>Marketplace Videos</h3>
      <div className="video-flex-container">
        {embeddedVideos.map((video, i) => {
          return <EmbeddedVideoBox title={video.title} embedID={video.embedID} key={i}></EmbeddedVideoBox>;
        })}
      </div>
    </div>
  );
};

export default getStarted;
