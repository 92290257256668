import React from 'react';

class ProviderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lineChartReady: false,
      model: {},
      map: {},
    };
  }

  render() {
    return <h1>Provider Details</h1>;
  }
}

export default ProviderDetails;
