import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import { withPrincipal, Spinner, Busy } from '../../components';
import { createGlobalStyle } from 'styled-components';
import Alert from '../../components/alert';
import AccessConditionsModal from './accessConditionsModal';

import './login.css';

const GlobalStyle = createGlobalStyle`

  html {
    height: 100%;
    margin: 0;
  }

  body {
    height: 100%;
    margin: 0;
    background-attachment: fixed;
    background-color: #000;
    background-position: center center;
    background-size: cover;
  }

  footer {
    visibility: hidden;
  }

  #root {
    height: 100%;
    margin: 0;
    overflow: hidden;
  }
}
`;

const { shouldAgreeToAccessConditionsToLogIn } = config;
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: '',
      password: '',
      twoFACode: '',
      isSuccess: this.props.principal != null,
      isBusy: false,
      submitIsBusy: false,
      loginFailure: false,
      loginFailureError: '',
      allVendorsCount: 0,
      allProductsCount: 0,
      vendorsCountLoaded: false,
      productsCountLoaded: false,
      backgroundImages: [
        '/static/images/login-page-background-1-08_2021.jpg',
        '/static/images/login-page-background-2-08_2021.jpg',
        '/static/images/login-page-background-3-08_2021.jpg',
      ],
      currentBackgroundImg: 0,
      backgroundImagesLoaded: [],
      backgroundImageLoaded: false,
      rememberMe: false,
      passwordResetToken: '',
      agreementWithAccessConditions: !shouldAgreeToAccessConditionsToLogIn,
      accessConditionsModalIsOpen: shouldAgreeToAccessConditionsToLogIn,
      missingAccessAgreementOnSubmit: false,
      showUserAgreementModal: false,
      logoutReason: '',
    };

    this.submit = this.submit.bind(this);
    this.onAlertClose = this.onAlertClose.bind(this);
    this.handleAgreementWithAccessConditionsChange = this.handleAgreementWithAccessConditionsChange.bind(this);
    this.handleRememberMeChange = this.handleRememberMeChange.bind(this);
    this.handleAccessConditionsModalClose = this.handleAccessConditionsModalClose.bind(this);
    this.handleShowUserAgreementModal = this.handleShowUserAgreementModal.bind(this);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  componentDidMount() {
    const emailAddress = localStorage.getItem('emailAddress');
    const logoutReason = localStorage.getItem('logout');
    this.setState({ emailAddress, rememberMe: !!emailAddress, logoutReason });

    this.state.backgroundImages.forEach((image, index) => {
      const img = new Image();
      img.src = image;
      let backgroundImagesLoaded = this.state.backgroundImagesLoaded;
      backgroundImagesLoaded[index] = img;
      this.setState({ backgroundImagesLoaded: backgroundImagesLoaded });
    });

    this.changeBackgroundImage();
    this.interval = setInterval(() => this.changeBackgroundImage(), 10000);
    this.getAllVendorsCount();
    // this.getAllProductsCount(); // Temporarily disabled until we have more products.
  }

  submit(e) {
    e.preventDefault();

    const { emailAddress, password, twoFACode, rememberMe, agreementWithAccessConditions } = this.state;

    if (!agreementWithAccessConditions) {
      this.setState({ accessConditionsModalIsOpen: true, missingAccessAgreementOnSubmit: true });
      return;
    }

    if (rememberMe) {
      localStorage.setItem('emailAddress', emailAddress);
    } else {
      localStorage.removeItem('emailAddress');
    }

    const params = new URLSearchParams();
    params.set('emailAddress', emailAddress);
    params.set('password', password);
    params.set('twofaCode', twoFACode);

    this.setState({ submitIsBusy: true, loginFailure: false });

    fetch(config.api.urlFor('login'), {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((json) => {
        const { isSuccess, message, data = {} } = json;

        if (isSuccess === true) {
          const { user, token, lastSuccessfulLogon } = data;
          window.$app.setState({ principal: user, token, lastSuccessfulLogon, logout: undefined });
          window.location.reload(true);
        } else {
          this.setState({
            loginFailure: true,
            loginFailureError: message,
            submitIsBusy: false,
            passwordResetToken: data.passwordResetToken,
          });
          this.clearLogoutReason();
        }
      })
      .catch(() => this.setState({ submitIsBusy: false }));
  }

  getAllVendorsCount() {
    fetch(config.api.urlFor('allVendorsCount'))
      .then((res) => res.json())
      .then((count) => {
        this.setState({ allVendorsCount: count, vendorsCountLoaded: true });
      })
      .catch((err) => {
        return err;
      });
  }

  // Temporarily not used until we have more products.
  getAllProductsCount() {
    fetch(config.api.urlFor('allProductsCount'))
      .then((res) => res.json())
      .then((count) => {
        this.setState({ allProductsCount: count, productsCountLoaded: true });
      })
      .catch((err) => {
        return err;
      });
  }

  handleRememberMeChange(event) {
    const input = event.target;
    if (input.type !== 'checkbox') return;

    const value = input.checked;

    this.setState({ rememberMe: value });
  }

  changeBackgroundImage() {
    const { backgroundImages, currentBackgroundImg, backgroundImagesLoaded } = this.state;
    const numberOfImages = backgroundImages.length;

    let newCurrentImg = 0;
    if (currentBackgroundImg !== numberOfImages - 1) {
      newCurrentImg = currentBackgroundImg + 1;
    }

    this.setState({ currentBackgroundImg: newCurrentImg });

    const img = backgroundImagesLoaded[newCurrentImg];

    var int = setInterval(() => {
      if (img) {
        clearInterval(int);
        document.getElementsByTagName('body')[0].style.backgroundImage = 'url(' + img.src + ')';
        this.setState({ backgroundImageLoaded: true });
      }
    }, 50);
  }

  clearLogoutReason() {
    if (this.state.logoutReason !== '') {
      localStorage.removeItem('logout');
      this.setState({ logoutReason: '' });
    }
  }

  onAlertClose() {
    this.setState({ loginFailure: false, loginFailureError: '', passwordResetToken: '' });
    this.clearLogoutReason();
  }

  handleAgreementWithAccessConditionsChange(event) {
    const input = event.target;
    if (input.type !== 'checkbox') return;

    const value = input.checked;

    this.setState({ agreementWithAccessConditions: value });
  }

  handleShowUserAgreementModal() {
    this.setState({ showUserAgreementModal: !this.state.showUserAgreementModal });
  }

  handleAccessConditionsModalClose() {
    this.setState({ accessConditionsModalIsOpen: false, showUserAgreementModal: false });
  }

  render() {
    const {
      accessConditionsModalIsOpen,
      emailAddress,
      password,
      twoFACode,
      agreementWithAccessConditions,
      submitIsBusy,
      loginFailure,
      loginFailureError,
      rememberMe,
      vendorsCountLoaded,
      allVendorsCount,
      // productsCountLoaded,
      // allProductsCount,
      isBusy,
      backgroundImageLoaded,
      missingAccessAgreementOnSubmit,
      passwordResetToken,
      showUserAgreementModal,
    } = this.state;

    const alert = this.props.location.state ? this.props.location.state.alert : undefined;
    const { logoutReason } = this.state;
    const numberFormatter = Intl.NumberFormat('en-US');

    const content = (
      <div className="login-page">
        <section>
          <div className="container w-25 pl-0 m-0">
            {/* <div style={hiMenuDiv}>
              <HiMenu style={hiMenu} />
            </div> */}
          </div>
          <div className="container login-container w-50">
            <div className="row">
              <div className="col-12 text-center">
                <a href="https://www.assettovendor.com/">
                  <img className="a2v-logo-heading" alt="A2V Logo" src="/static/images/A2V_logo_negative.png" />
                </a>
              </div>
            </div>
            <div className="card card-login mx-auto">
              <div className="card-header">
                <p>Login</p>
              </div>
              <div className="card-body">
                <form onSubmit={this.submit} noValidate={accessConditionsModalIsOpen}>
                  <div className="form-group">
                    <div className="form-group">
                      <label htmlFor="inputemailAddress" className="login-page-label">
                        Email Address
                      </label>
                      <input
                        type="text"
                        id="inputemailAddress"
                        className="form-control login-page-input"
                        placeholder=""
                        required="required"
                        onChange={(e) => this.setState({ emailAddress: e.target.value })}
                        value={emailAddress}
                      ></input>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label htmlFor="inputPassword" className="login-page-label">
                        Password
                      </label>
                      <input
                        type="password"
                        id="inputPassword"
                        className="form-control login-page-input"
                        placeholder=""
                        required="required"
                        onChange={(e) => this.setState({ password: e.target.value })}
                        value={password}
                      ></input>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label htmlFor="twofacode" className="login-page-label">
                        2FA Code
                      </label>
                      <input
                        type="text"
                        id="twofacode"
                        className="form-control login-page-input"
                        placeholder=""
                        required="required"
                        onChange={(e) => this.setState({ twoFACode: e.target.value })}
                        value={twoFACode}
                      ></input>
                    </div>
                  </div>
                  {shouldAgreeToAccessConditionsToLogIn && (
                    <>
                      <div className="form-group login-checkbox">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={agreementWithAccessConditions}
                            onChange={() => this.setState({ accessConditionsModalIsOpen: true })}
                            id="agree-to-access-conditions"
                            name="agree-to-access-conditions"
                            value="I have read and agree to the conditions of access"
                            className={
                              missingAccessAgreementOnSubmit && !agreementWithAccessConditions ? 'on-focus' : ''
                            }
                          ></input>
                          <label htmlFor="agree-to-access-conditions">
                            <button type="button" onClick={() => this.setState({ accessConditionsModalIsOpen: true })}>
                              I have read and agree to the conditions of access
                            </button>
                          </label>
                        </div>
                      </div>
                      <AccessConditionsModal
                        isOpen={accessConditionsModalIsOpen}
                        handleClose={this.handleAccessConditionsModalClose}
                        isChecked={agreementWithAccessConditions}
                        handleAgreementWithAccessConditionsChange={this.handleAgreementWithAccessConditionsChange}
                        checkboxOnFocus={missingAccessAgreementOnSubmit}
                        showUserAgreementModal={showUserAgreementModal}
                        handleShowUserAgreementModal={this.handleShowUserAgreementModal}
                      />
                    </>
                  )}
                  {alert && alert.message && (
                    <Alert
                      message={alert.message}
                      type={alert.type ? alert.type : undefined}
                      onClose={this.onAlertClose}
                    />
                  )}
                  {loginFailure && (
                    <Alert
                      message={loginFailureError}
                      type="warning"
                      className={shouldAgreeToAccessConditionsToLogIn ? '' : 'login-alert'}
                      onClose={this.onAlertClose}
                      linkTo={
                        passwordResetToken && (
                          <Link to={`/resetPassword/${passwordResetToken}`} className="ml-3">
                            Reset Password
                          </Link>
                        )
                      }
                    />
                  )}
                  {!!logoutReason && (
                    <Alert
                      message={
                        logoutReason === 'true'
                          ? 'You have successfully logged out.'
                          : 'Your session has expired. You need to authenticate in order to continue.'
                      }
                      type={logoutReason === 'true' ? 'success' : 'info'}
                      className={shouldAgreeToAccessConditionsToLogIn ? '' : 'login-alert'}
                      onClose={this.onAlertClose.bind(this)}
                    />
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    value="Login"
                    disabled={
                      submitIsBusy || !emailAddress || !password || !twoFACode || !agreementWithAccessConditions
                    }
                  >
                    Log In <Spinner style={{ marginLeft: '5px' }} isVisible={submitIsBusy} />
                  </button>
                  <div className="form-group login-checkbox">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={this.handleRememberMeChange}
                        id="remember-me"
                        name="remember-me"
                        value="Remember Me"
                      ></input>
                      <label htmlFor="remember-me">
                        <p>Remember Me</p>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <div className="forgot-password">
                <div>
                  <Link to="/forgotPassword">
                    <p>Forgot Your Password?</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row fortress-logo">
              <div className="col-12">
                <img className="logo" alt="A2V Logo" src="/static/images/fortress-logo-white-06_2021.png" />
              </div>
            </div>
            {/* <div className="card card-login mx-auto" style={registerContactButtonsCard}>
              <div className="card-body" style={registerButtonCardBody}>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    value='Register'
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <div className="container w-25 pl-0 pr-0 m-0">
            <div className={`vendor-product-count-container${loginFailure ? ' lower' : ''}`}>
              <div className="vendor-product-count">
                <h3>
                  <b>{vendorsCountLoaded ? numberFormatter.format(allVendorsCount) : '...'}</b>
                </h3>
                <h4>VENDORS</h4>
              </div>
              {/* // Temporarily disabled until we have more products.
              <div className="vendor-product-count-vertical-line"></div>
              <div className="vendor-product-count">
                <h3>
                  <b>{productsCountLoaded ? numberFormatter.format(allProductsCount) : '...'}</b>
                </h3>
                <h4>PRODUCTS</h4>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    );

    return (
      <React.Fragment>
        <GlobalStyle />
        <Busy isBusy={isBusy}>{backgroundImageLoaded ? content : null}</Busy>
      </React.Fragment>
    );
  }
}

export default withPrincipal(Login);
