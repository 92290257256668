import React, { Component } from 'react';

import { makeGauge, removeGauge } from './../assets/js/gauge';

import BackgroundColorString from './../../../reusable/backgroundColorString';
import { inherentRiskScoreGauge as RiskScoreGauge } from './inherentRiskScoreGauge';

import './overallVendorScore.css';

export default class OverallVendorScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      charts: [],
    };
  }

  componentDidMount() {
    const { vendor = {} } = this.props;
    const totalScore = vendor.totalScore || 0;
    const chart = makeGauge(totalScore, 'ddrr-inherent-risk-score');
    this.setState({
      charts: chart,
    });
  }

  componentWillUnmount() {
    const { charts } = this.state;
    removeGauge(charts);
  }

  render() {
    const { vendor = {}, redData = {}, doe_cyber_presence = [], eo_cyber_presence = [] } = this.props;
    const { totalScore, totalScoreLabel } = vendor;
    const { noRed = true, bannedRelationships = {}, regulations = [] } = redData;

    return (
      <div className="overall-vendor-score">
        <RiskScoreGauge className="risk-score-gauge" gaugeValue={totalScore} scoreText={totalScoreLabel} />
        <div className="overall-vendor-score-information">
          <div className="compliance-alerts">
            <div className="compliance-alerts-header">Compliance Alerts</div>
            <div>
              {!noRed ? (
                <React.Fragment>
                  {Object.keys(bannedRelationships).map((keyName, i) => {
                    let countOfArray = bannedRelationships[keyName].length || 0;
                    return (
                      <p key={'bannedRelOverall-' + i}>
                        {' '}
                        <BackgroundColorString
                          string={countOfArray}
                          bgColor={countOfArray == 0 ? '#28a745' : '#ffc107'}
                        />{' '}
                        {keyName}
                      </p>
                    );
                  })}
                  {regulations
                    .filter((row) => !Object.keys(bannedRelationships).includes(row.regulation))
                    .map((row, i) => {
                      let countOfArray = row.length || 0;
                      return (
                        <p key={'regsOverall-' + i}>
                          {' '}
                          <BackgroundColorString
                            string={countOfArray}
                            bgColor={countOfArray == 0 ? '#28a745' : '#ffc107'}
                          />{' '}
                          {row.regulation}
                        </p>
                      );
                    })}
                </React.Fragment>
              ) : (
                ''
              )}
            </div>
            <div id="executive-order-status" className="executive-order-status">
              <div>
                |&nbsp;&nbsp;
                <BackgroundColorString
                  string={doe_cyber_presence.length}
                  bgColor={doe_cyber_presence.length == 0 ? '#28a745' : '#ffc107'}
                />{' '}
                DoE Prohibition Order&nbsp;&nbsp;
              </div>
              <div>
                |&nbsp;&nbsp;
                <BackgroundColorString
                  string={eo_cyber_presence.length}
                  bgColor={eo_cyber_presence.length == 0 ? '#28a745' : '#ffc107'}
                />{' '}
                EO 13920&nbsp;&nbsp;|
              </div>
            </div>
            <p id="risk-explanation">
              *Inherent risk is an indicator of predicted business impact risk (i.e., the consequences of a potential
              security incident). This should be evaluated separately from likelihood risk (i.e., the chance of a
              security incident occurring).
            </p>
          </div>
        </div>
      </div>
    );
  }
}
