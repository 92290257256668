import React from 'react';

const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="d-flex justify-content-between">
        {column.text}
        {sortElement}
      </div>
      {filterElement ? filterElement : null}
    </div>
  );
};

export default headerFormatter;
