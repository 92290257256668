import React, { Component } from 'react';

import './methodology.css';
import ReusableProvenanceTable from './reusableProvenanceTable';
import BackgroundColorString from './../../../reusable/backgroundColorString.jsx';

export default class Methodology extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          <p>
            The procedures in this report are designed to identify possible foreign ownership, control or influence
            (FOCI) from adversaries of the United States of America or its allies. The Department of Energy, in an&nbsp;
            <a
              href="https://www.govinfo.gov/content/pkg/FR-2020-07-08/pdf/2020-14668.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              RFI (DOE–HQ–2020–0028) issued on July 8, 2020{' '}
            </a>
            , identifies the following countries as adversaries:
          </p>
          <ol>
            <li>The People’s Republic of China (China),</li>
            <li>the Republic of Cuba (Cuba),</li>
            <li>the Islamic Republic of Iran (Iran),</li>
            <li>the Democratic People&apos;s Republic of Korea (North Korea),</li>
            <li>the Russian Federation (Russia)</li>
            <li>and the Bolivarian Republic of Venezuela (Venezuela).</li>
          </ol>
          <p>
            The Defense Counterintelligence and Security Agency provides the follow definition for FOCI&nbsp;
            <a href="https://www.dcsa.mil/mc/ctp/foci/" target="_blank" rel="noopener noreferrer">
              on their web site
            </a>
            .
          </p>
          <p className="methodology-quote">
            A company is considered to be operating under FOCI whenever a foreign interest has the power, direct or
            indirect, whether or not exercised, and whether or not exercisable, to direct or decide matters affecting
            the management or operations of that company in a manner which may result in unauthorized access to
            classified information or may adversely affect the performance of classified contracts.
          </p>
          <p>
            The following table addresses how the sections of this report tie back to the factors determining FOCI, the
            data sources considered and the expected data quality.
          </p>
        </div>

        <ReusableProvenanceTable
          tableName="MethodologyTable-"
          headers={['Factors determining whether a company is under FOCI', 'Sections Addressed', 'Data Sources']}
        >
          <tr className="provenance-table-row">
            <td>Record of economic and government espionage against U.S. target</td>
            <td>Watchlist</td>
            <td className="row-padding">
              <span className="data-amount">
                <BackgroundColorString string={16} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Core Watchlists,
              <br />
              <span className="data-amount">
                <BackgroundColorString string={'1,000+'} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Enforcement Lists and Court Filings
              <br />
              <span className="data-amount">
                <BackgroundColorString string={'25,000+'} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Illicit Activity Sources
            </td>
          </tr>
          <tr className="provenance-table-row">
            <td>Record of enforcement and/or engagement in unauthorized technology transfer</td>
            <td>Watchlist</td>
            <td className="row-padding">See above</td>
          </tr>
          <tr className="provenance-table-row">
            <td></td>
            <td>Product Provenance Detail</td>
            <td className="row-padding">
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={1} bgColor={'#004d70'} />{' '}
              </span>
              Product Provenance Detail
              <br />
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={1} bgColor={'#004d70'} />{' '}
              </span>
              Non-conformance Database
            </td>
          </tr>
          <tr className="provenance-table-row">
            <td>The type and sensitivity of the information that shall be accessed</td>
            <td>Vendor Tiering</td>
            <td className="row-padding">
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={1} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Recorded in Fortress Platform
              <br />
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={6} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Data Driven Risk Ranking (optional)
            </td>
          </tr>
          <tr className="provenance-table-row">
            <td>
              The nature of any bilateral and multilateral security and information exchange agreements that may pertain
            </td>
            <td>Vendor Tiering</td>
            <td className="row-padding">See above</td>
          </tr>
          <tr className="provenance-table-row">
            <td>Ownership or control, in whole or in part, by a foreign government</td>
            <td>Foreign Ownership, Merger & Acquisition</td>
            <td className="row-padding">
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={8} bgColor={'#004d70'} />{' '}
              </span>
              Public and Private Databases
            </td>
          </tr>
          <tr className="provenance-table-row">
            <td>The source, nature and extent of FOCI</td>
            <td>Foreign Ownership, Merger & Acquisition</td>
            <td className="row-padding">See aboves</td>
          </tr>
          <tr className="provenance-table-row">
            <td></td>
            <td>Headquarters, Physical Presence (Enhanced Only), Manufacturing(Enhanced Only)</td>
            <td className="row-padding">
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={8} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Open Sources <br />
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={3} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Private Databases
            </td>
          </tr>
          <tr className="provenance-table-row">
            <td></td>
            <td>Cyber Presence</td>
            <td className="row-padding">
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={6} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Geolocation Tools
            </td>
          </tr>
          <tr className="provenance-table-row">
            <td>Record of compliance with pertinent U.S. laws, regulations and contracts</td>
            <td>Compliance</td>
            <td className="row-padding">
              <span className="data-amount">
                {' '}
                <BackgroundColorString string={5} bgColor={'#004d70'} />{' '}
              </span>{' '}
              Regulatory Databases
            </td>
          </tr>
        </ReusableProvenanceTable>

        <footer className="footer-p-wrapper">
          <p>Data sources above are subject to change based on data and commercial feasibility.</p>
        </footer>

        <footer id="legal-disclaimer">
          <p>
            Disclaimer: The usage and distribution of this document is strictly governed by the terms of our agreement
            with our client, and this document may not be relied upon anyone other than our client. Only individuals
            with a specific contractual right to view this document may do so, and if you believe you may have received
            this document in error, please do not read further and contact us via email at
            Compliance@FortressInfoSec.com as soon as possible.To the extent this document is provided to or obtained by
            a governmental entity, please note that confidential treatment of this document is requested under both the
            Freedom of Information Act, as well as any similar applicable state or local laws governing the public
            disclosure of documentation, and written notice of any request for this document is requested to be sent to
            us via email at Compliance@FortressInfoSec.com as soon as possible.The analysis set forth herein is made as
            of the date listed in this document, and we are under no obligation to correct or modify this document on an
            ongoing basis, unless we have specifically contracted with our client to do so in a mutually executed
            agreement. The usage of critical, high, medium, moderate, nominal, low, or other scaled indicators or
            parlance associated with the rating of risk is based on our subjective determinations, and our subjective
            determinations may deviate from regulatory determination, and determinations by others. Our determinations
            are based on facts and data we receive, and to the extent any facts or data are incorrect, our
            determinations may then be correspondingly incorrect. Any included recommendations provided are
            non-inclusive and are only intended to serve as exemplar actions to address security risks, rather than as
            the sum total of all actions recommended to address security risks.Information security threats change on a
            daily basis, and no amount of security testing or verification can ensure that any systems or hardware are
            fully secure from unauthorized access. We make no guarantees or representations of accuracy beyond those
            contained in any mutually executed agreement with our client that address the contents of this document.
            This document only provides our findings under the terms of our agreement with our client and does not
            constitute a representation or warranty that any systems or hardware are advisable to use or free of defects
            or malicious code.
          </p>
        </footer>
      </React.Fragment>
    );
  }
}
