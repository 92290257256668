const chartColors = {
  'sb-color-0': '#BCBFF7',
  'sb-color-1': '#676EE3',
  'sb-color-2': '#5C94E2',
  'sb-color-3': '#48B9E0',
  'sb-color-4': '#416391',
  'sb-color-5': '#5CF7D8',
  'sb-color-6': '#00B792',
  'sb-color-7': '#4F5169',
  'sb-color-8': '#61818C',
  'sb-color-9': '#30383B',
};

export default chartColors;
