import React, { Component } from 'react';

import './reusableDdrrTable.css';

export default class ReusableDdrrTable extends Component {
  render() {
    const { children, headers = [], tableName = '' } = this.props;
    return (
      <table className="table-bordered ddrr-table">
        <thead>
          <tr className="ddrr-theader">
            {headers.map((header, i) => {
              return (
                <th scope="col" key={tableName + i} className={header == 'Observations' ? 'm-tal-c' : ''}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    );
  }
}
