import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';

const makeCyberMap = function (data, container = 'ddrr-cyber-map') {
  am4core.addLicense('CH91205152');
  am4core.addLicense('MP91205152');

  // Create map instance
  var chart = am4core.create(container, am4maps.MapChart);

  // Set map definition
  chart.geodata = am4geodata_worldLow;

  // Set projection
  chart.projection = new am4maps.projections.Miller();

  // Create map polygon series
  var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

  // Make map load polygon (like country names) data from GeoJSON
  polygonSeries.useGeodata = true;

  // Configure series
  var polygonTemplate = polygonSeries.mapPolygons.template;
  polygonTemplate.tooltipText = '{name}';
  polygonTemplate.fill = am4core.color('#cccccc');

  // Color codes
  // fortress blue: 004D71
  // fortress orange: ff9b2b

  //Adding DataPoçints to the Series
  polygonSeries.data = [data];

  // Create hover state and set alternative fill color
  var hs = polygonTemplate.states.create('hover');
  hs.properties.fill = am4core.color('#737373');

  // Add Image series
  var imageSeries = chart.series.push(new am4maps.MapImageSeries());

  //Create the circles
  var imageSeriesTemplate = imageSeries.mapImages.template;
  var circle = imageSeriesTemplate.createChild(am4core.Circle);
  circle.radius = 4;
  circle.fill = am4core.color('#004D71');
  circle.stroke = am4core.color('#ffffff');
  circle.strokeWidth = 1;
  circle.nonScaling = true;
  circle.tooltipText = '{title}';

  imageSeries.mapImages.template.propertyFields.longitude = 'longitude';
  imageSeries.mapImages.template.propertyFields.latitude = 'latitude';

  // Add the datapoints
  imageSeries.data = data;
  return chart;
};

const removeCyberMap = function (chart) {
  chart.dispose();
};

export { makeCyberMap, removeCyberMap };
