import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

export const TermsAndConditionsModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
        },
      }}
      contentLabel="Dispute Input"
    >
      <form data-test-id="Modal_Button-modal_tc">
        <div>
          <label
            data-test-id="Modal_Button-modal-label_tc"
            style={{ textAlign: 'left', 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}
          >
            <h3 className="pb-5">Terms of Use </h3>
            <p>
              <b className="pl-5">These Terms of Use were last updated on December 20, 2021.</b>
            </p>
            <p>
              These Terms of Use (including any future modifications, the &quot;Terms&quot;) govern your use of this
              website, as well as any other websites, applications, products, or services that include these Terms or a
              link to these Terms (collectively, the &quot;Services&quot;). The Services are made available to you by
              Fortress Information Security LLC and its subsidiaries, divisions, and affiliates (collectively,
              &quot;we,&quot; &quot;us,&quot; or &quot;Fortress&quot;). These Terms set out the agreement between us and
              you regarding how you can use the Services and what responsibilities you and we have to each other.
            </p>
            <p>
              Your use of this website, as well as the Services, are governed by these Terms of Use, regardless of how
              you access them (including but not limited to through the Internet, through a mobile network, or in any
              other manner). By using the Services, you are agreeing to these Terms of Use, both on your behalf, and on
              behalf of the company, government agency, or juridical entity that you are affiliated or associated with,
              and you represent and warrant that you have the authority to bind such entity to these Terms of Use - if
              you do not have such authority, you are not allowed to utilize the Services. If you do not agree to these
              Terms of Use, then you are not allowed to use the Services.{' '}
            </p>
            <p>
              These Terms of Use are written in the English language. We do not guarantee the accuracy of any translated
              versions. To the extent any translated versions conflict with the English language version, the English
              language version shall control.
            </p>
            <p>
              PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICES. THESE TERMS CONTAIN IMPORTANT INFORMATION
              REGARDING YOUR LEGAL RIGHTS, INCLUDING MANDATORY ARBITRATION, NO CLASS RELIEF AND WAIVER OF YOUR RIGHT TO
              A JURY TRIAL.
            </p>
            <p>
              <b className="pl-5"> Acceptance of Terms; Requirements of Use; Export Controls</b>
            </p>
            <p>
              By applying to use the Services, using the Services, or submitting data or information via the Services,
              you agree to follow any additional guidelines or rules provided by us, as may change from time to time. We
              may make changes to these Terms of Use, and it is your responsibility to check these Terms of Use to
              determine when it was last updated and to ensure your continued compliance with any revised terms.
            </p>
            <p>
              You represent that you have reached the age of legal age to agree to these Terms of Use in the
              jurisdiction where you reside, and if you are not that you have obtained parental consent. If you are
              under the age of eighteen you are not permitted to use the Services. You represent and warrant that (i)
              you are not located in a country that is (a) subject to a U.S. government embargo (for example, Crimea,
              Cuba, Iran, North Korea or Syria) or (b) on Title 15, Part 740 Supplement 1 Country Group E of the U.S.
              Code of Federal Regulations, (ii) you are not listed on any U.S. or United Nations Security Council (UNSC)
              list of prohibited or restricted parties, including the list of Specially Designated Nationals and Blocked
              Persons administered by the U.S. Treasury Department or the U.S. Commerce Department&apos;s Denied Persons
              List, and (iii) you are not otherwise the target of U.S. or UNSC economic sanctions. You may not access,
              download or otherwise use any Services in violation of United States or UNSC export control or economic
              sanctions laws and regulations. Nothing related to the Services may be downloaded or otherwise exported or
              re-exported in violation of applicable laws, including without limitation to any end user in a U.S.
              embargoed country or territory or an end user included on any U.S., or UNSC government list of prohibited
              or restricted parties.
            </p>
            <p>
              Your use of the Services must comply with all applicable laws, rules, and regulations. You agree that you
              will not use any robot, spider, site search/retrieval application or other manual or automatic device to
              retrieve, index, &quot;scrape,&quot; &quot;data mine,&quot; or in any way gather content from the
              Services, any websites associated with the Services, or reproduce or circumvent the navigational structure
              or presentation of the Services without our express prior written consent. Notwithstanding the foregoing,
              we grant the operators of public online search engines limited permission to use search retrieval
              applications to reproduce materials from publicly viewable areas associated with the Services for the sole
              purpose of and solely to the extent necessary for creating publicly available searchable indices of such
              materials solely in connection with each operator&apos;s public online search service. We reserve the
              right to revoke these exceptions either generally or in specific instances.
            </p>
            <p>
              We may monitor your use of the Services but have no obligation to do so. any monitoring we may conduct
              will be done so in accordance with applicable law. We may use information or data collected during your
              use of the Services in accordance with our Privacy Policy,{' '}
              <Link to="/help/privacyPolicy">linked here</Link>. These Terms of Use, as amended from time to time, and
              including the
              <Link className="pl-2" to="/help/privacyPolicy">
                Privacy Policy
              </Link>
              , constitute the entire agreement between us and you with respect to your use of the Services, unless you
              have entered into a separate Master Services Agreement or other contract directly with us previously, in
              which case the previously entered-into contract controls wherever there is a conflict between these Terms
              of Use and the prior contract.
            </p>
            <p>
              We may, in our sole discretion and at any time without notice to you, terminate, suspend, or restrict your
              use of the Services for any reason, including your failure to comply with such rules as listed in this
              section. We may also charge for the Services, in which case you agree to pay all amounts owed to us under
              the prevailing rates provided to you by us associated with the use of the Services.
            </p>
            <p>
              <b className="pl-5"> Informational Purposes Only</b>
            </p>
            <p>
              The Services offer a range of content and features containing information for general guidance only, such
              as to assist you in your independent evaluation of vendors or other service providers. While we take
              reasonable efforts to offer current and accurate information where possible, the Services may contain
              references to certain laws and regulations which may have changed over time and may contain inaccurate
              information, including but not limited to information self-posted by various vendors that is not subject
              to independent evaluation by us for accuracy. You also agree that any evaluation, or rating, of any
              companies listed on the Services may be inaccurate, and that you will not rely upon any information from
              the Services to form a basis to take, or refrain from taking, any action. If you are posting information
              online through the Services, you represent and warrant that, after a reasonably diligence investigation,
              you have determined that all such information is true and accurate.
            </p>
            <p>
              <b className="pl-5"> Intellectual Property</b>
            </p>
            <p>
              Unless noted otherwise, the Services and all content posted via the Services are protected by copyright,
              trademark, and other laws of the United States. We reserve all rights not expressly granted in these Terms
              of Use. No part of the materials posted and available via the Services, including but not limited to the
              text, graphics, and html code, may be reproduced, or transmitted in any form, or by any means without our
              written permission. We comply with the copyright notice-and-takedown procedures set out in the United
              States Digital Millennium Copyright Act (DMCA), which applies to content reported and removed for
              violating U.S. copyrights, and please see our DMCA policy available at this link to submit a takedown
              request.
            </p>
            <p>
              Any downloadable information you may access and use on the Services must be used as they appear and must
              maintain all copyright or other notices or disclaimers precisely as posted by us, without modification,
              removal, or white-labeling by you. We expressly prohibit any redistribution, retransmission, commercial
              exploitation, linking, or other uses without our express written consent.{' '}
            </p>
            <p>
              &quot;Fortress&quot;, &quot;A2V&quot;, &quot;Fortress Information Security, LLC&quot;, and the Fortress
              logo, and certain product names that appear or are displayed on the Services (collectively, the
              &quot;Fortress Marks&quot;), are our trademarks or registered trademarks.
            </p>
            <p>
              <b className="pl-5"> User Content</b>
            </p>
            <p>
              The Services may include forums and other opportunities for you and other users to upload, post, transmit,
              or otherwise distribute (collectively, to &quot;Upload&quot;) content and materials (upon Upload,
              &quot;User Content&quot;). When you Upload any User Content, you hereby grant us and our licensees a
              perpetual, irrevocable, worldwide, royalty-free, fully paid up, sub-licensable through multiple tiers,
              transferable, non-exclusive license to use, reproduce, adapt, publicly display, publicly perform,
              synchronize and otherwise exploit that User Content, including any Personal Elements (as defined below) in
              your User Content, in any manner and any media, formats, and channels now known or later developed or
              discovered throughout the universe in perpetuity, including in connection with advertising, promotions or
              Services, without notice or payment to you. In some circumstances, you may also designate User Content as
              confidential upon Upload (upon Upload, &quot;Confidential User Content&quot;), and you may also request
              Confidential User Content that has been Uploaded by others. If you upload Confidential User Content, you
              will have the ability to approve or disapprove requests by other Users for the Confidential User Content,
              as well as to add any confidentiality terms or restrictions on use for the User requesting the
              Confidential User Content. If you request Confidential User Content, you agree that the User that uploaded
              the Confidential User Content may exercise their sole discretion in approving or denying any requests for
              the Confidential User Content, and that Fortress is not liable or in any way responsible or associated
              with any User&apos;s decisions to approve or deny any specific request for any Confidential User Content,
              and you agree not to challenge or otherwise contest any User decision to approve or deny access to any
              Confidential User Content.
            </p>

            <p>
              When you Upload any User Content or Confidential User Content, you represent and warrant that you own that
              User Content or Confidential User Content or have sufficient intellectual property and proprietary rights
              in order to make the grants in these Terms. You agree to pay any monies owed to any party based on our and
              our licensees&apos; use of your User Content or Confidential User Content.
            </p>
            <p>
              You acknowledge that you have no expectation of privacy or confidentiality with respect to any User
              Content, unless designated as Confidential User Content. While we may offer you the ability to Upload User
              Content anonymously, we may still store your account information. For some of our features, other members
              may be able to publish their own comments to your comments. We may use User Content for any purpose,
              including to develop aggregate ratings, personalize site views, or market Content or other products.
            </p>
            <p>
              If you Upload any User Content, you may not be able to remove it from the Services. We make no guarantees
              to remove User Content from the Services. Even if the Services give you an opportunity to delete User
              Content, we may retain the User Content in our backup files, which are not publicly available. We retain
              the right to make use of your Content in accordance with these Terms of Use even after your User Content
              is deleted. You acknowledge that (i) deletion of your User Content from the Services will not result in,
              and we are not responsible for, the deletion of the User Content by third parties who previously had
              access to that User Content and (ii) termination of your account will not automatically delete User
              Content you Uploaded.
            </p>
            <p>
              We also reserve the right to limit the storage capacity of your User Content or Confidential User Content.
              You assume full responsibility for maintaining backup copies of your User Content or Confidential User
              Content and we assume no responsibility for any loss of your User Content or Confidential User Content,
              for instance, due to its removal by us.
            </p>
            <p>
              We are not responsible or liable for any User Content or Confdential User Content, or any decisions made
              based on User Content or Confidential User Content. You are solely responsible for the User Content or
              Confidential User Content that you Upload and for any claims, losses or damages relating thereto.
            </p>
            <p>
              We do not endorse any User Content or Confidential User Content that you or other users Upload, and we may
              remove or refuse to post any User Content or Confidential User Content that, in our sole discretion, is
              objectionable or violates these Terms of Use. You acknowledge that you may encounter User Content or
              Confidential User Content on or through the Services that you find objectionable, offensive or otherwise
              inappropriate and you shall have no right against us based on User Content or Confidential User Content.
            </p>
            <p>
              For User Content not designated Confidential User Content, we and our partners may display advertising,
              promotions and other content in connection with your User Content and you will not be entitled to any
              associated revenue. You agree that we and our licensees may give you credit for your User Content, but are
              not required to so. To the extent permitted by applicable law, you hereby waive and agree not to assert
              any &quot;moral rights&quot; or other proprietary rights in your User Content or Confidential User Content
              against us, our licensees, our representatives or other users. When you Upload any User Content, you also
              consent to the recording, use and reuse by us and our licensees of your voice, actions, likeness, name,
              appearance, profile photograph, performance, biographical material, and any other identifying information
              in your User Content as used or modified by us (collectively, &quot;Personal Elements&quot;).
            </p>
            <p>
              <b className="pl-5"> Unsolicited Submissions</b>
            </p>
            <p>
              We do not accept unsolicited submissions for any media, products or services. Please do not make
              unsolicited submissions to us through the Services, including submissions by e-mail, text messages or
              other means (collectively, &quot;Submissions&quot;). We are not responsible for any similarity, in any
              media, of the Services to your Submissions. Any Submissions will be deemed User Content and subject to the
              grants by you applicable to User Content in these Terms of Use.
            </p>
            <p>
              <b className="pl-5"> </b>
            </p>
            <p>
              We have not agreed to and do not agree to treat as confidential any comments, information, ideas,
              concepts, reviews, techniques or other communication you may send to us, including via responses to
              questionnaires and other methods (&quot;Feedback&quot;). We shall be free to use, profit from, disclose,
              publish, or otherwise exploit any Feedback without compensation to you. Our receipt of your Submissions or
              Feedback is not an admission by us of their novelty, priority or originality and does not limit our right
              to contest intellectual property rights related to your Submissions or Feedback. Any Feedback will be
              deemed User Content and subject to the grants by you applicable to User Content in these Terms of Use.
            </p>
            <p>
              You hereby appoint us as your agent with full authority to execute any document or take any action we may
              consider appropriate in order to confirm the rights granted by you to us in this Agreement associated with
              Submissions and Feedback.
            </p>
            <p>
              <b className="pl-5"> Disclaimers and Limitations of Liability; Indemnity</b>
            </p>
            <p>
              <b className="pl-5"> </b>
            </p>
            <p>
              THE SERVICES (INCLUDING, WITHOUT LIMITATION, ANY CONTENT OR OTHER PART THEREOF) ARE PROVIDED FOR
              INFORMATIONAL PURPOSES ONLY, AND WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES REGARDING ACCURACY. WE
              ALSO MAKE NO REPRESENTATION OR WARRANTIES WITH RESPECT TO THE FUNCTIONALITY OR AVIALABILITY OF THE
              SERVICES. THE SERVICES ARE MADE AVAILABLE ON AN &quot;AS-IS&quot; BASIS AND WE MAKE NO EXPRESS OR IMPLIED
              REPRESENTATIONS OR WARRANTIES REGARDING THE SERVICES. WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY FOR ANY
              ERRORS OR OMISSIONS IN THE CONTENT DISPLAYED OR PROVIDED THROUGH THE SERVICES. WITHOUT LIMITING THE
              FOREGOING, WE DO NOT WARRANT THE SERVICES WILL BE ERROR-FREE, SECURE, FREE FROM VIRUSES, OR FREE FROM
              MALICIOUS CODE.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL LIABILITY AND SHALL NOT BE LIABLE FOR ANY
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR
              LOST PROFITS OR REVENUES, GOODWILL, WORK STOPPAGE, SECURITY BREACHES, VIRUSES, COMPUTER FAILURE OR
              MALFUNCTION, USE, DATA OR OTHER INTANGIBLE LOSSES OR COMMERCIAL DAMAGES, EVEN IF ANY OF SUCH PARTIES ARE
              ADVISED OF THE POSSIBILITY OF SUCH LOSSES, ARISING UNDER OR IN CONNECTION WITH THESE TERMS, THE SERVICES,
              THE USE OF OR INABILITY TO USE THE SAME, OR ANY OTHER SUBJECT MATTER HEREOF.
            </p>
            <p>
              IN NO EVENT WILL OUR AGGREGATE LIABILITY TO YOU IN CONNECTION WITH THE SERVICES OR THESE TERMS OF USE
              EXCEED (A) THE AMOUNT (IF ANY) PAID BY YOU TO US IN THE SIX MONTHS IMMEDIATELY PRECEDING THE EVENT WHICH
              GAVE RISE TO THE LIABILITY OR (B) ONE HUNDRED DOLLARS ($100), WHICHEVER IS LESS.
            </p>
            <p>
              You agree, to the maximum extent permitted by applicable law, to defend, indemnify, and hold us and our
              insurers and affiliates harmless from and against any and all losses, damages, liabilities, costs
              (including reasonable attorneys&apos; fees) and expenses in connection with any claim arising out of or in
              connection with your use of the Services or your violation of these Terms of Use.{' '}
            </p>
            <p>
              <b className="pl-5"> Agreement to Arbitrate All Claims on Individualized Basis</b>
            </p>
            <p>
              You agree that any and all disputes or claims that have arisen or may arise between you and us that relate
              in any way to your use of the Services, our actions or omissions, or our agents&apos; actions or
              omissions, or any products or services sold, offered, or purchased from us shall be resolved through
              confidential, final and binding arbitration rather than in court, with the exception of any dispute
              relating to the enforcement or validity of our intellectual property rights. The Federal Arbitration Act
              (&quot;FAA&quot;) governs the interpretation and enforcement of this Agreement to Arbitrate.
            </p>
            <p>
              UNLESS WE AGREE OTHERWISE IN WRITING, EACH OF YOU MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
              BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE ACTION OR PRIVATE
              ATTORNEY GENERAL PROCEEDING. ALSO, TO THE EXTENT AVAILABLE BY LAW, AND SUBJECT TO THE DAMAGE LIMITATIONS
              DISCUSSED HEREIN, THE ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR, AND FOR THE BENEFIT OF, THE INDIVIDUAL
              PARTY SEEKING RELIEF. If a court decides that any part of this agreement to arbitrate is invalid or
              unenforceable, the other parts of this Section shall still apply. Specifically, if a court decides that
              applicable law precludes enforcement of any of this paragraph&apos;s limitations as to a particular claim
              or a particular request for a remedy (such as a request for public injunctive relief), then that claim or
              that remedy request (and only that claim or that remedy request) may be severed from the arbitration and
              may be brought in court, subject to your and our right to appeal the court&apos;s decision. All other
              claims shall be arbitrated.
            </p>
            <p>
              The arbitration will be conducted by the American Arbitration Association (&quot;AAA&quot;) under its
              rules and procedures, including the AAA&apos;s Supplementary Procedures for Consumer-Related Disputes (as
              applicable), as modified by this Section. The AAA&apos;s rules are available at www.adr.org. A form for
              initiating arbitration proceedings is available on the AAA&apos;s website at http://www.adr.org. The
              arbitration shall be held in Orange County, Florida, or at another mutually agreed location.{' '}
            </p>
            <p>
              The arbitrator&apos;s award shall be confidential, final and binding, and judgment on the award rendered
              by the arbitrator may be entered in any court having jurisdiction thereof. Payment of all filing,
              administration, and arbitrator fees will be governed by the AAA&apos;s rules.
            </p>
            <p>
              <b className="pl-5"> Applicable Law & Waiver of Jury Trial</b>
            </p>
            <p>
              You agree that the laws of the State of Florida, without regard to principles of conflict of laws, will
              govern these Terms of Use and any claim or dispute that has arisen or may arise between us, except as
              otherwise stated in these Terms. Where arbitration does not apply, you consent to submit to the exclusive
              jurisdiction of the federal and state courts located in Orange County, Florida, USA, and waive any
              jurisdictional, venue, or inconvenient forum objections thereto, and you specifically waive the right to a
              jury trial and acknowledge and agree that under no circumstances will you have the right to have a jury
              decide any claims or dispute regarding the Services. You understand that the Services are directed only to
              residents of the United States.
            </p>
            <p>
              <b className="pl-5"> Claims and Disputes Must Be Filed Within One Year & Waiver.</b>
            </p>
            <p>
              To the extent permitted by law, and without limiting the effect of any disclaimer contained herein, any
              cause of action or claim you may have with respect to your use of the Services, including, without
              limitation, any related product, services, or other content offered or provided by us must be commenced
              within one (1) year after the claim or cause of action arises. This section applies to you and your heirs,
              successors, and assigns.
            </p>
            <p>
              Our failure to exercise or enforce any right or provision of these Terms of Use shall not constitute a
              waiver of such right or provision. If any provision of these Terms of Use is found by a court of competent
              jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect
              to the parties&apos; intentions as reflected in the provision, and the other provisions of these Terms of
              Use remain in full force and effect.{' '}
            </p>
            <p>
              We may at any time assign our rights and obligations under these Terms of Use, in whole or in part,
              without notice to you. You may not assign your rights or obligations under these Terms of Use without our
              prior written consent. We may make changes to these Terms of Use from time to time, and it is your
              responsibility to check these Terms of Use to determine when it was last updated.
            </p>
          </label>
        </div>
      </form>
      <div>
        <button
          data-test-id="Modal_Button-button-close"
          className="btn btn-outline-primary btn-nav float-right"
          onClick={onClose}
          style={{ marginRight: '15px' }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export const PrivacyPolicyModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
        },
      }}
      contentLabel="Dispute Input"
    >
      <form data-test-id="Modal_Button-modal_pp">
        <div>
          <label
            data-test-id="Modal_Button-modal-label_pp"
            style={{ textAlign: 'left', 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}
          >
            <h3 className="pb-5">Privacy Policy</h3>

            <p>
              <b className="pl-5">This Privacy Policy was last updated on August 5, 2021.</b>
            </p>
            <p>
              Fortress Information Security LLC and its subsidiaries, divisions, and affiliates (collectively, “we,”
              “us,” or “Fortress”) wants you to be familiar with how we collect, use and disclose the information you
              provide by utilizing our website, as well as any other websites, applications, products, or services that
              include this Privacy Policy or a link to this Privacy Policy (collectively, the “Services”).
            </p>
            <p>
              Your use of this website, as well as the Services, are governed by this Privacy Policy, regardless of how
              you access them (including but not limited to through the Internet, through a mobile network, or in any
              other manner). By using the Services, you are agreeing to the terms in this Privacy Policy, both on your
              behalf, and on behalf of the company, government agency, or juridical entity that you are affiliated or
              associated with, and you represent and warrant that you have the authority to bind such entity to this
              Privacy Policy- if you do not have such authority, you are not allowed to utilize the Services. If you do
              not agree to the Privacy Policy, then you are not allowed to use the Services.
            </p>
            <p>
              This Privacy Policy is written in the English language. We do not guarantee the accuracy of any translated
              versions. To the extent any translated versions conflict with the English language version, the English
              language version shall control.
            </p>
            <p>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. BY USING THE
              SERVICES, YOU ACKNOWLEDGE YOU HAVE READ AND UNDERSTAND THE PRIVACY POLICY AND THAT WE WILL COLLECT, USE
              AND SHARE YOUR INFORMATION AS SET FORTH BELOW.
            </p>

            <p>
              <b className="pl-5">Collection of Personal Information</b>
            </p>
            <p>
              Fortress will collect personal information from you directly when you apply to use any of the Services,
              interact with our Services, contact us for any reason, use our Services, create an account, or retain our
              Services.
            </p>
            <p>
              We may receive personal information about you from information provided to us by our customers, as well as
              third-party affiliates or partners and from marketing companies that provide us with such information as a
              part of their relationship with us.
            </p>
            <p>
              We may combine this with information that we already have collected about you. Such collected information
              could include contact details (such as email address) and previous purchase history or interests.
            </p>
            <p>
              When you use our Services, we collect certain information about you automatically through our use of
              cookies (more information about our use of cookies follows later in this Privacy Policy) and similar
              technologies such as standard internet log information and usage information, including your IP address,
              browser type and language, access times, location, usage data, and referring website addresses.
            </p>
            <p>
              We may collect the following categories of personal information: (1) contact information including your
              name, email address, street address, city, state, zip code; (2) authentication information, including the
              user name and password that you use to register an account; and (3) IP address or mobile network
              information.
            </p>
            <p>
              We take commercially reasonable measure to ensure we collect and process the minimum amount of personal
              information from you that is necessary to conduct our business, provide you with web-based and mobile
              applications, communicate with you, customer support, user verification, provide you with information on
              our services and content, website maintenance and improvements, and comply with legal requirements.
            </p>

            <p>
              <b className="pl-5">Use and Disclosure of Information</b>
            </p>
            <p>
              Fortress may store and use your personally identifiable information for (i) product or Services
              fulfillment, (ii) voluntary marketing, promotional, and advertising purposes, such as to inform you of
              products or services available from us, (iii) internal operations, such as improving your customer
              experience – this includes auditing current interactions and optimizing user experience, (iv) detecting
              security incidents, protecting against malicious, deceptive activity, and taking all necessary and
              appropriate steps to mitigate current and future risk; (v) debugging and repairing internal information
              technology as necessary; (vi) undertaking internal research for technological development and
              demonstration; and (vii) to share with trusted third party service providers to help us perform activities
              to improve your customer experience.
            </p>
            <p>
              Third parties are prohibited from using your personally identifiable information beyond providing services
              to us and are required to maintain the information’s confidentiality and privacy. Fortress does not trade,
              rent, or sell your personal information to third parties.
            </p>
            <p>We may also share or disclose your personal information for the following limited purposes: </p>
            <ul>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                With third party service providers who perform services and functions on our behalf to support our
                interactions with you. These service providers may assist with technical operation of our Services,
                provide analytics, process orders, transactions and payments, or provide customer service. We may also
                share non-identifying information, such as aggregate statistics or usage information, with third
                parties.{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                As required by applicable law, statute, rule, regulation or professional standard, or through subpoena,
                search warrant or other legal process.{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>For regulatory compliance purposes. </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                When explicitly requested or consented to by you.{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                When required to deliver publications or reference materials requested by you.{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                For administrative or maintenance purposes related to the Services.{' '}
              </li>
            </ul>
            <p>
              Unless otherwise required by law, the personal information we collect will only be retained for so long as
              the information is needed for our professional, marketing, or analytic purposes or to comply with your
              request, or until you ask for the information be deleted.{' '}
            </p>
            <p>
              As with any other business, Fortress may merge with or be purchased by another company. If Fortress is
              acquired, the company that acquires us would have access to the information maintained by us, including
              personally identifiable information, but would continue to be bound by this Privacy Policy unless and
              until it is amended.
            </p>
            <p>
              <b className="pl-5">Children’s Privacy: No Users Under Eighteen Years of Age</b>
            </p>
            <p>
              Our Services are not targeted for use by children. We will never knowingly request or collect personal
              information from any child. If you are under eighteen years old, please do not provide your information
              through our Service. Upon notification that a child has provided us with personally identifiable
              information, we will delete the child’s personally identifiable information from our records. If you
              believe we might have any information from a child, please contact us at Compliance@FortressInfoSec.com.{' '}
            </p>
            <p>
              In addition, if you are a California resident under the age of eighteen, while we do not allow users under
              the age of eighteen and you should notify us so we may delete your information, specifically, you may
              request us to remove content or information posted on our websites or stored on our servers by (a)
              submitting a request in writing to Compliance@FortressInfoSec.com,and (b) clearly identifying the content
              or information you wish to have removed and providing sufficient information to allow us to locate the
              content or information to be removed. However, please note that we are not required to erase or otherwise
              eliminate content or information if (i) other state or federal laws require us or a third party to
              maintain the content or information; (ii) the content or information was posted, stored, or republished by
              another user; (iii) the content or information is anonymized so that the minor cannot be individually
              identified; (iv) the minor does not follow the instructions posted herein on how to request removal of
              such content or information; or (v) the minor has received compensation or other consideration for
              providing the content. Further, nothing in this provision shall be construed to limit the authority of a
              law enforcement agency to obtain such content or information.
            </p>
            <p>
              <b className="pl-5">Security</b>
            </p>
            <p>
              The security and confidentiality of your personal information is important to us. We follow reasonable
              commercial standards for organizational, technical, and administrative measures to protect the personal
              information submitted to us, both during transmission and once it is received.{' '}
            </p>
            <p>
              Please be advised, however, that while we take reasonable security measures to protect your personal
              information, such measures cannot be guaranteed to be secure. We cannot promise or guarantee that hackers,
              cybercriminals, or other unauthorized third parties will not be able to defeat our security, and
              improperly collect, access, steal, or modify your personal information. The security of your account
              relies on your protection of your user profile information.{' '}
            </p>
            <p>
              You are responsible for maintaining the security of your user profile information, including your password
              and for all activities that occur under your account. You may not share your password with anyone.{' '}
            </p>
            <p>
              We will never ask you to send your password or other sensitive information to us in an email, though we
              may ask you to enter this type of information to login to your account. Any email or other communication
              purporting to be from one of our websites requesting your password or asking you to provide sensitive
              account information via email, should be treated as unauthorized and suspicious and should be reported to
              us immediately. If you believe someone else has obtained access to your password, please change it
              immediately.
            </p>
            <p>
              If you believe any personal information you have submitted to us is unsecure or that someone has accessed
              your account, please notify us immediately at Compliance@FortressInfoSec.com.
            </p>
            <p>
              <b className="pl-5">Unsubscribe </b>
            </p>
            <p>
              If you no longer wish to receive marketing emails from us, you may opt-out of receiving such emails by
              following the unsubscribe instructions contained in any such email or by contacting us at
              Compliance@FortressInfoSec.com with the subject “unsubscribe.” Please note we still may need to send you
              transactional emails associated with the operation of the Services.
            </p>
            <p>
              <b className="pl-5">Request to Access, Know, and Delete</b>
            </p>
            <p>
              You may obtain certain information and access upon request, and you may also request deletion of certain
              information (any such request is hereinafter defined as a “Consumer Request”). This Privacy Policy
              outlines how you can request the information and what you can receive.
            </p>
            <p>
              If you would like to submit a Consumer Request, you can contact Fortress at
              Compliance@FortressInfoSec.com, write to us at 1 S. Orange Avenue, Suite 306, ATTN: COMPLIANCE, Orlando,
              Florida 32801.
            </p>
            <p>
              If you choose to submit a Consumer Request, you must provide us with enough information to identify you
              and enough specificity on the requested data. We will only use the information we receive to respond to
              your request and for compliance and legal purposes. Fortress will not be able to disclose information if
              it cannot verify that the person making the Consumer Request is the person about whom we collected
              information, or someone authorized to act on such person’s behalf.{' '}
            </p>
            <p>
              “Personal information” means information that identifies, relates to, describes, is capable of being
              associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or
              household, or as otherwise defined by applicable law.
            </p>
            <p>
              <b>Request to Access.</b> You may submit a Consumer Request to obtain a copy of or access to the personal
              information that Fortress has collected on you.{' '}
            </p>
            <p>
              <b>Request to Know.</b> You may submit a Consumer Request to receive information about Fortress’s data
              collection practices. You may request information on the categories of personal information (as defined by
              law) Fortress has collected about you; the categories of data collection sources; Fortress’s business or
              commercial purpose for collecting or selling personal information; the categories of third parties with
              whom Fortress shares personal information, if any; and the specific pieces of personal information we have
              collected about you
            </p>
            <p>
              Please note that the categories of personal information and sources will not exceed what is contained in
              this Privacy Policy. Additionally, Fortress is not required to retain any information about you if it is
              only used for a one-time transaction and would not be maintained in the ordinary course of business.
              Fortress is also not required to reidentify personal information if it is not stored in that manner
              already, nor is it required to provide the personal information to you more than twice in a twelve-month
              period.
            </p>
            <p>
              <b>Request to Delete.</b> You may request that Fortress delete your personal information. Subject to
              certain exceptions set out below we will, on receipt of a verifiable Consumer Request, delete your
              personal information from our records and direct any service providers to do the same.
            </p>
            <p>Please note that we may not delete your personal information if it is necessary to:</p>
            <ul>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                complete the transaction for which the personal information was collected;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                provide a good or service requested by you, or reasonably anticipated within the context of our ongoing
                business relationship with you, or otherwise perform a contract between you and us;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                detect security incidents, protect against malicious, deceptive activity, and take all necessary and
                appropriate steps to mitigate current and future risk;{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                debug and repair internal information technology as necessary;{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                undertake internal research for technological development and demonstration;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                exercise free speech, ensure the right of another consumer to exercise his or her right of free speech,
                or exercise another right provided for by law;{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                comply with the California Electronic Communications Privacy Act or any other law;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
                that adheres to all other applicable ethics and privacy laws, when our deletion of the information is
                likely to render impossible or seriously impair the achievement of such research, provided we have
                obtained your informed consent;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                enable solely internal uses that are reasonably aligned with your expectations based on your
                relationship with us;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>comply with an existing legal obligation; or</li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                otherwise use your personal information, internally, in a lawful manner that is compatible with the
                context in which you provided the information.
              </li>
            </ul>
            <p>
              Fortress will not treat you differently because of your Consumer Request activity. As a result of your
              Consumer Request activity, we may not and will not deny goods or services to you; charge different rates
              for goods or services; provide a different level quality of goods or services; or suggest any of the
              preceding will occur. However, we can and may charge you a different rate, or provide a different level of
              quality, if the difference is reasonably related to the value provided by your personal information.
            </p>
            <p>
              <b className="pl-5">Do Not Track Signals &apm; Cookies </b>
            </p>
            <p>Currently, we do not respond to “Do Not Track,” “DNT,” or similar signals. </p>
            <p>
              We may use cookies, web beacons, pixel tags, mobile analytics software, log files, or other technologies
              to collect certain information about your online activity and interactions with our Services which allows
              us to keep track of analytics and certain statistical information that enables us to improve our services
              and provide you with more relevant content and advertising.{' '}
            </p>
            <p>
              Some of the cookies we use are necessary to enable you to move around the Services and use its features.
              We also use functional cookies to record information about the choices you have made and to allow us to
              tailor the Services to our users; for example, to remember your language or region or that you have
              already completed a survey. This information is usually anonymized and is not used for any other purpose.
              We may also use analytic services to help us understand how effective our content is, what interests our
              users have, and to improve how the Service works. In addition, we use web beacons or tracking pixels to
              count visitor numbers and performance cookies to track how many individual users access the Services and
              how often. This information is used for statistical purposes only and it is not our intention to use such
              information to personally identify any user.{' '}
            </p>
            <p>
              Most web browsers are set to accept cookies by default but will allow you to see what cookies you have,
              delete them on an individual basis, or block cookies from particular or all websites (“opting out”).
              Please note that if you choose to remove, reject, or opt-out from cookies, this could affect the
              availability and functionality of the Services.
            </p>
            <p>
              <b className="pl-5"> Special Notice for Nevada Residents</b>
            </p>
            <p>
              Fortress does not sell, rent, or lease your personally identifiable information to third parties. However,
              if you are a resident of Nevada and would like to submit a request not to sell your personally
              identifiable information, you may do so by contacting us by email at Compliance@FortressInfoSec.com or
              writing to us at 1 S. Orange Avenue, Suite 306, ATTN: COMPLIANCE, Orlando, Florida 32801 or calling us at
              855-FORTRESS.
            </p>
            <p>
              <b className="pl-5"> Third Party Links</b>
            </p>
            <p>
              The Services may link to third party owned or operated websites including, but not limited to social media
              websites, as a convenient method of accessing information that may be useful or of interest to you. This
              Privacy Policy and the practices we follow do not apply to the linked websites. We are not responsible for
              the content, accuracy, or opinions expressed on any linked website or for the privacy practices or
              security standards used by third parties on such linked websites.{' '}
            </p>
            <p>
              <b className="pl-5"> Consent to Processing and International Transfer </b>
            </p>
            <p>
              The Services are controlled and operated by us from the United States. Fortress may process, transfer, and
              store your information on servers located in the United States. As a result, your personal information may
              be subject to data protection and other laws that may differ from your country of residence. Your personal
              information may be disclosed in response to inquiries or requests from government authorities or to
              respond to judicial process in the countries in which we operate. By using the Services, or by providing
              us with any information, you consent to the collection, processing, maintenance, and transfer of such
              information in and to the United States and other applicable territories in which the privacy laws may not
              be as comprehensive as, or equivalent to, those in the country where you reside or are a citizen.
            </p>
            <p>
              <b className="pl-5"> Updates</b>
            </p>
            <p>
              We may update this Privacy Policy at any time by publishing an updated version here. If we make changes in
              the way we collect or use information, we will notify you by posting an announcement on the website or
              sending you an email. You are bound by any changes to our Privacy Policy once you use the Services after
              such changes have been posted. Please review the “Last Updated” legend at the top of this page to see when
              this Privacy Policy was last revised.
            </p>
          </label>
        </div>
      </form>
      <div>
        <button
          data-test-id="Modal_Button-button-close"
          className="btn btn-outline-primary btn-nav float-right"
          onClick={onClose}
          style={{ marginRight: '15px' }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export const DMCANoticeModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
        },
      }}
      contentLabel="Dispute Input"
    >
      <form data-test-id="Modal_Button-modal_dn">
        <div>
          <label
            data-test-id="Modal_Button-modal-label_dn"
            style={{ textAlign: 'left', 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}
          >
            <h3 className="pb-5">COPYRIGHT POLICY</h3>
            <p>
              Fortress Information Security LLC and its subsidiaries, divisions, and affiliates (collectively,
              “Fortress”) comply with the copyright notice-and-takedown procedures set out in the United States Digital
              Millennium Copyright Act (DMCA), which applies to content reported and removed for violating U.S.
              copyrights. Please note that any notice or counter-notice you submit must be truthful and must be
              submitted under penalty of perjury. A false notice or counter-notice may give rise to personal liability.
              You may therefore want to seek the advice of legal counsel before submitting a notice or a counter-notice.
              We may share any notices and counter-notices submitted to us with others including your contact
              information, and by submitting any notices, you agree you have no expectation of privacy in your
              submission.
            </p>
            <p>
              If we determine that you are a repeat infringer, we may terminate your access to our service, remove or
              ban you (and any account you created or control), and take other appropriate action in our sole
              discretion.
            </p>
            <p>
              <b className="pl-5"></b>DMCA Takedown Notices{' '}
            </p>
            <p>
              Content owners of copyrighted material or their representing agents may submit a DMCA notice to our
              registered Copyright Agent if they believe that infringing activity has taken place on our service. The
              abuse team will only consider valid reports of infringement, and you may submit a complete DMCA notice
              that features all of the points described below only if the representing party sending the request is the
              content owner or the authorized agent acting on behalf of the copyright owner. If you are not sure if
              Fortress has control over the allegedly infringed content, please obtain legal representation before
              contacting us. To be effective under the DMCA, any notification of claimed infringement must be in a
              written communication that includes substantially the following which must include a certification made
              under penalty of perjury:
            </p>
            <ol type="i">
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
                right that is allegedly infringed, as well as information sufficient for Fortress to determine the
                legitimacy of the signature and the identity of the signatory;{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                Identification of the copyrighted work claimed to have been infringed, or, if a single notification
                covers multiple copyrighted works at a single online site, a representative list of such works at that
                site, including citation to the applicable copyright registrations where available;{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                Identification of the material that is claimed to be infringing or to be the subject of infringing
                activity and that is to be removed or access to which is to be disabled, and information reasonably
                sufficient to permit Fortress to locate the material, including a timestamp and visible identification
                of the material in a screenshot or comparable medium, with all metadata intact, as well as a hyperlink
                or URL to the website or online content at issue;{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                Information reasonably sufficient to permit Fortress to contact the complaining party, including an
                email address, telephone number, and, if available, physical mail address;{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                A statement that the complaining party has a good faith belief that use of the material in the manner
                complained of is not authorized by the copyright owner, its agent, or the law; and{' '}
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                A statement that the information in the notification is accurate, and under penalty of perjury, that the
                complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly
                infringed.{' '}
              </li>
            </ol>
            <p>
              Any such notifications of claimed infringement must be sent to the below contact with the subject line
              “DMCA NOTICE:”
            </p>
            <p>
              <a className="pl-5"> Fortress Copyright Agent</a>
            </p>
            <p>
              <a className="pl-5"> Losey PLLC</a>
            </p>
            <p>
              <a className="pl-5"> 1420 Edgewater Drive</a>
            </p>
            <p>
              <a className="pl-5"> Orlando, FL 32804</a>
            </p>
            <p>
              <a className="pl-5"> dmca@losey.law</a>
            </p>
            <p>
              Please note if any notification of claimed infringement does not meet the above requirements, Fortress has
              no responsibility to respond to or act on any such defective notification of claimed infringement.{' '}
            </p>
            <p>
              <b className="pl-5"></b>DMCA Counter Notification
            </p>
            <p>
              If you receive a notification of claimed infringement, you may submit a counter notification to us under
              the DMCA. It must include the following, which includes a certification made under penalty of perjury:
            </p>
            <ol type="i">
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                Your physical or electronic signature, as well as information sufficient for Fortress to determine the
                legitimacy of the signature and the identity of the signatory;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                Identification of the material that has been removed or to which access has been disabled and the
                location at which the material appeared before it was removed or access to it was disabled;
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                A statement under penalty of perjury that you have a good faith belief that the material was removed or
                disabled as a result of mistake or misidentification of the material to be removed or disabled; and
              </li>
              <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
                Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the
                Federal District court (i) in the judicial district where your address is located if the address is in
                the United States, or (ii) located in the Middle District of Florida, if your address is located outside
                the United States, and that you will accept service of process from the Complainant submitting the
                notice or the Complainant’s authorized agent.
              </li>
            </ol>
            <p>Any such counter notification must be sent to:</p>
            <p className="pl-5"> Fortress Copyright Agent</p>
            <p className="pl-5"> Losey PLLC</p>
            <p className="pl-5"> 1420 Edgewater Drive</p>
            <p className="pl-5"> Orlando, FL 32804</p>
            <p className="pl-5"> dmca@losey.law</p>
          </label>
        </div>
      </form>
      <div>
        <button
          data-test-id="Modal_Button-button-close"
          className="btn btn-outline-primary btn-nav float-right"
          onClick={onClose}
          style={{ marginRight: '15px' }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
