import React from 'react';

const Release_1_6_0 = () => (
  <div>
    <h3>
      <strong>June 2022</strong>
    </h3>
    <p>
      Fortress is proud to present Version 1.6 of the A2V Network. With this version, we have worked on the requests
      from the hundreds of users on our platform to add products to our network along with Fortress Software Bill of
      Materials Analysis, create an importer to upload lists of vendors to My Vendor list and to create training videos
      on the main features of the A2V marketplace.
    </p>
    <p>
      Version 1.6 will be live on June 1st. If you experience any issue within A2V, just raise the issue and we will
      review it for future versions.
    </p>
    <h4>
      <strong>Products</strong>
    </h4>
    <h5>Products Network</h5>
    <ul>
      <li>
        <p>
          Products Network Page: Products are now available in the marketplace along with vendors. A Products Network
          page has been created to browse through A2V available products and access their individual product profiles.
        </p>
      </li>
    </ul>
    <h5>Products Profiles</h5>
    <ul>
      <li>
        <p>
          Products Profile Page: Each product will now have a profile page with their related product, eventual vendor
          and SBOM information.
        </p>
      </li>
      <li>
        <p>
          SBOM Analysis Download: Fortress’ Software Bill of Materials Analysis is now available for purchase and
          download in products’ profiles.
        </p>
      </li>
    </ul>
    <h5>SBOM APIs</h5>
    <ul>
      <li>
        <p>
          SBOM API: An API has been created to return SBOM’s product and vendor information into A2V. The following data
          fields will be provided from this endpoint: vendor DDRR_ID, product search, product overview data and SBOM
          score data.
        </p>
      </li>
      <li>
        <p>
          SBOM Proxy Endpoint: A proxy endpoint had been created to return SBOM data from A2V into Fortress Platform.
          This endpoint will provide the following data points: SBOM data, metadata, hash, created date and Fortress
          SBOM Analysis.
        </p>
      </li>
      <li>
        <p>
          API Code Improvements: All instances of “new Promise” code have been reviewed to ensure they were all either
          resolved or rejected to prevent errors.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Bulk Vendor Importer</strong>
    </h4>
    <ul>
      <li>
        <p>
          Bulk Vendor Importer Pages: A page will be available in A2V for admin-type users to upload csv files with
          their vendors names and domains. Once the file is uploaded and scanned, users will be able to match all of
          their vendors to existing A2V marketplace vendors using Fortress vendor match algorithm and import the whole
          list to their My Vendors section.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Get Started Videos</strong>
    </h4>
    <ul>
      <li>
        <p>
          A2V Marketplace Training Videos: Training videos will now be available’ in a dedicated page to present the
          main features of the A2V marketplace. This page will be accessible from the floating “Get Started” button
          under the “Need Help” button everywhere on the platform.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Trust Center</strong>
    </h4>
    <h5>Access Requests Table</h5>
    <ul>
      <li>
        <p>
          Access Requests Vendor Details: Logo, company name and domain information in the Access Requests table now
          come from the vendors’ information available in the vendors’ profiles.
        </p>
      </li>
    </ul>
    <h5>Access Requested Email</h5>
    <ul>
      <li>
        <p>
          Trust Center Document Hyperlink: The link to the Trust Center document requested was added to the automatic
          Access Requested email for the user to access its Trust Center file requests directly from the email.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Contact</strong>
    </h4>
    <ul>
      <li>
        <p>
          Fortress Information Security Address Update: Fortress Information Security address has been updated to our
          new location in 1 S. Orange Avenue, Suite 306, Orlando, FL 32801.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Home</strong>
    </h4>
    <ul>
      <li>
        <p>
          Monitoring Findings Alignment: The text for count of Monitoring Findings has been adjusted to fit the box
          size.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Company Profile</strong>
    </h4>
    <h5>Available Reports</h5>
    <ul>
      <li>
        <p>
          Download of Continuous Monitoring Enhanced Reports: Download of Continuous Monitoring Enhanced Reports is not
          fixed and works as designed for CME reports that have been purchased.
        </p>
      </li>
      <li>
        <p>
          Uncaught Exception Error Bugfix: An uncaught exception error has been fixed and assessments purchases now work
          as designed.
        </p>
      </li>
    </ul>
    <h5>Logos</h5>
    <ul>
      <li>
        <p>
          Logo Size Standardization: Company Profile logos’ sizes have been standardized to be the same on all profiles.
        </p>
      </li>
    </ul>
    <h5>Foreign Influence </h5>
    <ul>
      <li>
        <p>
          Data Source Updates: Data sources for Foreign Influence have been modified to use the latest data warehouse
          views.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Network</strong>
    </h4>
    <ul>
      <li>
        <p>
          Cache and Store Vendor Logos: A function had been created to pull and save vendors logos from different
          sources before they are updated in the A2V database.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Admin</strong>
    </h4>
    <h5>User Invite</h5>
    <ul>
      <li>
        <p>
          Email Format: A warning message will now be displayed and prevent users to send an invitation with an invalid
          email format.
        </p>
      </li>
      <li>
        <p>
          First and Last Names Characters Restrictions: A warning message will now be displayed and prevent users to use
          symbols in First and Last Names form fields on an A2V user invitation.
        </p>
      </li>
      <li>
        <p>
          First and Last Names Character Limit: First and Last Names fields will now be limited to 64 character maximum
          on an A2V user invitation
        </p>
      </li>
    </ul>
    <h5>Transaction History</h5>
    <ul>
      <li>
        <p>
          Transaction History Table: A transaction history table will be displayed in the Admin section for Financial
          Admin to remove, refund or adjust price on A2V transactions. This table will enable Financial Admin to track
          any change or deletion on the subscription’s transaction.
        </p>
      </li>
    </ul>
    <h5>Subscriber Page</h5>
    <ul>
      <li>
        <p>
          SSO Flag: A Single-Sign-On flag has been added to Subscriber Admin page to allow SSO to connect to A2V.
          Password and confirmation fields will be disabled as soon as the SSO flag is turn on. This flag will also be
          available from the users’ list view along with reset user, forgot password and delete buttons.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Release Notes</strong>
    </h4>
    <ul>
      <li>
        <p>
          Release Note Page: A new process has been created to add release notes to the A2V marketplace and release
          notes for versions 1.4, 1.5 and 1.6 will be added to the release notes page.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Fortress Platform Integration</strong>
    </h4>
    <h5>DDRR API</h5>
    <ul>
      <li>
        <p>
          Company URL Redirection: If a company URL is provided to identify a vendor, the API will return if the URL
          redirected to another URL and will display the redirected URL, if any. Results will be reorder by first
          results related to the input URL, second results related to the redirected URL and then results related to the
          company name.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Activity Log</strong>
    </h4>
    <ul>
      <li>
        <p>
          New Dashboard for Activity Logs: A new dashboard for activity logs has been created to display simplified
          errors and messaging on user activity for all user roles. Logs will be available on purchases, A2V
          invitations, login, vendor addition, page claimed and Trust Center document requests and upload.
        </p>
      </li>
    </ul>
  </div>
);

export default Release_1_6_0;
