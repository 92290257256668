import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { round } from 'lodash';

const chartColors = require('./chartColors');

const makeSpendBreakdownChart = function (spendBreakdown, chartSize) {
  String.prototype.splice = function (idx, str) {
    return this.slice(0, idx) + str + this.slice(idx);
  };

  am4core.addLicense('CH91205152');
  am4core.useTheme(am4themes_animated); // Theme
  // var chart = am4core.create("db-sb-chart", am4charts.PieChart);// Create chart instance
  var chart = am4core.create(`${chartSize === 'mobile' ? 'db-sb-chart-mobile' : 'db-sb-chart'}`, am4charts.PieChart); // Create chart instance
  chart.data = [];

  let index = 0;
  for (let assessment in spendBreakdown) {
    chart.data.push({
      type: assessment,
      count: (round(Number(spendBreakdown[assessment]), 2)).toString().concat(' Tokens'),
      color: am4core.color(`${chartColors.default[`sb-color-${++index}`]}`),
    });
  }

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = 'count';
  pieSeries.dataFields.category = 'type';
  pieSeries.innerRadius = am4core.percent(65);
  pieSeries.ticks.template.disabled = true;
  pieSeries.labels.template.disabled = true;
  pieSeries.slices.template.stroke = am4core.color('white');
  pieSeries.slices.template.propertyFields.fill = 'color';
  pieSeries.legendSettings.valueText = '{value}';
  chart.legend = new am4charts.Legend();
  chart.legend.position = 'left';

  return chart;
};

export { makeSpendBreakdownChart };
