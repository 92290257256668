import React from 'react';

const Release_1_7_0 = () => (
  <div>
    <h3>
      <strong>October 2022</strong>
    </h3>
    <p>
      Fortress is proud to present version 1.7 of the A2V Network. Our focus for this version was to build Fortress SBOM
      (Software Bill of Materials) module which gives users the ability to search for SBOMs using a brand new advanced
      search feature, buy and request new SBOMs creation from the marketplace and for our vendors to securely upload,
      manage and share their products and SBOMs.
    </p>
    <p>
      Version 1.7 will be live on October 6th, 2022. If you experience any issue within A2V, just raise the issue and we
      will review it for future versions.{' '}
    </p>
    <h4>
      <strong>Software Bill of Materials</strong>
    </h4>
    <h5>SBOM Module Access</h5>
    <ul>
      <li>
        <p>
          Fortress SBOM module is now subscription-based and only available to SBOMs subscribers. Individual SBOM
          reports prices are now set to 0 token and deducted from the amount of purchasable SBOMs in users
          subscriptions.
        </p>
      </li>
    </ul>
    <h5>Product Profiles</h5>
    <ul>
      <li>
        <p>
          Product Profile has been redesigned to highlight SBOM’s products most important information and improve our
          user experience on the product pages.
        </p>
      </li>
      <li>
        <p>A2V now supports 2 different types of SBOMs: Open Source SBOM and Supplier Provided SBOM.</p>
      </li>
      <li>
        <p>
          Product Profiles now include a product version dropdown that enable users to see the SBOM information and buy
          SBOM reports for a specific version.
        </p>
      </li>
      <li>
        <p>
          SBOM product version dropdown now include the type of SBOMs available. Version and SBOM type will also be
          displayed on the Available Reports section of the product profile.
        </p>
      </li>
      <li>
        <p>
          Requests and Approval flow from the Available Reports section has been updated from Add to Cart to Request
          Pending to Download or Denied to account for supplier provided SBOMs types.
        </p>
      </li>
      <li>
        <p>
          Product Profiles now redirect to default product version whenever a version that does not exist is entered by
          the user.
        </p>
      </li>
      <li>
        <p>
          SBOM Production &amp; Analysis report has been renamed to Open Source SBOM Production &amp; Analysis to match
          the Open Source SBOM type.
        </p>
      </li>
      <li>
        <p>
          SBOM reports download process has been improved to reduce the downtime between the user click and the actual
          start of the download.
        </p>
      </li>
    </ul>
    <h5>Vendor Trust Center Products Management</h5>
    <ul>
      <li>
        <p>
          Admin vendor users can now add and manage their products inside the Products Management table of their Trust
          Center. Vendors’ products will be automatically synchronized with A2V product catalog and have their own
          product page available from the Products Network page.
        </p>
      </li>
      <li>
        <p>
          The edit modal of the Vendor Products Management section has been redesigned and contains typeaheads for
          reusable fields to improve admin vendor users experience when they create and edit their products.
        </p>
      </li>
      <li>
        <p>
          Admin vendor users can also add and manage their products’ SBOM directly from the SBOM uploader of the edit
          modal of the Product Management section of the Trust Center.{' '}
        </p>
      </li>
      <li>
        <p>
          The product version drop down of the SBOM uploader now uses the same drop down used in the Trust Center
          products table.
        </p>
      </li>
      <li>
        <p>
          Admin vendor users can now upload an SBOM document related to one of their products for a defined product
          version and get Fortress SBOM Analysis on their document.
        </p>
      </li>
      <li>
        <p>
          Error and warning messages will be displayed to the admin vendor user upon upload of an SBOM which cannot lead
          to a complete SBOM Analysis from Fortress SBOM Analysis engine.
        </p>
      </li>
      <li>
        <p>
          An email will be sent to Admin vendor users if the analysis of their SBOM file failed so that they can review
          the error message or upload another file.
        </p>
      </li>
      <li>
        <p>
          Admin vendor users now receive an email notification whenever the SBOM Analysis of the SBOM they uploaded on
          the Trust Center Product table is ready for download in A2V.
        </p>
      </li>
      <li>
        <p>
          Admin vendor users can now download the Supplier Provided SBOM Analysis of their SBOMs directly from the
          Products Management table of their Trust Center.
        </p>
      </li>
      <li>
        <p>
          Supplier-provided SBOMs approval flow is now built so that these SBOMs will work like any other Trust Center
          document in terms of process and approval mechanism.
        </p>
      </li>
      <li>
        <p>
          SBOM category of the Trust Center Documents has been removed since it is now available on the product table of
          the Trust Center.
        </p>
      </li>
      <li>
        <p>
          SBOMs Products links of the Products Management table redirect users to the right product and product version
          of the product profile page.
        </p>
      </li>
      <li>
        <p>Users can now request SBOMs from vendors directly from their Trust Center Products section.</p>
      </li>
      <li>
        <p>
          Fortress Operation team will now be notified via A2V’s ticketing system of any SBOM request for new or
          existing products and everything from request to delivery will be tracked in the ticket.
        </p>
      </li>
      <li>
        <p>
          SBOMs can now be selected and requested at product version level instead of product level both from the
          Product Profile and the Vendor Trust Center Product Management Table.
        </p>
      </li>
    </ul>
    <h5>Products Network</h5>
    <ul>
      <li>
        <p>
          Product versions on the Product Network cards have been fixed to match the versions displayed in the product
          profiles.
        </p>
      </li>
      <li>
        <p>
          Products Network navigation have been fixed so that pagination now redirects users to the right page of the
          Products Network.
        </p>
      </li>
      <li>
        <p>Order confirmation of product reports is now fixed and will not display undefined products data.</p>
      </li>
      <li>
        <p>Product reports have been fixed and can now be viewed directly after the PDF is downloaded. </p>
      </li>
    </ul>
    <h5>Advanced Search</h5>
    <ul>
      <li>
        <p>
          A2V Advanced Search feature is now available from the Product Network page. This search feature allow users to
          search for SBOMs by Vendor, License, CVE, Malware, Hash, Component, PURL or CPE.
        </p>
      </li>
      <li>
        <p>The Product Network page can now be filtered by SBOM types.</p>
      </li>
    </ul>
    <h5>Database Enhancements</h5>
    <ul>
      <li>
        <p>
          A2V SBOM data and metadata is now synchronized with Fortress File Integrity Assurance Application so that any
          edit made to the SBOM data in A2V will be available on any of the Fortress products.
        </p>
      </li>
      <li>
        <p>A2V assessment types now included the newly added SBOM types.</p>
      </li>
      <li>
        <p>Documentation for the Fortress Platform SBOM proxy is now available on the documentation site.</p>
      </li>
    </ul>
    <h5>Notification Emails</h5>
    <ul>
      <li>
        <p>
          SBOMs notification emails’ hyperlinks have been updated to redirect users to the relevant product of the
          vendor’s Trust Center page.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Vendor Profile</strong>
    </h4>
    <h5>Continuous Monitoring</h5>
    <ul>
      <li>
        <p>
          User can now download historical Continuous Monitoring Report Enhanced by selecting the report’s month on the
          Continuous Monitoring Report Enhanced row of the reports table inside vendors profiles.
        </p>
      </li>
      <li>
        <p>
          Cyber Hygiene, Cyber Presence, Physical Location, Corporate Families, Foreign Ownership, Merges &amp;
          Acquisitions and Compliance data queries have been updated to support future historical views.{' '}
        </p>
      </li>
      <li>
        <p>
          Continuous Monitoring Enhanced report has been fixed and can now be viewed directly after the PDF is
          downloaded.{' '}
        </p>
      </li>
    </ul>
    <h5>Vendor Profile Dashboards</h5>
    <ul>
      <li>
        <p>
          Cyber Hygiene data has been so that the dashboards on the vendor profile and the Continuous Monitoring Report
          Enhanced now display the same information.
        </p>
      </li>
      <li>
        <p>
          The products section of the vendor overview has been removed since it is now replaced with the Trust Center
          Products Table.
        </p>
      </li>
      <li>
        <p>
          The download of Vulnerabilities table of the vendor profile has been fixed and the file downloaded now
          displays all the Vulnerabilities data from the A2V table.
        </p>
      </li>
      <li>
        <p>
          While purchasing different vendors A2V questionnaire, vendor contact information now reset properly for users
          to enter the vendor contact details to use to initiate the assessment process.
        </p>
      </li>
    </ul>
    <h5>Trust Center Documents</h5>
    <ul>
      <li>
        <p>The Trust Center Document section has been fixed and now display documents as designed.</p>
        <ul>
          <li>
            <p>
              The Request Access of NATF template has been fixed and users can now request access to the NATF template
              without getting an error.
            </p>
          </li>
        </ul>
      </li>
    </ul>
    <h4>
      <strong>Vendor Bulk Importer</strong>
    </h4>
    <ul>
      <li>
        <p>
          Vendor Bulk Importer Interface has been redesigned to provide an cleaner and easier experience to admin users
          who need to review the potential A2V vendor matches with their vendor list.
        </p>
        <ul>
          <li>
            <p>
              A2V selected vendor column now provide a drop down of possible A2V vendor matches with vendors name,
              domain and the matching percentage to easily separate high confidence matches with low confidence matches.
            </p>
          </li>
          <li>
            <p>The dropdown will be prepopulated with the highest match if the confidence percentage is above 95%.</p>
          </li>
        </ul>
      </li>
      <li>
        <p>Vendors’ Logo loading of the Vendor Bulk Importer landing page has been fixed and now works as designed.</p>
      </li>
    </ul>
    <h4>
      <strong>Vendors Network</strong>
    </h4>
    <ul>
      <li>
        <p>
          New vendors can now be added to A2V without cost since the purchase of Data Driven Risk Rank and Cyber Scan
          will not be required for vendor creation.
        </p>
      </li>
      <li>
        <p>
          While adding a new vendor on the Vendors Network, the creation form will remove any character entered by the
          user in the search bar to ensure the user enters the full vendor name to be created into the form.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Navigation</strong>
    </h4>
    <ul>
      <li>
        <p>A2V’s top navigation bar has been updated to match the size of the buttons and text.</p>
      </li>
      <li>
        <p>Unused A2V pages have been removed from A2V’s application.</p>
      </li>
    </ul>
    <h4>
      <strong>Home</strong>
    </h4>
    <ul>
      <li>
        <p>Transactions table is now fixed and display the subscriber’s transaction on the Home page.</p>
      </li>
    </ul>
    <h4>
      <strong>Admin </strong>
    </h4>
    <ul>
      <li>
        <p>
          new button in the Admin &gt; Subscriber page has been renamed to New to match A2V’s buttons capitalization
          standards.
        </p>
      </li>
      <li>
        <p>
          Upload your vendors window of the vendors bulk importer has been resized so that the text is still readable
          while a file is added to the drag and drop area.
        </p>
      </li>
      <li>
        <p>Subscriber’s transactions are now available for A2V financial admin roles.</p>
      </li>
      <li>
        <p>The Save button of the Subscriber’s details page now enable users to save transaction edits as well.</p>
      </li>
      <li>
        <p>the duplication of users on multiple pages of the user list has been resolved.</p>
      </li>
    </ul>
    <h4>
      <strong>Operations Improvements</strong>
    </h4>
    <ul>
      <li>
        <p>
          Vendor Contact Information entered in A2V by users during their checkout process is now automatically captured
          by Fortress ticketing system to improve the efficiency of the operation team that manages orders of Validated
          Security Controls Assessment shares.
        </p>
      </li>
    </ul>
    <h5>Jira API Integration</h5>
    <ul>
      <li>
        <p>
          A2V now leverage the Jira API to create Jira organizations out of subscriber names and create/add customer
          users to organizations to facilitate operation requests management.
        </p>
      </li>
      <li>
        <p>A2V can now interrupt the sending of Jira API calls thanks to an additional parameter.</p>
      </li>
    </ul>
    <h4>
      <strong>Application Security</strong>
    </h4>
    <ul>
      <li>
        <p>A2V now destroys the session ID value and/or cookie on logoff or browser close.</p>
      </li>
      <li>
        <p>15-character password length minimum is now required for any A2V user to log in.</p>
      </li>
      <li>
        <p>
          A2V now enforces a limit of three consecutive invalid logon attempts by user during a 15 minute time period.
        </p>
      </li>
      <li>
        <p>
          A2V now displays an explicit logoff message to users indicating the reliable termination of authenticated
          communications sessions.
        </p>
      </li>
      <li>
        <p>A2V now implements a session timeout of 15 minutes.</p>
      </li>
      <li>
        <p>A2V now provides a capability to limit the number of logon sessions per user.</p>
      </li>
      <li>
        <p>
          During the Forgot Password process, A2V now messages users that they can’t reuse a previous password and
          prevent them from registering with a previous password.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Database Improvements</strong>
    </h4>
    <ul>
      <li>
        <p>Vendor and Product assessments requests queries have been updated to match the new database format.</p>
      </li>
      <li>
        <p>
          The mapping between shared assessment and parent assessment has been updated to simplify the logic and improve
          the operations processes to deliver shared assessments.
        </p>
      </li>
      <li>
        <p>A2V database tables have been updated to match our data warehouse structure.</p>
      </li>
    </ul>
  </div>
);

export default Release_1_7_0;
