import React from 'react';
import RcTooltip from 'rc-tooltip';

const tooltips = {
  'Companies Monitored': 'Count of companies across all portfolios that are monitored and scanned.',
  'Cyber Risk Total': 'Weighted average score of DNS, SSL, Application Security scores.',
  'Total Products Scanned':
    'Count of active products (also referred to as “targets”) being monitored across all companies. Products represent externally-facing websites, sub-domains and IP addresses.',
  'Total Vulnerabilities':
    'Count of weakness which can be exploited by a threat actor and are tied back to the Common Vulnerabilities and Exposure (“CVE”) framework. Example vulnerabilities include ability to create a denial-of-service, malicious code execution, data overflow (causing system disruption), memory corruption, database injection, cross-site scripting (“XSS” which can be used to obtain private data or direct a user to an attacker-controlled web location), directory traversal (accessing restricted files), Http response splitting (ability to control Http responses and send malicious content), information access, grant user-privilege, cross-site request forgery (“CSRF” causing a user to execute unwanted actions such as changing email address, password or transferring funds) and file inclusion (attacker changes which code is called on the user’s web browser).',
  DNS: 'Domain Name System (DNS) score - Weaknesses in DSN allow threat actors to intercept data from web-site users, email and databases. DNS is vital to the internet, providing a mechanism for resolving host names into Internet Protocol (IP) addresses. Insecure underlying protocols, lack of authentication and integrity-checking of the information within the DNS threaten the proper functionality of the DNS.',
  SSL: 'Secure Socket Layer score - Weaknesses in SSL is not only used to identify opportunities for threat actors to intercept information but could also imply that the company does not have a robust IT Security program in place given the prevalence of SSL standards. SSL is the standard security technology for establishing an encrypted link between a web server and a browser. This link ensures that all data passed between the web server and browser remain private and integral.',
  'App Sec':
    'Application Security score - Weaknesses in App Sec indicates potential for unauthorized access to sensitive data stored in private, digital environments. Our research team keeps track of attack vectors against SharePoint, ColdFusion, Apache Tomcat, FrontPage, Joomla, Drupal, WordPress, PHP, PHP-Nuke, phpMyAdmin, node.js, ruby, ruby-on-rails and many other common platforms. Known issues are compared to the company’s software versions to identify potential exploits.',
  'Domain Sec':
    'Domain Security score - Weaknesses in Domain Security enable threat actors to either (1) direct website users, unknowingly, to malicious sites in an effort to collect sensitive information or (2) deny access to the site all together.',
  Target: 'Synonymous with product. Products represent externally-facing websites, sub-domains and IP addresses.',
  IP: '(Internet Protocol) a digital media transport system that runs over standard IP networks.',
  Collected: 'Date and time that data was collected from scan',
  'DNS Score': 'Risk score of DNS per target',
  'Negative News':
    'Negative News Findings score – Negative news and sentiment-analysis from monitored companies are determined through sources such as LexisNexis, social-listening and news-monitoring services. Finding scores are based on severity and elapsed time since event.',
  AML: 'Anti-Bribery / Anti-Money Laundering score – Determined by monitoring related party activities that have been flagged for bribery or money laundering. Sources include LexisNexis and Thompson Reuters.',
  Legal:
    'Legal score – Determined by frequency and magnitude of legal actions against the company. Sources are from county public records.',
  Operational:
    'Operational score – Measures the is inherent (i.e. untreated) risk of doing business with a company. This inherent risk serves to capture the magnitude (not the frequency) of a cyber event happening. Thus, lower scores are given to companies that deal in sensitive data, have physical access to their clients, use labor arbitrage, utilize cloud-based services, operate in countries known for high-risk activity and are prone to high vendor-concentration risk. Sources include data scraping, keyword matching, manual QC, Hoovers and proprietary databases.',
  Financial:
    'Financial Conditions score – Identifies potential financial instability. Sources include news stories, content detection of layoffs, bankruptcy proceedings, trending from Dun & Bradstreet and changes in employee counts.',
  Compliance:
    'Regulatory & Compliance score – Represents deficiencies recognized by regulating bodies across the globe. Sources include TransUnion, LexisNexis and industry-specific regulatory bodies.',
  Doppelgangers:
    'Doppelganger Domains are domains whose names are nearly identical to those of legitimate sites except the doppelganger version is typically registered for nefarious purposes. Counts represent the total amount of doppelgangers less the doppelgangers that are registered to the legitimate company. Methods generating doppelgangers include bitsquatting (e.g. common typos), homoglyph (visually appearing to be another word), repetition, replacement, omission and insertion.',
  SPF: 'Sender Policy Framework (SPF) records are used to indicate to mail exchanges which hosts are authorized to send mail for a domain. SPFs are used to prevent non-authorized servers from sending emails on behalf of another company’s domain.',
  DMARC:
    'Domain-based Message Authentication, Reporting & Conformance (DMARC) is an email-validation system and database designed to detect and prevent email spoofing. DMARC is intended to combat certain techniques often used in phishing and email spam, such as emails with forged sender addresses that appear to originate from legitimate organizations.',
  DNSSEC:
    'DNSSEC (Domain Name System Security Extensions) – Suite of Internet Engineering Task Force (IETF) specifications for securing certain kinds of information provided by the Domain Name System (DNS) as used on Internet Protocol (IP) networks. It is a set of extensions to DNS which provide to DNS clients (resolvers) origin authentication of DNS data, authenticated denial of existence, and data integrity, but not availability or confidentiality. DNSSEC should be enabled for best security practices.',
  NSEC: "NSEC (Next Secure) – NSEC-records link to the next record name in the zone (in DNSSEC sorting order) and lists the record types that exist for the record's name. These records can be used by resolvers to verify the non-existence of a record name and type as part of DNSSEC validation. NSEC should be enabled for best security practices.",
  Encryption:
    'DNS Encryption – The process of encoding a message or information in such a way that only authorized parties can access it. DNS encryption should be enabled for best security practices.',
  Wildcard:
    'Wildcard – A record in a DNS zone that will match requests for non-existent domain names. A wildcard DNS record is specified by using an asterisk as the leftmost label (part) of a domain name (e.g. *.example.com). Wildcards should not be used in DNS records for best security practices. Issues arise because (1) If one server or sub-domain is compromised, all sub-domains may be compromised, (2)  if the wildcard certificate needs to be revoked, all sub-domains will need a new certificate and (3) wildcard certificates may not work seamlessly with older server-client configurations.',
  'SSL ports':
    'Generally, separate port numbers are reserved for each protocol commonly secured by SSL. This allows packet filtering firewalls to allow such secure traffic through.',
  'SSL v2':
    'Secure Sockets Layer version 2 is an obsolete version of SSL that was released in February 1995 but was replaced in 1996 due to having several security flaws.',
  'SSL v3':
    'Secure Sockets Layer version 3 is an obsolete version of SSL that was released in 1996 but was replaced in 1999 by subsequent versions of SSL and TLS.',
  'TLS v1.0':
    'Transport Layer Security version 1 is a deprecated version of TLS that was released in 1999. TLS v1.0 is primarily vulnerable to man-in-the-middle attacks and is no longer considered secure.',
  'TLS v1.1':
    'Transport Layer Security version 1.1 is an upgraded version of TLS that was released in 2006. TLS v1.1 contains some small security improvements to protect against CBC attacks and IANA registries defined for protocol parameters.',
  'TLS v1.2': 'Transport Layer Security version 1.2 is an upgraded version of TLS that was released in 2008.',
  Ciphers:
    'Ciphers are the technologies used by transport security (e.g. SSL, TLS) to secure a connection. Grade effectiveness is highest at A-grade and decreases down to F grade.',
  'ciphers a':
    "An indication if 'A' grade ciphers are used by a site's certificate. A green checkmark indicates 'A' grade ciphers were detected.",
  'ciphers b':
    "An indication if 'B' grade ciphers are used by a site's certificate. A red X indicates suboptimal, 'B' grade ciphers were detected.",
  'ciphers c':
    "An indication if 'C' grade ciphers are used by a site's certificate. A red X indicates suboptimal, 'C' grade ciphers were detected.",
  'ciphers d':
    "An indication if 'D' grade ciphers are used by a site's certificate. A red X indicates suboptimal, 'D' grade ciphers were detected.",
  'ciphers e':
    "An indication if 'E' grade ciphers are used by a site's certificate. A red X indicates poor, 'E' grade ciphers were detected.",
  'ciphers f':
    "An indication if 'F' grade ciphers are used by a site's certificate. A red X indicates very poor, 'F' grade ciphers were detected.",
  Cookies:
    'Pieces of information stored on the client web browser which are sent to the server with every request made by the client. Cookie counts are for informational purposes.',
  CSP: 'Content Security Policy (CSP) is a W3C specification, offering the possibility to instruct the client browser from which location and/or which type of resources are allowed to be loaded. Having a CSP is a security best practice.',
  XSS: 'Cross-Site Scripting (XSS) protection prevents attacks where malicious scripts are injected into otherwise benign and trusted websites. XSS attacks occur when an attacker uses a web application to send malicious code, generally in the form of a browser-side script to a different end user. Flaws that allow these attacks to succeed are widespread and can occur when a web application outputs user-provided input without validation or encoding. XSS headers are security best practices.',
  XFO: 'Cross-Frame-Options (XFO) is an HTTP response header, mostly used to combat clickjacking, that informs a Web browser if the page should be rendered in a <* frame> or <* iframe>. XFO counts are for informational purposes and do not pose a security risk.',
  PKP: 'Public Key Pinning (PKP) follows standard best practices for web security, including consistent use of escaping to avoid XSS attacks, tokens to prevent CSRF attacks, etc. PKP counts are for informational purposes.',
  CTO: 'Content Type Options (CTO) headers were introduced by Microsoft in Internet Explorer 8.0 as a way for webmasters to block prevalent content sniffing that could transform non-executable MIME types into executable MIME types.',
  STS: 'HTTP Strict-Transport-Security (STS) response header (often abbreviated as HSTS) lets a web site tell browsers that it should only be accessed using HTTPS, instead of using HTTP.',
  'E-refs':
    'External References (E-refs) are links that point a user to a site outside of the original website top-level domain; for example, a.com referring to b.com (note that a.com referring to x.a.com would not be considered an e-ref). Large amounts of E-refs could be a cause of concern.',
};

const tooltip = function (label) {
  return tooltips[label];
};

const Tooltip = (props) => (
  <RcTooltip
    {...props}
    placement={props.placement || 'topLeft'}
    trigger={props.trigger || ['hover']}
    overlay={<div style={{ maxWidth: parseInt(props.maxWidth || 200) }}>{tooltip(props.message) || props.message}</div>}
  >
    <div>{props.children}</div>
  </RcTooltip>
);

export { Tooltip, tooltip };
