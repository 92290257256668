import React, { useEffect } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import SbomUploadTable from './sbomUploadTable';
import ReactTooltip from 'react-tooltip';
import Busy from '../../busy';
import config from '../../../config';
import { withEvents, events } from '../../withEvents';
import CatalogDropZone from '../catalog/catalogDropZone';
import { useUpdateState } from '../..';
import UploadResponseModal from '../../modals/uploadResponseModal';
import ConfirmDeleteModal from '../../modals/confirmDeleteModal';
import { assessmentTypes } from '../../../constants/assessmentTypes';
import { toastError } from '../../../lib/utils';

const FileUploader = (props) => {
  // Props
  const {
    uploaderType = null,
    handleInputBox = () => {},
    handleVersionSearch = () => {},
    versionOptions = '',
    versionsLoading = false,
    currSearch = '',
    productID = undefined,
    canDownloadSBOMs = false,
    vendorID = null,
    sbomHash = undefined,
  } = props;

  let { productVersion } = props;
  productVersion = productVersion ? (productVersion.customOption ? productVersion.version : productVersion) : '';

  // State
  const [state, setState] = useUpdateState({
    sbomTable: [],
    sbomsLoading: false,
    downloading: [],
    submittedFiles: [],
    fileData: {},
    fileRawData: {},
    showUploadModal: false,
    processingUpload: false,
    uploadResponse: {},
    showDeleteModal: false,
    sbomsToDelete: [],
    selectedFileType: 'sbom',
    removeAllSubmittedFiles: false,
    // selectedPage: 1,
  });

  const {
    sbomTable,
    sbomsLoading,
    downloading,
    submittedFiles,
    fileData,
    fileRawData,
    showUploadModal,
    processingUpload,
    uploadResponse,
    showDeleteModal,
    sbomsToDelete,
    selectedFileType,
    removeAllSubmittedFiles,
    // selectedPage,
  } = state;

  // populates the sbom table on component load and product selection change:
  useEffect(() => {
    getSbomsTableData();
  }, [productID, productVersion]);

  // called when a new file is placed:
  // note - currently only supports one file at a time
  useEffect(() => {
    setState({ removeAllSubmittedFiles: false });
    if (submittedFiles?.[0]) {
      setState({ fileRawData: submittedFiles[0] });

      const fileReader = new FileReader();
      fileReader.readAsText(submittedFiles[0], 'UTF-8');
      fileReader.onload = (e) => {
        setState({ fileData: e.target.result });
      };
    }
  }, [submittedFiles]);

  const filterBy = () => true;

  const setDownloadBusyState = (version) => {
    const idx = downloading.indexOf(version);

    if (idx > -1) {
      let downloadList = downloading;
      downloadList.splice(idx, 1);

      setState({ downloading: downloadList });
    }
  };

  const handleDownload = (file = {}) => {
    const { version, isSupplierProvided, isPurchased, fileName, type } = file;

    if (productID && !downloading.includes(version) && canDownloadSBOMs && isSupplierProvided && isPurchased) {
      let downloadList = downloading;

      downloadList.push(version);
      setState({ downloading: downloadList });

      const downloadURL = config.api.urlFor('posternProxySBOM', { id: productID, version: version || '' });
      const todayStr = new Date().toISOString().substring(0, 10);
      const filename = `${fileName || type}${version ? `_v${version}` : ''}-${todayStr}.zip`.replace(/ /g, '_');

      props.events.emit(events.DOWNLOAD_FILE, {
        url: downloadURL,
        filename,
        callerID: version,
        downloadCompletedCallback: setDownloadBusyState,
      });
    }
  };

  const setSubmittedFiles = (submittedFiles) => {
    setState({ submittedFiles });
  };

  const getSbomsTableData = () => {
    setState({ sbomsLoading: true });

    if (productID) {
      fetch(
        config.api.urlFor('sbomByProductID', {
          productID,
          page: 1,
          pageSize: 0,
          productVersion,
        }),
      )
        .then((res) => res.json())
        .then((response) => {
          const { isSuccess, data } = response;
          if (isSuccess) {
            setState({ sbomTable: data.rows });
            matchStatus(data.rows);
          }
        })
        .catch(() => {
          toastError('Error: Unable to load SBOMs.', { autoClose: 5000 });
        })
        .finally(() => {
          setState({ sbomsLoading: false });
        });
    } else {
      setState({ sbomTableData: [], sbomsLoading: false });
    }
  };

  const matchStatus = (sbomsArray) => {
    let hashes = [];
    for (const i of sbomsArray) {
      hashes.push(i.hash);
    }

    let hashesStr = '';
    for (const h of hashes) {
      hashesStr = hashesStr + `'${h}', `;
    }
    let queryStr = hashesStr.slice(0, -2);

    fetch(
      config.api.urlFor('sbomReceiptsByHash', {
        hashes: queryStr,
      }),
    )
      .then((res) => res.json())
      .then((response) => {
        for (const s of sbomsArray) {
          for (const r of response) {
            if (r.hash == s.hash) {
              s.status = r.status.charAt(0).toUpperCase() + r.status.slice(1);
            }
          }
        }
        setState({ sbomTable: sbomsArray });
      })
      .catch(() => {
        toastError('Error: Unable to load SBOM Analysis Statuses.', { autoClose: 5000 });
      });
  };

  const handleUploadClick = () => {
    setState({ showUploadModal: true });

    if (selectedFileType === 'vex' && !sbomHash) {
      setState({ uploadResponse: { isSuccess: false, message: 'Select a product with SBOM Available.' } });
      return;
    }

    setState({ processingUpload: true });

    const formData = new FormData();
    formData.append('productID', productID);

    if (selectedFileType === 'sbom') {
      formData.append('file', JSON.stringify(fileData));
      formData.append('a2vVendorID', vendorID);
      formData.append('typeID', assessmentTypes.SBOM[1].id);
    } else if (selectedFileType === 'vex') {
      formData.append('file', fileRawData);
      formData.append('sbomHash', sbomHash);
    }

    //'sbomUpload' or 'vexUpload'
    fetch(config.api.urlFor(selectedFileType + 'Upload'), {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        setState({ uploadResponse: response });
      })
      .catch(() => {})
      .finally(() => {
        setState({ processingUpload: false, removeAllSubmittedFiles: true });
        selectedFileType === 'sbom' && getSbomsTableData();
      });
  };

  // handles a single deletion at a time:
  const handleDeleteClick = (sbomHash, fileName) => {
    let toDelete = [
      {
        uuid: sbomHash,
        name: fileName,
      },
    ];

    setState({
      sbomsToDelete: toDelete,
      showDeleteModal: true,
    });
  };

  const closeUploadModal = () => {
    setState({ showUploadModal: false });
  };

  const closeDeleteModal = () => {
    setState({ showDeleteModal: false });
  };

  const handleVersionOnChange = (e) => {
    if (e[0] != null) {
      handleInputBox(e, 'version');
    }
  };

  const handleFileTypeOnChange = (e) => {
    if (e?.target?.value) {
      setState({ selectedFileType: e.target.value });
    }
  };

  // Renders
  const renderNA = (type) => {
    let message = '';
    switch (type) {
      case 1:
        message = 'To begin File Upload, please click one of the "Available Products" below.';
        break;
      case 2:
        message = 'File uploader is not available for this product type.';
        break;
      default:
        break;
    }

    return (
      <div className="sbom-uploader-na">
        <p>{message}</p>
      </div>
    );
  };

  const renderUploader = () => {
    return (
      <div className="sbom-uploader-full">
        <div className="fu-header">
          <UploadResponseModal
            isOpen={showUploadModal}
            onModalClose={closeUploadModal}
            processingUpload={processingUpload}
            uploadResponse={uploadResponse}
          />
          <ConfirmDeleteModal
            isOpen={showDeleteModal}
            onModalClose={closeDeleteModal}
            refreshData={getSbomsTableData}
            itemsToDelete={sbomsToDelete}
            deletionType={2}
          />
          <h3>File Uploader</h3>
        </div>
        <div className="fu-type-version-upload-container">
          <div>
            <p className="opacity-50 font-weight-bold ml-3">File Type</p>
            <div>
              <input
                id="sbomFileType"
                type="radio"
                name="fileType"
                className="ml-4"
                required="required"
                value="sbom"
                checked={selectedFileType === 'sbom'}
                onChange={handleFileTypeOnChange}
              />
              <label htmlFor="sbomFileType" className="sa-option-label mr-4">
                SBOM
              </label>
              <input
                id="vexFileType"
                type="radio"
                name="fileType"
                required="required"
                value="vex"
                checked={selectedFileType === 'vex'}
                onChange={handleFileTypeOnChange}
              />
              <label htmlFor="vexFileType" className="sa-option-label">
                VEX
              </label>
            </div>

            <div className="fu-version">
              <p>
                Add Product Version
                <p className="clarifying-text">(optional)</p>
              </p>
              <AsyncTypeahead
                required={true}
                filterBy={filterBy}
                id="product-version"
                isLoading={versionsLoading}
                labelKey="version"
                minLength={2}
                onSearch={handleVersionSearch}
                onChange={handleVersionOnChange}
                options={versionOptions}
                placeholder={productVersion || 'Search for product version'}
                renderMenuItemChildren={(option) => <Highlighter search={currSearch}>{option}</Highlighter>}
                disabled={sbomsLoading}
              />
            </div>
          </div>
          <div className="fu-drop-zone">
            <p className="opacity-50 font-weight-bold">File Upload</p>
            <CatalogDropZone
              currentFiles={submittedFiles}
              setCurrentFiles={setSubmittedFiles}
              singleFileMode
              acceptableFileType={'.json'}
              showHelperText
              shouldHandleLongFileData
              removeAllFiles={removeAllSubmittedFiles}
            />
          </div>
        </div>
        <div className="fu-new-container">
          <button
            className="fu-new-btn"
            disabled={sbomsLoading || !submittedFiles || !submittedFiles.length}
            onClick={() => handleUploadClick()}
            data-tip
            data-for="addToCatalog"
          >
            Add to Catalog
            {productVersion && submittedFiles.length ? (
              <></>
            ) : (
              <ReactTooltip id="addToCatalog" className="on-hover-tooltip" place="left" effect="solid">
                <p className="on-hover-text">Upload your file.</p>
              </ReactTooltip>
            )}
          </button>
        </div>
        <Busy isBusy={sbomsLoading}>
          <div className="fu-table-container">
            <SbomUploadTable
              tableName="sbom-upload-table"
              headers={[[''], ['Version'], ['File Name'], ['Analysis Status'], ['']]}
            >
              {sbomTable.map((file) => {
                return (
                  <tr className="sbom-tr" key={file.id}>
                    <td className="px-2 py-4">
                      {file.isSupplierProvided && file.isPurchased && canDownloadSBOMs && (
                        <button className="fu-download-btn" onClick={() => handleDownload(file)}>
                          <Busy isBusy={downloading.includes(file.version)} smallest={true}>
                            <div className="fu-download-icon"></div>
                          </Busy>
                        </button>
                      )}
                    </td>
                    <td className="px-2 py-4">{file.version}</td>
                    <td className="px-2 py-4">{file.fileName}</td>
                    <td className="px-2 py-4">{file.status ? file.status : ''}</td>
                    <td className="px-2 py-4" onClick={() => handleDeleteClick(file.hash, file.fileName)}>
                      <button className="fu-del-btn">
                        <div className="fu-delete-circle-icon"></div>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </SbomUploadTable>
          </div>
        </Busy>
      </div>
    );
  };

  // const renderProgressBar = (percentage) => {
  //   return (
  //     <div className="fu-progress-bar-wrapper">
  //       <p>{percentage}%</p>
  //       <div className="fu-progress-bar">
  //         <span className="fu-progress-bar-fill" style={{ width: `${percentage}%` }}></span>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderCompleteStatus = () => {
  //   return <div className="fu-checkmark-circle"></div>;
  // };

  return (
    <div className="sbom-uploader-container">
      {uploaderType ? (productID ? renderUploader() : renderNA(1)) : renderNA(2)}
    </div>
  );
};

export default withEvents(FileUploader);
