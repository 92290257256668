import React from 'react';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { multiSelectFilter, Comparator } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Busy from '../../busy';
import config from '../../../config';
import { RequestStatus } from './lib';
import { sortDate, sortCaseInsensitive, toastError } from '../../../lib/utils';
import * as TrustCenterAction from './actions';
import { useTrustCenterStore } from './context';

import '../bootstrapTable.css';

const approvalStatusSelectOptions = {
  [RequestStatus.PENDING]: RequestStatus.PENDING.charAt(0).toUpperCase() + RequestStatus.PENDING.slice(1),
  [RequestStatus.APPROVED]: RequestStatus.APPROVED.charAt(0).toUpperCase() + RequestStatus.APPROVED.slice(1),
  [RequestStatus.DENIED]: RequestStatus.DENIED.charAt(0).toUpperCase() + RequestStatus.DENIED.slice(1),
};

const approvalStatusEditorOptions = Object.keys(approvalStatusSelectOptions).map((x) => ({
  value: x,
  label: approvalStatusSelectOptions[x],
}));

const FileAccessRequestTable = (props) => {
  //Context
  const { store, dispatch } = useTrustCenterStore();
  const { fileRequests } = store;

  //Props
  const { canEdit, canAccessVendorOverview = true } = props;

  //Helpers
  const updateRequestStatus = async (oldValue, newValue, row) => {
    if (oldValue != newValue) {
      let fileItemData = row;
      fileItemData.status = newValue;

      fetch(config.api.urlFor('fileItemAccessRequests', { fileID: fileItemData.fileID }), {
        method: 'PUT',
        body: fileItemData,
      })
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, message = 'Error updating access request.' } = response;

          if (!isSuccess) {
            toastError(message);
            return;
          }

          const { fileID, fileCategoryID, userID, subscriberID } = fileItemData;
          dispatch(
            TrustCenterAction.adjustFileItemRequestCount(oldValue, newValue, 1, {
              fileID,
              fileCategoryID,
              userID,
              subscriberID,
            }),
          );
        })
        .catch(() => toastError('Failed to update access request.'));
    }
  };

  const NoAccessRequestsIndication = () => (
    <div className="text-center">
      <h4>No file access requests found.</h4>
    </div>
  );

  const expandRowFormat = (row) => {
    return (
      <div>
        <strong>Description:</strong> {row.description}
      </div>
    );
  };

  const logoFormatter = (cell) => {
    return cell ? <img className="companyLogo" src={cell} /> : null;
  };

  const companyNameFormatter = (cell, row) => {
    const website = row.website;

    return !!canAccessVendorOverview && website ? (
      <a href={(website.startsWith('http') ? '' : '//') + website} target="_blank" title={cell} rel="noreferrer">
        {cell}
      </a>
    ) : (
      <span title={cell}>{cell}</span>
    );
  };

  const nameEmailDocumentFormatter = (cell) => {
    return <span title={cell}>{cell}</span>;
  };

  const requestedDateFormatter = (cell) => {
    return cell ? new Date(cell).toLocaleDateString() : 'N/A';
  };

  const approvalStatusFormatter = (cell) => {
    let spanStyle = { minWidth: 'fit-content' };
    const spanClassName = 'bootstrap-cell-formatter' + (canEdit ? ' canEdit' : '');

    switch (cell) {
      case RequestStatus.PENDING:
        spanStyle = { ...spanStyle, color: '#30383B', border: '1px solid #30383B' };
        break;
      case RequestStatus.DENIED:
        spanStyle = { ...spanStyle, color: '#ffffff', backgroundColor: '#30383B' };
        break;
      case RequestStatus.APPROVED:
        spanStyle = { ...spanStyle, color: '#ffffff', backgroundColor: '#002752' };
        break;
      default:
        spanStyle = { ...spanStyle, color: '#ffffff', backgroundColor: '#e74c3c' };
        break;
    }

    return (
      <span className={spanClassName} style={spanStyle} data-tip data-for="onHoverStatus">
        {approvalStatusSelectOptions[cell] || 'Error'}
        {canEdit ? (
          <span style={{ marginLeft: '15px' }}>
            <i className="fas fa-chevron-down"></i>
          </span>
        ) : (
          <span style={{ marginLeft: '10px' }} />
        )}
        <ReactTooltip id="onHoverStatus" place="left" effect="solid">
          <p style={{ marginBottom: '0px', textAlign: 'center' }}>
            Contact your Administrator for more<br></br>information regarding this status.
          </p>
        </ReactTooltip>
      </span>
    );
  };

  const getColumns = () => {
    const sortEnabled = !!fileRequests && fileRequests.length > 1;

    //Can't reduce this directly as the list will be ordered by first insert for some reason
    const allRequestorCompanies = sortEnabled ? [...new Set(fileRequests.map((x) => x.companyName))] : [];
    const allRequestorNames = sortEnabled ? [...new Set(fileRequests.map((x) => x.fullname))] : [];
    const allFilenames = sortEnabled ? [...new Set(fileRequests.map((x) => x.filename))] : [];
    const allRequestorEmails = sortEnabled ? [...new Set(fileRequests.map((x) => x.email))] : [];

    allRequestorCompanies.sort(sortCaseInsensitive);
    allRequestorNames.sort(sortCaseInsensitive);
    allFilenames.sort(sortCaseInsensitive);
    allRequestorEmails.sort(sortCaseInsensitive);

    let columns = [
      {
        dataField: 'companyURL',
        text: 'Logo',
        sort: false,
        editable: false,
        formatter: logoFormatter,
      },
      {
        dataField: 'companyName',
        text: 'Company',
        sort: sortEnabled,
        sortFunc: sortCaseInsensitive,
        editable: false,
        formatter: companyNameFormatter,
        classes: 'sa-access-requests-table-columns',
        filter:
          allRequestorCompanies.length > 1
            ? multiSelectFilter({
                comparator: Comparator.EQ,
                options: allRequestorCompanies.reduce((map, obj) => {
                  map[obj] = obj;
                  return map;
                }, {}),
              })
            : undefined,
      },
      {
        dataField: 'fullname',
        text: 'Name',
        sort: sortEnabled,
        sortFunc: sortCaseInsensitive,
        editable: false,
        formatter: nameEmailDocumentFormatter,
        classes: 'sa-access-requests-table-columns',
        filter:
          allRequestorNames.length > 1
            ? multiSelectFilter({
                comparator: Comparator.EQ,
                options: allRequestorNames.reduce((map, obj) => {
                  map[obj] = obj;
                  return map;
                }, {}),
              })
            : undefined,
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: sortEnabled,
        sortFunc: sortCaseInsensitive,
        editable: false,
        formatter: nameEmailDocumentFormatter,
        classes: 'sa-access-requests-table-columns',
        filter:
          allRequestorEmails.length > 1
            ? multiSelectFilter({
                comparator: Comparator.EQ,
                options: allRequestorEmails.reduce((map, obj) => {
                  map[obj] = obj;
                  return map;
                }, {}),
              })
            : undefined,
      },
      {
        dataField: 'filename',
        text: 'Document',
        sort: sortEnabled,
        sortFunc: sortCaseInsensitive,
        editable: false,
        formatter: nameEmailDocumentFormatter,
        classes: 'sa-access-requests-table-columns',
        filter:
          allFilenames.length > 1
            ? multiSelectFilter({
                comparator: Comparator.EQ,
                options: allFilenames.reduce((map, obj) => {
                  map[obj] = obj;
                  return map;
                }, {}),
              })
            : undefined,
      },
      {
        dataField: 'requestedDate',
        text: 'Requested Date',
        sort: sortEnabled,
        sortFunc: sortDate,
        formatter: requestedDateFormatter,
        editable: false,
      },
      {
        dataField: 'status',
        text: 'Approval Status',
        sort: sortEnabled,
        sortFunc: sortCaseInsensitive,
        formatter: approvalStatusFormatter,
        editor: canEdit
          ? {
              type: Type.SELECT,
              getOptions: () => {
                return approvalStatusEditorOptions;
              },
            }
          : undefined,
        filter: sortEnabled
          ? multiSelectFilter({
              comparator: Comparator.EQ,
              options: approvalStatusSelectOptions,
            })
          : undefined,
      },
    ];

    return columns;
  };

  //Render
  return (
    <Busy isBusy={fileRequests === undefined}>
      <div id="access-requests-table" className={fileRequests !== undefined ? 'd-table' : 'd-none'}>
        <BootstrapTable
          bootstrap4
          keyField="access_request_num"
          data={fileRequests || []}
          defaultSortDirection="asc"
          columns={getColumns()}
          expandRow={{
            renderer: expandRowFormat,
            showExpandColumn: true,
            expandColumnPosition: 'right',
            expandByColumnOnly: true,
          }}
          headerClasses="bootstrap-table-header"
          rowClasses="bootstrap-table-row"
          pagination={
            !!fileRequests && fileRequests.length > 0 ? paginationFactory({ hideSizePerPage: true }) : undefined
          }
          noDataIndication={() => <NoAccessRequestsIndication />}
          cellEdit={
            canEdit
              ? cellEditFactory({
                  mode: 'click',
                  blurToSave: true, // Utilized to enable saving (no actual blur)
                  afterSaveCell: updateRequestStatus,
                })
              : undefined
          }
          filter={filterFactory()}
          filterPosition="top"
          defaultSorted={[
            {
              dataField: 'requestedDate',
              order: 'desc',
            },
          ]}
        />
      </div>
    </Busy>
  );
};

export default FileAccessRequestTable;
