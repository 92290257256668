import React from 'react';

const ProductReportBox = (props) => {
  const { handleProductCheckbox, requestedProductReports = {}, canDelete } = props;
  const { requestedProductArray } = requestedProductReports;

  return (
    <div className="product-report-box">
      {requestedProductArray &&
        requestedProductArray.map((product, i) => {
          const productName = product.productName;
          const productVersionOrNumber = product.productNumber;
          const fileArray = product.fileArray || [];
          const reportsArray = product.reportsArray || [];

          return (
            <div key={i} className="pl-2 mb-2 mt-2 m-soft-bor-bot">
              {canDelete && (
                <button
                  onClick={() => handleProductCheckbox(i, reportsArray)}
                  className="remove-product-report btn btn-danger"
                >
                  X
                </button>
              )}
              <span className="ml-3">{productName + (productVersionOrNumber ? ' ' + productVersionOrNumber : '')}</span>
              <div className="m-flex-around mb-3">
                {reportsArray.length ? (
                  <div className="">
                    <p className="m-bold m-txt-size-m">Reports:</p>
                    {reportsArray.map((report, i) => {
                      return (
                        <p className="modal-report-title m-cancel-cursor" key={i}>
                          {report}
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  <div className=""></div>
                )}
                {fileArray.length ? (
                  <div className="">
                    <p className="m-bold m-txt-size-m">Files:</p>
                    {fileArray.map((file, i) => {
                      return (
                        <p className="modal-report-title m-cancel-cursor" key={i}>
                          {file.name}
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  <div className=""></div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ProductReportBox;
