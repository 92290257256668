import React from 'react';

const ForeignInfluenceMapLegends = (props) => {
  //Props
  const {
    manufacturing = [],
    physicalPresence = [],
    cyberPresence = [],
    corporateFamilies = [],
    foreignOwnership = [],
  } = props;

  //Prop-based consts
  const hasManufacturing = manufacturing.length > 0;
  const hasPhysical = physicalPresence.length > 0;
  const hasCyber = cyberPresence.length > 0;
  const hasCorporate = corporateFamilies.length > 0;
  const hasForeign = foreignOwnership.length > 0;

  const hasAny = hasManufacturing || hasPhysical || hasCyber || hasCorporate || hasForeign;

  //Render
  return (
    <div className={'fi-map-legends-container' + (hasAny ? '' : ' country-legend-only')}>
      {hasAny ? (
        <>
          <div className="fi-marker-legend">
            <h5>
              <strong>Marker Legend</strong>
            </h5>
            <div className="provenance-cybermap-marker-legend">
              {hasManufacturing && (
                <div>
                  <span className="cybermap-legend-color" style={{ background: '#E84D3D' }}></span> &nbsp;Manufacturing
                  Location
                </div>
              )}
              {hasPhysical && (
                <div>
                  <span className="cybermap-legend-color" style={{ background: '#186e15' }}></span> &nbsp;Physical
                  Presence
                </div>
              )}
              {hasCyber && (
                <div>
                  <span className="cybermap-legend-color" style={{ background: '#E88F24' }}></span> &nbsp;Cyber Presence
                </div>
              )}
              {hasCorporate && (
                <div>
                  <span className="cybermap-legend-color" style={{ background: '#004D70' }}></span> &nbsp;Corporate
                  Families
                </div>
              )}
              {hasForeign && (
                <div>
                  <span className="cybermap-legend-color" style={{ background: '#B585E3' }}></span> &nbsp;Foreign
                  Ownership
                </div>
              )}
              {/* <div><span className="cybermap-legend-color" style="background: #FDD400"></span> &nbsp;Watchlist</div> */}
            </div>
          </div>
          <div className="fi-legend-spacer"></div>
        </>
      ) : null}
      <div className="fi-country-legend">
        <h5><strong>Country Risk</strong></h5>
        <div className="provenance-cybermap-country-legend">
          <div className="cybermap-legend-text">
            <span
              className="cybermap-legend-color"
              style={{ border: '1px solid rgb(123 160 131)', background: '#fff' }}
            ></span>{' '}
            &nbsp;Low Risk
          </div>
          <div className="cybermap-legend-text">
            <span
              className="cybermap-legend-color"
              style={{ border: '1px solid rgb(168 105 130)', background: '#F8D7DA' }}
            ></span>{' '}
            &nbsp;High Risk
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForeignInfluenceMapLegends;
