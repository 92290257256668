import React, { useContext } from 'react';
import { withPrincipal } from '../../components';
import SectionLocked from '../sectionLocked';
import SectionSummaryCyberHygiene from '../sectionSummaryCyberHygiene';
import { CatalogVendorContext } from '../../routes/vendor/context';

/** Functional component for displaying the Cyber Hygiene summary section */
const CyberHygieneSection = (props) => {
  //Props
  const {
    isUnlockedCyberHygiene,
    reportTypeCyberHygiene,
    handleUnlockButtonClick,
    viewDashboardOnClick,
    vendorName,
    dnsFindings = {},
    sslFindings = {},
    appSecFindings = {},
    vulnsFindings = {},
    hasScanData,
  } = props;

  // Context
  const { findingsCounts = {}, cyberHygieneScore = {} } = useContext(CatalogVendorContext);

  //Render
  return (
    <>
      <div className="section-main-title">Cyber Hygiene</div>
      {isUnlockedCyberHygiene ? (
        <SectionSummaryCyberHygiene
          viewDashboardOnClick={viewDashboardOnClick}
          dnsFindings={dnsFindings}
          sslFindings={sslFindings}
          appSecFindings={appSecFindings}
          vulnsFindings={vulnsFindings}
          hasScanData={hasScanData}
          rawFindingsCounts={findingsCounts}
          cyberHygieneScore={cyberHygieneScore}
        />
      ) : (
        <SectionLocked
          title="Unlock Cyber Hygiene Results"
          buttonCaption="Unlock Cyber Hygiene Scan"
          info={`View Cyber Hygiene results for ${vendorName} by purchasing a Cyber Scan.`}
          details="The Cyber Hygiene Scan identifies risk from a company's public web presence by identifying:"
          bullets={[
            'Domain Name Security',
            'Doppelgangers',
            'Sender Policy Framework',
            'Transport Layer Security',
            'Cipher Strength',
            'Deprecated Protocols',
            'Expired Certificates',
            '+Application Security',
            '+Vulnerabilities',
          ]}
          canUnlockSection={props.principal.roles.some((r) =>
            r.permissions.some((p) => p === 'subscriber.purchaseReports'),
          )}
          handleUnlockButtonClick={handleUnlockButtonClick}
          reportType={reportTypeCyberHygiene}
        />
      )}
    </>
  );
};

export default withPrincipal(CyberHygieneSection);
