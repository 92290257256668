const country_locations = [
  { country: 'Africa', latitude: -26.2041028, longitude: 28.047305100000003 },
  { country: 'Algeria', latitude: 28.033886, longitude: 1.6596259999999998 },
  { country: 'Angola', latitude: -8.8146556, longitude: 13.230175599999999 },
  { country: 'Argentina', latitude: -38.416097, longitude: -63.616671999999994 },
  { country: 'Austrailia', latitude: -37.813627600000004, longitude: 144.96305759999998 },
  { country: 'Australia', latitude: -37.852616299999994, longitude: 145.1519822 },
  { country: 'Austria', latitude: 48.208174299999996, longitude: 16.3738189 },
  { country: 'Azerbaijan', latitude: 40.4092617, longitude: 49.8670924 },
  { country: 'Bangladesh', latitude: 23.810332, longitude: 90.4125181 },
  { country: 'Belarus', latitude: 53.9006011, longitude: 27.558971999999997 },
  { country: 'Belgium', latitude: 50.503887, longitude: 4.469936 },
  { country: 'Benin', latitude: 6.3702928, longitude: 2.3912362000000003 },
  { country: 'Bolivia', latitude: -16.489689000000002, longitude: -68.11929359999999 },
  { country: 'Brasil', latitude: -23.550519899999998, longitude: -46.6333094 },
  { country: 'Brazil', latitude: -23.550519899999998, longitude: -46.6333094 },
  { country: 'Bulgaria', latitude: 42.69770820000001, longitude: 23.3218675 },
  { country: 'Cambodia', latitude: 10.8230989, longitude: 106.6296638 },
  { country: 'Cameroon', latitude: 3.8480324999999995, longitude: 11.5020752 },
  { country: 'Canada', latitude: 43.653226000000004, longitude: -79.3831843 },
  { country: 'Chile', latitude: -35.675146999999996, longitude: -71.542969 },
  { country: 'China', latitude: 20.044412, longitude: 110.19828600000001 },
  { country: 'Colombia', latitude: 2.5359349, longitude: -75.5276699 },
  { country: 'Congo', latitude: -4.4419311, longitude: 15.266293099999999 },
  { country: "Cote d'Ivoire", latitude: 5.3599517, longitude: -4.008256299999999 },
  { country: 'Czech', latitude: 50.0755381, longitude: 14.4378005 },
  { country: 'Czech Republic', latitude: 49.817492, longitude: 15.472961999999999 },
  { country: 'Denmark', latitude: 55.677262600000006, longitude: 12.3430265 },
  { country: 'Deutschland', latitude: 50.035067100000006, longitude: 8.6127698 },
  { country: 'Dominican Republic', latitude: 18.4860575, longitude: -69.9312117 },
  { country: 'Ecuador', latitude: -2.1894127999999995, longitude: -79.8890662 },
  { country: 'Egypt', latitude: 26.820553000000004, longitude: 30.802497999999996 },
  { country: 'Equadorial Guinea', latitude: 3.7549606, longitude: 8.7821344 },
  { country: 'Ethiopia', latitude: 8.9806034, longitude: 38.757760499999996 },
  { country: 'France', latitude: 46.227638, longitude: 2.213749 },
  { country: 'Gabon', latitude: 0.41619759999999995, longitude: 9.4672676 },
  { country: 'Georgia', latitude: 41.71513770000001, longitude: 44.827096000000004 },
  { country: 'Germany', latitude: 51.165690999999995, longitude: 10.451526 },
  { country: 'Germnay', latitude: 51.2277411, longitude: 6.773455599999999 },
  { country: 'Ghana', latitude: 5.6037168, longitude: -0.1869644 },
  { country: 'Greece', latitude: 37.9838096, longitude: 23.7275388 },
  { country: 'Guatemala', latitude: 14.6349149, longitude: -90.50688240000001 },
  { country: 'Guinea', latitude: 9.641185499999999, longitude: -13.578401199999998 },
  { country: 'Hong Kong', latitude: 22.319303899999998, longitude: 114.1693611 },
  { country: 'Hong Kong SAR', latitude: 22.319303899999998, longitude: 114.1693611 },
  { country: 'Hungary', latitude: 47.497912, longitude: 19.040235 },
  { country: 'India', latitude: 12.9715987, longitude: 77.5945627 },
  { country: 'Indonesia', latitude: -6.2087634000000005, longitude: 106.84559899999999 },
  { country: 'Iraq', latitude: 33.315241, longitude: 44.3660671 },
  { country: 'Israel', latitude: 32.029748, longitude: 34.856173 },
  { country: 'Italy', latitude: 41.902783500000005, longitude: 12.4963655 },
  { country: 'Jamaica', latitude: 18.017874300000003, longitude: -76.8099041 },
  { country: 'Japan', latitude: 35.6761919, longitude: 139.65031059999998 },
  { country: 'Jordan', latitude: 30.585164000000002, longitude: 36.238414 },
  { country: 'Kazakhstan', latitude: 43.2220146, longitude: 76.8512485 },
  { country: 'Kenya', latitude: -1.2920658999999999, longitude: 36.821946200000006 },
  { country: 'KIndia', latitude: 12.9698196, longitude: 77.7499721 },
  { country: 'Korea', latitude: 37.3976747, longitude: 127.1104788 },
  { country: 'Kuwait', latitude: 29.375859000000002, longitude: 47.9774052 },
  { country: 'Kyrgyzstan', latitude: 42.87462120000001, longitude: 74.56976170000002 },
  { country: 'Latvia', latitude: 56.94964870000001, longitude: 24.1051865 },
  { country: 'Lebanon', latitude: 33.854721000000005, longitude: 35.862285 },
  { country: 'Liberia', latitude: 6.3156068, longitude: -10.8073698 },
  { country: 'Libia', latitude: 32.887209399999996, longitude: 13.1913383 },
  { country: 'Libya', latitude: 32.887209399999996, longitude: 13.1913383 },
  { country: 'Macau SAR', latitude: 22.198745000000002, longitude: 113.543873 },
  { country: 'Macedonia', latitude: 41.998129399999996, longitude: 21.425435500000003 },
  { country: 'Madagascar', latitude: -18.8791902, longitude: 47.5079055 },
  { country: 'Malawi', latitude: -15.766670699999999, longitude: 35.0167866 },
  { country: 'Malaysia', latitude: 3.1278870999999997, longitude: 101.5944885 },
  { country: 'Mauritania', latitude: 18.110344, longitude: -15.9993672 },
  { country: 'Mauritius', latitude: -20.348404000000002, longitude: 57.552152 },
  { country: 'Mexico', latitude: 19.430657500000002, longitude: -99.20839529999999 },
  { country: 'Mongolia', latitude: 47.886398799999995, longitude: 106.9057439 },
  { country: 'Morocco', latitude: 33.9715904, longitude: -6.8498129 },
  { country: 'Mozambique', latitude: -25.969248, longitude: 32.5731746 },
  { country: 'Myanmar', latitude: 16.840939000000002, longitude: 96.173526 },
  { country: 'Nepal', latitude: 27.658752500000002, longitude: 85.3247183 },
  { country: 'Netherlands', latitude: 52.07049779999999, longitude: 4.300699900000001 },
  { country: 'New Zealand', latitude: -40.900557, longitude: 174.88597099999998 },
  { country: 'Nicaragua', latitude: 12.114992599999999, longitude: -86.23617440000001 },
  { country: 'Niger', latitude: 13.511596300000003, longitude: 2.1253854 },
  { country: 'Nigeria', latitude: 6.5243793, longitude: 3.3792057 },
  { country: 'Nigeria Lagos', latitude: 6.45, longitude: 3.433333 },
  { country: 'Norway', latitude: 59.878322999999995, longitude: 10.6195026 },
  { country: 'Oman', latitude: 21.4735329, longitude: 55.975413 },
  { country: 'Pakistan', latitude: 24.8607343, longitude: 67.0011364 },
  { country: 'Panam', latitude: 19.3506165, longitude: -99.16325570000001 },
  { country: 'Panama', latitude: 8.982379199999999, longitude: -79.51986959999999 },
  { country: 'Paraguay', latitude: -23.442503, longitude: -58.44383199999999 },
  { country: 'Per', latitude: -12.0463731, longitude: -77.042754 },
  { country: 'Peru', latitude: -12.0463731, longitude: -77.042754 },
  { country: 'Philippines', latitude: 12.879721, longitude: 121.77401699999999 },
  { country: 'Poland', latitude: 51.919438, longitude: 19.145135999999997 },
  { country: 'Portugal', latitude: 38.722252399999995, longitude: -9.1393366 },
  { country: 'Qatar', latitude: 25.354826, longitude: 51.183884 },
  { country: 'Republic of Kazakhstan', latitude: 43.2220146, longitude: 76.8512485 },
  { country: 'Republic of Uzbekistan', latitude: 41.299495799999995, longitude: 69.2400734 },
  { country: 'Romania', latitude: 44.4267674, longitude: 26.1025384 },
  { country: 'Russia', latitude: 55.755826, longitude: 37.6172999 },
  { country: 'Saudi Arabia', latitude: 24.7135517, longitude: 46.67529570000001 },
  { country: 'Senegal', latitude: 14.7445334, longitude: -17.525368 },
  { country: 'Serbia', latitude: 44.786568, longitude: 20.448921600000002 },
  { country: 'Sierra Leone', latitude: 8.4656765, longitude: -13.2317225 },
  { country: 'Singapore', latitude: 1.352083, longitude: 103.819836 },
  { country: 'Slovakia', latitude: 48.148596500000004, longitude: 17.1077478 },
  { country: 'South Africa', latitude: -30.559482, longitude: 22.937506 },
  { country: 'South Africs', latitude: -26.2041028, longitude: 28.047305100000003 },
  { country: 'South Korea', latitude: 37.566534999999995, longitude: 126.9779692 },
  { country: 'Spain', latitude: 40.4167754, longitude: -3.7037902000000003 },
  { country: 'Sri Lanka', latitude: 6.927078599999999, longitude: 79.861243 },
  { country: 'Sweden', latitude: 59.4024341, longitude: 17.9464824 },
  { country: 'Switzerland', latitude: 46.818188, longitude: 8.227511999999999 },
  { country: 'Taiwan', latitude: 25.0329694, longitude: 121.56541770000001 },
  { country: 'Tajikistan', latitude: 38.5597722, longitude: 68.78703840000001 },
  { country: 'Tanzania', latitude: -6.792354, longitude: 39.2083284 },
  { country: 'Thailand', latitude: 13.746182999999998, longitude: 100.5307787 },
  { country: 'The Netherlands', latitude: 52.060669, longitude: 4.494025 },
  { country: 'The Republic of Kazakhstan', latitude: 43.2220146, longitude: 76.8512485 },
  { country: 'Tunisia', latitude: 33.886917, longitude: 9.537499 },
  { country: 'Turkey', latitude: 38.963745, longitude: 35.243322 },
  { country: 'UAE', latitude: 25.204849300000003, longitude: 55.2707828 },
  { country: 'Uganda', latitude: 0.3475964, longitude: 32.5825197 },
  { country: 'UK & Ireland', latitude: 51.5485, longitude: -0.47961099999999995 },
  { country: 'Ukraine', latitude: 48.379433, longitude: 31.1655799 },
  { country: 'United Arab Emeirates', latitude: 24.453884, longitude: 54.3773438 },
  { country: 'United Arab Emirates', latitude: 25.204849300000003, longitude: 55.2707828 },
  { country: 'United Kingdom', latitude: 51.26654, longitude: -1.0923964 },
  { country: 'United States', latitude: 25.9860762, longitude: -80.3035602 },
  { country: 'Uruguay', latitude: -34.901112700000006, longitude: -56.1645314 },
  { country: 'Uzbekistan', latitude: 41.299495799999995, longitude: 69.2400734 },
  { country: 'Venezuela', latitude: 10.480593699999998, longitude: -66.90360629999999 },
  { country: 'Vietnam', latitude: 14.058323999999999, longitude: 108.277199 },
  { country: 'Zambia', latitude: -15.3875259, longitude: 28.3228165 },
  { country: 'Zimbabwe', latitude: -17.8216288, longitude: 31.049225899999996 },
];

export default country_locations;
