import React from 'react';
import Modal from 'react-modal';
import { withPrincipal } from '../..';
import config from '../../../config';
import * as TrustCenterAction from './actions';
import { useTrustCenterStore } from './context';
import RequestFileAccess from './requestFileAccess';
import Busy from '../../busy';
import { toastError } from '../../../lib/utils';

const RequestFileAccessModal = (props) => {
  //Context
  const { store, dispatch } = useTrustCenterStore();
  const { requestAccessFileID, fileItem = {} } = store;

  //State
  const [isBusy, setIsBusy] = React.useState(false);

  //Props
  const { principal, setReloadFilesFlag } = props;

  const handleModalClose = () => {
    dispatch(TrustCenterAction.toggleRequestAccessModal());
  };

  const handleAgreeClick = () => {
    setIsBusy(true);

    fetch(config.api.urlFor('fileItemAccessRequests', { fileID: requestAccessFileID }), { method: 'POST' })
      .then((result) => {
        handleModalClose();
        return result.json();
      })
      .then((response) => {
        const { isSuccess, message = 'Error while attempting to process the request for file access.' } = response;

        if (isSuccess) {
          setReloadFilesFlag(true);
        } else {
          toastError(message);
        }
      })
      .catch(() => {
        toastError('An error occurred attempting to process the request for file access.');
      })
      .finally(() => setIsBusy(false));
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={!!requestAccessFileID}
      className="sa-modal-style"
      contentLabel="Request File Access"
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
    >
      <Busy isBusy={isBusy}>
        <RequestFileAccess
          userFirstName={principal.firstName}
          userLastName={principal.lastName}
          fileName={fileItem.name}
          docusignNDAData={fileItem.NDA}
          agreeButtonHandler={handleAgreeClick}
          closeButtonHandler={handleModalClose}
        />
      </Busy>
    </Modal>
  );
};

export default withPrincipal(RequestFileAccessModal);
