import React from 'react';
import { Card, ListItem } from '.';

const CardRow = ({ stats, isBusy }) => {
  const { orgStats = {}, topIndustry } = stats;
  const { totalCompanies = null } = orgStats;

  const lowRiskCount =
    parseInt(totalCompanies) -
    (parseInt(orgStats.criticalCount) + parseInt(orgStats.highCount) + parseInt(orgStats.moderateCount));

  return (
    <React.Fragment>
      <div className="row mb-5">
        <Card label="Statistic" icon="fa-balance-scale" isBusy={isBusy && !totalCompanies}>
          <ListItem label="Total Companies" count={totalCompanies} />
          <ListItem label="Critical Risk Companies" count={orgStats.criticalCount} total={totalCompanies} />
          <ListItem label="High Risk Companies" count={orgStats.highCount} total={totalCompanies} />
          <ListItem label="Moderate Risk Companies" count={orgStats.moderateCount} total={totalCompanies} />
          <ListItem label="Low Risk Companies" count={lowRiskCount} total={totalCompanies} />
        </Card>
        <Card label="Top Industries" icon="fa-clipboard-list" isBusy={isBusy && !topIndustry}>
          {topIndustry &&
            topIndustry.map &&
            topIndustry.map((industry, index) => (
              <ListItem label={industry.naicsName} count={industry.count} total={totalCompanies} key={index} />
            ))}
        </Card>
        <Card label="Companies by Revenue" icon="fa-dollar-sign" isBusy={isBusy && !totalCompanies}>
          {parseInt(orgStats['<$1Mn']) ? <ListItem label="Less Than $1 Million" count={orgStats['<$1Mn']} /> : null}
          {parseInt(orgStats['$1+Mn']) ? <ListItem label="$1 Million" count={orgStats['$1+Mn']} /> : null}
          {parseInt(orgStats['$5+Mn']) ? <ListItem label="$5 Million" count={orgStats['$1+Mn']} /> : null}
          {parseInt(orgStats['$10+Mn']) ? <ListItem label="$10 Million" count={orgStats['$10+Mn']} /> : null}
          {parseInt(orgStats['$50+Mn']) ? <ListItem label="$50 Million" count={orgStats['$50+Mn']} /> : null}
          {parseInt(orgStats['$100+Mn']) ? <ListItem label="$100 Million" count={orgStats['$100+Mn']} /> : null}
          {parseInt(orgStats['$500+Mn']) ? <ListItem label="$500 Million" count={orgStats['$500+Mn']} /> : null}
          {parseInt(orgStats['$1+Bn']) ? <ListItem label="$1 Billion" count={orgStats['$1+Bn']} /> : null}
          {parseInt(orgStats['$5+Bn']) ? <ListItem label="$5 Billion" count={orgStats['$5+Bn']} /> : null}
          {parseInt(orgStats['$10+Bn']) ? <ListItem label="$10 Billion" count={orgStats['$10+Bn']} /> : null}
          {parseInt(orgStats['>$50Bn']) ? (
            <ListItem label="Greater Than $50 Billion" count={orgStats['>$50Bn']} />
          ) : null}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default CardRow;
