import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Spinner, useUpdateState } from '..';
import config from '../../config';
import { toastError } from '../../lib/utils';

const reportsFirstTDs = {
  fontSize: '15px',
  fontWeight: '500',
  textAlign: 'start',
  border: '1px solid #A9A9A9',
  padding: '5px 15px',
};

const reportsOtherTDs = {
  fontSize: '15px',
  fontWeight: '500',
  textAlign: 'center',
  border: '1px solid #A9A9A9',
  padding: '5px 15px',
};

const firstTHTotal = {
  fontSize: '15px',
  fontWeight: '700',
  textAlign: 'start',
  border: '1px solid #A9A9A9',
  padding: '5px 10px',
};

const otherTHsTotal = {
  fontSize: '15px',
  fontWeight: '700',
  textAlign: 'center',
  border: '1px solid #A9A9A9',
  padding: '5px 10px',
};

const firstTHBalance = {
  fontSize: '15px',
  fontWeight: '700',
  textAlign: 'start',
  border: '1px solid #A9A9A9',
  padding: '5px 10px',
  width: '88%',
};

const otherTHsBalance = {
  fontSize: '15px',
  fontWeight: '700',
  textAlign: 'center',
  border: '1px solid #A9A9A9',
  padding: '5px 10px',
  width: '12%',
};

const firstTHReports = {
  fontSize: '15px',
  fontWeight: '700',
  textAlign: 'start',
  border: '1px solid #A9A9A9',
  padding: '5px 10px',
  width: '64%',
};

const otherTHReports = { ...otherTHsBalance };

const table = {
  fontFamily: 'Open Sans, Arial',
  fontSize: '1px',
  padding: '0',
  borderSpacing: '0',
  margin: '0',
  borderCollapse: 'collapse',
  width: '100%',
  height: '35px',
  marginLeft: '-3px',
};

const reportsTable = { ...table, marginBottom: '2rem' };

const balanceTables = {
  ...table,
  ...{
    marginBottom: '1.5rem',
    backgroundColor: 'rgb(224,224,224)',
  },
};

const ProductCheckoutModal = (props) => {
  const {
    productID,
    productName,
    vendorID,
    vendorName,
    vendorDDRRid,
    requestedProductReports,
    allowGoingBackwards = false,
    reportDescriptions,
    setNextModalStage = () => {},
    productVersionOrNumber,
    allowMultipleProducts,
    updateSBOMpurchaseStatus = () => {},
    resetModalData = () => {},
    supplierProvidedSbomFlag = false,
    selectedFullHash = '',
    getSbomAccessStatus = () => {},
  } = props;

  const [state, setState] = useUpdateState({
    isBusy: false,
    sendRequestObject: {},
    hasErrors: {
      hasReport: false,
      nsf: false,
    },
    balance: 0,
    tokenSum: 0,
    endingTokenBalance: 0,
  });

  const {
    hasErrors,
    reportsListForEmail,
    balance,
    endingTokenBalance,
    fetchingBalance,
    tokenSum,
    isBusy,
    sendRequestObject,
  } = state;
  const { nsf } = hasErrors;

  /* ----------------------------------- */
  /*          Life Cycle Methods         */
  /* ----------------------------------- */

  React.useEffect(() => {
    const { requestedProductArray = [] } = requestedProductReports;
    let requestObject = requestedProductReports;
    let requestArray = Object.keys(requestedProductReports);
    let quantitySum = 0;

    requestArray.map((reportType) => {
      if (requestObject[`${reportType}`] > 0) {
        quantitySum += requestObject[`${reportType}`];
      }
    });

    if (quantitySum != 0) {
      const sendRequestObject = {
        name: vendorName,
        vendorID,
        vendorDDRRid,
        requestedProductReports: requestedProductArray.map((report) => {
          return {
            productName: productName,
            fileNameArray: report.fileArray || [report],
            reportsArray: report.reportsArray || [report],
            productID: productID,
            productNumber: productVersionOrNumber || '',
            sbomHash: selectedFullHash || '',
          };
        }),
      };

      setState({
        hasErrors: { hasReport: true },
        sendRequestObject,
        isBusy: false,
      });

      makeReportsListForEmail();
    }

    getBalance();
  }, []);

  /* ----------------------------------- */
  /*          API Calls                  */
  /* ----------------------------------- */

  const sendData = async () => {
    let supplierProvidedRes;
    if (supplierProvidedSbomFlag) {
      supplierProvidedRes = await createSupplierProvidedSbomAccessRecord();
      if (!supplierProvidedRes.isSuccess) {
        toastError(
          `Unable to process SBOM access request at this time. If this issue persists, please contact our support.`,
          {
            autoClose: 7000,
          },
        );
        return;
      }
    }

    setState({ isBusy: true });

    const formData = new FormData();

    formData.append('requests', JSON.stringify(sendRequestObject));

    if (endingTokenBalance < 0) {
      setState({ hasErrors: { ...hasErrors, nsf: true }, isBusy: false });
      return;
    }
    try {
      const res = await fetch(config.api.urlFor('catalogSubmitRequest'), {
        method: 'POST',
        body: formData,
      });
      if (res.status === 200) {
        setNextModalStage('result');
        updateSBOMpurchaseStatus();
        resetModalData();
        if (supplierProvidedSbomFlag) {
          getSbomAccessStatus();
        }
      } else if (res.status === 403) {
        const resData = await res.text();
        if (resData === 'nonsufficient funds') {
          setState({ hasErrors: { ...hasErrors, nsf: true } });
        }
      } else if (res.status >= 500) {
        toastError(`Unknown Error, please try again later. If this issue persists, please contact our support.`, {
          autoClose: 7000,
        });
      }
      setState({ isBusy: false });
    } catch (err) {
      setState({ isBusy: false });
    }
  };

  const createSupplierProvidedSbomAccessRecord = async () => {
    if (selectedFullHash) {
      return fetch(config.api.urlFor('createSbomAccessRecord'), {
        method: 'POST',
        body: {
          sbomHash: selectedFullHash,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          console.log('json = ', json);
          return json;
        });
    } else {
      return { isSuccess: false };
    }
  };

  const makeReportsListForEmail = () => {
    const { requestedProductArray = [] } = requestedProductReports;
    let requestObject = requestedProductReports;
    let requestArray = Object.keys(requestObject);
    let price = 0;
    let products = [];

    const reportsListForEmail = requestArray
      .map((data) => {
        if (requestObject[`${data}`] > 0) {
          price = reportDescriptions[`${data}`] ? reportDescriptions[`${data}`].price : 0;

          products = [];

          allowMultipleProducts
            ? requestedProductArray.map((productData) => {
                if (productData.reportsArray.find((r) => r === data)) {
                  products.push({
                    productName: productData.productName,
                    productVersion: productData.productNumber,
                  });
                }
              })
            : null;

          return { report: data, quantity: requestObject[`${data}`], tokens: price, products };
        }
      })
      .filter((data) => data !== undefined);

    setState({ reportsListForEmail });
  };

  const countDecimals = (value) => {
    if (Number.isNaN(Number.parseFloat(value)) || Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
  };

  const makeTableRows = (reportsListForEmail) => {
    const quantitySum = reportsListForEmail ? reportsListForEmail.reduce((total, r) => total + r.quantity, 0) : 0;
    let tokenSum = reportsListForEmail ? reportsListForEmail.reduce((total, r) => total + r.quantity * r.tokens, 0) : 0;

    tokenSum = countDecimals(tokenSum) <= 2 ? tokenSum.toFixed(2) : tokenSum.toFixed(3);

    setTokenSum(tokenSum);
    setEndingTokenBalance(tokenSum);

    return (
      <React.Fragment>
        {reportsListForEmail &&
          reportsListForEmail.map((data, i) => {
            let countDecimalsTokenEa = countDecimals(data.tokens);
            let countDecimalsTokensUtilized = countDecimals(data.tokens * data.quantity);
            const tokenEa = countDecimalsTokenEa <= 2 ? data.tokens.toFixed(2) : data.tokens.toFixed(3);
            const tokensUtilized =
              countDecimalsTokensUtilized <= 2
                ? (data.tokens * data.quantity).toFixed(2)
                : (data.tokens * data.quantity).toFixed(3);

            return (
              <tr key={i}>
                <td style={reportsFirstTDs}>
                  {data.report}{' '}
                  {data.products && data.products.length
                    ? '(' +
                      data.products
                        .map((p) => {
                          return p.productName + (p.productVersion ? ' ' + p.productVersion : '');
                        })
                        .join(', ') +
                      ')'
                    : '(' + productName + (productVersionOrNumber ? ' ' + productVersionOrNumber : '') + ')'}
                </td>
                <td style={reportsOtherTDs}>{data.quantity}</td>
                <td style={reportsOtherTDs}>{tokenEa}</td>
                <td style={reportsOtherTDs}>{tokensUtilized}</td>
              </tr>
            );
          })}
        <tr style={{ backgroundColor: 'rgb(224,224,224)' }}>
          <th className="order-total-text" style={firstTHTotal}>
            Item Total
          </th>
          <td style={otherTHsTotal}>{quantitySum}</td>
          <td style={otherTHsTotal}></td>
          <td style={otherTHsTotal}>{tokenSum}</td>
        </tr>
      </React.Fragment>
    );
  };

  const getBalance = () => {
    setState({ fetchingBalance: true });
    fetch(config.api.urlFor('tokenBalance'))
      .then((res) => res.json())
      .then((response) => {
        if (response.hasSubscription === false) {
          const contactSupportDomain =
            config.contactSupport && config.contactSupport.domain && config.contactSupport.domain + '/contact';

          const toastMessage = (
            <div>
              You don&apos;t have a subscription and you cannot purchase reports.
              <br />
              Please contact support for assistance
              {!!contactSupportDomain && (
                <>
                  <br />
                  <a href={contactSupportDomain} target={contactSupportDomain}>
                    Fortress Information Security
                  </a>
                </>
              )}
              .
            </div>
          );

          const toastOptions = { toastId: 'error-purchase-reports', autoClose: 7000 };
          toastError(toastMessage, toastOptions);
        } else {
          const data = response.isSuccess ? response.data : {};
          setState({
            balance: data.balance
              ? countDecimals(data.balance) <= 2
                ? data.balance.toFixed(2)
                : data.balance.toFixed(3)
              : 0,
            fetchingBalance: false,
          });
        }
      })
      .catch(() => {
        const toastMessage = (
          <div>
            Unknown Error, please try again later. If this issue persists, please contact our support:
            <br />
            <a
              href={config.contactSupport && config.contactSupport.domain + '/contact'}
              target={config.contactSupport && config.contactSupport.domain + '/contact'}
            >
              Fortress Information Security
            </a>
            .
          </div>
        );

        const toastOptions = { toastId: 'error-purchase-reports', autoClose: 7000 };
        toastError(toastMessage, toastOptions);
      });
  };

  const setTokenSum = (newTokenSum) => {
    if (newTokenSum !== tokenSum) {
      setState({ tokenSum: newTokenSum });
    }
  };

  const setEndingTokenBalance = (tokenSum) => {
    const maxCountDecimal = Math.max(countDecimals(balance), countDecimals(tokenSum));
    const multiplyingValue = Math.pow(10, maxCountDecimal);
    const updatedEndingTokenBalance = (
      (balance * multiplyingValue - tokenSum * multiplyingValue) /
      multiplyingValue
    ).toFixed(maxCountDecimal);

    if (endingTokenBalance !== updatedEndingTokenBalance) {
      setState({ endingTokenBalance: updatedEndingTokenBalance });
    }
  };

  return (
    <div className="modal-report-info-box modal-mt-override">
      <div className="m-bold pb-2">Checkout Confirmation</div>
      <div className="pb-4" style={{ fontSize: 'medium' }}>
        Please confirm the token utilization below.
      </div>

      <div>
        {fetchingBalance ? (
          <div>
            <Spinner isVisible={true} />
          </div>
        ) : (
          <div>
            <table className="table table-bordered" border="0" cellPadding="0" cellSpacing="0" style={balanceTables}>
              <thead>
                <tr>
                  <th style={firstTHBalance} scope="col">
                    Beginning Token Balance
                    <a
                      aria-describedby="footnote-label"
                      data-tip
                      data-for="onHoverTokens"
                      style={{ fontSize: 'large' }}
                    ></a>
                    <ReactTooltip id="onHoverTokens" place="right" effect="solid">
                      <p style={{ marginTop: '2px', marginBottom: '15px', textAlign: 'center' }}>
                        Token balances may not reflect activity performed outside of the Marketplace module.
                      </p>
                      <p style={{ marginBottom: '2px', textAlign: 'center' }}>
                        Prior month balances are reconciled 30 days after the month close.
                      </p>
                    </ReactTooltip>
                  </th>
                  <th style={otherTHsBalance} scope="col">
                    {balance}
                  </th>
                </tr>
              </thead>
            </table>

            <table className="table table-bordered" border="0" cellPadding="0" cellSpacing="0" style={reportsTable}>
              <thead>
                <tr style={{ border: '1px solid #dee2e6', backgroundColor: 'rgb(211,211,211)' }}>
                  <th className="m-tal-c" style={firstTHReports} scope="col">
                    Item
                  </th>
                  <th className="m-tal-c" style={otherTHReports} scope="col">
                    Quantity
                  </th>
                  <th className="m-tal-c" style={otherTHReports} scope="col">
                    Token/Ea.
                  </th>
                  <th className="m-tal-c" style={otherTHReports} scope="col">
                    Tokens Utilized
                  </th>
                </tr>
              </thead>
              <tbody className="m-tal-c">{makeTableRows(reportsListForEmail)}</tbody>
            </table>

            <table className="table table-bordered" border="0" cellPadding="0" cellSpacing="0" style={balanceTables}>
              <thead>
                <tr>
                  <th style={firstTHBalance} scope="col">
                    Ending Token Balance
                    <a
                      aria-describedby="footnote-label"
                      data-tip
                      data-for="onHoverTokens"
                      style={{ fontSize: 'large' }}
                    ></a>
                  </th>
                  <th style={otherTHsBalance} scope="col">
                    {endingTokenBalance}
                  </th>
                </tr>
              </thead>
            </table>

            {nsf ? <div className="m-error m0">This purchase request exceeds your available funds.</div> : ''}
            <div className="m-flex-out product-checkout-button-container">
              {!!allowGoingBackwards ? (
                <button
                  onClick={() => {
                    setNextModalStage('reports');
                  }}
                  className="modal-report-info-btn btn"
                >
                  Back
                </button>
              ) : (
                <span></span>
              )}
              {hasErrors.hasReport && (
                <button disabled={isBusy} onClick={() => sendData()} className="modal-report-info-btn btn">
                  Confirm <Spinner isVisible={isBusy} />
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCheckoutModal;
