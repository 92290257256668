import React from 'react';

import { Link } from 'react-router-dom';
import { CARDGREEN } from '../constants';

const CallToAction = ({ problem = null, color = CARDGREEN, icon = 'fas fa-caret-right', action, link, ...props }) => {
  if (props.style) {
    props.style.backgroundColor = color;
  }

  const Button = () =>
    link ? (
      <Link
        to={link}
        className="btn btn-outline-light btn-lg"
        role="button"
        style={{ backgroundColor: color }}
        {...props}
      >
        <h4 className="m-2">
          {action}
          <span className={action ? 'float-right pl-3' : null}>
            <i className={icon}></i>
          </span>
        </h4>
      </Link>
    ) : (
      <div
        className="btn btn-outline-light btn-disabled btn-lg"
        role="button"
        style={{ backgroundColor: color }}
        {...props}
      >
        <h4 className="m-2">
          {action}
          <span className={action ? 'float-right pl-3' : null}>
            <i className={icon}></i>
          </span>
        </h4>
      </div>
    );

  return (
    <React.Fragment>
      {problem ? (
        <div className="card my-3">
          <div className="card-body text-center">
            <h3>{problem}</h3>
            <Button />
          </div>
        </div>
      ) : (
        <Button />
      )}
    </React.Fragment>
  );
};

export default CallToAction;
