import React from 'react';

const Release_1_4_0 = () => {
  return (
    <div>
      <h3>
        <b>March 2022</b>
      </h3>
      <p>
        Fortress is proud to present Version 1.4 of the A2V Network. This time around, we’ve heavily invested in the
        requests from the hundreds of users on our platform, and made it easier to facilitate assessment exchange with
        the Trust Center. As a heads up, Version 1.4 will be live for one month, with 1.5 going out in April. Don’t
        worry though, we’ll still keep a close eye out on any urgent issues that may pop up.
      </p>
      <h4>
        <b>Trust Center</b>
      </h4>
      <p>
        With the influx of regulatory pressure we’re seeing for better Utilities supply chain risk management, many have
        been asking for a painless way to obtain a NATF (North-American Transmission Forum) Compliant Questionnaire.
      </p>
      <ul>
        <li>
          <p>
            <b>Trust Center’s Questionnaire category</b>
          </p>
          <ul>
            <li>
              <p>
                Companies are now able to select the NATF Questionnaire as a file-type when uploading a file into the
                Trust Center.
              </p>
              <ul>
                <li>
                  <p>
                    Administrators of a subscription can modify files on the trust center, with the new capability of
                    downloading the initial file that was uploaded.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Companies can also share their NATF Questionnaire and other trust center items to external parties such
                as customers.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Email notifications</b>
          </p>
          <ul>
            <li>
              <p>
                A user in A2V requests your file <em>(admin only)</em>
              </p>
            </li>
            <li>
              <p>Another company approves your file requests</p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Interface</b>
          </p>
          <ul>
            <li>
              <p>
                “Coming Soon” categories such as Software Bill of Materials (SBOM) <em>&amp;</em> Certifications have
                been removed.
              </p>
            </li>
            <li>
              <p>File Icons have been updated based on file types uploaded (ex. Word, PDF)</p>
            </li>
            <li>
              <p>File download history is now sorted by date, with minor updates to the modal’s text format.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Security</b>
          </p>
          <ul>
            <li>
              <p>We’ve taken some extra steps in security, beginning with role-based access.</p>
              <ul>
                <li>
                  <p>
                    Users who have a viewer role assigned to them by their admins can no longer request trust center
                    files.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>Logging improvements to detect errors, and improvements in load performance.</p>
            </li>
            <li>
              <p>Claiming a page requires an email that passes validation.</p>
            </li>
            <li>
              <p>
                Improved the speed of virus scans for files uploaded into the Trust Center. (Did you know the virus scan
                is using the File Integrity Assurance software powered by Fortress?)
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h4>
        <b>Navigation</b>
      </h4>
      <p>
        With our growing design team, we are beginning to make significant improvements to the User Experience and
        Interface of A2V starting with:
      </p>
      <ul>
        <li>
          <p>
            <b>Menu</b>
          </p>
          <ul>
            <li>
              <p>
                Your Trust Center is now accessible as a menu item - bringing you over to the files you’re sharing with
                customers in a click of a button.
              </p>
            </li>
            <li>
              <p>
                As much as we love to tell you about Fortress, the “About Us” menu item has been moved to the bottom
                footer of the website. We’ve changed the “Help” menu item to “Contact”, so you can get in touch whenever
                you like (even if it’s just to say hello).
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>My Vendors</b>
          </p>
          <ul>
            <li>
              <p>
                To make it easier to add companies to your “My Vendors” list, we’ve created an additional button to add
                a vendor to your “My Vendors” list located at the top of a company’s profile page.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Products</b>
          </p>
          <ul>
            <li>
              <p>
                We’re excited to show you what we’re working on in the lab, but sometimes our eagerness creates
                incomplete features in the application. With over 1+ million products in our database, we prematurely
                launched the “Products Marketplace” as a beta. For now… The “Coming Soon” Products tile on the Home Page
                has been removed. Additionally, we’ve removed the “Products” search capability in the Marketplace.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h4>
        <b>
          Reports <em>&amp;</em> Findings
        </b>
      </h4>
      <p>
        The A2V solution was built for scale - the scale companies need to rapidly gain risk insight on their supply
        chain. To that end, we’ve made some leaps forward to improve the experience for supply chain risk managers:
      </p>
      <ul>
        <li>
          <p>
            <b>NATF Questionnaire</b>
          </p>
          <ul>
            <li>
              <p>
                The NATF Questionnaire is now available as an orderable report in the marketplace. If the company you’re
                browsing has a NATF Questionnaire in their trust center, it’ll be available to you when they approve
                your request. If it’s not there, ordering the NATF Questionnaire will prompt our team to reach out to
                the company and bring their NATF Questionnaire into the trust center.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Cyber Hygiene</b>
          </p>
          <ul>
            <li>
              <p>
                The cyber hygiene scan is one of our most used features. It assesses the security controls that were
                implemented on a company’s website. To make this tool more insightful, we’ve updated the descriptions of
                all the cyber hygiene findings for: DNS, SSL/TLS, and Application Security.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Findings Management</b>
          </p>
          <ul>
            <li>
              <p>
                Now available for admins and users on the home page is a “My Findings” table that provides lightweight
                GRC tracking and management of findings originating from Data-Driven Risk Ranks <em>&amp;</em>{' '}
                Continuous Monitoring.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Product Reports</b>
          </p>
          <ul>
            <li>
              <p>
                We’re seeing a convergence of OT <em>&amp;</em> IT Risk, and want it to be easy to type in a product and
                get your reports in the easiest way possible. When ordering a Data-Driven Product Assessment (DDPA) or a
                Product Assessment Provenance Reports (PAPR), these reports can now be downloaded from the Home Page.
                Simply scroll down to the bottom of the Home Page to a section called “Available Reports”, and you will
                find a table that shows the status of your report - once the report has been reviewed for 100% accuracy
                from our product gurus, your reports will be downloadable.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h4>
        <b>Branding</b>
      </h4>
      <p>
        We’re growing A2V so fast that we need to keep up with how we present ourselves. You may notice some small
        changes to our name in this release:
      </p>
      <ul>
        <li>
          <p>
            <b>Company Logos</b>
          </p>
          <ul>
            <li>
              <p>
                Before you report an email from Fortress A2V to the fashion police as a phishing link, know that’s
                probably us. We’ve rebranded the logos and looks of emails you receive from Fortress A2V. To be sure,
                check out the new futuristic logos the next time you login - representing our spirit of innovation.
              </p>
            </li>
            <li>
              <p>
                We not only made updates to our logos but yours…(more accurately made no updates) We had a small bug
                before that caused your company’s logo to be updated with outdated logos or logos of your subsidiary
                company. It was an embarrassing mistake, but we’ve fixed that. We hope you understand.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Report Name</b>
          </p>
          <ul>
            <li>
              <p>
                The A2V Questionnaire report has been renamed to “Validated Security Controls Assessment” to better
                represent what it really is - a vendor questionnaire that our team of security assessors checked with a
                supplier for accuracy. The next time you browse for available reports and your transaction history,
                you’ll see the new naming convention.
              </p>
              <ul>
                <li>
                  <p>
                    We’ve kept the name “A2V Questionnaire” for some of our early customers who have supported us since
                    Day 1.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h4>
        <b>Dashboard</b>
      </h4>
      <p>
        We are starting to make significant investment in Dashboards that will help you see your SCRM program under one
        pane of glass. If you have any dashboard requests, send us a message in the “Contact” form in A2V! Until we
        release the new Home Page, here are some capabilities we’re providing:
      </p>
      <ul>
        <li>
          <p>
            <b>My Vendor Table</b>
          </p>
          <ul>
            <li>
              <p>
                You may have seen a slight delay in your report statuses in the “My Vendors” table. We’ve upgraded our
                servers, and this should be accurately updating completed DDRR, Cyber Hygiene, and Monitoring status at
                lightning speed.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Tokens</b>
          </p>
          <ul>
            <li>
              <p>
                Bing Crosby said “Brother can you spare a dime”, not a penny. We’ve removed the thousandth decimal place
                on your available token balance in “My Tokens”, and rounded it up to the closest hundredth for everyone.
                (ex. .089 → .09)
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Findings Management</b>
          </p>
          <ul>
            <li>
              <p>Comments you write in your “My Findings” table are now limited to 160 characters.</p>
            </li>
            <li>
              <p>
                There was a small bug that caused the status and resolutions on the “My Findings” table to not save
                properly. This is now fixed.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h4>
        <b>General Improvements</b>
      </h4>
      <ul>
        <li>
          <p>
            <b>
              User Input Restrictions <em>&amp;</em> Email Validation
            </b>
          </p>
          <ul>
            <li>
              <p>User input restrictions on the “Need Help” button during Login</p>
            </li>
            <li>
              <p>Email validation on clicking “Forgot Password” during Login</p>
            </li>
            <li>
              <p>
                If multiple instances or “tabs” of A2V is open, logging out of one will force sign out of every
                instance.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Release_1_4_0;
