import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import ScoreBreakdown from './scoreBreakdown';
import ProviderTargets from './providerTargets';
import Vulnerabilities from './vulnerabilities';
import RiskHistory from './riskHistory';
import VendorsRisk from './vendorsRisk';
import PortfoliosRisk from './portfoliosRisk';

class Scores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Route exact path={`${this.props.match.path}/score/:tier/:score`} component={ScoreBreakdown} />
        <Route exact path={`${this.props.match.path}/provider/:id/targets`} component={ProviderTargets} />
        <Route
          exact
          path={`${this.props.match.path}/vulnerabilities`}
          component={() => <Redirect to={`${this.props.match.path}/vulnerabilities/global/1`} />}
        />
        <Route exact path={`${this.props.match.path}/vulnerabilities/:tier/:page`} component={Vulnerabilities} />
        <Route exact path={`${this.props.match.path}/risk/history`} component={RiskHistory} />
        <Route exact path={`${this.props.match.path}/vendorRisk/:risk?/:value?`} component={VendorsRisk} />
        <Route exact path={`${this.props.match.path}/portfolioRisk/:risk?/:value?`} component={PortfoliosRisk} />
      </React.Fragment>
    );
  }
}

export default Scores;
