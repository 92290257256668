import React, { useEffect, useMemo } from 'react';

import config from './../../../../config';

// reusable components
import Section from '../../reusable/section';
import SubSection from '../../reusable/subSection';
import TopBanner from '../../reusable/topBanner';
import HorizontalLayout from '../../layouts/horizontalLayout';
import PrintCoverPageHorizontal from '../../layouts/printCoverPageHorizontal';
import Busy from '../../../../components/busy';
import FetchError from '../../reusable/fetchError';
import { useUpdateState, useSignalEffect, useQuery } from '../../..';

// sections
import PrintCoverPage from './components/printCoverPage';
import VendorInfo from './components/vendorInfo';
import ComplianceAlerts from './components/complianceAlerts';
import History from './components/history';
import Disclaimer from './components/disclaimer';
import OverallVendorScore from './components/overallVendorScore';
import CompanyDescription from './components/companyDescription';
import HighRiskFactors from './components/highRiskFactors';
import RiskObservations from './components/riskObservations';
import ScoringOverview from './components/scoringOverview';
import CyberScannerMap from './components/cyberScannerMap';
import ScoreDrillDown from './components/scoreDrillDown';

import { buildDdrrNavOptions } from './components/buildDdrrNavOptions.js';

import './ddrr.css';

export const DDRR = (props) => {
  const { query, fetchData = true, data: propsData } = props;
  const { showDrillDown, id, vendorID } = query || useQuery();

  const [state, setState] = useUpdateState({
    isBusy: true,
    fetchError: false,
    data: propsData,
  });

  useSignalEffect(
    async (signal) => {
      if (!fetchData) return;

      let url = vendorID
        ? config.api.urlFor('posternDDRRByVendorID', { id: vendorID })
        : config.api.urlFor('posternDDRRByDDRRID', { id });

      if (showDrillDown) {
        url += '?showDrillDown=true';
      }

      try {
        const response = await fetch(url, { signal });
        const data = await response.json();
        setState({ data, isBusy: false });
      } catch (err) {
        setState({ fetchError: true });
      }
    },
    [fetchData, vendorID],
  );

  const { isBusy, fetchError, data = propsData } = state;

  useEffect(() => {
    if (data) {
      setState({ isBusy: false });
    }
  }, [data]);

  const navOptions = useMemo(() => {
    let navOptions = [];

    if (data && data.cyberMap) {
      if (showDrillDown && data.cyberMap.length > 0) {
        navOptions = buildDdrrNavOptions('mapAndDrilldown');
      } else if (showDrillDown) {
        navOptions = buildDdrrNavOptions('onlyDrilldown');
      } else if (data.cyberMap.length > 0) {
        navOptions = buildDdrrNavOptions('onlyMap');
      } else {
        navOptions = buildDdrrNavOptions(); // no additional options
      }
    }

    return navOptions;
  }, [showDrillDown, data]);

  const {
    vendor = {},
    redData = {},
    questionData = [],
    questionDataRaw = [],
    cyberMap,
    category,
    riskCountryData,
    safeCountryData,
    doe_cyber_presence,
    eo_cyber_presence,
    doe_countries,
    eo_countries,
    continentCountries,
    ddrr_history,
  } = data || {};

  return (
    <>
      {fetchError ? (
        <FetchError />
      ) : (
        <Busy isBusy={isBusy}>
          <div id="postern-ddrr">
            <HorizontalLayout navOptions={navOptions}>
              <PrintCoverPageHorizontal navOptions={navOptions}>
                <PrintCoverPage
                  vendor={vendor}
                  category={category}
                  riskCountryData={riskCountryData}
                  safeCountryData={safeCountryData}
                />
              </PrintCoverPageHorizontal>
              <div className="ddrr-report-content">
                <div className="print-hide">
                  <div className="top-banner-section">
                    <TopBanner vendor={vendor} />
                  </div>
                  <div className="company-info-score-section">
                    <div className="company-info-score-section-left-column">
                      <SubSection ddrrHeaderColor sectionID="vendor-name" sectionName={vendor.companyName || 'Vendor'}>
                        <div className="sub-section-child">
                          <VendorInfo vendor={vendor} />
                        </div>
                      </SubSection>
                      <SubSection ddrrHeaderColor sectionID="company-description" sectionName="Company Description">
                        <div className="sub-section-child">
                          <CompanyDescription vendor={vendor} />
                        </div>
                      </SubSection>
                    </div>
                    <div className="company-info-score-section-right-column">
                      <SubSection ddrrHeaderColor sectionID="overall-vendor-score" sectionName="Inherent Risk Score*">
                        <div className="sub-section-child overall-vendor-score-center">
                          <OverallVendorScore
                            vendor={vendor}
                            redData={redData}
                            doe_cyber_presence={doe_cyber_presence}
                            eo_cyber_presence={eo_cyber_presence}
                          />
                        </div>
                      </SubSection>
                      <SubSection ddrrHeaderColor sectionID="high-risk-factors" sectionName="High Risk Factors">
                        <div className="sub-section-child">
                          <HighRiskFactors vendor={vendor} category={category} />
                        </div>
                      </SubSection>
                    </div>
                  </div>
                </div>
                <div className="print-shown">
                  <Section ddrrHeaderColor sectionID="compliance-alerts" sectionName="Compliance Alerts">
                    <ComplianceAlerts
                      redData={redData}
                      doe_cyber_presence={doe_cyber_presence}
                      doe_countries={doe_countries}
                      eo_cyber_presence={eo_cyber_presence}
                      eo_countries={eo_countries}
                    />
                  </Section>

                  <Section ddrrHeaderColor sectionID="risk-observations" sectionName="Risk Observations">
                    <RiskObservations category={category} questionData={questionData} vendor={vendor} />
                  </Section>

                  {cyberMap ? (
                    cyberMap.length > 0 ? (
                      <Section ddrrHeaderColor sectionID="cyber-scanner-map" sectionName="Cyber Scanner Map">
                        <CyberScannerMap cyberMap={cyberMap} continentCountries={continentCountries} />
                      </Section>
                    ) : null
                  ) : null}

                  <Section
                    ddrrHeaderColor
                    sectionID="scoring-overview"
                    sectionName="Data-Driven Risk Rank Scoring Overview"
                  >
                    <ScoringOverview />
                  </Section>

                  {showDrillDown && (
                    <Section ddrrHeaderColor sectionID="score-drill-down" sectionName="Score Drill Down">
                      <ScoreDrillDown questionDataRaw={questionDataRaw} vendor={vendor} />
                    </Section>
                  )}

                  <Section ddrrHeaderColor sectionID="ddrr-history" sectionName="DDRR History">
                    <History ddrr_history={ddrr_history} vendor={vendor} />
                  </Section>

                  <Section
                    ddrrHeaderColor
                    sectionID="ddrr-disclaimer"
                    sectionName="Fortress Information Security Disclaimer"
                  >
                    <Disclaimer />
                  </Section>
                </div>
              </div>
            </HorizontalLayout>
          </div>
        </Busy>
      )}
    </>
  );
};

export default DDRR;
