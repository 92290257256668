import React from 'react';
import SectionToggle from './providerTargets/sectionToggle';
import Chartjs from './chartjs';
import FindingsSummaryItem from './findingsSummaryItem';
import SeverityLabel from './vendor/severityLabel';

class Findings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.targets) !== JSON.stringify(prevProps.targets) ||
      this.props.flatten !== prevProps.flatten ||
      this.props.title !== prevProps.title ||
      this.props.category !== prevProps.category ||
      this.props.tagId !== prevProps.tagId
    ) {
      this.setState(this.props);
    }
  }

  toggleSection(section) {
    this.setState((state) => {
      const newState = {};
      newState[section] = !state[section];

      return newState;
    });
  }

  render() {
    const {
      stats,
      title,
      description,
      targets,
      showTargets,
      sections = [],
      flatten,
      avg_score,
      tagId,
      category,
      targetsToggleEnabled = true,
    } = this.state;

    const categoryScore = {
      marginTop: '10px',
      marginLeft: '10px',
      marginBottom: '20px',
    };

    const summaryData = sections.filter((section) => Array.isArray(section.targets) && section.targets.length);

    return (
      <>
        <div>
          <h6>
            <b>Findings Summary:</b>
          </h6>
          <div style={categoryScore}>
            {category} Score:
            <SeverityLabel label={avg_score} />
          </div>
          {summaryData &&
            summaryData.map((item, index) => (
              <FindingsSummaryItem key={index} label={'critical'} tagId={tagId + item.heading}>
                {item.brief}
              </FindingsSummaryItem>
            ))}

          {summaryData.length === 0 && (category === 'DNS' || category === 'SSL/TLS') ? (
            <p>There were no {category} risks discovered as part of this scan.</p>
          ) : null}
          {summaryData.length === 0 && category === 'App Security' ? (
            <p>There were no web application risks discovered as part of this scan.</p>
          ) : null}
          {summaryData.length === 0 && category === 'Vulnerability' ? (
            <p>There were no vulnerabilities discovered as part of this scan.</p>
          ) : null}
        </div>
        <br />
        <br />
        <h6>
          <b>Detailed Findings:</b>
        </h6>
        <div className="og-alert og-alert-info" style={{ border: '1px solid' }}>
          <div className="row">
            <div className="col">
              <div className="row mb-3">
                <div className="col">
                  <h5 className="og-alert-heading">{title}</h5>
                  <hr />
                  {description}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p style={{ borderLeft: '2px solid #888' }} className="ml-2 py-2">
                    <span className="pl-2">
                      <strong>{(targets || []).length}</strong> targets were scanned.
                    </span>
                  </p>
                  {targetsToggleEnabled && targets && targets.length ? (
                    <>
                      {!flatten && (
                        <SectionToggle
                          value={showTargets}
                          onClick={() => this.setState((state) => ({ showTargets: !state.showTargets }))}
                        />
                      )}
                      <div className="row">
                        {(flatten || (showTargets && targets)) &&
                          targets.map((target, i) => (
                            <div key={i} className="col-3">
                              {target.hostname || target}
                            </div>
                          ))}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {stats && (
              <div className="col-3" style={{ borderLeft: '1px solid' }}>
                {stats.title && <h6>{stats.title}</h6>}
                <Chartjs id={`chart_heading_${title.replace(' ', '_')}`} height="200px" options={stats} />
              </div>
            )}
          </div>
        </div>
        {sections &&
          sections.map((section, index) => (
            <span key={index} id={tagId + section.heading}>
              {!(section.hideIfEmpty && (!section.targets || !section.targets.length)) ? (
                <>
                  <div className={section.targets.length > 0 ? 'og-alert og-alert-bad' : 'og-alert og-alert-warning'}>
                    <div className="row">
                      <div className="col">
                        <div className="row mb-3">
                          <div className="col">
                            <h5 className="og-alert-heading">{section.heading}</h5>
                            <hr />
                            {section.description}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <p style={{ borderLeft: '2px solid #888' }} className="ml-2 py-2">
                              <span className="pl-2">{section.brief}</span>
                            </p>
                            {targetsToggleEnabled && section.targets && section.targets.length ? (
                              <>
                                {!flatten && (
                                  <SectionToggle
                                    value={this.state[`showSection${index}`]}
                                    onClick={() => this.toggleSection(`showSection${index}`)}
                                  />
                                )}
                                <div className="row">
                                  {(flatten || this.state[`showSection${index}`]) &&
                                    section.targets.map((target, i) => (
                                      <div key={i} className="col-3">
                                        {target.hostname || target}
                                      </div>
                                    ))}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {section.stats && (
                        <div className="col-3" style={{ borderLeft: '1px solid' }}>
                          {section.stats.title && <h6>{section.stats.title}</h6>}
                          <Chartjs
                            id={`chart_section_${section.stats.title.replace(' ', '_')}_${index + 1}`}
                            height="200px"
                            options={section.stats}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </span>
          ))}
        {flatten && (
          <div className="row justify-content-md-center mb-3">
            <div className="col-3">
              <hr />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Findings;
