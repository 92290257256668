import React from 'react';
import { Tooltip } from 'reactstrap';
import { TermsAndConditionsModal, PrivacyPolicyModal, DMCANoticeModal } from './notices';
import { useUpdateState, Spinner, PasswordStrength } from '../../components';
import { nameIsValid } from '../../lib/utils';
import '../../components/password-strength/password-strength.css';

const SetPasswordForm = ({ config, id, isInvited, vendorID, isGuest = false }) => {
  const [state, setState] = useUpdateState({
    privacyPolicyIsOpen: false,
    termsAndConditionsIsOpen: false,
    dmcaNoticeIsOpen: false,
    showPasswordRules: false,
    show2FATooltip: false,
    qrURL: null,
    firstName: '',
    lastName: '',
  });

  const {
    password,
    privacyPolicyIsOpen,
    termsAndConditionsIsOpen,
    dmcaNoticeIsOpen,
    show2FATooltip,
    twoFactorCode,
    confirmAgreement,
    invalidToken,
    tokenError,
    isBusy,
    receiveInformation,
    passwordIsStrong,
    passwordsMatch,
    firstName,
    lastName,
  } = state;

  const finish = async (e) => {
    e.preventDefault();

    setState({ isBusy: true });

    const params = new URLSearchParams();
    params.set('code', twoFactorCode);
    params.set('id', id);
    params.set('password', password);

    if (isGuest) {
      params.set('firstName', firstName);
      params.set('lastName', lastName);
    }

    const response = await fetch(config.api.urlFor('finishRegistration'), {
      method: 'POST',
      body: params,
    });

    const result = await response.json();

    if (result.isSuccess) {
      if (isGuest) {
        window.location.assign('/#/catalog/vendor/' + vendorID + '/TrustCenter');
        window.location.reload();
      } else {
        window.location.replace('/');
      }
    } else {
      setState({ invalidToken: true, tokenError: result.error, isBusy: false });
    }
  };

  const handlePasswordChange = ({ password, isValid, isConfirmed }) => {
    if (isValid && isConfirmed) {
      setState({ password, passwordIsStrong: isValid, passwordsMatch: isConfirmed });
    }
  };

  const handleValueChange = (e, keyName) => {
    const val = e.target.value;

    if (val === '' || keyName === 'twoFactorCode' || nameIsValid(val)) {
      setState({ [keyName]: val });
    }
  };

  const toggle2FATooltip = () => setState({ show2FATooltip: !state.show2FATooltip });

  const toggleTermsAndConditions = () => setState({ termsAndConditionsIsOpen: !state.termsAndConditionsIsOpen });

  const togglePrivacyPolicy = () => setState({ privacyPolicyIsOpen: !state.privacyPolicyIsOpen });

  const toggleDMCANotice = () => setState({ dmcaNoticeIsOpen: !state.dmcaNoticeIsOpen });

  if (!isInvited) return <h5>Invalid invitation</h5>;

  return (
    <form onSubmit={finish}>
      {isGuest && (
        <div className="row" style={{ paddingBottom: '0.3rem' }}>
          <div className="col-md-6">
            <div className={firstName.length ? 'met' : 'not-met'}>
              <input
                id="form_name"
                type="text"
                name="name"
                className="form-control"
                placeholder="First Name"
                required="required"
                data-error="Firstname is required."
                onChange={(e) => handleValueChange(e, 'firstName')}
                value={firstName}
                autoFocus={true}
              />
              <div className="help-block with-errors" />
            </div>
          </div>
          <div className="col-md-6">
            <div className={lastName.length ? 'met' : 'not-met'}>
              <input
                id="form_lastname"
                type="text"
                name="surname"
                className="form-control"
                placeholder="Last Name"
                required="required"
                data-error="Lastname is required."
                onChange={(e) => handleValueChange(e, 'lastName')}
                value={lastName}
              />
              <div className="help-block with-errors" />
            </div>
          </div>
        </div>
      )}

      <PasswordStrength
        id="inputPassword1"
        uppers={1}
        specials={1}
        digits={1}
        lowers={1}
        length={config.passwordRequiredMinLength}
        onChange={handlePasswordChange}
        inputProps={{ className: 'form-control', placeholder: 'Enter Password' }}
      />
      <br />
      <div className="row">
        <div className="col-md-11">
          <p>Please enter the following QR code into a 2 factor app such as Google Authenticator</p>
        </div>
        <div className="col-md-1" id="Register-qrinfo">
          <span className="fa fa-info-circle"></span>
        </div>
      </div>
      <Tooltip
        placement="right"
        isOpen={show2FATooltip}
        autohide={false}
        target="Register-qrinfo"
        toggle={toggle2FATooltip}
      >
        To enable 2FA you need to download a 2FA app such as{' '}
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en_US"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Authenticator
        </a>{' '}
        on your mobile device. Once you have downloaded the app please take a picture of this QR code and input the code
        into the QRCode Field.
      </Tooltip>
      <div>
        <img
          src={config.api.urlFor('generate2fa', { id })}
          alt="QR"
          className="img-responsive mx-auto"
          style={{
            width: '200px',
            height: '200px',
            background: 'white',
            paddingTop: '30px',
            paddingBottom: '30px',
            paddingLeft: '30px',
            paddingRight: '30px',
            display: 'block',
            margin: '0 auto',
          }}
        />
      </div>
      <p>Please enter the code that your app provides</p>
      <div className="form-group">
        <div className="form-label-group">
          <input
            type="text"
            id="twofaverifyCode"
            className="form-control"
            placeholder="Email address"
            required="required"
            onChange={(e) => handleValueChange(e, 'twoFactorCode')}
            value={twoFactorCode}
          ></input>
          <label htmlFor="twofaverifyCode">QRCode</label>
        </div>
      </div>
      <div className="form-group" style={{ marginTop: '-10px' }}>
        <div className="form-group" style={{ display: 'flex', marginBottom: '0px', marginTop: '0.8rem' }}>
          <input
            type="checkbox"
            style={{ width: '20px', marginRight: '10px', boxShadow: 'none', marginTop: '-7px' }}
            className="form-control"
            required="required"
            onChange={(e) => setState({ confirmAgreement: e.target.checked })}
            value={confirmAgreement}
          ></input>
          <label htmlFor="confirmAgreement">
            {' '}
            I agree to the
            <b data-test-id="Modal_Button-button-1" onClick={toggleTermsAndConditions}>
              {' '}
              <u className="cursor-pointer">Terms of Use</u>
            </b>
            ,{' '}
            <b data-test-id="Modal_Button-button-2" onClick={togglePrivacyPolicy}>
              {' '}
              <u className="cursor-pointer">Privacy Policy</u>
            </b>{' '}
            and{' '}
            <b data-test-id="Modal_Button-button-2" onClick={toggleDMCANotice}>
              {' '}
              <u className="cursor-pointer">Copyright notice</u>
            </b>
            .<a style={{ color: '#FF0000' }}>*</a>
          </label>
        </div>

        <div className="form-group" style={{ display: 'flex', marginBottom: '0px' }}>
          <input
            type="checkbox"
            style={{ width: '20px', marginRight: '10px', boxShadow: 'none', marginTop: '-7px' }}
            defaultChecked="True"
            className="form-control"
            onChange={(e) => setState({ receiveInformation: e.target.checked })}
            value={receiveInformation}
          ></input>
          <label htmlFor="receiveInformation">
            I would like to receive information about Fortress&apos;s products, services, events and promotions.{' '}
            <br></br>
          </label>
        </div>
      </div>

      <TermsAndConditionsModal isOpen={termsAndConditionsIsOpen} onClose={toggleTermsAndConditions} />

      <PrivacyPolicyModal isOpen={privacyPolicyIsOpen} onClose={togglePrivacyPolicy} />

      <DMCANoticeModal isOpen={dmcaNoticeIsOpen} onClose={toggleDMCANotice} />

      {invalidToken && (
        <div className="text-danger">
          {tokenError}
          <br />
          <br />
        </div>
      )}
      <button
        type="submit"
        className="btn btn-primary btn-block"
        value="Login"
        disabled={
          !passwordIsStrong ||
          !passwordsMatch ||
          !twoFactorCode ||
          isBusy ||
          !confirmAgreement ||
          (isGuest && (!firstName.trim() || !lastName.trim()))
        }
      >
        Login <Spinner style={{ marginLeft: '5px' }} isVisible={isBusy} />
      </button>
    </form>
  );
};

export default SetPasswordForm;
