import React from 'react';
import { Link } from 'react-router-dom';

import { Table, Pagination } from '../../components/';
import { urlFromPath, pageIsValid } from '../../lib/utils';

import config from '../../config';

class RoleList extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      model: [],
      isBusy: true,
      search: '',
      pageSize: 10,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { search } = this.props.location.query;
    const { page, orderBy, dir } = this.props.match.params;

    const { pageSize } = this.state;

    this.getData(search, page, pageSize, orderBy, dir);
  }

  componentDidUpdate(prevProps) {
    const { search: cSearch = '' } = this.props.location.query;
    const { page: cPage, orderBy: cOrderBy, dir: cDir } = this.props.match.params;

    const { search: pSearch = '' } = prevProps.location.query;
    const { page: pPage, orderBy: pOrderBy, dir: pDir } = prevProps.match.params;

    const { pageSize, totalRecords } = this.state;

    let updatedPage = cPage,
      pageUpdated = false;

    if (!pageIsValid(cPage, totalRecords, pageSize)) {
      updatedPage = '1';
      pageUpdated = true;
    }

    if (!pageUpdated && updatedPage === pPage && cOrderBy === pOrderBy && cDir === pDir && cSearch === pSearch) {
      return;
    }

    this.getData(cSearch, updatedPage, pageSize, cOrderBy, cDir);
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getData(search, page, pageSize, orderBy, dir) {
    search = search || '';
    page = parseInt(page || 1, 10);
    pageSize = parseInt(pageSize || 10, 10);
    orderBy = orderBy || 'name';
    let asc = dir === 'desc' ? 0 : 1;

    this.setState({ isBusy: true });

    let pathname = urlFromPath(this.props.match.path, { page, orderBy, dir });

    if (search) {
      pathname = pathname.concat(`?search=${search}`);
    }

    this.props.history.push(pathname);

    fetch(config.api.urlFor('roles', { search: search, page: page, pageSize: pageSize, orderBy: orderBy, asc: asc }))
      .then((response) => response.json())
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            search: search,
            page,
            orderBy,
            dir,
            model: data.rows.map((row) => ({
              name: <Link to={`/admin/roles/${row.id}`}>{row.name}</Link>,
              description: row.description,
            })),
            totalRecords: data.totalRecords || 0,
            isBusy: false,
          });
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isBusy: false });
        }
      });
  }

  onSearchSubmit(e) {
    e.preventDefault();

    const { search, pageSize, orderBy, dir } = this.state;

    this.getData(search, 1, pageSize, orderBy, dir);
  }

  render() {
    const { match, history } = this.props;
    const { orderBy, dir, page } = match.params;
    const { totalRecords, model, isBusy, search } = this.state;

    let query = search ? `?search=${search}` : '';
    let path = query ? match.path + query : match.path;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light mb-3">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav-item active">
                <Link className="btn btn-outline-primary" to={`/admin/roles/new`}>
                  New
                </Link>
              </li>
            </ul>
            <form className="form-inline" onSubmit={this.onSearchSubmit.bind(this)}>
              <input
                id="search"
                type="text"
                className="form-control mr-2"
                placeholder="search"
                value={search}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
            </form>
          </div>
        </nav>
        <Table
          columns={[
            { label: 'name', source: 'name', sortable: true },
            { label: 'description', source: 'description' },
          ]}
          data={model}
          isBusy={isBusy}
          sort={orderBy}
          sortDirection={dir}
          onSorted={(orderBy, dir) => history.push(window.mapPath(path, { orderBy, dir, page }))}
        />
        <Pagination
          page={page}
          totalRecords={totalRecords}
          onPageChange={(page) => history.push(window.mapPath(path, { orderBy, dir, page }))}
        />
      </div>
    );
  }
}

export default RoleList;
