import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import FileAccessRequest from './fileAccessRequest';

export const FileAccessRequestColumn = ({ title, requests, id }) => (
  <div>
    <h5 className="file-requests-title">{title}</h5>
    <div className="file-requests-column-container">
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <div>
            <div
              className={'file-requests-list' + (snapshot.isDraggingOver ? ' isdraggingover' : '')}
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {requests &&
                requests.map((request, index) => (
                  <FileAccessRequest key={request.id} request={request} index={index} content={request.content} />
                ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </div>
  </div>
);

export default FileAccessRequestColumn;
