import React from 'react';
import {
  GrDocument,
  GrDocumentPdf,
  GrDocumentExcel,
  GrDocumentTxt,
  GrDocumentImage,
  GrDocumentPpt,
  GrDocumentCsv,
  GrDocumentWord,
  GrShareOption,
} from 'react-icons/gr';
import { toastError } from '../lib/utils';
import { RequestStatus, ScanStatus } from './vendor/trustCenter/lib';

const ProtectedFileItemActionButton = (props) => {
  const {
    accessRequestStatus,
    scanStatus,
    className,
    canEdit,
    canRequestAccess,
    isUploadRequest,
    onDownloadClick = () => {},
    onRequestAccessClick = () => {},
    onModifyClick = () => {},
    onUploadRequestClick = () => {},
  } = props;

  let extraClassName,
    caption,
    role = null,
    onClickHandler = () => {};

  if (isUploadRequest) {
    if (canEdit) {
      extraClassName = 'text-white bg-warning';
      caption = 'Upload';
      role = 'button';
      onClickHandler = onUploadRequestClick;
    } else if (canRequestAccess) {
      if (accessRequestStatus) {
        extraClassName = 'text-white bg-warning';
        caption = `Access ${accessRequestStatus}`;
      } else {
        extraClassName = 'btn-danger';
        caption = 'Request Access';
        role = 'button';
        onClickHandler = onRequestAccessClick;
      }
    } else {
      extraClassName = 'text-white bg-secondary';
      caption = 'Access Restricted';
    }
  } else if (canEdit) {
    extraClassName = 'btn-danger';
    caption = 'Modify';
    role = 'button';
    onClickHandler = onModifyClick;
  } else {
    if (scanStatus !== ScanStatus.VALID) {
      switch (scanStatus) {
        case ScanStatus.PENDING:
          extraClassName = 'text-white bg-warning';
          caption = 'Pending Scan';
          break;
        case ScanStatus.SCANNING:
          extraClassName = 'text-white bg-warning';
          caption = 'Scanning';
          break;
        case ScanStatus.ERROR:
          extraClassName = 'btn-danger';
          caption = 'Invalid';
          break;
        case ScanStatus.REQUESTED:
          if (canRequestAccess) {
            extraClassName = 'btn-danger';
            caption = 'Request Access';
            role = 'button';
            onClickHandler = onRequestAccessClick;
          } else {
            extraClassName = 'text-white bg-secondary';
            caption = 'Unable to Request';
          }
          break;
        default:
          break;
      }
    } else {
      switch (accessRequestStatus) {
        case RequestStatus.DENIED:
          extraClassName = 'text-white bg-secondary';
          caption = 'Access Restricted';
          break;
        case RequestStatus.PENDING:
          extraClassName = 'text-white bg-warning';
          caption = 'Access Pending';
          break;
        case RequestStatus.APPROVED:
          extraClassName = 'btn-success';
          caption = 'Download';
          role = 'button';
          onClickHandler = onDownloadClick;
          break;
        default:
          if (canRequestAccess) {
            extraClassName = 'btn-danger';
            caption = 'Request Access';
            role = 'button';
            onClickHandler = onRequestAccessClick;
          } else {
            extraClassName = 'text-white bg-secondary';
            caption = 'Unable to Request';
          }
          break;
      }
    }
  }

  return (
    <span role={role} className={className + ' ' + extraClassName} onClick={onClickHandler}>
      {caption}
    </span>
  );
};

const ProtectedFileItem = (props) => {
  //Props
  const {
    canEdit,
    canCreate,
    canShare,
    canRequestAccess,
    fileItem,
    onPendingRequestsClick = () => {},
    onDownloadsClick = () => {},
    onRequestAccessClick = () => {},
    onDownloadClick = () => {},
    onModifyClick = () => {},
    onShareClick = () => {},
    onUploadRequestClick = () => {},
  } = props;

  //Prop-based consts
  const {
    name,
    type,
    description,
    fileClassName,
    fileTemplate,
    created,
    accessRequestStatus,
    isPublic,
    scanStatus = ScanStatus.PENDING,
    countPendingRequests = 0,
    countApprovedRequests = 0,
    countDeniedRequests = 0,
    countDownloads = 0,
  } = fileItem;

  const hasRequests = countPendingRequests > 0 || countApprovedRequests > 0 || countDeniedRequests > 0;
  const hasViews = countDownloads > 0;

  const isUploadRequest = scanStatus === ScanStatus.REQUESTED;
  const showOverlay = scanStatus !== ScanStatus.VALID && !isUploadRequest;

  const canModify = canCreate || canEdit;

  //Functions
  const getScanStatusOverlay = () => {
    let overlayTitle = undefined;
    let overlayDisplay = null;

    switch (scanStatus) {
      case ScanStatus.PENDING:
        overlayTitle = 'This file is awaiting a virus scan.';
        overlayDisplay = <div className="scan-status-nostarted">Pending Scan</div>;
        break;
      case ScanStatus.SCANNING:
        overlayTitle = 'A virus scan of the file is currently in progress.';
        overlayDisplay = <div className="scan-status-scanning">Scanning</div>;
        break;
      case ScanStatus.ERROR:
        overlayTitle =
          'One or more issues have been found during the virus scan for this file. For your safety, this file has been locked down.';
        overlayDisplay = (
          <div
            className="scan-status-invalid"
            role={canEdit ? 'button' : undefined}
            onClick={canEdit ? onModifyClick : undefined}
          >
            Invalid
          </div>
        );
        break;
    }

    return overlayDisplay ? (
      <div className="scan-status-overlay" title={overlayTitle}>
        {overlayDisplay}
      </div>
    ) : null;
  };

  //This is just an additional safeguard if the user tries to download a non-valid (pending, scanning, invalid) file
  const handleOnDowloadClick = () => {
    if (scanStatus === ScanStatus.VALID) {
      onDownloadClick();
    } else {
      toastError('This file cannot be downloaded at this time.');
    }
  };

  //  '.csv,.doc,.docx,.dotx,.pdf,.jpg,.png,.ppt,.pptx,.tiff,.tsv,.txt,.xls,.xlsx'
  const getFileIconByType = () => {
    switch (type) {
      case 'csv':
        return <GrDocumentCsv />;

      case 'doc':
      case 'docx':
        return <GrDocumentWord />;

      case 'jpg':
      case 'png':
      case 'tiff':
        return <GrDocumentImage />;

      case 'pdf':
        return <GrDocumentPdf />;

      case 'ppt':
      case 'pptx':
        return <GrDocumentPpt />;

      case 'txt':
        return <GrDocumentTxt />;

      case 'xls':
      case 'xlsx':
        return <GrDocumentExcel />;

      default:
        return <GrDocument />;
    }
  };

  //Render
  return (
    <div
      className={
        'protected-file-item-container smaller-text' +
        (showOverlay ? ' scan-overlay' : '') +
        (isUploadRequest ? ' upload-request' : '')
      }
    >
      {getScanStatusOverlay()}
      <div className="protected-file-item-info">
        <span className="protected-file-item-file-icon">{getFileIconByType()}</span>
        <span className="ml-2">
          <b>{name}</b>
        </span>
        {canShare && scanStatus === ScanStatus.VALID && (
          <span className="protected-file-item-share-icon" onClick={onShareClick}>
            <GrShareOption />
          </span>
        )}
        {!!fileClassName && <div className="file-class">File Type: {fileClassName}</div>}
        <div className="mt-2">
          {fileTemplate ? (
            <>
              [
              <a href={fileTemplate} target="_blank" rel="noreferrer noopener">
                Download Template
              </a>
              ] -{' '}
            </>
          ) : (
            <></>
          )}
          {description}
        </div>
        <div className="mt-2">
          <b>{isUploadRequest ? 'Requested:' : 'Uploaded:'}</b>{' '}
          {created ? new Date(created).toLocaleDateString() : 'N/A'}
        </div>
      </div>
      {scanStatus !== ScanStatus.ERROR && (
        <div className="protected-file-item-buttons-container">
          {/* Display the file statistics only for users allowed to modify the contents */}
          {canEdit && !isUploadRequest && (
            <>
              <span
                role={!hasRequests ? undefined : 'button'}
                className="protected-file-item-button col-4"
                onClick={!hasRequests ? undefined : onPendingRequestsClick}
                title={!hasRequests ? undefined : 'Manage access requests for this file'}
              >
                <span className="file-pending-req-cnt">{countPendingRequests} P</span>
                &nbsp;/&nbsp;
                <span className="file-approved-req-cnt">{countApprovedRequests} A</span>
                &nbsp;/&nbsp;
                <span className="file-denied-req-cnt">{countDeniedRequests} D</span>
              </span>
              <span
                role={!hasViews ? undefined : 'button'}
                className="protected-file-item-button col-5"
                onClick={!hasViews ? undefined : onDownloadsClick}
                title={!hasViews ? undefined : 'View file download history'}
              >
                {countDownloads + (countDownloads === 1 ? ' Download' : ' Downloads')}
              </span>
            </>
          )}
          {isUploadRequest ? (
            <span className={'file-request-banner-msg protected-file-item-button col-' + (canModify ? '9' : '8')}>
              This file has been requested
              {/* Display the lock icon for limited users always but when ready for download */}
              {!canModify && !isPublic && !(accessRequestStatus === RequestStatus.APPROVED) && (
                <i className="fas fa-lock sa-file-privacy-icon"></i>
              )}
            </span>
          ) : (
            <>
              {/* Display the lock icon for limited users always but when ready for download */}
              {!canModify && !isPublic && !(accessRequestStatus === RequestStatus.APPROVED) && (
                <i className="fas fa-lock sa-file-privacy-icon"></i>
              )}
            </>
          )}

          <ProtectedFileItemActionButton
            className={'protected-file-item-button col-' + (canEdit ? '3' : '4')}
            canEdit={canEdit}
            canRequestAccess={canRequestAccess}
            isUploadRequest={isUploadRequest}
            onRequestAccessClick={onRequestAccessClick}
            onDownloadClick={handleOnDowloadClick}
            onModifyClick={onModifyClick}
            accessRequestStatus={isPublic ? RequestStatus.APPROVED : accessRequestStatus}
            scanStatus={scanStatus}
            onUploadRequestClick={onUploadRequestClick}
          />
        </div>
      )}
    </div>
  );
};

export default ProtectedFileItem;
