import React, { Component } from 'react';

import './section.css';

export const SubSection = ({ children, sectionID, sectionName, sectionNumber, ddrrHeaderColor }) => {
  return (
    <div>
      <span id={sectionID} style={ddrrHeaderColor ? { background: '#004d70' } : {}} className="p-section-header">
        {sectionNumber ? sectionNumber + '.' : ''} {sectionName}
      </span>
      {children}
    </div>
  );
};

// class SubSection extends Component {
//   render() {
//     const { children, sectionID, sectionName, sectionNumber } = this.props
//     return <div>
//       <span id={sectionID} className="p-section-header">{sectionNumber ? sectionNumber+"." : "" } {sectionName}</span>
//       {children}
//     </div>
//   }
// }

export default SubSection;
