import React, { Component } from 'react';

import ReusableProvenanceTable from './reusableProvenanceTable';
import { scrollToSection } from '../../../../../constants/postern/scrollToSection.js';

export default class VulnSolution extends Component {
  render() {
    const { scores = {}, vulnSolution = [] } = this.props;
    const { vulnSolution: sVulnSolution = '' } = scores;
    const TableData = vulnSolution.filter(
      (row) =>
        row.criticality && (row.criticality.toLowerCase() === 'high' || row.criticality.toLowerCase() === 'critical'),
    );

    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          {
            <p>
              {sVulnSolution === 'high'
                ? `During our review of the current vendor, some vulnerabilities were found. Further investigation concludes that solutions to each vulnerability exists and are listed below. `
                : `During our review of the current vendor, no high or critical severity vulnerabilities were found. `}
              The timeline is calculated to reflect the delta since the remediated version was released, indicating the
              number of days the vendor may have had the opportunity to patch the listed vulnerabilities. For more
              information on vulnerability findings, please reference the{' '}
              <a
                className="navLink configurable-link"
                href={`provenance-appendix-vuln-solution`}
                onClick={(e) => scrollToSection(e, `provenance-appendix-vuln-solution`)}
              >
                appendix
              </a>
              .
            </p>
          }
        </div>

        {TableData.length > 0 ? (
          <ReusableProvenanceTable
            tableName="ManufacturingTable-"
            headers={[
              'CVE Number',
              'Criticality',
              'Publish Date',
              'Remediated Version',
              'Version Release Date',
              'Timeline (days)',
            ]}
          >
            {vulnSolution
              .filter((row) => row.criticality.toLowerCase() === 'high' || row.criticality.toLowerCase() === 'critical')
              .map((row, i) => {
                const publish_date = new Date(row.publish_date);
                const version_release_date = new Date(row.version_release_date);

                return (
                  <React.Fragment key={'ForeignOwnershipTable-inner-' + i}>
                    <tr className="provenance-table-row">
                      <th className="row-padding"> {row.cve} </th>
                      <td> {row.cvss_score} </td>
                      <td> {publish_date.toLocaleDateString()} </td>
                      <td> {row.remediated_version} </td>
                      <td> {version_release_date.toLocaleDateString()} </td>
                      <td> -{row.timeline.days} </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </ReusableProvenanceTable>
        ) : null}
      </React.Fragment>
    );
  }
}
