import React from 'react';

const Release_1_5_0 = () => (
  <div>
    <h3>
      <strong>April 2022</strong>
    </h3>
    <p>
      Fortress is proud to present Version 1.5 of the A2V Network. This time around, we’ve heavily invested in the
      requests from the hundreds of users on our platform, improved access and instructions on the Trust Center and
      created the integration between our A2V network and our Fortress Platform at the vendor level. Version 1.5 will be
      live on April 14th. If you experience any issue within A2V, just raise the issue and we will review it for future
      versions.
    </p>
    <h4>
      <strong>Trust Center</strong>
    </h4>
    <ul>
      <li>
        <p>
          Trust Center Instructions: The Trust Center now has instructions on how to see, approve or deny any pending
          requests. They can be found in the informative “How to” section.
        </p>
      </li>
      <li>
        <p>
          Trust Center Navigation Item: A2V top navigation now has a Trust Center button that will bring the user
          directly to their own Trust Center. If there is no subscription assigned to the user, they will be redirected
          to the help form to claim a page.
        </p>
      </li>
      <li>
        <p>
          Trust Center Page Loading Performance Improvements: The Trust Center Page will now render before all the
          documents are entirely loaded for users to be able to start managing requests and documents faster.
        </p>
      </li>
      <li>
        <p>
          Trust Center Documents Creator: The uploader of a Trust Center document a.k.a. the creator of the document
          will now be tracked in the A2V database. The document created date will now update whenever the file is saved
          in the event of a failure of the initial upload or if the first file was a placeholder.
        </p>
      </li>
      <li>
        <p>
          Trust Center Documents Private Download: Users of the owning subscription with a Subscriber Admin role will
          now be able to download their own Trust Center private documents when hitting Modify and then Download File on
          their document card.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Fortress Platform Integration </strong>
    </h4>
    <ul>
      <li>
        <p>
          FP-A2V Vendors API: The A2V vendors of a specific subscription can now be seen in Fortress Platform. Fortress
          Platform Admin users can now map FP vendors with A2V vendors.
        </p>
      </li>
      <li>
        <p>
          FP-A2V Scoring API: Once mapped to A2V vendors, Fortress Platform vendors get an A2V tab with all A2V company
          information data as well as Inherent Risk Score and Cyber Hygiene Score in the Vendor Information tab.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Network</strong>
    </h4>
    <ul>
      <li>
        <p>
          Most Popular Vendors: A2V Network now displays the top 10 most popular vendors i.e. the vendors that have been
          added the most frequently to subscribers’ My Vendors lists at the top of the Network page.
        </p>
      </li>
      <li>
        <p>
          Remove from My Vendors in Most Popular Vendors: Removing Vendors to their My Vendors list will not
          automatically remove them from the Most Popular Vendor section.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Search</strong>
    </h4>
    <ul>
      <li>
        <p>
          Trust Center NATF Questionnaire New Search Filter: A new search filter for NATF Questionnaires available in a
          vendor’s Trust Center has been added to the search area on the Network page.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Home</strong>
    </h4>
    <ul>
      <li>
        <p>
          Vendor Name in the Transaction Table: Each row of the transaction table located in the home page now have the
          associated vendor name along with the product and date ordered of the transaction. The vendor name column is
          sortable by ascending and descending alphabetical order.
        </p>
      </li>
      <li>
        <p>
          Findings Details Line Breaks: Lines breaks have been added to the Finding column in the Findings table located
          in the home page for better readability.
        </p>
      </li>
    </ul>
    <h4>
      <strong>My Vendors </strong>
    </h4>
    <ul>
      <li>
        <p>
          Sort My Vendors by Activity Date: The Created date column was added to My Vendors list. The Created date
          column is sortable by ascending and descending date order.
        </p>
      </li>
      <li>
        <p>
          Risk Tier, Cyber Hygiene and Foreign Influence Monitoring Data Fixes: Statuses of risk tier, cyber hygiene
          score and foreign influence monitoring are now aligned with the current statuses of purchases.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Company Profile </strong>
    </h4>
    <ul>
      <li>
        <p>
          Monitoring Alerts Renamed to Foreign Influence Alerts: Monitoring Alerts section of the Company Profile was
          renamed to Foreign Influence Alerts to match the naming convention of our Continuous Monitoring products.
        </p>
      </li>
      <li>
        <p>
          Claim the Page Button Overlapping Vendor Name Bugfix: The Vendor Name now has a dedicated width so that the
          “Claim the Page” button will not overlap long vendor names anymore.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Cart </strong>
    </h4>
    <ul>
      <li>
        <p>
          Click to Remove Button: The button to “Click to Remove” from the cart has been fixed and now enables users to
          remove selected items from their cart.
        </p>
      </li>
      <li>
        <p>
          Error Message when Users Try to Buy Reports without Having a Subscription: Users that are not assigned to any
          subscription will now get a message telling them to contact their Administrator to be assigned to a
          subscription before they can buy any report.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Product Reports</strong>
    </h4>
    <ul>
      <li>
        <p>
          Product Reports Mapping with Suppliers: All Fortress products reports - Product Assessment Provenance Report,
          Software Bill of Materials Analysis and Data-Driven Product Assessments - are now all mapped with their
          relevant suppliers.
        </p>
      </li>
    </ul>
    <h4>
      <strong>Login Page</strong>
    </h4>
    <ul>
      <li>
        <p>A2V Logos Update: The login and registration pages have been updated with the new A2V logos - AV Network.</p>
      </li>
    </ul>
    <h4>
      <strong>Admin </strong>
    </h4>
    <ul>
      <li>
        <p>
          Subscriber Page Enhancements: The Add Data-Driven Risk Rank section has been renamed to Claim Vendor Page. An
          Admin now has the functionality to claim the page using a drop down list of vendors names. The subscriber page
          will now display the DDRR id and the subscriber/vendor contact name who claimed the page once the page is
          claimed.
        </p>
      </li>
      <li>
        <p>
          Changing Subscriber Bugfix: While changing subscriber in the Admin menu, the Trust Center requests information
          are now fixed and the error message will not show up anymore on the new subscriber’s company profile.
        </p>
      </li>
      <li>
        <p>
          Code Refactoring: Several admin and users functions had been refactored to fit within A2V code guidelines.
        </p>
      </li>
      <li>
        <p>
          Admin Tabs Bugfix: “Page is invalid” error now shows up as design. Search in Admin tabs now responds properly.
          When clicking on other pages, while searching in Admin/Users, Admin/Invitation, Admin/Subscribers, the search
          parameters now stays on the screen.
        </p>
      </li>
      <li>
        <p>
          Financial Admin Role Creation: A new Financial Admin user role had been created to access and update the
          subscribers token balances. Going forward, this role will be the only role which can access the token balance
          updates functionality.
        </p>
      </li>
      <li>
        <p>
          Users Page Selection Bugfix: Selected Page is now the one that is highlighted when the user clicks on a page
          number in the Admin/Users section.
        </p>
      </li>
      <li>
        <p>
          Subscriber’s Page Lagging when Searching for a Vendor in Claim the Page Field: When typing the name of a
          subscriber in the Claim the Page field users will now get a selection of 10 subscriber that automatically
          adjusts to the characters they type in the search field.
        </p>
      </li>
    </ul>
  </div>
);

export default Release_1_5_0;
