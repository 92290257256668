import React, { useEffect, useState, useRef } from 'react';
import Busy from './busy';

/*
  - currentFilters = {
    'category': {
        '<option>': true/false
      }
    }
  - categoryOptionClassName =  { '<category>': '<extra-css-class>'}
  - applyFiltersFn = (<newFilterState>) => { /... }
*/
const CategoryFilterOptions = (props) => {
  //Refs
  const catFilterOptRef = useRef(null);

  //Props
  const { currentFilters, categoryOptionClassName, applyFiltersFn, currentFiltersLoaded } = props;

  //State
  const [isOpen, setIsOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(Object.keys(currentFilters)[0]);
  const [searchText, setSearchText] = useState('');
  const [filtersToDisplay, setFiltersToDisplay] = useState([]);

  //Effects
  useEffect(() => {
    if (isOpen) {
      setSearchText('');
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentFiltersLoaded) {
      handleFiltersToDisplayChange();
    }
  }, [currentFilters, currentFiltersLoaded, currentCategory, searchText]);

  //Functions
  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const handleResetOnClick = () => {
    let resetFilters = Object.assign({}, currentFilters);
    Object.keys(resetFilters).forEach((cat) => {
      return Object.keys(resetFilters[cat]).forEach((filterOption) => {
        return (resetFilters[cat][filterOption] = false);
      });
    });

    applyFiltersFn(resetFilters);
  };

  //Event Handlers
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchKeydown = (event) => {
    if (event.key === 'Escape') {
      if (searchText) {
        clearSearchText();
      } else {
        handleFilterClick();
      }
    }
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const handleCategoryOnChange = (event) => {
    setCurrentCategory(event.target.value);
  };

  const handleFilterOptionOnClick = (filterOption) => {
    const newFilters = Object.assign(
      {},
      {
        ...currentFilters,
        [currentCategory]: {
          ...currentFilters[currentCategory],
          [filterOption]: !currentFilters[currentCategory][filterOption],
        },
      },
    );

    applyFiltersFn(newFilters);
  };

  const handleOutsideClick = (event) => {
    if (
      isOpen &&
      catFilterOptRef &&
      catFilterOptRef.current &&
      !catFilterOptRef.current.contains(event.target) &&
      event.target.type !== 'checkbox' &&
      event.target.id !== 'clearSearch'
    ) {
      handleFilterClick();
    }
  };

  const handleFiltersToDisplayChange = () => {
    const filtersToDisplay = Object.keys(currentFilters[currentCategory]).filter((filterOption) => {
      return (
        !searchText ||
        currentFilters[currentCategory][filterOption] ||
        filterOption.toUpperCase().includes(searchText.toUpperCase())
      );
    });

    setFiltersToDisplay(filtersToDisplay);
  };

  //Render
  return (
    <div className="category-filter-options-container" ref={catFilterOptRef}>
      <button className="filter-btn" onClick={handleFilterClick}>
        <i className="fas fa-filter"></i>
        Filters
      </button>
      {isOpen && (
        <div className="category-filter-options-lists">
          <div className="category-filter-search-section">
            <div className="category-filter-search-container">
              <input
                type="text"
                value={searchText}
                placeholder="Search Filters"
                onChange={handleSearchOnChange}
                onKeyDown={handleSearchKeydown}
              />
              {searchText && (
                <button className="icon-btn" onClick={clearSearchText}>
                  <i id="clearSearch" className="far fa-times-circle"></i>
                </button>
              )}
            </div>
          </div>
          <Busy isBusy={!currentFiltersLoaded} smallest>
            <div className="category-filter-selection">
              <div className="category-filter-selecter">
                <select onChange={handleCategoryOnChange} value={currentCategory}>
                  {Object.keys(currentFilters).map((filterCategory) => {
                    return (
                      <option key={filterCategory} value={filterCategory}>
                        {filterCategory}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="reset-btn-container">
                <button onClick={handleResetOnClick} title="Remove all applied filters">
                  Reset
                </button>
              </div>
            </div>
            <div className="category-filter-options">
              {filtersToDisplay.length > 0 ? (
                filtersToDisplay.map((filterOption) => {
                  const isChecked = currentFilters[currentCategory][filterOption];
                  const addlClassName = categoryOptionClassName[currentCategory];

                  return (
                    <div
                      key={filterOption}
                      tabIndex={0}
                      className={'filter-option' + (isChecked ? ' option-selected' : '')}
                      onClick={() => handleFilterOptionOnClick(filterOption)}
                    >
                      <input type="checkbox" checked={isChecked} readOnly={true} tabIndex={-1} />
                      <span className={addlClassName ? addlClassName : ''}>{filterOption}</span>
                    </div>
                  );
                })
              ) : (
                <div className="no-results">No results found</div>
              )}
            </div>
          </Busy>
        </div>
      )}
    </div>
  );
};

export default CategoryFilterOptions;
