import React from 'react';
import trustCenterReducer from './reducer';

/* --------------------------------- */
/*             Store                 */
/* --------------------------------- */

const TrustCenterStore = React.createContext();

/* --------------------------------- */
/*           useContext              */
/* --------------------------------- */

export const useTrustCenterStore = () => React.useContext(TrustCenterStore);

/* --------------------------------- */
/*        Provider Component         */
/* --------------------------------- */

export const TrustCenterStoreProvider = ({ children, initialState = {} }) => {
  const [store, dispatch] = React.useReducer(trustCenterReducer, initialState);

  return <TrustCenterStore.Provider value={{ store, dispatch }}>{children}</TrustCenterStore.Provider>;
};
