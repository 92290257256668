import React, { Component } from 'react';

import ReusableDdrrTable from './reusableDdrrTable';
import SeverityPill from './../../../reusable/severityPill';

import './history.css';

export default class History extends Component {
  ddrrVersionDisplay(ddrrVersion) {
    const decimalPlaces = 1;
    return Math.trunc(ddrrVersion * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces).toFixed(decimalPlaces);
  }

  render() {
    const { ddrr_history = [], vendor = {} } = this.props;
    const { companyName = '', scoreDate, totalScore, totalScoreLabel } = vendor;
    const lastDate = new Date(scoreDate);
    return (
      <div id="ddrr-history-component">
        {ddrr_history.length > 0 ? (
          <ReusableDdrrTable
            tableName="DdrrHistoryTable-"
            headers={['Organization Name', 'DDRR Version', 'Date Completed', 'Inherent Risk Score']}
          >
            {ddrr_history.map((row, i) => {
              const rowDate = new Date(row.client_score_date);
              return (
                <tr key={'DdrrTable-' + i} className="ddrr-table-row">
                  <td>{companyName}</td>
                  <td>{this.ddrrVersionDisplay(row.ddrr_version)}</td>
                  <td>{rowDate ? rowDate.toLocaleDateString() : ''}</td>
                  <td>
                    {row.total_score ? row.total_score.toLocaleString() : ''} &nbsp;{' '}
                    <SeverityPill severity={row.criticality} />
                  </td>
                </tr>
              );
            })}
          </ReusableDdrrTable>
        ) : (
          <ReusableDdrrTable
            tableName="DdrrHistoryTable-"
            headers={['Organization Name', 'DDRR Version', 'Date Completed', 'Inherent Risk Score']}
          >
            {
              <tr className="ddrr-table-row">
                <td>{companyName}</td>
                <td>1.0</td>
                <td>{lastDate ? lastDate.toLocaleDateString() : ''}</td>
                <td>
                  {totalScore ? totalScore.toLocaleString() : ''} &nbsp; <SeverityPill severity={totalScoreLabel} />
                </td>
              </tr>
            }
          </ReusableDdrrTable>
        )}
        <br />
      </div>
    );
  }
}
