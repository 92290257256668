import React from 'react';

import './fetchError.css';

const FetchError = () => {
  return (
    <div className="postern-report-fetch-error">
      <h3>Unfortunately, we were unable to generate this report.</h3>
    </div>
  );
}

export default FetchError;