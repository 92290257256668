import React, { Component } from 'react';

import PrintButton from './../../components/postern/reusable/printButton';

// Reports

import PosternDemo from '../../components/postern/reports/posternDemo/posternDemo.jsx';
import DDRR from '../../components/postern/reports/ddrr/ddrr.jsx';
import Provenance from '../../components/postern/reports/provenance/provenance.jsx';
import './postern.css';
import './../../constants/postern/cssVars.css';

class Postern extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: '',
      query: {},
    };
  }

  componentDidMount = () => {
    const {
      match: {
        params: { report },
      },
      location: { query },
    } = this.props;

    this.setState({ report, query });
  };

  renderReport = () => {
    switch (this.state.report) {
      case 'demo':
        return <PosternDemo query={this.state.query} />;
      case 'ddrr':
        return <DDRR query={this.state.query} />;
      case 'provenance':
        return <Provenance query={this.state.query} />;
      default:
        return <p>Report not found</p>;
    }
  };

  render() {
    return (
      <React.Fragment>
        <PrintButton printOption={this.state.report || ''} />
        <div className="p-max-height">{this.renderReport()}</div>
      </React.Fragment>
    );
  }
}

export default Postern;
