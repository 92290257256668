import React, { Component } from 'react';

import './hexSeverity.css';

export default class HexSeverity extends Component {
  render() {
    const { letter = '' } = this.props;
    return <div className={'hex-severity ' + letter}>{letter}</div>;
  }
}
