import { useEffect } from 'react';

export const useSignalEffect = (fn, deps) =>
  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      try {
        await fn(abortController.signal);
      } catch (err) {
        if (err.name !== 'AbortError') {
          throw err;
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, deps);

export default useSignalEffect;
