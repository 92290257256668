import { useState } from 'react';

const setStateWrapper = (setState) => (next) => {
  typeof next === 'function' ? setState(next) : setState((previous) => ({ ...previous, ...next }));
};

export const useUpdateState = (initial) => {
  const [state, setState] = useState(initial);

  return [state, setStateWrapper(setState)];
};

export default useUpdateState;
