import React from 'react';

const Alert = ({ message, type, className, onClose, linkTo }) => {
  const cssClass =
    'alert alert-dismissible fade show ' +
    (type === 'success'
      ? 'alert-success'
      : type === 'warning'
      ? 'alert-danger'
      : type === 'info'
      ? 'alert-info'
      : 'alert-primary') +
    (className ? ' ' + className : '');

  return (
    <div className={cssClass} role="alert" id="alert">
      {message}
      {linkTo}
      <button
        type="button"
        className="close"
        onClick={() => {
          document.getElementById('alert').style.display = 'none';
          onClose && onClose();
        }}
      >
        <span>&times;</span>
      </button>
    </div>
  );
};

export default Alert;
