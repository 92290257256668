import React, { Component } from 'react';

export default class SeverityFlag extends Component {
  render() {
    const { color = '#000' } = this.props;
    return (
      <div className={'severity-flag'} style={{ color: color }}>
        &#9873;
      </div>
    );
  }
}
