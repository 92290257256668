import React from 'react';
import * as Constants from '../../constants';

const DdrlFormatter = (cell, row) => {
  const cellColor =
    cell === 'Critical'
      ? Constants.CRITITCALRED
      : cell === 'High'
      ? Constants.HIGHORANGE
      : cell === 'Medium'
      ? Constants.MODERATEYELLOW
      : cell === 'Low'
      ? Constants.LOWGREEN
      : Constants.MARGINALBLUE;
  return (
    <div className="container">
      <div className="row">
        <div key={0} className="col" style={{ padding: 0 }}>
          <div key={1} className="progress" style={{ height: '22px' }}>
            <div
              key={2}
              className="progress-bar"
              role="progressbar"
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: 14,
                backgroundColor: `${cellColor}`,
                minWidth: '4em',
              }}
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {cell}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DdrlFormatter;
