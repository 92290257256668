import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { DdrlFormatter } from '.';

const Table = ({ data }) => {
  const columns = [
    {
      dataField: 'categoryName',
      text: 'Risk Area',
    },
    {
      dataField: 'ddrl',
      text: 'Data Driven Risk Level',
      formatter: DdrlFormatter,
    },
    {
      dataField: 'intelligenceUtilized',
      text: 'Intelligence Utilized',
      style: {
        width: '800px',
      },
    },
    {
      dataField: 'sourcesAndMethods',
      text: 'Sources and Methods',
    },
  ];

  return (
    <div>
      <BootstrapTable keyField="categoryId" data={data} columns={columns} />
    </div>
  );
};

export default Table;
