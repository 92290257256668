import React, { Component } from 'react';
import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';
import { generateSymbol, generateColor } from '../../../../../constants/postern/riskIconGenerator.js';
import { makeGoogleMapsUrl } from '../../../../../lib/utils';
import SafeHyperlink from '../../../../safeHyperlink';

// import "./physicalPresence.css"

export default class PhysicalPresence extends Component {
  render() {
    const { scores = {}, physicalPresence = [] } = this.props;
    const { physicalPresence: sPhysicalPresence = '' } = scores;

    return (
      <div className="pp-table">
        <div className="postern-section-summary-paragraph">
          {sPhysicalPresence == 'high'
            ? 'During our review of the physical presence, some were found in countries of adversarial presence.  Further investigation concludes that the foreign influence from physical presence is likely significant.'
            : 'During our review of the physical presence, none were found in countries of adversarial presence.  Further investigation concludes that the foreign influence from physical presence is not likely significant, and thus no finding is raised.'}
        </div>

        {physicalPresence.length > 0 ? (
          <div>
            <ReusableProvenanceTable
              tableName="PhysicalPresenceTable-"
              headers={['', 'Country', 'Cities', 'Known Addresses', 'Evidence']}
            >
              {physicalPresence.map((row, i) => {
                return (
                  <React.Fragment key={'PhysicalPresenceTable-' + i}>
                    <tr className="provenance-table-row">
                      <th className="row-padding" scope="row">
                        {' '}
                        <SeverityCircle
                          symbol={generateSymbol(row.highRisk)}
                          color={generateColor(row.highRisk)}
                        />{' '}
                      </th>
                      <td> {row.country} </td>
                      <td> {row.city} </td>
                      <td>
                        {' '}
                        <SafeHyperlink url={makeGoogleMapsUrl(row.country, row.city, row.known_address)}>
                          {row.known_address}
                        </SafeHyperlink>
                      </td>
                      <td className="m-word-break">
                        {' '}
                        <SafeHyperlink url={row.evidence}>{row.evidence}</SafeHyperlink>{' '}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </ReusableProvenanceTable>
          </div>
        ) : null}

        <footer className="footer-p-wrapper">
          <p>
            Corporate physical locations are searched using publicly available sources, including but not limited to the
            vendor website, job board queries (such as a careers page), and other third-party tools. Each country a
            vendor is located in is then captured. The primary source is the company’s location page. If one does not
            exist then the careers page is utilized. If there is no additional information on this page, third-party
            tools are utilized to assess physical presence.
          </p>
        </footer>
      </div>
    );
  }
}
