import React, { Component } from 'react';

import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';

import './appendixVulnSolution.css';

export default class AppendixVulnSolution extends Component {
  render() {
    const { vulnSolutionAppendix = [] } = this.props;
    const generateSymbol = (cvssScore) => (cvssScore >= 9 ? '!' : cvssScore >= 7 ? '!' : '&#10003');
    const generateColor = (cvssScore) =>
      cvssScore >= 9
        ? 'var(--posternRedCircle)'
        : cvssScore >= 7
        ? 'var(--posternYellowCircle)'
        : 'var(--posternGreenCircle)';

    return (
      <>
        <div className="postern-section-summary-paragraph">
          During our review of the current vendor, some vulnerabilities were found. Further investigation concludes that
          solutions to each vulnerability exists and are listed below.
        </div>

        {vulnSolutionAppendix.length > 0 ? (
          <div id="ap-vuln-solution-table">
            <ReusableProvenanceTable
              tableName="AppendixVulnSolutionTable-"
              headers={['', 'Vulnerable Domain', 'CVE', 'CVSS score']}
            >
              {vulnSolutionAppendix.map((row, i) => {
                return (
                  <React.Fragment key={'AppendixVulnSolutionTable-' + i}>
                    <tr className="provenance-table-row">
                      <th scope="row">
                        {' '}
                        <SeverityCircle
                          symbol={generateSymbol(row.cvss_score)}
                          color={generateColor(row.cvss_score)}
                        />{' '}
                      </th>
                      <td> {row.vulnerable_domain} </td>
                      <td> {row.cve} </td>
                      <td id="row-cvss-score"> {row.cvss_score} </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </ReusableProvenanceTable>
          </div>
        ) : null}
      </>
    );
  }
}
