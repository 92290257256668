import React, { Component } from 'react';

import './scoreDrillDown.css';

export default class ScoreDrillDown extends Component {
  render() {
    const { vendor = {}, questionDataRaw = [] } = this.props;
    const { totalScore = '' } = vendor;
    return (
      <div id="score-drill-down">
        <h5>Questions and Methods</h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Risk Area</th>
              <th>Question</th>
              <th>Method Approach</th>
              <th>Method Answer</th>
              <th>Score</th>
              <th>%</th>
              {/* <th>Internal Text</th> may not need this seems to be legacy code to toggle scope */}
              <th>External Text</th>
              <th>Question Max Points </th>
              <th>Method Max Points</th>
            </tr>
          </thead>

          <tbody>
            {questionDataRaw.map((question, i) => {
              const methodAnswerScore = (question.methodAnswerScore / parseInt(totalScore)) * 0.1;
              return (
                <tr key={question.categoryName + i}>
                  <td>{question.categoryName == 'Application/Hardware' ? 'System Access' : question.categoryName}</td>
                  <td>{question.question}</td>
                  <td>{question.methodApproach}</td>
                  <td>{question.methodAnswer}</td>
                  <td>{question.methodAnswerScore}</td>
                  <td>
                    <span className={methodAnswerScore >= 10 ? 'high-severity-text' : ''}>
                      {methodAnswerScore.toFixed(2)}
                    </span>
                  </td>
                  {/* <td>{ question.methodInternal }</td> may not need this seems to be legacy code to toggle scope */}
                  <td>{question.methodExternal}</td>
                  <td>{question.maxPoint}</td>
                  <td>{question.methodMaxScore}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
