import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import RoleList from './roleList';
import RoleDetails from './roleDetails';

class Roles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      listModel: [],
      username: '',
    };
  }

  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={() => <Redirect to={`${this.props.match.url}/name/asc/1`} />}
        />
        <Route exact path={`${this.props.match.url}/:orderBy/:dir/:page`} component={RoleList} />
        <Route exact path={`${this.props.match.url}/:id`} component={RoleDetails} />
      </React.Fragment>
    );
  }
}

export default Roles;
