import React from 'react';

import './table.css';

const Expansion = () => {
  return (
    <div className="loader-expansion-container">
      <ul className="loader-expansion-flex-container">
        <li>
          <span className="loader-expansion-loading"></span>
        </li>
      </ul>
    </div>
  );
};

const applyColumnDefaults = function (col = {}) {
  let val = {
    style: {},
    formatter: function (row) {
      return row[this.source];
    },
  };

  if (typeof col === 'string') {
    col = {
      source: col,
    };
  }

  col.label = col.label === undefined ? col.source : col.label;
  col.sortSource = col.sort || col.source;

  for (let i in col) {
    val[i] = col[i] || val[i];
  }

  return val;
};

const Table = ({
  busy: Busy = Expansion,
  sortSymbolAsc = <>&darr;</>,
  sortSymbolDesc = <>&uarr;</>,
  onSorted,
  id = `table_${Math.random() * 100000000 + 1000}`,
  columns = [],
  data = [],
  noDataMessage = '',
  isBusy = false,
  sort,
  sortDirection,
  className = '',
  ...props
}) => {
  columns = columns.map(applyColumnDefaults);

  if (!columns) {
    return (
      <strong className="text-danger">
        ERROR <small> | Table component cannot be rendered due to missing column definition.</small>
      </strong>
    );
  }

  return (
    <table id={id} className={'table table-striped' + (className ? ' ' + className : '')}>
      <thead>
        <tr>
          {columns.map((col, i) => (
            <th key={i} style={col.style}>
              {col.label}
              {col.sortable && (
                <button
                  className="link-button ml-1"
                  style={props.buttonLinkStyle}
                  onClick={() =>
                    onSorted &&
                    onSorted(col.sortSource, sort === col.sortSource && sortDirection === 'asc' ? 'desc' : 'asc')
                  }
                >
                  <span className={sort === col.sortSource && sortDirection === 'desc' ? 'text-primary' : ''}>
                    {sortSymbolDesc}
                  </span>
                  <span className={sort === col.sortSource && sortDirection === 'asc' ? 'text-primary' : ''}>
                    {sortSymbolAsc}
                  </span>
                </button>
              )}
              <div>{col.content}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!isBusy && data && data.map ? (
          <>
            {data.length ? (
              data.map((row = {}, i) => (
                <tr key={i} className={row.isDeleted ? 'deleted-instance-row' : ''}>
                  {columns.map((col, j) => (
                    <td key={j} className={col.class} style={col.style}>
                      {col.formatter(row, i)}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan={columns.length}>
                  {noDataMessage ? noDataMessage : 'No Data'}
                </td>
              </tr>
            )}
          </>
        ) : (
          <tr>
            <td className="text-center" colSpan={columns.length}>
              <Busy isBusy={true} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
