const generateSymbol = (risk) => {
  switch (risk) {
    case true:
    case 'High':
    case 'high':
      return '!';
    case false:
    case 'Low':
    case 'low':
      return '&#10003;';
    default:
      return '';
  }
};

const generateColor = (risk) => {
  switch (risk) {
    case true:
    case 'High':
    case 'high':
      return 'var(--posternRedCircle)';
    case false:
    case 'Low':
    case 'low':
      return 'var(--posternGreenCircle)';
    default:
      return '';
  }
};

export { generateSymbol, generateColor };
