import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Busy, useSignalEffect, useUpdateState, PasswordStrength, Spinner } from '../../components';
import { toast } from 'react-toastify';
import Alert from '../../components/alert';

const GlobalStyle = createGlobalStyle`
  body{
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), no-repeat center/100% url('/static/images/login-page-background-1-08_2021.jpg');
  }
`;

const SetPasswordForm = ({ config, token }) => {
  const history = useHistory();

  const [state, setState] = useUpdateState({
    isBusy: false,
    passwordStrong: false,
    password: '',
    passwordMatch: false,
    password2: '',
    isCompleted: false,
    passwordSending: false,
    updatePasswordFailure: false,
    updatePasswordFailureMessage: '',
  });

  const { isBusy, passwordStrong, passwordMatch, password, updatePasswordFailure, updatePasswordFailureMessage } =
    state;

  const onPasswordChange = ({ password, isValid, isConfirmed }) => {
    if (isValid && isConfirmed) {
      setState({ password, passwordStrong: isValid, passwordMatch: isConfirmed });
    }
  };

  const setPassword = async (e) => {
    e.preventDefault();

    setState({ isBusy: true, updatePasswordFailure: false, updatePasswordFailureMessage: '' });

    const res = await fetch(config.api.urlFor('updatePassword'), {
      method: 'POST',
      body: { token, password },
    });

    const result = await res.json();
    const { isSuccess, message } = result || {};

    if (isSuccess) {
      toast.success('Password set! Please log in to continue.', { position: toast.POSITION.BOTTOM_RIGHT });
      history.push('/login');
      return;
    } else {
      setState({
        isBusy: false,
        updatePasswordFailure: true,
        updatePasswordFailureMessage: message || 'Reset Password Failed. Please contact support.',
      });
    }
  };

  return (
    <form>
      <PasswordStrength
        uppers={1}
        specials={1}
        digits={1}
        lowers={1}
        length={config.passwordRequiredMinLength}
        onChange={onPasswordChange}
        inputProps={{ className: 'form-control', placeholder: 'Enter Password', autoFocus: true }}
      />
      {updatePasswordFailure && <Alert message={updatePasswordFailureMessage} type="warning" />}
      <button
        type="submit"
        className="btn btn-primary btn-block"
        value="Set Password"
        disabled={!passwordStrong || !passwordMatch || isBusy}
        onClick={setPassword}
      >
        Set Password <Spinner style={{ marginLeft: '5px' }} isVisible={isBusy} />
      </button>
    </form>
  );
};

export const ResetPassword = ({ config }) => {
  const { id: token } = useParams();

  const [state, setState] = useUpdateState({
    isBusy: true,
    passwordStrong: false,
    password: '',
    passwordMatch: false,
    isValid: false,
    password2: '',
    errors: '',
    isCompleted: false,
    passwordSending: false,
  });

  useSignalEffect(
    async (signal) => {
      const res = await fetch(config.api.urlFor('verifyPasswordToken'), {
        signal,
        method: 'PUT',
        body: { token },
      });

      const result = await res.json();
      const { isSuccess, message } = result || {};

      setState({
        isValid: isSuccess,
        errors: message,
        isBusy: false,
      });
    },
    [token],
  );

  const { isBusy, isValid, errors } = state;

  return (
    <div className="container">
      <GlobalStyle />
      <div className="row">
        <div style={{ textAlign: 'center', width: '100%' }}>
          <img alt="A2V Logo" className="a2v-logo-heading" src="/static/images/A2V_logo_negative.png" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center">Reset Password</h5>
            </div>
            <div className="card-body">
              <Busy isBusy={isBusy}>
                {isValid ? (
                  <SetPasswordForm config={config} token={token} />
                ) : (
                  <div className="row">
                    <div className="col-6 offset-3 text-center">
                      {errors}
                      <br />
                      <Link to="/">Login Page</Link>
                    </div>
                  </div>
                )}
              </Busy>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
