import React from 'react';

const LabeledListItem = ({ label, value }) => (
  <div className="row">
    <div className="col-4">{label}</div>
    <div className="col-8">{value}</div>
  </div>
);

export default LabeledListItem;
