import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-modal';

import config from '../../config';
import InviteButton from '../../components/inviteButton';
import Busy from '../../components/busy';

class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      subscriptions: [],
      page: 1,
      pageSize: 10,
      orderBy: 'username',
      totalRecords: 0,
      showRolesModal: false,
      userRoles: [],
      currentSelectedUserId: '',
    };
  }

  componentDidMount() {
    fetch(config.api.urlFor('subscribers', { pageSize: 0 }))
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          subscriptions:
            data && data.rows && data.rows.length ? data.rows.map((row) => ({ label: row.name, value: row.id })) : [],
        });
      })
      .catch(() => {});

    this.getUsers(1);
  }

  getUsers = (page) => {
    fetch(
      config.api.urlFor('users', {
        page,
        search: '',
        pageSize: this.state.pageSize,
        orderBy: this.state.orderBy,
        asc: 1,
      }),
    )
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, data } = response;

        if (isSuccess) {
          this.setState({
            page,
            users: data.rows.map((row) => ({
              id: row.id,
              emailAddress: row.emailAddress,
              firstName: row.firstName || '-',
              lastName: row.lastName || '-',
              created: new Date(row.created).toLocaleDateString(),
            })),
            totalRecords: data.totalRecords || 0,
          });
        }
      })
      .catch(() => {});
  };

  onTableChange = (props, { page }) => {
    this.getUsers(page);
  };

  buildUserCols = () => {
    return [
      {
        dataField: 'emailAddress',
        text: 'email address',
        sort: true,
        editable: false,
      },
      {
        dataField: 'firstName',
        text: 'first name',
        sort: true,
        editable: false,
      },
      {
        dataField: 'lastName',
        text: 'last name',
        sort: true,
        editable: false,
      },
      {
        dataField: 'created',
        text: 'created',
        sort: true,
        editable: false,
      },
      {
        dataField: 'actions',
        text: 'Actions',
        formatter: (row, cell) => {
          return (
            <div>
              <button className="btn btn-primary" onClick={() => this.toggleModal(cell.id)}>
                Roles
              </button>
            </div>
          );
        },
      },
    ];
  };

  getRoles = (userId) => {
    fetch(config.api.urlFor('userRoles', { id: userId, page: 1 }))
      .then((response) => response.json())
      .then((userRoles) => {
        this.setState({ userRoles: userRoles.rows });
      })
      .catch(() => {});
  };

  revokeRole = (roleID) => {
    fetch(config.api.urlFor('revokeRoles', { id: this.state.currentSelectedUserId }), {
      method: 'POST',
      body: { roleIDs: [roleID] },
    })
      .then((response) => response.json())
      .then(() => this.getRoles(this.state.currentSelectedUserId))
      .catch(() => {});
  };

  grantRole = (roleID) => {
    fetch(config.api.urlFor('grantRoles', { id: this.state.currentSelectedUserId }), {
      method: 'POST',
      body: { roleIDs: [roleID] },
    })
      .then((response) => response.json())
      .then(() => this.getRoles(this.state.currentSelectedUserId))
      .catch(() => {});
  };

  toggleModal = (userId) => {
    this.setState((prevState) => {
      if (prevState.showRolesModal) {
        return { showRolesModal: !prevState.showRolesModal, userRoles: [], currentSelectedUserId: '' };
      } else {
        this.setState({ showRolesModal: true, currentSelectedUserId: userId }, () => {
          this.getRoles(userId);
        });
      }
    });
  };

  render() {
    const { users, subscriptions, totalRecords, page, pageSize, showRolesModal } = this.state;
    let totalSize = Number(totalRecords);
    return (
      <div className="mb-5">
        <InviteButton
          buttonTitle="Invite User"
          associations={subscriptions}
          context="subscriber"
          submitUrl={config.api.urlFor('inviteUser')}
        />
        <BootstrapTable
          remote
          keyField="id"
          data={users}
          columns={this.buildUserCols()}
          pagination={paginationFactory({ page, sizePerPage: pageSize, totalSize, hideSizePerPage: true })}
          onTableChange={this.onTableChange}
        />
        <Modal
          isOpen={showRolesModal}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            },
          }}
          onRequestClose={this.toggleModal}
        >
          <div className="modal-header">
            <h4 className="modal-title">User Roles</h4>
          </div>
          <Busy isBusy={this.state.userRoles.length === 0} className="p-5">
            <table className="table">
              <thead>
                <tr>
                  <td>name</td>
                  <td>system?</td>
                  <td>granted?</td>
                  <td>action</td>
                </tr>
              </thead>
              <tbody>
                {this.state.userRoles.map((role, i) => (
                  <tr key={`role-revoke-${i}`}>
                    <td>{role.name}</td>
                    <td>
                      {role.isSystem ? (
                        <i className="far fa-check-square fa-lg"></i>
                      ) : (
                        <i className="far fa-square"></i>
                      )}
                    </td>
                    <td>
                      <i className={`far fa-${role.granted ? 'check-' : ''}square fa-lg`}></i>
                    </td>
                    {role.granted ? (
                      <td>
                        <button className="btn btn-danger" onClick={() => this.revokeRole(role.id)}>
                          Revoke
                        </button>
                      </td>
                    ) : (
                      <td>
                        <button className="btn btn-success" onClick={() => this.grantRole(role.id)}>
                          Grant
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Busy>
        </Modal>
      </div>
    );
  }
}

export default UserManagement;
