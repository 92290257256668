import React from 'react';
import { Link, withRouter } from 'react-router-dom';

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  render() {
    const { match, principal } = this.props;

    const MenuLink = (props) => {
      return (
        <Link to={props.to} className={`nav-link ${match.path === props.to ? 'active' : null}`}>
          <i className={`fa ${props.iconClass} fa-nav fa-fw fa-lg`}></i>
          {props.label}
        </Link>
      );
    };

    return (
      <div>
        <ul
          className="nav flex-column nav-pills"
          style={{ width: '100%', borderRight: '1px solid #ddd', paddingRight: '15px' }}
        >
          <MenuLink label="home" to="/" iconClass="fa-home" />
          {!principal && <MenuLink label="login" to="/login" iconClass="fa-sign-in" />}
          {principal && (
            <React.Fragment>
              <hr />
              <MenuLink label="admin" to="/admin" iconClass="fa-gears" />
              <MenuLink label="users" to="/admin/users" iconClass="fa-users" />
              <MenuLink label="roles" to="/admin/roles" iconClass="fa-user-secret" />
              <hr />
              <MenuLink label="profile" to="/admin/profile" iconClass="fa-user-circle-o" />
              <MenuLink label="logout" to="/logout" iconClass="fa-sign-out" />
            </React.Fragment>
          )}
        </ul>
      </div>
    );
  }
}

export default withRouter(Menu);
