import React, { useEffect } from 'react';
import Modal from 'react-modal';
import Spinner from './spinner';
import { nameIsValid, emailIsValid } from '../lib/utils';
import { useUpdateState } from '../components';

const InviteButton = (props) => {
  const { associations, mode = 'new', context, inUserData, submitUrl, reloadDataInParent = () => {} } = props;

  const [state, setState] = useUpdateState({
    modalIsOpen: false,
    firstName: '',
    lastName: '',
    emailAddress: '',
    buttonTitle: props.buttonTitle || 'Invite Button',
    association: '',
    error: '',
    isInvited: false,
    isBusy: false,
    errorFields: {
      firstName: false,
      lastName: false,
      emailAddress: false,
    },
    touchedFields: {
      firstName: false,
      lastName: false,
      emailAddress: false,
    },
  });

  const {
    modalIsOpen,
    firstName,
    lastName,
    emailAddress,
    buttonTitle,
    association,
    error,
    isInvited,
    isBusy,
    errorFields,
    touchedFields,
  } = state;

  useEffect(() => {
    if (modalIsOpen && inUserData && context && mode === 'edit') {
      setState({
        firstName: inUserData.firstName,
        lastName: inUserData.lastName,
        emailAddress: inUserData.emailAddress.props.children,
        /* ToDo: remove the single role limitation when the UI needs to work with more than one assigned role per user */
        association: context === 'role' ? inUserData.roleIDs[0] : inUserData.subscriptionID,
      });
    }
  }, [modalIsOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({ isBusy: true });

    const params = new URLSearchParams();
    params.set('firstName', firstName);
    params.set('lastName', lastName);
    params.set('email', emailAddress);
    if (association) params.set('association', association);
    params.set('context', context);

    if (mode === 'edit') {
      params.set('id', inUserData.id);

      if (context === 'role' && association) params.set('roles', [association]);

      // ToDo: to be done for subscriptions if needed in the future.
    }

    fetch(submitUrl, {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.error) {
          return setState({ error: json.error, isBusy: false });
        }

        if (json.isSuccess == false) {
          return setState({ error: json.message, isBusy: false });
        }

        setState({ isInvited: true, isBusy: false, error: '' });
      });
  };

  const handleModalClose = () => {
    if (reloadDataInParent && typeof reloadDataInParent === 'function') {
      reloadDataInParent();
    }

    // Clear the component's state when closing the modal, in order to avoid seeing old data when opening it again.
    setState({
      modalIsOpen: false,
      firstName: '',
      lastName: '',
      emailAddress: '',
      buttonTitle: props.buttonTitle || 'Invite Button',
      association: '',
      error: '',
      isInvited: false,
      isBusy: false,
      errorFields: {
        firstName: false,
        lastName: false,
        emailAddress: false,
      },
      touchedFields: {
        firstName: false,
        lastName: false,
        emailAddress: false,
      },
    });
  };

  const openInvite = () => {
    setState({ modalIsOpen: true });
  };

  const handleInputBox = (e, keyName) => {
    const val = e.target.value;
    let hasError = false,
      isNameValid = true,
      isNameEmpty = false;

    if (keyName === 'firstName' || keyName === 'lastName') {
      if (!val.length) {
        isNameEmpty = true;
        hasError = true;
      } else if (!nameIsValid(val)) {
        isNameValid = false;
      }
    } else if (keyName === 'emailAddress') {
      if (!val.length || !emailIsValid(val)) {
        hasError = true;
      }
    }

    if (isNameValid || isNameEmpty) {
      setState({
        [keyName]: val,
        errorFields: { ...errorFields, [keyName]: hasError },
      });
    }
  };

  const handleBlur = (e) => {
    setState({
      touchedFields: { ...touchedFields, [e.target.id]: true },
    });
  };

  const disableSubmit = isBusy || !association || Object.keys(errorFields).some((el) => errorFields[el] === true);

  return (
    <div>
      {mode === 'new' && (
        <button className="btn btn-outline-primary" onClick={openInvite}>
          {buttonTitle}
        </button>
      )}
      {mode === 'edit' && (
        <button className="btn btn-success" title="Modify user" onClick={openInvite}>
          <i className="fas fa-pencil-alt fa-fw"></i>
        </button>
      )}
      <Modal isOpen={modalIsOpen} contentLabel="Example Modal" className="card card-login mx-auto mt-5">
        <div className="col-auto" style={{ paddingTop: '20px' }}>
          <button type="button" className="close" style={{ width: '20px' }} onClick={handleModalClose}>
            <span aria-hidden="true" style={{ color: 'black' }}>
              &times;
            </span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="m-3">
            <div className="form-group">
              <div className="form-label-group">
                <input
                  type="text"
                  id="firstName"
                  className={'form-control' + (errorFields.firstName && touchedFields.firstName ? ' is-invalid' : '')}
                  placeholder="First Name"
                  required="required"
                  maxLength={64}
                  onChange={(e) => handleInputBox(e, 'firstName')}
                  onBlur={handleBlur}
                  value={firstName}
                ></input>
                <label htmlFor="firstName">First Name *</label>
                {!!(errorFields.firstName && touchedFields.firstName) && (
                  <span className="m-error"> Please enter a valid name </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <input
                  type="text"
                  id="lastName"
                  className={'form-control' + (errorFields.lastName && touchedFields.lastName ? ' is-invalid' : '')}
                  placeholder="Last Name"
                  required="required"
                  maxLength={64}
                  onChange={(e) => handleInputBox(e, 'lastName')}
                  onBlur={handleBlur}
                  value={lastName}
                ></input>
                <label htmlFor="lastName">Last Name *</label>
                {!!(errorFields.lastName && touchedFields.lastName) && (
                  <span className="m-error"> Please enter a valid name </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <input
                  type="text"
                  id="emailAddress"
                  className={
                    'form-control' + (errorFields.emailAddress && touchedFields.emailAddress ? ' is-invalid' : '')
                  }
                  placeholder="Email Address"
                  required="required"
                  maxLength={256}
                  onChange={(e) => handleInputBox(e, 'emailAddress')}
                  onBlur={handleBlur}
                  value={emailAddress}
                ></input>
                <label htmlFor="emailAddress">Email Address *</label>
                {!!(errorFields.emailAddress && touchedFields.emailAddress) && (
                  <span className="m-error"> Please enter a valid email address </span>
                )}
              </div>
            </div>
            {associations && associations.map ? (
              <div>
                <label className="mr-sm-2" htmlFor="inlineFormCustomSelect">
                  {context.toLowerCase() === 'role' ? 'Role' : 'Association'}
                </label>
                <select
                  className="custom-select mr-sm-2"
                  id="inlineFormCustomSelect"
                  value={association}
                  required
                  onChange={(e) => handleInputBox(e, 'association')}
                >
                  <option value="" defaultValue>
                    Choose...
                  </option>
                  {associations.map((row, i) => (
                    <option key={i} value={row.value}>
                      {row.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ''
            )}
            <div style={{ paddingTop: '10px' }}>
              <button type="submit" className="btn btn-primary btn-block" value="Login" disabled={disableSubmit}>
                {mode === 'new' ? 'Invite' : 'Confirm'}
                <Spinner style={{ marginLeft: '5px' }} isVisible={isBusy} />
              </button>
            </div>
            {isInvited && !error && mode === 'new' && <div>User was Successfully Invited</div>}
            {isInvited && !error && mode === 'edit' && <div>User was Successfully Modified</div>}
            {error && <div>{error}</div>}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default InviteButton;
