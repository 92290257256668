/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useUpdateState } from '../../components';
import AsyncSelect from 'react-select/async';
import { BoxShadow, withPrincipal, Busy, Spinner, debounce } from '../../components';
import { subscriberTypeIDToName, subscriberTypeNameToID } from './helpers';
import {
  nameIsValid,
  emailIsValid,
  formatPhoneNumber,
  uuidIsValid,
  toastError,
  toastSuccess,
  toastInfo,
  sortDate,
  sortCaseInsensitive,
  sortNumber,
} from '../../lib/utils';
import ModalConfirmation from '../../components/modalConfirmation';
import BootstrapTable from 'react-bootstrap-table-next';
import { SortCarets } from '../../components/assessmentList';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SubscriberTransactions from '../../components/transaction/subscriberTransactions';
import SubscriberFeatures from '../../components/subscriber/subscriberFeatures';
import ReactTooltip from 'react-tooltip';

import config from '../../config';
import './subscriberDetails.css';

const ClaimedDDRRids = (props) => {
  const {
    isBusy,
    data,
    page = 1,
    sizePerPage = 10,
    sort = [{ dataField: 'created', order: 'desc' }],
    handleRemoveDDRRid = () => null,
    canRemoveDDRRids,
  } = props;

  const columnFormatter = (cell) => {
    return <span title={cell}>{cell}</span>;
  };

  const vendorIdColumnFormatter = (cell) => {
    return (
      <a href={`#/catalog/vendor/${cell}/Overview`} title={cell}>
        {cell}
      </a>
    );
  };

  const columns = [
    {
      dataField: 'ddrrID',
      text: 'ddrr id',
      sort: true,
      sortFunc: sortNumber,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '6%' },
      classes: 'subscriber-details-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'id',
      text: 'vendor id',
      sort: true,
      sortFunc: sortCaseInsensitive,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '16%' },
      classes: 'subscriber-details-table-columns',
      formatter: vendorIdColumnFormatter,
    },
    {
      dataField: 'name',
      text: 'vendor name',
      sort: true,
      sortFunc: sortCaseInsensitive,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '20%' },
      classes: 'subscriber-details-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'domain',
      text: 'vendor domain',
      sort: true,
      sortFunc: sortCaseInsensitive,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '17%' },
      classes: 'subscriber-details-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'creator',
      text: 'creator',
      sort: true,
      sortFunc: sortCaseInsensitive,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '28%' },
      classes: 'subscriber-details-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'created',
      text: 'created',
      sort: true,
      sortFunc: sortDate,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '8%' },
      classes: 'subscriber-details-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      editable: false,
      headerStyle: { width: '5%' },
      classes: 'subscriber-details-table-columns',
      formatter: (cell, row) => {
        return canRemoveDDRRids ? (
          <button
            className="btn btn-danger ml-1"
            title="Remove ddrr id"
            onClick={(e) => handleRemoveDDRRid(e, row.ddrrID, 'remove')}
          >
            <i className="fas fa-times fa-fw"></i>
          </button>
        ) : (
          ''
        );
      },
    },
  ];

  return (
    <div className="subscriber-details-table mb-5 mt-2">
      <BootstrapTable
        noDataIndication={() => (isBusy ? <Busy isBusy={true} /> : <div className="text-center">no data</div>)}
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={sort}
        pagination={paginationFactory({ page, sizePerPage, hideSizePerPage: true })}
        hover={true}
        striped={true}
      />
    </div>
  );
};

const FormLabel = (props) => (
  <label {...props} className={`col-md-2 text-right`}>
    {props.children}
  </label>
);
const FormInput = (props) => <input {...props} maxLength="64" className={`form-control ${props.className}`} />;

const SubscriberDetails = (props) => {
  //Props
  const { principal, history, match } = props;

  //State
  const [state, setState] = useUpdateState({
    isBusy: true,
    isSaving: false,
    id: '',
    name: '',
    logoURL: '',
    website: '',
    emailAddress: '',
    phone: '',
    streetAddress: '',
    city: '',
    _state: '',
    country: '',
    ddrrID: '',
    subscriberTypesList: [],
    subscriberSubscriberTypeName: '',
    subscriberSubscriberTypeID: '',
    initialName: '',
    associatedDDRRids: [],
    needsDataReload: false,
    ddrrAction: '',
    modalShow: false,
    errorFields: {
      name: false,
      phone: false,
      emailAddress: false,
    },
    touchedFields: {
      name: false,
      phone: false,
      emailAddress: false,
    },
    isTableBusy: false,
    selectedVendor: undefined,
    claimVendorPageOptions: [],
    canClaimDDRRid: true,
  });

  const {
    isBusy,
    isSaving,
    id,
    name,
    logoURL,
    website,
    emailAddress,
    phone,
    streetAddress,
    city,
    _state,
    country,
    ddrrID,
    subscriberTypesList,
    subscriberSubscriberTypeName,
    subscriberSubscriberTypeID,
    initialName,
    associatedDDRRids,
    needsDataReload,
    ddrrAction,
    modalShow,
    errorFields,
    touchedFields,
    isTableBusy,
    selectedVendor,
    claimVendorPageOptions,
    canClaimDDRRid,
  } = state;

  const disableSaving = Object.keys(errorFields).some((el) => errorFields[el] === true);

  const canEditSubscribers = principal.roles.some((r) => r.permissions.some((p) => p === 'subscription.save'));
  const canViewTransactions = principal.roles.some((r) => r.permissions.some((p) => p === 'transactions.list'));

  const selectVendorStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? '#18bc9c' : state.isFocused ? '#8ef0dd' : '#ffffff',
    }),
    control: (styles, state) => ({
      ...styles,
      borderColor: state.isFocused ? '#597ea2' : '#ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(89 126 162 / 50%)' : 'none',
      height: '42px',
    }),
    menu: (provided) => ({ ...provided, zIndex: 1000 }),
  };

  //Effects
  useEffect(() => {
    const id = match.params.id;

    if (id && uuidIsValid(id)) {
      getData(id);
      if (canEditSubscribers) {
        getClaimedDDRRids(id);
        getClaimVendorPageOptions();
      }
    } else {
      getSubscriberTypesList();
    }
  }, [match.params.id]);

  useEffect(() => {
    if (needsDataReload) {
      getClaimedDDRRids(id);
    }
  }, [needsDataReload]);

  //Helper Functions
  const getData = async (id) => {
    const subscriberTypesList = await getSubscriberTypesList();
    setState({ isBusy: true });

    fetch(config.api.urlFor('subscriberById', { id }))
      .then((response) => response.json())
      .then((subscriber) => {
        setState({
          id: subscriber.id,
          name: subscriber.name,
          emailAddress: subscriber.email_address,
          website: subscriber.website,
          logoURL: subscriber.logo_url,
          phone: subscriber.phone,
          streetAddress: subscriber.street_address,
          city: subscriber.city,
          _state: subscriber.state,
          country: subscriber.country,
          initialName: subscriber.name,
          subscriberSubscriberTypeName: subscriberTypeIDToName(subscriber.subscriber_type_id, subscriberTypesList),
          subscriberSubscriberTypeID: subscriber.subscriber_type_id,
          isBusy: false,
        });
      })
      .catch(() => setState({ isBusy: false }));
  };

  const getSubscriberTypesList = async () => {
    const response = await fetch(config.api.urlFor('subscriberTypesList'));
    const subscriberTypesList = await response.json();

    setState({ subscriberTypesList: subscriberTypesList || [], isBusy: false });

    return subscriberTypesList;
  };

  const getClaimedDDRRids = (id) => {
    setState({ needsDataReload: false });

    if (canEditSubscribers && id && uuidIsValid(id)) {
      setState({ isTableBusy: true });

      fetch(config.api.urlFor('claimedDDRRidsBySubscriberID', { id }))
        .then((response) => response.json())
        .then((ddrrs) => {
          ddrrs.forEach((entry) => {
            entry.creator = entry.claimedBy || 'Created manually';
            entry.created = entry.claimed ? new Date(entry.claimed).toDateString() : '-';
          });
          setState({ associatedDDRRids: ddrrs, isTableBusy: false, canClaimDDRRid: !ddrrs?.[0] });
        })
        .catch(() => setState({ isTableBusy: false }));
    }
  };

  const getClaimVendorPageOptions = async () => {
    const response = await fetch(config.api.urlFor('claimVendorPageOptions'));
    const claimVendorPageOptions = await response.json();

    setState({
      claimVendorPageOptions,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setState({ isSaving: true, isBusy: true });

    const subscriber = {
      id,
      name,
      logoURL: logoURL && logoURL.trim(),
      website: website && website.trim(),
      emailAddress: emailAddress,
      phone,
      streetAddress: streetAddress && streetAddress.trim(),
      city: city && city.trim(),
      state: _state && _state.trim(),
      country: country && country.trim(),
      userID: principal.id,
      subscriberSubscriberTypeName,
      subscriberSubscriberTypeID,
      updatingName: name !== initialName ? true : false,
    };

    const toastOptions = { toastId: 'save-subscriber-result', autoClose: 5000 };

    toastInfo(
      <>
        Saving...
        <Spinner className="ml-1" isVisible={true} />
      </>,
      toastOptions,
    );

    fetch(config.api.urlFor('saveSubscriber', { subscriberID: id }), {
      method: id ? 'PUT' : 'POST',
      body: subscriber,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.subscriberID) {
          toastSuccess(`Saved subscriber with ID: ${result.subscriberID}.`, toastOptions);

          subscriber.id !== result.subscriberID && history.replace(match.path.replace(':id', result.subscriberID));

          setState({
            isSaving: false,
            isBusy: false,
            modalShow: false,
          });
        } else {
          toastError(result.errorMessage, toastOptions);

          setState({
            isSaving: false,
            isBusy: false,
            name: !!id ? initialName : name,
            modalShow: false,
          });
        }
      })
      .catch(() => {
        toastError('An error occurred attempting to save subscriber.', toastOptions);

        setState({
          isSaving: false,
          isBusy: false,
          name: !!id ? initialName : name,
          modalShow: false,
        });
      });
  };

  const handleInputBox = (e, keyName) => {
    let val = e.target.value;
    let hasError = false,
      isNameValid = true,
      isNameEmpty = false;

    if (keyName === 'name') {
      const allowNumbers = true;

      if (!val.length) {
        isNameEmpty = true;
        hasError = true;
      } else if (!nameIsValid(val, allowNumbers)) {
        isNameValid = false;
      }
    } else if (keyName === 'phone') {
      val = formatPhoneNumber(val);
      e.target.value = val;

      if (val.length > 1 && val.length !== 14) {
        hasError = true;
      }
    } else if (keyName === 'emailAddress') {
      if (val.length && !emailIsValid(val)) {
        hasError = true;
      }
    }

    if (isNameValid || isNameEmpty) {
      if (keyName === 'subscriberType') {
        handleSubCategoryChange(e);
      } else {
        setState({
          [keyName]: val,
          errorFields: { ...errorFields, [keyName]: hasError },
        });
      }
    }
  };

  const handleSubCategoryChange = (e) => {
    setState({
      subscriberSubscriberTypeName: e.target[e.target.selectedIndex].innerText,
      subscriberSubscriberTypeID: subscriberTypeNameToID(
        e.target[e.target.selectedIndex].innerText,
        subscriberTypesList,
      ),
    });
  };

  const handleBlur = (e) => {
    setState({
      touchedFields: { ...touchedFields, [e.target.id]: true },
    });
  };

  const handleClaimRemoveDDRRid = (e, ddrrID, ddrrAction) => {
    e.preventDefault();
    setState({ ddrrID, ddrrAction, modalShow: true });
  };

  const handleClaimDDRRid = () => {
    if (canClaimDDRRid) {
      const toastOptions = { toastId: 'claim-ddrr-id', autoClose: 5000 };

      setState({ isSaving: true, isBusy: true, associatedDDRRids: [] });

      fetch(config.api.urlFor('subscriberClaimDDRRid', { id }), {
        method: 'POST',
        body: {
          ddrrID,
          userID: principal.id,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result && result.isSuccess) {
            toastSuccess(`Successfully claimed ddrr id: ${ddrrID}.`, toastOptions);

            claimVendorPageOptions.map((option) => {
              if (option.ddrrID === ddrrID) {
                option.disabled = true;
                option.isClaimed = true;
                option.claimant = name;
              }
            });

            setState({ claimVendorPageOptions, selectedVendor: undefined });
          } else {
            toastError(result.errorMessage, toastOptions);
          }

          setState({
            ddrrID: '',
            isBusy: false,
            isSaving: false,
            needsDataReload: true,
            modalShow: false,
          });
        })
        .catch(() => {
          toastError('An error occurred attempting to claim ddrr id.', toastOptions);

          setState({
            isBusy: false,
            isSaving: false,
            isTableBusy: false,
            modalShow: false,
          });
        });
    }
  };

  const handleRemoveDDRRid = () => {
    const toastOptions = { toastId: 'remove-ddrr-id', autoClose: 5000 };

    setState({ isSaving: true, isBusy: true });

    fetch(config.api.urlFor('subscriberRemoveDDRRid', { id, ddrrID }), { method: 'DELETE' })
      .then((response) => response.json())
      .then((result) => {
        if (result && result.isSuccess) {
          toastSuccess(`Successfully removed ddrr id: ${ddrrID}.`, toastOptions);

          const updatedAssociatedDDRRids = associatedDDRRids.filter((elem) => elem.ddrrID !== ddrrID);

          claimVendorPageOptions.map((option) => {
            if (option.ddrrID === ddrrID) {
              option.disabled = false;
              option.isClaimed = false;
            }
          });

          setState({ associatedDDRRids: updatedAssociatedDDRRids, claimVendorPageOptions, needsDataReload: true });
        } else {
          toastError(result.errorMessage, toastOptions);
        }
      })
      .catch(() => {
        toastError('An error occurred attempting to remove ddrr id.', toastOptions);
      })
      .then(() =>
        setState({
          isBusy: false,
          ddrrID: '',
          isSaving: false,
          modalShow: false,
        }),
      );
  };

  const handleVendorChange = (selectedOption) => {
    if (selectedOption && selectedOption.ddrrID) {
      setState({ selectedVendor: selectedOption, ddrrID: selectedOption.ddrrID });
    } else {
      setState({ selectedVendor: undefined, ddrrID: '' });
    }

    return selectedOption;
  };

  const optionsLabelsFormatted = (option) => {
    return (
      option && (
        <div className="row ml-1">
          <div className="text-left" style={{ width: '40%' }}>
            Name: <b>{option.name || ''}</b>
          </div>
          <div className="text-left" style={{ width: '30%' }}>
            Domain: <b>{option.domain || ''}</b>
          </div>
          <div className="text-left" style={{ width: '10%' }}>
            DDRR ID: <b>{option.ddrrID}</b>
          </div>
          <div className="text-left" style={{ width: '20%' }}>
            {option.isClaimed ? (
              <>
                (Claimed by <b>{option.claimant}</b>)
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      )
    );
  };

  const handleModalClose = () => {
    setState({ modalShow: false, isBusy: false, ddrrID: selectedVendor ? selectedVendor.ddrrID : '' });
  };

  const loadUnclaimedVendorOptions = debounce(async (search, callback) => {
    const response = await fetch(config.api.urlFor('claimVendorPageOptions', { search }));
    const vendors = await response.json();

    callback && callback(vendors);
  }, 500);

  //Render
  return (
    <React.Fragment>
      <div>
        <ul className="nav nav-tabs subscriber-details-button-ul">
          <li className="nav-item">
            <button className="btn btn-link nav-link subscriber-details-button">Details</button>
          </li>
        </ul>
        <Busy isBusy={isBusy} />
        <div className={'subscriber-details-body' + (isBusy ? ' hide-component' : '')}>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="row px-3">
                <div className="col-md-8 col-sm-9">
                  <div className="form-group">
                    <FormLabel id="id_label" htmlFor="id">
                      ID:
                    </FormLabel>
                    <strong>{id}</strong>
                  </div>
                </div>
                <div className="col-md-4 col-sm-3 text-right pr-0 mobile-save-btn">
                  <button
                    type="submit"
                    className="btn btn-outline-primary"
                    disabled={isSaving === true || subscriberSubscriberTypeID == null || disableSaving}
                  >
                    <i className="fa fa-save fa-lg fa-fw mr-1"></i>
                    {id ? 'Save' : 'Create'}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <BoxShadow title="Profile" className="mb-3">
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="name">name:*</FormLabel>
                      <FormInput
                        id="name"
                        type="text"
                        className={'col-md-5' + (errorFields.name && touchedFields.name ? ' is-invalid' : '')}
                        onChange={(e) => handleInputBox(e, 'name')}
                        onBlur={handleBlur}
                        value={name}
                        required
                        disabled={!canEditSubscribers}
                      />
                      {!!(errorFields.name && touchedFields.name) && (
                        <span className="m-error"> Please enter a valid name </span>
                      )}
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="logo">logo:</FormLabel>
                      <FormInput
                        id="logo"
                        type="text"
                        className="col-md-5"
                        onChange={(e) => handleInputBox(e, 'logoURL')}
                        value={logoURL}
                        disabled={!canEditSubscribers}
                      />
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="emailAddress">email:</FormLabel>
                      <FormInput
                        id="emailAddress"
                        type="text"
                        className={
                          'col-md-5' + (errorFields.emailAddress && touchedFields.emailAddress ? ' is-invalid' : '')
                        }
                        onChange={(e) => handleInputBox(e, 'emailAddress')}
                        onBlur={handleBlur}
                        value={emailAddress}
                        disabled={!canEditSubscribers}
                      />
                      {!!(errorFields.emailAddress && touchedFields.emailAddress) && (
                        <span className="m-error"> Please enter a valid email address </span>
                      )}
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="website">website:</FormLabel>
                      <FormInput
                        id="website"
                        type="text"
                        className="col-md-5"
                        onChange={(e) => handleInputBox(e, 'website')}
                        value={website}
                        disabled={!canEditSubscribers}
                      />
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="phone">phone:</FormLabel>
                      <FormInput
                        id="phone"
                        type="text"
                        className={'col-md-5' + (errorFields.phone && touchedFields.phone ? ' is-invalid' : '')}
                        onChange={(e) => handleInputBox(e, 'phone')}
                        onBlur={handleBlur}
                        value={phone}
                        disabled={!canEditSubscribers}
                      />
                      {!!(errorFields.phone && touchedFields.phone) && (
                        <span className="m-error"> Please enter a valid phone number </span>
                      )}
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="streetAddress">street address:</FormLabel>
                      <FormInput
                        id="streetAddress"
                        type="text"
                        className="col-md-5"
                        onChange={(e) => handleInputBox(e, 'streetAddress')}
                        value={streetAddress}
                        disabled={!canEditSubscribers}
                      />
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="city">city:</FormLabel>
                      <FormInput
                        id="city"
                        type="text"
                        className="col-md-5"
                        onChange={(e) => handleInputBox(e, 'city')}
                        value={city}
                        disabled={!canEditSubscribers}
                      />
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="state">state:</FormLabel>
                      <FormInput
                        id="state"
                        type="text"
                        className="col-md-5"
                        onChange={(e) => handleInputBox(e, '_state')}
                        value={_state}
                        disabled={!canEditSubscribers}
                      />
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="country">country:</FormLabel>
                      <FormInput
                        id="country"
                        type="text"
                        className="col-md-5"
                        onChange={(e) => handleInputBox(e, 'country')}
                        value={country}
                        disabled={!canEditSubscribers}
                      />
                    </div>
                    <div className="form-group row mobile-padding">
                      <FormLabel htmlFor="country">subscriber category:*</FormLabel>
                      <select
                        required
                        disabled={!canEditSubscribers}
                        className="form-control col-md-5"
                        // value={subscriberSubscriberTypeName}
                        onChange={handleSubCategoryChange}
                      >
                        <option value={''}>-</option>
                        {subscriberTypesList.map((option, i) => (
                          <option key={i} value={option.id} selected={option.name === subscriberSubscriberTypeName}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </BoxShadow>
                  <BoxShadow title="Transactions" className="mb-2">
                    {!!id ? (
                      canViewTransactions ? (
                        <SubscriberTransactions
                          subscriberID={id}
                          subscriberName={initialName}
                          isExternalSaving={isSaving}
                        />
                      ) : (
                        <p className="text-center">You are not authorized to view transactions.</p>
                      )
                    ) : (
                      <p className="text-center">Please create subscriber before managing transactions and tokens.</p>
                    )}
                  </BoxShadow>
                  <BoxShadow title="Features" className="mb-2">
                    {!!id ? (
                      <SubscriberFeatures subscriberID={id} />
                    ) : (
                      <p className="text-center">Please create subscriber before managing features.</p>
                    )}
                  </BoxShadow>
                  <BoxShadow title="Claim Vendor Page" className="mb-2">
                    {!!id ? (
                      <>
                        {!!canEditSubscribers && (
                          <div className="row mobile-padding">
                            <FormLabel htmlFor="vendorsList">Vendors:</FormLabel>
                            <AsyncSelect
                              id="vendorsList"
                              className="from-control col-md-8 pl-0 pr-0"
                              styles={selectVendorStyles}
                              defaultOptions={claimVendorPageOptions}
                              value={selectedVendor}
                              loadOptions={loadUnclaimedVendorOptions}
                              onChange={handleVendorChange}
                              noOptionsMessage={() => 'No options'}
                              isOptionDisabled={(option) => option.isClaimed}
                              getOptionLabel={(option) => optionsLabelsFormatted(option)}
                              getOptionValue={(option) => option.ddrrID + ' ' + option.name + ' ' + option.domain}
                              isClearable={true}
                            />
                            <div data-tip data-for="onHoverClaim">
                              <button
                                type="button"
                                className="btn btn-outline-primary subscriber-details-claim-reset-btns h-100"
                                onClick={(e) => handleClaimRemoveDDRRid(e, ddrrID, 'claim')}
                                disabled={!canClaimDDRRid || !ddrrID}
                              >
                                Claim
                              </button>
                              {!canClaimDDRRid && (
                                <ReactTooltip id="onHoverClaim" place="top" effect="solid">
                                  <p className="mb-0">Subscriber can claim only one DDRR ID.</p>
                                </ReactTooltip>
                              )}
                            </div>
                          </div>
                        )}
                        <div className={'row mobile-padding' + (canEditSubscribers ? ' mt-4' : '')}>
                          <label className="col-md-12 text-left">
                            DDRR IDs Claimed by <b>{initialName}</b>:
                          </label>
                          <ClaimedDDRRids
                            isBusy={isTableBusy}
                            data={associatedDDRRids}
                            handleRemoveDDRRid={handleClaimRemoveDDRRid}
                            canRemoveDDRRids={canEditSubscribers}
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-center">Please create subscriber before claiming vendor pages.</p>
                    )}
                  </BoxShadow>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ModalConfirmation
        isBusy={isBusy}
        isOpen={modalShow}
        confirmationText={`Are you sure you want to ${ddrrAction} vendor with this ddrr id: ${ddrrID}?`}
        onClickYes={ddrrAction === 'claim' ? handleClaimDDRRid : handleRemoveDDRRid}
        onClickNo={handleModalClose}
      />
    </React.Fragment>
  );
};

export default withPrincipal(SubscriberDetails);
