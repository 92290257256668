import React from 'react';
import { Tooltip } from 'reactstrap';

import { ChipAlert, Busy, Pagination } from '../../components/';

import config from '../../config';

class InvitationDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      name: '',
      description: '',
      isSystem: false,
      isBusy: true,
      tab: 0,
      alerts: [],
      rolePermissions: [],
      popovers: {},
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const _self = this;

    fetch(config.api.urlFor('roleDetails', { id: this.props.match.params.id }))
      .then((response) => response.json())
      .then(function (role) {
        _self.setState({
          id: role.id,
          name: role.name,
          description: role.description,
          isSystem: role.isSystem,
          isBusy: false,
        });
      })
      .catch(function () {
        _self.setState({ isBusy: false });
      });
  }

  submit() {
    const _self = this;
    const { history, match } = this.props;

    _self.setState({ isBusy: true });

    const role = {
      id: _self.state.id,
      name: _self.state.name,
      description: _self.state.description,
      isSystem: _self.state.isSystem,
    };

    fetch(config.api.urlFor('saveInvitation'), {
      method: 'POST',
      body: role,
    })
      .then((response) => response.json())
      .then(
        (result) =>
          (role.id !== result && history.replace(match.path.replace(':id', result))) &
          _self.setState({
            isBusy: false,
            isSuccess: result && result.length > 0,
            alerts: [`Saved role with ID '${result}'`],
            alertIsError: false,
          }),
      )
      .catch((err) => _self.setState({ isBusy: false, alerts: [err.message], alertIsError: true }));
  }

  getPermissions(page) {
    page = page || 1;

    this.setState({ popovers: {}, page: page });

    const _self = this;

    _self.setState({ isBusy: true });

    fetch(config.api.urlFor('rolePermissions', { id: this.props.match.params.id, page: page }))
      .then((response) => response.json())
      .then((rolePermissions) =>
        _self.setState({
          isBusy: false,
          rolePermissions: rolePermissions.rows,
          totalInvitationPermissions: rolePermissions.totalRecords,
        }),
      )
      .catch(() => _self.setState({ isBusy: false }));
  }

  grantPermission(permissionID) {
    const _self = this;
    _self.setState({ isBusy: true });

    const grant = { roleID: _self.state.id, permissionIDs: [permissionID] };

    fetch(config.api.urlFor('grantPermissions'), {
      method: 'POST',
      body: grant,
    })
      .then((response) => response.json())
      .then(() => _self.getPermissions())
      .catch(() => _self.getPermissions());
  }

  revokePermission(permissionID) {
    const _self = this;
    _self.setState({ isBusy: true });

    const revoke = { roleID: _self.state.id, permissionIDs: [permissionID] };

    fetch(config.api.urlFor('revokePermissions'), {
      method: 'POST',
      body: revoke,
    })
      .then((response) => response.json())
      .then(() => _self.getPermissions())
      .catch(() => _self.getPermissions());
  }

  togglePopover(name) {
    const { popovers } = this.state;

    popovers[name] = popovers[name] === true ? false : true;

    this.setState({ popovers: popovers });
  }

  render() {
    const { totalInvitationPermissions, page } = this.state;

    return (
      <div>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`btn btn-link nav-link ${this.state.tab === 0 ? 'active' : null}`}
              onClick={() => this.setState({ tab: 0 })}
            >
              Details
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`btn btn-link nav-link ${this.state.tab === 1 ? 'active' : null}`}
              onClick={() => this.getPermissions() & this.setState({ tab: 1 })}
            >
              Permissions
            </button>
          </li>
        </ul>
        <Busy
          isBusy={this.state.isBusy}
          content={
            <div style={{ marginTop: '25px' }}>
              <ChipAlert
                isError={this.state.alertIsError}
                alerts={this.state.alerts}
                style={{ marginTop: '15px' }}
                onDelete={() => this.setState({ alerts: [] })}
              />
              <h3>ID: {this.state.id}</h3>
              {this.state.tab === 0 && (
                <div>
                  <form onSubmit={(e) => e.preventDefault() & this.submit()}>
                    <div className="row">
                      <div className="col-12"></div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="name">name</label>
                          <input
                            id="name"
                            type="text"
                            className="form-control"
                            onChange={(e) => this.setState({ name: e.target.value })}
                            value={this.state.name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="name">description</label>
                          <input
                            id="name"
                            type="text"
                            className="form-control"
                            onChange={(e) => this.setState({ description: e.target.value })}
                            value={this.state.description}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          {this.state.isSystem ? (
                            <i className="far fa-check-square fa-lg mr-1"></i>
                          ) : (
                            <i className="far fa-square fa-lg mr-1"></i>
                          )}
                          <label className="form-check-label" htmlFor="isSystem">
                            system?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button type="submit" className="btn btn-outline-primary">
                          <i className="fa fa-save fa-lg fa-fw"></i>save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {this.state.tab === 1 && (
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <td>name</td>
                        <td>description</td>
                        <td>granted?</td>
                        <td>action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rolePermissions.map((permission, i) => (
                        <tr key={permission.id}>
                          <td>{permission.name}</td>
                          <td>{permission.description}</td>
                          <td>
                            <i className={`far fa${permission.granted ? '-check' : ''}-square fa-lg`}></i>
                          </td>
                          {permission.granted ? (
                            <td onClick={() => this.revokePermission(permission.id)}>
                              <i id={`revoke-${i}`} className="fa fa-unlink fa-lg"></i>
                              <Tooltip
                                placement="right"
                                isOpen={this.state.popovers[`revoke-${i}`]}
                                target={`revoke-${i}`}
                                toggle={() => this.togglePopover(`revoke-${i}`)}
                              >
                                REVOKE: {permission.name}
                              </Tooltip>
                            </td>
                          ) : (
                            <td onClick={() => this.grantPermission(permission.id)}>
                              <i id={`grant-${i}`} className="fa fa-link fa-lg"></i>
                              <Tooltip
                                placement="right"
                                isOpen={this.state.popovers[`grant-${i}`]}
                                target={`grant-${i}`}
                                toggle={() => this.togglePopover(`grant-${i}`)}
                              >
                                GRANT: {permission.name}
                              </Tooltip>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    onPageChanged={(page) => this.getPermissions(page)}
                    page={page}
                    pageSize={10}
                    totalRecords={totalInvitationPermissions}
                    visible={7}
                  />
                </div>
              )}
            </div>
          }
        />
      </div>
    );
  }
}

export default InvitationDetails;
