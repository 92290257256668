import React from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import { useUpdateState, Spinner, useSignalEffect, debounce } from '../../components';
import { nameIsValid, emailIsValid } from '../../lib/utils';

import config from '../../config';

const getSusbcribers = async ({ signal, search }) => {
  const response = await fetch(config.api.urlFor('subscribers', { pageSize: 10, search }), {
    signal,
  });

  const data = await response.json();

  return data.rows;
};

const InviteButton = ({ closeButtonStyle, reloadDataInParent }) => {
  const [state, setState] = useUpdateState({
    modalIsOpen: false,
    firstName: '',
    lastName: '',
    emailAddress: '',
    association: '',
    associationName: '',
    error: '',
    isInvited: false,
    isBusy: false,
    errorFields: {
      firstName: false,
      lastName: false,
      emailAddress: false,
    },
    touchedFields: {
      firstName: false,
      lastName: false,
      emailAddress: false,
    },
  });

  const {
    modalIsOpen,
    firstName,
    lastName,
    emailAddress,
    association,
    associationName,
    isBusy,
    isInvited,
    error,
    errorFields,
    touchedFields,
    subscriptions,
  } = state;

  useSignalEffect(async (signal) => {
    const subscriptions = await getSusbcribers({ signal });

    setState({
      subscriptions,
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({ isBusy: true });

    const params = new URLSearchParams();
    params.set('firstName', firstName);
    params.set('lastName', lastName);
    params.set('email', emailAddress);
    if (association) params.set('association', association);
    if (associationName) params.set('associationName', associationName);
    params.set('context', 'subscriber');

    const response = await fetch(config.api.urlFor('inviteUser'), {
      method: 'POST',
      body: params,
    });
    const json = await response.json();

    if (json.error) {
      return setState({ error: json.error, isBusy: false });
    }

    if (json.isSuccess == false) {
      return setState({ error: json.message, isBusy: false });
    }

    setState({ isInvited: true, isBusy: false, error: '' });
  };

  const handleInputBox = (e, keyName) => {
    const val = e.target.value;
    let hasError = false,
      isNameValid = true,
      isNameEmpty = false;

    if (keyName === 'firstName' || keyName === 'lastName') {
      if (!val.length) {
        isNameEmpty = true;
        hasError = true;
      } else if (!nameIsValid(val)) {
        isNameValid = false;
      }
    } else if (keyName === 'emailAddress') {
      if (!val.length || !emailIsValid(val)) {
        hasError = true;
      }
    }

    if (isNameValid || isNameEmpty) {
      setState({
        [keyName]: val,
        errorFields: { ...errorFields, [keyName]: hasError },
      });
    }
  };

  const handleBlur = (e) => {
    setState({
      touchedFields: { ...touchedFields, [e.target.id]: true },
    });
  };

  const handleModalOpen = () => {
    setState({ modalIsOpen: true });
  };

  const handleModalClose = () => {
    if (reloadDataInParent) {
      reloadDataInParent();
    }

    setState({
      modalIsOpen: false,
      association: '',
      associationName: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      isInvited: false,
      error: '',
      errorFields: {
        firstName: false,
        lastName: false,
        emailAddress: false,
      },
      touchedFields: {
        firstName: false,
        lastName: false,
        emailAddress: false,
      },
    });
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? '#18bc9c' : state.isFocused ? '#8ef0dd' : '#ffffff',
    }),
    control: (styles, state) => ({
      ...styles,
      borderColor: state.isFocused ? '#597ea2' : '#ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(89 126 162 / 50%)' : 'none',
      height: '42px',
    }),
  };

  const onSelectInputChange = (subscriber) => {
    setState({ association: subscriber.id, associationName: subscriber.name });
  };

  const onLoadOptions = debounce(async (search, callback) => {
    const subscribers = await getSusbcribers({ search });

    callback(subscribers);
  }, 300);

  const disableSubmit =
    isBusy || !association || !associationName || Object.keys(errorFields).some((el) => errorFields[el] === true);

  return (
    <React.Fragment>
      <button className="btn btn-outline-primary" onClick={handleModalOpen}>
        Invite User
      </button>
      <Modal isOpen={modalIsOpen} contentLabel="Example Modal" className="card card-login mx-auto mt-5">
        <div className="col-auto" style={{ paddingTop: '20px' }}>
          <button type="button" className="close" style={closeButtonStyle} onClick={handleModalClose}>
            <span aria-hidden="true" style={{ color: 'black' }}>
              &times;
            </span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="m-3">
            <div className="form-group">
              <div className="form-label-group">
                <input
                  type="text"
                  id="firstName"
                  className={'form-control' + (errorFields.firstName && touchedFields.firstName ? ' is-invalid' : '')}
                  placeholder="First Name"
                  required="required"
                  onChange={(e) => handleInputBox(e, 'firstName')}
                  onBlur={handleBlur}
                  value={firstName}
                  maxLength={64}
                ></input>
                <label htmlFor="firstName">First Name *</label>
                {!!(errorFields.firstName && touchedFields.firstName) && (
                  <span className="m-error"> Please enter a valid name </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <input
                  type="text"
                  id="lastName"
                  className={'form-control' + (errorFields.lastName && touchedFields.lastName ? ' is-invalid' : '')}
                  placeholder="Last Name"
                  required="required"
                  onChange={(e) => handleInputBox(e, 'lastName')}
                  onBlur={handleBlur}
                  value={lastName}
                  maxLength={64}
                ></input>
                <label htmlFor="lastName">Last Name *</label>
                {!!(errorFields.lastName && touchedFields.lastName) && (
                  <span className="m-error"> Please enter a valid name </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <input
                  type="text"
                  id="emailAddress"
                  className={
                    'form-control' + (errorFields.emailAddress && touchedFields.emailAddress ? ' is-invalid' : '')
                  }
                  placeholder="Email Address"
                  required="required"
                  onChange={(e) => handleInputBox(e, 'emailAddress')}
                  onBlur={handleBlur}
                  value={emailAddress}
                  maxLength={256}
                ></input>
                <label htmlFor="emailAddress">Email Address *</label>
                {!!(errorFields.emailAddress && touchedFields.emailAddress) && (
                  <span className="m-error"> Please enter a valid email address </span>
                )}
              </div>
            </div>
            <div>
              <label className="mr-sm-2" htmlFor="inlineFormCustomSelect">
                Subscription
              </label>
              <AsyncSelect
                id="inlineFormCustomSelect"
                styles={customStyles}
                cacheOptions
                defaultOptions={subscriptions}
                onChange={onSelectInputChange}
                loadOptions={onLoadOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </div>
            <div style={{ paddingTop: '10px' }}>
              <button type="submit" className="btn btn-primary btn-block" value="Login" disabled={disableSubmit}>
                Invite
                <Spinner style={{ marginLeft: '5px' }} isVisible={isBusy} />
              </button>
            </div>
            {isInvited && !error && <div>Success!</div>}
            {error && <div>{error}</div>}
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default InviteButton;
