/* eslint-disable react/display-name */
import React from 'react';
import ReactTable from 'react-table';
import config from './../../config.js';
import { withRouter } from 'react-router-dom';
import StatusButton from '../statusButton';
import { useUpdateState } from '../';

import './orders.css';

const disabledPurchaseText = 'You do not have the permissions to order reports. Please contact your system admin.';

const Orders = (props) => {
  //Ref
  const _isMounted = React.useRef(false);

  //Props
  const { handlePurchaseButtonClick, canPurchaseReports, history } = props;

  //State
  const [state, setState] = useUpdateState({
    pages: 1,
    orders: [],
    type: 'vendor',
    tableIsBusy: true,
  });

  const { pages, orders, type, tableIsBusy } = state;

  //Effects
  React.useEffect(() => {
    _isMounted.current = true;
    getTableData(1);

    return () => {
      _isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getTableData(1);
  }, [type]);

  //Helper Functions
  const changeTable = (type) => {
    setState({ type });
  };

  const getTableData = (page, filter, sort) => {
    page = parseInt(page || 1, 10);
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [{ id: 'subject', desc: false }];

    setState({ tableIsBusy: true });

    let url = config.api.urlFor('transactionList');

    url += `?page=${page}&pageSize=10&type=${type}&purchasesOnly=true`;

    if (filter.length) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? '1' : '0'}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (_isMounted.current) {
          if (response.isSuccess) {
            setState({
              orders: response.data.rows,
              pages: Math.ceil(response.data.totalRecords / 10),
            });
          }
          setState({ tableIsBusy: false });
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ tableIsBusy: false });
      });
  };

  const getColumns = () => {
    let columns = [];

    if (type === 'product') {
      columns.push({
        Header: 'Product',
        accessor: 'subject',
        Cell: ({ row }) => <div className="text-center">{row.subject}</div>,
        minWidth: 110,
      });
      columns.push({
        Header: 'Vendor',
        accessor: 'vendor',
        Cell: ({ row }) => <div className="text-center">{row._original.vendor}</div>,
        minWidth: 110,
      });
    } else {
      columns.push({
        Header: 'Vendor',
        accessor: 'subject',
        Cell: ({ row }) => (
          <div className="text-center">
            <a href={`#/catalog/vendor/${row._original.subjectID}/Overview`}>{row.subject}</a>
          </div>
        ),
        minWidth: 110,
      });
    }

    columns.push(
      {
        Header: 'Report Type',
        accessor: 'assessmentType',
        Cell: ({ row }) => <div className="text-center">{row.assessmentType}</div>,
        sortable: false,
      },
      {
        Header: 'Date Ordered',
        accessor: 'timestamp',
        Cell: ({ row }) => (
          <div className="text-center">{row.timestamp ? new Date(row.timestamp).toDateString() : ''}</div>
        ),
      },
      {
        Header: 'Token Count',
        accessor: 'amount',
        Cell: ({ row }) => <div className="text-center">{row.amount ? row.amount : ''}</div>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { _original: row } }) => {
          const assessmentInfo = {
            type: row.assessmentType,
            isComplete: row.hasCompletedDate,
            reportTypeID: row.assessmentTypeID,
            reportRawType: row.assessmentType,
            vendorDomain: row.subjectURL,
            vendorName: row.context === 'vendor' ? row.subject : '',
            vendorID: row.context === 'vendor' ? row.subjectID : null,
            assessmentRequestID: row.requestID,
            hasAccess: true,
            productName: row.context === 'product' ? row.subject : '',
            productID: row.context === 'product' ? row.subjectID : null,
            assessmentData: row.assessmentData,
          };
          return StatusButton({
            assessmentInfo,
            handlePurchaseButtonClick,
            canPurchaseReports,
            history,
            disabledPurchaseText,
          });
        },
        sortable: false,
        maxWidth: 160,
      },
    );

    return columns;
  };

  //Render
  return (
    <>
      <div id="my-orders-table" className="card mb-3">
        <div className="card-body align-middle">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a className={'nav-link' + (type !== 'product' ? ' active' : '')} onClick={() => changeTable('vendor')}>
                Vendor
              </a>
            </li>
            {/* Please remove in A2V-2517
             <li className="nav-item">
              <a className={'nav-link' + (type === 'product' ? ' active' : '')} onClick={() => changeTable('product')}>
                Product
              </a>
            </li>
            */}
          </ul>
          <ReactTable
            NoDataComponent={() => null}
            manual
            defaultCanFilter={false}
            multiSort={false}
            showPageSizeOptions={false} // handles amount per page
            pageSize={0}
            pages={pages}
            loading={tableIsBusy}
            defaultSorted={[{ id: 'subject', desc: false }]}
            useSortBy
            columns={getColumns()}
            data={orders}
            onFetchData={(state) => getTableData(state.page + 1, state.filtered, state.sorted)}
            className="-striped -highlight hide-arrows"
          />
        </div>
        <div className="card-footer small text-muted"></div>
      </div>
    </>
  );
};

export default withRouter(Orders);
