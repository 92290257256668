import React from 'react';

const ProductOrderResultModal = (props) => {
  const { handleModalClose } = props;

  return (
    <div className="product-order-reports-result-modal modal-report-info-box">
      <p className="m-tal-c m-bold mb-0">Your request has been received.</p>
      <p className="m-tal-c m-bold mb-0">You will receive a confirmation email of your requested report(s).</p>

      <div className="d-flex">
        <button
          onClick={() => handleModalClose()}
          className="product-order-reports-result-modal-close-btn modal-report-info-btn btn"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ProductOrderResultModal;
