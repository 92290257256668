import React from 'react';
import SectionSummaryLabel from './sectionSummaryLabel.jsx';

const SectionSummaryScoreCard = ({ categoryString = '', severity = '', findingsCounts = {}, chartID = '' }) => {
  return (
    <>
      {/* CH CATEGORY LABEL (ex: "Domain Name System" & "Moderate") */}
      <SectionSummaryLabel label={categoryString} severity={severity} />
      <br />
      {/* AM4CHARTS PIE CHART  */}
      <div id={chartID} className={`ch-chart ${chartID}`}></div>
      {/* FINDINGS BAR (ex: 4/7 Findings) */}
      <div className="ch-chart-findings-bar">
        {chartID === 'ch-vulns' ? null : (
          <p>{`${findingsCounts.findings ? findingsCounts.findings : '0'} / ${
            findingsCounts.totalPotentialFindings ? findingsCounts.totalPotentialFindings : '0'
          } Findings`}</p>
        )}
      </div>
    </>
  );
};

export default SectionSummaryScoreCard;
