import React from 'react';

const CenteredListItem = ({ value }) => (
  <div className="row">
    <div className="col-12 text-center">
      <h4>{value}</h4>
    </div>
  </div>
);

export default CenteredListItem;
