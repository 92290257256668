import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import config from './../../config.js';

import ModalStagesContainer from '../vendor/catalog/modalStagesContainer';
import DashboardTileGrouping from './dashboardTileGrouping';
import MyVendorsTable from '../../components/vendor/myVendorsTable';
import AssessmentRequest from './assessmentRequest';
import SpendBreakdown from './spendBreakdown';
import Orders from './orders';
import Busy from '../../components/busy';
import { withPrincipal } from '../../components';
import { round } from 'lodash';

import './newDashboard.css';
import MyFindingsTable from '../vendor/myFindingsTable';

class NewDashboard extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      isBusy: true,
      numVendors: '',
      numFindings: '',
      canViewOwnTransactions: false,
      canPurchaseReports: false,
      canBulkUploadVendors: false,
      potentialPurchaseInfo: {
        reportType: '',
        id: '',
        name: '',
        domain: '',
      },
      showModal: false,
      rerenderTable: false,
    };
    this.handleNumVendorsCallback = this.handleNumVendorsCallback.bind(this);
    this.handleNumFindingsCallback = this.handleNumFindingsCallback.bind(this);
    this.handlePurchaseButtonClick = this.handlePurchaseButtonClick.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const { principal } = this.props;
    const canViewOwnTransactions = principal.roles.some((r) =>
      r.permissions.some((p) => p === 'subscriber.viewOwnTransactions'),
    );

    if (canViewOwnTransactions) {
      this.getData();
    } else {
      this.setState({ isBusy: false });
    }

    this.setState({
      canViewOwnTransactions: canViewOwnTransactions,
      canPurchaseReports: principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.purchaseReports')),
      canBulkUploadVendors: principal.roles.some((r) =>
        r.permissions.some((p) => p === 'subscriber.admin.import.vendors'),
      ),
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getData() {
    const balanceURL = config.api.urlFor('tokenBalance');
    const transactionListURL = config.api.urlFor('transactionList');

    const balanceFetch = fetch(balanceURL).then((res) => res.json());
    const spendBreakdownFetch = fetch(config.api.urlFor('transactionBreakdown')).then((res) => res.json());
    const csvPrepFetch = fetch(transactionListURL + '?pageSize=0&type=both&purchasesOnly=true').then((res) =>
      res.json(),
    );

    Promise.all([balanceFetch, spendBreakdownFetch, csvPrepFetch])
      .then((response) => {
        if (this._isMounted) {
          const balanceData = response[0] && response[0].isSuccess ? response[0].data : {};
          const csvData = response[2] && response[2].isSuccess ? response[2].data : {};

          this.setState({
            // for balance info:
            balance: balanceData.balance !== undefined ? round(balanceData.balance, 2) : 0,
            paid: balanceData.paid ? balanceData.paid : 0,
            received: balanceData.received ? balanceData.received : 0,
            subscriber: balanceData.subscriber ? balanceData.subscriber : 0,

            // for spend breakdown:
            spendBreakdown: response[1].isSuccess ? response[1].data : {},

            // for csv functionality:
            csvPrep: csvData.rows,

            isBusy: false,
          });
        }
      })
      .catch((e) => {
        throw e;
      });
  }

  handlePurchaseButtonClick(reportType, id, name, domain, ddrrID) {
    this.setState({
      potentialPurchaseInfo: {
        reportType,
        id,
        name,
        domain,
        ddrrID,
      },
      showModal: true,
      selectedVendorReports: { [`${reportType}`]: 1 },
    });
  }

  handleNumVendorsCallback(childData) {
    const numVendors = childData ? childData : '-';
    this.setState({ numVendors: numVendors });
  }

  handleNumFindingsCallback(childData) {
    const numFindings = childData ? childData : '-';
    this.setState({ numFindings: numFindings });
  }

  render() {
    const {
      isBusy,
      numVendors,
      numFindings,
      balance,
      spendBreakdown,
      csvPrep,
      canViewOwnTransactions,
      canPurchaseReports,
      canBulkUploadVendors,
      selectedVendorReports,
      potentialPurchaseInfo,
    } = this.state;
    const csvHeaders = [
      { label: 'Vendor/Product', key: 'subject' },
      { label: 'Report Type', key: 'assessmentType' },
      { label: 'Date Ordered', key: 'timestamp' },
      { label: 'Token Count', key: 'amount' },
    ];

    const csvReport = {
      filename: `Orders_${new Date().toLocaleDateString()}.csv`,
      headers: csvHeaders,
      data: csvPrep || [],
    };

    const tileValues = [numVendors, numFindings, balance];
    const tileLabels = ['My Vendors', 'Monitoring Findings', 'Tokens'];

    const hasSelectedVendorReports = selectedVendorReports && Object.keys(selectedVendorReports).length > 0;

    return (
      <>
        {canPurchaseReports && (
          <ModalStagesContainer
            mode={hasSelectedVendorReports ? 'purchaseOnly' : 'vendorOverview'}
            modalData={potentialPurchaseInfo}
            showModal={this.state.showModal}
            predefinedVendorReports={hasSelectedVendorReports ? selectedVendorReports : undefined}
            vendorID={this.state.potentialPurchaseInfo.id}
            vendorName={this.state.potentialPurchaseInfo.name}
            vendorDomain={this.state.potentialPurchaseInfo.domain}
            onRequestClose={() =>
              this.setState({
                showModal: false,
                rerenderTable: !this.state.rerenderTable,
              })
            }
          />
        )}
        <div id="db-container">
          <Busy isBusy={isBusy}>
            <DashboardTileGrouping values={tileValues} labels={tileLabels} displayTokens={canViewOwnTransactions} />
            <div className="my-vendors-header">
              <h3>My Vendors</h3>
              {canBulkUploadVendors ? (
                <button
                  id="vendor-import-btn"
                  className="btn font-weight-bold"
                  onClick={() => {
                    this.props.history.push('/subscriber/imports');
                  }}
                >
                  Bulk Upload Vendors
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className="db-vendors-table-without-map">
              <MyVendorsTable
                onDashboard={true}
                parentCallback={this.handleNumVendorsCallback}
                handleButtonClick={this.handlePurchaseButtonClick}
                rerenderTable={this.state.rerenderTable}
              />
            </div>
            <hr />
            <h3>My Findings</h3>
            <div className="db-vendors-table-without-map">
              <MyFindingsTable numFindings={this.handleNumFindingsCallback} />
            </div>
            <br />
            {config.requestBanner.assessmentRequest && <AssessmentRequest />}
            {canViewOwnTransactions && (
              <div>
                <hr />
                <h3>My Tokens &amp; Orders</h3>
                <div className="db-tokens-orders-flex-container-large">
                  <div className="db-spend-breakdown">
                    <SpendBreakdown balance={balance} sbTokenData={spendBreakdown} />
                  </div>
                  <div className="db-orders-table">
                    <Orders canPurchaseReports={canPurchaseReports} />
                    <div className="db-csv-wrapper">
                      <CSVLink {...csvReport} id="csv-orders-link">
                        Export to CSV
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Busy>
        </div>
      </>
    );
  }
}

export default withRouter(withPrincipal(NewDashboard));
