/* --------------------------------- */
/*            Actions                */
/* --------------------------------- */
const ACTION_TYPE = {
  SET_DATA: 'SET_DATA',
  SET_ACCESS_REQUESTS: 'SET_ACCESS_REQUESTS',
  PEND_REQ_MODAL: 'PEND_REQ_MODAL',
  REQUEST_ACCESS_MODAL: 'REQUEST_ACCESS_MODAL',
  HISTORY_LOG_MODAL: 'HISTORY_LOG_MODAL',
  ADDMODIFY_MODAL: 'ADDMODIFY_MODAL',
  ADD_UPDATE_FILE: 'ADD_UPDATE_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  ADJUST_PEND_COUNT: 'ADJUST_PEND_COUNT',
  INIT_CATEGORIES_AND_CLASSES: 'INIT_CATEGORIES_AND_CLASSES',
  SHARE_FILE_MODAL: 'SHARE_FILE_MODAL',
  UPLOAD_REQUEST_MODAL: 'UPLOAD_REQUEST_MODAL',
  SET_SCROLL_TO_SECTION_REF: 'SET_SCROLL_TO_SECTION_REF',
};

/* --------------------------------- */
/*         Action Handlers           */
/* --------------------------------- */

export const setData = (fileItemsByCategories = {}) => ({
  type: ACTION_TYPE.SET_DATA,
  payload: { fileItemsByCategories },
});

export const setAccessRequests = (fileRequests = []) => ({
  type: ACTION_TYPE.SET_ACCESS_REQUESTS,
  payload: { fileRequests },
});

export const togglePendingRequestsModal = (fileCategoryID, id) => ({
  type: ACTION_TYPE.PEND_REQ_MODAL,
  payload: { fileCategoryID, id },
});

export const toggleRequestAccessModal = (fileCategoryID, id) => ({
  type: ACTION_TYPE.REQUEST_ACCESS_MODAL,
  payload: { fileCategoryID, id },
});

export const toggleHistoryLogModal = (id) => ({
  type: ACTION_TYPE.HISTORY_LOG_MODAL,
  payload: { id },
});

export const toggleAddModifyModal = (fileCategoryID, id) => ({
  type: ACTION_TYPE.ADDMODIFY_MODAL,
  payload: { fileCategoryID, id },
});

export const toggleUploadRequestModal = (fileCategoryID, id) => ({
  type: ACTION_TYPE.UPLOAD_REQUEST_MODAL,
  payload: { fileCategoryID, id },
});

export const addUpdateFile = (newFile, fileClassData) => ({
  type: ACTION_TYPE.ADD_UPDATE_FILE,
  payload: { newFile, fileClassData },
});

export const removeFile = (fileCategoryID, id, fileClassData) => ({
  type: ACTION_TYPE.REMOVE_FILE,
  payload: { fileCategoryID, id, fileClassData },
});

export const adjustFileItemRequestCount = (oldStatus, newStatus, countAdjustment, fileData) => ({
  type: ACTION_TYPE.ADJUST_PEND_COUNT,
  payload: { oldStatus, newStatus, countAdjustment, fileData },
});

export const initFileCategoriesAndClasses = (fileCategories, fileClassesByCategories) => {
  return {
    type: ACTION_TYPE.INIT_CATEGORIES_AND_CLASSES,
    payload: { fileCategories, fileClassesByCategories },
  };
};

export const toggleShareFileModal = (id) => ({
  type: ACTION_TYPE.SHARE_FILE_MODAL,
  payload: { id },
});

export default ACTION_TYPE;
