import React from 'react';
import { FaLock } from 'react-icons/fa';

const SectionLocked = (props) => {
  const {
    title,
    buttonCaption,
    info,
    details,
    bullets = [],
    canUnlockSection,
    handleUnlockButtonClick,
    reportType,
    className = '',
  } = props;

  return (
    <div className={'locked-section-container ' + className}>
      <div className="locked-section-icon">
        <FaLock />
      </div>
      <div className="section-inner-title">{title}</div>
      <div className="section-info-text">{info}</div>
      <div className="section-details-text">
        {details}
        <ul className="section-details-bullets">
          {bullets &&
            bullets.length &&
            bullets.map((bullet, index) => {
              const result = bullet[0] === '+' ? <b>{bullet.substring(1)}</b> : bullet;
              return <li key={index}>{result}</li>;
            })}
        </ul>
      </div>
      <button
        type="button"
        className="btn btn-danger locked-section-button"
        onClick={() => handleUnlockButtonClick(reportType)}
        disabled={!canUnlockSection}
        title={
          !canUnlockSection
            ? 'You do not have the permissions to unlock this section. Please contact your system admin.'
            : null
        }
        pointerEvents="auto"
      >
        {buttonCaption}
      </button>
    </div>
  );
};

export default SectionLocked;
