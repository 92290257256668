import React, { Component } from 'react';

import './severityPill.css';

export default class SeverityPill extends Component {
  render() {
    const { severity = '' } = this.props;
    const { children = '' } = this.props;
    return (
      <React.Fragment>
        <div className={'severity-pill ' + (severity || '').toLowerCase()}>{severity}</div> {children}
      </React.Fragment>
    );
  }
}
