import React from 'react';
import { useHistory } from 'react-router-dom';

const ContactFormButton = (props) => {
  const { caption, storageKey, storageValue, disabled, look = 'solidRedButton' } = props;
  const displayCaption = !caption || !storageKey || !storageValue ? 'Missing Button Properties!!!' : caption;
  const isDisabled = disabled || displayCaption !== caption;
  const className =
    look === 'outlinedSmallRedButton' ? 'btn btn-outline-danger btn-sm' : 'btn btn-danger font-weight-bold';

  const history = useHistory();

  const handleButtonClick = () => {
    if (!storageKey || !storageValue) {
      return;
    }

    localStorage.setItem(storageKey, storageValue);
    history.push('/help/contact');
  };

  return (
    <button className={className} onClick={handleButtonClick} disabled={isDisabled}>
      {displayCaption}
    </button>
  );
};

export default ContactFormButton;
