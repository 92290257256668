import React from 'react';

class Title extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const title = document.getElementsByTagName('title')[0];
    title.innerText = this.props.title || this.props.text;

    return (
      <React.Fragment>
        <h1 style={{ paddingLeft: '15px', fontSize: '40px' }}>{this.props.text}</h1>
      </React.Fragment>
    );
  }
}

export default Title;
