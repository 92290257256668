import React from 'react';
import config from '../../config';

const About = () => (
  <React.Fragment>
    <div className="row justify-content-center mb-lg-2">
      <div className="col-8">
        <h4>
          <a
            href={config.contactSupport && config.contactSupport.domain}
            target={config.contactSupport && config.contactSupport.domain}
          >
            Fortress Information Security
          </a>
        </h4>
        <h5>{config.contactSupport && config.contactSupport.telephoneUI}</h5>
        <small>
          For sales inquiries, product demos and all other purposes, please{' '}
          <a href={'#' + config.nav.contactUs.to}>contact us</a>.
        </small>
        <hr />
      </div>
    </div>
    <div className="row justify-content-center mb-lg-5">
      <div className="col-8">
        <div className="card o-hidden h-100">
          <div className="card-header">
            <h4>
              <i className="fas fa-fw fa-rocket" />
              MISSION
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-3">
                <img src="/static/images/mission_image.png" style={{ width: '159px' }} alt="MISSION" />
              </div>
              <div className="col">
                <p>
                  We target industries where reputation is critical, security is a priority and regulatory requirements
                  drive the need to protect highly-sensitive data. Our industry expertise includes critical
                  infrastructure, financial services, healthcare, banking/mortgage, insurance, retail, membership
                  organizations, and organizations with highly-decentralized global infrastructures.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center mb-lg-5">
      <div className="col-8">
        <div className="card o-hidden h-100">
          <div className="card-header">
            <h4>
              <i className="fab fa-superpowers" />
              COMPANY CULTURE
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-3">
                <img src="/static/images/culture_image.png" style={{ width: '159px' }} alt="COMPANY CULTURE" />
              </div>
              <div className="col">
                <p>
                  The work environment is in an office setting with great views of downtown Orlando from all sides! We
                  provide top-of-the-line hardware, equipment, and tools for all employees. We believe that our
                  employees are the reason that we are successful, and we continuously work on improving our posture in
                  order to continuously grow our employees’ skill sets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center mb-lg-5">
      <div className="col-8">
        <div className="card o-hidden h-100">
          <div className="card-header">
            <h4>
              <i className="fas fa-fw fa-people-carry" />
              TEAM
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-3">
                <img src="/static/images/team_image.png" style={{ width: '159px' }} alt="TEAM" />
              </div>
              <div className="col">
                <p>
                  Technology has its limits; that’s why we have experts. When you partner with Fortress, you are
                  engaging a multifaceted team, each an expert in their field, ready to defend your perimeter. We work
                  alongside you in partnership, addressing your most complex cyber-vulnerability questions and boosting
                  your overall security resilience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default About;
