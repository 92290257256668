import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withRouter } from 'react-router-dom';
import { IMAGE_NOT_AVAILABLE, sortCaseInsensitive } from '../../../lib/utils';
import { ProductVersionSelect } from '../../../routes/product/catalogProductOverview.jsx';
import Busy from '../../busy';
import config from '../../../config';
import { withEvents, events } from '../../withEvents';
import { assessmentTypes } from '../../../constants/assessmentTypes';

export const ProductManagementView = {
  Available: 'available',
  Delete: 'delete',
  Upload: 'upload',
};

const ProductManagementTable = (props) => {
  //Props
  const {
    isBusy,
    view,
    handleRowOnClick = () => {},
    data,
    selectedProducts,
    onTableChange,
    totalSize,
    sizePerPage = 10,
    page = 1,
    scrollRef,
    canManageProducts,
    setSelectedSbomHash = () => {},
    canAccessProductOverview = true,
  } = props;

  //Formatters
  const logoFormatter = (cell) => {
    return <img className="companyLogo" src={cell || IMAGE_NOT_AVAILABLE} />;
  };

  const productNameFormatter = (cell, row) => {
    const rowRef =
      view === ProductManagementView.Available &&
      selectedProducts &&
      selectedProducts.length &&
      selectedProducts.indexOf(row.productID) > -1
        ? scrollRef
        : undefined;

    return !!canAccessProductOverview ? (
      <a href={'/#/catalog/product/' + row.productID} onClick={(e) => e.stopPropagation()} ref={rowRef}>
        {cell}
      </a>
    ) : (
      <span title={cell}>{cell}</span>
    );
  };

  const simpleSpanFormatter = (cell) => {
    return cell ? <span>{cell}</span> : <>-</>;
  };

  const descriptionFormatter = (cell) => {
    return cell ? <div className="product-description">{cell}</div> : <>-</>;
  };

  const sbomAvailableFormatter = (cell, row) => {
    return cell && cell.length > 0 ? (
      <div className="sbom-avail-cell" onClick={(e) => e.stopPropagation()}>
        <i className="fa fa-check-circle"></i>
        <ProductVersionSelect
          versions={cell}
          alwaysShowDropdown={true}
          customLabel={handleVersionDisplay}
          handleVersionChange={(hashKey) => handleVersionChange(hashKey, row)}
        />
      </div>
    ) : (
      <>-</>
    );
  };

  const handleVersionDisplay = (action) => {
    let actionIcon = '';

    switch (action) {
      case 'download':
        actionIcon = <span className="fu-download-icon" title="Download SBOM"></span>;
        break;
      case 'redirect':
        actionIcon = <span className="redirect-icon" title="Redirect to Product Overview Page"></span>;
        break;

      default:
        break;
    }

    return actionIcon;
  };

  const handleDownload = (sbomData = {}) => {
    const { productID, typeID, hash, version = '' } = sbomData;
    if (productID) {
      const downloadURL = config.api.urlFor('posternProxySBOM', { id: productID, typeID, hash, version });
      const todayStr = new Date().toISOString().substring(0, 10);
      const reportName = (assessmentTypes.SBOM.find((s) => s.id === typeID) || {}).name || 'SBOM';
      const fileName = `${reportName}${version ? `_v${version}` : ''}-${todayStr}.zip`.replace(/ /g, '_');

      props.events.emit(events.DOWNLOAD_FILE, {
        url: downloadURL,
        filename: fileName,
      });
    }
  };

  const handleVersionChange = (hashKey, row) => {
    const { productID, productVersions = [] } = row || {};

    if (hashKey && row && productID) {
      const versionData = productVersions.find((v) => v.hash.substring(0, 8) === hashKey);
      if (versionData && versionData.isPurchased) {
        handleDownload(versionData);
        setSelectedSbomHash(versionData.hash);
      } else if (canAccessProductOverview) {
        props.history.push(config.routes.catalogProduct.to.replace(':id', productID).replace(':hash?', hashKey));
      }
    }
  };

  return (
    <BootstrapTable
      bootstrap4
      keyField="productID"
      data={data}
      defaultSortDirection="asc"
      columns={[
        {
          dataField: 'logoURL',
          text: 'Product Logo',
          sort: false,
          formatter: logoFormatter,
        },
        {
          dataField: 'name',
          text: 'Product Name',
          sort: true,
          sortFunc: sortCaseInsensitive,
          formatter: productNameFormatter,
        },
        {
          dataField: 'category',
          text: 'Category',
          sort: true,
          sortFunc: sortCaseInsensitive,
          formatter: simpleSpanFormatter,
        },
        {
          dataField: 'series',
          text: 'Product Line',
          sort: true,
          sortFunc: sortCaseInsensitive,
          formatter: simpleSpanFormatter,
        },
        {
          dataField: 'tags',
          text: 'Tags',
          sort: true,
          sortFunc: sortCaseInsensitive,
          formatter: simpleSpanFormatter,
        },
        {
          dataField: 'description',
          text: 'Product Description',
          sort: false,
          formatter: descriptionFormatter,
        },
        {
          dataField: 'productVersions',
          text: 'SBOM Available',
          sort: true,
          sortFunc: sortCaseInsensitive,
          formatter: sbomAvailableFormatter,
        },
      ]}
      headerClasses="bootstrap-table-header"
      rowClasses={'bootstrap-table-row' + (view === ProductManagementView.Delete ? ' delete-view' : '')}
      pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true })}
      remote
      onTableChange={onTableChange}
      noDataIndication={() =>
        isBusy ? (
          <Busy isBusy={true} />
        ) : view === ProductManagementView.Delete ? (
          <NoRecentlyAddedProductsIndication />
        ) : (
          <NoProductsIndication />
        )
      }
      selectRow={{
        hideSelectColumn: true,
        ...(canManageProducts
          ? {
              mode: view === ProductManagementView.Delete ? 'checkbox' : 'radio',
              classes: view === ProductManagementView.Delete ? 'delete-product' : 'selected-product',
              clickToSelect: true,
              selected: selectedProducts,
              onSelect: handleRowOnClick,
            }
          : {}),
      }}
      defaultSorted={[
        {
          dataField: 'name',
          order: 'asc',
        },
      ]}
    />
  );
};

const NoRecentlyAddedProductsIndication = () => (
  <div className="text-center">
    <h4>No products recently added</h4>
  </div>
);

const NoProductsIndication = () => (
  <div className="text-center">
    <h4>No products found</h4>
  </div>
);

export default withRouter(withEvents(ProductManagementTable));
