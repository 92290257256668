import React from 'react';
import FindingsSummaryItem from './findingsSummaryItem';
import SeverityLabel from './vendor/severityLabel';
import Vulnerabilities from '../routes/reports/vulnerabilities';
import { useUpdateState } from './';

const infoContainer = {
  borderRadius: '5px',
  padding: '15px',
  paddingBottom: '15px',
  background: '#f6f6f6',
};

const VulnerabilityFindings = (props) => {
  //Props
  const { vendorID, vulnsFindings, avg_score, finishedFullScan } = props;
  const { counts: scores, details: data } = vulnsFindings;

  //State
  const [state, setState] = useUpdateState({ columnSort: [], filterableData: data });

  //Helpers
  const columnSortHandler = (columnID) => {
    setState({ columnSort: [{ id: columnID, desc: true }] });
  };

  const columnSortResetter = () => {
    setState({ columnSort: [] });
  };

  const filterDataBySeverity = (severity) => {
    setState({ filterableData: data.filter((row) => row.cveSeverity == severity) });
  };

  //Render
  return (
    <>
      {finishedFullScan ? (
        <>
          <div>
            <h5>
              <b>Findings Summary:</b>
            </h5>
            <div>
              Score:
              <SeverityLabel label={avg_score} />
              {!scores?.critical ? null : (
                <div
                  onClick={() => {
                    filterDataBySeverity('CRITICAL');
                  }}
                >
                  <FindingsSummaryItem label="critical" showingVulns={true} columnSortHandler={columnSortHandler}>
                    {scores.critical} Critical vulnerabilities discovered.
                  </FindingsSummaryItem>
                </div>
              )}
              {!scores?.high ? null : (
                <div
                  onClick={() => {
                    filterDataBySeverity('HIGH');
                  }}
                >
                  <FindingsSummaryItem label="high" showingVulns={true} columnSortHandler={columnSortHandler}>
                    {scores.high} High vulnerabilities discovered.
                  </FindingsSummaryItem>
                </div>
              )}
              {!scores?.moderate ? null : (
                <div
                  onClick={() => {
                    filterDataBySeverity('MEDIUM');
                  }}
                >
                  <FindingsSummaryItem label="moderate" showingVulns={true} columnSortHandler={columnSortHandler}>
                    {scores.moderate} Moderate vulnerabilities discovered.
                  </FindingsSummaryItem>
                </div>
              )}
              {!scores?.low ? null : (
                <div
                  onClick={() => {
                    filterDataBySeverity('LOW');
                  }}
                >
                  <FindingsSummaryItem label="low" showingVulns={true} columnSortHandler={columnSortHandler}>
                    {scores.low} Low vulnerabilities discovered.
                  </FindingsSummaryItem>
                </div>
              )}
            </div>

            {!!scores && Object.keys(scores).some((x) => (scores[x] || 0) !== 0) ? null : (
              <>
                <br />
                <h5>
                  <i>There were no risks discovered as part of this scan.</i>
                </h5>
              </>
            )}
          </div>
          <br />
          <br />
          <h5>
            <b>Detailed Findings:</b>
          </h5>
          <Vulnerabilities
            page={1}
            setPageByState={true}
            tier={`vendor`}
            id={vendorID}
            externalData={state.filterableData}
            omitH1={true}
            columnSort={state.columnSort}
            columnSortResetter={columnSortResetter}
          />
        </>
      ) : (
        <div style={infoContainer}>
          <b>Vulnerability Findings will be shown once the Full Scan has completed.</b>
        </div>
      )}
    </>
  );
};

export default VulnerabilityFindings;
