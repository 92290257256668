import React from 'react';
import Busy from '../busy';
import { Table } from '..';

const BreachMonitoring = (props) => {
  //Props
  const { breachMonitoring = [], isLoadingBreaches = true } = props;

  if (breachMonitoring.length > 0) {
    breachMonitoring.forEach((obj) => {
      const breachDate = new Date(obj.date_of_breach);
      obj.date_of_breach_ui = breachDate.toLocaleDateString();
    });
  }

  //Render
  return (
    <>
      <Busy isBusy={isLoadingBreaches}>
        {
          <div className="breach-monitoring-table col-12">
            <div className="card">
              {Array.isArray(breachMonitoring) && (
                <Table
                  columns={[
                    { label: 'Date of Incident', source: 'date_of_breach_ui' },
                    { label: 'Type Of Breach', source: 'type_of_breach' },
                    { label: 'Description', source: 'description' },
                    { label: 'Evidence', source: 'evidence_link' },
                  ]}
                  data={
                    breachMonitoring.length === 0
                      ? []
                      : breachMonitoring.sort((a, b) =>
                          new Date(a?.date_of_breach) < new Date(b?.date_of_breach) ? 1 : -1,
                        )
                  } //Sort findings by date decending
                  noDataMessage={'No breaches have been identified.'}
                  isBusy={false}
                />
              )}
            </div>
          </div>
        }
      </Busy>
    </>
  );
};

export default BreachMonitoring;
