export const instances = [
  {
    host: "fortressa2v.com",
    api: "https://api.fortressa2v.com"
  },
  {
    host: "www.fortressa2v.com",
    api: "https://api.fortressa2v.com"
  }
];

export const requestBanner = {
  scrmLetter: true,
  assessmentRequest: true,
};

export const shouldAgreeToAccessConditionsToLogIn = false;

export const scrmLetter = true;

export const passwordRequiredMinLength = 15;

export const contactSupport = {
  domain: "https://www.fortressinfosec.com",
  salesDesk: "sales@assettovendor.com",
  serviceHelpDesk: "clienthelp@fortressinfosec.com",
  telephone: "+14075736800",
  telephoneUI: "407-573-6800",
}
