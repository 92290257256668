import React from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import Busy from '../../busy';
import CatalogDropZone from '../catalog/catalogDropZone';
import { ScanStatus } from './lib';
import * as TrustCenterAction from './actions';
import { useTrustCenterStore } from './context';
import config from '../../../config';
import { toastError, toastSuccess } from '../../../lib/utils';
import { AiOutlineWarning } from 'react-icons/ai';

//Modal options
const privacyOptionsToLabel = {
  public: 'Public (Anyone can view and download)',
  private: 'Private (Confirmation from you is required)',
};

//Commenting this out till we decide if this is needed or not / what updates needs to be made
// const roleBasedAccessToLabel = {
//     'admins': 'Admins (Only Admins can view and download',
//     'users': 'Users (Only Users can view and download)',
//     'viewers': 'Viewers (Only Viewers can view and download)'
// };

const AddModifyModal = (props) => {
  //Context
  const { store, dispatch } = useTrustCenterStore();
  const { addModifyModalFileCatID, fileItem = {}, fileClassesByCategories, isUploadRequest, fileCategories } = store;
  const isEdit = !!(fileItem && fileItem.id);

  //Props
  const { vendorID, onDownloadFileClick } = props;

  //State
  const [id, setID] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [fileDescription, setFileDescription] = React.useState('');
  const [fileClassData, setFileClassData] = React.useState(undefined);
  const [selectedIsPublic, setSelectedIsPublic] = React.useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [selectedRoleBasedAccess, setSelectedRoleBasedAccess] = React.useState([]);
  const [submittedFiles, setSubmittedFiles] = React.useState([]);
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isConfirmingDeletion, setIsConfirmingDeletion] = React.useState(false);

  //Consts
  const validFileCatID = fileCategories && !!fileCategories[addModifyModalFileCatID];

  //Effects
  React.useEffect(() => {
    if (validFileCatID) {
      chkSubmitEnabled();
    }
  }, [
    fileName,
    fileDescription,
    fileClassData,
    selectedIsPublic,
    selectedRoleBasedAccess.length,
    submittedFiles,
    submittedFiles.length,
  ]);

  React.useEffect(() => {
    if (validFileCatID) {
      // eslint-disable-next-line no-unused-vars
      const { id = '', name = '', description = '', fileClassID, isPublic, role_access } = fileItem || {};

      setID(id);
      setFileName(name);
      setFileDescription(description);
      setSelectedIsPublic(id ? isPublic ?? true : false);
      //setSelectedRoleBasedAccess(id ? (role_access ?? Object.keys(roleBasedAccessToLabel)) : []);
      setSubmittedFiles(fileItem && id ? [fileItem] : []);

      if (fileClassID && fileClassesByCategories && fileClassesByCategories[addModifyModalFileCatID]) {
        setFileClassData(
          fileClassesByCategories[addModifyModalFileCatID].find((classData) => classData.fileClassID === fileClassID),
        );
      } else {
        setFileClassData(undefined);
      }

      setIsSubmitted(false);
    }
  }, [addModifyModalFileCatID]);

  //Functions
  const chkSubmitEnabled = () => {
    setIsEnabled(
      fileName.length > 0 &&
        fileDescription.length > 0 &&
        fileClassData &&
        fileClassData.fileClassID &&
        selectedIsPublic !== undefined &&
        submittedFiles &&
        submittedFiles.length > 0 &&
        (isUploadRequest ? !(submittedFiles[0].url == 'pending_upload') : true) && // disables Save button until placeholder is replaced w/ a real file
        submittedFiles[0].scanStatus != 'error', // disables Save button until new file is chosen to replace an Invalid file (scan_status: 'error')
    ); // Do we need to check privacy and role-based access is populated?
  };

  const submitRequest = (event) => {
    event.preventDefault();
    // Here, we're using FormData so we can upload json and binary side-by-side
    let formData = new FormData();

    if (validFileCatID) {
      const uploadedFile = submittedFiles[0];

      let newFile = {
        vendorID: vendorID,
        id: id,
        name: fileName,
        description: fileDescription,
        fileClassID: fileClassData && fileClassData.fileClassID,
        fileClassName: fileClassData && fileClassData.canBeIncluded && fileClassData.fileClassName,
        isPublic: selectedIsPublic,
        roleAccess: selectedRoleBasedAccess,
        fileCategoryID: addModifyModalFileCatID,
        file: uploadedFile,
        type: id ? uploadedFile.type : uploadedFile.name.split('.').pop(),
      };
      setIsEnabled(false);
      setIsSubmitted(true);
      const isFile = (input) => 'File' in window && input instanceof File;

      if (isFile(uploadedFile)) {
        formData.append('file', uploadedFile, uploadedFile.name);
      }

      formData.append('metadata', JSON.stringify(newFile));

      fetch(config.api.urlFor('fileItems', { fileID: id }), {
        method: id ? 'PUT' : 'POST',
        body: formData,
      })
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, data: fileID, message } = response;

          if (isSuccess && fileID) {
            toastSuccess(`File successfully ${isEdit ? 'modified' : 'created'}.`);
            newFile.id = fileID;
            dispatch(TrustCenterAction.addUpdateFile(newFile, fileClassData));
            handleModalClose();
          } else {
            toastError(message || `File NOT ${isEdit ? 'modified' : 'created'}.`);
            chkSubmitEnabled();
            setIsSubmitted(false);
          }
        })
        .catch(() => {
          toastError('An issue occured while trying to ' + (id ? 'update' : 'add') + ' file.');
          chkSubmitEnabled();
          setIsSubmitted(false);
        });
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter' && isEnabled) {
      submitRequest(event);
    }
  };

  const promptConfirmDeletion = (event) => {
    event.preventDefault();
    setIsConfirmingDeletion(true);
  };

  const handleFileDeletion = (event) => {
    event.preventDefault();

    if (validFileCatID) {
      handleDeletionModalClose();

      setIsEnabled(false);
      setIsSubmitted(true);

      fetch(config.api.urlFor('fileItems', { fileID: id }), { method: 'DELETE' })
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, message } = response;

          if (isSuccess) {
            toastSuccess(message);
            dispatch(TrustCenterAction.removeFile(addModifyModalFileCatID, id, fileClassData));
            handleModalClose();
          } else {
            toastError(message || 'Error deleting file item.');
          }
        })
        .catch(() => toastError('An issue occured while trying to delete file.'))
        .finally(() => {
          chkSubmitEnabled();
          setIsSubmitted(false);
        });
    }
  };

  const handleDeletionModalClose = () => {
    setIsConfirmingDeletion(false);
  };

  const handleModalClose = () => {
    dispatch(TrustCenterAction.toggleAddModifyModal());
  };

  const hanldeOptionOnClick = (handleStateUpdate) => {
    return (event) => {
      event.stopPropagation();
      handleStateUpdate(event.currentTarget.closest('.sa-modal-option').getAttribute('data-value'), true);
    };
  };

  const handleOptionKeyDown = (handleStateUpdate) => {
    return (event) => {
      const currentTarget = event.currentTarget;

      if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
        event.preventDefault();

        handleStateUpdate(currentTarget.getAttribute('data-value'));
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();

        const prevSibling = currentTarget.closest('.sa-modal-option').previousSibling;

        if (prevSibling) {
          const inputEl = currentTarget.querySelector('input');

          if (inputEl && inputEl.getAttribute('type') === 'radio') {
            handleStateUpdate(prevSibling.getAttribute('data-value'));
          }

          prevSibling.focus();
        }
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();

        const nextSibling = currentTarget.closest('.sa-modal-option').nextElementSibling;

        if (nextSibling) {
          const inputEl = currentTarget.querySelector('input');

          if (inputEl && inputEl.getAttribute('type') === 'radio') {
            handleStateUpdate(nextSibling.getAttribute('data-value'));
          }

          nextSibling.focus();
        }
      }
    };
  };

  const onNameValueChange = (event) => {
    let fileName = event.target.value;
    fileName.length <= 160 ? setFileName(event.target.value) : null;
  };

  const onDescriptionChange = (event) => {
    let description = event.target.value;
    description.length <= 255 ? setFileDescription(event.target.value) : null;
  };

  const handlePrivacyValueChange = (isPublic) => {
    if (fileClassData && !fileClassData.canBePublic) {
      isPublic = 'false';
    }

    setSelectedIsPublic(isPublic === 'true');
  };

  const handleFileDownload = (event) => {
    event.preventDefault();

    if (typeof onDownloadFileClick === 'function' && !isUploadRequest) {
      onDownloadFileClick(fileItem);
    }
  };

  // const handleRoleBasedAccessChange = (roleBasedAccess,) => {
  //     let newAccessList = [].concat(selectedRoleBasedAccess|| []);

  //     const idx = newAccessList.indexOf(roleBasedAccess);

  //     if(idx > -1){
  //         newAccessList.splice(idx, 1);
  //     }
  //     else {
  //         let roleAccessToAdd = [];

  //         switch(roleBasedAccess) {
  //             case 'admins':
  //                 roleAccessToAdd.push('admins');
  //                 break;
  //             case 'users':
  //                 roleAccessToAdd.push('admins', 'users');
  //                 break;
  //             default:
  //                 roleAccessToAdd = Object.keys(roleBasedAccessToLabel);
  //                 break;
  //         }

  //         for(let roleAccess of roleAccessToAdd) {
  //             if(newAccessList.indexOf(roleAccess) < 0){
  //                 newAccessList.push(roleAccess);
  //             }
  //         }
  //     }

  //     setSelectedRoleBasedAccess(newAccessList);
  // }

  const fileClassChangeHandler = (selectedOption) => {
    setFileClassData(selectedOption);

    if (selectedOption && !selectedOption.canBePublic) {
      setSelectedIsPublic(false);
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={validFileCatID}
      className="sa-modal-style"
      contentLabel={
        (isEdit ? 'Edit ' : 'New ') + (validFileCatID ? fileCategories[addModifyModalFileCatID] + ' ' : '') + 'file'
      }
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
    >
      <Busy isBusy={isSubmitted}>
        <form data-test-id="UploadFile-modal-1">
          <div className="sa-modal-btn-container">
            <div className="sa-modal-close" onClick={handleModalClose}>
              x
            </div>
          </div>
          {selectedIsPublic && (
            <div className="public-privacy-warning">
              <AiOutlineWarning className="public-privacy-warning-icon" />
              <div>This file is public and will be available for any A2V member to download!</div>
            </div>
          )}
          <div className="sa-entry-form">
            <div className="sa-entry-form-row">
              <div>
                <label className="sa-modal-label" htmlFor="UploadFile-fileName">
                  File Name:
                </label>
              </div>
              <div>
                <input
                  type="text"
                  id="UploadFile-fileName"
                  className="form-control sa-modal-input"
                  required="required"
                  name="file_name"
                  onChange={(e) => onNameValueChange(e)}
                  value={fileName}
                  maxLength={160}
                  onKeyPress={handleEnterKeyPress}
                />
              </div>
            </div>

            <div className="sa-entry-form-row">
              <div>
                <label className="sa-modal-label" htmlFor="UploadFile-description">
                  Description:
                </label>
              </div>
              <div>
                <input
                  type="text"
                  id="UploadFile-description"
                  className="form-control sa-modal-input"
                  required="required"
                  name="file_description"
                  onChange={(e) => onDescriptionChange(e)}
                  value={fileDescription}
                  maxLength={255}
                  onKeyPress={handleEnterKeyPress}
                />
              </div>
            </div>

            {fileClassesByCategories &&
              fileClassesByCategories[addModifyModalFileCatID] &&
              fileClassesByCategories[addModifyModalFileCatID].length && (
                <div className="sa-entry-form-row">
                  <div>
                    <label className="sa-modal-label" htmlFor="UploadFile-type">
                      File Type:
                    </label>
                  </div>
                  {isEdit ? (
                    <input
                      type="text"
                      id="UploadFile-type"
                      name="file_type"
                      className="form-control sa-modal-input"
                      value={fileClassData && fileClassData.fileClassName ? fileClassData.fileClassName : ''}
                      disabled
                    />
                  ) : (
                    <Select
                      id="UploadFile-type"
                      name="file_type"
                      value={fileClassData}
                      onChange={fileClassChangeHandler}
                      options={fileClassesByCategories[addModifyModalFileCatID]}
                      getOptionLabel={(option) => option.fileClassName}
                      getOptionValue={(option) => option.fileClassID}
                      isOptionDisabled={(option) => !option.canBeUsed}
                    />
                  )}
                </div>
              )}
          </div>

          <label className="sa-modal-label">Privacy Setting:</label>
          <div className="sa-modal-privacy">
            {Object.keys(privacyOptionsToLabel).map((privacyOption) => {
              const key = privacyOption;
              const label = privacyOptionsToLabel[key];
              const value = key === 'public';

              const optionID = 'privacy-option-' + key.replace(/ /g, '');
              const isChecked = selectedIsPublic === value;
              const isDisabled = !!fileClassData && !fileClassData.canBePublic;
              return (
                <div
                  key={key}
                  data-value={value}
                  className={'sa-modal-option' + (value && isChecked ? ' privacy-warning' : '')}
                  onClick={hanldeOptionOnClick(handlePrivacyValueChange)}
                  onKeyDown={handleOptionKeyDown(handlePrivacyValueChange)}
                  tabIndex={selectedIsPublic === undefined || (selectedIsPublic !== undefined && isChecked) ? 0 : -1}
                  disabled={isDisabled}
                >
                  <input
                    id={optionID}
                    type="radio"
                    name="privacy"
                    required="required"
                    value={value}
                    tabIndex={-1}
                    checked={isChecked}
                    readOnly={true}
                    disabled={isDisabled}
                  />
                  <label className="sa-option-label">{label}</label>
                </div>
              );
            })}
          </div>

          {/*
                    <label className='sa-modal-label'>Role-Based Access: <span className='sa-modal-label-tip'>(Select all that apply)</span></label>
                    <div className='sa-modal-role-access'>
                    {
                        Object.keys(roleBasedAccessToLabel).map((access) => {
                        const value = access;
                        const label = roleBasedAccessToLabel[access];

                        const optionID = 'role-access-option-' + value.replace(/ /g, '');
                        const isChecked = selectedRoleBasedAccess.indexOf(value) > -1;
                        
                        return (
                            <div 
                                key={value}
                                data-value={value}
                                className='sa-modal-option' 
                                onClick={hanldeOptionOnClick(handleRoleBasedAccessChange)} 
                                onKeyDown={handleOptionKeyDown(handleRoleBasedAccessChange)} 
                                tabIndex={(!selectedRoleBasedAccess.length || (selectedRoleBasedAccess.length > 0 && isChecked)) ? 0 : -1}
                            >   
                                <input id={optionID} type="checkbox" name="role_access[]" value={value}  tabIndex={-1} checked={isChecked} readOnly={true}/>
                                <label className='sa-option-label'>{label}</label>
                            </div>
                        );
                        })
                    }
                    </div>
                    */}
          {(!id || isUploadRequest || (id && fileItem.scanStatus === ScanStatus.ERROR)) && (
            <>
              <label className="sa-modal-label">Upload your file:</label>
              <CatalogDropZone
                currentFiles={submittedFiles}
                setCurrentFiles={setSubmittedFiles}
                singleFileMode={true}
              />
            </>
          )}

          <div className="sa-modal-btn-container">
            {isEdit && !isUploadRequest && (
              <div className="sa-modal-btns-left">
                <button
                  data-test-id="UploadFile-button-delete"
                  className="btn btn-outline-primary btn-nav sa-modal-delete-btn"
                  onClick={promptConfirmDeletion}
                >
                  Delete File
                </button>
                {fileItem.scanStatus !== ScanStatus.ERROR && (
                  <button
                    data-test-id="UploadFile-button-download"
                    className="btn btn-outline-success btn-nav bg-success text-white ml-3"
                    onClick={handleFileDownload}
                  >
                    Download File
                  </button>
                )}
              </div>
            )}
            <div className="sa-modal-btns-right">
              <button
                data-test-id="UploadFile-button-cancel"
                className="btn btn-outline-primary btn-nav sa-modal-cancel-btn"
                onClick={handleModalClose}
              >
                Cancel
              </button>
              <button
                data-test-id="UploadFile-button-submit"
                className="btn btn-outline-primary btn-nav sa-modal-submit-btn"
                onClick={submitRequest}
                disabled={!isEnabled}
              >
                {isEdit ? 'Save' : 'Submit'}
              </button>
            </div>
          </div>
        </form>

        <Modal
          ariaHideApp={false}
          isOpen={isConfirmingDeletion}
          className="sa-modal-style sa-modal-style-mini"
          contentLabel="Confirm file deletion"
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleDeletionModalClose}
        >
          <div className="sa-modal-btn-container">
            <div className="sa-modal-close" onClick={handleDeletionModalClose}>
              x
            </div>
          </div>
          <div className="sa-modal-delete-confim">
            <div>Are you sure you want to delete this file?</div>
            <div>You will lose all of the user approval requests linked to this file.</div>
            <div>
              This action is <span className="sa-modal-delete-confirm-msg">permanent</span> and cannot be undone.
            </div>
          </div>
          <div className="sa-modal-btn-container">
            <div className="sa-modal-btns-right">
              <button
                data-test-id="UploadFile-button-cancel"
                className="btn btn-outline-primary btn-nav sa-modal-cancel-btn"
                onClick={handleDeletionModalClose}
              >
                Cancel
              </button>
              <button
                data-test-id="UploadFile-button-delete"
                className="btn btn-outline-primary btn-nav sa-modal-delete-btn"
                onClick={handleFileDeletion}
              >
                Delete This File
              </button>
            </div>
          </div>
        </Modal>
      </Busy>
    </Modal>
  );
};

export default AddModifyModal;
