import React, { useEffect } from 'react';
import { useUpdateState } from '../../';
import CatalogReportGenericInfo from './catalogReportGenericInfo';
import config from '../../../config';
import { Busy, Spinner } from '../../../components';
import { assessmentTypes } from '../../../constants/assessmentTypes';
import { roleNames, guestSubscription } from '../../../constants/userRoles';
import { withPrincipal } from '../../';
import UnclaimedVendorContactDetailsModal from '../trustCenter/unclaimedVendorContactDetailsModal';
import { toastError, toastSuccess } from '../../../lib/utils';

const NUM_OF_COLS = 4;

const BottomModalStage1 = (props) => {
  const [state, setState] = useUpdateState({
    hasErrors: {
      hasReport: false,
    },
    newVendorError: {
      hasError: false,
      errorMessage: '',
      errorResolveMessage: '',
    },
    isBusy: false,
    isDisabled: false,
    unclaimedFileClassID: undefined,
    buttonCaption: '',
  });

  const {
    vendorID,
    newVendorID,
    requestedReports,
    handleCheckbox,
    name,
    topModalState,
    isVendorCreationOnly,
    domain,
    reportDescriptions = {},
    reportOfferings = {},
    downloadableReports = {},
    freeReports,
    setCatalogRequestedVendor,
    setBottomModalState,
    linkedReports,
    shareableReports,
    selectedSharedReports,
    canPurchaseSharedReports,
    trustCenterReports,
    sharingPropensity,
    principal,
    vendorDDRRid,
    setParentState = () => {},
  } = props;

  useEffect(() => {
    // should catch the undefined case too, since null == undefined
    if (props.errorFields.stateReports.bottomModalButtonDisabled != null) {
      if (props.errorFields.stateReports.bottomModalButtonDisabled == false) {
        setState({ isDisabled: false });
      }
    }
  }, [props.errorFields.stateReports.bottomModalButtonDisabled]);

  useEffect(() => {
    let buttonCaption = 'Add to Cart ';

    if (props.topModalState === 'addVendor' && !props.newVendorID) {
      if (props.isVendorCreationOnly) {
        buttonCaption = 'Create Vendor ';
      } else {
        buttonCaption = 'Create and Add to Cart';
      }
    }

    setState({ buttonCaption });
  }, [props.topModalState, props.isVendorCreationOnly, props.newVendorID]);

  //------------------
  // Event Helper Functions
  //------------------
  const handleKeydownCheckbox = (data) => {
    return (event) => {
      if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
        event.preventDefault();
        props.handleCheckbox(data);
      }
    };
  };

  const handleTrustCenterReportRequest = (fileClassID) => {
    fetch(config.api.urlFor('vendorClaimStatus', { vendorDDRRid }))
      .then((res) => res.json())
      .then((vendorClaimData) => {
        if (vendorClaimData.isSuccess) {
          setState({ vendorIsClaimed: vendorClaimData.isClaimed });
        }

        if (vendorClaimData.isClaimed) {
          fetch(config.api.urlFor('fileItemRequest'), {
            method: 'POST',
            body: {
              vendorID,
              fileClassID,
            },
          })
            .then((result) => result.json())
            .then((response) => {
              const { isSuccess, message = 'Error requesting report.' } = response;

              if (isSuccess) {
                toastSuccess('File Request Successfully Made.');
              } else {
                toastError(`Request Failed: ${message}`);
              }
            })
            .catch(() => toastError('Failed to request report.'));
        } else {
          setState({ unclaimedFileClassID: fileClassID }); // opens the unclaimedVendorContactDetailsModal
        }
      });
  };

  const closeUnclaimedVendorContactDetailsModal = () => {
    setState({ unclaimedFileClassID: undefined });
  };

  //------------------
  // API Calls
  //------------------

  /** This asks the API to check for
   * 1. domain resolution: Renders error message and returns false
   * 2. presence in guardian db: Renders error message that redirects to the correct vendor profile and returns false
   * 3. presence of a match in DDRR API: Returns object representing best match
   * 4. None of the above: return true
   * @param {string} name: vendor name
   * @param {domain} domain: company domain
   * @returns boolean or object of schema
   * {
   *     goForward: true,
   *     name: {string} response.match.name,
   *     domain: {string} response.match.domain
   * }
   */
  const sendCreationCallAndSetErrorMessages = async (name, domain) => {
    let goForward = null;

    await fetch(config.api.urlFor('catalogCreateNewVendor'), {
      method: 'POST',
      body: { name, domain },
    }).then((res) => {
      if (res.status >= 400) {
        goForward = false;
        setState({
          newVendorError: {
            hasError: true,
            errorMessage: 'Encountered Server Error! Please reach out to support for assistance',
          },
        });
      } else if (res.status === 200) {
        return res.json().then((response) => {
          const { isSuccess, message, data: validationData } = response;

          goForward = isSuccess;

          if (!validationData) {
            setState({
              hasErrors: { hasReport: false },
              newVendorError: {
                hasError: true,
                errorMessage: message || 'Unknown error on vendor creation',
              },
            });
          } else if (validationData.resolved === false) {
            setState({
              hasErrors: { hasReport: false },
              newVendorError: {
                hasError: true,
                errorMessage: 'The provided domain could not be resolved! Please ensure the domain field is correct!',
              },
            });
          } else if (validationData.alreadyExists === true) {
            setState({
              hasErrors: { hasReport: false },
              newVendorError: {
                hasError: true,
                hasDomain: true,
                name: validationData.match.name,
                domain: `/#/catalog/vendor/${validationData.match.id}/Overview`,
                errorMessage: 'A company already exists with this domain: ',
                errorResolveMessage: (
                  <React.Fragment>
                    Please contact support using the <strong>Need Help?</strong> button at the bottom right of the page
                    if the existing vendor is not the vendor you are looking for.
                  </React.Fragment>
                ),
              },
            });
          } else {
            if (validationData.isMatchingDomain === true) {
              // domain match exists, replace name
              setState({
                hasErrors: { hasReport: false },
                newVendorError: {
                  hasError: true,
                  hasDomain: true,
                  name: validationData.match.name,
                  domain: `/#/catalog/name/asc/1?search=${validationData.match.domain}&purchaseReports=${validationData.match.id}`,
                  errorMessage: 'Created a record with the provided domain: ',
                },
              });
            }

            goForward = {
              goForward: true,
              isMatchingDomain: validationData.isMatchingDomain,
              name: validationData.match.name,
              domain: validationData.match.domain,
              ddrr_id: validationData.match.id,
              newVendorID: validationData.vendorID,
            };
          }

          // Check for null or undefined
          if (goForward == null) {
            goForward = false;
            setState({
              newVendorError: {
                hasError: true,
                errorMessage: 'Encountered Unknown Error! Please reach out to support for assistance',
              },
            });
          }
        });
      }
    });

    return goForward;
  };

  /** Performs all necessary checks before permitting the modal to head to the next step. Manages state changes for error handling
   * @param name {string}: company name from input box
   * @param domain {string}: company domain from input box
   * @param topModalState {string}: "addVendor" runs checks in the case that the user is trying to add a new vendor
   * @param setCatalogRequestedVendor {function}: passed down from catalog.jsx, used to set name/domain at the catalog scope
   * @param newVendorID {UUID}: The ID of the newly created vendor. Will have value only when the user goes forward and then back to that modal stage.
   * @returns {boolean}: true for allow next step, false for block next step
   */
  const authorizeNextStage = async (topModalState, name, domain, setCatalogRequestedVendor, newVendorID) => {
    setState({ isDisabled: true, newVendorError: { hasError: false } });
    props.setInputError('bottomModalButtonDisabled', true);
    let returnValue = true;

    if (topModalState === 'addVendor' && !newVendorID) {
      if (!name || name.length === 0) {
        props.setInputError('name', true);
        returnValue = false;
      } else {
        props.setInputError('name', false);
      }

      // ensure this is a valid domain input
      const regex = new RegExp(/^(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z-]*[a-z])?$/);
      if (!domain || !regex.test(domain)) {
        props.setInputError('domain', true);
        returnValue = false;
      } else {
        props.setInputError('domain', false);
      }

      if (returnValue) {
        // This is where the Guardian API call for vendor creation takes place
        setState({ isBusy: true });
        const response = await sendCreationCallAndSetErrorMessages(name, domain);
        setState({ isBusy: false });

        if (response === false) {
          // return false: do not authorize next stage.
          return false;
        } else if (typeof response === 'object' && response.goForward === true) {
          // return object: quietly replace the requestedVendor data in catalog.jsx then authorize next step
          setCatalogRequestedVendor(response.newVendorID, response.name, response.domain, response.ddrr_id);
        }
      }
    } else {
      let reportCount = 0;
      let requestedReports = props.requestedReports;

      Object.keys(requestedReports).map((report) => {
        reportCount += requestedReports[report];
      });

      if (reportCount === 0 && !props.hasProductReports) {
        setState({ hasErrors: { hasReport: true } });
        return false;
      } else {
        setState({ hasErrors: { hasReport: false } });
      }
    }

    return returnValue;
  };

  let { vendorReports = [], assetReports = [] } = reportOfferings;
  const { hasErrors, newVendorError, unclaimedFileClassID } = state;

  // TODO: The following check must be based on permissions, not on role names.
  const canRequestTrustCenterReports =
    !principal.roles.some((x) => x.name == roleNames.GUEST_USER || x.name == roleNames.SUBSCRIBER_VIEWER) &&
    principal.subscription != guestSubscription;

  // Override to provide checkbox option all non-share reports when in addVendor mode
  if (topModalState === 'addVendor') {
    vendorReports = reportOfferings.vendorReports
      .map((x) => reportDescriptions[x])
      .filter((x) => ![assessmentTypes.A2VQA_S.name, assessmentTypes.DDVA_S.name].includes(x.rawType))
      .map((x) => x.name);

    assetReports = reportOfferings.assetReports;
  }

  return (
    <>
      <div className="modal-report-info-box">
        <p className="modal-report-info-title">Assessments &amp; Reports</p>
        <p className="modal-report-info-description">
          Disclaimer: Turntime of assessments and reports is dependent on availability of assessment or report, and
          pending sharing agreements with company. If the company chooses not to cooperate (for A2V), we may not be able
          to deliver the assessment.
          <br />
          If you would like to request a new version of a report you have previously purchased, please reach out to your
          account lead.
        </p>

        {hasErrors.hasReport && <p className="m-error">Please select a report to request</p>}
        <p className="report-section-title mt-4">
          <i className="fas fa-check" /> &nbsp; Purchased
        </p>
        <p className="report-section-title">
          <i className="fas fa-bolt" /> &nbsp; Available immediately
        </p>
        <Busy isBusy={freeReports === undefined || trustCenterReports === undefined}>
          <table className="modal-report-table">
            <thead>
              <tr>
                <th className="modal-report-column-reports">
                  {canRequestTrustCenterReports && !newVendorID ? 'Self-Attestation' : 'Vendor Reports'}
                </th>
                <th className="modal-report-column-dates">Latest Completion Date</th>
                <th className="modal-report-column-shares">{!!canPurchaseSharedReports && 'Purchase Shared Report'}</th>
                <th className="modal-report-column-prices">Price</th>
              </tr>
            </thead>
            <tbody>
              {canRequestTrustCenterReports && !newVendorID ? (
                trustCenterReports && trustCenterReports.length ? (
                  trustCenterReports.map((data) => {
                    const { id, name, price = 0 } = data;

                    return (
                      <tr key={name}>
                        <td className="modal-report-trustcenter text-left">{name}</td>
                        <td>
                          <button
                            className="modal-report-info-btn-stage1 btn"
                            onClick={() => {
                              handleTrustCenterReportRequest(id);
                            }}
                          >
                            Request From Vendor
                          </button>
                        </td>
                        <td></td>
                        <td>{price}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={NUM_OF_COLS}>No self-attestation reports found for {name}</td>
                  </tr>
                )
              ) : null}
              {canRequestTrustCenterReports && !newVendorID && (
                <tr>
                  <th className="pt-4">Vendor Reports</th>
                </tr>
              )}
              {vendorReports.length ? (
                vendorReports.map((data, i) => {
                  const purchaseDate = freeReports && freeReports[`${data}`] ? freeReports[`${data}`] : null;
                  const showSharedReport = shareableReports[`${data}`];
                  const shareReport =
                    linkedReports.filter((item) => item.parentReport === data).length > 0 &&
                    linkedReports.filter((item) => item.parentReport === data)[0].report;
                  const sharedReportsClassName =
                    canPurchaseSharedReports && showSharedReport && requestedReports[data]
                      ? 'cursor-pointer'
                      : 'cursor-default';

                  // The check if the value in this column should be "Real-Time" is temporary. We expect this information to come from another source in the future.
                  const report = reportDescriptions[data];

                  const latestCompletionDate = !report.autodeliver
                    ? downloadableReports[`${data}`]
                      ? downloadableReports[`${data}`]
                      : '-'
                    : 'Real-Time';

                  const sharingPropensityText =
                    showSharedReport && report.rawType === assessmentTypes.A2VQA.name && latestCompletionDate !== '-'
                      ? sharingPropensity
                      : null;

                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td
                          className="modal-report-info text-left"
                          onClick={() => handleCheckbox(data)}
                          onKeyDown={handleKeydownCheckbox(data)}
                          tabIndex={0}
                        >
                          <input
                            className="modal-report-tick-box"
                            type="checkbox"
                            checked={requestedReports[data] || false}
                            readOnly={true}
                            tabIndex={-1}
                          />
                          <CatalogReportGenericInfo
                            title={data}
                            reportDescriptions={reportDescriptions}
                            purchaseDate={purchaseDate}
                          />
                          {downloadableReports[`${data}`] && !purchaseDate ? <i className="fas fa-bolt"></i> : null}
                          {purchaseDate ? <i className="fas fa-check"></i> : null}
                        </td>
                        <td>{latestCompletionDate}</td>
                        <td
                          className={sharedReportsClassName}
                          onClick={() =>
                            canPurchaseSharedReports && showSharedReport
                              ? handleCheckbox(data, !selectedSharedReports[data])
                              : null
                          }
                        >
                          {!!(canPurchaseSharedReports && showSharedReport) && (
                            <input
                              className={'modal-report-tick-box ' + sharedReportsClassName}
                              type="checkbox"
                              disabled={!requestedReports[data]}
                              checked={(requestedReports[data] && selectedSharedReports[data]) || false}
                              readOnly={true}
                              tabIndex={0}
                            />
                          )}
                        </td>
                        <td>
                          {purchaseDate
                            ? 0
                            : selectedSharedReports[data] && reportDescriptions[shareReport]
                            ? reportDescriptions[shareReport].price
                            : report.price}
                        </td>
                      </tr>
                      {!!sharingPropensityText && (
                        <tr>
                          <td colSpan="4">
                            <p className="modal-report-sharing-propensity-text">{sharingPropensityText}</p>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={NUM_OF_COLS}>No vendor reports found for {name}</td>
                </tr>
              )}
              <tr>
                <th className="pt-4">Product Reports</th>
              </tr>
              {assetReports.length ? (
                assetReports.map((data, i) => {
                  const purchaseDate = freeReports && freeReports[`${data}`] ? freeReports[`${data}`] : null;
                  return (
                    <tr key={i}>
                      <td
                        className="modal-report-info text-left"
                        onClick={() => handleCheckbox(data)}
                        onKeyDown={handleKeydownCheckbox(data)}
                        tabIndex={0}
                        key={i}
                      >
                        <input
                          className="modal-report-tick-box"
                          type="checkbox"
                          checked={requestedReports[data] || false}
                          readOnly={true}
                          tabIndex={-1}
                        />
                        <CatalogReportGenericInfo
                          title={data}
                          reportDescriptions={reportDescriptions}
                          purchaseDate={purchaseDate}
                        />
                        {downloadableReports[`${data}`] && !purchaseDate ? <i className="fas fa-bolt"></i> : null}
                        {purchaseDate ? <i className="fas fa-check"></i> : null}
                      </td>
                      <td></td>
                      <td></td>
                      <td>{reportDescriptions[data].price}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={NUM_OF_COLS}>No product reports found for {name}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Busy>
        {newVendorError.hasError ? (
          <div className="modal-report-request-error">
            {newVendorError.errorMessage}{' '}
            {newVendorError.hasDomain ? <a href={newVendorError.domain}>{newVendorError.name}</a> : null}
            {newVendorError.errorResolveMessage ? (
              <div className="modal-report-request-resolve">{newVendorError.errorResolveMessage}</div>
            ) : null}
          </div>
        ) : null}
        <div className="mt-5">
          <div className="modal-report-bottom-right">
            <button
              disabled={state.isBusy}
              onClick={async () => {
                if (await authorizeNextStage(topModalState, name, domain, setCatalogRequestedVendor, newVendorID)) {
                  setBottomModalState(isVendorCreationOnly ? 'CLOSE' : 'NEXT');

                  if (topModalState === 'addVendor') {
                    setParentState('forceDataReload', true);
                  }
                }
              }}
              className="modal-report-info-btn-stage1 btn"
            >
              {state.buttonCaption}
              <Spinner isVisible={state.isBusy} />
            </button>
          </div>
        </div>
      </div>
      <UnclaimedVendorContactDetailsModal
        vendorID={vendorID}
        fileClassID={unclaimedFileClassID}
        handleModalClose={closeUnclaimedVendorContactDetailsModal}
      />
    </>
  );
};

export default withPrincipal(BottomModalStage1);
