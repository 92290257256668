import React from 'react';
import { useParams } from 'react-router-dom';
import { Busy, Pagination, useQuery, useUpdateState, withPrincipal, ToggleSwitch } from '../../components';
import VendorCard from '../../components/vendor/catalog/vendorCard';
import ModalStagesContainer from '../../components/vendor/catalog/modalStagesContainer';
import config from '../../config';
import { pageIsValid } from '../../lib/utils';
import CatalogViewSwitch from './catalogViewSwitch';
import { CatalogVendorContext } from './context';
import NewItemCard from '../../components/newItemCard';

import './catalog.css';

// CONNECTS TO COMMENTED OUT VENDOR/PRODUCTS TOGGLE IN RENDER
// const searchOptionsBtn = {
//   border: 'none',
//   backgroundColor: 'white',
//   outline: 'none',
//   cursor: 'pointer',
// };

const Catalog = (props) => {
  //Props
  const { principal } = props;
  const { page: pPage, orderBy, dir, pageSize = 50 } = useParams();
  const { search = '', myVendor, reports = '', showAddVendor, files = '', purchaseReports } = useQuery();

  const page = !Number.isNaN(pPage) ? +pPage : 1;

  //State
  const [state, setState] = useUpdateState({
    requestedVendorName: '',
    requestedVendorDomain: '',
    isBusy: true,
    showModal: false,
    modalData: {},
    topModalState: 'addVendor',
    showAllAvailableReportsOptions: false,
    totalRecords: 0,
    availableReportsList: [],
    filteredAvailableReportsList: [],
    selectedAvailableReportsList: [],
    vendors: [],
    popularVendors: [],
    trustCenterFilesList: [],
    filteredTrustCenterFilesList: [],
    selectedTrustCenterFilesList: [],
    canPurchaseReports: principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.purchaseReports')),
    canAddVendors: principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.addVendors')),
    searchInput: search,
    forceDataReload: false,
  });

  const {
    isBusy,
    totalRecords,
    showModal,
    vendors,
    popularVendors,
    modalData,
    topModalState,
    canPurchaseReports,
    canAddVendors,
    availableReportsList,
    filteredAvailableReportsList,
    selectedAvailableReportsList,
    requestedVendorName,
    requestedVendorDomain,
    reportDescriptions,
    reportOfferings,
    linkedReports,
    trustCenterFilesList,
    // filteredTrustCenterFilesList,
    selectedTrustCenterFilesList,
    searchInput,
    forceDataReload,
  } = state;

  //Context
  const contextData = {
    reportDescriptions,
    reportOfferings,
    linkedReports,
  };

  /* ----------------------------------- */
  /*          Life Cycle Methods         */
  /* ----------------------------------- */

  React.useEffect(() => {
    if (!!showAddVendor) {
      setShowAddVendorModal(true);
    }

    getAssessmentCatalog();
    //getTrustCenterFilesList();
  }, [showAddVendor]);

  const loadVendorsData = (page, orderBy, dir, myVendor, search, reports, files) => {
    setState({ isBusy: true });

    const reportsList = !reports ? [] : reports.split(','); // encode once as the url decoded once

    const filesList = !files ? [] : files.split(',');

    setState({
      selectedAvailableReportsList: reportsList,
      selectedTrustCenterFilesList: filesList,
    });

    const asyncGetVendors = async (reports, page, orderBy, dir, search, pageSize, myVendor, files) => {
      const asc = dir === 'desc' ? 0 : 1;

      const reportsEncoded = JSON.stringify(reports);

      const filesEncoded = JSON.stringify(files);

      const searchEncoded = JSON.stringify(search);

      const vendorData = await getVendors(
        reportsEncoded,
        page,
        orderBy,
        asc,
        searchEncoded,
        pageSize,
        myVendor,
        filesEncoded,
      );

      handleVendorResults(vendorData);
    };

    asyncGetVendors(reportsList, page, orderBy, dir, search, pageSize, myVendor, filesList);
  };

  React.useEffect(() => {
    loadVendorsData(page, orderBy, dir, myVendor, search, reports, files);
  }, [page, orderBy, dir, myVendor, search, reports, files]);

  React.useEffect(() => {
    if (forceDataReload) {
      loadVendorsData(page, orderBy, dir, myVendor, search, reports, files);
      setState({ forceDataReload: false });
    }
  }, [forceDataReload]);

  React.useEffect(() => {
    if (!reports) {
      setState({ selectedAvailableReportsList: [] });
    }
  }, [reports]);

  React.useEffect(() => {
    if (!files) {
      setState({ selectedTrustCenterFilesList: [] });
    }
  }, [files]);

  React.useEffect(() => {
    handlePageChange(page);
  }, [page]);

  /* ----------------------------------- */
  /*          API Calls                  */
  /* ----------------------------------- */

  const getAssessmentCatalog = () => {
    fetch(config.api.urlFor('catalogReportTypes') + '?pageSize=0')
      .then((res) => res.json())
      .then((data) => {
        let { rows = [] } = data;
        let reportDescriptions = {};
        let availableReportsList = [];
        let reportOfferings = { vendorReports: [], assetReports: [] };
        let linkedReports = [];

        rows.forEach((r) => {
          reportDescriptions[r.name] = {
            name: r.name,
            abvName: r.abbreviation,
            rawType: r.rawType,
            price: r.price,
            description: r.description,
            id: r.id,
            parentID: r.parent_id,
            autodeliver: r.autodeliver,
          };

          if (r.class === 'vendor' && !r.parent_id) {
            availableReportsList.push({ name: r.name, rawType: r.rawType });
          }

          if (r.class === 'product') {
            reportOfferings.assetReports.push(r.name);
          } else if (!r.parent_id) {
            reportOfferings.vendorReports.push(r.name);
          } else {
            const parentReportName = rows.find((report) => report.id === r.parent_id).name;
            linkedReports.push({ report: r.name, parentReport: parentReportName });
          }
        });

        availableReportsList.sort((a, b) => (a.name > b.name ? 1 : -1));

        setState({
          reportOfferings,
          reportDescriptions,
          availableReportsList,
          filteredAvailableReportsList: availableReportsList,
          linkedReports,
        });
      })
      .catch(() => {
        setState({ reportDescriptions: {}, availableReportsList: [] });
      });
  };

  /* const getTrustCenterFilesList = () => {
    fetch(config.api.urlFor('purchasableFileItemsClasses'))
      .then((res) => res.json())
      .then((data) => {
        setState({
          trustCenterFilesList: data.classes,
          filteredTrustCenterFilesList: data.classes,
        });
      })
      .catch(() => {
        setState({
          trustCenterFilesList: [],
          filteredTrustCenterFilesList: [],
        });
      });
  }; */

  const updateURL = async ({
    page: argPage,
    orderBy: argOrderBy,
    dir: argDir,
    search: argSearch,
    myVendor: argMyVendor,
    reports: argReports,
    files: argFiles,
  } = {}) => {
    argPage = argPage || page || 1;
    argOrderBy = argOrderBy || orderBy || 'name';
    argDir = argDir || dir || 'asc';
    argSearch = argSearch !== undefined ? argSearch : null || search || '';
    argMyVendor = argMyVendor !== undefined ? argMyVendor : null || myVendor;
    argReports = argReports || (reports.length ? reports.split(',') : null);
    argFiles = argFiles || (files.length ? files.split(',') : null);

    const myVendorFilter = argMyVendor ? true : false;
    const reportsFilter = argReports && argReports.length ? true : false;
    const filesFilter = argFiles && argFiles.length ? true : false;
    const searchFilter = argSearch && argSearch.length ? true : false;

    let pathname = '/catalog/' + argOrderBy + '/' + argDir + '/' + argPage;
    let hasAddedQueryParam = false;

    if (myVendorFilter) {
      pathname = pathname.concat('?myVendor=true');
      hasAddedQueryParam = true;
    }

    if (searchFilter) {
      if (hasAddedQueryParam) {
        pathname = pathname.concat(`&search=${argSearch}`);
      } else {
        pathname = pathname.concat(`?search=${argSearch}`);
        hasAddedQueryParam = true;
      }
    }

    if (reportsFilter) {
      if (hasAddedQueryParam) {
        pathname = pathname.concat(`&reports=${argReports.join(',')}`);
      } else {
        pathname = pathname.concat(`?reports=${argReports.join(',')}`);
        hasAddedQueryParam = true;
      }
    } else {
      setState({ selectedAvailableReportsList: [] });
    }

    if (filesFilter) {
      if (hasAddedQueryParam) {
        pathname = pathname.concat(`&files=${argFiles.join(',')}`);
      } else {
        pathname = pathname.concat(`?files=${argFiles.join(',')}`);
        hasAddedQueryParam = true;
      }
    } else {
      setState({ selectedTrustCenterFilesList: [] });
    }

    props.history.replace(pathname);
  };

  const getVendors = async (
    assessmentRawTypeList,
    page,
    orderBy,
    asc,
    search,
    pageSize,
    myVendor,
    trustCenterFileClassList,
  ) => {
    const response = await fetch(
      config.api.urlFor('catalog', {
        assessmentRawTypeList,
        page,
        orderBy,
        asc,
        search,
        pageSize,
        myVendor,
        trustCenterFileClassList,
      }),
    );

    const data = await response.json();
    return data;
  };

  const handleVendorResults = (data) => {
    const { vendors, popularVendorsByTransactions } = data;

    setState({
      isBusy: false,
      vendors: vendors.rows,
      popularVendors: popularVendorsByTransactions.rows,
      totalRecords: vendors.totalRecords,
    });

    if (!!purchaseReports) {
      let foundVendor = data.rows.find((i) => i.id === purchaseReports);
      setModalData(foundVendor);
      setShowModal(true);
    }
  };

  /* ----------------------------------- */
  /*          Handle Inputs              */
  /* ----------------------------------- */

  const handleCardClick = (event, canPurchaseReports, vendorData) => {
    let e = event || window.event;
    e = e.target || e.srcElement;

    if (!vendorData || e.nodeName.toLowerCase() === 'a') {
      return;
    }

    if (canPurchaseReports) {
      setModalData(vendorData);
      setShowModal(true);
    } else if (vendorData.id) {
      props.history.push(`/catalog/vendor/${vendorData.id}/Overview`);
    }
  };

  const handleCheckboxFilter = (id, type) => {
    let updatedList = [];

    if (type === 'report') {
      updatedList = selectedAvailableReportsList;
    } else if (type === 'file') {
      updatedList = selectedTrustCenterFilesList;
    }

    if (document.getElementById(id).checked) {
      updatedList.push(id);
    } else {
      updatedList.splice(updatedList.indexOf(id), 1);
      if (document.getElementById('filterOptions').value.toString().toLowerCase()) {
        //If we're already filtering based on search text, check if should it stay listed
        updateFilteredLists();
      }
    }

    if (type === 'report') {
      setState({ selectedAvailableReportsList: updatedList });
      updateURL({ page: 1, reports: updatedList, search: searchInput });
    } else if (type === 'file') {
      setState({ selectedTrustCenterFilesList: updatedList });
      updateURL({ page: 1, files: updatedList, search: searchInput });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    updateURL({ page: 1, search: searchInput });
  };

  const handlePageChange = (page) => {
    const updatedPage = pageIsValid(page, totalRecords, pageSize) ? page : 1;
    updateURL({ page: updatedPage, search: searchInput });
  };

  /* ----------------------------------- */
  /*          Set State Logic            */
  /* ----------------------------------- */

  const setParentState = (keyName, value, reloadParent) => {
    setState({ [keyName]: value });

    if (reloadParent) {
      updateURL({ page, orderBy, dir, search, myVendor, reports: selectedAvailableReportsList });
    }
  };

  const setShowModal = (showModal) => {
    setState({
      // requestedVendorDomain: "",
      showModal: showModal,
      topModalState: 'displayVendor',
    });
  };

  const setShowAddVendorModal = (showAddVendorModal) => {
    setState({
      requestedVendorName: search,
      requestedVendorDomain: '',
      showModal: showAddVendorModal,
      topModalState: 'addVendor',
      modalData: {},
    });
  };

  const setMyVendor = (myVendor) => {
    setState({ myVendor: myVendor });

    updateURL({ page: 1, myVendor, search: searchInput });
  };

  const setModalData = (modalData) => {
    setState({
      modalData: modalData,
      requestedVendorName: modalData.name,
      requestedVendorDomain: modalData.domain,
    });
  };

  const updateFilteredLists = () => {
    const searchText = document.getElementById('filterOptions').value.toString().toLowerCase();
    const updatedAvailableReportsList = availableReportsList.filter((row) => {
      return (
        row.name.toString().toLowerCase().includes(searchText) || // Matched based on search text
        selectedAvailableReportsList.some((selectedReport) => selectedReport === row.rawType)
      ); // Include previously selected
    });

    const updatedTrustCenterFilesList = trustCenterFilesList.filter((row) => {
      return (
        row.name.toString().toLowerCase().includes(searchText) || // Matched based on search text
        selectedTrustCenterFilesList.some((selectedFile) => selectedFile === row.name)
      ); // Include previously selected
    });

    setState({
      filteredAvailableReportsList: updatedAvailableReportsList,
      filteredTrustCenterFilesList: updatedTrustCenterFilesList,
    });
  };

  const saveCheckboxCheckedStatus = (checkboxID, checkboxType) => {
    return checkboxType === 'report'
      ? selectedAvailableReportsList.includes(checkboxID)
      : selectedTrustCenterFilesList.includes(checkboxID);
  };

  /* ----------------------------------- */
  /*          Render Logic               */
  /* ----------------------------------- */

  return (
    <CatalogVendorContext.Provider value={contextData}>
      {/* Modal for adding a vendor or displaying a vendor and requesting reports for it */}
      {canPurchaseReports && (
        <ModalStagesContainer
          fetchReportTypes={false}
          mode="vendorCatalog"
          modalData={modalData}
          showModal={showModal}
          topModalState={topModalState}
          setParentState={setParentState}
          onRequestClose={() => setShowModal(false)}
          vendorName={requestedVendorName}
          vendorDomain={requestedVendorDomain}
        />
      )}

      {/* Search Area */}
      <div className="catalog-page">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="catalog-nav">
                <div className="search-title-area">
                  <h3>Network: Assessments &amp; Reports {myVendor === true ? '- My Vendors' : ''}</h3>
                  <p className="catalog-description">Order risk reports on vendors and products.</p>
                </div>
                <CatalogViewSwitch isVendorView={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="catalog-content">
          <div className="catalog-sidebar-wrapper">
            <div className="catalog-sidebar">
              <div className="show-my-vendors-container">
                <div>
                  <div className="my-vendors-toggle">
                    <ToggleSwitch
                      disabled={isBusy}
                      checked={!!myVendor}
                      onChange={() => setMyVendor(!myVendor)}
                      id="catalog-my-vendors-toggle-switch"
                    />
                  </div>
                  <label htmlFor="material-switch" className="catalog-my-vendor-switch-btn-label">
                    Show My Vendors
                  </label>
                </div>
              </div>
              <form className="form-inline">
                <div className="input-group mb-3">
                  <input
                    className="form-control catalog-search-input"
                    type="text"
                    value={searchInput}
                    placeholder="Search Vendors"
                    onChange={(e) => setState({ searchInput: e.target.value })}
                  />
                  <div className="input-group-append">
                    <button type="submit" className="btn input-group-text" onClick={handleSearch}>
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
              <div className="catalog-search-filters-form">
                <div className="catalog-search-filters-div">
                  <i className="fas fa-search search-icon"></i>
                  <input
                    className="form-control"
                    type="text"
                    id="filterOptions"
                    placeholder="Search Filters"
                    onChange={updateFilteredLists}
                    maxLength={64}
                  />
                </div>

                <p className="catalog-search-filters-checkboxes-sections-title">Risk Reports</p>

                {!!(filteredAvailableReportsList && filteredAvailableReportsList.length) &&
                  filteredAvailableReportsList.map((row, i) => (
                    <form
                      key={'filteredAvailableReportsList-form-' + i}
                      className="catalog-search-filters-checkboxes-form"
                    >
                      <input
                        type="checkbox"
                        id={row.rawType}
                        name={row.rawType}
                        value={row.name}
                        className="catalog-search-filters-checkboxes-input"
                        checked={saveCheckboxCheckedStatus(row.rawType, 'report')}
                        onChange={() => {
                          handleCheckboxFilter(row.rawType, 'report');
                        }}
                      />
                      <label htmlFor={row.rawType} className="catalog-search-filters-checkboxes-label">
                        {row.name}
                      </label>
                    </form>
                  ))}

                {/* <p className="catalog-search-filters-checkboxes-sections-title">Trust Center Files</p>

              {!!(filteredTrustCenterFilesList && filteredTrustCenterFilesList.length) &&
                filteredTrustCenterFilesList.map((row, i) => (
                  <form
                    key={'filteredTrustCenterFilesList-form-' + i}
                    className="catalog-search-filters-checkboxes-form"
                  >
                    <input
                      type="checkbox"
                      id={row.name}
                      name={row.name}
                      value={row.name}
                      className="catalog-search-filters-checkboxes-input"
                      checked={saveCheckboxCheckedStatus(row.name, 'file')}
                      onChange={() => {
                        handleCheckboxFilter(row.name, 'file');
                      }}
                    />
                    <label htmlFor={row.name} className="catalog-search-filters-checkboxes-label">
                      {row.name}
                    </label>
                  </form>
                ))} */}
              </div>
            </div>
          </div>
          <div className="vendor-list">
            {/* Vendor list with paginate */}
            <Busy isBusy={isBusy}>
              {page === 1 && popularVendors && popularVendors.length > 0 && (
                <>
                  <h4>Most Popular Vendors</h4>
                  <div className="row cards-bottom-space">
                    {popularVendors.map((row, i) => (
                      <VendorCard
                        key={i}
                        data={row}
                        onClick={(event) => handleCardClick(event, canPurchaseReports, row)}
                      />
                    ))}
                  </div>
                </>
              )}
              {vendors && vendors.length > 0 && <h4>All Vendors</h4>}
              <div className="row catalog-list-cards-div">
                {!!(canAddVendors && search && !myVendor) && (
                  <NewItemCard caption="Add Vendor" name={search} onClickHandler={() => setShowAddVendorModal(true)} />
                )}
                {vendors && vendors.length === 0 && (
                  <p className="ml-3">
                    There are no vendors {myVendor ? 'within - My Vendors -' : ''}
                    {(selectedAvailableReportsList && selectedAvailableReportsList.length) ||
                    (selectedTrustCenterFilesList && selectedTrustCenterFilesList.length)
                      ? ' with all currently selected options'
                      : ''}
                    {search ? ` that contain the search term '${search}'` : ``}.
                  </p>
                )}
                {vendors &&
                  vendors.map((row, i) => (
                    <VendorCard
                      key={i}
                      data={row}
                      onClick={(event) => handleCardClick(event, canPurchaseReports, row)}
                    />
                  ))}
              </div>
              <div className="paginate-holder">
                <Pagination
                  className="catalog-paginate"
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  onPageChange={(page) => handlePageChange(page)}
                  visible={7}
                />
              </div>
            </Busy>
          </div>
        </div>
      </div>
    </CatalogVendorContext.Provider>
  );
};

export default withPrincipal(Catalog);
