import React from 'react';
import Modal from 'react-modal';
import { Busy, useUpdateState } from '..';
import './modalStyles.css';

const UploadResponseModal = (props) => {
  // Props
  const { isOpen = false, onModalClose = () => {}, processingUpload = false, uploadResponse = {} } = props;

  const initialState = {
    uploadSuccessful: false,
    warningsPresent: false,
    errorsPresent: false,
    showWarnings: false,
    showErrors: false,
    warningsString: '',
    errorsString: '',
  };

  // State
  const [state, setState] = useUpdateState(initialState);

  const { uploadSuccessful, showWarnings, showErrors, warningsPresent, errorsPresent, warningsString, errorsString } =
    state;

  const handleShowWarnings = () => {
    setState({ showWarnings: true });
  };

  const handleShowErrors = () => {
    setState({ showErrors: true });
  };

  const handleModalClose = () => {
    setState(initialState);
    onModalClose();
  };

  React.useEffect(() => {
    let { isSuccess, warnings, error, message } = uploadResponse;

    if (isSuccess) {
      setState({ uploadSuccessful: true });
    } else {
      setState({ uploadSuccessful: false });
      error = error || message;
    }

    if (warnings) {
      setState({
        warningsPresent: true,
        warningsString: warnings,
      });
    }

    if (error) {
      setState({
        errorsPresent: true,
        errorsString: error,
      });
    }
  }, [uploadResponse]);

  return (
    <Modal
      isOpen={isOpen}
      className="upload-response-modal"
      ariaHideApp={false}
      contentLabel="Upload Response"
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleModalClose}
    >
      <>
        <div className="up-res-modal-inner">
          <Busy isBusy={processingUpload}>
            {uploadSuccessful ? (
              <div className="up-res-modal-inner">
                <button className="btn-no-show btn-right-side" onClick={handleModalClose}>
                  <div className="modal-exit-btn"></div>
                </button>
                <div className="modal-success-big"></div>
                <p className="modal-response-main-txt">Upload Successful</p>
                {warningsPresent ? (
                  <>
                    {showWarnings ? (
                      <div className="color-box cb-warnings">
                        <p>{warningsString}</p>
                      </div>
                    ) : (
                      <button className="btn-no-show btn-undl btn-warnings" onClick={handleShowWarnings}>
                        Review Warnings
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="up-res-modal-inner">
                <button className="btn-no-show btn-right-side" onClick={handleModalClose}>
                  <div className="modal-exit-btn"></div>
                </button>
                <div className="modal-failure-big"></div>
                <p className="modal-response-main-txt">Failure to Upload</p>
                {errorsPresent ? (
                  <>
                    {showErrors ? (
                      <div className="color-box cb-errors">
                        <p>{errorsString}</p>
                      </div>
                    ) : (
                      <button className="btn-no-show btn-undl btn-errors" onClick={handleShowErrors}>
                        Review Errors
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </Busy>
          {processingUpload ? (
            <div className="committing-changes-txt section-inner-title animated-ellipsis">Uploading</div>
          ) : (
            <></>
          )}
        </div>
      </>
    </Modal>
  );
};

export default UploadResponseModal;
