import React, { useState } from 'react';
import ModalConfirmation from './modalConfirmation';

const SafeHyperlink = (props) => {
  const { url, children } = props;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleAnchorClick = (event) => {
    event.preventDefault();
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const loadUrlInNewWindow = () => {
    closeConfirmation();
    window.open(url, '_blank');
  };

  return (
    <>
      <a href={url} target="_blank" rel="noopener noreferrer" onClick={handleAnchorClick}>
        {children}
      </a>
      <ModalConfirmation
        isOpen={isConfirmationOpen}
        confirmationText="You are about to leave A2V. Do you want to proceed?"
        onClickYes={loadUrlInNewWindow}
        onClickNo={closeConfirmation}
      />
    </>
  );
};

export default SafeHyperlink;
