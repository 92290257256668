import React, { useEffect } from 'react';
import { Chart as ChartJS } from 'chart.js';
// eslint-disable-next-line no-unused-vars
import * as radial from 'chartjs-chart-radial-gauge'; //Import required for the chart to work

import './inherentRiskScoreGauge.css';

export const inherentRiskScoreGauge = ({ gaugeValue, scoreText }) => {
  const chartElementId = 'chart-area';

  const initializeChart = (canvasElementId, valueString, text) => {
    if (!valueString) return; //renders twice since dependency array in useEffect is provided out of sync

    // This code is 95% a copy from radialGauge.js in the postern DDRR source
    ChartJS.defaults.global.defaultFontFamily = 'Verdana';
    let color = '#53c653';
    let ctx = document.getElementById(canvasElementId).getContext('2d');
    let value = parseInt(valueString.replace(',', ''));

    if (value > 1800) {
      value = 1800;
      valueString = '1,800';
      color = '#bc2710';
    } else if (value >= 1000) color = '#bc2710';
    else if (value >= 820) color = '#ef5a43';
    else {
      color = ctx.createLinearGradient(0, 0, 0, 600);
      color.addColorStop(0, '#53c653');
      color.addColorStop('0.35', '#ffcc66');
      color.addColorStop('0.41', '#ef5a43');
      color.addColorStop(1, '#bc2710');
    }

    var config = {
      type: 'radialGauge',
      data: {
        // labels: ["Low"],
        datasets: [
          {
            data: [value],
            backgroundColor: [color],
            borderWidth: 0,
            label: 'Score',
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        events: [], //Hovering will clear the data for some reason so I disabled all events
        domain: [0, 1500],
        centerPercentage: 80,
        roundedCorners: true,
        centerArea: {
          text: valueString,
          subText: text,
        },
      },
    };
    new ChartJS(ctx, config);
  };

  useEffect(() => {
    initializeChart(chartElementId, gaugeValue, scoreText);
  }, [gaugeValue, scoreText]);

  return (
    <div>
      <canvas id={chartElementId}></canvas>
    </div>
  );
};

export default inherentRiskScoreGauge;
