import React from 'react';
import SeverityLabel from './vendor/severityLabel';
import RankTile from './rankTile';
import Circle from './circle';
import { riskRankColorNameByTier, riskRankColorNameByGrade } from '../constants';

const SectionSummary = (props) => {
  const {
    showRankTile = false,
    avgScoreName,
    avgScoreValue,
    tierName,
    tierValue,
    tierMaxValue,
    scoreInfo,
    scoresData = [],
    className = '',
    viewDashboardOnClick,
    componentContext,
  } = props;

  const incompleteDDRR = (!avgScoreValue || !tierValue) && componentContext === 'ddrr';
  // ^ more of these can be added in future, with different context (i.e. componentContext === "foreign-influence")
  // ^ This logic should be outside of this component. It only needs to know if info should be displayed when data is not ready.

  const scoreColor = isNaN(avgScoreValue)
    ? riskRankColorNameByGrade(avgScoreValue)
    : riskRankColorNameByTier(avgScoreValue);

  const tierToInfoString = (tier) => {
    switch (true) {
      case tier == 1:
        return 'A Tier 1 vendor poses the highest business impact to clients when a vendor is breached or unavailable. Based on our OSINT analysis, this vendor has a critical inherent risk and should be assessed first.';
      case tier == 2:
        return 'A Tier 2 vendor poses a high business impact to clients when a vendor is breached or unavailable. Based on our OSINT analysis, this vendor has a high inherent risk and should be assessed along with Tier 1 vendors.';
      case tier == 3:
        return 'A Tier 3 vendor poses a moderate business impact to clients when a vendor is breached or unavailable. Based on our OSINT analysis, this vendor has a moderate inherent risk and should be assessed after Tier 1 & 2 vendor risk is assessed.';
      case tier == 4:
        return 'A Tier 4 vendor poses a low business impact to clients when a vendor is breached or unavailable. Based on our OSINT analysis, this vendor has a low inherent risk and should be assessed last.';
      default:
        return 'Multiple issues were identified, suggesting the vendor has a lackluster security posture overall.';
    }
  };
  return (
    <div className={'summary-section-container ' + className}>
      {incompleteDDRR && componentContext === 'ddrr' ? (
        <>
          <div className="section-inner-title animated-ellipsis">Currently Processing</div>
          {/* <div className="section-info-text">[ add this if you want to give user more context ]</div>  */}
        </>
      ) : (
        <>
          <div className="summary-section-average-score">
            {!!(avgScoreName && avgScoreValue !== undefined) && (
              <>
                <div>
                  <b>{avgScoreName}</b>
                </div>
                {showRankTile ? <RankTile caption="Tier" value={avgScoreValue}></RankTile> : null}
              </>
            )}
            {tierValue !== undefined && tierMaxValue !== undefined && (
              <>
                <div>
                  <b>{tierName}</b>
                </div>
                <Circle color={scoreColor} currentValue={tierValue} maxValue={tierMaxValue} footnote={''} small />
              </>
            )}
          </div>
          <div className="summary-section-details">
            <ul>
              {Array.isArray(scoresData) &&
                scoresData.length > 0 &&
                scoresData.map((item, index) => {
                  return (
                    <li key={index}>
                      {item.label}:<SeverityLabel label={item.value} smaller />
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="summary-section-info">
            {!!scoreInfo && scoreInfo}
            <p>{avgScoreValue && tierToInfoString(avgScoreValue)}</p>
            {typeof viewDashboardOnClick === 'function' && (
              <div className="text-center">
                <button type="button" className="btn btn-warning" onClick={viewDashboardOnClick}>
                  View Dashboard
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SectionSummary;
