import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import UserList from './userList';
import UserDetails from '../users/userDetails';

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      listModel: [],
      username: '',
    };
  }

  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={() => <Redirect to={`${this.props.match.url}/email_address/asc/1`} />}
        />
        <Route exact path={`${this.props.match.url}/:orderBy/:dir/:page`} component={UserList} />
        <Route exact path={`${this.props.match.url}/:id`} component={UserDetails} />
      </React.Fragment>
    );
  }
}

export default Users;
