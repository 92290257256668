import React, { Component } from 'react';
import parse from 'html-react-parser';

import './severityCircle.css';

export default class SeverityCircle extends Component {
  render() {
    const { symbol = '&#33;', color = '#000', customStyles = {}, customClasses = '' } = this.props;
    return (
      <div className={'severity-Circle ' + customClasses} style={{ backgroundColor: color, ...customStyles }}>
        {parse(symbol)}
      </div>
    );
  }
}
//&#10003; check mark
//&#10004; heavy check mark
//&#33; exclamation mark
//&#8252; double bang
