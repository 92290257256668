import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import SubscriberList from './subscriberList';
import SubscriberDetails from './subscriberDetails';

class Subscribers extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={`${this.props.match.url}`}
          component={() => <Redirect to={`${this.props.match.url}/name/asc/1`} />}
        />
        <Route exact path={`${this.props.match.url}/:orderBy/:dir/:page`} component={SubscriberList} />
        <Route exact path={`${this.props.match.url}/:id`} component={SubscriberDetails} />
      </React.Fragment>
    );
  }
}

export default Subscribers;
