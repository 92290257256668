import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import InitialModeContent from './initialTargetGroupModalContent';
import AddModeContent from './addTargetGroupModalContent';
import EditModeContent from './editTargetGroupModalContent';
import { getTargetGroupsByProviderId, getAllAssetsByProviderId, deleteTargetGroup } from './targetGroupService';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default (props) => {
  const [scannedAssets, setScannedAssets] = useState(null);
  const [targetGroups, setTargetGroups] = useState(null);
  const [mode, setMode] = useState('');
  const [selected, setSelected] = useState({});

  useEffect(() => {
    async function get() {
      let assets = await getAllAssetsByProviderId(props.providerId);
      setScannedAssets(assets);
    }
    get();
  }, []);

  // get target groups when you open modal
  useEffect(() => {
    async function get() {
      let data = await getTargetGroupsByProviderId(props.providerId);
      setTargetGroups(data);
    }
    get();
  }, [props.show]);

  const closeModal = () => {
    props.toggleModal();
    setMode('');
    setSelected({});
  };

  const handleDeleteTargetGroup = () => {
    const selectedTargetGroupId = targetGroups.filter((tg) => tg.name === Object.keys(selected)[0])[0].id;
    deleteTargetGroup(selectedTargetGroupId);
    closeModal();
  };

  return (
    <>
      <button
        className="btn btn-primary mr-2"
        disabled={!targetGroups && !scannedAssets}
        onClick={() => props.toggleTargetGroupsModal()}
      >
        Manage Target Groups
      </button>
      <Modal style={modalStyle} isOpen={props.show} onRequestClose={closeModal}>
        <div className="modal-header">
          <h5 className="pt-1 modal-title" style={{ color: '#18bc9c' }}>
            Manage Target Groups
          </h5>
          <button type="button" className="btn btn-light" onClick={closeModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="row">
          {mode === '' && (
            <>
              <button type="button" className="btn btn-light m-1" onClick={() => setMode('add')}>
                Add
              </button>
              <button
                type="button"
                className="btn btn-light m-1"
                disabled={Object.keys(selected).length !== 1}
                onClick={() => setMode('edit')}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-light m-1"
                disabled={Object.keys(selected).length !== 1}
                onClick={() => handleDeleteTargetGroup()}
              >
                Delete
              </button>
            </>
          )}
          {mode !== '' && (
            <button type="button" className="btn btn-light m-1" onClick={() => setMode('')}>
              Back
            </button>
          )}
        </div>
        <div className="row">
          {mode === '' && (
            <InitialModeContent targetGroups={targetGroups} selected={selected} setSelected={setSelected} />
          )}
          {mode === 'add' && (
            <AddModeContent
              assets={scannedAssets}
              providerId={props.providerId}
              setScannedAssets={setScannedAssets}
              closeModal={closeModal}
            />
          )}
          {mode === 'edit' && (
            <EditModeContent
              selectedTargetGroup={selected}
              targetGroups={targetGroups}
              assets={scannedAssets}
              providerId={props.providerId}
              setScannedAssets={setScannedAssets}
              closeModal={closeModal}
            />
          )}
        </div>
      </Modal>
    </>
  );
};
