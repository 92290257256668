import React from 'react';
import { HexagonView } from './hexagonView';

const OverallScoresByCategory = (props) => {
  const { scores = {} } = props;

  return (
    <>
      <div
        className="card-body text-center"
        style={{
          color: '#576685',
          fontSize: '14px',
          fontWeight: '500',
          height: '400px',
        }}
      >
        <div className="" style={{ position: 'relative', top: '125px', right: '85px' }}>
          <HexagonView path="dependency" scores={scores} />
        </div>
        <div className="" style={{ position: 'relative', top: '-25px', right: '0px' }}>
          <HexagonView path="overall" scores={scores} />
        </div>
        <div className="" style={{ position: 'relative', top: '-25px', left: '0px' }}>
          <HexagonView path="integrity" scores={scores} />
        </div>
        <div className="" style={{ position: 'relative', top: '-275px', left: '85px' }}>
          <HexagonView path="vulnerability" scores={scores} />
        </div>
        <div className="" style={{ position: 'relative', top: '-275px', left: '85px' }}>
          <HexagonView path="malware" scores={scores} />
        </div>
        <div className="" style={{ position: 'relative', top: '-275px', left: '85px' }}>
          <HexagonView path="foci" scores={scores} />
        </div>
      </div>
    </>
  );
};
export default OverallScoresByCategory;
