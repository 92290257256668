import React from 'react';

function ComplianceTable(props) {
  const { children, headers = [], includedHeaders = '', excludedHeaders = '', tableName = '' } = props;

  return (
    <table className={'compliance-table'}>
      <thead className="compliance-thead">
        <tr>
          {headers.map((header, i) => {
            return (
              <th scope="col" key={tableName + i} className={header[1] ? includedHeaders : excludedHeaders}>
                {header[0]}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

export default ComplianceTable;
