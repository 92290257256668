import React from 'react';
import { generateColor, generateSymbol } from '../../../constants/postern/riskIconGenerator';
import Busy from '../../busy';
import SeverityCircle from '../../postern/reusable/severityCircle';
import SectionContainer from '../../sectionContainer';
import ForeignInfluenceMap from '../foreignInfluenceMap';
import ForeignInfluenceTable from '../foreignInfluenceTable';
import SafeHyperlink from '../../safeHyperlink';

/** Functional component for displaying the physical pressence portion of Foreign Influence data */
const PhysicalPresence = (props) => {
  //Props
  const { physicalPresence = [], isLoading, isActive } = props;

  //Render
  return (
    <Busy isBusy={isLoading}>
      <SectionContainer label="Physical Locations" className="row fi-section-container">
        <ForeignInfluenceTable
          tableName="PhysicalLocationsTable-"
          headers={['Violation', 'Country', 'Cities', 'Evidence']}
        >
          {physicalPresence.length > 0 ? (
            physicalPresence.map((row, i) => {
              return (
                <tr className="provenance-table-row" key={'PhysicalLocationsTable-' + i}>
                  <th className="row-padding" scope="row">
                    {' '}
                    <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                  </th>
                  <td className="country-display">{row.country}</td>
                  <td>{row.city}</td>
                  <td>
                    <SafeHyperlink url={row.evidence}>{row.evidence}</SafeHyperlink>{' '}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="provenance-table-row-empty" key={'PhysicalLocationsTable-empty'}>
              <td colSpan={5}>
                <p>No Findings Identified</p>
              </td>
            </tr>
          )}
        </ForeignInfluenceTable>
      </SectionContainer>
      <div className="fi-summary-text">
        {Object.keys(physicalPresence).length > 0 ? (
          <p>
            During our review of the physical pressence, some were found in countries of adversarial presence. Further
            investigation concludes that the foreign influence from a physical pressence is likely significant.
          </p>
        ) : (
          <p>During our review of the physical pressence, none were found in countries of adversarial presence.</p>
        )}
      </div>
      <SectionContainer label="Physical Locations" className="fi-section-container fi-map">
        <ForeignInfluenceMap physicalPresence={physicalPresence} displayIfEmpty={!isLoading && isActive} />
      </SectionContainer>
    </Busy>
  );
};

export default PhysicalPresence;
