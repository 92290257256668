import React, { useEffect } from 'react';
import { useUpdateState } from '../..';

import './nav.css';

export const Nav = (props) => {
  const [state, setState] = useUpdateState({
    navOptions: [],
  });

  useEffect(() => setState({ navOptions: props.navOptions }), [props.navOptions]);

  const expandSubList = (i, expand) => {
    setState((state) => {
      let { navOptions } = state;

      navOptions[i].isExpanded = expand;

      return {
        ...state,
        navOptions,
      };
    });
  };

  const scrollToSection = function (e, id) {
    e.preventDefault();
    let elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView();
    }
  };

  // navOptions = [
  //   {
  //       sectionNumber: The number of the section EX:"I",
  //      name: The name of the section EX: "Vendor Information",
  //      idToScrollTo: the id of the element to scroll to EX: "vendor-info",
  //      hasSubOptions: If this nav option has a a subset of sections EX: true,
  //      subOptionArray: An array of strings that are the sub options EX: ["a","b","c","d"],
  //      subOptionGroupNumber: the grouping of the sub options EX: "1",
  //      isExpanded: if the subOption list is expanded EX: true
  //   }
  // ]

  const { navOptions } = state;

  return (
    <div id="postern-nav">
      <div className="nav-fortress-logo-container">
        <img className="nav-fortress-logo" src="/static/images/fis-logo.svg" alt="Fortress Information Security Logo" />
      </div>
      <nav>
        <p className="p-toc-title print-hide">Table of Contents</p>
        <ul className="p-nav">
          {navOptions.map((navOption, i_nav) => {
            return (
              <React.Fragment key={navOption.idToScrollTo + i_nav}>
                <li>
                  <a
                    className="p-nav-link"
                    href={'#' + navOption.idToScrollTo}
                    onClick={(e) => scrollToSection(e, navOption.idToScrollTo)}
                  >
                    {' '}
                    {navOption.excludePrefix ? '' : navOption.sectionNumber + '.'} {navOption.name}
                  </a>
                  {navOption.hasSubOptions === true ? (
                    <span>
                      {navOption.isExpanded === true ? (
                        <img
                          onClick={() => expandSubList(i_nav, false)}
                          className="expand-chevron-more"
                          src="static/images/expand_more-24px.svg"
                          alt="expand-chevron-more"
                        />
                      ) : (
                        <img
                          onClick={() => expandSubList(i_nav, true)}
                          className="expand-chevron-less"
                          src="static/images/expand_less-24px.svg"
                          alt="expand-chevron-less"
                        />
                      )}
                    </span>
                  ) : (
                    ''
                  )}
                </li>
                {navOption.hasSubOptions === true ? (
                  <div
                    id={'sub-list-' + navOption.subOptionGroupNumber}
                    className={'expandable-nav-list ' + (navOption.isExpanded === true ? 'p-show' : 'p-hide')}
                  >
                    <ul>
                      {navOption.subOptionArray.map((subOption, i) => {
                        return (
                          <li key={subOption + i} className="p-sub-nav-item">
                            <a
                              className="p-nav-link"
                              href={'#sub-list-' + navOption.subOptionGroupNumber + '-' + i}
                              onClick={(e) =>
                                scrollToSection(e, 'sub-list-' + navOption.subOptionGroupNumber + '-' + i)
                              }
                            >
                              {subOption}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ''
                )}
              </React.Fragment>
            );
          })}
        </ul>
        <p className="p-nav-bottom-text">
          <b>Confidential |</b> Fortress Information Security{' '}
        </p>
      </nav>
    </div>
  );
};

export default Nav;
