import React from 'react';

import Flex from '../../components/flex';
import config from '../../config';
import withPrincipal from '../../components/withPrincipal';
import { toastError, toastSuccess } from '../../lib/utils';

class YourInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      title: '',
      phone: '',
      company: '',
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getUserData = () => {
    const { id } = this.props.principal;
    fetch(config.api.urlFor('userDetails', { id }))
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, data: user, message } = response;

        if (isSuccess) {
          this.setState({
            user,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.emailAddress || '',
            company: user.company || '',
            phone: user.phone || '',
            title: user.title || '',
          });
        } else if (message) {
          toastError(message);
        }
      })
      .catch(() => {});
  };

  updateUserData = () => {
    const { firstName, lastName, email, title, phone, company } = this.state;
    fetch(config.api.urlFor('userSelf'), {
      method: 'PUT',
      body: {
        first_name: firstName,
        last_name: lastName,
        email_address: email,
        title,
        phone,
        company,
      },
    })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          toastSuccess('Data saved successfully');
        } else if (message) {
          toastError(message);
        }
      })
      .catch(() => toastError());
  };

  render() {
    const { firstName, lastName, email, title, phone, company } = this.state;
    return (
      <Flex flexDirection="column">
        <h1>Your Info</h1>
        <Flex className="p-3 border" flexWrap="wrap">
          <div className="m-3" style={{ width: '30%' }}>
            <label htmlFor="firstName">First Name</label>
            <input
              name="firstName"
              type="text"
              className="form-control"
              onChange={this.handleInputChange}
              value={firstName}
              placeholder="ex: John"
            />
          </div>
          <div className="m-3" style={{ width: '30%' }}>
            <label htmlFor="lastName">Last Name</label>
            <input
              name="lastName"
              type="text"
              className="form-control"
              onChange={this.handleInputChange}
              value={lastName}
              placeholder="ex: Doe"
            />
          </div>
          <div className="m-3" style={{ width: '30%' }}>
            <label htmlFor="email">Email Address</label>
            <input
              name="email"
              type="text"
              className="form-control"
              onChange={this.handleInputChange}
              value={email}
              placeholder="ex: john@doe.com"
            />
          </div>
          <div className="m-3" style={{ width: '30%' }}>
            <label htmlFor="title">Title</label>
            <input
              name="title"
              type="text"
              className="form-control"
              onChange={this.handleInputChange}
              value={title}
              placeholder="ex: CEO"
            />
          </div>
          <div className="m-3" style={{ width: '30%' }}>
            <label htmlFor="phone">Phone</label>
            <input
              name="phone"
              type="text"
              className="form-control"
              onChange={this.handleInputChange}
              value={phone}
              placeholder="ex: 888-888-8888"
            />
          </div>
          <div className="m-3" style={{ width: '30%' }}>
            <label htmlFor="company">Company</label>
            <input
              name="company"
              type="text"
              className="form-control"
              onChange={this.handleInputChange}
              value={company}
              placeholder="ex: Fortress Information Security"
            />
          </div>
        </Flex>
        <Flex flexDirection="row-reverse" className="p-3">
          <button className="btn btn-primary" onClick={() => this.updateUserData()}>
            Update
          </button>
        </Flex>
      </Flex>
    );
  }
}

export default withPrincipal(YourInfo);
