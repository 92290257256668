import React, { Component } from 'react';

import './backgroundColorString.css';

export default class BackgroundColorString extends Component {
  render() {
    const { string = '', color = '#fff', bgColor = '#000' } = this.props;
    return (
      <span className="postern-background-color-string" style={{ backgroundColor: bgColor, color: color }}>
        {string}
      </span>
    );
  }
}
