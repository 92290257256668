// risk ranks
export const INFORMATIONAL = 'Informational';
export const MARGINAL = 'Marginal';
export const LOW = 'Low';
export const MODERATE = 'Moderate';
export const HIGH = 'High';
export const CRITICAL = 'Critical';
export const PENDING = 'Pending';

// risk tiers
export const TIER_ONE = '1';
export const TIER_TWO = '2';
export const TIER_THREE = '3';
export const TIER_FOUR = '4';
export const TIER_UNDEFINED = '-';

export const GRADE_A = 'A';
export const GRADE_B = 'B';
export const GRADE_C = 'C';
export const GRADE_D = 'D';
export const GRADE_F = 'F';
export const GRADE_NA = 'N/A';

export const MARGINALRANGE = '(> 90)';
export const LOWRANGE = '(80 - 89)';
export const MODERATERANGE = '(70 - 79)';
export const HIGHRANGE = '(60 - 69)';
export const CRITICALRANGE = '(< 60)';

export const riskRankByScore = (score) => {
  return score
    ? score > 89
      ? MARGINAL
      : score > 79
      ? LOW
      : score > 69
      ? MODERATE
      : score > 59
      ? HIGH
      : CRITICAL
    : PENDING;
};

export const riskGradeByScore = (score) => {
  return score
    ? score > 89
      ? GRADE_A
      : score > 79
      ? GRADE_B
      : score > 69
      ? GRADE_C
      : score > 59
      ? GRADE_D
      : GRADE_F
    : GRADE_NA;
};

export const severityByScore = (score) => {
  return score && score >= 0 ? (score >= 80 ? LOW : score >= 70 ? MODERATE : score >= 60 ? HIGH : CRITICAL) : PENDING;
};

export const riskTierBySeverity = (severity) => {
  return severity
    ? severity === LOW || severity === LOW.toLowerCase()
      ? TIER_FOUR
      : severity === MODERATE || severity === MODERATE.toLowerCase()
      ? TIER_THREE
      : severity === HIGH || severity === HIGH.toLowerCase()
      ? TIER_TWO
      : TIER_ONE
    : TIER_UNDEFINED;
};

export const withRange = (rank) => {
  switch (rank) {
    case MARGINAL:
      return MARGINAL + ' ' + MARGINALRANGE;
    case LOW:
      return LOW + ' ' + LOWRANGE;
    case MODERATE:
      return MODERATE + ' ' + MODERATERANGE;
    case HIGH:
      return HIGH + ' ' + HIGHRANGE;
    case CRITICAL:
      return CRITICAL + ' ' + CRITICALRANGE;
    default:
      return;
  }
};
