import React, { useEffect } from 'react';
import { useUpdateState, Busy, Spinner } from '..';
import CatalogReportGenericInfo from '../vendor/catalog/catalogReportGenericInfo';
import CatalogDropZone from '../vendor/catalog/catalogDropZone';
import ProductReportBox from '../vendor/catalog/productReportBox';
import { assessmentTypes } from '../../constants/assessmentTypes';

const NUM_OF_COLS = 2;

const ProductOrderReportsModal = (props) => {
  const [state, setState] = useUpdateState({
    hasErrors: {
      hasReport: false,
      productName: false,
      productVersionOrNumber: false,
    },
    requestedProductReports: props.requestedProductReports || {
      requestedProductArray: [],
    },
    isBusy: false,
    selectedReports: props.selectedReports || {},
    productName: props.productName || '',
    productVersionOrNumber: props.productVersionOrNumber || '',
    currentFiles: [],
    isSBOMselected: false,
  });

  const {
    productReports,
    reportDescriptions,
    productID,
    setNextModalStage = () => {},
    allowMultipleProducts,
    setParentState = () => {},
  } = props;

  useEffect(() => {
    setState({ productName: props.productName });
  }, [props.productName]);

  useEffect(() => {
    if (props.productVersionOrNumber) {
      setState({ productVersionOrNumber: props.productVersionOrNumber });
    }
  }, [props.productVersionOrNumber]);

  const {
    hasErrors,
    isBusy,
    selectedReports,
    productName,
    productVersionOrNumber,
    currentFiles,
    isSBOMselected,
    requestedProductReports,
  } = state;

  const handleCheckbox = (keyName) => {
    setState((state) => {
      const setValueTo = selectedReports[`${keyName}`] ? 0 : 1;
      const updatedSelectedReports = { ...selectedReports, [`${keyName}`]: setValueTo };

      setParentState('selectedReports', updatedSelectedReports);

      const isSBOMselected =
        Object.keys(updatedSelectedReports).filter(
          (report) => assessmentTypes.SBOM.find((s) => s.name === report) && updatedSelectedReports[report] === 1,
        ).length > 0;

      return {
        ...state,
        selectedReports: updatedSelectedReports,
        isSBOMselected,
        hasErrors: {
          ...hasErrors,
          productVersionOrNumber: !isSBOMselected ? false : hasErrors.productVersionOrNumber,
        },
      };
    });
  };

  const handleAddedProducts = (i, reportTypesArray) => {
    let updatedRequestedProductReports = requestedProductReports;

    reportTypesArray.map((reportType) => {
      updatedRequestedProductReports[reportType]--;
    });

    updatedRequestedProductReports.requestedProductArray.splice(i, 1);

    setState({ requestedProductReports: updatedRequestedProductReports });

    setParentState('requestedProductReports', updatedRequestedProductReports);
  };

  const handleInputBox = (e, keyName) => {
    const val = e.target.value;
    setState({ [keyName]: val });
    setParentState(keyName, val);
  };

  const setCurrentFiles = (value) => {
    setState({ currentFiles: value });
  };

  const addToProductBox = () => {
    if (!productName || productName.trim() === '') {
      setState({ hasErrors: { productName: true } });
      return;
    }

    if (isSBOMselected && (!productVersionOrNumber || productVersionOrNumber.trim() === '')) {
      setState({ hasErrors: { productVersionOrNumber: true } });
      return;
    }

    let updatedRequestedProductReports = requestedProductReports || {};

    let hasReportChecked = false;
    let arrayOfRequestedReports = [];
    productReports.forEach((reportType) => {
      let addToCount = 0;
      if (selectedReports[reportType]) {
        addToCount = selectedReports[reportType];

        if (addToCount > 0) {
          hasReportChecked = true;
          arrayOfRequestedReports.push(reportType);
        }
      }

      if (updatedRequestedProductReports[reportType]) {
        updatedRequestedProductReports[reportType] += addToCount;
      } else {
        updatedRequestedProductReports[reportType] = addToCount;
      }
    });

    if (hasReportChecked) {
      updatedRequestedProductReports['requestedProductArray'].push({
        productName: productName.trim(),
        productNumber: productVersionOrNumber.trim(),
        fileArray: currentFiles,
        reportsArray: arrayOfRequestedReports,
      });

      setParentState('requestedProductReports', updatedRequestedProductReports);

      setState({
        productName: productName ? productName.trim() : '',
        productVersionOrNumber: productVersionOrNumber.trim() || '',
        currentFiles: [],
        hasErrors: { productName: false, productVersionOrNumber: false },
        requestedProductReports: updatedRequestedProductReports,
      });
    } else {
      setState({
        hasErrors: { noReport: true },
      });
    }
  };

  return (
    <div className="modal-report-info-box modal-mt-override">
      <p className="modal-report-info-title mb-2">Product Reports</p>
      <p className="modal-report-info-description">
        Disclaimer: Turntime of assessments and reports is dependent on availability of assessment or report, and
        pending sharing agreements with company. If the company chooses not to cooperate (for SBOM), we may not be able
        to deliver the assessment.
      </p>

      {hasErrors.hasReport && <p className="m-error">Please select a report to request</p>}
      <Busy isBusy={productReports === undefined}>
        <div className="pb-3">
          <div className="input-title-text">Product Name*</div>
          {hasErrors.productName && <span className="m-error"> Please enter a Product Name </span>}
          <input
            id="product-name"
            className={'form-control modal-s-2-input' + (hasErrors.productName ? ' is-invalid' : '')}
            type="text"
            placeholder="Product Name"
            onChange={(e) => !productID && handleInputBox(e, 'productName')}
            value={productName}
            disabled={productID ? true : false}
            maxLength={255}
          />
        </div>

        <div className="pb-3">
          <div className="input-title-text">{'Model Version / Model Number' + (isSBOMselected ? '*' : '')}</div>
          {hasErrors.productVersionOrNumber && (
            <span className="m-error"> Please enter a Product Model Version or Number </span>
          )}
          <input
            id="product-model-number"
            className={'form-control modal-s-2-input' + (hasErrors.productVersionOrNumber ? ' is-invalid' : '')}
            type="text"
            placeholder="Product Model Version or Number"
            onChange={(e) => handleInputBox(e, 'productVersionOrNumber')}
            value={productVersionOrNumber}
            maxLength={255}
          />
        </div>

        <div className="pb-3">
          <div className="input-title-text">
            Attachments (ex. Documentation, User Manual, Configuration Guides, BOMs)
          </div>
          <div className="catalog-drop-zone">
            <CatalogDropZone setCurrentFiles={setCurrentFiles} />
          </div>
        </div>

        {hasErrors.noReport && <span className="m-error"> Please select a report to request </span>}
        <table id="product-modal-reports-table" className="modal-report-table m-0">
          <thead>
            <tr>
              <th className="modal-report-column-reports">Product Reports</th>
              <th className="modal-report-column-prices">Price</th>
            </tr>
          </thead>
          <tbody>
            {productReports.length ? (
              productReports.map((data, i) => {
                return (
                  <tr key={i}>
                    <td
                      className="modal-report-info text-left"
                      onClick={() => handleCheckbox(data)}
                      tabIndex={0}
                      key={i}
                    >
                      <input
                        className="modal-report-tick-box"
                        type="checkbox"
                        checked={(selectedReports[data] && selectedReports[data] > 0) || false}
                        readOnly={true}
                        tabIndex={-1}
                      />
                      <CatalogReportGenericInfo title={data} reportDescriptions={reportDescriptions} />
                    </td>
                    <td className="modal-report-title">{reportDescriptions[data].price}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={NUM_OF_COLS}>No product reports found</td>
              </tr>
            )}
          </tbody>
        </table>

        {!!allowMultipleProducts && (
          <>
            <button className="add-requested-btn btn" onClick={() => addToProductBox()}>
              Add Requested
            </button>
            <div className="input-title-text">Added Products:</div>
            <ProductReportBox
              requestedProductReports={requestedProductReports}
              handleProductCheckbox={handleAddedProducts}
              canDelete={true}
            />
          </>
        )}

        <div className="mt-5">
          <div className="modal-report-bottom-right">
            <button
              disabled={isBusy || !requestedProductReports || !requestedProductReports.requestedProductArray.length}
              onClick={() => {
                const allowGoingBackwards = true;
                setNextModalStage('confirmation', allowGoingBackwards);
              }}
              className="modal-report-info-btn-stage1 btn"
            >
              Add to Cart
              <Spinner isVisible={isBusy} />
            </button>
          </div>
        </div>
      </Busy>
    </div>
  );
};

export default ProductOrderReportsModal;
