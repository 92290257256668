import React from 'react';
import config from '../../config';
import { Busy, withPrincipal } from '../../components';
import { toast } from 'react-toastify';
import CopyToClipboard from '../../components/copyToClipboard';
import ModalConfirmation from '../../components/modalConfirmation';

class Integrations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: true,
      modalShow: false,
      apiKey: '',
      subscription: this.props.principal ? this.props.principal.subscription : undefined,
    };
  }

  componentDidMount() {
    this.getApiKey();
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modalShow: !prevState.modalShow,
    }));
  };

  getApiKey = () => {
    if (this.state.subscription) {
      this.setState({ isBusy: true });

      const url = config.api.urlFor('subscriberApiKey');

      fetch(url, { method: 'GET' })
        .then((res) => res.json())
        .then((data) => {
          this.setState({ isBusy: false, apiKey: data ? data : '' });
        })
        .catch(() => {
          this.setState({ isBusy: false });
          this.showError('Retrieving the API key failed.');
        });
    }
  };

  createApiKey = () => {
    if (this.state.subscription) {
      this.setState({ isBusy: true, modalShow: false });

      let errorMessage = '';
      const url = config.api.urlFor('subscriberApiKey');

      fetch(url, { method: 'POST' })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.apiKey) this.setState({ isBusy: false, apiKey: data.apiKey });
          else {
            this.setState({ isBusy: false });
            errorMessage = 'API key creation failed. Reloading data.';
          }
        })
        .catch(() => {
          this.setState({ isBusy: false });
          errorMessage = 'API key creation failed. Refreshing data.';
        })
        .then(() => {
          if (errorMessage) {
            this.showError(errorMessage);
            this.getApiKey();
          }
        });
    }
  };

  prepareForApiKeyCreation = () => {
    const { apiKey } = this.state;

    if (!apiKey) this.createApiKey();
    else this.toggleModal();
  };

  showError(errMsg) {
    toast.error(errMsg ? errMsg : 'Error!', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
    });
  }

  render() {
    const { apiKey, isBusy, modalShow, subscription } = this.state;

    return (
      <div className="px-5">
        <h4>Integration</h4>
        <div>Manage your API keys connecting to external applications</div>
        <br />
        <br />

        {subscription ? (
          <>
            <div>
              <div>
                <b>Fortress Platform</b>
              </div>
              <div className="px-5">
                <Busy isBusy={isBusy}>
                  <CopyToClipboard text={apiKey} successMessage="API Key copied to Clipboard" timeout={2000} />
                </Busy>
                <button className="btn btn-danger mb-3" onClick={this.prepareForApiKeyCreation}>
                  Generate New Key
                </button>
                <div>Step 1. Copy your API key from the field above.</div>
                <div>
                  Step 2. Log into Fortress Platform as an Administrator, and navigate to your Administration -&gt;
                  Subscription page.
                </div>
                <div>Step 3. Navigate to the API Section of the Page and paste your key into the API field.</div>
              </div>
            </div>

            <ModalConfirmation
              isOpen={modalShow}
              confirmationText="Confirm creation of a new API key?"
              onClickYes={this.createApiKey}
              onClickNo={this.toggleModal}
            />
          </>
        ) : (
          <div>
            <h4 className="text-center align-middle">
              This user does not have a subscriber. A subscription is required for an API key.
            </h4>
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default withPrincipal(Integrations);
