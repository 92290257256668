/* eslint-disable react/display-name */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';

import { CsvDownloadButton, withPrincipal } from '../../components';

import { urlFromPath } from '../../lib/utils';

import { numericColumnFilter, textColumnFilter, CenterContent } from '../../components/reactTable';

import config from '../../config';

import { tooltip } from '../../tooltips.js';

import { cyberHygieneVulnerabilityColorByScore } from '../../constants';

const badgeStyles = {
  fontSize: '100%',
  minWidth: 80,
};

const columnSpecDetails = () => [
  {
    Header: 'Common Vulnerability & Exposures',
    accessor: 'cve',
    minWidth: 150,
    // Filter: ({ onChange }) => <TextColumnFilter filter={host} onChange={onChange} />,
  },
  {
    Header: 'Common Vulnerability Severity Score',
    accessor: 'cveScore',
    Cell: ({ original: row }) => (
      <CenterContent>
        <span style={{ color: cyberHygieneVulnerabilityColorByScore(row.cveScore), fontWeight: 'bold' }}>
          {row.cveScore} / 10
        </span>
      </CenterContent>
    ),
    width: 300,
    // Filter: textColumnFilter,
  },
  {
    Header: 'Subdomains Impacted',
    accessor: 'subdomainsImpacted',
    minWidth: 300,
    Cell: ({ original: row }) => <>{row.subdomainsImpacted?.join()}</>,
    // Filter: ({ filter, onChange }) => <TextColumnFilter filter={host} onChange={onChange} />,
    // Filter: ({ onChange }) => <TextColumnFilter onChange={onChange} />,
  },
];

const columnSpecProvider = [
  {
    Header: 'Host',
    accessor: 'hostname',
    minWidth: 150,
    Filter: textColumnFilter,
    Cell: ({ original: row }) => <span>{row.hostname}</span>,
  },
  {
    Header: 'Critical',
    accessor: 'critical',
    Cell: ({ original: row }) => <CenterContent>{row.critical}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'High',
    accessor: 'high',
    Cell: ({ original: row }) => <CenterContent>{row.high}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'Medium',
    accessor: 'medium',
    Cell: ({ original: row }) => <CenterContent>{row.medium}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'Low',
    accessor: 'low',
    Cell: ({ original: row }) => <CenterContent>{row.low}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
];

const columnSpecPortfolio = [
  {
    Header: 'Company',
    accessor: 'name',
    Cell: ({ original: row }) => <Link to={`/reports/vulnerabilities/provider/1?id=${row.id}`}>{row.name}</Link>,
    minWidth: 150,
    Filter: textColumnFilter,
  },
  {
    Header: 'Critical',
    accessor: 'critical',
    Cell: ({ original: row }) => <CenterContent>{row.critical}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'High',
    accessor: 'high',
    Cell: ({ original: row }) => <CenterContent>{row.high}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'Medium',
    accessor: 'medium',
    Cell: ({ original: row }) => <CenterContent>{row.medium}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'Low',
    accessor: 'low',
    Cell: ({ original: row }) => <CenterContent>{row.low}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
];

const columnSpecGlobal = [
  {
    Header: 'Company',
    accessor: 'name',
    Cell: ({ original: row }) => <Link to={`/reports/vulnerabilities/provider/1?id=${row.id}`}>{row.name}</Link>,
    minWidth: 150,
    Filter: textColumnFilter,
  },
  {
    Header: 'Critical',
    accessor: 'critical',
    Cell: ({ original: row }) => <CenterContent>{row.critical}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'High',
    accessor: 'high',
    Cell: ({ original: row }) => <CenterContent>{row.high}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'Medium',
    accessor: 'medium',
    Cell: ({ original: row }) => <CenterContent>{row.medium}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
  {
    Header: 'Low',
    accessor: 'low',
    Cell: ({ original: row }) => <CenterContent>{row.low}</CenterContent>,
    width: 150,
    Filter: numericColumnFilter,
  },
];

const parseProps = (props, state) => {
  const {
    match: {
      params: { tier: tierParam },
    },
    location,
  } = props;
  const tier = props.tier || tierParam;

  let id = 0,
    hostname,
    targetGroupName,
    targetGroupId;
  if (location && location.query) {
    id = props.id || location.query.id || id;
    hostname = location.query.hostname || hostname;
    targetGroupName = location.query.targetGroupName;
    targetGroupId = location.query.targetGroupId;
  }

  let page = props.match.params.page;

  if (props.setPageByState) {
    page = state.page || page;
  }

  const { tab, detailsPage, filter } = state;
  let sort = props.columnSort.length !== 0 ? props.columnSort : state.sort;

  return {
    id,
    tab,
    tier,
    page,
    pageSize: 10,
    detailsPage,
    sort,
    filter,
    hostname,
    targetGroupId,
    targetGroupName,
  };
};

class Vulnerabilities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 1,
      isBusy: true,
      model: [],
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const props = parseProps(this.props, this.state);

    this.doVulnerabilities(
      props.tab,
      props.tier,
      props.id,
      props.page,
      props.detailsPage,
      props.pageSize,
      props.filter,
      props.sort,
      props.hostname,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const cProps = parseProps(this.props, this.state);
    const pProps = parseProps(prevProps, prevState);

    if (JSON.stringify(cProps) === JSON.stringify(pProps)) {
      return;
    }

    this.doVulnerabilities(
      cProps.tab,
      cProps.tier,
      cProps.id,
      cProps.page,
      cProps.detailsPage,
      cProps.pageSize,
      cProps.filter,
      cProps.sort,
      cProps.hostname,
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  doVulnerabilities(tab, tier, id, page, detailsPage, pageSize, filter, sort, hostname) {
    if (id === 0) return;

    switch (tab) {
      case 1:
        if (!this.props.externalData) {
          this.getVulnerabilityCVEDetails(tier, id, detailsPage, pageSize, filter, sort);
        }
        break;

      default:
        this.getVulnerabilitySummary(tier, id, page, pageSize, filter, sort);
        break;
    }

    if (hostname !== undefined) {
      this.setState({ hostname, tab: 1, filter: [{ id: 'hostname', value: { value: hostname, comparator: '=' } }] });
    }

    // Hyperlink from summary to details
  }

  getVulnerabilityCVEDetails(tier, id, page, pageSize, filter, sort) {
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];
    page = page || 1;

    this.setState({ isBusy: true });

    let url = config.api.urlFor('vulnerabilitiesCve', { id });

    if (sort && sort.length) {
      url += `?orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            csvUrl: config.api.urlFor('vulnerabilitiesCsv', { id }),
            detailsModel: data.rows,
            pages: Math.ceil(data.totalRecords / pageSize),
            isBusy: false,
          });
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isBusy: false });
        }
      });
  }

  // This method seems to not be used
  getVulnerabilityDetails(tier, id, page, pageSize, filter, sort) {
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];
    page = page || 1;

    this.setState({ isBusy: true });

    let url = config.api.urlFor('vulnerabilities', { tier, page, pageSize });

    url = id ? url + `&id=${id}` : url;

    if (filter) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            csvUrl: config.api.urlFor('vulnerabilitiesCsv', { id }),
            detailsModel: data.rows,
            pages: Math.ceil(data.totalRecords / pageSize),
            isBusy: false,
          });
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isBusy: false });
        }
      });
  }

  getVulnerabilitySummary(tier, id, page, pageSize, filter, sort) {
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];
    page = page || 1;

    this.setState({ isBusy: true });

    let url = config.api.urlFor('vulnerabilitySummary', { tier, page, pageSize });

    url = id ? url + `&id=${id}` : url;

    if (filter) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const label =
          tier === 'global'
            ? tier
            : tier === 'portfolio'
            ? id || tier
            : data && data.rows && data.rows[0]
            ? data.rows[0].name
            : 'provider';

        if (this._isMounted) {
          this.setState({
            csvUrl: config.api.urlFor('vulnerabilitySummaryCsv', { tier, id }),
            tier,
            id,
            label,
            summaryModel: data.rows.map((row) => {
              return {
                id: tier === 'provider' ? id : row.id,
                name: row.name,
                hostname: row.hostname,
                critical: row.critical ? (
                  <span className="badge badge-danger" style={badgeStyles}>
                    {row.critical}
                  </span>
                ) : (
                  row.critical
                ),
                high: row.high,
                medium: row.medium,
                low: row.low,
              };
            }),
            pages: Math.ceil(data.totalRecords / pageSize),
            isBusy: false,
          });
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isBusy: false });
        }
      });
  }

  render() {
    const { id = 0, pages = 1, summaryModel = [], tier = 'global', label = 'global', tab = 0, csvUrl } = this.state;
    const {
      match: { path },
      history,
      location: { search = '' },
      externalData,
    } = this.props;
    let { columnSort } = this.props;
    columnSort = columnSort || [
      { id: 'critical', desc: true },
      { id: 'high', desc: true },
      { id: 'medium', desc: true },
      { id: 'low', desc: false },
    ];

    let page = this.props.match.params.page || 1;

    if (this.props.setPageByState) {
      page = this.state.page || page;
    }

    return (
      // TODO: CSS hack. Not sure why this overlaps with the sticky footer without this extra margin.
      <div className="row" style={{ marginBottom: '80px' }}>
        <div className="col-12">
          {this.props.omitH1 ? null : <h1>{label}</h1>}
          <div className="card o-hidden h-100">
            <div className="card-header">
              <div className="float-left">
                <i className={`fa-fw fas fa-exclamation-triangle`}></i> Vulnerabilities
              </div>
              <div className="float-right">
                <CsvDownloadButton
                  url={csvUrl}
                  filename={`${label.toLowerCase().replace(/ /g, '-')}-vulnerability-${
                    tab === 0 ? 'summary' : 'details'
                  }`}
                />
              </div>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-12">{tooltip('Total Vulnerabilities')}</div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  {tier === 'provider' && (
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <button
                          className={`btn btn-link nav-link ${tab === 0 ? 'active' : null}`}
                          onClick={() =>
                            tab !== 0 &&
                            history.replace(`/reports/vulnerabilities/provider/1?id=${id}`) |
                              this.setState({ tab: 0, hostname: undefined, filter: [] })
                          }
                        >
                          Host Summary
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`btn btn-link nav-link ${tab === 1 ? 'active' : null}`}
                          onClick={() => tab !== 1 && this.setState({ tab: 1, filter: [] })}
                        >
                          Details
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {tab === 0 &&
                    (['provider', 'vendor'].includes(tier) ? (
                      <ReactTable
                        manual
                        columns={columnSpecProvider}
                        multiSort={false}
                        showPageSizeOptions={false}
                        filterable
                        sortable
                        data={summaryModel}
                        defaultSorted={columnSort}
                        pageSize={0}
                        pages={pages}
                        page={page - 1}
                        onSortedChange={(sort) => {
                          typeof this.props.columnSortResetter === 'function' ? this.props.columnSortResetter() : null;
                          this.setState({ sort });
                        }}
                        onPageChange={(page) => {
                          if (this.props.setPageByState) {
                            this.setState({ page: page + 1 });
                          } else {
                            history.push(`${urlFromPath(path, this.props.match.params, { page: page + 1 })}${search}`);
                          }
                        }}
                        onFilteredChange={(filter) => this.setState({ filter, page: 1 })}
                        loading={this.state.isBusy}
                        className="-striped -highlight hide-arrows"
                      />
                    ) : (
                      <ReactTable
                        manual
                        columns={tier === 'global' ? columnSpecGlobal : columnSpecPortfolio}
                        multiSort={false}
                        showPageSizeOptions={false}
                        filterable
                        sortable
                        data={summaryModel}
                        defaultSorted={[
                          { id: 'critical', desc: true },
                          { id: 'high', desc: true },
                          { id: 'medium', desc: true },
                          { id: 'low', desc: true },
                        ]}
                        pageSize={0}
                        pages={pages}
                        page={page - 1}
                        onSortedChange={(sort) => this.setState({ sort })}
                        onPageChange={(page) =>
                          history.push(`${urlFromPath(path, this.props.match.params, { page: page + 1 })}${search}`)
                        }
                        onFilteredChange={(filter) => this.setState({ filter })}
                        loading={this.state.isBusy}
                        className="-striped -highlight hide-arrows"
                      />
                    ))}
                  {tab === 1 && (
                    <ReactTable
                      columns={columnSpecDetails()}
                      multiSort={false}
                      showPageSizeOptions={false}
                      filterable
                      sortable
                      data={externalData}
                      defaultSorted={[{ id: 'cveScore', desc: true }]}
                      defaultPageSize={10}
                      // pages={pages}
                      // page={detailsPage - 1}
                      // onSortedChange={(sort) => this.setState({ sort })}
                      // onPageChange={(page) => this.setState({ detailsPage: page + 1 })}
                      // onFilteredChange={(filter) => this.setState({ filter })}
                      loading={!externalData}
                      className="-striped -highlight hide-arrows"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withPrincipal(withRouter(Vulnerabilities));
