const getUserRoutePerms = (item) => {
  // Checks User Perms for Route Perms. Currently uses any admin.user role.
  const permTest = /^admin\.user/;
  return permTest.test(item);
};

const getSubscriberUserRoutePerms = (item) => {
  // Checks User Perms for Route Perms. Currently uses any subscriber.admin.user role.
  const permTest = /^subscriber\.admin\.user/;
  return permTest.test(item);
};

const getIntegrationsPerms = (item) => {
  // Checks User Perms for Integrations Perms. Currently uses permission 'subscriber.admin.apikeys'.
  const permTest = /^subscriber\.admin\.apikeys/;
  return permTest.test(item);
};

const getImportPerms = (item) => {
  // Checks User Perms for Import specific perms.
  const permTest = /^subscriber\.admin\.import/;
  return permTest.test(item);
};

const getPortfolioPerms = (item) => {
  // Checks User Perms for Portfolio specific perms. Currently uses any portfolio role.
  const permTest = /portfolio/;
  return permTest.test(item);
};

const getRolePerms = (item) => {
  // Chanes User Perms for Role specific Perms. Currently uses any admin.role role.
  const permTest = /^admin\.role/;
  return permTest.test(item);
};

const getSubscriberPerms = (item) => {
  // Checks Subscribers Perms for Subscriber specific Perms. Currently uses permissions 'subscriber.list', 'subscriber.new' and 'susbcriber.save'.
  const permTest = /^admin\.subscriptions\.list/;
  return permTest.test(item);
};

const buildPermissions = (principal) => {
  //List of user permissions
  let userPermission = [];

  // Parse user permissions from the users principal. Add them to the userPermission list
  for (let x = 0; x < principal.roles.length; x++) {
    for (let i = 0; i < principal.roles[x].permissions.length; i++) {
      if (!userPermission.includes(principal.roles[x].permissions[i])) {
        userPermission.push(principal.roles[x].permissions[i]);
      }
    }
  }

  return userPermission;
};

export const adminOptions = (principal) => {
  const userPermission = buildPermissions(principal);

  if (
    principal &&
    (userPermission.some((item) => getUserRoutePerms(item)) ||
      userPermission.some((item) => getSubscriberUserRoutePerms(item)) ||
      userPermission.some((perm) => getPortfolioPerms(perm)) ||
      userPermission.some((item) => getSubscriberPerms(item)))
  ) {
    const options = [];

    // Adds Users Link based on regex of permissions in getUserRoutePerms
    // Adds Invitations link based on regex of permissions in getUserRoutePerms
    if (userPermission.some((item) => getUserRoutePerms(item))) {
      options.push({ label: 'Users', to: '/admin/users' });
      options.push({ label: 'Invitations', to: '/admin/invitations' });
      // options.push({ label: "Assessment Invites", to: "/assessment-review/invite" })
    } else if (userPermission.some((item) => getSubscriberUserRoutePerms(item))) {
      options.push({ label: 'Users', to: '/subscriber/users' });
      //options.push({ label: "Invitations", to: "/subscriber/invitations" }) // ToDo: uncomment when ready.
    }

    if (userPermission.some((item) => getIntegrationsPerms(item))) {
      options.push({ label: 'Integrations', to: '/subscriber/integrations' });
    }

    // Adds Roles Link based on regex of permissions in getRolePerms
    if (userPermission.some((item) => getRolePerms(item))) {
      options.push({ label: 'Roles', to: '/admin/roles' });
    }

    // Adds Subscribers Link based on regex of permissions in getSubscriberPerms
    if (userPermission.some((item) => getSubscriberPerms(item))) {
      options.push({ label: 'Subscribers', to: '/admin/subscriptions' });
    }

    // Adds Portfolios Link based on regex in getPortfolioPerms
    // if(userPermission.some((item) => getPortfolioPerms(item))){
    //   options.push({label: "Portfolios", to: "/admin/portfolios"})
    // }

    // options.push({label: "Target Groups", to: "/admin/targetGroups"})

    if (userPermission.some((item) => getImportPerms(item))) {
      options.push({ label: 'Vendor Imports', to: '/subscriber/imports' });
    }

    return {
      label: 'Admin',
      to: options,
    };
  }

  return null;
};
