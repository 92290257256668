import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Icon, IconFormatter } from './home';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Link } from 'react-router-dom';

import { CsvDownloadButton } from '.';

import config from '../config';

class ProvidersTableSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      providers: [],
      page: 0,
      sizePerPage: 10,
    };

    this.doTable = this.doTable.bind(this);
  }

  componentDidMount() {
    this.doTable(null, {
      page: this.state.page + 1,
      sizePerPage: 10,
      filters: this.state.filtered,
      sortOrder: this.state.sorted,
    });
  }

  doTable = (type, { page, sizePerPage, filters, sortOrder, search }) => {
    page = parseInt(page || 1, 10);
    filters = filters && filters.push ? filters.slice() : [];
    sortOrder = sortOrder || [];

    let url = config.api.urlFor('providerScores', { page: page });

    if (search !== undefined) {
      filters.push({ id: 'name', value: { value: search, comparator: '~*' } });
    }

    if (filters) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filters))}`;
    }

    if (sortOrder && sortOrder.length) {
      url += `&orderBy=${sortOrder[0].id}&asc=${!sortOrder[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const providers = data.rows.map((provider) => {
          return {
            id: provider.id,
            name: <Link to={`/reports/provider/${provider.id}`}>{provider.name}</Link>,
            portfolio: provider.parent
              ? `4th Party of ${provider.parent}`
              : <Link to={`/portfolios/${provider.portfolio}`}>{provider.portfolio}</Link> || '-',
            risk: provider.avg_risk_score || '-',
            dns: provider.avg_dns_score || '-',
            web_app: provider.avg_web_app_score || '-',
            auth_security: provider.avg_auth_security || '-',
            session_security: provider.avg_session_security || '-',
            ssl: provider.avg_ssl_score || '-',
            cipher: provider.avg_cipher_score || '-',
            services: provider.services || '-',
            combined_risk: provider.combined_risk || '0',
            remediation_status: provider.remediation_status || 'Outdated',
            remediation_status_icon: provider.remediation_status || 'Outdated',
            business_impact_risk: provider.business_impact_risk || '0',
            cyber_risk: provider.cyber_risk || '0',
            operational_score: provider.operational_score || '0',
          };
        });

        this.setState({
          providers,
          page,
          tableIsBusy: false,
          totalSize: parseInt(data.totalRecords),
        });
      })
      .catch((err) => this.setState({ tableIsBusy: false }));
  };

  render() {
    const { providers, page, sizePerPage, totalSize } = this.state;

    const columns = [
      {
        dataField: 'remediation_status_icon',
        text: '',
        formatter: (cell, row) => <Icon cell={cell} />,
      },
      {
        dataField: 'name',
        text: 'Name',
      },
      {
        dataField: 'portfolio',
        text: 'Portfolio',
      },
      {
        dataField: 'services',
        text: 'Services',
      },
      {
        dataField: 'combined_risk',
        text: 'Combined Risk',
        formatter: IconFormatter,
      },
      {
        dataField: 'remediation_status',
        text: 'Remediation Status',
        formatter: IconFormatter,
      },
      {
        dataField: 'risk',
        text: 'Cyber Risk',
        formatter: IconFormatter,
      },
      {
        dataField: 'business_impact_risk',
        text: 'Business-Impact\n(inherent) Risk',
        formatter: IconFormatter,
      },
      {
        dataField: 'operational_score',
        text: 'Operations Findings Score',
        formatter: IconFormatter,
      },
    ];

    const searchRef = React.createRef();

    return (
      <>
        <div className="card mb-3">
          <div className="card-header">
            <div className="float-left">
              <i className="fas fa-list mr-1"></i>
              Providers Monitored
            </div>
            <div className="float-right">
              <CsvDownloadButton url={config.api.urlFor('providersCsv')} filename="global-provider-scores" />
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <form
                  className="form-inline"
                  onSubmit={(e) =>
                    e.preventDefault() | this.doTable(null, { page, sizePerPage, search: searchRef.current.value })
                  }
                >
                  <input
                    type="text"
                    className="form-control float-right mb-2"
                    placeholder="search..."
                    ref={searchRef}
                  />
                  <button type="submit" className="btn btn-primary ml-2 mb-2">
                    <i className="fas fa-search fa-fw fa-lg"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <BootstrapTable
                  keyField="id"
                  data={providers}
                  columns={columns}
                  remote
                  striped
                  pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true })}
                  onTableChange={this.doTable}
                />
              </div>
            </div>
          </div>

          <div className="card-footer small text-muted"></div>
        </div>
      </>
    );
  }
}

export default ProvidersTableSummary;
