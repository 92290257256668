export const RequestStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
};

export const ScanStatus = {
  PENDING: 'pending', // This status will not be used. To be removed when we finish the file scan functionality.
  SCANNING: 'scanning',
  VALID: 'valid',
  ERROR: 'error',
  REQUESTED: 'requested',
};
