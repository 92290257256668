import React from 'react';
import Switch from 'react-switch';

const ToggleSwitch = (props) => {
  const { checked, disabled, onChange, size = 'normal', id } = props;
  let diameter = 30,
    height = 20,
    width = 48;

  if (size === 'small') {
    diameter = 20;
    height = 15;
    width = 40;
  }

  return (
    <Switch
      disabled={disabled}
      checked={!!checked}
      onChange={onChange}
      onColor="#86d3ff"
      onHandleColor="#2693e6"
      handleDiameter={diameter}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={height}
      width={width}
      className="react-switch"
      id={id}
    />
  );
};

export default ToggleSwitch;
