import React from 'react';
import { withRouter } from 'react-router-dom';
import Busy from '../../busy';
import TabList from '../../tabList';
import SectionContainer from '../../sectionContainer';
import ComplianceTable from './complianceTable';
import InfoHygiene from '../infoHygiene';
import SectionLocked from '../../sectionLocked';

const _ = require('lodash');

import './compliance.css';

/** Functional component for displaying Compliance data */
const ComplianceContent = (props) => {
  //Props
  const {
    isLoadingVendorRisk = true,
    vendorData = {},
    oshaFindings = [],
    epaFindings = [],
    hipaaFindings = [],
    whdFindings = [],
    warnFindings = [],
    history,
    pathBase,
    isUnlockedCMRE,
    reportTypeCMRE,
    handleUnlockButtonClick,
    canPurchaseReports,
    hasData,
    isLoadingProvenanceData,
  } = props;
  //Prop-Dependant Consts
  const CHPathAppend = 'Compliance';
  const CHPathBase = pathBase + (pathBase.endsWith('/') ? '' : '/') + CHPathAppend;

  //State
  const [forceSelected, setForceSelected] = React.useState(
    props.match.params.selectedTab === CHPathAppend ? props.match.params.subsection : undefined,
  );

  // Currency Formatter
  const currencyFormat = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  /* ----------------------------------- */
  /*              Effects                */
  /* ----------------------------------- */
  // Note: Both effects relate to updating and maintaining the URL and selected tab for this content display

  React.useEffect(() => {
    const subsection = props.match.params.subsection;

    if (props.match.params.selectedTab === CHPathAppend) {
      if (subsection && subsection !== forceSelected) {
        setForceSelected(subsection);
      } else if (!props.match.params.subsection && isUnlockedCMRE) {
        history.replace(CHPathBase + '/' + (forceSelected || 'OSHA'));
      }
    }
  }, [props.match.params.selectedTab]);

  React.useEffect(() => {
    const subsection = props.match.params.subsection;

    if (props.match.params.selectedTab === CHPathAppend && subsection && subsection !== forceSelected) {
      setForceSelected(subsection);
    }
  }, [props.match.params.subsection]);

  /* ----------------------------------- */
  /*             Render                  */
  /* ----------------------------------- */
  return (
    <InfoHygiene isLoadingVendorRisk={isLoadingVendorRisk} vendorData={vendorData}>
      <Busy isBusy={isLoadingProvenanceData}>
        {isUnlockedCMRE ? (
          hasData ? (
            <div className="compliance-content">
              <TabList forceSelected={forceSelected} history={history} pathBase={CHPathBase}>
                <div label="OSHA">
                  <SectionContainer label="OSHA Findings" className="compliance-section-container">
                    <ComplianceTable
                      tableName="compliance-table-osha"
                      headers={[
                        ['Date', true],
                        ['Status', true],
                        ['Reason', false],
                      ]}
                      includedHeaders="m-tal-c px-2 pt-3 pb-4"
                      excludedHeaders="px-2 pt-3 pb-4"
                    >
                      {oshaFindings.length ? (
                        oshaFindings.map((finding, i) => {
                          return (
                            <tr className="compliance-tr" key={'compliance-table-osha' + i}>
                              <td className="text-center px-2 py-4">{finding.date}</td>
                              <td className="text-center px-2 py-4">{finding.osha_report_type}</td>
                              <td className="px-2 py-4">{finding.description}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="compliance-tr-empty" key={'compliance-table-osha-empty'}>
                          <td colSpan={3}>
                            <p>No Findings Identified</p>
                          </td>
                        </tr>
                      )}
                    </ComplianceTable>
                  </SectionContainer>
                </div>
                <div label="EPA">
                  <SectionContainer label="EPA Findings" className="compliance-section-container">
                    <ComplianceTable
                      tableName="compliance-table-epa"
                      headers={[
                        ['State', true],
                        ['Overall Compliance', true],
                        ['Most Recent Significant Violation', true],
                      ]}
                      includedHeaders="m-tal-c px-2 pt-3 pb-4"
                      excludedHeaders="px-2 pt-3 pb-4"
                    >
                      {epaFindings.length && !epaFindings.some((obj) => _.isEmpty(obj)) ? (
                        epaFindings.map((finding, i) => {
                          return (
                            <tr className="compliance-tr" key={'compliance-table-epa' + i}>
                              <td className="text-center px-2 py-4">{finding.state}</td>
                              <td className="text-center px-2 py-4">{finding.overall_compliance}</td>
                              <td className="text-center px-2 py-4">
                                {finding.most_recent_significant_violation == 'N'
                                  ? 'Null'
                                  : finding.most_recent_significant_violation}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="compliance-tr-empty" key={'compliance-table-epa-empty'}>
                          <td colSpan={3}>
                            <p>No Findings Identified</p>
                          </td>
                        </tr>
                      )}
                    </ComplianceTable>
                  </SectionContainer>
                </div>
                <div label="HIPAA">
                  <SectionContainer label="HIPAA Findings" className="compliance-section-container">
                    <ComplianceTable
                      tableName="compliance-table-hipaa"
                      headers={[
                        ['Date', true],
                        ['Status', true],
                        ['State', true],
                        ['Reason', false],
                        ['Fine', true],
                        ['# of Records Affect', true],
                      ]}
                      includedHeaders="m-tal-c px-2 pt-3 pb-4"
                      excludedHeaders="px-2 pt-3 pb-4"
                    >
                      {hipaaFindings.length && !hipaaFindings.some((obj) => _.isEmpty(obj)) ? (
                        hipaaFindings.map((finding, i) => {
                          return (
                            <tr className="compliance-tr" key={'compliance-table-hipaa' + i}>
                              <td className="text-center px-2 py-4">{finding.date}</td>
                              <td className="text-center px-2 py-4">{finding.settlement_or_cmp}</td>
                              <td className="text-center px-2 py-4">{finding.state}</td>
                              <td className="text-center px-2 py-4">{finding.reason}</td>
                              <td className="text-center px-2 py-4">{finding.fine}</td>
                              <td className="text-center px-2 py-4">{finding.number_of_records_affected}</td>
                              {/* TODO FIX bw_atp_amt to CURRENCY FORMAT */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="compliance-tr-empty" key={'compliance-table-hipaa-empty'}>
                          <td colSpan={6}>
                            <p>No Findings Identified</p>
                          </td>
                        </tr>
                      )}
                    </ComplianceTable>
                  </SectionContainer>
                </div>
                <div label="WHD">
                  <SectionContainer label="WHD Findings" className="compliance-section-container">
                    <ComplianceTable
                      tableName="compliance-table-whd"
                      headers={[
                        ['Employer Name', true],
                        ['State', true],
                        ['Findings Start Date', true],
                        ['Findings End Date', true],
                        ['Case Violation Count', true],
                        ['Backwages Agreed to Pay', true],
                      ]}
                      includedHeaders="m-tal-c px-2 pt-3 pb-4"
                      excludedHeaders="px-2 pt-3 pb-4"
                    >
                      {whdFindings.length && !whdFindings.some((obj) => _.isEmpty(obj)) ? (
                        whdFindings.map((finding, i) => {
                          return (
                            <tr className="compliance-tr" key={'compliance-table-whd' + i}>
                              <td className="text-center px-2 py-4">{finding.trade_nm}</td>
                              <td className="text-center px-2 py-4">{finding.state}</td>
                              <td className="text-center px-2 py-4">{finding.findings_start_date}</td>
                              <td className="text-center px-2 py-4">{finding.date}</td>
                              <td className="text-center px-2 py-4">{finding.case_violtn_cnt}</td>
                              <td className="text-right px-2 py-4 pr-5">{currencyFormat.format(finding.bw_atp_amt)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="compliance-tr-empty" key={'compliance-table-whd-empty'}>
                          <td colSpan={6}>
                            <p>No Findings Identified</p>
                          </td>
                        </tr>
                      )}
                    </ComplianceTable>
                  </SectionContainer>
                </div>
                <div label="WARN">
                  <SectionContainer label="WARN Findings" className="compliance-section-container">
                    <ComplianceTable
                      tableName="compliance-table-warn"
                      headers={[
                        ['State', true],
                        ['Type', true],
                        ['Number Affected', true],
                        ['Effective Date', true],
                      ]}
                      includedHeaders="m-tal-c px-2 pt-3 pb-4"
                      excludedHeaders="px-2 pt-3 pb-4"
                    >
                      {warnFindings.length && !warnFindings.some((obj) => _.isEmpty(obj)) ? (
                        warnFindings.map((finding, i) => {
                          return (
                            <tr className="compliance-tr" key={'compliance-table-warn' + i}>
                              <td className="text-center px-2 py-4">{finding.state}</td>
                              <td className="text-center px-2 py-4">{finding.type}</td>
                              <td className="text-center px-2 py-4">{finding.number_affected}</td>
                              <td className="text-center px-2 py-4">{finding.effective_date}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="compliance-tr-empty" key={'compliance-table-warn-empty'}>
                          <td colSpan={4}>
                            <p>No Findings Identified</p>
                          </td>
                        </tr>
                      )}
                    </ComplianceTable>
                  </SectionContainer>
                </div>
              </TabList>
            </div>
          ) : (
            <div className="summary-section-container">
              <div className="section-inner-title animated-ellipsis">Currently Processing</div>
              <div className="section-info-text">Continuous Monitoring of {vendorData.name} is in progress.</div>
            </div>
          )
        ) : (
          <SectionContainer label="Foreign Influence Alerts">
            <SectionLocked
              title="Unlock Foreign Influence Alerts"
              buttonCaption="Unlock Foreign Influence Alerts"
              info={`Subscribe to Continuous Monitoring for ${vendorData.name} by purchasing a Continuous Monitoring Report - Enhanced.`}
              details="Be alerted to events that may increase the risk of doing business with your vendors:"
              bullets={['Compromise', 'Compliance', 'Foreign Influence', 'Mergers & Acquisitions']}
              canUnlockSection={canPurchaseReports}
              handleUnlockButtonClick={handleUnlockButtonClick}
              reportType={reportTypeCMRE}
            />
          </SectionContainer>
        )}
      </Busy>
    </InfoHygiene>
  );
};

export default withRouter(ComplianceContent);
