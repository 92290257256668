import React, { useEffect } from 'react';
import { useUpdateState } from '../..';
import FileUploader from './fileUploader';
import ReactTooltip from 'react-tooltip';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import config from '../../../config';
import Form from 'react-bootstrap/Form';
import ContactFormButton from '../../contactFormButton';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { toastError, toastSuccess } from '../../../lib/utils';
import CatalogDropZone from '../catalog/catalogDropZone';
import { useParams } from 'react-router-dom';

const ProductManagementMain = (props) => {
  const { id: vendorID } = useParams();

  // Props
  const { editProduct, canDownloadSBOMs, setReloadData = () => {}, setEditProductByID = () => {}, sbomHash } = props;

  // State
  const [state, setState] = useUpdateState({
    uploaderType: 1,
    // PRODUCT NAME
    productNameOptions: [],
    namesLoading: false,
    // CATEGORY
    categoryOptions: [],
    categoriesLoading: true,
    // LINE
    productLineOptions: [],
    linesLoading: false,
    // TYPE
    productTypeOptions: ['Hardware', 'Software'],
    // TAGS
    tagOptions: [],
    tagsLoading: false,
    // VERSION
    versionOptions: [],
    versionsLoading: false,
    // OTHER
    saveButtonIsDisabled: true,
    deleteButtonIsDisabled: true,
    productData: {},
    submittedFiles: [],
    removeAllSubmittedFiles: false,
    productsOptionsWithIDs: [],
  });

  const {
    currSearch,
    uploaderType,
    // PRODUCT NAME
    productNameOptions,
    namesLoading,
    // CATEGORY
    categoryOptions,
    categoriesLoading,
    // LINE
    productLineOptions,
    linesLoading,
    // TYPE
    productTypeOptions,
    // TAGS
    tagOptions,
    tagsLoading,
    // VERSION
    versionOptions,
    versionsLoading,
    saveButtonIsDisabled,
    deleteButtonIsDisabled,
    productData,
    submittedFiles,
    removeAllSubmittedFiles,
    productsOptionsWithIDs,
  } = state;

  // Effect
  useEffect(() => {
    setState({ saveButtonIsDisabled: productData.name && productData.type ? false : true });
  }, [productData]);

  useEffect(() => {
    setInitialProductData();
    setState({
      deleteButtonIsDisabled: editProduct && editProduct.productID ? false : true,
      removeAllSubmittedFiles: true,
    });
  }, [editProduct]);

  // categories is static, so it just needs to be fetched once
  useEffect(() => {
    setState({ categoriesLoading: true });
    const filterByVendorID = false;

    fetch(
      config.api.urlFor('productDropdown', {
        vendorID: filterByVendorID ? vendorID : null,
        dropdown: 'categories',
      }),
    )
      .then((res) => res.json())
      .then((response) => {
        const { isSuccess, rows } = response;
        if (isSuccess) {
          setState({ categoryOptions: rows });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setState({ categoriesLoading: false });
      });
  }, []);

  const setInitialProductData = () => {
    setState({
      productData: {
        id: (editProduct && editProduct.productID) || null,
        name: (editProduct && editProduct.name) || '',
        category: (editProduct && editProduct.category) || '',
        description: (editProduct && editProduct.description) || '',
        type: (editProduct && editProduct.type) || '',
        series: (editProduct && editProduct.series) || '',
        tags: (editProduct && editProduct.tags && editProduct.tags.split(',')) || [],
        vendorID: vendorID,
      },
    });
  };

  const handleInputBox = (e, keyName) => {
    let val;
    switch (keyName) {
      case 'version':
        val = e && e[0] ? e[0] : null;
        break;
      case 'description':
        val = e ? e.target.value : null;
        break;
      case 'name':
        val = null;
        if (e && e[0]) {
          if (typeof e[0] === 'object') {
            val = e[0].label;
          } else {
            val = e[0];
            const foundProduct = productsOptionsWithIDs.find((p) => p.name === val);
            if (foundProduct && foundProduct.id) {
              setEditProductByID(foundProduct.id);
            }
          }
        }
        break;
      case 'series':
        val = e && e[0] ? (typeof e[0] === 'object' ? e[0].label : e[0]) : null;
        break;
      case 'tags':
        val = e && e.length ? e.map((tag) => (typeof tag === 'object' ? tag.label : tag)) : [];
        break;
      case 'category':
        val = e ? e.target.value : null;
        break;
      case 'type':
        val = e ? e.target[e.target.selectedIndex].innerText : null;
        break;
      default:
        break;
    }

    setState({ productData: { ...productData, [keyName]: val } });
  };

  const handleProductNameInput = (event, isMouseClick = false) => {
    if (isMouseClick || event.key == 'Enter' || event.key == 'Tab') {
      handleInputBox([event.target.value], 'name');
    }
  };

  const handleSearch = async (options) => {
    let { search, dropdown } = options;
    let filterByVendorID = false;

    let stateToChange, stateLoading;
    switch (dropdown) {
      case 'products':
        stateToChange = 'productNameOptions';
        stateLoading = 'namesLoading';
        filterByVendorID = true;
        break;
      case 'series':
        stateToChange = 'productLineOptions';
        stateLoading = 'linesLoading';
        filterByVendorID = true;
        break;
      case 'tags':
        stateToChange = 'tagOptions';
        stateLoading = 'tagsLoading';
        break;
      case 'versions':
        stateToChange = 'versionOptions';
        stateLoading = 'versionsLoading';
        break;
      default:
        break;
    }

    setState({ [stateLoading]: true });

    fetch(config.api.urlFor('productDropdown', { vendorID: filterByVendorID ? vendorID : null, search, dropdown }))
      .then((res) => res.json())
      .then((response) => {
        const { isSuccess, rows } = response;
        if (isSuccess && rows && rows.length) {
          // eslint-disable-next-line no-prototype-builtins
          if (rows[0].hasOwnProperty('name')) {
            const options = rows.map((a) => a.name);
            setState({ [stateToChange]: options, productsOptionsWithIDs: rows });
          } else {
            setState({ [stateToChange]: rows });
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setState({ [stateLoading]: false });
      });
  };

  const searchNames = (search) => {
    setState({ currSearch: search });
    handleSearch({ search, dropdown: 'products' });
  };

  const searchLines = (search) => {
    setState({ currSearch: search });
    handleSearch({ search, dropdown: 'series' });
  };

  const searchTags = (search) => {
    setState({ currSearch: search });
    handleSearch({ search, dropdown: 'tags' });
  };

  const searchVersion = (search) => {
    setState({ currSearch: search });
    handleSearch({ search, dropdown: 'versions' });
  };

  const filterBy = () => true;

  const handleSaveProduct = () => {
    if (saveButtonIsDisabled) return;

    //Calls POST endpoint for creating a net new product or editing the selected one.
    const formData = new FormData();
    formData.append('productData', JSON.stringify({ ...productData, tags: productData.tags.join(',') }));

    if (submittedFiles && submittedFiles.length > 0) {
      formData.append('file', submittedFiles[0], submittedFiles[0].name);
    }

    setState({ saveButtonIsDisabled: true, deleteButtonIsDisabled: true });

    fetch(config.api.urlFor('products'), {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.isSuccess) {
          toastSuccess('Product saved successfully.');
          setReloadData(true);
        } else {
          toastError('An error occurred attempting to save product.');
        }
        setState({ saveButtonIsDisabled: false, deleteButtonIsDisabled: false });
      })
      .catch(() => {
        setState({ saveButtonIsDisabled: false, deleteButtonIsDisabled: false });
        toastError('An error occurred attempting to save product.');
      });
  };

  const setSubmittedFiles = (submittedFiles) => {
    setState({ submittedFiles, removeAllSubmittedFiles: false });
  };

  const handleCancelClick = () => {
    setInitialProductData();
    setState({ removeAllSubmittedFiles: true });
  };

  const { name, category, description, type, series, tags } = productData;

  // Render
  return (
    <div className="pm-outer-container">
      <div className="pm-row-grid-container">
        <div className="pm-col-grid-container">
          <div className="pm-col-1">
            <div className="pm-inner-div">
              <p>Product Name*</p>
              <AsyncTypeahead
                id="product-name"
                filterBy={filterBy}
                isLoading={namesLoading}
                minLength={2}
                onSearch={searchNames}
                onChange={(e) => handleInputBox(e, 'name')}
                options={productNameOptions}
                placeholder={name || 'Select...'}
                allowNew
                newSelectionPrefix="New Product: "
                renderMenuItemChildren={(option) => <Highlighter search={currSearch}>{option}</Highlighter>}
                onKeyDown={handleProductNameInput}
                onBlur={(e) => handleProductNameInput(e, true)}
              />
            </div>
            <div className="pm-inner-div">
              <p>Category</p>
              <select
                disabled={categoriesLoading}
                onChange={(e) => handleInputBox(e, 'category')}
                className="form-control"
                value={category}
              >
                <option disabled value={''}>
                  -- Select an option --
                </option>
                {categoryOptions ? (
                  categoryOptions.map((option, i) => (
                    <option key={i} value={option}>
                      {option}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>
            <div className="pm-inner-div">
              <p>Product Line</p>
              <AsyncTypeahead
                id="product-line"
                filterBy={filterBy}
                isLoading={linesLoading}
                minLength={2}
                onSearch={searchLines}
                onChange={(e) => handleInputBox(e, 'series')}
                selected={series ? [series] : []}
                options={productLineOptions}
                placeholder="Select..."
                allowNew
                newSelectionPrefix="New Product Line: "
                renderMenuItemChildren={(option) => <Highlighter search={currSearch}>{option}</Highlighter>}
              />
            </div>
            <div className="pm-inner-div">
              <p>Product Description</p>
              <textarea
                id="product-description"
                className="form-control"
                placeholder="Product Description..."
                rows="4"
                required="required"
                data-error="Please, leave us a message."
                onChange={(e) => handleInputBox(e, 'description')}
                maxLength={5000}
                value={description}
              />
            </div>
          </div>
          <div className="pm-col-2">
            <div className="pm-inner-div">
              <p>Product Logo</p>
              {/* under construction, needs remaining functionality */}
              <div className="catalog-drop-zone">
                <CatalogDropZone
                  currentFiles={submittedFiles}
                  setCurrentFiles={setSubmittedFiles}
                  singleFileMode
                  acceptableFileType={'.jpg,.jpeg,.png'}
                  showUploadBox
                  removeAllFiles={removeAllSubmittedFiles}
                  additionalMessageOnRejection="Product will be saved without a logo."
                />
              </div>
              {/* ----------------- */}
            </div>
            <div className="pm-inner-div">
              <p>Product Type*</p>
              <select required onChange={(e) => handleInputBox(e, 'type')} className="form-control" value={type}>
                <option disabled value={''}>
                  -- Select an option --
                </option>
                {productTypeOptions.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="pm-inner-div">
              <p>Tags</p>
              <React.Fragment>
                <Form.Group>
                  <AsyncTypeahead
                    id="product-tags"
                    filterBy={filterBy}
                    isLoading={tagsLoading}
                    minLength={2}
                    onSearch={searchTags}
                    onChange={(e) => handleInputBox(e, 'tags')}
                    options={tagOptions}
                    placeholder="Search..."
                    allowNew
                    multiple
                    newSelectionPrefix="New Tag: "
                    selected={tags ? tags : []}
                  />
                </Form.Group>
              </React.Fragment>
            </div>
          </div>
          <div className="pm-col-3">
            <FileUploader
              uploaderType={uploaderType}
              handleInputBox={handleInputBox}
              handleVersionSearch={searchVersion}
              productVersion={productData && productData.version}
              versionOptions={versionOptions}
              versionsLoading={versionsLoading}
              currSearch={currSearch}
              productID={editProduct && editProduct.productID}
              canDownloadSBOMs={canDownloadSBOMs}
              vendorID={vendorID}
              sbomHash={sbomHash}
            />
          </div>
        </div>
        <div className="pm-button-grid">
          <div data-tip data-for="saveProduct">
            <button className="save-product btn btn-sm" disabled={saveButtonIsDisabled} onClick={handleSaveProduct}>
              Save Product
            </button>
            {saveButtonIsDisabled && (
              <ReactTooltip id="saveProduct" className="on-hover-tooltip" place="right" effect="solid">
                <p className="on-hover-text">Please choose an option for each required field, denoted by (*).</p>
              </ReactTooltip>
            )}
          </div>
          <div data-tip data-for="deleteProduct">
            <ContactFormButton
              caption="Delete Product"
              storageKey="requestProductDeletion"
              storageValue={editProduct ? `${editProduct.productID}|${editProduct.name}` : ''}
              look="outlinedSmallRedButton"
              disabled={deleteButtonIsDisabled}
            />
            <ReactTooltip id="deleteProduct" className="on-hover-tooltip" place="right" effect="solid">
              <p className="on-hover-text">
                {deleteButtonIsDisabled ? 'Please select a product.' : 'Request product deletion.'}
              </p>
            </ReactTooltip>
          </div>
          <button
            className="cancel"
            disabled={saveButtonIsDisabled || deleteButtonIsDisabled}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductManagementMain;
