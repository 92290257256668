import React, { Component } from 'react';

import Nav from './../reusable/nav';

import './printCoverPageHorizontal.css';

class PrintCoverPageHorizontal extends Component {
  render() {
    const { navOptions, children } = this.props;
    return (
      <div id="postern-print-cover-page">
        <Nav navOptions={navOptions} />
        <div className="postern-print-cover-page-content">
          <img
            className="print-nav-fortress-logo"
            src="/static/images/fis-logo.svg"
            alt="Fortress Information Security Logo"
          />
          {children}
        </div>
      </div>
    );
  }
}

export default PrintCoverPageHorizontal;
