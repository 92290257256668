export const debounce = (fn, timeout = 300) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    return new Promise((res) => (timer = setTimeout(async () => res(await fn(...args)), timeout)));
  };
};

export default debounce;
