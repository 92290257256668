import React from 'react';
import { Release_1_4_0, Release_1_5_0, Release_1_6_0, Release_1_7_0 } from './releases';

const releaseNotes = () => (
  <div className="row justify-content-center mb-lg-2">
    <div className="col-8">
      <h3>
        <b>Introduction</b>
      </h3>
      <p>
        The A2V Network <em>(Powered by Fortress Information Security)</em> is always improving - check out the latest
        updates and release notes.
      </p>
      <Release_1_7_0 />
      <hr />
      <Release_1_6_0 />
      <hr />
      <Release_1_5_0 />
      <hr />
      <Release_1_4_0 />
    </div>
  </div>
);

export default releaseNotes;
