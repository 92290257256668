import React from 'react';

const centeredHeaders = [
  'Manufacturing',
  'Physical Locations',
  'Cyber Presence',
  'Mergers & Acquisitions',
  'Foreign Ownership',
  'Corporate Families',
];

const ForeignInfluenceTable = (props) => {
  const { children, headers = [], tableName = '' } = props;

  return (
    <table className={'provenance-table no-border'}>
      <thead className="fi-thead">
        <tr>
          {headers.map((header, i) => {
            return (
              <th scope="col" key={tableName + i} className={centeredHeaders.includes(header) ? 'm-tal-c' : ''}>
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

export default ForeignInfluenceTable;
