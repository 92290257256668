import React, { Component } from 'react';

import NewDashboard from '../../components/dashboard/newDashboard';

import './home.css';

class Home extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    return (
      <div id="catalog-home">
        <NewDashboard />
      </div>
    );
  }
}

export default Home;
