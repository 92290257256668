import React from 'react';
import { withRouter } from 'react-router-dom';
import { withPrincipal } from '../../components';
import InfoBox from '../../components/layout/infoBox';

const TrustCenterHome = (props) => {
  const { principal, history } = props;

  React.useEffect(() => {
    if (principal && principal.claimedPageVendorIds && principal.claimedPageVendorIds.length > 0) {
      history.replace('/catalog/vendor/' + principal.claimedPageVendorIds[0] + '/TrustCenter');
    }
  }, []);

  return (
    <InfoBox>
      <p>
        You have not yet claimed a profile page.
        <br />
        <br />
        Please use the red “Need Help” button in the lower right corner and indicate you would like to claim your
        profile page. Please include your company&apos;s name and website domain name in the request.
      </p>
    </InfoBox>
  );
};

export default withRouter(withPrincipal(TrustCenterHome));
