import React, { useEffect } from 'react';
import { makeCyberMap } from '../postern/reports/provenance/assets/js/cybermap.js';
import ForeignInfluenceMapLegends from './foreignInfluenceMapLegends';

const ForeignInfluenceMap = (props) => {
  //Props
  const {
    manufacturing = [],
    physicalPresence = [],
    cyberPresence = [],
    corporateFamilies = [],
    foreignOwnership = [],
    watchlist = [],
    displayIfEmpty,
  } = props;

  //Prop-Based Consts
  const hasKeys =
    manufacturing.length > 0 ||
    physicalPresence.length > 0 ||
    cyberPresence.length > 0 ||
    corporateFamilies.length > 0 ||
    foreignOwnership.length > 0;

  //Effects
  useEffect(() => {
    // fired on component mount
    const chart =
      hasKeys || displayIfEmpty
        ? makeCyberMap(manufacturing, physicalPresence, cyberPresence, corporateFamilies, foreignOwnership, watchlist)
        : null;

    return () => {
      // fired on component un-mount
      if (chart) {
        chart.dispose();
      }
    };
  }, [hasKeys, displayIfEmpty]);

  //Render
  return (
    <>
      {hasKeys || displayIfEmpty ? (
        <>
          <div id="provenance-cyber-map"></div>
          <ForeignInfluenceMapLegends
            manufacturing={manufacturing}
            physicalPresence={physicalPresence}
            cyberPresence={cyberPresence}
            corporateFamilies={corporateFamilies}
            foreignOwnership={foreignOwnership}
          />
        </>
      ) : null}
    </>
  );
};

export default ForeignInfluenceMap;
