import React, { Component } from 'react';

import ComplianceList from './appendix/complianceList';

export default class AppendixCompliance extends Component {
  render() {
    const { compliance = {} } = this.props;
    const { OSHA, WHD, HIPAA, EPA, WARN } = compliance;

    return (
      <div className="postern-section-summary-paragraph">
        {OSHA.findings.length > 0 ? (
          <p>
            <b>O</b>ccupational <b>S</b>afety and <b>H</b>ealth <b>A</b>dministration (<b>OSHA</b>) – Vendor has had{' '}
            {OSHA.findings.length} incident{OSHA.findings.length > 1 ? 's' : null} impacting safety violations, deaths,
            injury, etc.
            <ComplianceList compliance={OSHA} />
          </p>
        ) : (
          <p>
            <b>O</b>ccupational <b>S</b>afety and <b>H</b>ealth <b>A</b>dministration (<b>OSHA</b>) – Vendor has not had
            any incidents impacting safety violations, deaths, injury, etc.
          </p>
        )}

        {WHD.findings.length > 0 ? (
          <p>
            <b>W</b>age and <b>H</b>our <b>D</b>ivision (<b>WHD</b>) – Vendor has had {WHD.findings.length} incident
            {WHD.findings.length > 1 ? 's' : null} impacting federal labor laws.
            <ComplianceList compliance={WHD} />
          </p>
        ) : (
          <p>
            <b>W</b>age and <b>H</b>our <b>D</b>ivision (<b>WHD</b>) – Vendor has not had any incidents impacting
            federal labor laws.
          </p>
        )}

        {HIPAA.findings.length > 0 ? (
          <p>
            <b>H</b>ealth <b>I</b>nsurance <b>P</b>ortability and <b>A</b>ccountability <b>A</b>ct (<b>HIPAA</b>) –
            Vendor has had {HIPAA.findings.length} incident{HIPAA.findings.length > 1 ? 's' : null} impacting the
            privacy and security of personally identifiable health related information of individuals.
            <ComplianceList compliance={HIPAA} />
          </p>
        ) : (
          <p>
            <b>H</b>ealth <b>I</b>nsurance <b>P</b>ortability and <b>A</b>ccountability <b>A</b>ct (<b>HIPAA</b>) –
            Vendor has not had any incidents impacting the privacy and security of personally identifiable health
            related information of individuals.
          </p>
        )}

        {EPA.findings.length > 0 ? (
          <p>
            <b>E</b>nvironmental <b>P</b>rotection <b>A</b>gency (<b>EPA</b>) – Vendor has had {EPA.findings.length}{' '}
            incident{EPA.findings.length > 1 ? 's' : null} impacting environmental protection matters.
            <ComplianceList compliance={EPA} />
          </p>
        ) : (
          <p>
            <b>E</b>nvironmental <b>P</b>rotection <b>A</b>gency (<b>EPA</b>) – Vendor has not had any incidents
            impacting environmental protection matters.
          </p>
        )}

        {WARN.findings.length > 0 ? (
          <p>
            <b>W</b>orker <b>A</b>djustment and <b>R</b>etraining <b>N</b>otification (<b>WARN</b>) – Vendor has had{' '}
            {WARN.findings.length} incident{WARN.findings.length > 1 ? 's' : null} impacting plant closings and mass
            layoffs of employees.
            <ComplianceList compliance={WARN} />
          </p>
        ) : (
          <p>
            <b>W</b>orker <b>A</b>djustment and <b>R</b>etraining <b>N</b>otification (<b>WARN</b>) – Vendor has not had
            any incidents impacting plant closings and mass layoffs of employees.
          </p>
        )}
      </div>
    );
  }
}
