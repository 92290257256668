import React, { Component } from 'react';

import { makeSpendBreakdownChart } from './spendBreakdownChart';

import ReactTooltip from 'react-tooltip';
import './footnote.css';

export default class SpendBreakdown extends Component {
  componentDidMount() {
    const { sbTokenData, chartSize = '' } = this.props;
    const chart = makeSpendBreakdownChart(sbTokenData, chartSize); // dipose on unmount
    this.setState({ chart: chart });
  }

  componentWillUnmount() {
    this.state.chart.dispose();
  }

  render() {
    const { balance = '', chartSize = '' } = this.props;
    return (
      <div className="db-sb-wrapper">
        <h4>Spend Breakdown</h4>
        <div className="db-sb-balance-chart-fc">
          {/* <div id="db-sb-chart"></div> */}
          {chartSize === 'mobile' ? <div id="db-sb-chart-mobile"></div> : <div id="db-sb-chart"></div>}
          <div className="db-sb-balance-wrapper">
            <h4>
              Balance
              <a aria-describedby="footnote-label" data-tip data-for="onHoverTokens"></a>
              <ReactTooltip id="onHoverTokens" place="top" effect="solid">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                  Token balances may not reflect activity performed outside of the Marketplace module.<br></br>
                  Prior month balances are reconciled 30 days after the month close.
                </p>
              </ReactTooltip>
            </h4>
            <h2>{balance === '' ? '-' : balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h2>
          </div>
        </div>
      </div>
    );
  }
}
