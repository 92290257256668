import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import ProviderList from './providerList';
import ProviderDetails from './providerDetails';

class Scores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
    };
  }

  render() {
    const { match } = this.props;

    return (
      <React.Fragment>
        <Route exact path={`${match.path}`} component={() => <Redirect to={`${match.path}/list/1`} />} />
        <Route exact path={`${match.path}/list/:page`} component={ProviderList} />
        <Route exact path={`${match.path}/:id`} component={ProviderDetails} />
      </React.Fragment>
    );
  }
}

export default Scores;
