import React, { useEffect, useState } from 'react';
import RankTile from './rankTile';
import Circle from './circle';
import SectionSummaryScoreCard from './sectionSummaryScoreCard';
import { riskRankColorNameByGrade } from '../constants';
import { makeSummaryPieChart, makeSummaryColumnChart } from './sectionSummaryCharts.js';

const chartIDs = ['ch-dns', 'ch-tls', 'ch-app-sec', 'ch-vulns'];

const letterToInfoString = (letterGrade) => {
  switch (letterGrade) {
    case 'A':
      return 'The vendor exhibits a robust cybersecurity posture, with no major issues identified.';
    case 'B':
      return 'Although a few issues were identified, the vendor overall exhibits a solid cybersecurity posture.';
    case 'C':
      return 'Several issues were identified, although the vendor still exhibits an adequate cybersecurity posture.';
    case 'D':
      return 'Multiple issues were identified, suggesting the vendor has a lackluster security posture overall.';
    default:
      return 'Several major issues were identified, suggesting the vendor has a weak cybersecurity posture.';
  }
};

const prepareChartData = (dnsFindings, sslFindings, appSecFindings, rawFindingsCounts, cyberHygieneScore) => {
  const { overallScore: scan, dns, ssl, appSec, vulnerabilities: vulns, overallLetterGrade } = cyberHygieneScore || {};

  const data = {
    severities: { scan, dns, ssl, appSec, vulns },
    findingsCounts: {
      dns: {
        totalPotentialFindings: rawFindingsCounts.dnsDenominator,
        findings: rawFindingsCounts.dns,
        nonFindings: rawFindingsCounts.dnsDenominator - rawFindingsCounts.dns,
      },
      ssl: {
        totalPotentialFindings: rawFindingsCounts.sslDenominator,
        findings: rawFindingsCounts.ssl,
        nonFindings: rawFindingsCounts.sslDenominator - rawFindingsCounts.ssl,
      },
      appSec: {
        totalPotentialFindings: rawFindingsCounts.appSecDenominator,
        findings: rawFindingsCounts.appSec,
        nonFindings: rawFindingsCounts.appSecDenominator - rawFindingsCounts.appSec,
      },
    },
    categoryStrings: {
      dns: dnsFindings.title || 'Domain Name System',
      ssl: sslFindings.title || 'Transport Layer Security',
      appSec: appSecFindings.title || 'Application Security',
      vulns: 'Vulnerabilities',
    },
    letterGrade: overallLetterGrade,
    scoreInfoString: letterToInfoString(overallLetterGrade),
  };

  return data;
};

const SectionSummaryCyberHygiene = (props) => {
  /* ----------------------------------- */
  /*               Props                 */
  /* ----------------------------------- */
  const {
    viewDashboardOnClick,
    dnsFindings = {},
    sslFindings = {},
    appSecFindings = {},
    vulnsFindings = {},
    hasScanData,
    rawFindingsCounts,
    cyberHygieneScore,
  } = props;

  /* ----------------------------------- */
  /*               State                 */
  /* ----------------------------------- */
  const [overallScoreNumerator, setOverallScoreNumerator] = useState(0);
  const [overallScoreDenominator, setOverallScoreDenominator] = useState(100);
  const [scanSeverity, setScanSeverity] = useState('');
  const [dnsSeverity, setDnsSeverity] = useState('');
  const [sslSeverity, setSslSeverity] = useState('');
  const [appSecSeverity, setAppSecSeverity] = useState('');
  const [vulnsSeverity, setVulnsSeverity] = useState('');

  const [dnsFindingsCounts, setDnsFindingsCounts] = useState({});
  const [sslFindingsCounts, setSslFindingsCounts] = useState({});
  const [appSecFindingsCounts, setAppSecFindingsCounts] = useState({});

  const [dnsString, setDnsString] = useState('');
  const [sslString, setSslString] = useState('');
  const [appSecString, setAppSecString] = useState('');
  const [vulnsString, setVulnsString] = useState('');

  const [letterGrade, setLetterGrade] = useState('');
  const [scoreInfoString, setScoreInfoString] = useState('');

  const scoreColor = cyberHygieneScore?.overallLetterGrade
    ? riskRankColorNameByGrade(cyberHygieneScore.overallLetterGrade)
    : '';

  useEffect(() => {
    const chartsModel = prepareChartData(
      dnsFindings,
      sslFindings,
      appSecFindings,
      rawFindingsCounts,
      cyberHygieneScore,
    );
    const { severities, findingsCounts, categoryStrings, letterGrade, scoreInfoString } = chartsModel;

    setOverallScoreNumerator(cyberHygieneScore.overallScoreNumerator);
    setOverallScoreDenominator(cyberHygieneScore.overallScoreDenominator);

    setScanSeverity(severities.scan);
    setDnsSeverity(severities.dns);
    setSslSeverity(severities.ssl);
    setAppSecSeverity(severities.appSec);
    setVulnsSeverity(severities.vulns);

    setDnsFindingsCounts(findingsCounts.dns);
    setSslFindingsCounts(findingsCounts.ssl);
    setAppSecFindingsCounts(findingsCounts.appSec);

    setDnsString(categoryStrings.dns);
    setSslString(categoryStrings.ssl);
    setAppSecString(categoryStrings.appSec);
    setVulnsString(categoryStrings.vulns);

    setLetterGrade(letterGrade);
    setScoreInfoString(scoreInfoString);

    /* ------------------------------------ */
    /*        Instantiate the charts        */
    /* ------------------------------------ */
    makeSummaryPieChart(chartIDs[0], severities.dns, findingsCounts.dns);
    makeSummaryPieChart(chartIDs[1], severities.ssl, findingsCounts.ssl);
    makeSummaryPieChart(chartIDs[2], severities.appSec, findingsCounts.appSec);
    makeSummaryColumnChart(chartIDs[3], vulnsFindings);
  }, [
    dnsFindings,
    sslFindings,
    appSecFindings,
    vulnsFindings,
    rawFindingsCounts,
    cyberHygieneScore,
    cyberHygieneScore.overallScoreNumerator,
    cyberHygieneScore.overallScoreDenominator,
  ]);

  /* ---------------------------------- */
  /*               Render               */
  /* ---------------------------------- */

  if (overallScoreNumerator === -1 && hasScanData !== undefined)
    return (
      <div className="summary-section-container">
        <div className="section-info-text">Cyber hygiene information cannot be retrieved. Please try again later.</div>
      </div>
    );

  return (
    <div className="summary-section-container">
      {!hasScanData ? (
        <>
          <div className="section-inner-title animated-ellipsis">Currently Processing</div>
          <div className="section-info-text">Please refer to the Cyber Hygiene tab for the Scan status.</div>
        </>
      ) : (
        <>
          <div className="summary-section-average-score">
            <div>
              <b>Cyber Rank</b>
            </div>
            <RankTile chRank={true} value={letterGrade} />
            <div>
              <b>Cyber Score</b>
            </div>
            <Circle
              color={scoreColor}
              currentValue={overallScoreNumerator}
              maxValue={overallScoreDenominator}
              footnote={scanSeverity}
              small
            />
          </div>
          <div className="ch-card-container">
            <div className="ch-card">
              <SectionSummaryScoreCard
                categoryString={dnsString}
                severity={dnsSeverity}
                findingsCounts={dnsFindingsCounts}
                chartID={chartIDs[0]}
              />
            </div>
            <div className="ch-card">
              <SectionSummaryScoreCard
                categoryString={sslString}
                severity={sslSeverity}
                findingsCounts={sslFindingsCounts}
                chartID={chartIDs[1]}
              />
            </div>
            <div className="ch-card">
              <SectionSummaryScoreCard
                categoryString={appSecString}
                severity={appSecSeverity}
                findingsCounts={appSecFindingsCounts}
                chartID={chartIDs[2]}
              />
            </div>
            <div className="ch-card">
              <SectionSummaryScoreCard
                categoryString={vulnsString}
                severity={vulnsSeverity}
                findingsCounts={{}}
                chartID={chartIDs[3]}
              />
            </div>
          </div>
          <div className="summary-section-info ssi-media-query has-new-line">
            {scoreInfoString}
            {viewDashboardOnClick && (
              <div className="text-center">
                <button type="button" className="btn btn-warning" onClick={viewDashboardOnClick}>
                  View Dashboard
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SectionSummaryCyberHygiene;
