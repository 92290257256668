import React, { useEffect } from 'react';
import { useUpdateState } from '../../';
import { Link } from 'react-router-dom';

const BottomModalStage4 = (props) => {
  const [state, setState] = useUpdateState({
    vendor_id: '',
  });

  useEffect(() => {
    setState({ vendor_id: props.vendor_id });
    props.resetCatalogState();
  }, []);

  const { onProductModal, setBottomModalState } = props;
  const { vendor_id } = state;
  const linkToOverviewPage = props.linkToOverviewPage === undefined ? true : props.linkToOverviewPage;

  return (
    <React.Fragment>
      <div id="catalog-modal-stage-4" className="modal-report-info-box modal-mt-override">
        <p className="m-tal-c m-bold">Your request has been received.</p>
        <p className="m-tal-c m-bold">You will receive a confirmation email of your requested report(s).</p>

        <div className="d-flex">
          {vendor_id && linkToOverviewPage && !onProductModal && (
            <Link to={'/catalog/vendor/' + vendor_id + '/Overview'} className="modal-report-info-btn btn">
              View Vendor
            </Link>
          )}

          <button onClick={() => setBottomModalState('CLOSE')} className="modal-report-info-btn btn">
            Close
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BottomModalStage4;
