import React, { useState, useEffect } from 'react';
import '../../routes/vendor/catalogVendor.css';
import Busy from '../busy';
import ReactTooltip from 'react-tooltip';
import { withPrincipal } from '../../components';
import { currencyFormatter } from '../../lib/utils';
import InfoHygieneBadge from './infoHygieneBadge';

import './infoHygiene.css';

const InfoHygiene = (props) => {
  const { isLoadingVendorRisk = true, vendorData = {} } = props;
  const { riskTierNumber } = vendorData;

  const [isBusy, setIsBusy] = useState(true);
  const [ddrrExists, setDdrrExists] = useState(false);

  useEffect(() => {
    if (vendorData) {
      setDdrrExists(true);
    }
  }, []);

  useEffect(() => {
    if (isLoadingVendorRisk == false) {
      setIsBusy(isLoadingVendorRisk);
    }
  }, [isLoadingVendorRisk]);

  return (
    <div className="row pt-3">
      <div className="col-3 vendor-info-container">
        <Busy isBusy={isBusy}>
          <div className="info-hygiene-logo-container">
            {ddrrExists ? (
              <div className="info-hygiene-logo-wrapper">
                {!!(vendorData && vendorData.logo) && (
                  <img
                    alt="Logo"
                    src={vendorData.logo}
                    className="img-fluid info-hygiene-company-logo"
                    onError={(e) => {
                      e.target.src = '';
                      e.target.alt = '';
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="info-hygiene-waiting-text">
                <b className="info-hygiene-bold">
                  <i>
                    Vendor details are being processed, and you will be instantly notified upon availability. Cyber
                    Hygiene Scan progress can be tracked in the &quot;Available Reports&quot; table below.
                  </i>
                </b>
              </div>
            )}
            <br />
            <div>
              <div data-tip data-for="onHoverRisk">
                <InfoHygieneBadge ddrrTier={riskTierNumber} />
              </div>
              {riskTierNumber > 0 ? (
                <ReactTooltip id="onHoverRisk" place="top" effect="solid">
                  <p className="info-hygiene-hover-text">
                    This vendor is typically rated with a <strong>Tier {riskTierNumber}</strong> inherent risk by A2V
                    members.
                  </p>
                  <p className="info-hygiene-hover-text">
                    Assessment details are available in the vendor&apos;s Data-Driven Risk Rank.
                  </p>
                </ReactTooltip>
              ) : null}
            </div>
            <br />
            <h5 className="info-hygiene-bold info-hygiene-vendor-risk-text">Vendor Risk</h5>
            {vendorData.isMember && (
              <>
                <div className="col-sm-12 col-md-6 header-logo info-hygiene-logo" data-tip data-for="onHoverFisLogo">
                  <img className="logo info-hygiene-logo" alt="fis logo" src="/static/images/fisLogo-70px.png" />
                </div>
                <ReactTooltip id="onHoverFisLogo" place="top" effect="solid">
                  <p className="info-hygiene-hover-text">This vendor is a member of the Asset-2-Vendor Network.</p>
                </ReactTooltip>
              </>
            )}
          </div>
          <div>
            <p>
              <b>Website: </b> {vendorData.domain || '-'}{' '}
            </p>
            <p>
              <b>NAICS: </b>
              {ddrrExists && vendorData.naics ? vendorData.naics : 'N/A'}
            </p>
            <p>
              <b>Estimated Revenue: </b>
              {ddrrExists && vendorData.revenue ? vendorData.revenue : 'N/A'}
            </p>
            <p>
              <b>Estimated Employees: </b>
              {ddrrExists && vendorData.employees ? currencyFormatter(vendorData.employees) : 'N/A'}
            </p>
            <p>
              <b>Company Description: </b>
              {ddrrExists && vendorData.description
                ? vendorData.description.length > 1000
                  ? vendorData.description.substring(0, 999) + '...'
                  : vendorData.description
                : 'N/A'}
            </p>
          </div>
        </Busy>
      </div>
      <div className="col-9 pr-0">{props.children}</div>
    </div>
  );
};

export default withPrincipal(InfoHygiene);
