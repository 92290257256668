import React, { Component } from 'react';
import config from '../../config';

import { CardRow, Table } from '../../components/riskRankOrg';

class RiskRankOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: true,
    };
  }

  componentDidMount() {
    this.doDdrr();
  }

  doDdrr() {
    fetch(config.api.urlFor('ddrrOrganization', { id: this.props.match.params.id }))
      .then((res) => res.json())
      .then((data) => this.setState({ data: data, isBusy: false }));
  }

  render() {
    const { data, isBusy } = this.state;

    const orgDetails = data ? data[0] : {};
    const { riskAreas = [] } = orgDetails;

    return (
      <div>
        <CardRow details={orgDetails} isBusy={isBusy} />
        <Table data={riskAreas} />
      </div>
    );
  }
}

export default RiskRankOrg;
