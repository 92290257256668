import React from 'react';
import '../prototypes';

const withPrincipal = (Wrapped) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const newProps = { ...props };

    newProps.principal = window.$app.getState('principal');

    return React.createElement(Wrapped, newProps);
  };
};

export default withPrincipal;
