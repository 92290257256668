import React, { Component } from 'react';

import HexSeverity from './../../../reusable/hexSeverity';
import SeverityPill from './../../../reusable/severityPill';

import './vendorOverview.css';

export default class VendorOverview extends Component {
  render() {
    const date = new Date();
    const { vendor = {}, scores = {}, compliance = {}, template = '' } = this.props;
    const { total_risk = '' } = compliance;
    const { name = '', domain = '', description = '' } = vendor;
    const {
      overall = '',
      manufacturing = '',
      foreignOwnership = '',
      mergerAcquisition = '',
      physicalPresence = '',
      corporateFamilies = '',
      cyberPresence = '',
      overall_security = '',
      watchlist = '',
      cyberHygiene = '',
      breaches = '',
      vulnSolution = '',
    } = scores;

    return (
      <div id="provenance-vendor-overview">
        <div className="p-vo-flex-holder">
          <div className="meta-info">
            <p className="p-title">Vendor Continuous Monitoring Report</p>
            <p className="m-ital">{template === 'cmrs' ? 'Standard Version' : 'Enhanced Version'}</p>
            <p className="p-name">{name}</p>
            <p className="">
              Website: <a href={domain}>{domain}</a>
            </p>
            <p className="">Printed Date: {date.toLocaleDateString()}</p>
            <p className="p-description-title">Company Description</p>
            <p className="">{description}</p>
          </div>
          <div className="provenance-risk">
            <HexSeverity letter={overall[0]} />
            <h3>Provenance Risk</h3>
            {template === 'cmrs' ? null : (
              <span>
                <SeverityPill severity={manufacturing} /> Manufacturing
              </span>
            )}
            {template === 'cmrs' ? null : (
              <span>
                <SeverityPill severity={foreignOwnership} /> Foreign Ownership
              </span>
            )}
            <span>
              <SeverityPill severity={mergerAcquisition} /> Mergers and Acquisitions
            </span>
            {template === 'cmrs' ? null : (
              <span>
                <SeverityPill severity={physicalPresence} /> Physical Presence
              </span>
            )}
            {template === 'cmrs' ? null : (
              <span>
                <SeverityPill severity={corporateFamilies} /> Corporate Families
              </span>
            )}
            <span>
              <SeverityPill severity={cyberPresence} /> Cyber Presence
            </span>
          </div>
          <div className="security-risk">
            <HexSeverity letter={overall_security[0]} />
            <h3>Security Risk</h3>
            {template === 'cmrs' ? null : (
              <span>
                <SeverityPill severity={watchlist} /> Watchlist
              </span>
            )}
            <span>
              <SeverityPill severity={cyberHygiene} /> Cyber Hygiene
            </span>
            <span>
              <SeverityPill severity={breaches} /> Breaches
            </span>
            {template === 'cmrs' ? null : (
              <span>
                <SeverityPill severity={total_risk} /> Compliance
              </span>
            )}
            <span>
              <SeverityPill severity={vulnSolution} /> Vulnerability Solution
            </span>
          </div>
        </div>
      </div>
    );
  }
}
