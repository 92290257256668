/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

import InviteButton from '../../components/inviteButton';
import config from '../../config';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

import { urlFromPath, pageIsValid, toastError } from '../../lib/utils';
import { withPrincipal } from '../../components';

import ActionButton from '../../components/actionButton';

import { SortCarets } from '../../components/assessmentList';

import { MdDeleteForever } from 'react-icons/md';

import '../subscriberAndUserLists.css';

const Users = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  sort,
  roles,
  canEditUsers,
  canResetUsers,
  canDeleteUsers,
  canResetUserPassword,
  reloadData,
}) => {
  // eslint-disable-next-line no-unused-vars
  const columnFormatter = (cell) => {
    return (
      <span title={cell && cell.props ? cell.props.children : cell}>
        {cell && cell.props ? cell.props.children : cell}
      </span>
    );
  };

  const columns = [
    {
      dataField: 'emailAddress',
      text: 'email address',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '30%' },
      classes: 'subscriber-users-table-columns',
    },
    {
      dataField: 'firstName',
      text: 'first name',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      classes: 'subscriber-users-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'lastName',
      text: 'last name',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      classes: 'subscriber-users-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'created',
      text: 'created',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '8%' },
      classes: 'subscriber-users-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'roleNames',
      text: 'role',
      editable: false,
      headerStyle: { width: '12%' },
      classes: 'subscriber-users-table-columns',
      formatter: columnFormatter,
    },
    {
      dataField: 'isDeleted',
      text: 'is deleted',
      sort: true,
      editable: false,
      sortCaret: SortCarets,
      headerStyle: { width: '5%' },
      classes: 'subscriber-users-table-columns subscriber-users-table-is-deleted-column',
      formatter: (cell, row) => {
        return row.isDeleted ? <MdDeleteForever className="deleted-instance-icon" title="Deleted" /> : '';
      },
    },
    {
      dataField: 'actions',
      text: 'Action',
      editable: false,
      formatter:
        canEditUsers || canResetUsers || canDeleteUsers || canResetUserPassword
          ? (cell, row) => {
              return !row.isDeleted ? (
                <div className="btn-group">
                  {canEditUsers && row.allowEditting && (
                    <ActionButton
                      action="editUser"
                      associations={roles}
                      inUserData={row}
                      reloadDataInParent={reloadData}
                    />
                  )}
                  {canResetUsers && row.allowEditting && <ActionButton userID={row.id} action="resetUser" />}
                  {canResetUserPassword && row.allowEditting && (
                    <ActionButton userEmailAddress={row.emailAddress.props.children} action="resetUserPassword" />
                  )}
                  {canDeleteUsers && row.allowEditting && (
                    <ActionButton userID={row.id} action="deleteUser" onAction={reloadData} />
                  )}
                </div>
              ) : (
                ''
              );
            }
          : null,
    },
  ];

  const rowStyle = (row) => {
    return row.isDeleted ? { backgroundColor: '#ff8080' } : {};
  };

  return (
    <div className="subscriber-users-table mb-5">
      <BootstrapTable
        remote
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={sort}
        pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true })}
        onTableChange={onTableChange}
        cellEdit={cellEditFactory({ mode: 'click' })}
        rowStyle={rowStyle}
      />
    </div>
  );
};

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      model: [],
      isBusy: true,
      search: '',
      associations: [],
      pageSize: 10,
      modalShow: false,
      needsDataReload: false,
      canEditUsers: false,
      canDeleteUsers: false,
      canSendInvitations: false,
      canResetUserPassword: false,
    };

    this.reloadData = this.reloadData.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    const { search } = this.props.location.query;
    const { page, orderBy, dir } = this.props.match.params;
    const { pageSize } = this.state;
    const { principal } = this.props;

    this.setState({
      canEditUsers: principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.admin.user.save')),
      canDeleteUsers: principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.admin.user.delete')),
      canSendInvitations: principal.roles.some((r) =>
        r.permissions.some((p) => p === 'subscriber.admin.invitations.send'),
      ),
      canResetUserPassword: principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.admin.user.save')),
    });

    // First read roles data from DB
    fetch(config.api.urlFor('roles', { search: '', page: '', pageSize: '', orderBy: '', asc: '' }))
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          roles:
            data && data.rows && data.rows.length ? data.rows.map((row) => ({ label: row.nameUI, value: row.id })) : [],
        });

        // Then read the users data
        this.getData(search, page, pageSize, orderBy, dir);
      });
  }

  componentDidUpdate(prevProps) {
    const { search: cSearch = '' } = this.props.location.query;
    const { page: cPage, orderBy: cOrderBy, dir: cDir } = this.props.match.params;

    const { search: pSearch = '' } = prevProps.location.query;
    const { page: pPage, orderBy: pOrderBy, dir: pDir } = prevProps.match.params;

    const { pageSize, needsDataReload, totalRecords } = this.state;

    let updatedPage = cPage,
      pageUpdated = false;

    if (!pageIsValid(cPage, totalRecords, pageSize)) {
      updatedPage = '1';
      pageUpdated = true;
    }

    if (
      !pageUpdated &&
      updatedPage === pPage &&
      cOrderBy === pOrderBy &&
      cDir === pDir &&
      cSearch === pSearch &&
      !needsDataReload
    ) {
      return;
    }

    this.getData(cSearch, updatedPage, pageSize, cOrderBy, cDir);
  }

  reloadData() {
    this.setState({ needsDataReload: true });
  }

  getData(search, page, pageSize, orderBy, dir) {
    search = search || '';
    page = parseInt(page || 1, 10);
    pageSize = parseInt(pageSize || this.state.pageSize, 10);
    orderBy = orderBy || 'username';
    let asc = dir === 'desc' ? 0 : 1;
    const subscriberID = this.props.principal.subscriptionID;
    const maxRolePriority = this.props.principal.maxRolePriority;

    this.setState({ isBusy: true, needsDataReload: false });

    let pathname = urlFromPath(this.props.match.path, { page, orderBy, dir });

    if (search) {
      pathname = pathname.concat(`?search=${search}`);
    }

    this.props.history.push(pathname);

    fetch(
      config.api.urlFor('usersWithRoles', {
        search: search,
        page: page,
        pageSize: pageSize,
        orderBy: orderBy,
        asc: asc,
        subscriberID: subscriberID,
        showDeleted: true,
      }),
    )
      .then((result) => result.json())
      .then((response) => {
        if (response.isSuccess) {
          this.setState({
            search: search,
            page,
            orderBy,
            dir,
            model: response.data.rows.map((row) => ({
              id: row.id,
              emailAddress:
                !row.isDeleted && row.maxRolePriority <= maxRolePriority ? (
                  <Link to={`/subscriber/users/${row.id}`} title={row.emailAddress}>
                    {row.emailAddress}
                  </Link>
                ) : (
                  row.emailAddress
                ),
              firstName: row.firstName || '-',
              lastName: row.lastName || '-',
              created: new Date(row.created).toLocaleDateString(),
              roleNames: row.roles.map((r) => r.nameUI).join(', '),
              roleIDs: row.roles.map((r) => r.id),
              allowEditting: row.maxRolePriority <= maxRolePriority,
              isDeleted: row.isDeleted,
            })),
            totalRecords: response.data.totalRecords || 0,
            isBusy: false,
          });
        } else {
          this.setState({ isBusy: false });

          if (response.message) {
            toastError(response.message);
          }
        }
      })
      .catch(() => this.setState({ isBusy: false }));
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange(type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, ...leftovers }) {
    // leaving the unused variables as they are valid options we may implement in the future
    const {
      params: { page: cPage, orderBy: cOrderBy, dir: cDir },
      path,
    } = this.props.match;

    if (cPage === page && cOrderBy === sortField && cDir === sortOrder) {
      return;
    }

    this.props.history.push(urlFromPath(path, { page, orderBy: sortField || cOrderBy, dir: sortOrder || cDir }));
  }

  onSearchSubmit(e) {
    e.preventDefault();

    const { search, pageSize, orderBy, dir } = this.state;

    this.getData(search, 1, pageSize, orderBy, dir);
  }

  render() {
    const { match } = this.props;
    const { orderBy, dir, page } = match.params;
    const {
      model,
      totalRecords,
      search,
      pageSize,
      roles,
      canEditUsers,
      canDeleteUsers,
      canSendInvitations,
      canResetUserPassword,
    } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light mb-3">
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <form className="form-inline mr-auto" onSubmit={this.onSearchSubmit.bind(this)}>
              <input
                id="search"
                type="text"
                className="form-control mr-2"
                placeholder="search"
                value={search}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
            </form>
            {canSendInvitations && (
              <ul className="navbar-nav mt-2 mt-lg-0">
                <li className="nav-item">
                  <InviteButton
                    buttonTitle="Invite New User"
                    associations={roles}
                    mode="new"
                    context="role"
                    submitUrl={config.api.urlFor('inviteUser')}
                    reloadDataInParent={this.reloadData}
                  />
                </li>
              </ul>
            )}
          </div>
        </nav>
        <Users
          data={model}
          page={parseInt(page, 10)}
          sizePerPage={pageSize}
          totalSize={parseInt(totalRecords, 10)}
          onTableChange={this.handleTableChange}
          sort={[{ dataField: orderBy, order: dir }]}
          roles={roles}
          canEditUsers={canEditUsers}
          canResetUsers={canSendInvitations}
          canDeleteUsers={canDeleteUsers}
          canResetUserPassword={canResetUserPassword}
          reloadData={this.reloadData}
        />
      </div>
    );
  }
}

export default withPrincipal(UserList);
