import React, { Component } from 'react';

import './content.css';

export default class Content extends Component {
  render() {
    const { children } = this.props;
    return <div id="postern-content">{children}</div>;
  }
}
