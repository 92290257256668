import React, { Component } from 'react';
import Modal from 'react-modal';
import { Spinner, withPrincipal } from '../components';
import { A2VLOGOBLUE } from '../constants/color';
import config from '../config';

import '../routes/vendor/catalogVendor.css';
import './claimPageButton.css';

const style = {
  modalStyle: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
    },
  },
  claimPageBtnStyle: {
    backgroundColor: A2VLOGOBLUE,
  },
};

class ClaimPageButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalConfirmationIsOpen: false,
      modalFailure: false,
      user: props.principal,
      isBusy: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeModalFailure = this.closeModalFailure.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
  }

  toggleModal() {
    this.setState((state) => ({ modalIsOpen: !state.modalIsOpen }));
  }

  openConfirmationModal() {
    this.setState({ modalConfirmationIsOpen: !this.state.modalConfirmationIsOpen });
  }
  closeModalFailure() {
    this.setState({ modalFailure: false });
  }

  submitRequest(event) {
    event.preventDefault();
    this.setState({ isBusy: true });

    const { userName, userEmail, userNotes } = this.state;
    const { vendorName, vendorID, vendorDDRRid, isPageClaimed } = this.props;

    // if page is already claimed, hit the request-page-updates endpoint... otherwise, hit the claim-page endpoint
    const endpointName = isPageClaimed ? 'requestPageUpdates' : 'claimCatalogPage';
    fetch(config.api.urlFor(endpointName), {
      method: 'POST',
      body: {
        untrustedInputUserName: userName,
        untrustedInputUserEmail: userEmail,
        untrustedInputUserNotes: userNotes,
        vendorName: vendorName,
        vendorID: vendorID,
        vendorDDRRid: vendorDDRRid,
      },
    })
      .then((res) => {
        this.setState({ isBusy: false });
        if (res.status === 200) {
          this.toggleModal();
          this.openConfirmationModal();
        } else {
          this.setState({ modalFailure: true });
        }
      })
      .catch(() => this.setState({ modalFailure: true, isBusy: false }));
  }

  render() {
    const {
      userName = '',
      userEmail = '',
      userNotes = '',
      isBusy,
      modalIsOpen,
      modalConfirmationIsOpen,
      modalFailure,
    } = this.state;
    const { isPageClaimed } = this.props;
    return (
      <>
        <button
          data-test-id="ClaimPageButton-button-1"
          className={'btn head-btn claim-page-btn'}
          onClick={this.toggleModal}
          style={style.claimPageBtnStyle}
        >
          {isPageClaimed ? 'Request Updates' : 'Claim This Page'}
        </button>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.toggleModal}
          style={style.modalStyle}
          contentLabel="Dispute Input"
        >
          <form onSubmit={this.submitRequest}>
            <div>
              <label data-test-id="ClaimPageButton-modal-label-1" className="modal-main-label">
                <b>
                  {isPageClaimed
                    ? 'Please provide context for what changes or updates you would like to inquire about for this page.'
                    : 'If you are a member, please provide your contact information to request verification and someone from Fortress will respond to assist you.'}
                </b>
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="userName" className="modal-label">
                Name: *
              </label>
              <input
                type="text"
                id="userName"
                className="form-control"
                required="required"
                value={userName}
                onChange={(e) => this.setState({ userName: e.target.value })}
                maxLength={64}
              />
            </div>

            <div className="form-group">
              <label htmlFor="userEmail" className="modal-label">
                Email: *
              </label>
              <input
                id="userEmail"
                type="email"
                className="form-control"
                required="required"
                data-error="Valid email is required."
                onChange={(e) => this.setState({ userEmail: e.target.value })}
                value={userEmail}
                maxLength={256}
              />
            </div>

            <div className="form-group">
              <label className="modal-label">{isPageClaimed ? 'Request Details: *' : 'Notes: *'}</label>
              <div className="modal-textarea-div">
                <textarea
                  data-test-id="ClaimPageButton-textarea"
                  className="form-control modal-textarea"
                  rows="4"
                  cols="50"
                  value={userNotes}
                  onChange={(e) => this.setState({ userNotes: e.target.value })}
                  maxLength={1000}
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-outline-primary btn-nav float-right"
              data-test-id="ClaimPageButton-button-submit"
              disabled={!userName || !userEmail || !userNotes || isBusy}
            >
              Submit
              <Spinner style={{ marginLeft: '5px' }} isVisible={isBusy} />
            </button>
            <button
              data-test-id="ClaimPageButton-button-cancel"
              className="btn btn-outline-primary btn-nav float-right modal-cancel-btn"
              onClick={this.toggleModal}
            >
              Cancel
            </button>
          </form>
        </Modal>
        <Modal
          isOpen={modalConfirmationIsOpen}
          onAfterOpen={this.afterOpenConfirmationModal}
          onRequestClose={this.closeConfirmationModal}
          style={style.modalStyle}
          contentLabel="Dispute Confirm"
        >
          <form data-test-id="ClaimPageButton-modal-2">
            <div>
              <label data-test-id="ClaimPageButton-modal-label-1">
                <b>We have received your request and will be in touch soon.</b>
              </label>
            </div>
          </form>
          <div>
            <button
              data-test-id="ClaimPageButton-button-close"
              className="btn btn-outline-primary btn-nav float-right"
              onClick={this.openConfirmationModal}
            >
              Close
            </button>
          </div>
        </Modal>
        <Modal isOpen={modalFailure} style={style.modalStyle}>
          <form data-test-id="ClaimPageButton-modal-2">
            <div>
              <label data-test-id="ClaimPageButton-modal-label-1">
                <b>Something went wrong, please contact support for assistance.</b>
              </label>
            </div>
          </form>
          <div>
            <button
              data-test-id="ClaimPageButton-button-close"
              className="btn btn-outline-primary btn-nav float-right"
              onClick={this.closeModalFailure}
            >
              Close
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default withPrincipal(ClaimPageButton);
