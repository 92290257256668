import React, { useEffect, useRef } from 'react';
import { useUpdateState } from '../../';
import ProductReportBox from './productReportBox';
import CatalogDropZone from './catalogDropZone';
import CatalogReportGenericInfo from './catalogReportGenericInfo';
import { assessmentTypes } from '../../../constants/assessmentTypes';

const BottomModalStage2 = (props) => {
  const [state, setState] = useUpdateState({
    productName: '',
    productVersionOrNumber: '',
    currentFiles: [],
    requestedProductReports: {
      requestedProductArray: [],
    },
    hasErrors: {
      productName: false,
      productVersionOrNumber: false,
    },
  });

  const { requestedProductReports, hasErrors, productName, productVersionOrNumber, currentFiles, isSBOMselected } =
    state;

  const {
    requestedReports,
    handleCheckbox,
    reportDescriptions = {},
    reportOfferings = {},
    setParentState = () => {},
    modalProductData,
    setBottomModalState,
  } = props;

  const topOfModalRef = useRef();

  const setCurrentFiles = (value) => {
    setState({ currentFiles: value });
  };

  useEffect(() => {
    let { requestedProductReports, reportOfferings, modalProductData } = props;

    if (modalProductData && modalProductData.name) {
      setState({ productName: modalProductData.name });
    }

    reportOfferings.assetReports.map((reportType) => {
      const hasReportProperty = Object.prototype.hasOwnProperty.call(requestedProductReports, reportType);
      if (!hasReportProperty) {
        requestedProductReports[reportType] = 0;
      }
    });

    setState({ requestedProductReports: props.requestedProductReports });
  }, []);

  useEffect(() => {
    const isSBOMselected =
      Object.keys(requestedReports).filter(
        (report) => assessmentTypes.SBOM.find((s) => s.name === report) && requestedReports[report] === 1,
      ).length > 0;

    setState({
      isSBOMselected,
      hasErrors: { ...hasErrors, productVersionOrNumber: !isSBOMselected ? false : hasErrors.productVersionOrNumber },
    });
  }, [requestedReports]);

  const handleInputBox = (e, keyName) => {
    let val = e.target.value;

    if (keyName === 'requestedVendorDomain') {
      val = val.toLowerCase();
    }

    setState({ [keyName]: val });
  };

  const handleProductCheckbox = (i, reportTypesArray) => {
    let requestedProductReports = state.requestedProductReports;

    reportTypesArray.map((reportType) => {
      requestedProductReports[reportType]--;
    });

    requestedProductReports.requestedProductArray.splice(i, 1);

    const isSBOMselected =
      Object.keys(requestedProductReports).filter(
        (report) => assessmentTypes.SBOM.find((s) => s.name === report) && requestedProductReports[report] === 1,
      ).length > 0;

    setState({ requestedProductReports, isSBOMselected });
  };

  const handleKeydownCheckbox = (data) => {
    return (event) => {
      if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
        event.preventDefault();
        props.handleCheckbox(data);
      }
    };
  };

  const addToProductBox = () => {
    if (productName === '' || productName.trim() === '') {
      setState({ hasErrors: { productName: true } });
      topOfModalRef.current?.scrollIntoView({ block: 'nearest' });
      return;
    }

    if (isSBOMselected && (productVersionOrNumber === '' || productVersionOrNumber.trim() === '')) {
      setState({ hasErrors: { productVersionOrNumber: true } });
      topOfModalRef.current?.scrollIntoView({ block: 'nearest' });
      return;
    }

    const { requestedReports = {}, reportOfferings = {}, modalProductData } = props;
    const { assetReports = [] } = reportOfferings;
    let requestedProductReports = state.requestedProductReports;

    let hasReportChecked = false;
    let arrayOfRequestedReports = [];
    assetReports.forEach((reportType) => {
      let addToCount = 0;
      if (requestedReports[reportType]) {
        addToCount = requestedReports[reportType];

        if (addToCount > 0) {
          hasReportChecked = true;
          arrayOfRequestedReports.push(reportType);
        }
      }

      // Add to or initalize
      if (requestedProductReports[reportType]) {
        requestedProductReports[reportType] += addToCount;
      } else {
        requestedProductReports[reportType] = addToCount;
      }
    });

    // If a report is selected to submit information toward - else - give error
    if (hasReportChecked) {
      requestedProductReports['requestedProductArray'].push({
        productName: productName.trim(),
        productNumber: productVersionOrNumber.trim(),
        fileArray: currentFiles,
        reportsArray: arrayOfRequestedReports,
      });

      // Reset State
      setState({
        productName: modalProductData ? productName.trim() : '',
        productVersionOrNumber: '',
        currentFiles: [],
        hasErrors: { productName: false, productVersionOrNumber: false },
        requestedProductReports: requestedProductReports,
      });

      // Wipe check boxes
      // Boyan Karakolev 2021-07-13: I am commenting this out, because it seems to not be needed.
      //props.handleCheckbox("", true)
    } else {
      setState({
        hasErrors: { noReport: true },
      });
    }
  };

  return (
    <div id="catalog-modal-stage-2" className="modal-report-info-box modal-mt-override">
      <p className="m-tal-c m-bold">A Product Assessment or Report has been selected.</p>
      <p className="m-tal-c m-bold">Please provide the following information:</p>
      <br />

      <div className="pb-3">
        <div className="input-title-text" ref={topOfModalRef}>
          Product Name*
        </div>
        {hasErrors.productName && <span className="m-error"> Please enter a Product Name </span>}

        <input
          id="product-name"
          className={'form-control modal-s-2-input' + (hasErrors.productName ? ' is-invalid' : '')}
          type="text"
          name="add-vendor"
          placeholder="Product Name"
          onChange={(e) => handleInputBox(e, 'productName')}
          value={productName}
          disabled={modalProductData ? true : false}
          maxLength={255}
        />
      </div>

      <div className="pb-3">
        <div className="input-title-text">{'Model Version / Model Number' + (isSBOMselected ? '*' : '')}</div>
        {hasErrors.productVersionOrNumber && (
          <span className="m-error"> Please enter a Product Model Version or Number </span>
        )}
        <input
          id="product-model-number"
          className={'form-control modal-s-2-input'}
          type="text"
          name="add-vendor"
          placeholder="Product Model Version or Number"
          onChange={(e) => handleInputBox(e, 'productVersionOrNumber')}
          value={productVersionOrNumber}
          maxLength={255}
        />
      </div>

      <div className="pb-3">
        <div className="input-title-text">Attachments (ex. Documentation, User Manual, Configuration Guides, BOMs)</div>
        <div className="catalog-drop-zone">
          <CatalogDropZone setCurrentFiles={setCurrentFiles} />
        </div>
      </div>

      <div className="input-title-text">Reports Requested:</div>
      {hasErrors.noReport && <span className="m-error"> Please select a report to request </span>}
      {reportOfferings.assetReports.map((data, i) => {
        return (
          <div
            className="modal-report-info"
            onClick={() => handleCheckbox(data)}
            onKeyDown={handleKeydownCheckbox(data)}
            tabIndex={0}
            key={i}
          >
            <input
              className="modal-report-tick-box"
              type="checkbox"
              checked={requestedReports[data] || false}
              tabIndex={-1}
            />
            <CatalogReportGenericInfo title={data} reportDescriptions={reportDescriptions} />
          </div>
        );
      })}

      <button
        className="add-requested-btn btn"
        onClick={() => {
          addToProductBox();
        }}
      >
        Add Requested
      </button>

      <div className="input-title-text">Added Products:</div>
      <ProductReportBox
        requestedProductReports={requestedProductReports}
        handleProductCheckbox={handleProductCheckbox.bind(this)}
        canDelete={true}
      />

      <div className="m-flex-out">
        <span>
          <button
            onClick={() => {
              setParentState('requestedProductReports', requestedProductReports);
              setBottomModalState('PREV');
            }}
            className="modal-report-info-btn btn"
          >
            Back
          </button>
        </span>
        <span>
          <button
            onClick={() => {
              setParentState('requestedProductReports', requestedProductReports);
              setBottomModalState('NEXT');
            }}
            className="modal-report-info-btn btn"
          >
            Next
          </button>
        </span>
      </div>
    </div>
  );
};

export default BottomModalStage2;
