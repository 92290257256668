import React, { Component } from 'react';

import TileComponent from './tileComponent';

// import "./corporateFamilies.css"

export default class CorporateFamilies extends Component {
  render() {
    const { scores = {}, corporateFamilies = {} } = this.props;
    const { corporateFamilies: sCorporateFamilies = '' } = scores;

    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          {sCorporateFamilies === 'high'
            ? 'During our review of the corporate families, some were found in countries of adversarial presence.  Further investigation concludes that the foreign influence from corporate families is likely significant.'
            : 'During our review of the corporate families, none were found in countries of adversarial presence.  Further investigation concludes that the foreign influence from corporate families is not likely significant, and thus no finding is raised.'}
        </div>

        <div className="provenance-tile-holder">
          {corporateFamilies.map((row, i) => {
            if (row.highRisk == true) {
              return (
                <TileComponent
                  key={'corp-fam-tile-' + i}
                  title={row.name}
                  highlight={row.corporate_family_member}
                  footer={row.country}
                />
              );
            } else {
              return (
                <TileComponent
                  key={'corp-fam-tile-' + i}
                  title={row.name}
                  highlight={row.corporate_family_member}
                  footer={row.country}
                  color="#1ab394"
                />
              );
            }
          })}
        </div>

        <footer className="footer-p-wrapper">
          <p>
            All observable branches of an organization are found using a third-party tool for corporate hierarchy
            search, including parent, sisters, and subsidiaries. Then, each branch is identified with city and country
            headquarters location.
          </p>
        </footer>
      </React.Fragment>
    );
  }
}
