import React from 'react';
import Findings from '../findings';

const categoryContainer = {
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  borderRadius: '5px',
  padding: '15px',
  margin: '0em 0em',
};

const categorySection = {
  margin: '0em 2em 10em 2em',
};

const FindingsCategory = (props) => {
  const { category, findingsData = {}, findingsAvgScore, tagId, targetsToggleEnabled } = props;

  return (
    <>
      <div style={categoryContainer}>
        {findingsData ? (
          <Findings
            category={category}
            chartjs={findingsData.chartjs}
            stats={findingsData.stats}
            title={findingsData.title}
            description={findingsData.description}
            targets={findingsData.targets}
            sections={findingsData.sections}
            flatten={false}
            avg_score={findingsAvgScore}
            tagId={tagId}
            targetsToggleEnabled={targetsToggleEnabled}
          />
        ) : (
          <>
            <div style={categorySection}>
              <b>{category} Findings Summary:</b>
            </div>
            <div style={categorySection}>
              <b>{category} Detailed Findings:</b>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FindingsCategory;
