import React, { Component } from 'react';

import './scoringOverview.css';

export default class ScoringOverview extends Component {
  render() {
    return (
      <div id="scoring-overview">
        <h5>Inherent Risk Methodology</h5>
        <p>
          The Data-Driven Risk Rank (DDRR) seeks to predict the inherent risk of a provider’s cyber risk to an
          organization. Nine areas of risk are evaluated to determine inherent risk estimation. This scoring is utilized
          by the Asset to Vendor Network as a prioritization tool for where to begin the risk assessment process. The
          process uses web crawlers, API’s, machine learning, business rules and analyst review to profile
          organizations. The DDRR is not intended to replace internal processes for risk assessment selection but rather
          as a tool to (1) assist in prioritization and to (2) perform quality control over existing risk assessment
          selection procedures.
        </p>
        <h5>Categories</h5>
        <table className="table-bordered">
          <thead>
            <tr>
              <th score="col">Category</th>
              <th className="m-tal-c" score="col">
                Max Score
              </th>
              <th score="col">Detection Objectives</th>
              <th score="col">Key Drivers</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Data Access</td>
              <td className="m-tal-c">670</td>
              <td>Handling of confidential data</td>
              <td>Primary services</td>
            </tr>
            <tr>
              <td scope="row">Cloud</td>
              <td className="m-tal-c">600</td>
              <td>
                Data stored or processed in a cloud environment (e.g., SaaS, PaaS, web portal, application/data hosting,
                etc.)
              </td>
              <td>Detection of online portals and website descriptions/text</td>
            </tr>
            <tr>
              <td scope="row">Fourth Party</td>
              <td className="m-tal-c">525</td>
              <td>Dependency and exposure to fourth parties</td>
              <td>Overlay of services and size*</td>
            </tr>
            <tr>
              <td scope="row">Offshore</td>
              <td className="m-tal-c">500</td>
              <td>
                Services rendered outside the US, particularly in countries where bribery, corruption or poverty are
                high
              </td>
              <td>Detection of offshore cyber assets and website scraping</td>
            </tr>
            <tr>
              <td scope="row">System Access</td>
              <td className="m-tal-c">400</td>
              <td>Ability to modify internal applications or hardware</td>
              <td>Primary services</td>
            </tr>
            <tr>
              <td scope="row">Strategic</td>
              <td className="m-tal-c">250</td>
              <td>Limited sourcing options or barriers to changing provider</td>
              <td>Competitor count, primary services</td>
            </tr>
            <tr>
              <td scope="row">Financial</td>
              <td className="m-tal-c">50</td>
              <td>Inability to fulfill commitments due to financial position</td>
              <td>Revenue and employee size</td>
            </tr>
            <tr>
              <td scope="row">Reputation</td>
              <td className="m-tal-c">50</td>
              <td>Customer-facing dependencies</td>
              <td>Overlay of services and size*</td>
            </tr>
            <tr>
              <td scope="row">Physical Access</td>
              <td className="m-tal-c">40</td>
              <td>On-premise access to client facilities</td>
              <td>Primary services</td>
            </tr>
            <tr>
              <th>Total</th>
              <th className="m-tal-c">3,085</th>
              <th></th>
              <th></th>
            </tr>
          </tbody>
        </table>
        <small>
          *Changes to the Reputation and Fourth Party DDRR scoring are slated for Jan 1, 2021 to incorporate the overlay
          of services and size. Currently, only primary service is used.
        </small>
        <br />
        <br />
        <h5>Scoring Bands &#38; Normalized Distribution</h5>
        <p>
          DDRR’s risk scoring has four (4) bands from Low, Moderate, High to Critical that are designed to yield a
          normalized distribution on large, randomly sampled vendor populations. Actual results will vary from
          organization to organization.
        </p>
        <table className="table-bordered">
          <thead>
            <tr>
              <th>Risk Band</th>
              <th className="m-tal-c">Score</th>
              <th className="m-tal-c">Normalized Distribution</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Low</td>
              <td className="m-tal-c">&lt; 500 </td>
              <td className="m-tal-c">30%</td>
            </tr>
            <tr>
              <td>Moderate</td>
              <td className="m-tal-c">&lt; 750 </td>
              <td className="m-tal-c">55%</td>
            </tr>
            <tr>
              <td>High</td>
              <td className="m-tal-c">&lt; 1,000 </td>
              <td className="m-tal-c">10%</td>
            </tr>
            <tr>
              <td>Critical</td>
              <td className="m-tal-c">&gt;= 1,000</td>
              <td className="m-tal-c">5%</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h5>Related Entity Discovery (RED) Methodology</h5>
        <p>
          Related Entity Discovery (RED) gathers open-source intelligence (OSINT) and then autonomously evaluates for
          possible relations to entities that have been prohibited under National Defense Authorization Act (NDAA)
          Section 889 and Executive Order (EO) 13971.
        </p>
        <ul>
          <li>
            FIS maintains and updates a database of the companies prohibited by the Section 889 of the NDAA and EO
            13971. This includes affiliates and subsidiaries.
          </li>
          <li>
            RED searches public data and the target’s website for evidence of potential relationships to any banned
            company.
          </li>
          <li>RED then uses with deep learning classifiers to predict whether a relationship exists.</li>
          <li>
            Results from RED should be validated as many relationship types will not result in a compliance issue.
          </li>
        </ul>
        <h5>RED Compliance Summary</h5>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Regulation</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>NDAA Section 889</b>
                <br />
                Federal Acquisition Regulation: Prohibition on Contracting With Entities Using Certain
                Telecommunications and Video Surveillance Services or Equipment (
                <a
                  rel="noreferrer"
                  href="https://www.federalregister.gov/documents/2020/08/27/2020-18772/federal-acquisition-regulation-prohibition-on-contracting-with-entities-using-certain"
                  target="_blank"
                >
                  85 FR 53126, Aug 27, 2020
                </a>
                )
              </td>
              <td>
                The FAR rules implemented National Defense Authorization Act Section 889.
                <br />
                <b>Part A</b> Prohibits the US government from buying equipment &amp; services from 5 banned companies
                and their affiliates – Huawei, Dahua, Hytera, Hikvision &amp; ZTE Corporation.
                <br />
                <b>Part B</b> Prohibits the US government from buying equipment &amp; services from any entity that uses
                equipment or services as a substantial or essential component of any system or as critical technology as
                part of any system from the 5 banned companies or their affiliates.
              </td>
            </tr>
            <tr>
              <td>
                <b>NDAA Section 1237</b>
                <br />
                Federal Investment Regulation: Prohibition on Investing in “Communist Chinese Military Companies” (
                <a
                  rel="noreferrer"
                  href="https://www.federalregister.gov/documents/2020/11/17/2020-25459/addressing-the-threat-from-securities-investments-that-finance-communist-chinese-military-companies"
                  target="_blank"
                >
                  85 FR 73185, Nov 17, 2020
                </a>
                )
              </td>
              <td>
                Prohibits any person to invest in Chinese companies with “military-civil” functions and development
                strategies, which according to the State Department support the modernization goals of the People’s
                Liberation Army (PLA). The{' '}
                <a
                  href="https://media.defense.gov/2020/Aug/28/2002486659/-1/-1/1/LINK_2_1237_TRANCHE_1_QUALIFIYING_ENTITIES.PDF"
                  target="_blank"
                  rel="noreferrer"
                >
                  first list
                </a>{' '}
                of prohibited Communist Chinese Military Companies was released on June 12th 2020 and additional
                entities were added in{' '}
                <a
                  href="https://media.defense.gov/2020/Aug/28/2002486689/-1/-1/1/LINK_1_1237_TRANCHE-23_QUALIFYING_ENTITIES.PDF"
                  rel="noreferrer"
                  target="_blank"
                >
                  August 28th 2020
                </a>
                ,{' '}
                <a
                  rel="noreferrer"
                  href="https://media.defense.gov/2020/Dec/03/2002545864/-1/-1/1/TRANCHE-4-QUALIFYING-ENTITIES.PDF"
                  target="_blank"
                >
                  December 3rd 2020
                </a>{' '}
                and{' '}
                <a
                  rel="noreferrer"
                  href="https://media.defense.gov/2021/Jan/14/2002565154/-1/-1/0/DOD-RELEASES-LIST-OF-ADDITIONAL-COMPANIES-IN-ACCORDANCE-WITH-SECTION-1237-OF-FY99-NDAA.PDF"
                  target="_blank"
                >
                  January 14th 2021
                </a>
                .
              </td>
            </tr>
            <tr>
              <td>
                <b>NDAA Section 1634</b>
                <br />
                Federal Acquisition Regulation: Use of Products and Services of Kaspersky Lab (
                <a
                  rel="noreferrer"
                  href="https://www.federalregister.gov/documents/2019/09/10/2019-19360/federal-acquisition-regulation-use-of-products-and-services-of-kaspersky-lab"
                  target="_blank"
                >
                  84 FR 47861, October 9, 2019
                </a>
                )
              </td>
              <td>
                Prohibits the US government from using products and services developed or provided by Kaspersky Lab (or
                any successor entity), any entity that controls, is controlled by, or is under common control with
                Kaspersky Lab; or any entity of which Kaspersky Lab has majority ownership.
              </td>
            </tr>
            <tr>
              <td>
                <b>Executive Order 13971</b>
                <br />
                Addressing the Threat Posed by Applications and Other Software Developed or Controlled by Chinese
                Companies (
                <a
                  rel="noreferrer"
                  href="https://www.federalregister.gov/documents/2021/01/08/2021-00305/addressing-the-threat-posed-by-applications-and-other-software-developed-or-controlled-by-chinese"
                  target="_blank"
                >
                  86 FR 1249, Jan 8, 2021
                </a>
                )
              </td>
              <td>
                Prohibits transactions by any person, or with respect to any property, subject to the jurisdiction of
                the United States, with persons that develop or control the following Chinese connected software
                applications, or with their subsidiaries: Alipay,CamScanner, QQ Wallet, SHAREit, Tencent QQ, VMate,
                WeChat Pay, WPS Office.
              </td>
            </tr>
          </tbody>
        </table>
        <h5>Internet Protocol Geolocation Methodology</h5>
        <p>
          The Fortress Overseer cyber scanning tool identifies related IP addresses of an organization. Those IP
          addresses are then converted to latitude and longitude values and assigned to countries. This is useful to
          prioritize diligence efforts for certain compliance regulations.
        </p>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Regulation</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>DoE Prohibition Order</b>
                <br />
                Department of Energy:
                <br />
                Prohibition Order Securing Critical Defense Facilities (
                <a
                  href="https://www.federalregister.gov/documents/2021/01/06/2020-28773/prohibition-order-securing-critical-defense-facilities"
                  target="_blank"
                  rel="noreferrer"
                >
                  86 FR 533, Jan 16, 2021
                </a>
                )
              </td>
              <td>
                The Department [of Energy] determined that certain Bulk Power System electric equipment or programmable
                components subject to China&apos;s ownership, control, or influence, constitute undue risk to the
                security of the BPS and to U.S. national security. The purpose of this Order is to prohibit the
                acquisition, importation, transfer, or subsequent installation of such BPS electric equipment or
                programmable components in certain sections of the BPS.
                <br />
                Systems in scope include transformers, generator step ups, circuit breakers, reactors &amp; capacitors,
                and attached software to these systems.
              </td>
            </tr>
            <tr>
              <td>
                <b>Executive Order 13920</b>
                <br />
                Securing the United States Bulk-Power System (
                <a
                  href="https://www.federalregister.gov/documents/2020/05/04/2020-09695/securing-the-united-states-bulk-power-system"
                  target="_blank"
                  rel="noreferrer"
                >
                  85 FR 26595, May 1,2020
                </a>
                )
              </td>
              <td>
                Prohibits electric equipment designed, developed, manufactured, or supplied, by persons owned by,
                controlled by, or subject to the jurisdiction or direction of a foreign adversary into the bulk-power
                system. Foreign adversaries were defined in an RFI published by the Department of Energy (
                <a
                  href="https://www.federalregister.gov/documents/2020/07/08/2020-14668/securing-the-united-states-bulk-power-system"
                  target="_blank"
                  rel="noreferrer"
                >
                  85 FR 41023, Aug 7, 2020
                </a>
                ) as The People&apos;s Republic of China (China), the Republic of Cuba (Cuba), the Islamic Republic of
                Iran (Iran), the Democratic People&apos;s Republic of Korea (North Korea), the Russian Federation
                (Russia), and the Bolivarian Republic of Venezuela (Venezuela).
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
