import React from 'react';

import Busy from '../../components/busy';
import config from '../../config';

class Logout extends React.Component {
  constructor() {
    super();

    this.state = {
      isBusy: true,
    };
  }

  async componentDidMount() {
    await fetch(config.api.urlFor('logout'));

    window.$app.setState({ principal: undefined, token: undefined, lastSuccessfulLogon: undefined, logout: true });
    this.setState({ isBusy: false });
    window.location = '/';
  }

  render() {
    return <Busy isBusy={this.state.isBusy} />;
  }
}

export default Logout;
