import React, { Component } from 'react';
import Modal from 'react-modal';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: '65%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class GenerateReportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  addParamter() {
    // fancy things go here
  }

  generateReport() {
    // fancy things go here

    this.closeModal();
  }

  render() {
    return (
      <div>
        <button type="button" className="btn btn-primary" style={{ marginLeft: '10px' }} onClick={this.openModal}>
          Generate Report
        </button>
        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={this.closeModal}
          style={modalStyle}
          contentLabel="Generate Report Input"
        >
          <form data-test-id="GenerateReport-modal-1">
            <h4 style={{ textAlign: 'center', marginBottom: '15px' }}>Generate Custom Report</h4>
            <div className="row">
              <div className="col">
                <div className="form-inline">
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <select className="form-control">
                      <option value="" defaultValue>
                        Select Paramter
                      </option>
                      <option>Crtiticality</option>
                      <option>Risk</option>
                    </select>
                    <input className="form-control" placeholder="Number of items?" />
                    <button className="btn btn-outline-primary">
                      <i className="fas fa-minus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '15px' }}>
              <button className="btn btn-outline-primary">
                <i className="fas fa-plus-circle"></i> Add Parameter
              </button>
            </div>
          </form>
          <div style={{ paddingTop: '15px' }}>
            <button
              data-test-id="GenerateReport-button-submit"
              className="btn btn-outline-primary btn-nav float-right"
              onClick={(event) => {
                event.preventDefault();
                this.generateReport();
              }}
            >
              Generate
            </button>
            <button
              data-test-id="GenerateReport-button-cancel"
              className="btn btn-outline-primary btn-nav float-right"
              style={{ marginRight: '15px' }}
              onClick={(event) => {
                event.preventDefault();
                this.closeModal();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default GenerateReportButton;
