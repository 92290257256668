import React from 'react';
import { Link } from 'react-router-dom';

import Spinner from './spinner';
import { withEvents, events } from './withEvents';

import { config } from '../config';

// const update = (fn) => {
//   setTimeout(() => {
//     fn();
//     update(fn);
//   }, (config.alerts || {}).refresh || 1000 * 60 * 30)
// }

class AlertBell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      alertCount: 0,
      isBusy: false,
    };

    this.props.events.on(events.ALERTS_UPDATED, this.getAlertCount.bind(this));
  }

  componentDidMount() {
    //update(this.getAlertCount.bind(this));
  }

  getAlertCount() {
    this.setState({ alertCount: -1 });
  }

  render() {
    const { alertCount, isBusy } = this.state;

    return (
      <React.Fragment>
        <Link className="nav-link" to="/">
          <span className="badge badge-danger">
            <Spinner isVisible={isBusy}>{alertCount}</Spinner>
          </span>
          <i className="fas fa-bell fa-fw"></i>
        </Link>
      </React.Fragment>
    );
  }
}

export default withEvents(AlertBell);
