// The assessment types info must be the same as defined in DB table [assessment_type]
export const assessmentTypes = {
  A2VQA: {
    id: 'c9323bc8-4cc6-49b2-8ea2-5e8229f3c6f6',
    name: 'Validated Security Controls Assessment',
    abbreviation: 'VSCA',
  },
  A2VQA_S: {
    id: 'ca87d9c7-95d5-4e29-baa2-19fa2b72ee1d',
    name: 'Validated Security Controls Assessment Share',
    abbreviation: 'VSCA-S',
  },
  CHS: {
    id: '4b424620-8f22-4784-aa30-f66263016af8',
    name: 'Cyber Hygiene Scan',
    abbreviation: 'CHS',
  },
  CMRE: {
    id: '8506a948-9bd1-4f1f-9f3d-7975eeb313ca',
    name: 'Continuous Monitoring - Enhanced',
    abbreviation: 'CMR-E',
  },
  DDPA: {
    id: '5c0b040f-3aee-4b6b-a384-e03dce82246f',
    name: 'Data-Driven Product Assessment',
    abbreviation: 'DDPA',
  },
  DDRR: {
    id: 'a768a18e-7002-4612-8550-c319d56e6e3f',
    name: 'Data-Driven Risk Rank',
    abbreviation: 'DDRR',
  },
  DDVA: {
    id: '380d9a63-cf95-4e73-9c84-ed3624a3f61e',
    name: 'Data-Driven Vendor Assessment',
    abbreviation: 'DDVA',
  },
  DDVA_S: {
    id: '813be1b4-d5db-4b2f-ab63-5a3307ca1deb',
    name: 'Data-Driven Vendor Assessment Share',
    abbreviation: 'DDVA-SHARE',
  },
  PAPR: {
    id: 'c57b0980-3ac7-47c0-b2b0-9de6d2328aff',
    name: 'Product Assessment Provenance Report',
    abbreviation: 'PAPR',
  },
  SBOM: [
    {
      id: '7d44d4b6-d767-416b-a4db-f347df77e0d6',
      name: 'Open Source SBOM',
      abbreviation: 'SBOM',
    },
    {
      id: '4599bf92-700c-4514-9689-ca24fc5ffa3e',
      name: 'Supplier Provided SBOM',
      abbreviation: 'SBOM',
    },
    {
      id: '236f7610-8ca3-41bc-bcf7-565a2e9579e2',
      name: 'Fortress Created SBOM',
      abbreviation: 'SBOM',
    },
  ],
  FIA_SBOM: [
    {
      id: '3c130420-d5cd-47c8-8f10-37a051532968',
      name: 'Open Source',
      a2vID: '7d44d4b6-d767-416b-a4db-f347df77e0d6',
    },
    {
      id: '42e72cfb-7b5c-4cd1-856c-117f28f61e74',
      name: 'Supplier Provided',
      a2vID: '4599bf92-700c-4514-9689-ca24fc5ffa3e',
    },
    {
      id: '59c37089-a18f-4a1d-a9d6-128c8bec1c42',
      name: 'Fortress Created',
      a2vID: '236f7610-8ca3-41bc-bcf7-565a2e9579e2',
    },
    {
      id: 'dcbe2829-545f-4eef-8c66-c4fed3ff82ca',
      name: 'Non-public Fortress Created',
    },
  ],
};

export const sbomTypeIDToIconCSSClassTitle = (sbomTypeID) => {
  let data = { cssClass: '', titleText: '' };

  switch (sbomTypeID) {
    //Open Source SBOM
    case assessmentTypes.SBOM[0].id:
      data = { cssClass: 'open-source-sbom', titleText: 'Open Source SBOM' };
      break;
    //Supplier Provided SBOM
    case assessmentTypes.SBOM[1].id:
      data = { cssClass: 'supplier-provided-sbom', titleText: 'Supplier Provided SBOM' };
      break;
    //Fortress Created SBOM
    case assessmentTypes.SBOM[2].id:
      data = { cssClass: 'fortress-created-sbom', titleText: 'Fortress Created SBOM' };
      break;
    default:
      break;
  }

  return data;
};
