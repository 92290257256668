import React from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { useUpdateState } from '../';
import config from '../../config';
import DisputeButton from '../../components/disputeButton';
import { riskTierBySeverity } from '../../constants/string';

import { textColumnFilter } from '../../components/reactTable';
import { assessmentTypes } from '../../constants/assessmentTypes';

const myVendorsContainer = {
  margin: '1em 0 80px 0',
  height: 'fit-content',
};

const tableContainer = {
  padding: '15px 0px',
};

const scanOrDispute = {
  padding: '0 80px 0 30px',
};

const addAVendorBtn = {
  display: 'block',
  width: '180px',
  margin: '1em auto',
  background: '#38ac34',
  color: 'white',
  fontSize: '1rem',
  fontWeight: '700',
  borderRadius: '5px',
  cursor: 'pointer',
  boxShadow:
    '0 1px 0 0 rgba(255, 255, 255, 0.05) inset, 0 2px 6px rgba(0, 0, 0, 0.9),  0 10px rgba(0, 0, 0, 0.015) inset',
};

const scannerInfo = {
  margin: '0 0 2.5em 0',
};

const MyVendorsTable = (props) => {
  //Refs
  const _isMounted = React.useRef(false);

  //Props
  const { onDashboard = false } = props;

  //State
  const [state, setState] = useUpdateState({
    name: '',
    ssl: '',
    dns: '',
    appSec: '',
    vulns: '',
  });

  React.useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    doTable(state.page + 1, state.filtered, state.sorted);
  }, [props.rerenderTable]);

  const grabNumVendors = (totalRecords) => {
    props.parentCallback(totalRecords);
  };

  const doTable = (page, filter, sort) => {
    page = parseInt(page || 1, 10);
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];

    setState({ tableIsBusy: true });

    let url = config.api.urlFor('vendorRisks', { page: page });

    if (filter.length) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (_isMounted.current) {
          setState({
            providers: data.rows,
            pages: Math.ceil(data.filteredRecords / 10),
            tableIsBusy: false,
          });
          grabNumVendors(data.totalRecords);
        }
      })
      .catch((err) => {
        setState({ tableIsBusy: false });
        throw err;
      });
  };
  /* eslint-disable react/display-name */
  const renderTable = (pages, tableIsBusy, providers) => {
    const { handleButtonClick } = props;
    return (
      <div className="card mb-3">
        <div className="card-body align-middle">
          <ReactTable
            NoDataComponent={() => null}
            manual
            defaultCanFilter={false}
            multiSort={false}
            showPageSizeOptions={false} // handles amount per page
            pageSize={0}
            pages={pages}
            loading={tableIsBusy}
            defaultSorted={[{ id: 'name', desc: false }]}
            useSortBy
            columns={[
              {
                Header: 'Name',
                accessor: 'vendorName',
                Cell: ({ row }) => (
                  <div className="">
                    <a href={'#/catalog/vendor/' + row._original.vendorID + '/Overview'}>{row.vendorName}</a>
                  </div>
                ),
                minWidth: 200,
                filterable: true,
                Filter: textColumnFilter,
              },
              {
                Header: 'Created',
                accessor: 'created',
                Cell: ({ row }) => (
                  <div className="text-center">{row.created ? new Date(row.created).toDateString() : ''}</div>
                ),
                minWidth: 75,
              },
              {
                Header: 'Risk Tier',
                accessor: 'ddrrRisk',
                Cell: ({ row }) => (
                  <div className="text-center">
                    {row.ddrrRisk !== 'REDACTED' ? (
                      <a href={'#/catalog/vendor/' + row._original.vendorID + '/RiskRank'}>
                        {row.ddrrRisk ? `${'Tier ' + riskTierBySeverity(row.ddrrRisk)}` : 'Currently Processing'}
                      </a>
                    ) : (
                      <button
                        className="my-vendors-table-btn"
                        onClick={() =>
                          handleButtonClick(
                            assessmentTypes.DDRR.name,
                            row._original.vendorID,
                            row.vendorName,
                            row._original.vendorDomain,
                            row._original.ddrrID,
                          )
                        }
                      >
                        Purchase Risk Rank
                      </button>
                    )}
                  </div>
                ),
                sortable: false,
              },
              {
                Header: 'Cyber Hygiene Score',
                accessor: 'cyberHygieneRisk',
                Cell: ({ row }) => (
                  <div className="text-center">
                    {row.cyberHygieneRisk !== 'REDACTED' ? (
                      <a href={'#/catalog/vendor/' + row._original.vendorID + '/CyberHygiene/Discovery'}>
                        {row.cyberHygieneRisk || 'Currently Processing'}
                      </a>
                    ) : (
                      <button
                        className="my-vendors-table-btn"
                        onClick={() =>
                          handleButtonClick(
                            assessmentTypes.CHS.name,
                            row._original.vendorID,
                            row.vendorName,
                            row._original.vendorDomain,
                            row._original.ddrrID,
                          )
                        }
                      >
                        Purchase Cyber Hygiene
                      </button>
                    )}
                  </div>
                ),
                sortable: false,
              },
              {
                Header: 'Foreign Influence Monitoring',
                accessor: 'hasCMR',
                Cell: ({ row }) => (
                  <div className="text-center">
                    {row.hasCMR ? (
                      <a href={'#/catalog/vendor/' + row._original.vendorID + '/ForeignInfluence/Summary'}>
                        View Monitoring
                      </a>
                    ) : (
                      <button
                        className="my-vendors-table-btn"
                        onClick={() =>
                          handleButtonClick(
                            assessmentTypes.CMRE.name,
                            row._original.vendorID,
                            row.vendorName,
                            row._original.vendorDomain,
                            row._original.ddrrID,
                          )
                        }
                      >
                        Purchase Monitoring
                      </button>
                    )}
                  </div>
                ),
                sortable: false,
              },
            ]}
            data={providers}
            onFetchData={(state) => {
              setState({ page: state.page, filtered: state.filtered, sorted: state.sorted });
              doTable(state.page + 1, state.filtered, state.sorted);
            }}
            className="-striped -highlight hide-arrows"
          />
        </div>
        <div className="card-footer small text-muted"></div>
      </div>
    );
  };

  const { pages, providers, tableIsBusy } = state;

  return (
    <>
      {onDashboard ? (
        <>{renderTable(pages, tableIsBusy, providers)}</>
      ) : (
        <div style={myVendorsContainer}>
          <b>My Vendors</b>
          <div className="row">
            <div className="col-8" style={tableContainer}>
              {renderTable(pages, tableIsBusy, providers)}
            </div>
            <div className="col-4" style={scanOrDispute}>
              <div>
                <Link to={'/catalog/name/asc/1'} style={addAVendorBtn} className="btn">
                  Add a Vendor
                </Link>
              </div>
              <div style={scannerInfo}>
                <p>
                  Fortress Cyber Scanner uses a proprietary algorithm to identify cyber hygiene risks associated to a
                  domain.
                  {/* (e.x. <i>https://www.***.com</i>).  */}
                  {/* <i>Risks scoring methodology can be found <a href="">here</a>.</i> */}
                </p>
              </div>
              <div>
                <p>
                  <i>If you would like to dispute a score, please fill out the form and provide details:</i>
                </p>
                <DisputeButton />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyVendorsTable;
