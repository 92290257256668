import 'core-js';
import 'url-search-params-polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

import Chart from 'chart.js';

// import 'bootstrap/dist/css/bootstrap.min.css';

import './bootstrap-flatly.min.css';
import './lib/sb-admin/sb-admin.min.css';
import './lib/fontawesome-free/css/all.css';
import './index.css';

import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap.bundle';

import './prototypes';

import config from './config';

Chart.defaults.global.animation.duration = 0;

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();