/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { Busy, useUpdateState, withPrincipal } from '..';
import config from '../../config';
import BootstrapTable from 'react-bootstrap-table-next';
import { SortCarets } from '../assessmentList';
import { ToggleSwitch } from '..';
import { toastError, toastSuccess } from '../../lib/utils';

const FeaturesTable = (props) => {
  const { isBusy, data, editable, handleFeatureSwitch = () => null } = props;

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      sortCaret: SortCarets,
      editable: false,
      headerStyle: { width: '15%' },
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      editable: false,
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'isFeatureOn',
      text: 'On/Off',
      sort: false,
      editable: false,
      headerStyle: { width: '8%' },
      classes: 'subscriber-details-table-columns',
      formatter: (cell, row) => (
        <ToggleSwitch
          disabled={!editable || row.isUpdating}
          checked={!!row.isFeatureOn}
          onChange={() => handleFeatureSwitch(row.id, !row.isFeatureOn)}
          size="small"
          id={`feature-switch-${row.name ? row.name.replace(' ', '-').toLowerCase() : 'undefined'}`}
        />
      ),
    },
  ];

  return (
    <div className="subscriber-details-table mb-5 mt-2">
      <BootstrapTable
        noDataIndication={() => (isBusy ? <Busy isBusy={true} /> : <div className="text-center">no data</div>)}
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={[{ dataField: 'name', order: 'asc' }]}
        hover={true}
        bordered={false}
      />
    </div>
  );
};

const SubscriberFeatures = (props) => {
  // props based constants
  const { principal, subscriberID } = props;
  const canManageFeatures = principal.roles.some((r) => r.permissions.some((p) => p === 'subscription.manageFeatures'));

  // state
  const [state, setState] = useUpdateState({
    isBusy: false,
    data: [],
  });

  const { isBusy, data } = state;

  // effects
  useEffect(() => {
    if (subscriberID) {
      getFeatures(subscriberID);
    }
  }, [subscriberID]);

  // functions
  const getFeatures = (id) => {
    setState({ isBusy: true, data: [] });

    fetch(config.api.urlFor('subscriberFeatures', { id }))
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, data, message = 'Error loading features data.' } = response;

        if (isSuccess) {
          setState({ data });
        } else {
          toastError(message);
        }
      })
      .catch(() => toastError('Failed to load features data.'))
      .finally(() => setState({ isBusy: false }));
  };

  const manageFeatures = (featureID, actionFlag) => {
    if (!canManageFeatures) {
      return;
    }

    handleFeatureUpdate(featureID, actionFlag, true);
    const method = actionFlag ? 'POST' : 'DELETE';

    fetch(config.api.urlFor('subscriberFeaturesManage', { id: subscriberID, featureID }), { method })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message = 'Error setting feature data.' } = response;

        if (isSuccess) {
          toastSuccess(`Feature successfully turned ${actionFlag ? 'on' : 'off'}.`);
          handleFeatureUpdate(featureID, actionFlag, false);
        } else {
          handleFeatureUpdate(featureID, !actionFlag, false);
          toastError(message);
        }
      })
      .catch(() => {
        handleFeatureUpdate(featureID, !actionFlag, false);
        toastError('Failed to set feature data.');
      });
  };

  const handleFeatureUpdate = (featureID, actionFlag, isUpdating) => {
    if (!data || !Array.isArray(data)) {
      toastError('Features data: Invalid value.');
      return;
    }

    setState({
      data: data.map((row) => {
        if (row.id === featureID) {
          return {
            ...row,
            isFeatureOn: actionFlag,
            isUpdating,
          };
        } else {
          return { ...row, isUpdating };
        }
      }),
    });
  };

  // render
  return (
    <FeaturesTable isBusy={isBusy} data={data} editable={canManageFeatures} handleFeatureSwitch={manageFeatures} />
  );
};

export default withPrincipal(SubscriberFeatures);
