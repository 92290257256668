import React, { Component } from 'react';

import SeverityFlag from './../../../reusable/severityFlag';

import './cyberScannerMap.css';
import { makeCyberMap, removeCyberMap } from './../assets/js/cybermap.js';

const hasCountryData = (continentCountries) => {
  let hasCountryData = false;
  if (Object.keys(continentCountries).length === 0) {
    return false;
  }

  Object.keys(continentCountries).forEach((continent) => {
    const countries = continentCountries[continent] || [];
    if (countries.length > 0) {
      hasCountryData = true;
    }
  });

  return hasCountryData;
};

export default class CyberScannerMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      charts: [],
    };
  }

  componentDidMount() {
    const { cyberMap = [] } = this.props;
    const chart = makeCyberMap(cyberMap);
    this.setState({ charts: chart });
  }

  componentWillUnmount() {
    const { charts } = this.state;
    removeCyberMap(charts);
  }

  render() {
    const { continentCountries = {} } = this.props;

    return (
      <div id="cyber-scanner-map">
        <p>
          Map shows detected, public-facing products with IP addresses identified by the Fortress scanner. Results are
          used to identify possible offshore operations
        </p>

        <div id="ddrr-cyber-map"></div>

        {hasCountryData(continentCountries) ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                {Object.keys(continentCountries).map((continent) => {
                  return <th key={continent}>{continent}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.keys(continentCountries).map((continent) => {
                  const countries = continentCountries[continent] || [];
                  return (
                    <td key={continent + '-list'}>
                      {countries.map((countryData) => {
                        const [country, color] = countryData;
                        return (
                          <div key={country} className="cyber-flag-data">
                            <SeverityFlag color={color} />
                            {country}
                          </div>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        ) : (
          ''
        )}
      </div>
    );
  }
}
