import React from 'react';

const SbomHexagon = ({ width = '200', height = '100', fill, stroke, scoreText, categoryText }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} viewBox="0 0 300 300">
      <polygon
        className="hex"
        points="300,150 225,280 75,280 0,150 75,20 225,20"
        fill={fill}
        stroke={stroke}
        strokeWidth="1"
        transform="translate(10)"
      ></polygon>
      <text
        id={scoreText}
        x="160"
        y="120"
        fontFamily="Arial"
        fontSize="40"
        textAnchor="middle"
        fontWeight="700"
        fill="white"
      >
        {scoreText}
      </text>
      <text
        id={categoryText}
        x="160"
        y="180"
        fontFamily="Arial"
        fontSize="30"
        textAnchor="middle"
        fontWeight="600"
        fill="white"
      >
        {categoryText}
      </text>
    </svg>
  );
};

export default SbomHexagon;
