import React from 'react';

function SbomUploadTable(props) {
  const { children, headers = [], tableName = '' } = props;

  return (
    <table className={'sbom-table'}>
      <thead className="sbom-thead">
        <tr>
          {headers.map((header, i) => {
            return (
              <th scope="col" key={tableName + i} className="px-2 pt-3 pb-4">
                {header[0]}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

export default SbomUploadTable;
