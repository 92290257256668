import React, { Component } from 'react';

import './complianceList.css';

export default class ComplianceList extends Component {
  render() {
    const { compliance = {} } = this.props;
    const { findings = [] } = compliance;

    return (
      <React.Fragment>
        <div class="row compliance-appendix">
          <div class="col-sm">
            <strong>Date</strong>
            <ul>
              {findings.map((finding, i) => (
                <li>{finding.date}</li>
              ))}
            </ul>
          </div>
          {findings[0].state != null ? (
            <div class="col-sm">
              <strong>State</strong>
              <ul>
                {findings.map((finding, i) => (
                  <li>{finding.state}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
