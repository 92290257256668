import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { useUpdateState } from '../../components';

import './login.css';

const AccessConditionsModal = (props) => {
  const {
    isOpen,
    handleClose,
    isChecked,
    handleAgreementWithAccessConditionsChange = () => {},
    checkboxOnFocus,
    showUserAgreementModal,
    handleShowUserAgreementModal = () => {},
  } = props;

  const [state, setState] = useUpdateState({
    agreementWithAccessConditions: isChecked,
  });

  useEffect(() => {
    setState({ agreementWithAccessConditions: isChecked });
  }, [isChecked]);

  const { agreementWithAccessConditions } = state;

  const handleCheckboxChange = (event) => {
    const input = event.target;
    if (input.type !== 'checkbox') return;

    const value = input.checked;

    setState({ agreementWithAccessConditions: value });
    handleAgreementWithAccessConditionsChange(event);
  };

  return (
    <Modal
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          padding: '0 20px 0 20px',
          maxHeight: '80vh',
        },
      }}
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >
      <div className="access-conditions-modal">
        {!showUserAgreementModal ? (
          <>
            <div>
              <h5 className="pt-1 modal-title font-weight-bold">Terms &amp; Conditions</h5>
            </div>
            <div>
              <p>
                You are accessing a U.S. Government (USG) Information System (IS) that is provided for USG-authorized
                use only.
              </p>
              <p>
                By using this IS (which includes any device attached to this IS), you consent to the following
                conditions:
              </p>
              <ul className="dash">
                <li>
                  The USG routinely intercepts and monitors communications on this IS for purposes including, but not
                  limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel
                  misconduct (PM), law enforcement (LE), and counterintelligence (CI) investigations.
                </li>
                <li>At any time, the USG may inspect and seize data stored on this IS.</li>
                <li>
                  Communications using, or data stored on, this IS are not private, are subject to routine monitoring,
                  interception, and search, and may be disclosed or used for any USG-authorized purpose.
                </li>
                <li>
                  This IS includes security measures (e.g., authentication and access controls) to protect USG
                  interests--not for your personal benefit or privacy.
                </li>
                <li>
                  Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI investigative
                  searching or monitoring of the content of privileged communications, or work product, related to
                  personal representation or services by attorneys, psychotherapists, or clergy, and their assistants.
                  Such communications and work product are private and confidential. See{' '}
                  <button type="button" className="user-agreement-btn" onClick={handleShowUserAgreementModal}>
                    User Agreement
                  </button>{' '}
                  for details.
                </li>
              </ul>
            </div>
            <div className="form-group login-checkbox">
              <div className="form-group ml-0">
                <input
                  type="checkbox"
                  checked={agreementWithAccessConditions}
                  onChange={handleCheckboxChange}
                  id="modal-agree-to-access-conditions"
                  name="modal-agree-to-access-conditions"
                  value="I have read and agree to the conditions of access"
                  className={checkboxOnFocus && !agreementWithAccessConditions ? 'on-focus' : ''}
                />
                <label htmlFor="modal-agree-to-access-conditions">
                  <p className="mb-0">I have read and agree to the conditions of access</p>
                </label>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <h5 className="pt-1 modal-title font-weight-bold">Department of Defense (DoD) Notice</h5>
            </div>
            <div>
              <p>
                By signing this document, you acknowledge and consent that when you access Department of Defense (DoD)
                information systems:
              </p>
              <ul className="pl-3">
                <li>
                  You are accessing a U.S. Government (USG) information system (IS) (which includes any device attached
                  to this information system) that is provided for U.S. Governmentauthorized use only.
                </li>
                <li>You consent to the following conditions:</li>
                <ul className="circle">
                  <li>
                    The U.S. Government routinely intercepts and monitors communications on this information system for
                    purposes including, but not limited to, penetration testing, communications security (COMSEC)
                    monitoring, network operations and defense, personnel misconduct (PM), law enforcement (LE), and
                    counterintelligence (CI) investigations.
                  </li>
                  <li>
                    At any time, the U.S. Government may inspect and seize data stored on this information system.
                  </li>
                  <li>
                    Communications using, or data stored on, this information system are not private, are subject to
                    routine monitoring, interception, and search, and may be disclosed or used for any U.S.
                    Government-authorized purpose.
                  </li>
                  <li>
                    This information system includes security measures (e.g., authentication and access controls) to
                    protect U.S. Government interests--not for your personal benefit or privacy.
                  </li>
                  <li>
                    Notwithstanding the above, using an information system does not constitute consent to personnel
                    misconduct, law enforcement, or counterintelligence investigative searching or monitoring of the
                    content of privileged communications or data (including work product) that are related to personal
                    representation or services by attorneys, psychotherapists, or clergy, and their assistants. Under
                    these circumstances, such communications and work product are private and confidential, as further
                    explained below:
                  </li>
                  <ul className="dash">
                    <li>
                      Nothing in this User Agreement shall be interpreted to limit the user&apos;s consent to, or in any
                      other way restrict or affect, any U.S. Government actions for purposes of network administration,
                      operation, protection, or defense, or for communications security. This includes all
                      communications DTM-08-060, May 9, 2008 Change 5, 09/25/2013 5 and data on an information system,
                      regardless of any applicable privilege or confidentiality.
                    </li>
                    <li>
                      The user consents to interception/capture and seizure of ALL communications and data for any
                      authorized purpose (including personnel misconduct, law enforcement, or counterintelligence
                      investigation). However, consent to interception/capture or seizure of communications and data is
                      not consent to the use of privileged communications or data for personnel misconduct, law
                      enforcement, or counterintelligence investigation against any party and does not negate any
                      applicable privilege or confidentiality that otherwise applies.
                    </li>
                    <li>
                      Whether any particular communication or data qualifies for the protection of a privilege, or is
                      covered by a duty of confidentiality, is determined in accordance with established legal standards
                      and DoD policy. Users are strongly encouraged to seek personal legal counsel on such matters prior
                      to using an information system if the user intends to rely on the protections of a privilege or
                      confidentiality.
                    </li>
                    <li>
                      Users should take reasonable steps to identify such communications or data that the user asserts
                      are protected by any such privilege or confidentiality. However, the user&apos;s identification or
                      assertion of a privilege or confidentiality is not sufficient to create such protection where none
                      exists under established legal standards and DoD policy.
                    </li>
                    <li>
                      A user&apos;s failure to take reasonable steps to identify such communications or data as
                      privileged or confidential does not waive the privilege or confidentiality if such protections
                      otherwise exist under established legal standards and DoD policy. However, in such cases the U.S.
                      Government is authorized to take reasonable actions to identify such communication or data as
                      being subject to a privilege or confidentiality, and such actions do not negate any applicable
                      privilege or confidentiality.
                    </li>
                    <li>
                      These conditions preserve the confidentiality of the communication or data, and the legal
                      protections regarding the use and disclosure of privileged information, and thus such
                      communications and data are private and confidential. Further, the U.S. Government shall take all
                      reasonable measures to protect the content of captured/seized privileged communications and data
                      to ensure they are appropriately protected.
                    </li>
                  </ul>
                  <li>
                    In cases when the user has consented to content searching or monitoring of communications or data
                    for personnel misconduct, law enforcement, or counterintelligence investigative searching, (i.e.,
                    for all communications and data DTM-08-060, May 9, 2008 Change 5, 09/25/2013 6 other than privileged
                    communications or data that are related to personal representation or services by attorneys,
                    psychotherapists, or clergy, and their assistants), the U.S. Government may, solely at its
                    discretion and in accordance with DoD policy, elect to apply a privilege or other restriction on the
                    U.S. Government&apos;s otherwise-authorized use or disclosure of such information.
                  </li>
                  <li>
                    All of the above conditions apply regardless of whether the access or use of an information system
                    includes the display of a Notice and Consent Banner (&quot;banner&quot;). When a banner is used, the
                    banner functions to remind the user of the conditions that are set forth in this User Agreement,
                    regardless of whether the banner describes these conditions in full detail or provides a summary of
                    such conditions, and regardless of whether the banner expressly references this User Agreement.
                  </li>
                </ul>
              </ul>
            </div>
          </>
        )}
      </div>
      <div className="row access-conditions-modal-close-back-btn">
        <button
          className="col-1 btn btn-primary btn-block"
          onClick={showUserAgreementModal ? handleShowUserAgreementModal : handleClose}
        >
          {showUserAgreementModal ? 'Back' : 'Close'}
        </button>
      </div>
    </Modal>
  );
};

export default AccessConditionsModal;
