import React from 'react';
import _ from 'lodash';

const displayPercent = (num, den) => {
  let percent = _.floor((num / den) * 100);
  let percentString = ' (' + percent.toString() + '%)';
  return percentString;
};

const formatLabel = (label) => {
  let formatedLabel = '';

  let words = label.split(' ');
  words.forEach((word) => {
    formatedLabel = formatedLabel + ' ' + _.capitalize(word);
  });

  formatedLabel = _.trim(formatedLabel) + ':';

  return formatedLabel;
};

const ListItem = ({ label, count, total }) => (
  <li className="list-group-item bg-transparent">
    {formatLabel(label)}
    <span className="float-right">
      {count}
      {total ? displayPercent(count, total) : ''}
    </span>
  </li>
);

export default ListItem;
