import React, { Component } from 'react';

import './reusableProvenanceTable.css';

export default class ReusableProvenanceTable extends Component {
  render() {
    const { children, headers = [], tableName = '', isBordered = true, striped = false } = this.props;
    return (
      <table
        className={'provenance-table'
          .concat(isBordered === true ? '' : ' no-border')
          .concat(striped === true ? ' table-striped' : '')}
      >
        <thead>
          <tr className="provenance-theader">
            {headers.map((header, i) => {
              return (
                <th
                  scope="col"
                  key={tableName + i}
                  className={header == 'CVSS score' || header == 'Number of Issues' ? 'm-tal-c' : ''}
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    );
  }
}
