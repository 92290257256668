import React, { Component } from 'react';
import PropTypes from 'prop-types';

import QRCode from 'qrcode.react';

import withPrincipal from '../../components/withPrincipal';

import config from '../../config';

class Generate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrUrl: null,
      code: '',
      user: this.props.principal,
    };
    this.verify = this.verify.bind(this);
  }

  componentDidMount() {
    this.getURL();
  }

  getURL() {
    const params = new URLSearchParams();
    params.set('username', this.state.user.username);
    fetch(config.api.urlFor('generate2fa'), {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          qrUrl: json.qrURL,
        });
        console.log(this.state.qrUrl);
      });
  }
  verify(e) {
    e.preventDefault();
    const params = new URLSearchParams();
    params.set('code', this.state.code);
    params.set('username', this.state.user.username);

    fetch(config.api.urlFor('verify2fa'), {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.isSuccess) {
          console.log('test');
        }
      });
  }
  render() {
    return (
      <div className="container">
        <div className="card card-login mx-auto mt-5">
          <div className="card-header">2 factor Setup</div>
          <div className="card-body">
            <p>Please use a 2 factor app such a google authenticator and take a picture of this qr code</p>
            <div
              style={{
                background: 'white',
                paddingTop: '30px',
                paddingBottom: '30px',
                paddingLeft: '30px',
                paddingRight: '30px',
              }}
            >
              {this.state && this.state.qrUrl && <QRCode className="img-responsive" value={this.state.qrUrl} />}
            </div>
            <p>Please enter the code that your app provides</p>
            <form onSubmit={this.verify}>
              <div className="form-group">
                <div className="form-label-group">
                  <input
                    type="text"
                    id="twofaverifyCode"
                    className="form-control"
                    placeholder="Email address"
                    required="required"
                    onChange={(e) => this.setState({ code: e.target.value })}
                    value={this.state.code}
                  ></input>
                  <label htmlFor="twofaverifyCode">QRCode</label>
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Generate.propTypes = {
  user: PropTypes.string,
};

export default withPrincipal(Generate);
