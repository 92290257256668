import React from 'react';
import './circle.css';

const Circle = (props) => {
  const { currentValue = 0, maxValue = 100, footnote, color = '', small } = props;
  const { percentage = Math.round(((currentValue > 0 ? currentValue : 0) / (maxValue > 0 ? maxValue : 100)) * 100) } =
    props;
  const textValue = currentValue ? `${currentValue}/${maxValue}` : percentage;
  const arClasses = ['c100', 'center'];

  if (percentage > 0 && percentage <= 100) {
    arClasses.push(`p${percentage}`);
  }
  if (textValue.length > 7) {
    arClasses.push('longer');
  }
  if (color) {
    arClasses.push(color);
  }
  if (small) {
    arClasses.push('small');
  }

  return (
    <div className={arClasses.join(' ')}>
      <span>{textValue}</span>
      <div className="slice">
        <div className="bar"></div>
        <div className="fill"></div>
      </div>
      {!!footnote && <span className="footnote">{footnote}</span>}
    </div>
  );
};

export default Circle;
