// react
import React, { Component } from 'react';

// map functions
import { makeCyberMap, removeCyberMap } from '../assets/js/cybermap.js';

// severity circle render functions
import { generateSymbol, generateColor } from './../../../../../constants/postern/riskIconGenerator';

// reuseable components
import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';

// css
import './supplierGeopoliticalAssociationsOverview.css';

export default class SupplierGeopoliticalAssociationsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      charts: [],
    };
  }

  componentDidMount() {
    const {
      manufacturing = [],
      physicalPresence = [],
      cyberPresence = [],
      corporateFamilies = [],
      foreignOwnership = [],
      watchlist = [],
    } = this.props;
    const chart = makeCyberMap(
      manufacturing,
      physicalPresence,
      cyberPresence,
      corporateFamilies,
      foreignOwnership,
      watchlist,
    );
    this.setState({ charts: chart });
  }

  componentWillUnmount() {
    const { charts } = this.state;
    removeCyberMap(charts);
  }

  scrollToSection(e, id) {
    e.preventDefault();
    let elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView();
    }
  }

  render() {
    const { summary = {}, riskSections = [] } = this.props;

    return (
      <>
        {Object.keys(summary).length > 0 ? (
          <div id="provenance-sga-overview">
            <h5>Marker Legend</h5>
            <div className="provenance-cybermap-marker-legend">
              <div>
                <span className="cybermap-legend-color" style={{ background: '#E84D3D' }}></span> &nbsp;Manufacturing
                Location
              </div>
              <div>
                <span className="cybermap-legend-color" style={{ background: '#186e15' }}></span> &nbsp;Physical
                Presence
              </div>
              <div>
                <span className="cybermap-legend-color" style={{ background: '#E88F24' }}></span> &nbsp;Cyber Presence
              </div>
              <div>
                <span className="cybermap-legend-color" style={{ background: '#004D70' }}></span> &nbsp;Corporate
                Families
              </div>
              <div>
                <span className="cybermap-legend-color" style={{ background: '#B585E3' }}></span> &nbsp;Foreign
                Ownership
              </div>
              {/* <div><span className="cybermap-legend-color" style="background: #FDD400"></span> &nbsp;Watchlist</div> */}
              <br />
            </div>
            <h5>Country Legend</h5>
            <div className="provenance-cybermap-country-legend">
              <div className="cybermap-legend-text">
                <span
                  className="cybermap-legend-color"
                  style={{ border: '1px solid rgb(123 160 131)', background: '#fff' }}
                ></span>{' '}
                &nbsp;Low Risk
              </div>
              <div className="cybermap-legend-text">
                <span
                  className="cybermap-legend-color"
                  style={{ border: '1px solid rgb(168 105 130)', background: '#F8D7DA' }}
                ></span>{' '}
                &nbsp;High Risk
              </div>
            </div>
            <div id="provenance-cyber-map"></div>
            <ReusableProvenanceTable
              striped={true}
              isBordered={false}
              tableName="sga-overview-"
              headers={['Location'].concat(riskSections)}
            >
              {Object.keys(summary).map((country, i) => {
                const countryData = summary[country];
                return (
                  <React.Fragment key={'sga-overview-outer-' + i}>
                    <tr className="provenance-table-row no-border">
                      <th className="row-padding" scope="row">
                        {' '}
                        {country}{' '}
                      </th>
                      {Object.keys(countryData).map((riskSection, i) => {
                        const riskSectionValue = countryData[riskSection];
                        return (
                          <React.Fragment key={'sga-overview-inner-' + i}>
                            <td>
                              <SeverityCircle
                                customClasses={'m-mar-a'}
                                color={generateColor(riskSectionValue)}
                                symbol={generateSymbol(riskSectionValue)}
                              />
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </ReusableProvenanceTable>

            <footer className="footer-p-wrapper">
              <p>
                <a
                  className="grey_link"
                  href="#legal-disclaimer"
                  onClick={(e) => this.scrollToSection(e, 'legal-disclaimer')}
                >
                  Please see the legal disclaimer at the end of the Methodology section.
                </a>
              </p>
            </footer>
          </div>
        ) : null}
      </>
    );
  }
}
