import React from 'react';

const chartColorWheel = ['#67b7dc', '#fdd400', '#ee9336', '#4cae4c', '#000040', '#900030', '#d43f3a', '#18bc9c'];

const dnsStatsLabels = ['total', 'dnssec', 'nsec', 'encryption', 'wildcard', 'doppelgangers', 'spf', 'dmarc'];
const sslStatsLabels = ['total', 'downgradable', 'deprecated', 'expired', 'upcoming exp.'];
const appSecStatsLabels = ['total', 'csp', 'xss', 'xfo', 'pkp', 'cto', 'sts', 'extRefs', 'ports'];

const populationDataArray = (value) => [(1.0 * value).toFixed(2), (100 - 1.0 * value).toFixed(2)];
const populationLabelsArray = (array) => [`Pop: ${array[0]}%`, `Other: ${array[1]}%`];

const prepareBarStatsObject = (title, arLabels, arData, axisLabel) => {
  return {
    title: title,
    type: 'bar',
    data: {
      labels: [axisLabel],
      datasets: arLabels.map((label, index) => {
        return { label, data: [arData[index]], backgroundColor: chartColorWheel[index] };
      }),
    },
    options: {
      animation: {
        duration: 1000,
      },
    },
  };
};

const prepareDoughnutStatsObject = (title, arLabels, arData) => {
  return {
    title: title,
    type: 'doughnut',
    data: {
      labels: arLabels,
      datasets: [
        {
          data: arData,
          backgroundColor: chartColorWheel,
        },
      ],
    },
    options: {
      animation: {
        duration: 1000,
      },
    },
  };
};

export const _generateDNSFindingsFromCRUX = (rootDomain, dnsData, findingsCounts) => {
  const totalDomains = 1; // total domains is always 1 for the DNS section
  const dnsStats = [
      totalDomains,
      dnsData?.dnsSec?.count || 0,
      dnsData?.nsec?.count || 0,
      dnsData?.dnsEncryption?.count || 0,
      dnsData?.wildcards?.count || 0,
      dnsData?.doppelgangers?.count > 0 ? 1 : 0,
      dnsData?.spf?.count || 0,
      dnsData?.dmarc?.count || 0,
    ],
    hasDNSSecStats = populationDataArray((dnsStats[1] / totalDomains) * 100),
    hasNSecStats = populationDataArray((dnsStats[2] / totalDomains) * 100),
    hasEncryptionStats = populationDataArray((dnsStats[3] / totalDomains) * 100),
    hasWildcardStats = populationDataArray((dnsStats[4] / totalDomains) * 100),
    hasDoppelgangersStats = populationDataArray((dnsStats[5] / totalDomains) * 100),
    hasSPFStats = populationDataArray((dnsStats[6] / totalDomains) * 100),
    hasDMARCStats = populationDataArray((dnsStats[7] / totalDomains) * 100);

  return {
    title: 'Domain Name System',
    description:
      'The Domain Name System (DNS) is the phonebook of the Internet. Humans access information online through domain names, like nytimes.com or espn.com. Web browsers interact through Internet Protocol (IP) addresses. DNS translates domain names to IP addresses so browsers can load Internet resources.',
    targets: [rootDomain],
    findingsCounts: {
      totalPotentialFindings: findingsCounts?.dnsDenominator || 0,
      findings: findingsCounts?.dns || 0,
      nonFindings: (findingsCounts?.dnsDenominator || 0) - (findingsCounts?.dns || 0),
    },
    stats: prepareBarStatsObject('Domains', dnsStatsLabels, dnsStats, 'hostnames'),
    sections: [
      {
        heading: 'Domain Name System Security Extensions',
        description:
          'The Domain Name System Security Extensions (DNSSEC) is a suite of Internet Engineering Task Force (IETF) specifications for securing certain kinds of information provided by the Domain Name System (DNS) as used on Internet Protocol (IP) networks. It is a set of extensions to DNS which provide to DNS clients (resolvers) cryptographic authentication of DNS data, authenticated denial of existence, and data integrity, but not availability or confidentiality. \n Failure to implement DNSSEC on a domain can leave that domain vulnerable to DNS cache poisoning attacks, potentially allowing attackers to hijack web traffic and steal sensitive information or direct users to a malicious website.',
        brief: (
          <span>
            DNSSEC is <strong>{dnsData?.dnsSec?.count ? 'disabled' : 'enabled'}</strong> for all hostnames.
          </span>
        ),
        targets: dnsData?.dnsSec?.count ? [rootDomain] : [],
        stats: prepareDoughnutStatsObject(
          'Population with DNSSec',
          populationLabelsArray(hasDNSSecStats),
          hasDNSSecStats,
        ),
      },
      {
        heading: 'Next Secure',
        description:
          "An NSEC-record links to the next record name in the zone (in DNSSEC sorting order) and lists the record types that exist for the record's name. These records can be used by resolvers to verify the non-existence of a record name and type as part of DNSSEC validation. \n NSEC is a part of the DNSSEC suite. Failure to implement DNSSEC on a domain can leave that domain vulnerable to DNS cache poisoning attacks, potentially allowing attackers to hijack web traffic and steal sensitive information or direct users to a malicious website.",
        brief: (
          <span>
            NSEC is <strong>{dnsData?.nsec?.count ? 'disabled' : 'enabled'}</strong> for all hostnames.
          </span>
        ),
        targets: dnsData?.nsec?.count ? [rootDomain] : [],
        stats: prepareDoughnutStatsObject('Population with NSec', populationLabelsArray(hasNSecStats), hasNSecStats),
      },
      {
        heading: 'DNSCrypt',
        description:
          "DNSCrypt is a network protocol which authenticates and encrypts Domain Name System (DNS) traffic between the user's computer and recursive name servers. ... DNSCrypt wraps unmodified DNS traffic between a client and a DNS resolver in a cryptographic construction in order to detect forgery. \n Failure to implement DNSCrypt on a domain could allow attackers to conduct man-in-the-middle attacks and intercept traffic between a client and a DNS resolver.",
        brief: (
          <span>
            DNSCrypt is <strong>{dnsData?.dnsEncryption?.count ? 'disabled' : 'enabled'}</strong> for all hostnames.
          </span>
        ),
        targets: dnsData?.dnsEncryption?.count ? [rootDomain] : [],
        stats: prepareDoughnutStatsObject(
          'Population with DNSCrypt',
          populationLabelsArray(hasEncryptionStats),
          hasEncryptionStats,
        ),
      },
      {
        heading: 'Wildcard',
        description:
          'A wildcard DNS record is a record in a DNS zone that will match requests for non-existent domain names. A wildcard DNS record is specified by using a "*" as the leftmost label (part) of a domain name, e.g. *.example.com. The exact rules for when a wild card will match are specified in RFC 1034, but the rules are neither intuitive nor clearly specified. This has resulted in incompatible implementations and unexpected results when they are used.',
        brief: (
          <span>
            Wildcard records are <strong>{!dnsData?.wildcards?.count ? 'disabled' : 'enabled'}</strong> for all
            hostnames.
          </span>
        ),
        targets: dnsData?.wildcards?.count ? [rootDomain] : [],
        stats: prepareDoughnutStatsObject(
          'Population with Wildcard',
          populationLabelsArray(hasWildcardStats),
          hasWildcardStats,
        ),
      },
      {
        heading: 'Doppelgangers',
        description: (
          <span>
            <p>
              Doppelganger domains mimic other domains for malicious purposes. Doppelganger domains take advantage of
              common mispellings, typos or lack of visual distinction to make a user think that they have navigated to
              the correct website.
            </p>
            <p>
              {/* This value should be changed in the near future */}
              <strong>The root domain has {dnsData?.doppelgangers?.count || 0} doppelgangers.</strong> These
              doppelgangers may be leveraged by malicious actors to impersonate a legitimate website and steal user
              credentials or other information. Organizations should conduct defensive registrations of doppelganger
              domains to prevent them from being acquired by malicious actors.
            </p>
          </span>
        ),
        brief: (
          <span>
            Doppelganger domains have been discovered for{' '}
            <strong>{dnsData?.doppelgangers?.count ? 'all' : 'none'}</strong> hostnames.
          </span>
        ),
        targets: dnsData?.doppelgangers?.count ? [rootDomain] : [],
        stats: prepareDoughnutStatsObject(
          'Population with Doppelgangers',
          populationLabelsArray(hasDoppelgangersStats),
          hasDoppelgangersStats,
        ),
      },
      {
        heading: 'Sender Policy Framework',
        description:
          'Sender Policy Framework (SPF) is an email authentication method designed to detect forged sender addresses during the delivery of email and should be used in conjunction with DMARC. Failure to implement SPF makes it easier for malicious actors to send phishing emails spoofing a legitimate domain.',
        brief: (
          <span>
            SPF is <strong>{dnsData?.spf?.count ? 'disabled' : 'enabled'}</strong> for all hostnames.
          </span>
        ),
        targets: dnsData?.spf?.count ? [rootDomain] : [],
        stats: prepareDoughnutStatsObject('Population with SPF', populationLabelsArray(hasSPFStats), hasSPFStats),
      },
      {
        heading: 'Domain-based Message Authentication, Reporting and Conformance',
        description:
          'DMARC is an email authentication protocol. It is designed to give email domain owners the ability to protect their domain from unauthorized use, commonly known as email spoofing. The purpose and primary outcome of implementing DMARC is to protect a domain from being used in business email compromise attacks, phishing emails, email scams and other cyber threat activities.',
        brief: (
          <span>
            DMARC is <strong>{dnsData?.dmarc?.count ? 'disabled' : 'enabled'}</strong> for all hostnames.
          </span>
        ),
        targets: dnsData?.dmarc?.count ? [rootDomain] : [],
        stats: prepareDoughnutStatsObject('Population with DMARC', populationLabelsArray(hasDMARCStats), hasDMARCStats),
      },
    ],
  };
};

export const _generateDNSFindings = (dnsData, stats = {}) => {
  const missingDns = dnsData.filter((x) => x.dnssec_enabled === null);
  dnsData = dnsData.filter((x) => !missingDns.find((y) => y.hostname === x.hostname));

  const dnssecDisabled = dnsData.filter((x) => x.dnssec_enabled === false);
  const nsecDisabled = dnsData.filter((x) => x.nsec_enabled === false);
  const encryptionDisabled = dnsData.filter((x) => x.encryption_enabled === false);
  const wildcardEnabled = dnsData.filter((x) => x.wildcard_enabled === true);
  const doppelgangers = dnsData.filter((x) => x.doppelgangers > 0);
  const spfDisabled = dnsData.filter((x) => x.spf_enabled === false);
  const dmarcDisabled = dnsData.filter((x) => x.dmarc_enabled === false);

  // The multiplication by 7 is determined by the number of "categories" for the findings [dnsSecDisabled, nsecDisabled, ... dmarcDisabled]
  const totalPotentialFindings = dnsData.length * 7;

  const numFindings =
    dnssecDisabled.length +
    nsecDisabled.length +
    encryptionDisabled.length +
    wildcardEnabled.length +
    doppelgangers.length +
    spfDisabled.length +
    dmarcDisabled.length;

  const dnsStats = [
      stats.total_domains,
      stats.with_doppelgangers,
      stats.with_dmarc,
      stats.with_spf,
      stats.with_wildcard,
      stats.with_nsec,
      stats.with_dnssec,
      stats.with_encryption,
    ],
    hasDNSSecStats = [(1.0 * stats.has_dnssec).toFixed(2), (100 - 1.0 * stats.has_dnssec).toFixed(2)],
    hasNSecStats = [(1.0 * stats.has_nsec).toFixed(2), (100 - 1.0 * stats.has_nsec).toFixed(2)],
    hasEncryptionStats = [(1.0 * stats.has_encryption).toFixed(2), (100 - 1.0 * stats.has_encryption).toFixed(2)],
    hasWildcardStats = [(1.0 * stats.has_wildcard).toFixed(2), (100 - 1.0 * stats.has_wildcard).toFixed(2)],
    hasSPFStats = [(1.0 * stats.has_spf).toFixed(2), (100 - 1.0 * stats.has_spf).toFixed(2)],
    hasDMARCStats = [(1.0 * stats.has_dmarc).toFixed(2), (100 - 1.0 * stats.has_dmarc).toFixed(2)],
    hasDoppelgangersStats = [
      (1.0 * stats.has_doppelgangers).toFixed(2),
      (100 - 1.0 * stats.has_doppelgangers).toFixed(2),
    ];

  return {
    title: 'Domain Name System',
    description:
      'The Domain Name System (DNS) is the phonebook of the Internet. Humans access information online through domain names, like nytimes.com or espn.com. Web browsers interact through Internet Protocol (IP) addresses. DNS translates domain names to IP addresses so browsers can load Internet resources.',
    targets: dnsData.concat(missingDns),
    findingsCounts: {
      totalPotentialFindings: totalPotentialFindings,
      findings: numFindings,
      nonFindings: totalPotentialFindings - numFindings,
    },
    stats: {
      title: 'Domains',
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: `total`,
            data: [dnsStats[0]],
            backgroundColor: chartColorWheel[0],
          },
          {
            label: `doppelgangers`,
            data: [dnsStats[1]],
            backgroundColor: chartColorWheel[1],
          },
          {
            label: `dmarc`,
            data: [dnsStats[2]],
            backgroundColor: chartColorWheel[2],
          },
          {
            label: `spf`,
            data: [dnsStats[3]],
            backgroundColor: chartColorWheel[3],
          },
          {
            label: `wildcard`,
            data: [dnsStats[4]],
            backgroundColor: chartColorWheel[4],
          },
          {
            label: `nsec`,
            data: [dnsStats[5]],
            backgroundColor: chartColorWheel[5],
          },
          {
            label: `dnssec`,
            data: [dnsStats[6]],
            backgroundColor: chartColorWheel[6],
          },
          {
            label: `encryption`,
            data: [dnsStats[7]],
            backgroundColor: chartColorWheel[7],
          },
        ],
      },
      options: {
        animation: {
          duration: 1000,
        },
      },
    },
    sections: [
      {
        heading: 'Domain Name System Security Extensions',
        description:
          'The Domain Name System Security Extensions (DNSSEC) is a suite of Internet Engineering Task Force (IETF) specifications for securing certain kinds of information provided by the Domain Name System (DNS) as used on Internet Protocol (IP) networks. It is a set of extensions to DNS which provide to DNS clients (resolvers) cryptographic authentication of DNS data, authenticated denial of existence, and data integrity, but not availability or confidentiality. \n Failure to implement DNSSEC on a domain can leave that domain vulnerable to DNS cache poisoning attacks, potentially allowing attackers to hijack web traffic and steal sensitive information or direct users to a malicious website.',
        brief: (
          <span>
            DNSSEC is <strong>not enabled</strong> for <strong>{(dnssecDisabled || []).length}</strong> hostnames.
          </span>
        ),
        targets: dnssecDisabled,
        stats: {
          title: 'Population with DNSSec',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasDNSSecStats[0]}%`, `Other: ${hasDNSSecStats[1]}%`],
            datasets: [
              {
                data: hasDNSSecStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Next Secure',
        description:
          "An NSEC-record links to the next record name in the zone (in DNSSEC sorting order) and lists the record types that exist for the record's name. These records can be used by resolvers to verify the non-existence of a record name and type as part of DNSSEC validation. \n NSEC is a part of the DNSSEC suite. Failure to implement DNSSEC on a domain can leave that domain vulnerable to DNS cache poisoning attacks, potentially allowing attackers to hijack web traffic and steal sensitive information or direct users to a malicious website.",
        brief: (
          <span>
            NSEC is <strong>not enabled</strong> for <strong>{(nsecDisabled || []).length}</strong> hostnames.
          </span>
        ),
        targets: nsecDisabled,
        stats: {
          title: 'Population with NSec',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasNSecStats[0]}%`, `Other: ${hasNSecStats[1]}%`],
            datasets: [
              {
                data: hasNSecStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'DNSCrypt',
        description:
          "DNSCrypt is a network protocol which authenticates and encrypts Domain Name System (DNS) traffic between the user's computer and recursive name servers. ... DNSCrypt wraps unmodified DNS traffic between a client and a DNS resolver in a cryptographic construction in order to detect forgery. \n Failure to implement DNSCrypt on a domain could allow attackers to conduct man-in-the-middle attacks and intercept traffic between a client and a DNS resolver.",
        brief: (
          <span>
            DNSCrypt is <strong>not enabled</strong> for <strong>{(encryptionDisabled || []).length}</strong> hostnames.
          </span>
        ),
        targets: encryptionDisabled,
        stats: {
          title: 'Population with DNSCrypt',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasEncryptionStats[0]}%`, `Other: ${hasEncryptionStats[1]}%`],
            datasets: [
              {
                data: hasEncryptionStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Wildcard',
        description:
          'A wildcard DNS record is a record in a DNS zone that will match requests for non-existent domain names. A wildcard DNS record is specified by using a "*" as the leftmost label (part) of a domain name, e.g. *.example.com. The exact rules for when a wild card will match are specified in RFC 1034, but the rules are neither intuitive nor clearly specified. This has resulted in incompatible implementations and unexpected results when they are used.',
        brief: (
          <span>
            Wildcard records are <strong>enabled</strong> for <strong>{(wildcardEnabled || []).length}</strong>{' '}
            hostnames.
          </span>
        ),
        targets: wildcardEnabled,
        stats: {
          title: 'Population with Wildcard',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasWildcardStats[0]}%`, `Other: ${hasWildcardStats[1]}%`],
            datasets: [
              {
                data: hasWildcardStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Doppelgangers',
        description: (
          <span>
            <p>
              Doppelganger domains mimic other domains for malicious purposes. Doppelganger domains take advantage of
              common mispellings, typos or lack of visual distinction to make a user think that they have navigated to
              the correct website.
            </p>
            <p>
              <strong>The average domain has {stats.avg_doppelgangers} doppelgangers.</strong> These doppelgangers may
              be leveraged by malicious actors to impersonate a legitimate website and steal user credentials or other
              information. Organizations should conduct defensive registrations of doppelganger domains to prevent them
              from being acquired by malicious actors.
            </p>
          </span>
        ),
        brief: (
          <span>
            Doppelganger domains have been discovered for <strong>{(doppelgangers || []).length}</strong> hostnames.
          </span>
        ),
        targets: doppelgangers,
        stats: {
          title: 'Population with Doppelgangers',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasDoppelgangersStats[0]}%`, `Other: ${hasDoppelgangersStats[1]}%`],
            datasets: [
              {
                data: hasDoppelgangersStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Sender Policy Framework',
        description:
          'Sender Policy Framework (SPF) is an email authentication method designed to detect forged sender addresses during the delivery of email and should be used in conjunction with DMARC. Failure to implement SPF makes it easier for malicious actors to send phishing emails spoofing a legitimate domain.',
        brief: (
          <span>
            SPF is <strong>not enabled</strong> for <strong>{(spfDisabled || []).length}</strong> hostnames.
          </span>
        ),
        targets: spfDisabled,
        stats: {
          title: 'Population with SPF',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasSPFStats[0]}%`, `Other: ${hasSPFStats[1]}%`],
            datasets: [
              {
                data: hasSPFStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Domain-based Message Authentication, Reporting and Conformance',
        description:
          'DMARC is an email authentication protocol. It is designed to give email domain owners the ability to protect their domain from unauthorized use, commonly known as email spoofing. The purpose and primary outcome of implementing DMARC is to protect a domain from being used in business email compromise attacks, phishing emails, email scams and other cyber threat activities.',
        brief: (
          <span>
            DMARC is <strong>not enabled</strong> for <strong>{(dmarcDisabled || []).length}</strong> hostnames.
          </span>
        ),
        targets: dmarcDisabled,
        stats: {
          title: 'Population with DMARC',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasDMARCStats[0]}%`, `Other: ${hasDMARCStats[1]}%`],
            datasets: [
              {
                data: hasDMARCStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
    ],
  };
};

export const _generateSSLFindingsFromCRUX = (sslData, findingsCounts) => {
  const totalDomains = sslData?.totalScanned?.count || 1; // 1 is used to avoid division by zero below
  const sslStats = [
      totalDomains,
      sslData?.downgradableEndpoints?.count || 0,
      sslData?.deprecatedProtocols?.count || 0,
      sslData?.expiredCertificates?.count || 0,
      sslData?.upcomingCertificateExpirations?.count || 0,
    ],
    hasDowngradableEndpoints = populationDataArray((sslStats[1] / totalDomains) * 100),
    hasDeprecatedProtocols = populationDataArray((sslStats[2] / totalDomains) * 100),
    hasExpiredCertStats = populationDataArray((sslStats[3] / totalDomains) * 100),
    hasUpcomingExpiration = populationDataArray((sslStats[4] / totalDomains) * 100);

  return {
    title: 'Transport Layer Security',
    description:
      'Transport Layer Security (TLS), and its now-deprecated predecessor, Secure Sockets Layer (SSL), are cryptographic protocols designed to provide communications security over a computer network. Several versions of the protocols find widespread use in applications such as web browsing, email, instant messaging, and voice over IP (VoIP). Websites can use TLS to secure all communications between their servers and web browsers.',
    targets: sslData?.totalScanned?.targets || [],
    findingsCounts: {
      totalPotentialFindings: findingsCounts?.sslDenominator || 0,
      findings: findingsCounts?.ssl || 0,
      nonFindings: (findingsCounts?.sslDenominator || 0) - (findingsCounts?.ssl || 0),
    },
    stats: prepareBarStatsObject('Hostnames', sslStatsLabels, sslStats, 'hostnames'),
    sections: [
      {
        heading: 'Downgradable Endpoints',
        description:
          "Some endpoints support a recent version of Transport Layer Security, but will negotiate connections using other than recommended protocols should the client's browser make the request. This could allow attackers to intercept and decrypt sensitive data.",
        brief: (
          <span>
            <strong>{sslData?.downgradableEndpoints?.count || 0}</strong> hostnames support downgrading.
          </span>
        ),
        targets: sslData?.downgradableEndpoints?.targets || [],
        stats: prepareDoughnutStatsObject(
          'Population with Downgradable Endpoints',
          populationLabelsArray(hasDowngradableEndpoints),
          hasDowngradableEndpoints,
        ),
      },
      {
        heading: 'Deprecated Protocols',
        description:
          'SSL 2.0 was deprecated in 2011 by RFC 6176. In 2014, SSL 3.0 was found to be vulnerable to the POODLE attack that affects all block ciphers in SSL; RC4, the only non-block cipher supported by SSL 3.0, is also feasibly broken as used in SSL 3.0. SSL 3.0 was deprecated in June 2015 by RFC 7568. In October 2018, Apple, Google, Microsoft, and Mozilla jointly announced they would deprecate TLS 1.0 and 1.1 in March 2020.',
        brief: (
          <span>
            <strong>{sslData?.deprecatedProtocols?.count || 0}</strong> hostnames allow SSL connections.
          </span>
        ),
        targets: sslData?.deprecatedProtocols?.targets || [],
        stats: prepareDoughnutStatsObject(
          'Population with Deprecated Protocols',
          populationLabelsArray(hasDeprecatedProtocols),
          hasDeprecatedProtocols,
        ),
      },
      {
        heading: 'Expired Certificates',
        description:
          'Sites presenting an expired or otherwise invalid digital certificate expose a system and its users to information theft.',
        brief: (
          <span>
            <strong>{sslData?.expiredCertificates?.count || 0}</strong> hostnames have an expired digital certificate.
          </span>
        ),
        targets: sslData?.expiredCertificates?.targets || [],
        stats: prepareDoughnutStatsObject(
          'Population with Expired Certificates',
          populationLabelsArray(hasExpiredCertStats),
          hasExpiredCertStats,
        ),
      },
      {
        heading: 'Upcoming Certificate Expirations',
        description:
          'Digital certificates do not normally automatically renew. While the process is generally made simple by certificate authorities, organizations should have a process in place to manage upcoming certificate expirations and renew them before they expire.',
        brief: (
          <span>
            <strong>{sslData?.upcomingCertificateExpirations?.count || 0}</strong> hostnames have a digital certificate
            that will expire within <strong>90</strong> days.
          </span>
        ),
        targets: sslData?.upcomingCertificateExpirations?.targets || [],
        stats: prepareDoughnutStatsObject(
          'Population with Upcoming Expirations',
          populationLabelsArray(hasUpcomingExpiration),
          hasUpcomingExpiration,
        ),
      },
    ],
  };
};

export const _generateSSLFindings = (sslData, stats = {}) => {
  const missingSSL = sslData.filter((x) => x.total_ciphers === null);
  sslData = sslData.filter((x) => !missingSSL.find((y) => x.hostname === y.hostname));

  const aCiphers = sslData.filter((x) => x.total_ciphers_a > 0),
    otherCiphers = sslData.filter(
      (x) =>
        x.total_ciphers_b > 0 ||
        x.total_ciphers_c > 0 ||
        x.total_ciphers_d > 0 ||
        x.total_ciphers_e > 0 ||
        x.total_ciphers_f > 0,
    ),
    goodWithBad = aCiphers.filter((x) => otherCiphers.find((y) => x.hostname === y.hostname)),
    supportsSSL = sslData.filter((x) => x.total_sslv3 > 0 || x.total_sslv2 > 0),
    expiredCerts = sslData.filter((x) => x.has_expired_cert),
    upcomingExpirations = sslData.filter((x) => x.has_upcoming_expiration);

  // The multiplication by 6 is determined by the number of "categories" for the findings [aCiphers, otherCiphers, ... upcomingExpirations]
  const totalPotentialFindings = sslData.length * 6;

  const numFindings =
    aCiphers.length +
    otherCiphers.length +
    goodWithBad.length +
    supportsSSL.length +
    expiredCerts.length +
    upcomingExpirations.length;

  const sslStats = [
      stats.total_hostnames,
      stats.with_ssl,
      stats.with_http,
      stats.with_sslv2,
      stats.with_sslv3,
      stats.with_tlsv10,
      stats.with_tlsv11,
      stats.with_weak_ciphers,
      stats.with_downgradable_endpoints,
      stats.with_deprecated_protocols,
      stats.with_expired_cert,
      stats.with_upcoming_expiration,
      stats.with_downgradable_endpoints,
    ],
    hasDowngradableEndpointsStats = [
      (1.0 * stats.has_downgradable_endpoints).toFixed(2),
      (100 - 1.0 * stats.has_downgradable_endpoints).toFixed(2),
    ],
    hasDeprecatedProtocols = [
      (1.0 * stats.has_deprecated_protocols).toFixed(2),
      (100 - 1.0 * stats.has_deprecated_protocols).toFixed(2),
    ],
    hasExpiredCertStats = [(1.0 * stats.has_expired_cert).toFixed(2), (100 - 1.0 * stats.has_expired_cert).toFixed(2)],
    hasUpcomingExpiration = [
      (1.0 * stats.has_upcoming_expiration).toFixed(2),
      (100 - 1.0 * stats.has_upcoming_expiration).toFixed(2),
    ];

  return {
    title: 'Transport Layer Security',
    description:
      'Transport Layer Security (TLS), and its now-deprecated predecessor, Secure Sockets Layer (SSL), are cryptographic protocols designed to provide communications security over a computer network. Several versions of the protocols find widespread use in applications such as web browsing, email, instant messaging, and voice over IP (VoIP). Websites can use TLS to secure all communications between their servers and web browsers.',
    targets: sslData.concat(missingSSL),
    findingsCounts: {
      totalPotentialFindings: totalPotentialFindings,
      findings: numFindings,
      nonFindings: totalPotentialFindings - numFindings,
    },
    stats: {
      title: 'Hostnames',
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: `total`,
            data: [sslStats[0]],
            backgroundColor: chartColorWheel[0],
          },
          {
            label: `https`,
            data: [sslStats[1]],
            backgroundColor: chartColorWheel[1],
          },
          {
            label: `http`,
            data: [sslStats[2]],
            backgroundColor: chartColorWheel[2],
          },
          {
            label: `sslv2`,
            data: [sslStats[3]],
            backgroundColor: chartColorWheel[3],
          },
          {
            label: `sslv3`,
            data: [sslStats[4]],
            backgroundColor: chartColorWheel[4],
          },
          {
            label: `tlsv1`,
            data: [sslStats[5]],
            backgroundColor: chartColorWheel[5],
          },
          {
            label: `tlsv11`,
            data: [sslStats[6]],
            backgroundColor: chartColorWheel[6],
          },
          {
            label: `weak ciphers`,
            data: [sslStats[7]],
            backgroundColor: chartColorWheel[7],
          },
        ],
      },
      options: {
        animation: {
          duration: 1000,
        },
      },
    },
    sections: [
      {
        heading: 'Downgradable Endpoints',
        description:
          "Some endpoints support a recent version of Transport Layer Security, but will negotiate connections using other than recommended protocols should the client's browser make the request. This could allow attackers to intercept and decrypt sensitive data.",
        brief: (
          <span>
            <strong>{(goodWithBad || []).length}</strong> hostnames support downgrading.
          </span>
        ),
        targets: goodWithBad,
        stats: {
          title: 'Population with Downgradable Endpoints',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasDowngradableEndpointsStats[0]}%`, `Other: ${hasDowngradableEndpointsStats[1]}%`],
            datasets: [
              {
                data: hasDowngradableEndpointsStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Deprecated Protocols',
        description:
          'SSL 2.0 was deprecated in 2011 by RFC 6176. In 2014, SSL 3.0 was found to be vulnerable to the POODLE attack that affects all block ciphers in SSL; RC4, the only non-block cipher supported by SSL 3.0, is also feasibly broken as used in SSL 3.0. SSL 3.0 was deprecated in June 2015 by RFC 7568. In October 2018, Apple, Google, Microsoft, and Mozilla jointly announced they would deprecate TLS 1.0 and 1.1 in March 2020.',
        brief: (
          <span>
            <strong>{(supportsSSL || []).length}</strong> hostnames allow SSL connections.
          </span>
        ),
        targets: supportsSSL,
        stats: {
          title: 'Population with Deprecated Protocols',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasDeprecatedProtocols[0]}%`, `Other: ${hasDeprecatedProtocols[1]}%`],
            datasets: [
              {
                data: hasDeprecatedProtocols,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Expired Certificates',
        description:
          'Sites presenting an expired or otherwise invalid digital certificate expose a system and its users to information theft.',
        brief: (
          <span>
            <strong>{(expiredCerts || []).length}</strong> hostnames have an expired digital certificate.
          </span>
        ),
        targets: expiredCerts,
        stats: {
          title: 'Population with Expired Certificates',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasExpiredCertStats[0]}%`, `Other: ${hasExpiredCertStats[1]}%`],
            datasets: [
              {
                data: hasExpiredCertStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Upcoming Certificate Expirations',
        description:
          'Digital certificates do not normally automatically renew. While the process is generally made simple by certificate authorities, organizations should have a process in place to manage upcoming certificate expirations and renew them before they expire.',
        brief: (
          <span>
            <strong>{(upcomingExpirations || []).length}</strong> hostnames have a digital certificate that will expire
            within <strong>90</strong> days.
          </span>
        ),
        targets: upcomingExpirations,
        stats: {
          title: 'Population with Upcoming Expirations',
          type: 'doughnut',
          data: {
            labels: [`Pop ${hasUpcomingExpiration[0]}%`, `Other: ${hasUpcomingExpiration[1]}%`],
            datasets: [
              {
                data: hasUpcomingExpiration,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
    ],
  };
};

export const _generateAppSecFindingsFromCRUX = (appSecData, findingsCounts) => {
  const totalDomains = appSecData?.totalScanned?.count || 1; // 1 is used to avoid division by zero below
  const appSecStats = [
      totalDomains,
      appSecData?.csp?.count || 0,
      appSecData?.xss?.count || 0,
      appSecData?.xfo?.count || 0,
      appSecData?.pkp?.count || 0,
      appSecData?.cto?.count || 0,
      appSecData?.sts?.count || 0,
      appSecData?.externalReferences?.count || 0,
      appSecData?.dynamicPorts?.count || 0,
    ],
    hasCSPStats = populationDataArray((appSecStats[1] / totalDomains) * 100),
    hasXSSStats = populationDataArray((appSecStats[2] / totalDomains) * 100),
    hasXFOStats = populationDataArray((appSecStats[3] / totalDomains) * 100),
    hasPKPStats = populationDataArray((appSecStats[4] / totalDomains) * 100),
    hasCTOStats = populationDataArray((appSecStats[5] / totalDomains) * 100),
    hasSTSStats = populationDataArray((appSecStats[6] / totalDomains) * 100),
    hasExternalRefsStats = populationDataArray((appSecStats[7] / totalDomains) * 100),
    hasDynamicPortsStats = populationDataArray((appSecStats[8] / totalDomains) * 100);

  return {
    title: 'Application Security',
    description: 'Application security is the discipline of mitigating risks involved in making a system accessible.',
    targets: appSecData?.totalScanned?.targets || [],
    findingsCounts: {
      totalPotentialFindings: findingsCounts?.appSecDenominator || 0,
      findings: findingsCounts?.appSec || 0,
      nonFindings: (findingsCounts?.appSecDenominator || 0) - (findingsCounts?.appSec || 0),
    },
    stats: prepareBarStatsObject('Hostnames', appSecStatsLabels, appSecStats, 'websites'),
    sections: [
      {
        heading: 'Content Security Policy',
        description:
          'The HTTP Content-Security-Policy response header allows web site administrators to control resources the user agent is allowed to load for a given page. With a few exceptions, policies mostly involve specifying server origins and script endpoints. This helps guard against cross-site scripting attacks (XSS), clickjacking, and other code execution attacks.',
        brief: (
          <span>
            <strong>{appSecData?.csp?.count || 0}</strong> website(s) <strong>do not specify</strong> a content security
            policy.
          </span>
        ),
        targets: appSecData?.csp?.targets || [],
        stats: prepareDoughnutStatsObject('Population with CSP', populationLabelsArray(hasCSPStats), hasCSPStats),
      },
      {
        heading: 'Cross Site Scripting',
        description:
          "The HTTP X-XSS-Protection response header is a feature of Internet Explorer, Chrome and Safari that stops pages from loading when they detect reflected cross-site scripting (XSS) attacks. Although these protections are largely unnecessary in modern browsers when sites implement a strong Content-Security-Policy that disables the use of inline JavaScript ('unsafe-inline'), they can still provide protections for users of older web browsers that don't yet support CSP.",
        brief: (
          <span>
            <strong>{appSecData?.xss?.count || 0}</strong> website(s) <strong>do not enable</strong> XSS protection.
          </span>
        ),
        targets: appSecData?.xss?.targets || [],
        stats: prepareDoughnutStatsObject('Population with XSS', populationLabelsArray(hasXSSStats), hasXSSStats),
      },
      {
        heading: 'X-Frame Options',
        description:
          'The X-Frame-Options header can be used to indicate that a website may be embedded within another site, and offer an explicit list sites allowed to embed. This can help prevent clickjacking attacks by ensuring that the website’s content is not embedded into other sites.',
        brief: (
          <span>
            <strong>{appSecData?.xfo?.count || 0}</strong> website(s) <strong>do not have</strong> an XFO policy.
          </span>
        ),
        targets: appSecData?.xfo?.targets || [],
        stats: prepareDoughnutStatsObject('Population with XFO', populationLabelsArray(hasXFOStats), hasXFOStats),
      },
      {
        heading: 'Public Key Pinning',
        description:
          'Originally conceived as a way to combat "Man in the Middle" attacks, the Public-Key-Pins response header explicitly references the certificates (by public key) that the browser should be using to establish secure transport. The mechanism was deprecated by the Google Chrome team in late 2017 because of its complexity and dangerous side-effects. Google recommends using the Expect-CT as a safer alternative.',
        brief: (
          <span>
            <strong>{appSecData?.pkp?.count || 0}</strong> website(s) <strong>do not pin</strong> public keys.
          </span>
        ),
        targets: appSecData?.pkp?.targets || [],
        stats: prepareDoughnutStatsObject('Population with PKP', populationLabelsArray(hasPKPStats), hasPKPStats),
      },
      {
        heading: 'Content Type Options',
        description:
          'The X-Content-Type-Options header disables MIME sniffing and forces browser to use the type given in Content-Type?. Content sniffing can lead to the download and/or detonation of malware disguised as innocuous content.',
        brief: (
          <span>
            <strong>{appSecData?.cto?.count || 0}</strong> website(s) <strong>do not present</strong> a CTO header.
          </span>
        ),
        targets: appSecData?.cto?.targets || [],
        stats: prepareDoughnutStatsObject('Population with CTO', populationLabelsArray(hasCTOStats), hasCTOStats),
      },
      {
        heading: 'Strict Transport Security',
        description:
          'The HTTP Strict-Transport-Security response header (often abbreviated as HSTS) lets a web site tell browsers that it should only be accessed using HTTPS, instead of using HTTP. This helps ensure that data transmission to and from the site occurs securely and lowers the risk of man-in-the-middle attacks.',
        brief: (
          <span>
            <strong>{appSecData?.sts?.count || 0}</strong> website(s) <strong>do not specify</strong> HSTS.
          </span>
        ),
        targets: appSecData?.sts?.targets || [],
        stats: prepareDoughnutStatsObject('Population with STS', populationLabelsArray(hasSTSStats), hasSTSStats),
      },
      {
        heading: 'External References',
        description:
          'Websites that link to external resources are effectively endorsing the legitimacy of said resource. Websites with links to external references may inadvertently send their users to malicious websites if the linked external content is compromised by attackers.',
        brief: (
          <span>
            <strong>{appSecData?.externalReferences?.count || 0}</strong> website(s) link to external resources.
          </span>
        ),
        targets: appSecData?.externalReferences?.targets || [],
        stats: prepareDoughnutStatsObject(
          'Population with External References',
          populationLabelsArray(hasExternalRefsStats),
          hasExternalRefsStats,
        ),
      },
      {
        heading: 'Dynamic Ports',
        description:
          "TCP/UDP ports with a number greater than 49151 are considered dynamic ports and are opened as needed by a system's user activity. Exposed dynamic ports could indicate system misconfiguration.",
        brief: (
          <span>
            <strong>{appSecData?.dynamicPorts?.count || 0}</strong> website(s) expose dynamic ports.
          </span>
        ),
        targets: appSecData?.dynamicPorts?.targets || [],
        stats: prepareDoughnutStatsObject(
          'Population with Dynamic Ports',
          populationLabelsArray(hasDynamicPortsStats),
          hasDynamicPortsStats,
        ),
      },
    ],
  };
};

export const _generateAppSecFindings = (appSecData, stats = {}) => {
  const missingAppSec = appSecData.filter((x) => x.total_ports === null);
  appSecData = appSecData.filter((x) => !missingAppSec.find((y) => x.hostname === y.hostname));

  const missingCsp = appSecData.filter((x) => x.total_csp < 1);
  const missingXss = appSecData.filter((x) => x.total_xss_protection < 1);
  const missingXfo = appSecData.filter((x) => x.total_xfo < 1);
  const missingPkp = appSecData.filter((x) => x.total_pkp < 1);
  const missingCto = appSecData.filter((x) => x.total_cto < 1);
  const missingSts = appSecData.filter((x) => x.total_sts < 1);
  const hasExternalRefs = appSecData.filter((x) => x.total_external_refs > 0);
  const hasVulns = appSecData.filter((x) => x.total_vulns > 0);
  const dynamicPorts = appSecData.filter((x) => x.dynamic_ports > 0);

  // The multiplication by 9 is determined by the number of "categories" for the findings [missingCsp, missingXss, ... dynamicPorts]
  const totalPotentialFindings = appSecData.length * 9;

  const numFindings =
    missingCsp.length +
    missingXss.length +
    missingXfo.length +
    missingPkp.length +
    missingCto.length +
    missingSts.length +
    hasExternalRefs.length +
    hasVulns.length +
    dynamicPorts.length;

  const appSecStats = [
      stats.total_hostnames,
      stats.with_vulns,
      stats.with_csp,
      stats.with_cto,
      stats.with_pkp,
      stats.with_sts,
      stats.with_xfo,
      stats.with_xss,
    ],
    hasCSPStats = [(1.0 * stats.has_csp).toFixed(2), (100 - 1.0 * stats.has_csp).toFixed(2)],
    hasXSSStats = [(1.0 * stats.has_xss).toFixed(2), (100 - 1.0 * stats.has_xss).toFixed(2)],
    hasXFOStats = [(1.0 * stats.has_xfo).toFixed(2), (100 - 1.0 * stats.has_xfo).toFixed(2)],
    hasPKPStats = [(1.0 * stats.has_pkp).toFixed(2), (100 - 1.0 * stats.has_pkp).toFixed(2)],
    hasCTOStats = [(1.0 * stats.has_cto).toFixed(2), (100 - 1.0 * stats.has_cto).toFixed(2)],
    hasSTSStats = [(1.0 * stats.has_sts).toFixed(2), (100 - 1.0 * stats.has_sts).toFixed(2)],
    hasExternalRefsStats = [
      (1.0 * stats.has_external_refs).toFixed(2),
      (100 - 1.0 * stats.has_external_refs).toFixed(2),
    ],
    hasDynamicPortsStats = [
      (1.0 * stats.has_dynamic_ports).toFixed(2),
      (100 - 1.0 * stats.has_dynamic_ports).toFixed(2),
    ];

  return {
    title: 'Application Security',
    description: 'Application security is the discipline of mitigating risks involved in making a system accessible.',
    targets: appSecData.concat(missingAppSec),
    findingsCounts: {
      totalPotentialFindings: totalPotentialFindings,
      findings: numFindings,
      nonFindings: totalPotentialFindings - numFindings,
    },
    stats: {
      title: 'Hostnames',
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: `total`,
            data: [appSecStats[0]],
            backgroundColor: chartColorWheel[0],
          },
          {
            label: `vulns`,
            data: [appSecStats[1]],
            backgroundColor: chartColorWheel[1],
          },
          {
            label: `csp`,
            data: [appSecStats[2]],
            backgroundColor: chartColorWheel[2],
          },
          {
            label: `cto`,
            data: [appSecStats[3]],
            backgroundColor: chartColorWheel[3],
          },
          {
            label: `pkp`,
            data: [appSecStats[4]],
            backgroundColor: chartColorWheel[4],
          },
          {
            label: `sts`,
            data: [appSecStats[5]],
            backgroundColor: chartColorWheel[5],
          },
          {
            label: `xfo`,
            data: [appSecStats[6]],
            backgroundColor: chartColorWheel[6],
          },
          {
            label: `xss`,
            data: [appSecStats[7]],
            backgroundColor: chartColorWheel[7],
          },
        ],
      },
      options: {
        animation: {
          duration: 1000,
        },
      },
    },
    sections: [
      {
        heading: 'Content Security Policy',
        description:
          'The HTTP Content-Security-Policy response header allows web site administrators to control resources the user agent is allowed to load for a given page. With a few exceptions, policies mostly involve specifying server origins and script endpoints. This helps guard against cross-site scripting attacks (XSS), clickjacking, and other code execution attacks.',
        brief: (
          <span>
            <strong>{(missingCsp || []).length}</strong> website(s) <strong>do not specify</strong> a content security
            policy.
          </span>
        ),
        targets: missingCsp,
        stats: {
          title: 'Population with CSP',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasCSPStats[0]}%`, `Other: ${hasCSPStats[1]}%`],
            datasets: [
              {
                data: hasCSPStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Cross Site Scripting',
        description:
          "The HTTP X-XSS-Protection response header is a feature of Internet Explorer, Chrome and Safari that stops pages from loading when they detect reflected cross-site scripting (XSS) attacks. Although these protections are largely unnecessary in modern browsers when sites implement a strong Content-Security-Policy that disables the use of inline JavaScript ('unsafe-inline'), they can still provide protections for users of older web browsers that don't yet support CSP.",
        brief: (
          <span>
            <strong>{(missingXss || []).length}</strong> website(s) <strong>do not enable</strong> XSS protection.
          </span>
        ),
        targets: missingXss,
        stats: {
          title: 'Population with XSS',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasXSSStats[0]}%`, `Other: ${hasXSSStats[1]}%`],
            datasets: [
              {
                data: hasXSSStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'X-Frame Options',
        description:
          'The X-Frame-Options header can be used to indicate that a website may be embedded within another site, and offer an explicit list sites allowed to embed. This can help prevent clickjacking attacks by ensuring that the website’s content is not embedded into other sites.',
        brief: (
          <span>
            <strong>{(missingXfo || []).length}</strong> website(s) <strong>do not have</strong> an XFO policy.
          </span>
        ),
        targets: missingXfo,
        stats: {
          title: 'Population with XFO',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasXFOStats[0]}%`, `Other: ${hasXFOStats[1]}%`],
            datasets: [
              {
                data: hasXFOStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Public Key Pinning',
        description:
          'Originally conceived as a way to combat "Man in the Middle" attacks, the Public-Key-Pins response header explicitly references the certificates (by public key) that the browser should be using to establish secure transport. The mechanism was deprecated by the Google Chrome team in late 2017 because of its complexity and dangerous side-effects. Google recommends using the Expect-CT as a safer alternative.',
        brief: (
          <span>
            <strong>{(missingPkp || []).length}</strong> website(s) <strong>do not pin</strong> public keys.
          </span>
        ),
        targets: missingPkp,
        stats: {
          title: 'Population with PKP',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasPKPStats[0]}%`, `Other: ${hasPKPStats[1]}%`],
            datasets: [
              {
                data: hasPKPStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Content Type Options',
        description:
          'The X-Content-Type-Options header disables MIME sniffing and forces browser to use the type given in Content-Type. Content sniffing can lead to the download and/or detonation of malware disguised as innocuous content.',
        brief: (
          <span>
            <strong>{(missingCto || []).length}</strong> website(s) <strong>do not present</strong> a CTO header.
          </span>
        ),
        targets: missingCto,
        stats: {
          title: 'Population with CTO',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasCTOStats[0]}%`, `Other: ${hasCTOStats[1]}%`],
            datasets: [
              {
                data: hasCTOStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Strict Transport Security',
        description:
          'The HTTP Strict-Transport-Security response header (often abbreviated as HSTS) lets a web site tell browsers that it should only be accessed using HTTPS, instead of using HTTP. This helps ensure that data transmission to and from the site occurs securely and lowers the risk of man-in-the-middle attacks.',
        brief: (
          <span>
            <strong>{(missingSts || []).length}</strong> website(s) <strong>do not specify</strong> HSTS.
          </span>
        ),
        targets: missingSts,
        stats: {
          title: 'Population with STS',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasSTSStats[0]}%`, `Other: ${hasSTSStats[1]}%`],
            datasets: [
              {
                data: hasSTSStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'External References',
        description:
          'Websites that link to external resources are effectively endorsing the legitimacy of said resource. Websites with links to external references may inadvertently send their users to malicious websites if the linked external content is compromised by attackers.',
        brief: (
          <span>
            <strong>{(hasExternalRefs || []).length}</strong> website(s) link to external resources.
          </span>
        ),
        targets: hasExternalRefs,
        stats: {
          title: 'Population with External References',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasExternalRefsStats[0]}%`, `Other: ${hasExternalRefsStats[1]}%`],
            datasets: [
              {
                data: hasExternalRefsStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
      {
        heading: 'Dynamic Ports',
        description:
          "TCP/UDP ports with a number greater than 49151 are considered dynamic ports and are opened as needed by a system's user activity. Exposed dynamic ports could indicate system misconfiguration.",
        brief: (
          <span>
            <strong>{(dynamicPorts || []).length}</strong> website(s) expose dynamic ports.
          </span>
        ),
        targets: dynamicPorts,
        stats: {
          title: 'Population with Dynamic Ports',
          type: 'doughnut',
          data: {
            labels: [`Pop: ${hasDynamicPortsStats[0]}%`, `Other: ${hasDynamicPortsStats[1]}%`],
            datasets: [
              {
                data: hasDynamicPortsStats,
                backgroundColor: chartColorWheel,
              },
            ],
          },
          options: {
            animation: {
              duration: 1000,
            },
          },
        },
      },
    ],
  };
};
