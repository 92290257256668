import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const FileAccessRequest = ({ request, index, content }) => {
  return (
    <div className="file-access-request">
      <Draggable draggableId={request.id} index={index}>
        {(provided, snapshot) => (
          <div
            className={'file-access-request-container' + (snapshot.isDragging ? ' isdragging' : '')}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div className="requestContent">
              {content?.companyURL && <img className="companyLogo" src={content?.companyURL} />}
              <div className="contentInfo">
                <div>
                  <b>Company:</b> {content?.companyName}
                </div>
                <div>
                  <b>Name:</b>{' '}
                  {(content?.firstName || '') +
                    (content?.firstName && content.lastName ? ' ' : '') +
                    (content?.lastName || '')}
                </div>
                <div>
                  <b>Email:</b> {content?.email || 'N/A'}
                </div>
                <div>
                  <b>Requested Date:</b>{' '}
                  {content?.requestedDate ? new Date(content?.requestedDate).toLocaleDateString() : 'N/A'}
                </div>
                {content?.ndaURL && (
                  <div className="buttonRow">
                    <a className="view-nda-lnk" href={content?.ndaURL}>
                      View NDA
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default FileAccessRequest;
