import React, { Component } from 'react';
import { assessmentTypes } from '../../../constants/assessmentTypes';

import './topBanner.css';

export default class TopBanner extends Component {
  render() {
    const { vendor = {} } = this.props;
    const { scoreDate, companyName } = vendor;
    const formattedDate = new Date(scoreDate).toLocaleDateString();

    return (
      <div className="postern-top-banner">
        <div className="vendor-name">{companyName || '[vendor name]'}</div>
        <div className="report-type">{assessmentTypes.DDRR.name} </div>
        <div className="last-reported-date">Last Reported: {scoreDate ? formattedDate : '[report date]'}</div>
      </div>
    );
  }
}
