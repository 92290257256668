import React from 'react';
import '../routes/vendor/catalogVendor.css';
import { riskRankColorByString } from '../constants/color';

class SectionSummaryLabel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label = '', severity = '' } = this.props;

    return (
      <>
        <div style={{ backgroundColor: riskRankColorByString(severity) }} className={'ch-ss-label-base'}>
          <span>{label ? label : ''}</span>
        </div>
        <div className="ch-ss-rank">
          <p>{`${severity}` === 'Medium' ? 'Moderate' : `${severity}`}</p>
        </div>
      </>
    );
  }
}

export default SectionSummaryLabel;
