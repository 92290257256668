import React from 'react';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router-dom';
import { Busy, Pagination, Table, useUpdateState } from '../../../components';
import { toastError } from '../../../lib/utils';
import config from '../../../config';

const ConfirmCommitModal = (props) => {
  //Props
  const { id, isOpen, onModalClose = () => {}, handleImportsListClick = () => {}, handleHomeClick = () => {} } = props;

  //State
  const [state, setState] = useUpdateState({
    processingConfirm: false,
    showCommitSuccess: false,
  });

  const { processingConfirm, showCommitSuccess } = state;

  //Functions
  const commit = () => {
    setState({ processingConfirm: true });

    fetch(config.api.urlFor('vendorCSVCommit'), {
      method: 'POST',
      body: { uploadID: id },
    })
      .then((res) => res.json())
      .then(() => {
        setState({
          processingConfirm: false,
          showCommitSuccess: true,
        });
      })
      .catch(() => {
        setState({ processingConfirm: false });
        toastError(
          'An error occured while processing the commit. Vendors have not been added to your collection of to My Vendors.',
        );
      });
  };

  //Render
  return (
    <ReactModal
      isOpen={isOpen}
      className="confirm-import-modal"
      ariaHideApp={false}
      contentLabel="Confirm Import"
      shouldCloseOnOverlayClick={!processingConfirm && !showCommitSuccess}
      onRequestClose={onModalClose}
    >
      {showCommitSuccess ? (
        <div className="import-modal-no-header-content">
          <div className="success-checkmark"></div>
          <p>Selected Vendors have been added to your collection of My Vendors.</p>
          <div className="side-buttons-container">
            <div>
              <button className="btn btn-continue" onClick={handleHomeClick}>
                Home
              </button>
            </div>
            <div>
              <button className="btn btn-continue" onClick={handleImportsListClick}>
                Back to Imports Page
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {processingConfirm ? (
            <div className="import-modal-no-header-content">
              <Busy isBusy={true} />
              <div className="section-inner-title animated-ellipsis">Committing Changes</div>
            </div>
          ) : (
            <>
              <div className="import-modal-header">
                Confirmation
                <button className="import-modal-header-close" onClick={onModalClose}>
                  x
                </button>
              </div>
              <div className="import-modal-content">
                <p>
                  By continuing, you confirm that each of the vendors you have processed for the file will be added to
                  your collection of My Vendors. Please be aware that any vendors without a{' '}
                  <strong>Selected Vendor</strong> match will NOT be added, and selections are finalized upon
                  completion.
                </p>
                <div className="side-buttons-container">
                  <div>
                    <button className="btn btn-close" onClick={onModalClose}>
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-continue" onClick={commit}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </ReactModal>
  );
};

const UploadDetails = (props) => {
  //Props
  const { id, isBusy, model, page, totalRecords, handleRefreshClick = () => {}, handlePageChange = () => {} } = props;

  //State
  const [state, setState] = useUpdateState({
    confirmModalIsOpen: false,
  });

  const { confirmModalIsOpen } = state;

  const enableCommit = model && model.length && model.find((item) => item.committed !== 'Committed');

  //Functions
  const togglConfirmCommiteModal = () => {
    enableCommit && setState({ confirmModalIsOpen: !confirmModalIsOpen });
  };

  const handleHomeClick = () => {
    props.history.push(config.routes.home.to);
  };

  const handleImportsListClick = () => {
    props.history.push(config.routes.vendorImports.to.replace('/:id?', ''));
  };

  //Render
  return (
    <>
      <ConfirmCommitModal
        id={id}
        isOpen={confirmModalIsOpen}
        onModalClose={togglConfirmCommiteModal}
        handleHomeClick={handleHomeClick}
        handleImportsListClick={handleImportsListClick}
      />
      <div className="imports-table-actionables">
        <div className="imports-actionables-trinary">
          <div>
            <button className="btn btn-primary" onClick={handleImportsListClick}>
              Back
            </button>
          </div>
          <div>
            <Pagination page={page} totalRecords={totalRecords} onPageChange={handlePageChange} />
          </div>
          <div>
            <button className="btn btn-primary" onClick={togglConfirmCommiteModal} disabled={!enableCommit}>
              Commit
            </button>

            <button className="btn btn-primary" onClick={handleRefreshClick}>
              Refresh
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Table
            columns={[
              { label: 'Vendor Name', source: 'name' },
              { label: 'Domain', source: 'domain' },
              { label: 'Status', source: 'committed' },
              { label: 'Selected Vendor', source: 'selected' },
            ]}
            data={model}
            isBusy={isBusy}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(UploadDetails);
