import { vendorAssessmentEndpointHandler } from './vendor/vendorAssessmentEndpointHandler';
import config from './../config';
import React from 'react';
import { assessmentTypes } from '../constants/assessmentTypes';
import { withEvents, events } from './withEvents';

const StatusButton = (props) => {
  const { assessmentInfo, handlePurchaseButtonClick, canPurchaseReports, history, disabledPurchaseText } = props;
  const {
    type,
    isComplete,
    reportTypeID,
    reportRawType,
    vendorDomain,
    vendorName,
    vendorID,
    assessmentRequestID,
    hasAccess,
    hasHistoricalData,
    reportDate,
    productID,
    productName,
    assessmentData,
  } = assessmentInfo;

  const isAvailable = isComplete && hasAccess;
  const purchasable = isComplete && !hasAccess;

  switch (true) {
    case purchasable === true:
      return (
        <button
          id={`assessment-dl-btn-${assessmentRequestID}`}
          disabled={!canPurchaseReports}
          onClick={() => handlePurchaseButtonClick(type)}
          className="btn statusBtnBase statusGreen"
          title={!canPurchaseReports ? disabledPurchaseText : null}
          pointerEvents="auto"
        >
          <span className="btn-circle"></span>Purchase
        </button>
      );

    case reportRawType === assessmentTypes.CHS.name:
      return (
        <button
          id={`assessment-dl-btn-${assessmentRequestID}`}
          onClick={() => history.push(`/catalog/vendor/${vendorID}/CyberHygiene/Discovery`)}
          className="btn statusBtnBase statusBlue"
        >
          <span className="btn-circle"></span>View
        </button>
      );

    case isAvailable === true && hasHistoricalData:
      return (
        <button
          id={`assessment-dl-btn-${assessmentRequestID}`}
          onClick={() => {
            const downloadUrl =
              config.api.urlFor('posternProxyCMRE', { domain: vendorDomain }) +
              (reportDate ? `?date=${reportDate}` : '');
            window.open(downloadUrl, '_top');
          }}
          className="btn statusBtnBase statusOrange"
        >
          <span className="btn-circle"></span>Download
        </button>
      );

    case isAvailable === true &&
      (reportRawType === assessmentTypes.A2VQA.name ||
        reportTypeID == assessmentTypes.A2VQA.id ||
        reportRawType === assessmentTypes.A2VQA_S.name ||
        reportTypeID == assessmentTypes.A2VQA_S.id ||
        reportRawType === assessmentTypes.DDVA.name ||
        reportTypeID == assessmentTypes.DDVA.id ||
        reportRawType === assessmentTypes.DDVA_S.name ||
        reportTypeID == assessmentTypes.DDVA_S.id ||
        reportRawType === assessmentTypes.PAPR.name ||
        reportTypeID == assessmentTypes.PAPR.id):
      return (
        <button
          id={`assessment-dl-btn-${assessmentRequestID}`}
          onClick={() => {
            const downloadUrl = config.api.urlFor('assessmentByRequest', { assessmentRequestID: assessmentRequestID });
            window.open(downloadUrl, '_top');
          }}
          className="btn statusBtnBase statusOrange"
        >
          <span className="btn-circle"></span>Download
        </button>
      );

    case isAvailable === true &&
      assessmentTypes.SBOM.find((s) => s.name === reportRawType || s.id == reportTypeID) != null:
      return (
        <button
          id={`assessment-dl-btn-${assessmentRequestID}`}
          onClick={() => {
            if (productID) {
              props.events.emit(events.DOWNLOAD_FILE, {
                url: config.api.urlFor('posternProxySBOM', {
                  id: productID,
                  typeID: reportTypeID,
                  hash: assessmentData || '',
                }),
                filename: `${assessmentTypes.SBOM.find(
                  (s) => s.name === reportRawType || s.id == reportTypeID,
                ).name.replace(/ /g, '_')}-${new Date().toISOString().substring(0, 10)}.zip`,
              });
            }
          }}
          className="btn statusBtnBase statusOrange"
        >
          <span className="btn-circle"></span>Download
        </button>
      );

    case isAvailable === true &&
      (reportRawType === assessmentTypes.DDPA.name || reportTypeID == assessmentTypes.DDPA.id):
      return (
        <button
          id={`assessment-dl-btn-${assessmentRequestID}`}
          onClick={() => {
            props.events.emit(events.DOWNLOAD_FILE, {
              url: config.api.urlFor('posternDDPA', { name: productName }),
              filename: `DDPA_${productName.replace(/ /g, '_')}-${new Date().toISOString().substring(0, 10)}.pdf`,
            });
          }}
          className="btn statusBtnBase statusOrange"
        >
          <span className="btn-circle"></span>Download
        </button>
      );

    case isAvailable === true:
      return (
        <button
          id={`assessment-dl-btn-${assessmentRequestID}`}
          onClick={() =>
            history.push(
              vendorAssessmentEndpointHandler({ reportTypeID, reportRawType, vendorID, vendorName, vendorDomain }),
            )
          }
          className="btn statusBtnBase statusOrange"
        >
          <span className="btn-circle"></span>Download
        </button>
      );

    case isAvailable === false:
      return (
        <button id={`assessment-dl-btn-${assessmentRequestID}`} className="btn statusBtnBase statusGray">
          <span className="btn-circle"></span>In-Progress
        </button>
      );

    default:
      //There are hypothetical combinations of props that dont trigger a case thus causing the button to not render.
      return (
        <button id={`assessment-dl-btn-${assessmentRequestID}`} className="btn statusBtnBase statusError">
          <span className="btn-circle-hidden"></span>Error
        </button>
      );
  }
};

export default withEvents(StatusButton);
