import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const DropdownItem = ({ to, className, children }) => {
  return (
    <React.Fragment>
      <span
        className={'dropdown-toggle ' + className}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ cursor: 'pointer' }}
      >
        {children}
      </span>
      <div className="dropdown-menu dl-submenu">
        {to.map((link) => {
          if (Array.isArray(link.to)) {
            return (
              <NestedDropdownItem key={link.label} to={link.to}>
                <link.label />
              </NestedDropdownItem>
            );
          } else {
            return (
              <NavLink key={link.label} className="dropdown-item" to={link.to}>
                {link.label}
              </NavLink>
            );
          }
        })}
      </div>
    </React.Fragment>
  );
};

const NestedDropdownItem = ({ to, children }) => {
  let [active, setActive] = useState(false);
  return (
    <div className="dropright dl-submenu" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
      <button
        className={`d-flex justify-content-between ${active ? 'dropdown-item active show' : 'dropdown-item'}`}
        style={{ cursor: 'pointer' }}
      >
        {children} <i className="fas fa-caret-right pt-1"></i>
      </button>
      {active && (
        <div className={active ? 'dropdown-menu show' : 'dropdown-menu'}>
          {to.map((link) => (
            <NavLink key={link.label} className="dropdown-item" to={link.to}>
              {link.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

const NavLink = ({ to, className, children }) => (
  <Link className={className} to={to}>
    {children}
  </Link>
);

const NavItem = ({ to, label, active = false, onClick }) => {
  const linkClass = active ? 'nav-link active' : 'nav-link';

  const Link = to.map ? DropdownItem : NavLink;

  const StyledLink = styled(Link)`
    background-color: #004e71;
    margin-right: 1px;
    color: white;
    ${(props) => (props.active ? `border-color: #004E71 #004E71 #fff;` : '')}
  `;

  return (
    <li className="nav-item">
      <StyledLink className={linkClass} to={to} onClick={() => onClick()}>
        {label}
      </StyledLink>
    </li>
  );
};

export default NavItem;
