import React from 'react';
import { Link, withRouter } from 'react-router-dom';

class LinkToPath extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { path, params } = this.props;
    let replace = this.props.replace || {};

    let to = path || '';

    for (let prop in params) {
      replace[prop] = replace[prop] || params[prop];
    }

    for (let prop in replace) {
      to = to.replace(`:${prop}`, replace[prop]);
    }

    return (
      <Link
        to={to}
        location={`${this.props.location}${this.props.append}`}
        className={this.props.className}
        style={this.props.style}
      >
        {this.props.children}
      </Link>
    );
  }
}

export default withRouter(LinkToPath);
