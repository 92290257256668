import React from 'react';
import Busy from '../../busy';
import SectionContainer from '../../sectionContainer';
import SectionSummary from '../../sectionSummary';
import ForeignInfluenceMap from '../foreignInfluenceMap';
import SummaryTable from './summaryTable';

/** Functional component for displaying a summary of Foreign Influence data */
const ForeignInfluenceSummary = (props) => {
  //Props
  const { scores = {}, isLoading, isActive } = props;
  
  //Render
  return (
    <Busy isBusy={isLoading}>
      <SectionContainer label="Foreign Influence Summary" className="fi-section-container">
        {
          <div className="fi-summary">
            <h5>
              <strong>Foreign Influence Risk</strong>
            </h5>
            <SectionSummary
              scoresData={[
                { label: 'Cyber Presence', value: scores.cyberPresence },
                { label: 'Foreign Ownership', value: scores.foreignOwnership },
                { label: 'Physical Locations', value: scores.physicalPresence },
                { label: 'Mergers & Acquisitions', value: scores.mergerAcquisition },
                { label: 'Corporate Families', value: scores.corporateFamilies },
                { label: 'Manufacturing', value: scores.manufacturing },
              ]}
              componentContext="foreign-influence"
            />
            <SummaryTable summary={props.summary} />
          </div>
        }
      </SectionContainer>

      <SectionContainer label="Foreign Influence" className="fi-section-container fi-map">
        <ForeignInfluenceMap
          manufacturing={props.manufacturing}
          physicalPresence={props.physicalPresence}
          cyberPresence={props.cyberPresence}
          corporateFamilies={props.corporateFamilies}
          foreignOwnership={props.foreignOwnership}
          watchlist={props.watchlist}
          displayIfEmpty={!isLoading && isActive}
        />
      </SectionContainer>
    </Busy>
  );
};

export default ForeignInfluenceSummary;
