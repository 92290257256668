import React, { useEffect } from 'react';
import { A2VLOGOBLUE } from '../constants/color';
import ModalConfirmation from './modalConfirmation';
import { useUpdateState } from '../components';
import { toastError, toastSuccess } from '../lib/utils';
import withPrincipal from './withPrincipal';
import config from '../config';
import { AiFillCloseCircle } from 'react-icons/ai';
import Spinner from './spinner';

import '../routes/vendor/catalogVendor.css';
import './addRemoveMyVendorButton.css';

const AddRemoveMyVendorButton = (props) => {
  const [state, setState] = useUpdateState({
    modalShow: false,
    isBusy: false,
  });

  useEffect(() => {
    setState({
      myVendor: props.myVendor,
      vendorID: props.vendorID,
      vendorName: props.vendorName,
    });
  }, [props.myVendor, props.vendorID, props.vendorName]);

  const submitRequest = () => {
    setState({ isBusy: true });

    fetch(config.api.urlFor('catalog'), {
      method: myVendor ? 'DELETE' : 'POST',
      body: { vendorID },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.isSuccess) {
          setState({ myVendor: !myVendor });
          toastSuccess(
            `${vendorName ? vendorName : 'This vendor'} has been successfully ` +
              (myVendor ? 'removed from' : 'added to') +
              ' My Vendors.',
          );
        } else {
          toastError(
            `${vendorName ? vendorName : 'This vendor'} has been successfully ` +
              (myVendor ? 'removed from' : 'added to') +
              ' My Vendors.',
          );
        }
      })
      .catch(() => {
        toastError('Vendor has been unsuccessfully ' + (myVendor ? 'removed from' : 'added to') + ' My Vendors.');
      });

    setState({ modalShow: false, isBusy: false });
  };

  const closeModal = () => {
    setState({ modalShow: false, isBusy: false });
  };

  const { vendorID, vendorName, myVendor, modalShow, isBusy } = state;
  let buttonName = '',
    confirmationText = '',
    isDisabled = undefined,
    buttonStyle = {};

  const isLoadingMyVendor = myVendor === undefined;

  if (myVendor) {
    buttonName = 'Added to My Vendors';
    confirmationText = `Are you sure you want to remove ${vendorName ? vendorName : 'this vendor'} from My Vendors?`;
    isDisabled = true;
    buttonStyle = {
      backgroundColor: '#7f7f7f',
      pointerEvents: 'none',
    };
  } else {
    buttonName = 'Add to My Vendors';
    confirmationText = `Are you sure you want to add ${vendorName ? vendorName : 'this vendor'} to My Vendors?`;
    isDisabled = false;
    buttonStyle = {
      backgroundColor: A2VLOGOBLUE,
    };
  }

  return (
    <React.Fragment>
      <div className="add-remove-my-vendor-div">
        <button
          className="btn head-btn add-remove-my-vendor-btn"
          onClick={() => setState({ modalShow: true })}
          style={buttonStyle}
          disabled={isDisabled || isLoadingMyVendor}
        >
          {!isLoadingMyVendor && buttonName}
          <Spinner isVisible={isLoadingMyVendor}></Spinner>
        </button>
        {!!myVendor && (
          <AiFillCloseCircle
            color="red"
            cursor="pointer"
            className="remove-my-vendor-icon"
            title="Remove from My Vendors"
            onClick={() => setState({ modalShow: true })}
          />
        )}
      </div>

      <ModalConfirmation
        isBusy={isBusy}
        isOpen={modalShow}
        confirmationText={confirmationText}
        onClickYes={submitRequest}
        onClickNo={closeModal}
      />
    </React.Fragment>
  );
};

export default withPrincipal(AddRemoveMyVendorButton);
