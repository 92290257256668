import React, { useEffect } from 'react';
import { BoxShadow, Busy, Pagination, Spinner, useUpdateState } from '../../components/';
import { Tooltip } from 'reactstrap';
import {
  emailIsValid,
  nameIsValid,
  formatPhoneNumber,
  toastError,
  toastSuccess,
  toastInfo,
  uuidIsValid,
} from '../../lib/utils';
import ActionButton from '../../components/actionButton.js';

import './userDetails.css';

import config from '../../config';

const FormLabel = (props) => (
  <label {...props} className={`col-md-2 text-right`}>
    {props.children}
  </label>
);

const FormInput = (props) => <input {...props} className={`form-control ${props.className}`} />;

const UserDetails = (props) => {
  //Props
  const { history, match } = props;

  //State
  const [state, setState] = useUpdateState({
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    isSSO: undefined,
    title: '',
    company: '',
    phone: '',
    isBusy: true,
    isSaving: false,
    tab: 0,
    userRoles: [],
    popovers: {},
    errorFields: {
      firstName: false,
      lastName: false,
      phone: false,
      emailAddress: false,
    },
    touchedFields: {
      firstName: false,
      lastName: false,
      phone: false,
      emailAddress: false,
    },
    page: 1,
    totalUserRoles: 0,
  });

  const {
    id,
    firstName,
    lastName,
    emailAddress,
    isSSO,
    title,
    company,
    phone,
    isBusy,
    isSaving,
    tab,
    userRoles,
    popovers,
    errorFields,
    touchedFields,
    page,
    totalUserRoles,
  } = state;

  const disableSaveBtn = Object.keys(errorFields).some((el) => errorFields[el] === true);

  //Effects
  useEffect(() => {
    const id = match.params.id;
    if (id && uuidIsValid(id)) {
      getData(id);
    }
  }, [match.params.id]);

  //Helper Functions
  const getData = (id) => {
    setState({ isBusy: true });

    fetch(config.api.urlFor('userDetails', { id }))
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, data: user, message } = response;

        if (isSuccess) {
          setState({
            id: user.id,
            emailAddress: user.emailAddress,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            title: user.title,
            company: user.company,
            phone: user.phone,
            isSSO: user.isSSO,
            isBusy: false,
          });
        } else {
          toastError(message);
        }
      })
      .catch(() => toastError());

    setState({ isBusy: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({ isSaving: true });

    const user = {
      id,
      emailAddress,
      firstName,
      lastName,
      title: title && title.trim(),
      company: company && company.trim(),
      phone,
      isSSO,
    };

    const toastOptions = { toastId: 'save-user-result', autoClose: 5000 };

    toastInfo(
      <>
        Saving...
        <Spinner className="ml-1" isVisible={true} />
      </>,
      toastOptions,
    );

    fetch(config.api.urlFor('saveUser'), {
      method: 'POST',
      body: user,
    })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, data, message } = response;

        if (isSuccess && data && data.userID) {
          toastSuccess(`Saved user with ID: ${data.userID}.`, toastOptions);

          user.id !== data.userID && history.replace(match.path.replace(':id', data.userID));
        } else {
          toastError(message, toastOptions);
        }
      })
      .catch(() => {
        toastError('An error occurred attempting to save user.', toastOptions);
      });

    setState({ isSaving: false });
  };

  const getRoles = (page) => {
    page = page || 1;
    setState({ popovers: {}, page: page, isBusy: true });

    fetch(config.api.urlFor('userRoles', { id: match.params.id, page: page }))
      .then((response) => response.json())
      .then((userRoles) =>
        setState({ isBusy: false, userRoles: userRoles.rows, totalUserRoles: userRoles.totalRecords }),
      )
      .catch(() => setState({ isBusy: false }));
  };

  const grantRole = (roleID) => {
    setState({ isBusy: true });

    fetch(config.api.urlFor('grantRoles', { id: id }), {
      method: 'POST',
      body: { roleIDs: [roleID] },
    })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          getRoles();
        } else {
          toastError(message);
        }
      })
      .catch(() => toastError());

    setState({ isBusy: false });
  };

  const revokeRole = (roleID) => {
    setState({ isBusy: true });

    fetch(config.api.urlFor('revokeRoles', { id: id }), {
      method: 'POST',
      body: { roleIDs: [roleID] },
    })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          getRoles();
        } else {
          toastError(message);
        }
      })
      .catch(() => toastError());

    setState({ isBusy: false });
  };

  const togglePopover = (name) => {
    popovers[name] = popovers[name] === true ? false : true;

    setState({ popovers: popovers });
  };

  const handleInputBox = (e, keyName) => {
    let val = e.target.value;
    let hasError = false,
      isNameValid = true,
      isNameEmpty = false;

    if (keyName === 'firstName' || keyName === 'lastName') {
      if (!val.length) {
        isNameEmpty = true;
        hasError = true;
      } else if (!nameIsValid(val)) {
        isNameValid = false;
      }
    } else if (keyName === 'phone') {
      val = formatPhoneNumber(val);
      e.target.value = val;

      if (val.length > 1 && val.length !== 14) {
        hasError = true;
      }
    } else if (keyName === 'emailAddress') {
      if (val.length && !emailIsValid(val)) {
        hasError = true;
      }
    }

    if (isNameValid || isNameEmpty) {
      setState({
        [keyName]: val,
        errorFields: { ...errorFields, [keyName]: hasError },
      });
    }
  };

  const handleIsSSOchange = () => {
    setState({ isSSO: !isSSO });
  };

  const handleTabChange = (tab) => {
    setState({ tab });
  };

  const handleBlur = (e) => {
    setState({
      touchedFields: { ...touchedFields, [e.target.id]: true },
    });
  };

  const handleUserDelete = () => {
    history.push('/admin/users/emailAddress/asc/1');
  };

  //Render
  return (
    <div>
      <ul className="nav nav-tabs user-nav-tabs">
        <li className="nav-item">
          <button
            className={`btn btn-link nav-link user-nav-tab-btn ${tab === 0 ? 'active' : null}`}
            onClick={() => handleTabChange(0)}
          >
            Details
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`btn btn-link nav-link user-nav-tab-btn ${tab === 1 ? 'active' : null}`}
            onClick={() => getRoles() & handleTabChange(1)}
          >
            Roles
          </button>
        </li>
      </ul>
      <Busy
        isBusy={isBusy}
        content={
          <div className="user-details-tab-body">
            {tab === 0 && (
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="row px-3">
                    <div className="col-md-8 col-sm-9">
                      <div className="form-group">
                        <FormLabel id="id_label" htmlFor="firstName">
                          ID:
                        </FormLabel>
                        <strong>{id}</strong>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-3 text-right pr-0 mobile-save-btn">
                      <button
                        type="submit"
                        className="btn btn-outline-primary"
                        title="Save user"
                        disabled={isSaving === true || disableSaveBtn}
                      >
                        <i className="fa fa-save fa-lg fa-fw"></i>
                      </button>
                      <ActionButton userID={id} action="resetUser" />
                      <ActionButton userEmailAddress={emailAddress} action="resetUserPassword" />
                      <ActionButton userID={id} action="deleteUser" onAction={handleUserDelete} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <BoxShadow title="Credentials" className="mb-3">
                        <div className="form-group row mobile-padding">
                          <FormLabel htmlFor="emailAddress">email:</FormLabel>
                          <FormInput
                            id="emailAddress"
                            type="text"
                            className={
                              'col-md-5' + (errorFields.emailAddress && touchedFields.emailAddress ? ' is-invalid' : '')
                            }
                            onChange={(e) => handleInputBox(e, 'emailAddress')}
                            onBlur={handleBlur}
                            value={emailAddress}
                          />
                          {!!(errorFields.emailAddress && touchedFields.emailAddress) && (
                            <span className="m-error"> Please enter a valid email address </span>
                          )}
                        </div>
                        <div className="form-group row mobile-padding">
                          <FormLabel htmlFor="sso">Single Sign On Enabled:</FormLabel>
                          <div>
                            <input
                              id="sso"
                              type="checkbox"
                              className="user-details-sso-checkbox"
                              checked={isSSO}
                              onChange={handleIsSSOchange}
                            />
                            <p className="user-details-sso-checkbox-p">
                              Note: Users with Single Sign On enabled cannot login to A2V directly. Users without Single
                              Sign On can only login on A2V.
                            </p>
                          </div>
                        </div>
                      </BoxShadow>
                      <BoxShadow title="Profile" className="mb-2">
                        <div className="form-group row mobile-padding">
                          <FormLabel htmlFor="firstName">first:</FormLabel>
                          <FormInput
                            id="firstName"
                            type="text"
                            className={
                              'col-md-5' + (errorFields.firstName && touchedFields.firstName ? ' is-invalid' : '')
                            }
                            onChange={(e) => handleInputBox(e, 'firstName')}
                            onBlur={handleBlur}
                            value={firstName}
                          />
                          {!!(errorFields.firstName && touchedFields.firstName) && (
                            <span className="m-error"> Please enter a valid name </span>
                          )}
                        </div>
                        <div className="form-group row mobile-padding">
                          <FormLabel htmlFor="lastName">last:</FormLabel>
                          <FormInput
                            id="lastName"
                            type="text"
                            className={
                              'col-md-5' + (errorFields.lastName && touchedFields.lastName ? ' is-invalid' : '')
                            }
                            onChange={(e) => handleInputBox(e, 'lastName')}
                            onBlur={handleBlur}
                            value={lastName}
                          />
                          {!!(errorFields.lastName && touchedFields.lastName) && (
                            <span className="m-error"> Please enter a valid name </span>
                          )}
                        </div>
                        <div className="form-group row mobile-padding">
                          <FormLabel htmlFor="title">title:</FormLabel>
                          <FormInput
                            id="title"
                            type="text"
                            className="col-md-5"
                            onChange={(e) => handleInputBox(e, 'title')}
                            value={title}
                          />
                        </div>
                        <div className="form-group row mobile-padding">
                          <FormLabel htmlFor="company">company:</FormLabel>
                          <FormInput
                            id="company"
                            type="text"
                            className="col-md-5"
                            onChange={(e) => handleInputBox(e, 'company')}
                            value={company}
                          />
                        </div>
                        <div className="form-group row mobile-padding">
                          <FormLabel htmlFor="phone">phone:</FormLabel>
                          <FormInput
                            id="phone"
                            type="text"
                            className={'col-md-5' + (errorFields.phone && touchedFields.phone ? ' is-invalid' : '')}
                            onChange={(e) => handleInputBox(e, 'phone')}
                            onBlur={handleBlur}
                            value={phone}
                          />
                          {!!(errorFields.phone && touchedFields.phone) && (
                            <span className="m-error"> Please enter a valid phone number </span>
                          )}
                        </div>
                      </BoxShadow>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {tab === 1 && (
              <div className="table-responsive-sm">
                <table className="table table-striped user-details-roles-table ">
                  <thead>
                    <tr>
                      <td>name</td>
                      <td>system?</td>
                      <td>granted?</td>
                      <td>action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {userRoles.map((role, i) => (
                      <tr key={`role-revoke-${i}`}>
                        <td>{role.nameUI}</td>
                        <td>
                          {role.isSystem ? (
                            <i className="far fa-check-square fa-lg"></i>
                          ) : (
                            <i className="far fa-square"></i>
                          )}
                        </td>
                        <td>
                          <i className={`far fa-${role.granted ? 'check-' : ''}square fa-lg`}></i>
                        </td>
                        {role.granted ? (
                          <td onClick={() => revokeRole(role.id)}>
                            <i
                              id={`role-revoke-${i}`}
                              className="fa fa-user-times fa-lg user-details-roles-revoke-icon"
                            ></i>
                            <Tooltip
                              placement="right"
                              isOpen={popovers[`role-revoke-${i}`]}
                              target={`role-revoke-${i}`}
                              toggle={() => togglePopover(`role-revoke-${i}`)}
                            >
                              REVOKE: {role.name}
                            </Tooltip>
                          </td>
                        ) : (
                          <td onClick={() => grantRole(role.id)}>
                            <i
                              id={`role-grant-${i}`}
                              className="fa fa-user-plus fa-lg user-details-roles-grant-icon"
                            ></i>
                            <Tooltip
                              placement="right"
                              isOpen={popovers[`role-grant-${i}`]}
                              target={`role-grant-${i}`}
                              toggle={() => togglePopover(`role-grant-${i}`)}
                            >
                              GRANT: {role.name}
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  onPageChange={(page) => getRoles(page)}
                  page={page}
                  pageSize={10}
                  totalRecords={totalUserRoles}
                  visible={7}
                />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default UserDetails;
