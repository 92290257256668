import React, { Component } from 'react';

import config from './../../../../config';

// Layouts and reusables
import Section from '../../reusable/section';
import SeverityPill from '../../reusable/severityPill';
import HorizontalLayout from '../../layouts/horizontalLayout';
import PrintCoverPageHorizontal from '../../layouts/printCoverPageHorizontal';
import Busy from '../../../../components/busy';
import FetchError from '../../reusable/fetchError';

// section imports
import PrintCoverPage from './components/printCoverPage';
import VendorOverview from './components/vendorOverview.jsx';
import Manufacturing from './components/manufacturing.jsx';
import AppendixCompliance from './components/appendixCompliance.jsx';
import AppendixCyberHygiene from './components/appendixCyberHygiene';
import AppendixVulnSolution from './components/appendixVulnSolution';
import AppendixWatchlist from './components/appendixWatchlist';
import Methodology from './components/methodology.jsx';
import MergerAcquisition from './components/mergerAcquisition';
import PhysicalPresence from './components/physicalPresence';
import CyberPresence from './components/cyberPresence';
import CyberHygiene from './components/cyberHygiene';
import ForeignOwnership from './components/foreignOwnership';
import VulnSolution from './components/vulnSolution';
import CorporateFamilies from './components/corporateFamilies';
import Breaches from './components/breaches';
import Compliance from './components/compliance';
import Watchlist from './components/watchlist';
import SupplierGeopoliticalAssociationsOverview from './components/supplierGeopoliticalAssociationsOverview';

import './provenance.css';

export default class Provenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: true,
      fetchError: false,
    };
  }

  componentDidMount() {
    const { query } = this.props;
    const { vendorID, template } = query;
    let url = '';
    let urlHasQueryParam = false;
    url = `${config.api.urlFor('posternProvenance', { vendorID })}`;
    if (template) {
      switch (template) {
        case 'cmrs':
          url += urlHasQueryParam ? '&template=cmrs' : '?template=cmrs';
          break;
        default:
          break;
      }
    }
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ data: data, isBusy: false });
      })
      .catch(() => {
        this.setState({ fetchError: true });
      });
  }

  render() {
    const { fetchError, isBusy } = this.state;
    const data = this.state.data || {};
    const {
      vendor = {},
      physicalPresence = [],
      cyberPresence = [],
      corporateFamilies = [],
      foreignOwnership = [],
      watchlist = [],
      mergerAcquisition = [],
      breaches = [],
      compliance = {},
      cyberHygiene = {},
      cyberHygiene_details = [],
      vulnSolution = [],
      vulnSolutionAppendix = [],
      manufacturing = [],
      watchlistDesc = [],
      cyberHygiene_transport = {},
      cyberPresenceAggregationCount = [],
      cyberHygieneSummary = {},
      scores = {},
      summary = {},
      riskSections = [],
      template = '',
    } = data;

    const {
      watchlist: scoreWatchlist = '',
      cyberPresence: scoreCyberPresence = '',
      physicalPresence: scorePhysicalPresence = '',
      corporateFamilies: scoreCorporateFamilies = '',
      foreignOwnership: scoreForeignOwnership = '',
      mergerAcquisition: scoreMergerAcquisition = '',
      manufacturing: scoreManufacturing = '',
      breaches: scoreBreaches = '',
      compliance: scoreCompliance = '',
      cyberHygiene: scoreCyberHygiene = '',
      vulnSolution: scoreVulnSolution = '',
    } = scores;

    const noAppendixForNav =
      !cyberHygiene_details.length > 0 && !vulnSolutionAppendix.length > 0 && !watchlistDesc.length !== 0;

    const navOptions =
      template === 'cmrs'
        ? [
            {
              name: 'Mergers and Acquisitions',
              excludePrefix: true,
              idToScrollTo: 'provenance-merger-acquisition',
            },
            {
              name: 'Cyber Presence',
              excludePrefix: true,
              idToScrollTo: 'provenance-cyber-presence',
            },
            {
              name: 'Cyber Hygiene',
              excludePrefix: true,
              idToScrollTo: 'provenance-cyber-hygiene',
            },
            {
              name: 'Vulnerability Solution',
              excludePrefix: true,
              idToScrollTo: 'provenance-vuln-solution',
            },
            {
              name: 'Breaches',
              excludePrefix: true,
              idToScrollTo: 'provenance-breaches',
            },
            {
              name: noAppendixForNav ? '' : 'Appendix',
              excludePrefix: true,
              idToScrollTo:
                cyberHygiene_details.length > 0
                  ? 'provenance-appendix-cyber-hygiene'
                  : vulnSolutionAppendix.length > 0
                  ? 'provenance-appendix-vuln-solution'
                  : watchlistDesc.length !== 0
                  ? 'provenance-appendix-watchlist'
                  : '',
            },
          ]
        : [
            {
              name: 'SGA Overview',
              excludePrefix: true,
              idToScrollTo: 'provenance-sga-overview',
            },
            {
              name: 'Manufacturing',
              excludePrefix: true,
              idToScrollTo: 'provenance-manufacturing',
            },
            {
              name: 'Foreign Ownership',
              excludePrefix: true,
              idToScrollTo: 'provenance-foreign-ownership',
            },
            {
              name: 'Mergers and Acquisitions',
              excludePrefix: true,
              idToScrollTo: 'provenance-merger-acquisition',
            },
            {
              name: 'Physical Presence',
              excludePrefix: true,
              idToScrollTo: 'provenance-physical-presence',
            },
            {
              name: 'Corporate Families',
              excludePrefix: true,
              idToScrollTo: 'provenance-corporate-families',
            },
            {
              name: 'Cyber Presence',
              excludePrefix: true,
              idToScrollTo: 'provenance-cyber-presence',
            },
            {
              name: 'Watchlist',
              excludePrefix: true,
              idToScrollTo: 'provenance-watchlist',
            },
            {
              name: 'Cyber Hygiene',
              excludePrefix: true,
              idToScrollTo: 'provenance-cyber-hygiene',
            },
            {
              name: 'Vulnerability Solution',
              excludePrefix: true,
              idToScrollTo: 'provenance-vuln-solution',
            },
            {
              name: 'Breaches',
              excludePrefix: true,
              idToScrollTo: 'provenance-breaches',
            },
            {
              name: 'Compliance',
              excludePrefix: true,
              idToScrollTo: 'provenance-compliance',
            },
            {
              name: 'Appendix',
              excludePrefix: true,
              idToScrollTo: 'provenance-appendix-compliance',
            },
            {
              name: 'Methodology',
              excludePrefix: true,
              idToScrollTo: 'provenance-methodology',
            },
          ];

    return (
      <>
        {fetchError === true ? (
          <FetchError
          // TODO: props can go here to determine type of error
          />
        ) : (
          <Busy isBusy={isBusy}>
            <div id="postern-provenance">
              <HorizontalLayout navOptions={navOptions}>
                <PrintCoverPageHorizontal navOptions={navOptions}>
                  <PrintCoverPage vendor={vendor} scores={scores} compliance={compliance} template={template} />
                </PrintCoverPageHorizontal>

                <div className="print-hide">
                  <VendorOverview vendor={vendor} scores={scores} compliance={compliance} template={template} />
                </div>

                {template === 'cmrs' ? null : (
                  <Section
                    sectionID="provenance-sga-overview"
                    sectionName="Supplier Geopolitical Associations Overview"
                  >
                    <SupplierGeopoliticalAssociationsOverview
                      manufacturing={manufacturing}
                      physicalPresence={physicalPresence}
                      cyberPresence={cyberPresence}
                      corporateFamilies={corporateFamilies}
                      foreignOwnership={foreignOwnership}
                      watchlist={watchlist}
                      summary={summary}
                      riskSections={riskSections}
                    />
                  </Section>
                )}

                {template === 'cmrs' ? null : (
                  <Section
                    sectionID="provenance-manufacturing"
                    sectionName={<SeverityPill severity={scoreManufacturing}> Manufacturing</SeverityPill>}
                  >
                    <Manufacturing scores={scores} manufacturing={manufacturing} />
                  </Section>
                )}

                {template === 'cmrs' ? null : (
                  <Section
                    sectionID="provenance-foreign-ownership"
                    sectionName={<SeverityPill severity={scoreForeignOwnership}> Foreign Ownership</SeverityPill>}
                  >
                    <ForeignOwnership scores={scores} foreignOwnership={foreignOwnership} />
                  </Section>
                )}

                <Section
                  sectionID="provenance-merger-acquisition"
                  sectionName={<SeverityPill severity={scoreMergerAcquisition}> Mergers and Acquisitions</SeverityPill>}
                >
                  <MergerAcquisition scores={scores} mergerAcquisition={mergerAcquisition} template={template} />
                </Section>

                {template === 'cmrs' ? null : (
                  <Section
                    sectionID="provenance-physical-presence"
                    sectionName={<SeverityPill severity={scorePhysicalPresence}> Physical Presence</SeverityPill>}
                  >
                    <PhysicalPresence scores={scores} physicalPresence={physicalPresence} />
                  </Section>
                )}

                {template === 'cmrs' ? null : (
                  <Section
                    sectionID="provenance-corporate-families"
                    sectionName={<SeverityPill severity={scoreCorporateFamilies}> Corporate Families</SeverityPill>}
                  >
                    <CorporateFamilies scores={scores} corporateFamilies={corporateFamilies} />
                  </Section>
                )}

                <Section
                  sectionID="provenance-cyber-presence"
                  sectionName={<SeverityPill severity={scoreCyberPresence}> Cyber Presence</SeverityPill>}
                >
                  <CyberPresence
                    scores={scores}
                    cyberPresence={cyberPresence}
                    vendor={vendor}
                    cyberPresenceAggregationCount={cyberPresenceAggregationCount}
                  />
                </Section>

                {template === 'cmrs' ? null : (
                  <Section
                    sectionID="provenance-watchlist"
                    sectionName={<SeverityPill severity={scoreWatchlist}> Watchlist</SeverityPill>}
                  >
                    <Watchlist watchlist={watchlist} />
                  </Section>
                )}

                <Section
                  sectionID="provenance-cyber-hygiene"
                  sectionName={<SeverityPill severity={scoreCyberHygiene}> Cyber Hygiene</SeverityPill>}
                >
                  <CyberHygiene
                    cyberHygiene={cyberHygiene}
                    cyberHygiene_transport={cyberHygiene_transport}
                    cyberHygieneSummary={cyberHygieneSummary}
                  />
                </Section>

                <Section
                  sectionID="provenance-vuln-solution"
                  sectionName={<SeverityPill severity={scoreVulnSolution}> Vulnerability Solution</SeverityPill>}
                >
                  <VulnSolution scores={scores} vulnSolution={vulnSolution} />
                </Section>

                <Section
                  sectionID="provenance-breaches"
                  sectionName={<SeverityPill severity={scoreBreaches}> Breaches</SeverityPill>}
                >
                  <Breaches breaches={breaches} template={template} />
                </Section>

                {template === 'cmrs' ? null : (
                  <Section
                    sectionID="provenance-compliance"
                    sectionName={<SeverityPill severity={scoreCompliance}> Compliance</SeverityPill>}
                  >
                    <Compliance compliance={compliance} />
                  </Section>
                )}

                {template === 'cmrs' ? null : (
                  <Section sectionID="provenance-appendix-compliance" sectionName="Appendix: Compliance">
                    <AppendixCompliance compliance={compliance} />
                  </Section>
                )}

                {/* the conditional logic for additional appendix sections */}
                {Object.keys(cyberHygiene).length > 0 ? (
                  <>
                    {cyberHygiene_details.length > 0 ? (
                      <Section sectionID="provenance-appendix-cyber-hygiene" sectionName="Appendix: Cyber Hygiene">
                        <AppendixCyberHygiene
                          cyberHygiene_details={cyberHygiene_details}
                          cyberHygieneSummary={cyberHygieneSummary}
                        />
                      </Section>
                    ) : null}
                    {vulnSolutionAppendix.length > 0 ? (
                      <Section
                        sectionID="provenance-appendix-vuln-solution"
                        sectionName="Appendix: Vulnerabilty Solution"
                      >
                        <AppendixVulnSolution vulnSolutionAppendix={vulnSolutionAppendix} />
                      </Section>
                    ) : null}
                  </>
                ) : null}

                {template === 'cmrs' || watchlistDesc.length === 0 ? null : (
                  <Section sectionID="provenance-appendix-watchlist" sectionName="Appendix: Watchlist">
                    <AppendixWatchlist watchlistDesc={watchlistDesc} />
                  </Section>
                )}

                {template === 'cmrs' ? null : (
                  <Section sectionID="provenance-methodology" sectionName="Methodology">
                    <Methodology />
                  </Section>
                )}
              </HorizontalLayout>
            </div>
          </Busy>
        )}
      </>
    );
  }
}
