import React, { Component } from 'react';

import './tileComponent.css';

export default class TileComponent extends Component {
  render() {
    const { title = '', highlight = '', footer = '', color = '#ed5565' } = this.props; //color="#1ab394" - green
    return (
      <React.Fragment>
        <div className={'provenance-tileBody tile-footer'} style={{ backgroundColor: color }}>
          <b className="provenance-titleTile">{title}</b>
          <p className="provenance-highlightedTileSection">{highlight}</p>
          {footer}
        </div>
      </React.Fragment>
    );
  }
}
