import React, { Component } from 'react';
import config from '../../config';
import { RemoteTable, CardRow } from '../../components/riskRank';

class RiskRank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: {
        limit: 10,
        offset: 0,
        total: 0,
        vendors: [],
      },
      stats: [],
      isBusy: true,
    };
  }

  componentDidMount() {
    this.doDdrr();
  }

  doDdrr() {
    const params = new URLSearchParams();
    // TODO: get user's ID
    params.set('id', 1);
    params.set('includeContainerStats', true);

    const url = config.api.urlFor('ddrrVendor', { offset: this.state.results.offset, limit: this.state.results.limit });

    fetch(url, {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          results: data.results,
          stats: data.stats,
          isBusy: false,
        }),
      );
  }

  onChange = (type, { page, sizePerPage }) => {
    this.setState({ isBusy: true });

    const params = new URLSearchParams();
    // TODO: get user's ID
    params.set('id', 1);
    params.set('includeContainerStats', true);

    const offset = (page - 1) * sizePerPage;

    const url = config.api.urlFor('ddrrVendor', { offset: offset, limit: sizePerPage });

    fetch(url, {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          results: data.results,
          isBusy: false,
        }),
      );
    // TODO: handle change, implement server side pagination
    // this.setState({isBusy: true})
    // update table
  };

  render() {
    const {
      isBusy,
      results: { limit, offset, total, vendors },
      stats,
    } = this.state;

    return (
      <div>
        <CardRow stats={stats} isBusy={isBusy} />
        <RemoteTable
          data={vendors}
          page={parseInt(limit) < 1 ? 0 : parseInt(offset) / parseInt(limit) + 1}
          totalSize={parseInt(total)}
          sizePerPage={parseInt(limit) < 1 ? 10 : parseInt(limit)}
          isBusy={isBusy}
          onTableChange={this.onChange}
        />
      </div>
    );
  }
}
export default RiskRank;
