import React from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';

import InvitationList from './invitationList';
import InvitationDetails from './invitationDetails';

export const Invitations = () => {
  const match = useRouteMatch();

  return (
    <React.Fragment>
      <Route exact path={`${match.url}`} component={() => <Redirect to={`${match.url}/list/1`} />} />
      <Route exact path={`${match.url}/list/:page`} component={InvitationList} />
      <Route exact path={`${match.url}/:id`} component={InvitationDetails} />
    </React.Fragment>
  );
};

export default Invitations;
