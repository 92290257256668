import React from 'react';
import './newItemCard.css';

const NewItemCard = (props) => {
  const { caption = 'Add Item', name, onClickHandler = () => {}, isWide, isClickable = true } = props;

  return (
    <div
      id={`new-item-card-${caption.replace(/ /g, '-').toLowerCase()}`}
      onClick={onClickHandler}
      className={
        (!!isWide ? 'new-item-card-wide' : 'new-item-card') + (!!isClickable ? '' : ' new-item-card-not-clickable')
      }
    >
      <div className="new-item-card-caption">{caption}</div>
      <div className="css-plus-container">
        <div>+</div>
      </div>
      <div className="new-item-card-text-name">{name}</div>
    </div>
  );
};

export default NewItemCard;
