import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SortCaret, HeaderFormatter, OrgLinkFormatter } from '.';
import * as Constants from '../../constants';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';

const RemoteTable = ({ data, page, sizePerPage, onTableChange, totalSize, isBusy }) => {
  const igSelectOptions = {};
  const ddrlSelectOption = {};

  data &&
    data.map &&
    data.forEach((test) => {
      if (!ddrlSelectOption.hasOwnProperty(test.ddrl)) {
        ddrlSelectOption[test.ddrl] = test.ddrl;
      }
      if (!igSelectOptions.hasOwnProperty(test.industryGroup)) {
        igSelectOptions[test.industryGroup] = test.industryGroup;
      }
    });

  ddrlSelectOption['null'] = 'Incomplete';

  const ddrlFormatter = (cell, row) => {
    const cellColor =
      cell === 'Critical'
        ? Constants.CRITITCALRED
        : cell === 'High'
        ? Constants.HIGHORANGE
        : cell === 'Medium'
        ? Constants.MODERATEYELLOW
        : cell === 'Low'
        ? Constants.LOWGREEN
        : cell === 'Moderate'
        ? Constants.MARGINALBLUE
        : '#999';
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-5" style={{ padding: 0 }}>
            <div className="progress" style={{ height: '22px' }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: 14,
                  backgroundColor: `${cellColor}`,
                  minWidth: '4em',
                }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {ddrlSelectOption[cell]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Id',
      sort: true,
      sortCaret: SortCaret,
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: 'organization',
      text: 'Organization',
      formatter: OrgLinkFormatter,
      sort: true,
      sortCaret: SortCaret,
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: 'industryGroup',
      text: 'Industry Grouping',
      formatter: (cell) => igSelectOptions[cell],
      filter: selectFilter({
        options: igSelectOptions,
      }),
      sort: true,
      sortCaret: SortCaret,
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: 'revenueCategory',
      text: 'Revenue Category',
      sort: true,
      sortCaret: SortCaret,
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: 'ddrl',
      text: 'Data Driven Risk Level',
      formatter: ddrlFormatter,
      filter: selectFilter({
        options: ddrlSelectOption,
      }),
      sort: true,
      sortCaret: SortCaret,
      headerFormatter: HeaderFormatter,
    },
  ];

  return (
    <div>
      <BootstrapTable
        remote
        keyField="id"
        data={data}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        loading={isBusy}
      />
    </div>
  );
};

export default RemoteTable;
