import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { Busy, Pagination, useUpdateState, useQuery, withPrincipal, useSignalEffect, debounce } from '../../components';
import ProductCard from '../../components/vendor/catalog/productCard';
import { pageIsValid, toastError } from '../../lib/utils';
import config from '../../config';
import CatalogViewSwitch from './catalogViewSwitch';
import NewItemCard from '../../components/newItemCard';
import ProductModalStagesContainer from '../../components/product/productModalStagesContainer';
import ReactTooltip from 'react-tooltip';
import { assessmentTypes } from '../../constants/assessmentTypes';

import './catalog.css';

const getProductVendors = async ({ search, signal }) => {
  const res = await fetch(config.api.urlFor('productVendors', { search, pageSize: 50 }), { signal });

  return await res.json();
};

const getProductLicenses = async ({ search, signal }) => {
  const res = await fetch(config.api.urlFor('productLicenses', { search, pageSize: 50 }), { signal });

  return await res.json();
};

const getProductCVEs = async ({ search, signal }) => {
  const res = await fetch(config.api.urlFor('productCVEs', { search, pageSize: 50 }), { signal });

  return await res.json();
};

const getProductComponents = async ({ search, signal }) => {
  const res = await fetch(config.api.urlFor('productComponents', { search, pageSize: 50 }), { signal });

  return await res.json();
};

const CatalogProducts = (props) => {
  //Refs
  const getProductsController = useRef(null);
  const filterOptionsRef = useRef(null);

  //Props
  const { principal } = props;
  const { page: pPage, orderBy, dir, pageSize = 20 } = useParams();
  const { search = '', reports = '', hasSBOM, hasMalware, vendor, license, cve, component } = useQuery();
  const page = !Number.isNaN(pPage) ? +pPage : 1;
  const canAddProducts = principal.roles.some((r) => r.permissions.some((p) => p === 'manageProducts'));
  const canPurchaseReports = principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.purchaseReports'));

  //State
  const [state, setState] = useUpdateState({
    vendorID: '',
    isBusy: true,
    searchInput: search,
    products: [],
    totalRecords: 0,
    isAvailableReportsLoading: false,
    availableReportsList: [],
    filteredAvailableReportsList: [],
    selectedAvailableReportsList: [],
    modalStage: '',
    showModal: false,
    showAdvancedSearch: false,
  });

  const {
    isBusy,
    searchInput,
    products,
    totalRecords,
    isAvailableReportsLoading,
    availableReportsList,
    filteredAvailableReportsList,
    selectedAvailableReportsList,
    modalStage,
    showModal,
    vendorSearch = vendor,
    productVendors,
    licenseSearch = license,
    productLicenses,
    cveSearch = cve,
    productCVEs,
    componentSearch = component,
    productComponents,
    hasSBOMSearch = !!hasSBOM,
    hasMalwareSearch = !!hasMalware,
  } = state;

  /* ----------------------------------- */
  /*          Life Cycle Methods         */
  /* ----------------------------------- */
  useEffect(() => {
    getAvailableReports();

    return () => {
      if (getProductsController.current) {
        getProductsController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    const reportsList = !reports ? [] : reports.split(',');

    setState({
      isBusy: true,
      selectedAvailableReportsList: reportsList,
    });

    const asyncGetProducts = async (reports, page, orderBy, dir, search, pageSize) => {
      const asc = dir === 'desc' ? 0 : 1;

      const reportsEncoded = JSON.stringify(reports);

      const searchEncoded = JSON.stringify(search);

      const { data, controller } = await getProducts(
        reportsEncoded,
        page,
        orderBy,
        asc,
        searchEncoded,
        pageSize,
        hasSBOM,
        hasMalware,
        vendor,
        license,
        cve,
        component,
      );

      handleProductsResults(data, controller);
    };

    asyncGetProducts(reportsList, page, orderBy, dir, search, pageSize);
  }, [page, orderBy, dir, search, reports, hasSBOM, hasMalware, vendor, license, cve, component]);

  useEffect(() => {
    if (!reports) {
      setState({ selectedAvailableReportsList: [] });
    }
  }, [reports]);

  useEffect(() => {
    handlePageChange(page);
  }, [page]);

  useEffect(() => {
    handleReloadData();
  }, [hasSBOMSearch, hasMalwareSearch, vendorSearch, licenseSearch, cveSearch, componentSearch]);

  useSignalEffect(
    async ({ signal }) => {
      const productVendors = (
        await getProductVendors({
          search: vendorSearch ? vendorSearch : undefined,
          signal,
        })
      ).filter((v) => v.name);

      setState({ productVendors });
    },
    [vendorSearch],
  );

  useSignalEffect(
    async ({ signal }) => {
      const productLicenses = (
        await getProductLicenses({
          search: licenseSearch ? licenseSearch : undefined,
          signal,
        })
      ).filter((l) => l.license);

      setState({ productLicenses });
    },
    [licenseSearch],
  );

  useSignalEffect(
    async ({ signal }) => {
      const productCVEs = (
        await getProductCVEs({
          search: cveSearch ? cveSearch : undefined,
          signal,
        })
      ).filter((c) => c.cve);

      setState({ productCVEs });
    },
    [cveSearch],
  );

  useSignalEffect(
    async ({ signal }) => {
      const productComponents = (
        await getProductComponents({
          search: componentSearch ? componentSearch : undefined,
          signal,
        })
      ).filter((c) => c.purl);

      setState({ productComponents });
    },
    [componentSearch],
  );

  /* ----------------------------------- */
  /*          API Calls                  */
  /* ----------------------------------- */

  const updateURL = async ({
    page: argPage,
    orderBy: argOrderBy,
    dir: argDir,
    search: argSearch,
    reports: argReports,
    hasSBOM: argHasSBOM,
    hasMalware: argHasMalware,
    vendor: argVendor,
    license: argLicense,
    cve: argCVE,
    component: argComponent,
  } = {}) => {
    argPage = argPage || page || 1;
    argOrderBy = argOrderBy || orderBy || 'name';
    argDir = argDir || dir || 'asc';
    argSearch = argSearch !== undefined ? argSearch : search;
    argReports = argReports !== undefined ? (argReports.length ? argReports.join(',') : null) : reports;
    argHasSBOM = argHasSBOM !== undefined ? argHasSBOM : hasSBOM;
    argHasMalware = argHasMalware !== undefined ? argHasMalware : hasMalware;
    argVendor = argVendor !== undefined ? argVendor : vendor;
    argLicense = argLicense !== undefined ? argLicense : license;
    argCVE = argCVE !== undefined ? argCVE : cve;
    argComponent = argComponent !== undefined ? argComponent : component;

    const params = new URLSearchParams();

    let pathname = '/catalog/products/' + argOrderBy + '/' + argDir + '/' + argPage; //TODO: Have order by something that actually changes - same goes for vendor search

    argSearch && params.append('search', argSearch);
    argReports ? params.append('reports', argReports) : setState({ selectedAvailableReportsList: [] });
    argHasSBOM && params.append('hasSBOM', argHasSBOM);
    argHasMalware && params.append('hasMalware', argHasMalware);
    argVendor && params.append('vendor', argVendor);
    argLicense && params.append('license', argLicense);
    argCVE && params.append('cve', argCVE);
    argComponent && params.append('component', argComponent);
    const queryString = params.toString();

    const url = `${pathname}${queryString ? `?${queryString}` : ''}`;

    props.history.push(url);
  };

  const getProducts = async (
    reportTypesRaw,
    page,
    orderBy,
    asc,
    search,
    pageSize,
    hasSBOM,
    hasMalware,
    vendor,
    license,
    cve,
    component,
  ) => {
    const controller = new AbortController();

    if (getProductsController.current) {
      getProductsController.current.abort();
    }
    getProductsController.current = controller;

    const response = await fetch(
      config.api.urlFor('catalogProducts', {
        page,
        pageSize,
        asc,
        orderBy,
        search,
        reportTypesRaw,
        hasSBOM,
        hasMalware,
        vendor,
        license,
        cve,
        component,
      }),
      { signal: controller.signal },
    );

    const data = await response.json();

    return { data, controller };
  };

  const handleProductsResults = (response, controller) => {
    if (controller && controller.signal && !controller.signal.aborted) {
      const { isSuccess = false, data = {} } = response;
      if (isSuccess) {
        setState({
          isBusy: false,
          products: data.rows || [],
          totalRecords: data.totalRecords || 0,
        });
      } else {
        toastError('An error occurred while searching products.');
        setState({
          isBusy: false,
          products: [],
          totalRecords: 0,
        });
      }
    }
  };
  /* ----------------------------------- */
  /*          Handle Inputs              */
  /* ----------------------------------- */

  const handleCardClick = (event, row) => {
    if (row.productID) {
      props.history.push(`/catalog/product/${row.productID}`);
    }
  };

  const handleReloadData = (e) => {
    e && e.preventDefault();

    updateURL({
      page: 1,
      search: searchInput,
      hasSBOM: hasSBOMSearch,
      hasMalware: hasMalwareSearch,
      vendor: vendorSearch,
      license: licenseSearch,
      cve: cveSearch,
      component: componentSearch,
    });
  };

  const handlePageChange = (page = 1) => {
    const updatedPage = pageIsValid(page, totalRecords, pageSize) ? page : 1;
    updateURL({ page: updatedPage, search: searchInput });
  };

  /* ----------------------------------- */
  /*          Helping Methods            */
  /* ----------------------------------- */

  const handleOrderOtherReports = () => {
    if (canPurchaseReports) {
      setState({ showModal: true, modalStage: 'reports' });
    }
  };

  const handleModalClose = () => {
    setState({
      showModal: false,
      modalStage: '',
    });
  };

  const loadProductVendors = debounce(async (search, cb) => {
    const vendors = await getProductVendors({ search });

    cb && cb(vendors);
  });

  const loadProductLicenses = debounce(async (search, cb) => {
    const licenses = await getProductLicenses({ search });

    cb && cb(licenses);
  });

  const loadProductCVEs = debounce(async (search, cb) => {
    const cves = await getProductCVEs({ search });

    cb && cb(cves);
  });

  const loadProductComponents = debounce(async (search, cb) => {
    const components = await getProductComponents({ search });

    cb && cb(components);
  });

  const getAvailableReports = () => {
    setState({ isAvailableReportsLoading: true });

    fetch(config.api.urlFor('catalogReportTypes', { shouldHaveSBOMfeature: false }))
      .then((res) => res.json())
      .then((data) => {
        let availableReportsList = [];
        if (data && data.rows) {
          availableReportsList = data.rows
            .filter((row) => row.abbreviation === assessmentTypes.SBOM[0].abbreviation)
            .map((row) => {
              return {
                ...row,
                isAvailableImmediately: row.id === assessmentTypes.SBOM[0].id || row.id === assessmentTypes.SBOM[2].id,
              };
            });
        }
        setState({
          isAvailableReportsLoading: false,
          availableReportsList,
          filteredAvailableReportsList: availableReportsList,
        });
      })
      .catch(() => {
        toastError('An error occurred attempting to load available reports.');
        setState({
          isAvailableReportsLoading: false,
          availableReportsList: [],
          filteredAvailableReportsList: [],
        });
      });
  };

  const updateFilteredLists = (selectedReportsList) => {
    const searchText =
      (filterOptionsRef &&
        filterOptionsRef.current &&
        filterOptionsRef.current.value &&
        filterOptionsRef.current.value.toLowerCase()) ||
      '';
    const updatedAvailableReportsList = availableReportsList.filter((row) => {
      return (
        row.name.toString().toLowerCase().includes(searchText) ||
        (Array.isArray(selectedReportsList) ? selectedReportsList : selectedAvailableReportsList).some(
          (selectedReport) => selectedReport === row.rawType,
        )
      );
    });

    setState({
      filteredAvailableReportsList: updatedAvailableReportsList,
    });
  };

  const handleCheckboxFilter = (id) => {
    let updatedList = selectedAvailableReportsList;
    if (updatedList.indexOf(id) < 0) {
      updatedList.push(id);
    } else {
      updatedList.splice(updatedList.indexOf(id), 1);
      if (filterOptionsRef && filterOptionsRef.current && filterOptionsRef.current.value) {
        updateFilteredLists(updatedList);
      }
    }

    setState({ selectedAvailableReportsList: updatedList });
    updateURL({ page: 1, reports: updatedList, search: searchInput });
  };

  /* ----------------------------------- */
  /*          Render Logic               */
  /* ----------------------------------- */

  return (
    <div>
      {/* Search Area */}
      <div className="catalog-page">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="catalog-nav">
                <div className="search-title-area">
                  <h3>Network: Products &amp; Reports</h3>
                  <p className="catalog-description">Order risk reports on products</p>
                </div>
                <CatalogViewSwitch isVendorView={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="catalog-content">
          <div className="catalog-sidebar-wrapper">
            <div className="catalog-sidebar">
              <form className="form-inline">
                <div className="input-group mb-3">
                  <input
                    className="form-control catalog-search-input"
                    type="text"
                    value={searchInput}
                    placeholder="Search Products"
                    onChange={(e) => setState({ searchInput: e.target.value })}
                  />
                  <div className="input-group-append">
                    <button type="submit" className="btn input-group-text" onClick={handleReloadData}>
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
              <div className="catalog-search-filters-form mb-2">
                <div className="catalog-search-filters-div">
                  <i className="fas fa-search search-icon"></i>
                  <input
                    className="form-control"
                    type="text"
                    id="filterOptions"
                    ref={filterOptionsRef}
                    placeholder="Search Available Reports"
                    onChange={updateFilteredLists}
                    maxLength={64}
                  />
                </div>
                <Busy isBusy={isAvailableReportsLoading} small>
                  {!!(filteredAvailableReportsList && filteredAvailableReportsList.length) &&
                    filteredAvailableReportsList.map((row, i) => (
                      <form
                        key={'filteredAvailableReportsList-form-' + i}
                        className="catalog-search-filters-checkboxes-form"
                      >
                        <input
                          type="checkbox"
                          id={row.rawType}
                          name={row.rawType}
                          value={row.name}
                          className="catalog-search-filters-checkboxes-input"
                          checked={selectedAvailableReportsList.includes(row.rawType)}
                          onChange={() => handleCheckboxFilter(row.rawType)}
                        />
                        <label htmlFor={row.rawType} className="catalog-search-filters-checkboxes-label">
                          <span data-tip data-for={`onHoverReport-${i}`}>
                            {row.name}
                          </span>
                          <ReactTooltip
                            id={`onHoverReport-${i}`}
                            place="right"
                            effect="solid"
                            className="tooltip-on-hover-report"
                          >
                            <p className="mb-0">{row.description}</p>
                          </ReactTooltip>

                          <i
                            className={row.isAvailableImmediately ? 'fas fa-bolt ml-2' : 'fas fa-clock ml-2'}
                            data-tip
                            data-for={`onHoverReportIcon-${i}`}
                          />
                          <ReactTooltip
                            id={`onHoverReportIcon-${i}`}
                            place="right"
                            effect="solid"
                            className="tooltip-on-hover-report-icon"
                          >
                            <p className="mb-0">
                              {row.isAvailableImmediately
                                ? 'Available Immediately'
                                : 'Supplier Provided SBOM can only be accessed once the supplier approved the access request.'}
                            </p>
                          </ReactTooltip>
                        </label>
                      </form>
                    ))}
                </Busy>
              </div>
              <div>
                <div className="form-group form-check mb-2">
                  <input
                    id="hasSBOM"
                    className="form-check-input"
                    type="checkbox"
                    checked={hasSBOMSearch === true}
                    onChange={(e) => setState({ hasSBOMSearch: e.target.checked ? true : null })}
                  />
                  <label className="form-check-label" htmlFor="hasSBOM">
                    has SBOM?
                  </label>
                </div>
                <div className="form-group form-check mb-2">
                  <input
                    id="hasMalware"
                    className="form-check-input"
                    type="checkbox"
                    checked={hasMalwareSearch === true}
                    onChange={(e) => setState({ hasMalwareSearch: e.target.checked ? true : null })}
                  />
                  <label className="form-check-label" htmlFor="hasMalware">
                    has Malware?
                  </label>
                </div>
                <AsyncSelect
                  className="mb-2"
                  isClearable={vendorSearch}
                  menuPortalTarget={document.body}
                  placeholder="vendor"
                  defaultOptions={productVendors}
                  getOptionLabel={(v) => v.name}
                  getOptionValue={(v) => v.id}
                  loadOptions={loadProductVendors}
                  isLoading={!productVendors}
                  onChange={(v) => setState({ vendorSearch: v?.name || null })}
                  value={productVendors?.find((v) => v.name === vendorSearch)}
                />
                <AsyncSelect
                  className="mb-2"
                  isClearable={licenseSearch}
                  menuPortalTarget={document.body}
                  placeholder="license"
                  defaultOptions={productLicenses}
                  getOptionLabel={(l) => l.license}
                  getOptionValue={(l) => l.id}
                  loadOptions={loadProductLicenses}
                  isLoading={!productLicenses}
                  onChange={(l) => setState({ licenseSearch: l?.license || null })}
                  value={productLicenses?.find((v) => v.license === licenseSearch)}
                />
                <AsyncSelect
                  className="mb-2"
                  isClearable={cveSearch}
                  menuPortalTarget={document.body}
                  placeholder="cve"
                  defaultOptions={productCVEs}
                  getOptionLabel={(c) => c.cve}
                  getOptionValue={(c) => c.id}
                  loadOptions={loadProductCVEs}
                  isLoading={!productCVEs}
                  onChange={(c) => setState({ cveSearch: c?.cve || null })}
                  value={productCVEs?.find((v) => v.cve === cveSearch)}
                />
                <AsyncSelect
                  className="mb-2"
                  isClearable={componentSearch}
                  menuPortalTarget={document.body}
                  placeholder="component"
                  defaultOptions={productComponents}
                  getOptionLabel={(c) => `${c.purl} - ${c.cpe || '(no cpe)'}`}
                  getOptionValue={(c) => c.id}
                  loadOptions={loadProductComponents}
                  isLoading={!productComponents}
                  onChange={(c) => setState({ componentSearch: c?.purl || null })}
                  value={productComponents?.find((v) => v.purl === componentSearch)}
                />
              </div>
            </div>
          </div>
          <div className="product-list">
            {/* Product list with paginate */}
            <Busy isBusy={isBusy}>
              <div className="row">
                {!!(canAddProducts && search) && (
                  <>
                    <NewItemCard
                      caption="Add Product"
                      name={search}
                      onClickHandler={() => handleOrderOtherReports()}
                      isWide
                      isClickable={canPurchaseReports}
                    />
                    <ProductModalStagesContainer
                      productName={search}
                      showModal={showModal}
                      modalStage={modalStage}
                      handleModalClose={handleModalClose}
                    />
                  </>
                )}
                {products && products.length === 0 && (
                  <p className="ml-3">
                    There are no products
                    {selectedAvailableReportsList && selectedAvailableReportsList.length
                      ? ' with all currently selected options'
                      : ''}
                    {search ? ` that contain the search term '${search}'` : ``}.
                  </p>
                )}
                {products &&
                  products.map((row, i) => (
                    <ProductCard key={i} data={row} onClick={(event) => handleCardClick(event, row)} />
                  ))}
              </div>
              <div className="paginate-holder">
                <Pagination
                  className="catalog-paginate"
                  page={page}
                  pageSize={pageSize}
                  totalRecords={totalRecords}
                  onPageChange={(page) => handlePageChange(page)}
                  visible={7}
                />
              </div>
            </Busy>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPrincipal(CatalogProducts);
