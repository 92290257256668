import React from 'react';
import config from '../../../config';
import './printButton.css';

const PrintButton = ({ printOption = 'default', vendorDomain, vendorName }) => {
  const runPrintOption = (printOption, data) => {
    switch (printOption) {
      case 'ddrr':
        ddrrPrint(data);
        break;
      case 'provenance':
        provenancePrint();
        break;
      default:
        printReport();
        break;
    }
  };

  const ddrrPrint = ({ vendorDomain, vendorName }) => {
    if (vendorDomain && vendorName) {
      const url = `${config.posternReportsDDRR}${encodeURIComponent(vendorName)}/${vendorDomain}?type=pdf`;
      window.open(url, '_blank');
    } else {
      //Leaving previous implementation for postern page
      const cyberMap = document.getElementById('ddrr-cyber-map');
      const severityGaugePrintDiv = document.getElementById('ddrr-inherent-risk-score-print');
      const severityGauge = document.getElementById('chart-area');
      const severityGaugePrintVersion = document.getElementById('severity-gauge-print-version');
      const executiveOrderStatus = document.getElementById('executive-order-status');
      const executiveOrderStatusPrint = document.getElementById('executive-order-status-print');

      if (cyberMap) {
        cyberMap.style.width = '1000px';
        cyberMap.style.height = '800px';
        cyberMap.style.margin = 'auto';
      }

      if (executiveOrderStatus && executiveOrderStatusPrint)
        executiveOrderStatusPrint.appendChild(executiveOrderStatus);

      if (severityGauge && !severityGaugePrintVersion) {
        let gaugeImage = new Image();
        gaugeImage.src = severityGauge.toDataURL();
        gaugeImage.id = 'severity-gauge-print-version';
        severityGaugePrintDiv.appendChild(gaugeImage);
        severityGaugePrintDiv.style.transform = 'scale(.5)';
      }

      setTimeout(() => {
        printReport();
      }, 1500);
    }
  };

  const provenancePrint = () => {
    let cyberMap = document.getElementById('provenance-cyber-map');

    if (cyberMap) {
      cyberMap.style.width = '1000px';
      cyberMap.style.height = '800px';
      cyberMap.style.margin = 'auto';
    }

    setTimeout(() => {
      printReport();
    }, 1500);
  };

  const printReport = () => {
    let printContents = document.getElementById('postern-content').innerHTML;
    let head = document.getElementsByTagName('head')[0].innerHTML;
    let printFooter = document.getElementById('postern-print-footer').outerHTML;
    let tempWindow = window.open('', '', ''); // might not need 2nd and last can contain h/w sizes 'height=500, width=500'
    tempWindow.document.write('<html><head>');
    tempWindow.document.write(head);
    tempWindow.document.write('</head><body>');
    tempWindow.document.write(printContents);
    tempWindow.document.write(printFooter);
    tempWindow.document.write('</body></html>');
    tempWindow.document.close();
    setTimeout(() => {
      tempWindow.print();
    }, 1500);
  };

  return (
    <div id="postern-print-button">
      <img
        onClick={() => runPrintOption(printOption, { vendorDomain, vendorName })}
        src="static/images/print-icon.svg"
        alt="print icon"
      />
    </div>
  );
};

export default PrintButton;
