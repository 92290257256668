import React, { Component } from 'react';

export default class HighRiskFactors extends Component {
  render() {
    const { vendor = {}, category = [] } = this.props;

    return (
      <div>
        <p>{vendor.manualReviewResult}</p>
        <ul>
          {category
            .filter((value) => (value.riskscoretext ? value.riskscoretext.toLowerCase() === 'high' : ''))
            .map((arrayItem, index) => (
              <li key={index}>{arrayItem.riskCategory}</li>
            ))}
        </ul>
      </div>
    );
  }
}
