import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Spinner, withPrincipal } from '../components';
import { toastError, toastSuccess, emailIsValid, nameIsValid } from '../lib/utils';
import config from '../config';

const version = require('../version.json');

const boxShadow = {
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  margin: '7px',
  borderRadius: '4px',
};

const cornerBtn = {
  borderRadius: '35px',
  color: 'white',
  padding: '10px 15px',
  zIndex: '10',
  right: '15px',
  cursor: 'pointer',
  border: '0',
};

class HelpPanel extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      showing: false,
      userInput: '',
      user: this.props.principal,
      isBusy: false,
      firstName: '',
      lastName: '',
      emailAddress: '',
      errorEmailField: false,
      touchedEmailField: false,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isBusy: true });

    const { userInput, firstName, lastName, emailAddress } = this.state;

    let user = this.props.principal;
    if (!user) {
      user = {
        emailAddress,
        firstName,
        lastName,
        id: '',
      };
    }

    fetch(config.api.urlFor('requestSupport'), {
      method: 'POST',
      body: {
        requestDetail: userInput.trim(),
        userEmail: user.emailAddress.trim(),
        userName: user.firstName.trim() + ' ' + user.lastName.trim(),
        userId: user.id,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setTimeout(() => {
          this.setState({
            showing: false,
            userInput: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            isBusy: false,
          });
          if (res) {
            toastSuccess(
              'Your request has been received by our support team! We will communicate further using your account email address.',
            );
          } else {
            toastError(
              'Something went wrong! Please directly email our support team at clienthelp@fortressinfosec.atlassian.com',
            );
          }
        }, 5000);
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({
            showing: false,
            userInput: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            isBusy: false,
          });
          toastError(
            'Something went wrong! Please directly email our support team at clienthelp@fortressinfosec.atlassian.com',
          );
        }, 5000);
      });
  }

  handleInputBox(e, keyName) {
    const val = e.target.value;
    let hasNameError = false;

    if (keyName === 'firstName' || keyName === 'lastName') {
      if (val.length && !nameIsValid(val)) {
        hasNameError = true;
      }
    } else if (keyName === 'emailAddress') {
      this.setState({ errorEmailField: val.length && !emailIsValid(val) });
    }

    if (!hasNameError) {
      this.setState({ [keyName]: val });
    }
  }

  handleBlur() {
    this.setState({ touchedEmailField: true });
  }

  render() {
    const { showing, isBusy, userInput, firstName, lastName, emailAddress, errorEmailField, touchedEmailField } =
      this.state;
    const { principal, history } = this.props;

    return (
      <div>
        <button
          onClick={() => this.setState({ showing: !showing })}
          id="help_btn"
          className="position-fixed"
          style={{
            ...boxShadow,
            ...cornerBtn,
            background: '#E74C3C',
            bottom: principal ? '50px' : '2px',
          }}
        >
          <i className="fas fa-question-circle" style={{ paddingRight: '5px' }}></i>Need Help?
        </button>
        {principal ? (
          <button
            onClick={() => history.push('/help/getStarted')}
            className="position-fixed"
            style={{
              ...boxShadow,
              ...cornerBtn,
              background: '#1D4B68',
              bottom: '2px',
            }}
          >
            <i className="fas fa-play-circle" style={{ paddingRight: '5px' }}></i>Get Started
          </button>
        ) : (
          <></>
        )}
        {showing ? (
          <div
            id="help_panel"
            width="40%"
            style={{
              ...boxShadow,
              background: '#00788a',
              marginBottom: '0',
              padding: '15px',
              position: 'fixed',
              right: '8px',
              bottom: '0',
              zIndex: '1000',
            }}
          >
            <div style={{ background: '#2C3E50', color: 'white' }}>
              <h4 className="float-left" style={{ padding: '0 0 5px' }}>
                Support Request
              </h4>
              <button
                onClick={() => this.setState({ showing: !showing })}
                style={{
                  background: 'transparent',
                  color: 'white',
                  border: '0px',
                  fontSize: '20px',
                  float: 'right',
                  cursor: 'pointer',
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div
              className="row justify-contenct-md-center float-left"
              style={{ background: 'white', padding: '20px 15px 45px' }}
            >
              <div>
                <h6 className="text-left" style={{ color: 'rgb(24, 188, 156)' }}>
                  <a href={`tel:${config.contactSupport && config.contactSupport.telephone}`}>
                    <i
                      className="fas fa-phone fa-flip-horizontal"
                      style={{ color: '#00788a', paddingLeft: '10px' }}
                    ></i>
                    Request a Demo: {config.contactSupport && config.contactSupport.telephoneUI}
                  </a>
                </h6>
                <h6 className="text-left" style={{ color: 'rgb(24, 188, 156)' }}>
                  <a href={`mailto:${config.contactSupport && config.contactSupport.salesDesk}`}>
                    <i className="fas fa-envelope" style={{ color: '#00788a', paddingRight: '10px' }}></i>Request a
                    Demo: {config.contactSupport && config.contactSupport.salesDesk}
                  </a>
                </h6>
                <h6 className="text-left" style={{ color: 'rgb(24, 188, 156)' }}>
                  <a href={`mailto:${config.contactSupport && config.contactSupport.serviceHelpDesk}`}>
                    <i className="fas fa-envelope" style={{ color: '#00788a', paddingRight: '10px' }}></i>Customer
                    Support: {config.contactSupport && config.contactSupport.serviceHelpDesk}
                  </a>
                </h6>

                <hr></hr>
                <p>Please provide us with details about your support request so we can best support you.</p>
                <form onSubmit={this.handleSubmit}>
                  <div className="controls">
                    {!principal && (
                      <>
                        <div className="row ml-0 mr-0">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                id="form_name"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="First Name *"
                                required="required"
                                data-error="Firstname is required."
                                onChange={(e) => this.handleInputBox(e, 'firstName')}
                                value={firstName}
                                maxLength={64}
                              />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                id="form_lastname"
                                type="text"
                                name="surname"
                                className="form-control"
                                placeholder="Last Name *"
                                required="required"
                                data-error="Lastname is required."
                                onChange={(e) => this.handleInputBox(e, 'lastName')}
                                value={lastName}
                                maxLength={64}
                              />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                        </div>
                        <div className="row ml-0 mr-0">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                id="form_email"
                                type="email"
                                name="email"
                                className={'form-control' + (errorEmailField && touchedEmailField ? ' is-invalid' : '')}
                                placeholder="Email Address *"
                                required="required"
                                data-error="Valid email is required."
                                onChange={(e) => this.handleInputBox(e, 'emailAddress')}
                                onBlur={this.handleBlur.bind(this)}
                                value={emailAddress}
                                maxLength={256}
                              />
                              {!!(errorEmailField && touchedEmailField) && (
                                <span className="m-error"> Please enter a valid email address </span>
                              )}
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          id="form_message"
                          data-test-id="RequestSupport-FormTextArea"
                          name="message"
                          className="form-control"
                          placeholder="Request Details *"
                          row="10"
                          required
                          data-error="Please, leave us a message."
                          onChange={(e) => this.handleInputBox(e, 'userInput')}
                          value={userInput}
                          maxLength={500}
                        ></textarea>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          data-test-id="RequestSupport-SubmitButton"
                          type="submit"
                          className="btn btn-outline-primary"
                          style={{ marginLeft: '45%' }}
                          disabled={
                            (!principal && (!firstName.trim() || !lastName.trim() || !emailAddress.trim())) ||
                            !userInput.trim() ||
                            isBusy ||
                            errorEmailField
                          }
                        >
                          Submit
                          <Spinner style={{ marginLeft: '5px' }} isVisible={isBusy} />
                        </button>
                      </div>
                      <div className="text-right col-md-12" style={{ fontSize: '.81rem', margin: '25px 0 -50px 0' }}>
                        {`Version ${version.version}.${version.buildNumber}`}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(withPrincipal(HelpPanel));
