import React from 'react';

import { withRouter } from 'react-router-dom';

import Tooltip from 'rc-tooltip';

import { riskRankColorByString, riskRankColorByScore, riskRankColorByGrade, PENDINGGREY } from '../../constants';

import { GRADE_A, GRADE_B, GRADE_C, GRADE_D, GRADE_F } from '../../constants';

import 'rc-tooltip/assets/bootstrap.css';

const ColorizedCard = (props) => {
  let { backgroundColor, value } = props;
  const { history, link, label, icon, tooltip } = props;

  if (value !== undefined) {
    backgroundColor =
      value === undefined
        ? PENDINGGREY
        : backgroundColor ||
          ([GRADE_A, GRADE_B, GRADE_C, GRADE_D, GRADE_F].indexOf(value) > -1
            ? riskRankColorByGrade(value)
            : isNaN(parseInt(value))
            ? riskRankColorByString(value)
            : riskRankColorByScore(value));
  }

  if (typeof value === 'number') {
    value = parseInt(value || 0);
  }

  backgroundColor = backgroundColor || PENDINGGREY;

  return (
    <div
      className="card text-white o-hidden h-100"
      style={{ backgroundColor: backgroundColor, cursor: link ? 'pointer' : 'default' }}
      onClick={() => link && history.push(link)}
    >
      {tooltip === undefined ? (
        <div className="card-header">{label}</div>
      ) : (
        <Tooltip
          placement="top"
          trigger={['hover']}
          overlay={<div style={{ maxWidth: 300 }}>{tooltip}</div>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <div className="card-header">{label}</div>
        </Tooltip>
      )}
      <div className="card-body">
        <div className="card-body-icon">
          <i className={`fa-fw ${icon}`}></i>
        </div>
        <div className="text-center">
          <h2>{value === undefined ? <i className="fas fa-circle-notch fa-spin"></i> : value || 0}</h2>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ColorizedCard);
