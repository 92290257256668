import React, { Component } from 'react';
import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';
import SafeHyperlink from '../../../../safeHyperlink';

// import "./breaches.css"

export default class Breaches extends Component {
  render() {
    const { breaches = {} } = this.props;

    const generateColor = (risk) => {
      switch (risk) {
        case true:
        case 'High':
        case 'high':
          return 'var(--posternRedCircle)';
        case false:
        case 'Low':
        case 'low':
          return 'var(--posternYellowCircle)';
        default:
          return 'var(--posternYellowCircle)';
      }
    };
    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          During our review of the vendor, some breaches were found over the past 5 years. Further investigation
          concludes details regarding the breach incident.
        </div>

        {breaches.length > 0 ? (
          <ReusableProvenanceTable
            tableName="BreachesTable-"
            headers={['', 'Company', 'Type of Breach', 'Description', 'Evidence', 'Date of Breach']}
          >
            {breaches.map((row, i) => {
              return (
                <React.Fragment key={'ManufacturingTable-' + i}>
                  <tr className="provenance-table-row">
                    <th className="row-padding" scope="row">
                      {' '}
                      <SeverityCircle symbol={'!'} color={generateColor(row.severity)} />{' '}
                    </th>
                    <td> {row.company} </td>
                    <td> {row.type_of_breach} </td>
                    <td> {row.description} </td>
                    <td className="m-word-break">
                      {' '}
                      <SafeHyperlink url={row.evidence}>{row.evidence}</SafeHyperlink>{' '}
                    </td>
                    <td>{new Date(row?.date_of_breach).toLocaleDateString()}</td>
                  </tr>
                </React.Fragment>
              );
            })}
          </ReusableProvenanceTable>
        ) : null}
        <footer className="footer-p-wrapper">
          <p>
            Breach data for an organization is checked through the Fortress breach database, which aggregates data from
            state breach publications and third-party sites monitoring breaches.
          </p>
        </footer>
      </React.Fragment>
    );
  }
}

{
  /* 

{% for _ in data.breaches %}
    <div className="row">
        {% if _.severity == "High" %}
        <p className="col1"><img src="../files/exclamation-circle-red.svg" style="height: 1rem" /></p>
        {% else %}
        <p className="col1"><img src="../files/yellow-exclamation-mark.png" style="height: 1rem; max-width: 18px; margin-left: auto; margin-right: auto" /></p>
        {% endif %}
        <p className="col2">{{_.company}}</p>
        <p className="col3">{{_.type_of_breach}}</p>
        <p className="col4">{{_.description}}</p>
        <p className="col5 url-ellipsis">
            <a href="{{ _.evidence }}" target="_blank">{{ _.evidence }}
            </a>
        </p>
    </div>
{% endfor %}
</div> */
}
