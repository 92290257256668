import React, { Component } from 'react';

class ScoreRiskMethodology extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="score-risk-methodology" className="section-header">
          Overall Vendor Score
        </div>
        <div>
          <div>
            <div className="findings">
              <span className="moderate-rr-st low">
                <b>Low</b>
              </span>
            </div>

            <h2>DATA| data.vendor.totalScore |DATA</h2>
            <br />
          </div>
        </div>
        <div>
          <div className="section-header">High Risk Factors</div>
          DATA| data.vendor.manualReviewResult |DATA
          <br />
          <ul>
            <li>
              <span>System Access</span> DATA| item.riskCategory |DATA
            </li>
            <li>
              <span>System Access</span> DATA| item.riskCategory |DATA
            </li>
            <li>
              <span>System Access</span> DATA| item.riskCategory |DATA
            </li>
            <li>
              <span>System Access</span> DATA| item.riskCategory |DATA
            </li>
            <li>
              <span>System Access</span> DATA| item.riskCategory |DATA
            </li>
            <li>
              <span>System Access</span> DATA| item.riskCategory |DATA
            </li>
          </ul>
          <br />
        </div>
      </React.Fragment>
    );
  }
}

export default ScoreRiskMethodology;
