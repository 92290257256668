const _ = require('lodash');
import { toast } from 'react-toastify';
import config from '../config';
import { assessmentTypes } from '../constants/assessmentTypes';

const urlFromPath = (path, params, override) => {
  path = path || '';
  params = params || {};
  override = override || {};

  const replace = Object.assign({}, params, override);

  let url = path;
  for (let prop in replace) {
    if (replace[prop]) {
      url = url.replace(`:${prop}?`, replace[prop]).replace(`:${prop}`, replace[prop]);
    } else {
      url = url.replace(`/:${prop}?`, '').replace(`/:${prop}`, '');
    }
  }

  return url;
};

const chartColors = [
  '#CB4335',
  '#884EA0',
  '#2471A3',
  '#17A589',
  '#138D75',
  '#273746',
  '#28B463',
  '#D4AC0D',
  '#CA6F1E',
  '#707B7C',
  '#A6ACAF',
];

export const capitalizeFirstLetter = (str) => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return '';
  }
};

export const splitCamelCase = (str) => str.replace(/([a-z])([A-Z])/g, '$1 $2');

export const emailIsValid = (email) => {
  const emailTestRegex =
    // eslint-disable-next-line no-useless-escape
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (
    email &&
    emailTestRegex.test(email) &&
    email.split('@').length &&
    email.split('@')[0].length <= 64 && // local cannot be larger than 64 octets/chars
    email.split('@')[1].length <= 255 // domain cannot be larger than 255 octets/chars
  )
    return true;
  return false;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '').slice(0, 10);
  const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
    let result = '';

    if (match[1]) result += '(' + match[1];
    if (match[2]) result += ') ' + match[2];
    if (match[3]) result += '-' + match[3];

    return result;
  }

  return cleaned;
};

export const formatNumber = (numberString, allowNegative = true) => {
  if (!numberString) {
    return '';
  }

  let cleaned = ('' + numberString)
    .replace(/[^0-9.-]/g, '') // Keep only digits, decimal point and negative sign.
    .replace(/(?<!^)-+/g, '') // Remove dashes in the middle of the text.
    .replace(/(?<=^0)0+/g, ''); // Remove all but the first leading zeroes.
  //  .replace(/^0+(?=[^.])/g, ''); // Remove insignificant leading zeroes. This is not allowing you to add a leading zero when other digits exist.

  if (!allowNegative) {
    cleaned = cleaned.replace(/-/g, '');
  }

  const x = cleaned.split('.');

  const x1 = x.shift();
  const x2 = x.length > 0 ? '.' + x.join('') : ''; // Here we are removing the extra decimal points.
  const l1 = x1[0] == '-' ? 7 : 6;
  const l2 = x2[0] == '.' ? 4 : 0;

  // Return up to 6 digits for the whole part and 3 for the decimal part of the number.
  return x1.slice(0, l1) + (l2 ? x2.slice(0, l2) : '');
};

export const formatDate = (dateString, showTime = true) => {
  if (!dateString) {
    return '';
  }

  const idx = showTime ? 5 : 4;

  return new Date(dateString).toString().split(' ').slice(0, idx).join(' ');
};

// Checks if a given text is containing at least two lower case, two upper case, two numbers and two special characters from @, $, !, %, *, #, ?, &
export const passwordHasTwoUppercase = (password) => /[A-Z].*[A-Z]/.test(password);
export const passwordHasTwoLowercase = (password) => /[a-z].*[a-z]/.test(password);
export const passwordHasTwoNumbers = (password) => /[0-9].*[0-9]/.test(password);
export const passwordHasTwoSpecialChars = (password) =>
  /[\@|\$|\!|\%|\*|\#|\?|\&].*[\@|\$|\!|\%|\*|\#|\?|\&]/.test(password); // eslint-disable-line

export { urlFromPath, chartColors };

export const findObjectByInnerKeyValue = (object, keyName, keyValue) => {
  let result = {};

  for (const property in object) {
    if (
      Object.prototype.hasOwnProperty.call(object[property], keyName) &&
      object[property][`${keyName}`] === keyValue
    ) {
      result = object[property];
      break;
    }
  }

  return result;
};

export const currencyFormatter = (number, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'MM' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return number >= item.value;
    });
  return item
    ? Number((number / item.value).toFixed(digits))
        .toLocaleString()
        .replace(rx, '$1') + item.symbol
    : '0';
};

export const checkIfEmptyObject = (object) => {
  const objKeys = Object.keys(object);
  return objKeys.length > 0
    ? objKeys.some((i) => {
        return Object.prototype.hasOwnProperty.call(object, i) && object[i].length > 0;
      })
    : true;
};

export const checkArrayIfEmptyObject = (arr) => {
  // return arr.some((obj) => checkIfEmptyObject(obj));
  return arr.some((obj) => _.isEmpty(obj));
};

export const tabToPath = (tab) => {
  return (tab || '').toString().replace(/ /g, '').replace(/\//g, '-');
};

export const makeGoogleMapsUrl = (country, city, known_address) => {
  country = !country || country.toLowerCase() === 'unknown' ? '' : country.replaceAll(' ', '+').concat('+');
  city = !city || city.toLowerCase() === 'unknown' ? '' : city.replaceAll(' ', '+').concat('+');
  known_address = !known_address || known_address.toLowerCase() === 'unknown' ? '' : known_address.replaceAll(' ', '+');
  return 'https://www.google.com/maps/place/' + (country + city + known_address);
};

export const toastError = (errorMessage, options) => {
  toast.error(
    errorMessage || 'Error!',
    Object.assign(
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      },
      options,
    ),
  );
};

export const toastSuccess = (successMessage, options) => {
  toast.success(
    successMessage || 'Success!',
    Object.assign(
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      },
      options,
    ),
  );
};

export const toastInfo = (infoMessage, options) => {
  toast.info(
    infoMessage || 'Saving...',
    Object.assign(
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      },
      options,
    ),
  );
};

// Validates UUIDs versions 1 to 5. Does NOT match NIL UUIDs.
export const uuidIsValid = (uuid) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);

// Validates page number.
export const pageIsValid = (page, totalRecords, pageSize) => {
  return (
    ((!totalRecords || Number(totalRecords) === 0) && Number(page) === 1) ||
    (Number(page) <=
      Number.parseInt(Number(totalRecords) / pageSize) +
        (Number.parseInt(Number(totalRecords) % pageSize) === 0 ? 0 : 1) &&
      Number(page) > 0)
  );
};

// Validates First and Last Names or Subscriber Name.
export const nameIsValid = (name, allowNumbers = false) => {
  const regex = allowNumbers ? /^[a-z 0-9 ,.'-]+$/i : /^[a-z ,.'-]+$/i;
  return (
    regex.test(name) &&
    !name.endsWith('..') &&
    !name.endsWith(',,') &&
    !name.endsWith("''") &&
    !name.endsWith('--') &&
    !name.endsWith('  ')
  );
};

export const sortDate = (a, b, order = 'asc') => {
  const diff = new Date(a).getTime() - new Date(b).getTime();
  return order === 'asc' ? diff : -diff;
};

export const sortCaseInsensitive = (a, b, order = 'asc') => {
  const diff = a.toLowerCase().localeCompare(b.toLowerCase());
  return order === 'asc' ? diff : -diff;
};

export const sortNumber = (a, b, order = 'asc') => {
  const diff = Number(a) - Number(b);
  return order === 'asc' ? diff : -diff;
};

// Password must include at least one lowecase/uppercase/symbol/digit and at least 15 characters.
export const passwordIsValid = (password) => {
  const passwordPatern = new RegExp(
    `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{${config.passwordRequiredMinLength},})`,
  );
  return passwordPatern.test(password);
};

export const IMAGE_NOT_AVAILABLE = '/static/images/imageNotAvail.jpg';

export const fiaToA2VsbomID = ({ fiaSBOMid, fiaSBOMname }) => {
  let sbomID = null;

  const foundFIAsbom = fiaSBOMid
    ? assessmentTypes.FIA_SBOM.find((elem) => elem.id === fiaSBOMid)
    : fiaSBOMname
    ? assessmentTypes.FIA_SBOM.find((elem) => elem.name === fiaSBOMname)
    : null;

  if (foundFIAsbom && foundFIAsbom.a2vID) {
    const foundSBOM = assessmentTypes.SBOM.find((elem) => elem.id === foundFIAsbom.a2vID);
    if (foundSBOM && foundSBOM.id) {
      sbomID = foundSBOM.id;
    }
  }

  return sbomID;
};

export const a2vToFIAsbomID = ({ sbomID, sbomName }) => {
  let fiaSBOMid = null;

  const foundSBOM = sbomID
    ? assessmentTypes.SBOM.find((elem) => elem.id === sbomID)
    : sbomName
    ? assessmentTypes.SBOM.find((elem) => elem.name === sbomName)
    : null;

  if (foundSBOM) {
    const foundFIAsbom = assessmentTypes.FIA_SBOM.find((elem) => elem.a2vID === foundSBOM.id);
    if (foundFIAsbom && foundFIAsbom.id) {
      fiaSBOMid = foundFIAsbom.id;
    }
  }

  return fiaSBOMid;
};

export const prependHTTPStoLink = (link) => {
  if (!link) return '';

  const httpString = 'http://',
    httpsString = 'https://';

  return link?.substr(0, httpString.length) !== httpString && link?.substr(0, httpsString.length) !== httpsString
    ? httpsString + link
    : link;
};
