import React from 'react';
import ReactModal from 'react-modal';
import { Busy, Pagination, Table, useUpdateState } from '../../../components';
import { toastError } from '../../../lib/utils';
import CatalogDropZone from '../../../components/vendor/catalog/catalogDropZone';
import config from '../../../config';

const NewImportModal = (props) => {
  //Props
  const { isOpen = false, onModalClose = () => {}, onImportComplete = () => {} } = props;

  //State
  const [state, setState] = useUpdateState({
    submittedFiles: [],
    processingUpload: false,
    uploadComplete: false,
  });

  const { submittedFiles, processingUpload, uploadComplete } = state;

  //Effects
  React.useEffect(() => {
    if (isOpen) {
      resetModal();
    }
  }, [isOpen]);

  //Functions
  const setSubmittedFiles = (file) => {
    setState({ submittedFiles: file });
  };

  const handleUpload = async () => {
    let formData = new FormData();
    formData.append('csv', submittedFiles[0]);

    setState({ processingUpload: true });

    await fetch(config.api.urlFor('vendorCSVUpload'), {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        if (res.status === 403) {
          toastError('You do not have the permissions to bulk upload vendors. Please contact your system admin.');
          setState({ processingUpload: false });
        } else if (res.status !== 200) {
          toastError('An error occured, please try again later. If this issue persists, please contact our support.');
          setState({ processingUpload: false });
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res && res.success) {
          setState({ processingUpload: false, uploadComplete: true });
          onImportComplete();
        }
      })
      .catch(() => {
        toastError(
          'An error occured while uploading the file, please try again later. If this issue persists, please contact our support.',
        );
        setState({ processingUpload: false });
      });
  };

  const handleTemplateDownload = () => {
    const rows = [
      ['name', 'domain'],
      ['example company name', 'example company domain'],
    ];

    var blob = new Blob([rows.map((e) => e.join(',')).join('\n')], { type: 'data:text/csv;charset=utf-8;' });
    var url = URL.createObjectURL(blob);

    var downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', 'upload-vendor-template.csv');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const resetModal = () => {
    setState({ submittedFiles: [], processingUpload: false, uploadComplete: false });
  };

  //Render
  return (
    <ReactModal
      isOpen={isOpen}
      className="new-import-modal"
      ariaHideApp={false}
      contentLabel="Upload Your Vendors"
      shouldCloseOnOverlayClick={!processingUpload}
      onRequestClose={onModalClose}
    >
      {uploadComplete ? (
        <div className="import-modal-no-header-content">
          <button className="import-modal-header-close" onClick={onModalClose}>
            x
          </button>
          <div className="success-checkmark"></div>
          <p>Your file has been successfully uploaded.</p>
          <div className="side-buttons-container">
            <div>
              <button className="btn btn-close" onClick={onModalClose}>
                Close
              </button>
            </div>
            <div>
              <button className="btn btn-continue" onClick={resetModal}>
                Upload New File
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {processingUpload ? (
            <div className="import-modal-no-header-content">
              <Busy isBusy={true} />
              <div className="section-inner-title animated-ellipsis">Uploading File</div>
            </div>
          ) : (
            <>
              <div className="import-modal-header">
                Upload Your Vendors
                <button className="import-modal-header-close" onClick={onModalClose}>
                  x
                </button>
              </div>
              <div className="import-modal-content">
                <p>
                  Adding your vendors to A2V is easy. A CSV file (comma separated values) containing the names of your
                  vendors can be dropped in the section below. A2V will compare your list of vendors with vendors
                  already added to A2V.
                </p>
                <p>
                  <button className="btn btn-primary" onClick={handleTemplateDownload}>
                    Download Template CSV File
                  </button>
                </p>
                <CatalogDropZone
                  currentFiles={submittedFiles}
                  acceptableFileType=".csv"
                  setCurrentFiles={setSubmittedFiles}
                  singleFileMode={true}
                />
                <div className="side-buttons-container">
                  <div>
                    <button className="btn btn-close" onClick={onModalClose}>
                      Close
                    </button>
                  </div>
                  <div>
                    <button className="btn btn-continue" disabled={submittedFiles.length === 0} onClick={handleUpload}>
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </ReactModal>
  );
};

const UploadList = (props) => {
  //Props
  const { isBusy, model, page, totalRecords, handleTableRefresh = () => {}, handlePageChange = () => {} } = props;

  //State
  const [state, setState] = useUpdateState({
    newModalIsOpen: false,
  });

  const { newModalIsOpen } = state;

  //Functions
  const toggleNewImportModal = () => {
    setState({ newModalIsOpen: !state.newModalIsOpen });
  };

  //Render
  return (
    <>
      <NewImportModal
        isOpen={newModalIsOpen}
        onModalClose={toggleNewImportModal}
        onImportComplete={handleTableRefresh}
      />
      <div className="imports-table-actionables">
        <div className="imports-actionables-trinary">
          <div>
            <button className="btn btn-primary" onClick={toggleNewImportModal}>
              New
            </button>
          </div>
          <div>
            <Pagination page={page} totalRecords={totalRecords} onPageChange={handlePageChange} />
          </div>
          <div>
            <button className="btn btn-primary" onClick={handleTableRefresh}>
              Refresh
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Table
            columns={[
              { label: `Subscriber`, source: `subscriber` },
              { label: `Name`, source: `name` },
              { label: `Status`, source: `status` },
              { label: `Created`, source: `created` },
              { label: `Updated`, source: `updated` },
              { label: ``, source: `action` },
            ]}
            data={model}
            isBusy={isBusy}
          />
        </div>
      </div>
    </>
  );
};

export default UploadList;
