import am4geodata_data_countries2 from '@amcharts/amcharts4-geodata/data/countries2';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import country_locations from '../../../../../../constants/postern/counrtyLocations';
import * as am4plugins_overlapBuster from '@amcharts/amcharts4/plugins/overlapBuster';

am4core.addLicense('CH91205152');

const makeCyberMap = function (
  manufacturing,
  physicalPresence,
  cyberPresence,
  corporateFamilies,
  foreignOwnership,
  watchlist,
) {
  am4core.addLicense('MP91205152');
  am4core.addLicense('CH91205152');
  (manufacturing = manufacturing || []),
    (physicalPresence = physicalPresence || []),
    (cyberPresence = cyberPresence || []),
    (corporateFamilies = corporateFamilies || []),
    (foreignOwnership = foreignOwnership || []),
    (watchlist = watchlist || []);

  // BEGIN GEO-IP MAP

  // Themes begin
  am4core.useTheme(am4themes_animated);
  // Themes end

  // Create chart instance
  let chart = am4core.create('provenance-cyber-map', am4maps.MapChart);
  chart.width = am4core.percent(100);
  chart.height = am4core.percent(100);
  chart.geodata = am4geodata_worldLow;
  chart.projection = new am4maps.projections.Miller();
  chart.homeZoomLevel = 1.0;

  // Add zoom control
  chart.zoomControl = new am4maps.ZoomControl();

  //Country view
  const countrySeries = chart.series.push(new am4maps.MapPolygonSeries());
  countrySeries.useGeodata = true;
  countrySeries.hide();

  countrySeries.geodataSource.events.on('done', function (ev) {
    worldSeries.hide();
    countrySeries.show();
  });

  const countryPolygon = countrySeries.mapPolygons.template;
  countryPolygon.tooltipText = '{name}';
  countryPolygon.nonScalingStroke = true;
  countryPolygon.strokeOpacity = 0.5;

  //World view
  const worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
  worldSeries.exclude = ['AQ'];
  worldSeries.useGeodata = true;

  const worldPolygon = worldSeries.mapPolygons.template;
  worldPolygon.tooltipText = '{name}';

  worldPolygon.events.on('hit', function (event) {
    const chart = event.target.series.chart;
    chart.zoomToMapObject(event.target);

    const map = event.target.dataItem.dataContext.map;

    if (map) {
      event.target.isHover = false;
      countrySeries.geodataSource.url = 'https://cdn.amcharts.com/lib/4/geodata/json/' + map + '.json';
      countrySeries.geodataSource.load();
    }

    const countryName = event.target.dataItem.dataContext.name;
    handleDataTransition(countryName in countryData ? countryData[countryName] : []);

    //This is so the user can actually hover over the tooltip and interact with the scrollbar in the tooltip
    imageSeries.tooltip.keepTargetHover = true;
    imageSeries.tooltip.label.interactionsEnabled = true;
  });

  //Set up high risk country color coding
  const highRiskCountries = [
    'RU', //Russia
    'CN', //China
    'IR', //Iran
    'KP', //North Korea
    'CU', //Cuba
    'VE', //Venezuela
    'HK', //Hong Kong
  ];

  let countryRiskData = [];
  for (let id in am4geodata_data_countries2) {
    if (am4geodata_data_countries2.hasOwnProperty(id)) {
      const country = am4geodata_data_countries2[id];

      if (country.maps.length) {
        let countryData = {
          id: id,
          map: country.maps[0],
          hoverColor: am4core.color('#367B25'),
        };

        if (highRiskCountries.indexOf(id) > -1) {
          countryData.fill = am4core.color('#f8d7da');
          countryData.stroke = am4core.color('#721c43');
          countryData.hoverColor = am4core.color('#cf382e');
        }

        countryRiskData.push(countryData);
      }
    }
  }

  worldSeries.data = countryRiskData;

  //Home button
  var homeButton = new am4core.Button();

  homeButton.events.on('hit', function () {
    chart.goHome();
    worldSeries.show();
    countrySeries.hide();

    handleDataTransition(worldData);

    imageSeries.tooltip.keepTargetHover = false;
    imageSeries.tooltip.label.interactionsEnabled = false;
  });

  homeButton.icon = new am4core.Sprite();
  homeButton.padding(7, 5, 7, 5);
  homeButton.width = 30;
  homeButton.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8';
  homeButton.marginBottom = 10;
  homeButton.parent = chart.zoomControl;
  homeButton.insertBefore(chart.zoomControl.plusButton);

  let polygonTemplate = worldSeries.mapPolygons.template;
  polygonTemplate.strokeOpacity = 0.5;
  polygonTemplate.nonScalingStroke = true;
  polygonTemplate.propertyFields.stroke = 'stroke';
  polygonTemplate.stroke = am4core.color('#155724');
  polygonTemplate.propertyFields.fill = 'fill';
  polygonTemplate.fill = am4core.color('#ffffff');

  var hs = polygonTemplate.states.create('hover');
  hs.propertyFields.fill = 'hoverColor';

  let imageSeries = chart.series.push(new am4maps.MapImageSeries());
  imageSeries.dataFields.value = 'count';
  imageSeries.mapImages.template.layout = 'absolute';
  imageSeries.mapImages.template.isMeasured = true;
  imageSeries.data = [];

  let imageSeriesTemplate = imageSeries.mapImages.template;
  imageSeriesTemplate.propertyFields.latitude = 'latitude';
  imageSeriesTemplate.propertyFields.longitude = 'longitude';
  imageSeriesTemplate.propertyFields.fill = 'fill';
  imageSeriesTemplate.horizontalCenter = 'middle';
  imageSeriesTemplate.verticalCenter = 'middle';
  imageSeriesTemplate.nonScaling = true;
  imageSeriesTemplate.tooltipHTML = '<div style="overflow-y: auto; max-height: 300px;">{title}</div>';
  imageSeriesTemplate.fill = am4core.color('#636363');
  imageSeriesTemplate.background.fillOpacity = 0;
  imageSeriesTemplate.background.fill = am4core.color('#fff');
  imageSeriesTemplate.setStateOnChildren = true;
  imageSeriesTemplate.layout = 'absolute';
  imageSeriesTemplate.states.create('hover');

  // use svg icon for pins
  let circle = imageSeriesTemplate.createChild(am4core.Circle);
  circle.propertyFields.radius = 'radius';
  circle.fillOpacity = 0.8;

  let circleText = imageSeriesTemplate.createChild(am4core.Label);
  circleText.text = '{count}';
  circleText.fill = am4core.color('#fff');
  circleText.horizontalCenter = 'middle';
  circleText.verticalCenter = 'middle';

  let overlap = chart.plugins.push(new am4plugins_overlapBuster.OverlapBuster());
  overlap.targets.push(imageSeries.mapImages.template);

  //This should handle the transition issues for using overlapbuster
  const handleDataTransition = (newData) => {
    imageSeries.hide();
    overlap.targets.clear();
    imageSeries.data = newData;
    overlap.targets.push(imageSeries.mapImages.template);
    imageSeries.show();
  };

  // Set heat rules
  imageSeries.heatRules.push({
    target: circle,
    min: 10,
    max: 25,
    property: 'radius',
  });

  //Organize data
  const displayGroups = [
    {
      groupName: 'Manufacturing Location',
      data: manufacturing,
      color: '#E84D3D',
      titleFn: (v) => v.city + ', ' + v.country,
    },
    {
      groupName: 'Physical Presence',
      data: physicalPresence,
      color: '#186E15',
      titleFn: (v) => v.city + ', ' + v.country,
    },
    {
      groupName: 'Cyber Presence',
      data: cyberPresence,
      color: '#E88F24',
      titleFn: (v) => v.ip,
    },
    {
      groupName: 'Corporate Family',
      data: corporateFamilies,
      color: '#004D70',
      titleFn: (v) => v.domain + ' - ' + v.name,
    },
    {
      groupName: 'Foreign Ownership',
      data: foreignOwnership,
      color: '#B585E3',
      titleFn: (v) => v.owner_name + ' - ' + v.city + ', ' + v.country,
    },
    {
      groupName: 'on Watch List',
      data: watchlist,
      color: '#FDD400',
      titleFn: (v) => v.company_name,
    },
  ];

  let worldData = [];
  let countryData = [];
  let worldDataByCountry = {};
  let countryDataByCountry = {};

  //Group data by country and by group
  for (let group of displayGroups) {
    const { groupName, data, color, titleFn } = group;

    if (groupName && data && data.length && color && !!titleFn) {
      for (let entry of data) {
        if (
          !entry ||
          !Object.prototype.hasOwnProperty.call(entry, 'latitude') ||
          !Object.prototype.hasOwnProperty.call(entry, 'longitude')
        ) {
          continue;
        }

        const { country } = entry;

        let latitude = entry.latitude;
        let longitude = entry.longitude;
        let location = country_locations.filter((country) => country.country === entry.country);

        if ((latitude == null || longitude == null) && location && location.length) {
          latitude = location[0].latitude;
          longitude = location[0].longitude;
        }

        if (!latitude || !longitude) {
          continue;
        }

        latitude *= 1.0;
        longitude *= 1.0;

        if (!countryDataByCountry[country]) {
          countryDataByCountry[country] = {};
        }

        if (!countryDataByCountry[country][groupName]) {
          countryDataByCountry[country][groupName] = {
            color: am4core.color(color),
            data: {},
          };
        }

        const coordinateKey = latitude + ',' + longitude;

        if (!countryDataByCountry[country][groupName].data[coordinateKey]) {
          countryDataByCountry[country][groupName].data[coordinateKey] = [];
        }

        countryDataByCountry[country][groupName].data[coordinateKey].push({
          latitude: latitude,
          longitude: longitude,
          title: titleFn(entry),
        });
      }
    }
  }

  //Build the data used for the world view and the country view
  for (let country in countryDataByCountry) {
    let countryOverlap = {};

    //Get the average coordinate for the country + group pairing
    for (let groupName in countryDataByCountry[country]) {
      const groupColor = countryDataByCountry[country][groupName].color;

      const groupData = Object.keys(countryDataByCountry[country][groupName].data)
        .map((prop) => countryDataByCountry[country][groupName].data[prop])
        .flat();
      const count = groupData.length;

      const avgLat = groupData.map((x) => x.latitude).reduce((sum, val) => sum + val) / count;
      const avgLong = groupData.map((x) => x.longitude).reduce((sum, val) => sum + val) / count;

      if (!worldDataByCountry[country]) {
        worldDataByCountry[country] = {};
      }

      //This is a single icon in the world view for the country + group pairing w/ the summary title
      worldDataByCountry[country][groupName] = {
        count: count,
        radius: calculateRadius(count),
        latitude: avgLat,
        longitude: avgLong,
        title:
          country +
          ': ' +
          count +
          ' ' +
          (count > 1 ? (groupName.endsWith('y') ? groupName.slice(0, -1) + 'ies' : groupName + 's') : groupName),
        fill: countryDataByCountry[country][groupName].color,
      };

      //Within countries that have clustered points, adjust the coordinates of the icons so they do not overlap
      for (let coordinateKey in countryDataByCountry[country][groupName].data) {
        const { latitude, longitude } = countryDataByCountry[country][groupName].data[coordinateKey][0];
        const titleList = countryDataByCountry[country][groupName].data[coordinateKey].map((x) => x.title);

        if (!countryOverlap[coordinateKey]) {
          countryOverlap[coordinateKey] = [];
        }

        //Combine entries in the same group with the same coordinates into a single point
        countryOverlap[coordinateKey].push({
          count: titleList.length,
          radius: calculateRadius(titleList.length),
          latitude: latitude,
          longitude: longitude,
          title:
            (titleList.length > 1 ? '<ul style="margin-left: -20px; margin-bottom: 3px"><li>' : '') +
            titleList.join('</li><li>') +
            (titleList.length > 1 ? '</li></ul>' : ''),
          fill: groupColor,
        });
      }
    }

    const groupKeys = Object.keys(worldDataByCountry[country]);
    const numOfGroups = groupKeys.length;

    //For countries that have multiple groups, adjust the coordinates of the icons so they do not overlap
    if (numOfGroups > 1) {
      let avgLat =
        groupKeys.map((groupKey) => worldDataByCountry[country][groupKey].latitude).reduce((sum, val) => sum + val) /
        numOfGroups;
      let avgLong =
        groupKeys.map((groupKey) => worldDataByCountry[country][groupKey].longitude).reduce((sum, val) => sum + val) /
        numOfGroups;

      for (let idx = 0; idx < numOfGroups; idx++) {
        const groupKey = groupKeys[idx];
        const dataPoint = worldDataByCountry[country][groupKey];

        const { adjustLat, adjustLong } = overlapAdjustment(idx, numOfGroups, 1.0, 1.0, 2);

        const newLat = avgLat + adjustLat;
        const newLong = avgLong + adjustLong;

        worldData.push(Object.assign(dataPoint, { latitude: newLat, longitude: newLong }));
      }
    } else {
      //No modifications needed in coordinate display
      worldData = worldData.concat(worldDataByCountry[country][groupKeys[0]]);
    }

    //Handle overlapping between groups within a country
    for (let coordinateKey in countryOverlap) {
      const count = countryOverlap[coordinateKey].length;

      for (let idx = 0; idx < count; idx++) {
        const dataPoint = countryOverlap[coordinateKey][idx];
        const { latitude, longitude } = dataPoint;

        //We want a smaller adjustment here compared to the world adjustment because the map still uses the same lat/long spacing
        const { adjustLat, adjustLong } = overlapAdjustment(idx, count, 0.1, 0.1, 2);

        const newLat = latitude + adjustLat;
        const newLong = longitude + adjustLong;

        if (!countryData[country]) {
          countryData[country] = [];
        }

        countryData[country].push(Object.assign(dataPoint, { latitude: newLat, longitude: newLong }));
      }
    }
  }

  //Set the default, world data view
  imageSeries.data = worldData;

  return chart;
};

const removeCyberMap = function (chart) {
  chart.dispose();
};

const calculateRadius = (count) => {
  return 8 + (count.toString().length - 1) * 5;
};

//This formatting is based on the 6 different possible areas we display
const overlapAdjustment = (idx, total, offsetX, offsetY, divisor) => {
  let adjustLat = 0;
  let adjustLong = 0;

  if (total >= 4) {
    adjustLat = (Math.ceil(total / 2) > idx ? 1 : -1) * (offsetY / divisor);
  }

  //There probably is a pattern here I'm not realizing...
  switch (idx) {
    case 0:
      adjustLong = total > 1 ? (total !== 2 && total !== 4 ? -1 * offsetX : (-1 * offsetX) / divisor) : 0;
      break;
    case 1:
      adjustLong += total === 1 || total === 4 ? offsetX / divisor : 0;
      break;
    case 2:
      adjustLong = total === 4 ? (-1 * offsetX) / divisor : offsetX;
      break;
    case 3:
      adjustLong = total === 4 ? offsetX / divisor : total === 5 ? (-1 * offsetX) / divisor : -1 * offsetX;
      break;
    case 4:
      adjustLong = total === 5 ? offsetX / divisor : 0;
      break;
    default:
      adjustLong = offsetX;
      break;
  }

  return { adjustLat, adjustLong };
};

export { makeCyberMap, removeCyberMap };
