import React from 'react';
import { Route } from 'react-router-dom';
import Register from './register';
import RegisterGuest from './registerGuest';

export const RegisterWrapper = (props) => {
  return (
    <React.Fragment>
      <Route exact path={`${props.match.url}/:id/:vendorID`} component={() => <RegisterGuest {...props} />} />
      <Route exact path={`${props.match.url}/:id`} component={() => <Register {...props} />} />
    </React.Fragment>
  );
};

export default RegisterWrapper;
