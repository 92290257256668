import React from 'react';
import { BACKGROUNDLIGHTGRAY, TEXTDARKBLUE } from '../../constants';
import ContactFormButton from '../contactFormButton';

const AssessmentRequest = () => {
  const wrapperStyle = {
    backgroundColor: BACKGROUNDLIGHTGRAY,
    padding: '20px 0px',
  };

  const requestHeaderStyle = {
    color: TEXTDARKBLUE,
  };

  return (
    <div style={wrapperStyle} className="text-center">
      <h4 style={requestHeaderStyle}>Request an Assessment on My Company</h4>
      <h5>
        An assessment is a deep dive into your company&#39;s secturity posture. Our specialists will work with you to
        <br />
        perform an assessment on your company, and report on your findings. After completion of an
        <br />
        assessment, your profile will receive a badge demonstrating you are taking steps to secure your company.
        <br />
        <br />
      </h5>
      <ContactFormButton caption="Request Assessment" storageKey="requestAssessment" storageValue="true" />
    </div>
  );
};

export default AssessmentRequest;
