import React, { useMemo } from 'react';
import { useUpdateState } from '../../components';
import './password-strength.css';

const flags = {
  none: 0,
  uppers: 1,
  specials: 2,
  digits: 4,
  lowers: 8,
  length: 16,
};

const replicate = (v, n) => {
  const result = [];

  for (let i = 0; i < n; i++) {
    result.push(v);
  }

  return result.join('');
};

export const PasswordStrength = ({ id, onChange, inputProps, uppers, specials, digits, lowers, length }) => {
  const [state, setState] = useUpdateState({
    password: '',
    confirmPassword: '',
    status: 0,
    isValid: false,
    isConfirmed: false,
  });

  const applicableFlags = useMemo(() => {
    let value = 0;

    value |= uppers ? flags.uppers : 0;
    value |= specials ? flags.specials : 0;
    value |= digits ? flags.digits : 0;
    value |= lowers ? flags.lowers : 0;
    value |= length ? flags.length : 0;

    return value;
  }, [uppers, specials, digits, lowers, length]);

  inputProps.id = useMemo(() => inputProps.id || 'password-strength-password', [inputProps.id]);
  inputProps.autoComplete = useMemo(() => inputProps.autoComplete || 'password', [inputProps.autoComplete]);

  const requirements = useMemo(() => {
    let uppersRegex = /.*/;
    if (uppers > 0) {
      uppersRegex = new RegExp(`(?=${replicate('.*[A-Z]', uppers)})`);
    }

    let specialsRegex = /.*/;
    if (specials > 0) {
      specialsRegex = new RegExp(`(?=${replicate('.*[!@#$&*%^]', specials)})`);
    }

    let digitsRegex = /.*/;
    if (digits > 0) {
      digitsRegex = new RegExp(`(?=${replicate('.*[0-9]', digits)})`);
    }

    let lowersRegex = /.*/;
    if (lowers > 0) {
      lowersRegex = new RegExp(`(?=${replicate('.*[a-z]', lowers)})`);
    }

    let lengthRegex = /.*/;
    if (length > 0) {
      lengthRegex = new RegExp(`(?=.{${length},})`);
    }

    return {
      uppers: uppersRegex,
      specials: specialsRegex,
      digits: digitsRegex,
      lowers: lowersRegex,
      length: lengthRegex,
    };
  }, [uppers, specials, digits, lowers, length]);

  const passwordConfirmProps = useMemo(
    () => ({
      ...inputProps,
      id: `${inputProps.id}-confirm`,
      placeholder: 'Confirm',
      autoComplete: `${inputProps.autoComplete}-confirm`,
      autoFocus: false,
    }),
    [inputProps],
  );

  const { isValid, isConfirmed, password, confirmPassword, status } = state;

  const onPasswordChange = (e) => {
    let status = 0,
      password = e.target.value,
      isConfirmed = password && password === confirmPassword;

    if (uppers && requirements.uppers.test(password)) {
      status |= flags.uppers;
    }

    if (specials && requirements.specials.test(password)) {
      status |= flags.specials;
    }

    if (digits && requirements.digits.test(password)) {
      status |= flags.digits;
    }

    if (lowers && requirements.lowers.test(password)) {
      status |= flags.lowers;
    }

    if (length && requirements.length.test(password)) {
      status |= flags.length;
    }

    const isValid = (status & applicableFlags) === applicableFlags;

    onChange && onChange({ password, isValid, isConfirmed });

    setState({
      status,
      password,
      isValid,
      isConfirmed,
    });
  };

  const onConfirmPasswordChange = (e) => {
    const { value } = e.target;
    const isConfirmed = value && password === value;

    onChange && onChange({ password, isValid, isConfirmed });

    setState({ confirmPassword: value, isConfirmed });
  };

  return (
    <div className="password-strength" id={id}>
      <div className={`${isValid ? 'met' : 'not-met'}`}>
        <input type="password" onChange={onPasswordChange} value={password} {...inputProps} />
      </div>
      <div className={`${isConfirmed ? 'met' : 'not-met'}`}>
        <input type="password" onChange={onConfirmPasswordChange} value={confirmPassword} {...passwordConfirmProps} />
      </div>
      {applicableFlags ? (
        <div className="requirements">
          Requirements:
          <ul>
            {uppers ? (
              <li className={(status & flags.uppers) === flags.uppers ? 'met' : 'not-met'}>{uppers} Upper</li>
            ) : (
              ''
            )}
            {specials ? (
              <li className={(status & flags.specials) === flags.specials ? 'met' : 'not-met'}>
                {specials} Special (!@#$&amp;*%^)
              </li>
            ) : (
              ''
            )}
            {digits ? (
              <li className={(status & flags.digits) === flags.digits ? 'met' : 'not-met'}>{digits} Numeric</li>
            ) : (
              ''
            )}
            {lowers ? (
              <li className={(status & flags.lowers) === flags.lowers ? 'met' : 'not-met'}>{lowers} Lower</li>
            ) : (
              ''
            )}
            {length ? (
              <li className={(status & flags.length) === flags.length ? 'met' : 'not-met'}>
                Length of at least {length}
              </li>
            ) : (
              ''
            )}
            {isConfirmed ? <li className="met">Passwords match</li> : <li className="not-met">Passwords must match</li>}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PasswordStrength;
