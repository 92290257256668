import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withPrincipal } from '../../components';
import { tabToPath } from '../../lib/utils';
import SectionContainer from '../sectionContainer';
import SectionLocked from '../sectionLocked';
import SectionSummary from '../sectionSummary';
import AvailableReports from './availableReports';
import InfoHygiene from './infoHygiene';
import RiskRankSection from './riskRankSection';
import CyberHygieneSection from './cyberHygieneSection';
import BreachMonitoring from './breachMonitoring';
import MyFindingsTable from './myFindingsTable';

import '../../routes/vendor/catalogVendor.css';
import Busy from '../busy';

/** Functional component for an overview summary of a vendor  */
const OverviewContent = (props) => {
  //Props
  const {
    vendorRisk = {},
    isLoadingVendorRisk = true,
    vendorData = {},
    ddrrScoresData = {},
    provenanceScoresData = {},
    isUnlockedDDRR,
    isUnlockedCyberHygiene,
    isUnlockedCMRE,
    handlePurchaseButtonClick,
    reportTypeCMRE,
    reportTypeDDRR,
    reportTypeCyberHygiene,
    setShowModal,
    breachMonitoring,
    isLoadingBreaches,
    dnsFindings = {},
    sslFindings = {},
    appSecFindings = {},
    vulnsFindings = {},
    hasScanData,
    hasCMREData,
    handleReportDateChange,
  } = props;

  //Prop-based consts
  const canPurchaseReports = props.principal.roles.some((r) =>
    r.permissions.some((p) => p === 'subscriber.purchaseReports'),
  );

  const currentLocationHash = props.history.location.hash;

  useEffect(() => {
    if (currentLocationHash) {
      scrollToSection();
    }
  }, [currentLocationHash]);

  const scrollToSection = () => {
    const anchorSectionID = currentLocationHash.substring(currentLocationHash.indexOf('#') + 1);
    const anchorSection = document.getElementById(anchorSectionID);

    if (anchorSection) {
      const y = anchorSection.getBoundingClientRect().top + window.pageYOffset - 10;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  /* ----------------------------------- */
  /*          Handle Inputs              */
  /* ----------------------------------- */

  const handleDetailsClick = (selectedTab, subsection) => {
    const overviewPathBase = props.pathBase;
    props.history.push(
      overviewPathBase +
        (overviewPathBase.endsWith('/') ? '' : '/') +
        tabToPath(selectedTab) +
        (subsection ? '/' + tabToPath(subsection) : ''),
    );
  };

  const outOfTokensHandler = () => {
    props.history.push('/help/contact');
  }; //This will eventually open the sales modal

  /* ----------------------------------- */
  /*             Render                  */
  /* ----------------------------------- */

  return (
    <div id="content-container">
      <div id="info-and-scan">
        <InfoHygiene vendorRisk={vendorRisk} isLoadingVendorRisk={isLoadingVendorRisk} vendorData={vendorData}>
          <SectionContainer label="Risk Summary">
            <RiskRankSection
              vendorName={vendorData.name}
              isUnlockedDDRR={isUnlockedDDRR}
              ddrrScoresData={ddrrScoresData}
              reportTypeDDRR={reportTypeDDRR}
              handleUnlockButtonClick={handlePurchaseButtonClick}
              viewDashboardOnClick={() => handleDetailsClick('RiskRank')}
            />
            <CyberHygieneSection
              isUnlockedCyberHygiene={isUnlockedCyberHygiene}
              vendorName={vendorData.name}
              reportTypeCyberHygiene={reportTypeCyberHygiene}
              handleUnlockButtonClick={handlePurchaseButtonClick}
              viewDashboardOnClick={() => handleDetailsClick('CyberHygiene', 'Discovery')}
              dnsFindings={dnsFindings}
              sslFindings={sslFindings}
              appSecFindings={appSecFindings}
              vulnsFindings={vulnsFindings}
              hasScanData={hasScanData}
            />
            <div className="text-center">
              <div className="section-main-title">Out of Tokens?</div>
              <div>
                The Asset-to-Vendor (A2V) Marketplace is powered by Fortress Tokens. Please reach out to an A2V support
                agent to refill your balance.
              </div>
              <button
                type="button"
                className="btn btn-danger my-2"
                onClick={() => outOfTokensHandler()}
                disabled={!canPurchaseReports}
                title={
                  !canPurchaseReports
                    ? 'You do not have the permissions to purchase reports. Please contact your system admin.'
                    : null
                }
                pointerEvents="auto"
              >
                Inquire about Tokens
              </button>
            </div>
          </SectionContainer>
        </InfoHygiene>
      </div>
      <SectionContainer label="Foreign Influence Alerts" className="row" loading={!provenanceScoresData}>
        {isUnlockedCMRE ? (
          <>
            {hasCMREData ? (
              provenanceScoresData ? (
                <SectionSummary
                  scoresData={[
                    { label: 'Cyber Presence', value: provenanceScoresData.cyberPresence },
                    { label: 'Foreign Ownership', value: provenanceScoresData.foreignOwnership },
                    { label: 'Physical Locations', value: provenanceScoresData.physicalPresence },
                    { label: 'Mergers & Acquisitions', value: provenanceScoresData.mergerAcquisition },
                    { label: 'Corporate Families', value: provenanceScoresData.corporateFamilies },
                    { label: 'Manufacturing', value: provenanceScoresData.manufacturing },
                  ]}
                  viewDashboardOnClick={() => handleDetailsClick('ForeignInfluence', 'Summary')}
                  componentContext="foreign-influence"
                />
              ) : (
                <Busy isBusy={!provenanceScoresData} />
              )
            ) : (
              <>
                <div className="summary-section-container overview-no-cmre-data-notice">
                  <div className="section-inner-title animated-ellipsis">Currently Processing</div>
                  <div className="section-info-text">Continuous Monitoring of {vendorData.name} is in progress.</div>
                </div>
              </>
            )}
          </>
        ) : (
          <SectionLocked
            title="Unlock Foreign Influence Alerts"
            buttonCaption="Unlock Foreign Influence Alerts"
            info={`Subscribe to Continuous Monitoring for ${vendorData.name} by purchasing a Continuous Monitoring Report - Enhanced.`}
            details="Be alerted to events that may increase the risk of doing business with your vendors:"
            bullets={['Compromise', 'Compliance', 'Foreign Influence', 'Mergers & Acquisitions']}
            className="col-9"
            canUnlockSection={canPurchaseReports}
            handleUnlockButtonClick={handlePurchaseButtonClick}
            reportType={reportTypeCMRE}
          />
        )}
      </SectionContainer>
      <div className="row">
        <SectionContainer label="Available Reports" className="col-7 pl-0">
          <AvailableReports
            canPurchaseReports={canPurchaseReports}
            vendorSharesVSCA={vendorData.canShareVSCA}
            setShowModal={setShowModal}
            handlePurchaseButtonClick={canPurchaseReports ? handlePurchaseButtonClick : () => {}}
            handleReportDateChange={handleReportDateChange}
          />
        </SectionContainer>
        <SectionContainer id="BreachMonitoring" label="Breach Monitoring" className="col-5 pr-0">
          <BreachMonitoring breachMonitoring={breachMonitoring} isLoadingBreaches={isLoadingBreaches} />
        </SectionContainer>
      </div>
      <SectionContainer label="Findings" className="overview-findings">
        <div className="row px-3">
          <div className="col-12">
            <div className="card">
              <MyFindingsTable forSpecificVendor={true} vendorID={vendorData.id} />
            </div>
          </div>
        </div>
      </SectionContainer>
      {/*
          This needs to be hidden for now. Probably will be moved elsewhere in the near future.
          <MyVendorsTable data={vendorData}/>
        */}
    </div>
  );
};

export default withPrincipal(withRouter(OverviewContent));
