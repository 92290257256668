import React from 'react';
import config from '../../../../src/config';
import { riskTierBySeverity } from '../../../constants/string';
import { InfoHygieneBadge } from '../infoHygieneBadge';
import { Spinner } from '../../../components';

const TopModalDisplayVendor = (props) => {
  const { modalData, callRemoveMyVendor, callMakeMyVendor, onProductModal } = props;

  const currencyFormatter = (number, digits) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'MM' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find((item) => {
        return number >= item.value;
      });
    return item
      ? Number((number / item.value).toFixed(digits))
          .toLocaleString()
          .replace(rx, '$1') + item.symbol
      : '0';
  };
  // This mimics the logic from catalogVendorOverview.jsx to create the 'Average Score' property
  // TODO: Ideally in the future, this number would be returned from the API. Instead of generated here
  if (modalData) {
    modalData.averageScore = riskTierBySeverity(modalData.predictedRiskTier);
  }

  const numberFormatter = Intl.NumberFormat('en-US');

  const vendorOverviewLink =
    '/#' +
    config.routes.catalogVendor.to
      .replace(':id', modalData.id)
      .replace(':selectedTab', 'Overview')
      .replace('?/:subsection?', '');

  return (
    <div className="modal-vendor-info-box">
      <div className="modal-vendor-info-left">
        <div className="modal-vendor-logo-box">
          {modalData.logoURL && <img src={modalData.logoURL} alt={modalData.name.length > 18 ? modalData.name.substring(0, 15) + '...' : modalData.name} className="modal-vendor-logo" />}
        </div>
        <div className="modal-vendor-name">
          <p className="modal-vendor-name-text">{modalData.name}</p>
          <div className="modal-vendor-overview-link">
            <a href={vendorOverviewLink} className="modal-vendor-overview-button btn-info">
              View Profile
            </a>
          </div>
        </div>
      </div>
      <div className="modal-vendor-info-right">
        <div className="modal-vendor-info">
          <p className="modal-NAICS">
            <span className="m-bold">NAICS: </span> {modalData.naics}
          </p>
          <p>
            <span className="m-bold">Estimated Revenue: </span>{' '}
            {isNaN(modalData.revenue) ? '' : '$' + currencyFormatter(Number(modalData.revenue), 1)}
          </p>
          <p>
            <span className="m-bold">Estimated Employees: </span>{' '}
            {isNaN(modalData.revenue) ? '' : numberFormatter.format(modalData.employees)}
          </p>
          <p className="temp-space"></p>
          {!onProductModal && (
            <div className="modal-vendor-icons">
              <a
                className="modal-my-vendors-button-link"
                onClick={() => {
                  modalData.myVendor === undefined
                    ? null
                    : modalData.myVendor
                    ? callRemoveMyVendor(modalData.id)
                    : callMakeMyVendor(modalData.id);
                }}
                disabled={modalData.myVendor === undefined}
              >
                <div className="modal-my-vendors-button add-vendor-button">
                  <div
                    className={`modal-my-vendors-color vendor-cricle ${
                      modalData.myVendor ? `` : `not-`
                    }my-vendors-color`}
                  >
                    <i className="lg-icon-fa fas fa-users"></i>
                  </div>
                  <div className="modal-my-vendors-text modal-icon-text">
                    {modalData.myVendor === undefined ? (
                      <Spinner isVisible={true} />
                    ) : modalData.myVendor ? (
                      `Click to Remove`
                    ) : (
                      `Click to Add`
                    )}
                  </div>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopModalDisplayVendor;
