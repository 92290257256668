import React, { Component } from 'react';

import ReusableProvenanceTable from './reusableProvenanceTable';

import './appendixCyberHygiene.css';

export default class AppendixCyberHygiene extends Component {
  render() {
    const { cyberHygiene_details = [], cyberHygieneSummary = {} } = this.props;
    return (
      <>
        <div className="postern-section-summary-paragraph">
          The table below details issues identified on the given hostname:
        </div>

        {cyberHygiene_details.length > 0 ? (
          <div id="ap-cyber-hygiene-table">
            <ReusableProvenanceTable
              tableName="AppendixCyberHygieneTable-"
              headers={['Target', 'Number of Issues', 'Issues Found']}
            >
              {cyberHygiene_details.map((childObject, i) => {
                let iss_num = 0;
                let iss_string = '';
                let childObjectKeys = Object.keys(childObject);
                let childObjectValues = Object.values(childObject);

                childObjectKeys.map((key, j) => {
                  if (
                    childObjectValues[j] === false &&
                    key !== 'wildcards' &&
                    !cyberHygieneSummary.transport_list.includes(key)
                  ) {
                    iss_num++;
                    iss_string = iss_string + key + ': disabled, ';
                  } else if (
                    (childObjectValues[j] === true && key === 'wildcards') ||
                    cyberHygieneSummary.transport_list.includes(key)
                  ) {
                    iss_num++;
                    iss_string = iss_string + key + ': enabled, ';
                  }
                });

                return (
                  <React.Fragment key={'AppendixVulnSolutionTable-' + i}>
                    <tr className="provenance-table-row">
                      <td className="row-padding"> {childObject.target} </td>
                      <td id="iss-num"> {iss_num} </td>
                      <td> {iss_string.slice(0, -2).replaceAll('_', '.')} </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </ReusableProvenanceTable>
          </div>
        ) : null}

        <div className="postern-section-summary-paragraph">
          <p id="fo-findings">
            {' '}
            <b>D</b>omain <b>N</b>ame <b>S</b>ystem <b>Sec</b>urity <b>E</b>xtensions (<b>DNSSEC</b>) – Suite of
            Internet Engineering Task Force (IETF) specifications for securing certain kinds of information provided by
            the Domain Name System (DNS) as used on Internet Protocol (IP) networks. It is a set of extensions to DNS
            which provide to DNS clients (resolvers) origin authentication of DNS data, authenticated denial of
            existence, and data integrity, but not availability or confidentiality. DNSSEC should be enabled for best
            security practices.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>N</b>ext <b>Sec</b>urity (<b>NSEC</b>) – NSEC-records link to the next record name in the zone (in DNSSEC
            sorting order) and lists the record types that exist for the record&apos;s name. These records can be used
            by resolvers to verify the non-existence of a record name and type as part of DNSSEC validation. NSEC should
            be enabled for best security practices.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>D</b>omain <b>N</b>ame <b>S</b>ystem <b>Encryption</b> – The process of encoding a message or information
            in such a way that only authorized parties can access it. DNS encryption should be enabled for best security
            practices.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>Wildcard </b> – A record in a DNS zone that will match requests for non-existent domain names. A wildcard
            DNS record is specified by using an asterisk as the leftmost label (part) of a domain name (e.g.
            *.example.com). Wildcards should not be used in DNS records for best security practices. Issues arise
            because (1) If one server or sub-domain is compromised, all sub-domains may be compromised, (2) if the
            wildcard certificate needs to be revoked, all sub-domains will need a new certificate and (3) wildcard
            certificates may not work seamlessly with older server-client configurations. Sender Policy Framework (SPF)
            records are used to indicate to mail exchanges which hosts are authorized to send mail for a domain. SPFs
            are used to prevent non-authorized servers from sending emails on behalf of another company&apos;s domain.
          </p>

          <p id="fo-findings">
            {' '}
            <b>S</b>ender <b>P</b>olicy <b>F</b>ramework (<b>SPF</b>) records are used to indicate to mail exchanges
            which hosts are authorized to send mail for a domain. SPFs are used to prevent non-authorized servers from
            sending emails on behalf of another company&apos;s domain.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>D</b>omain-based <b>M</b>essage <b>A</b>uthentication, <b>R</b>eporting &amp; <b>C</b>onformance (
            <b>DMARC</b>) - is an email-validation system and database designed to detect and prevent email spoofing.
            DMARC is intended to combat certain techniques often used in phishing and email spam, such as emails with
            forged sender addresses that appear to originate from legitimate organizations.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>C</b>ontent <b>S</b>ecurity <b>P</b>olicy (<b>CSP</b>) is a W3C specification, offering the possibility
            to instruct the client browser from which location and/or which type of resources are allowed to be loaded.
            Having a CSP is a security best practice.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>C</b>ross-<b>S</b>ite <b>S</b>cripting (<b>XSS</b>) protection prevents attacks where malicious scripts
            are injected into otherwise benign and trusted websites. XSS attacks occur when an attacker uses a web
            application to send malicious code, generally in the form of a browser-side script to a different end user.
            Flaws that allow these attacks to succeed are widespread and can occur when a web application outputs
            user-provided input without validation or encoding. XSS headers are security best practices.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>C</b>ross-<b>F</b>rame-<b>O</b>ptions (<b>XFO</b>) - is an HTTP response header, mostly used to combat
            clickjacking, that informs a Web browser if the page should be rendered in a frame or iframe. XFO counts are
            for informational purposes and do not pose a security risk.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>C</b>ontent <b>T</b>ype <b>O</b>ptions (<b>CTO</b>) headers were introduced by Microsoft in Internet
            Explorer 8.0 as a way for webmasters to block prevalent content sniffing that could transform non-executable
            MIME types into executable MIME types.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>P</b>ublic <b>K</b>ey <b>P</b>inning (<b>PKP</b>) follows standard best practices for web security,
            including consistent use of escaping to avoid XSS attacks, tokens to prevent CSRF attacks, etc. PKP counts
            are for informational purposes.{' '}
          </p>

          <p id="fo-findings">
            {' '}
            <b>S</b>trict-<b>T</b>ransport-<b>S</b>ecurity (<b>STS</b>) response header (often abbreviated as HSTS) lets
            a web site tell browsers that it should only be accessed using HTTPS, instead of using HTTP.{' '}
          </p>
        </div>
      </>
    );
  }
}
