import React, { useEffect } from 'react';
import { useUpdateState } from '../../';
import { formatPhoneNumber, emailIsValid } from '../../../lib/utils';

const BottomModalQuestionnaire = (props) => {
  const [state, setState] = useUpdateState({
    contactName: '',
    phoneNumber: '',
    emailAddress: '',
    reportName: '',
    errorFields: {
      contactName: true,
      phoneNumber: true,
      emailAddress: true,
    },
    touchedFields: {
      contactName: false,
      phoneNumber: false,
      emailAddress: false,
    },
  });

  useEffect(() => {
    const { contactName, phoneNumber, emailAddress, errorFields, touchedFields, reportName } = props;

    setState({
      contactName: contactName,
      phoneNumber: phoneNumber,
      emailAddress: emailAddress,
      errorFields: errorFields,
      touchedFields: touchedFields,
      reportName: reportName,
    });
  }, []);

  const handleInputBox = (e, keyName) => {
    const { errorFields = {} } = state;
    let val = e.target.value;
    let hasError = false;

    if (keyName === 'contactName') {
      if (!val.length) {
        hasError = true;
      }
    } else if (keyName === 'phoneNumber') {
      val = formatPhoneNumber(val);
      e.target.value = val;

      if (val.length !== 14) {
        hasError = true;
      }
    } else if (keyName === 'emailAddress') {
      if (!emailIsValid(val)) {
        hasError = true;
      }
    }

    setState({
      [keyName]: val,
      errorFields: { ...errorFields, [keyName]: hasError },
    });
  };

  const handleNavigationClick = (direction) => {
    const { setQuestionnaireFields, setBottomModalState } = props;
    const { contactName, phoneNumber, emailAddress, errorFields, touchedFields } = state;

    setQuestionnaireFields(contactName.trim(), phoneNumber, emailAddress, errorFields, touchedFields);
    setBottomModalState(direction);
  };

  const handleBlur = (e) => {
    const { touchedFields = {} } = state;
    setState({
      touchedFields: { ...touchedFields, [e.target.id]: true },
    });
  };

  const { contactName, phoneNumber, emailAddress, errorFields = {}, touchedFields = {}, reportName } = state;
  const disableNextStep = Object.keys(errorFields).some((el) => errorFields[el] === true);
  const allowGoingBackwards = props.allowGoingBackwards === undefined ? true : props.allowGoingBackwards;

  return (
    <React.Fragment>
      <div id="catalog-modal-stage-3" className="modal-report-info-box modal-mt-override">
        <p className="m-tal-c m-bold pb-4">Please provide vendor contact information for {reportName}</p>
        <div>
          <div className="pb-2">
            <div className="input-title-text">Vendor Contact Name*</div>
            <input
              value={contactName}
              id="contactName"
              className={'form-control' + (errorFields.contactName && touchedFields.contactName ? ' is-invalid' : '')}
              type="text"
              placeholder="Contact Name"
              onChange={(e) => handleInputBox(e, 'contactName')}
              onBlur={handleBlur}
            />
            {errorFields.contactName && touchedFields.contactName && (
              <span className="m-error"> Please enter a vendor contact name </span>
            )}
          </div>
          <div className="pb-2">
            <div className="input-title-text">Phone Number*</div>
            <input
              value={phoneNumber}
              id="phoneNumber"
              className={'form-control' + (errorFields.phoneNumber && touchedFields.phoneNumber ? ' is-invalid' : '')}
              type="text"
              placeholder="(___) ___-____"
              onChange={(e) => handleInputBox(e, 'phoneNumber')}
              onBlur={handleBlur}
            />
            {errorFields.phoneNumber && touchedFields.phoneNumber && (
              <span className="m-error"> Please enter a valid phone number </span>
            )}
          </div>
          <div className="pb-2">
            <div className="input-title-text">Email*</div>
            <input
              value={emailAddress}
              id="emailAddress"
              className={'form-control' + (errorFields.emailAddress && touchedFields.emailAddress ? ' is-invalid' : '')}
              type="text"
              placeholder="example@domain.com"
              onChange={(e) => handleInputBox(e, 'emailAddress')}
              onBlur={handleBlur}
            />
            {errorFields.emailAddress && touchedFields.emailAddress && (
              <span className="m-error"> Please enter a valid email address </span>
            )}
          </div>
        </div>

        <hr></hr>
        <div className="smaller-text">
          <p className="m-bold pb-2">How does Fortress work with vendors for questionnaires?</p>
          <p>
            For new questionnaires that do not exist in the Asset-to-Vendor Network, Fortress assessors will follow
            standard operating procedures:
          </p>
          <ul>
            <li>
              Fortress will reach out to the client to kickoff vendor chasing services, included in the purchase of{' '}
              {reportName}.
            </li>
            <li>Schedule kickoff call with vendor, based on contact information provided above: </li>
            <ul>
              <li>3x emails over 2 weeks.</li>
            </ul>
            <li>Vendor Portal Initiation:</li>
            <ul>
              <li>Vendor will be notified of an upcoming vendor portal invitation.</li>
              <li>Vendor will be invited by email into the vendor portal.</li>
            </ul>
            <li>Vendor Portal Follow-up:</li>
            <ul>
              <li>
                For completion of questionnaire, vendors will receive 3 follow-ups by call and email over 2 weeks.
              </li>
              <li>If a questionnaire is partially completed, Fortress will reach out to vendor to check on status.</li>
            </ul>
          </ul>
          <p className="m-bold pt-2 pb-2">What happens if the vendor is unresponsive?</p>
          <p>In the event a vendor is unresponsive, Fortress will refund 1/2 the token spent to process services.</p>
        </div>

        <div className="m-flex-out">
          {allowGoingBackwards ? (
            <span>
              <button onClick={() => handleNavigationClick('PREV')} className="modal-report-info-btn btn">
                Back
              </button>
            </span>
          ) : (
            <span></span>
          )}
          <span>
            <button
              disabled={disableNextStep}
              onClick={() => handleNavigationClick('NEXT')}
              className="modal-report-info-btn btn"
            >
              Next
            </button>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BottomModalQuestionnaire;
