import React from 'react';
import { generateColor, generateSymbol } from '../../../constants/postern/riskIconGenerator.js';
import Busy from '../../busy';
import SeverityCircle from '../../postern/reusable/severityCircle';
import SectionContainer from '../../sectionContainer';
import ForeignInfluenceMap from '../foreignInfluenceMap';
import ForeignInfluenceTable from '../foreignInfluenceTable';
import { prependHTTPStoLink } from '../../../lib/utils';

/** Functional component for displaying the foreign ownership portion of Foreign Influence data */
const ForeignOwnership = (props) => {
  //Props
  const { foreignOwnership = [], foreignOwnershipScore, isLoading, isActive } = props;

  //Prop-Based Consts
  const nonUSForeignOwnership = foreignOwnership.filter((row) => row.country.toLowerCase() != 'united states');

  //Render
  return (
    <Busy isBusy={isLoading}>
      <SectionContainer label="Foreign Ownership Findings" className="row fi-section-container">
        <ForeignInfluenceTable
          tableName="ForeignInfluence-ForeignOwnership"
          headers={['Violation', 'Owner', 'City', 'Country', 'Domain']}
        >
          {nonUSForeignOwnership.length > 0 ? (
            nonUSForeignOwnership.map((row, i) => {
              const updatedDomain = prependHTTPStoLink(row.owner_domain);
              return (
                <tr className="provenance-table-row fi-table-row" key={'ForeignOwnershipTable-inner-' + i}>
                  <th className="row-padding" scope="row">
                    {' '}
                    <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                  </th>
                  <td> {row.owner_name} </td>
                  <td className="country-display">{row.country}</td>
                  <td>
                    {' '}
                    {row.owner_domain != null ? (
                      <a href={updatedDomain} target="_blank" rel="noopener noreferrer">
                        {updatedDomain}
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="provenance-table-row-empty" key={'ForeignOwnershipTable-empty'}>
              <td colSpan={6}>
                <p>No Findings Identified</p>
              </td>
            </tr>
          )}
        </ForeignInfluenceTable>
      </SectionContainer>

      <div className="fi-summary-text">
        {foreignOwnershipScore === 'high' ? (
          <p>
            During our review of the current owners, some were found to have headquarters in countries of adversarial
            presence. Further investigation concludes that the foreign influence from ownership is likely significant.
          </p>
        ) : (
          <p>
            During our review of the current owners, none were found to have headquarters in countries of adversarial
            presence. Further investigation concludes that the foreign influence from ownership is not likely
            significant, and thus no finding is raised.
          </p>
        )}
      </div>

      <SectionContainer label="Foreign Ownership" className="fi-section-container fi-map">
        <ForeignInfluenceMap foreignOwnership={foreignOwnership} displayIfEmpty={!isLoading && isActive} />
      </SectionContainer>
    </Busy>
  );
};

export default ForeignOwnership;
