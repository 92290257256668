import React, { useState } from 'react';
import ReactTable from 'react-table';

export default (props) => {
  // return target groups in a checkbox list
  const { selected, setSelected } = props;
  const [selectAll, setSelectAll] = useState(0);
  const toggleRow = (name, id) => {
    const newSelected = Object.assign({}, selected);
    if (newSelected[name]) {
      delete newSelected[name];
    } else {
      newSelected[name] = !selected[name];
    }
    setSelected(newSelected);
    setSelectAll(2);
  };

  const toggleSelectAll = () => {
    let newSelected = {};

    if (selectAll === 0) {
      props.targetGroups.forEach((x) => {
        newSelected[x.name] = true;
      });
    }
    setSelected(newSelected);
    setSelectAll(selectAll === 0 ? 1 : 0);
  };

  const columns = [
    {
      columns: [
        {
          id: 'checkbox',
          accessor: '',
          Cell: ({ original }) => (
            <input
              type="checkbox"
              className="checkbox"
              checked={selected[original.name] === true}
              onChange={() => toggleRow(original.name, original.id)}
            />
          ),
          Header: (x) => (
            <input
              type="checkbox"
              className="checkbox"
              checked={selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = selectAll === 2;
                }
              }}
              onChange={() => toggleSelectAll()}
            />
          ),
          filterable: false,
          sortable: false,
          width: 45,
        },
        {
          Header: 'Target Groups',
          accessor: 'name',
        },
      ],
    },
  ];
  return (
    <ReactTable
      style={{ height: '400px', width: '400px' }}
      columns={columns}
      data={props.targetGroups}
      className=" -highlight hide-arrows"
      selectType="checkbox"
      showPageSizeOptions={false}
      showPagination={false}
    />
  );
};
