import React from 'react';

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: true,
      setting: 'value',
    };
  }

  componentDidMount() {
    this.setState({ isBusy: false });
  }

  render() {
    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-12">
              <h4>Settings</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="setting1">Setting1</label>
                <input
                  id="setting1"
                  type="text"
                  className="form-control"
                  onChange={(e) => this.setState({ setting: e.target.value })}
                  value={this.state.setting}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="setting2">Setting2</label>
                <input
                  id="setting2"
                  type="text"
                  className="form-control"
                  onChange={(e) => this.setState({ setting: e.target.value })}
                  value={this.state.setting}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label htmlFor="setting3">Setting3</label>
                <input
                  id="setting3"
                  type="text"
                  className="form-control"
                  onChange={(e) => this.setState({ setting: e.target.value })}
                  value={this.state.setting}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-outline-primary">
                go
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default Admin;
