import React from 'react';
import config from '../../config';

const CatalogViewSwitch = (props) => {
  const { isVendorView } = props;
  return (
    <div className="catalog-mini-nav-area">
      <div className="content-nav-switch">
        <a
          className={'catalog-view-option' + (isVendorView ? ' selected' : '')}
          href={'#' + config.routes.catalogShort.to}
        >
          Vendors
        </a>
        <a
          className={'catalog-view-option' + (!isVendorView ? ' selected' : '')}
          href={'#' + config.routes.catalogProductsShort.to}
        >
          Products
        </a>
      </div>
    </div>
  );
};

export default CatalogViewSwitch;
