import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';

import {
  getAssetsAssociatedToTargetGroup,
  deleteTargetGroup,
  addTargetGroupToProvider,
  addAssetsToTargetGroup,
  addTargetGroup,
} from './targetGroupService';

export default (props) => {
  // return input with target group name to be edited, list of selected and unselected associated assets, also edit button
  // props will be selected target group name and its associated assets that need to be checked
  const { selectedTargetGroup, targetGroups } = props;
  const selectedTargetGroupName = Object.keys(selectedTargetGroup)[0];
  const selectedTargetGroupId = targetGroups.filter((g) => g.name === selectedTargetGroupName)[0].id;
  const [selected, setSelected] = useState({});
  const [selectAll, setSelectAll] = useState(0);
  const [name, setName] = useState(selectedTargetGroupName);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function get() {
      // get associated assets to edited target group and make them selected
      let associatedAssets = await getAssetsAssociatedToTargetGroup(selectedTargetGroupId);
      let selectedAssets = {};
      for (let i = 0; i < associatedAssets.length; i++) {
        selectedAssets[associatedAssets[i].hostname] = true;
      }
      setSelected(selectedAssets);
    }
    get();
  }, []);

  const toggleRow = (name, id) => {
    const newSelected = Object.assign({}, selected);
    if (newSelected[name]) {
      delete newSelected[name];
    } else {
      newSelected[name] = !selected[name];
    }
    setSelected(newSelected);
    setSelectAll(2);
  };

  const toggleSelectAll = () => {
    let newSelected = {};

    if (selectAll === 0) {
      props.assets.forEach((x) => {
        newSelected[x.name] = true;
      });
    }
    setSelected(newSelected);
    setSelectAll(selectAll === 0 ? 1 : 0);
  };

  const editTargetGroupWithAssets = async () => {
    setLoading(true);
    await deleteTargetGroup(selectedTargetGroupId);
    // return name and id from this post
    let targetGroup = await addTargetGroup({ name });
    // need to add targetGroup to provider
    await addTargetGroupToProvider({
      targetGroupId: targetGroup.targetGroup.id,
      providerId: props.providerId,
    });
    const selectedAssets = Object.keys(selected);
    let assets = [];
    for (let i = 0; i < props.assets.length; i++) {
      if (selectedAssets.includes(props.assets[i].hostname)) {
        assets.push({
          id: props.assets[i].id,
          hostname: props.assets[i].hostname,
        });
      }
    }
    // add associated assets
    await addAssetsToTargetGroup(assets, targetGroup.targetGroup.id);
    props.closeModal();
    setLoading(false);
  };

  const columns = [
    {
      columns: [
        {
          id: 'checkbox',
          accessor: '',
          Cell: ({ original }) => (
            <input
              type="checkbox"
              className="checkbox"
              checked={selected[original.hostname] === true}
              onChange={() => toggleRow(original.hostname)}
            />
          ),
          Header: (x) => (
            <input
              type="checkbox"
              className="checkbox"
              checked={selectAll === 1}
              ref={(input) => {
                if (input) {
                  input.indeterminate = selectAll === 2;
                }
              }}
              onChange={() => toggleSelectAll()}
            />
          ),
          filterable: false,
          sortable: false,
          width: 45,
        },
        {
          Header: 'Products',
          accessor: 'hostname',
        },
      ],
    },
  ];
  return (
    <div>
      <div className="p-3">
        <div className="pb-2">Target Group Name:</div>
        <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} value={name} />
      </div>
      <ReactTable
        manual
        style={{ height: '400px', width: '400px' }}
        multiSort={false}
        showPageSizeOptions={false}
        filterable
        sortable
        columns={columns}
        data={props.assets}
        className="-highlight hide-arrows"
        selectType="checkbox"
        defaultSorted={[{ id: 'hostname', desc: false }]}
        showPagination={false}
        loading={loading}
      />
      <div className="pt-3">
        <button
          type="button"
          className="btn btn-primary"
          disabled={loading}
          onClick={() => {
            editTargetGroupWithAssets();
          }}
        >
          {loading ? <i className="fas fa-circle-notch fa-spin"></i> : 'Edit Target Group'}
        </button>
      </div>
    </div>
  );
};
