import React from 'react';
import { generateColor, generateSymbol } from '../../../constants/postern/riskIconGenerator';
import Busy from '../../busy';
import SeverityCircle from '../../postern/reusable/severityCircle';
import SectionContainer from '../../sectionContainer';
import ForeignInfluenceMap from '../foreignInfluenceMap';
import ForeignInfluenceTable from '../foreignInfluenceTable';

/** Functional component for displaying the cyber pressence portion of Foreign Influence data */
const CyberPresence = (props) => {
  //Props
  const { vendorName, cyberPresence = [], isLoading, isActive, highRisks } = props;

  //Handle Data
  const groupByCountry = (cyberPresence) => {
    let totalIPs = 0;
    let latestDate = null;
    let countryGrouping = {};

    for (let entry of cyberPresence) {
      const { ip, country, reportDate } = entry;

      const jsDate = new Date(reportDate);

      totalIPs += 1;

      if (!latestDate || (latestDate && latestDate.getTime() < jsDate.getTime())) {
        latestDate = jsDate;
      }

      if (!countryGrouping[country]) {
        countryGrouping[country] = {
          highRisk: highRisks.some((risk) => risk.toLowerCase() === country.toLowerCase()) ? 'high' : 'low',
          country: country,
          numIPs: 1,
          IPs: [ip],
        };
      } else {
        countryGrouping[country].numIPs += 1;
        countryGrouping[country].IPs.push(ip);
      }
    }

    for (let country in countryGrouping) {
      countryGrouping[country].IPs.sort();
    }
    const completionDate = latestDate ? latestDate.toLocaleDateString() : '';
    const cyberPresenceByCountry = Object.keys(countryGrouping)
      .map((country) => countryGrouping[country])
      .sort((a, b) => {
        const aRisk = a.highRisk.toLowerCase();
        const bRisk = b.highRisk.toLowerCase();
        const aCountry = a.country.toLowerCase();
        const bCountry = b.country.toLowerCase();
        return aRisk != bRisk ? (aRisk === 'high' ? -1 : 1) : aCountry > bCountry ? 1 : aCountry < bCountry ? -1 : 0;
      });

    return { completionDate, totalIPs, cyberPresenceByCountry };
  };

  //Calculate Data
  const { completionDate, totalIPs, cyberPresenceByCountry } = groupByCountry(cyberPresence);

  //Render
  return (
    <Busy isBusy={isLoading}>
      <SectionContainer label="Cyber Presence" className="row fi-section-container">
        <ForeignInfluenceTable tableName="CyberPresenceTable-" headers={['Violation', 'Country', 'Number of IPs']}>
          {cyberPresenceByCountry.length > 0 ? (
            cyberPresenceByCountry.map((row, i) => {
              return (
                <tr className="provenance-table-row" key={'CyberPresenceTable-' + i}>
                  <th className="row-padding" scope="row">
                    {' '}
                    <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                  </th>
                  <td className="country-display">{row.country}</td>
                  <td>{row.numIPs}</td>
                </tr>
              );
            })
          ) : (
            <tr className="provenance-table-row-empty" key={'CyberPresenceTable-empty'}>
              <td colSpan={4}>
                <p>No Findings Identified</p>
              </td>
            </tr>
          )}
        </ForeignInfluenceTable>
      </SectionContainer>

      <div className="fi-summary-text">
        <p>
          {'A scan of ' +
            vendorName +
            (completionDate ? ' completed on ' + completionDate : '') +
            ' has ' +
            totalIPs +
            ' IP address' +
            (totalIPs ? 'es' : '') +
            '.'}
        </p>
      </div>

      <SectionContainer label="Cyber Presence" className="fi-section-container fi-map">
        <ForeignInfluenceMap cyberPresence={cyberPresence} displayIfEmpty={!isLoading && isActive} />
      </SectionContainer>
    </Busy>
  );
};

export default CyberPresence;
