import React from 'react';
import Chart from 'chart.js';

const defaultID = `canvas.${Math.random() * 100000000 + 1000}`;

class Chartjs extends React.Component {
  componentDidMount() {
    const { id, options } = this.props;
    this.mountChart(id, options);
  }

  componentDidUpdate(prevProps) {
    const { id: prevID, options: prevOptions } = prevProps;
    const { id, options } = this.props;

    if (prevID === id && JSON.stringify(prevOptions) === JSON.stringify(options)) {
      return;
    }

    this.mountChart(id, options);
  }

  mountChart(id = defaultID, options) {
    let clonedOptions = JSON.parse(JSON.stringify(options));
    new Chart(id, clonedOptions);
  }

  render() {
    const { id = defaultID, height = '100px', ...other } = this.props;

    return <canvas id={id} height={height} {...other}></canvas>;
  }
}

export default Chartjs;
