import React from 'react';

const SectionToggle = (props) => {
  return (
    <>
      <button className="btn btn-link" onClick={(e) => props.onClick(e)}>
        {props.value ? <span>less &laquo;</span> : <span>more &raquo;</span>}
      </button>
    </>
  );
};

export default SectionToggle;
