import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Table, useUpdateState } from '..';
import { findObjectByInnerKeyValue } from '../../lib/utils';
import '../../routes/vendor/catalogVendor.css';
import { CatalogVendorContext } from '../../routes/vendor/context';
import StatusButton from '../statusButton';
import './availableReports.css';
import CatalogReportGenericInfo from './catalog/catalogReportGenericInfo';
import { assessmentTypes } from '../../constants/assessmentTypes';

const otherReportsBtn = {
  display: 'block',
  margin: '1em auto',
  color: 'white',
  fontSize: '1rem',
  fontWeight: '700',
};

const disabledPurchaseText = 'You do not have the permissions to order reports. Please contact your system admin.';

export const AvailableReports = (props) => {
  //Context
  const { availableReports, reportDescriptions, historicalDataReports } = useContext(CatalogVendorContext);

  //Props
  const {
    canPurchaseReports,
    vendorSharesVSCA,
    setShowModal,
    handlePurchaseButtonClick,
    history,
    handleReportDateChange,
  } = props;

  //State
  const [state, setState] = useUpdateState({
    isBusy: true,
    model: [],
  });

  //Effects
  React.useEffect(() => {
    const availableReportsList = availableReports.map((a) => ({ ...a }));
    const foundDDRR = availableReportsList.find((i) => i.rawType == assessmentTypes.DDRR.name);
    if (!foundDDRR) {
      const foundNameRow = findObjectByInnerKeyValue(reportDescriptions, 'rawType', assessmentTypes.DDRR.name);
      availableReportsList.push({
        completedDate: new Date().toISOString(),
        hasAccess: false,
        type: foundNameRow ? foundNameRow.name : assessmentTypes.DDRR.name,
      });
    }

    // map thru testData to get a button of each type (uncomment import too)
    let shareParents = availableReportsList.filter((r) => r.parent_id).map((r) => r.parent_id);
    let modelData = availableReportsList
      .filter((r) => !shareParents.includes(r.id))
      .map((r) => {
        const isComplete = r.completedDate === null ? false : true;
        const purchaseDate = r.purchaseDate ? new Date(r.purchaseDate).toDateString() : '';
        const hasReportDate =
          r.hasHistoricalData && r.status == 'available' && r.availableDates && r.availableDates.length;
        const hasErrorDate =
          r.availableDates && r.availableDates.length && r.availableDates[0].completedDate == 'Error' ? true : false;
        const assessmentInfo = {
          type: r.type,
          isComplete,
          reportTypeID: r.typeID,
          reportRawType: r.rawType,
          vendorDomain: r.vendorDomain,
          vendorName: r.vendor,
          vendorID: r.vendorID,
          assessmentRequestID: r.id,
          hasAccess: r.hasAccess,
          hasHistoricalData: r.hasHistoricalData,
          reportDate: historicalDataReports[r.typeID] ? historicalDataReports[r.typeID].value : undefined,
        };
        let completionDate = '';
        if ((r.typeID !== assessmentTypes.A2VQA.id && r.typeID !== assessmentTypes.A2VQA_S.id) || vendorSharesVSCA) {
          if (hasErrorDate) completionDate = 'Error: Please contact your sales representative';
          else {
            completionDate = hasReportDate ? (
              <Select
                id={'report-date-dropdown-' + r.id}
                name={r.typeID} // The name is used in the onChange event handler to recognize the report type.
                value={historicalDataReports[r.typeID]}
                onChange={handleReportDateChange}
                options={r.availableDates}
              />
            ) : r.completedDate ? (
              new Date(r.completedDate).toDateString()
            ) : (
              ''
            );
          }
        }

        const status =
          !vendorSharesVSCA && r.typeID === assessmentTypes.A2VQA_S.id
            ? ''
            : StatusButton({
                assessmentInfo,
                handlePurchaseButtonClick: canPurchaseReports ? handlePurchaseButtonClick : () => {},
                canPurchaseReports,
                history,
                disabledPurchaseText,
              });

        return {
          reportType: (
            <CatalogReportGenericInfo
              title={r.type}
              reportDescriptions={reportDescriptions}
              bold
              purchaseDate={purchaseDate}
            />
          ),
          completionDate,
          status: status,
        };
      });

    setState({
      isBusy: !(modelData.length > 0),
      model: modelData,
    });
  }, [JSON.stringify(availableReports), JSON.stringify(reportDescriptions), JSON.stringify(historicalDataReports)]);

  //Render
  return (
    <div id="available-reports">
      <div className="row px-3">
        <div className="col-12">
          <div className="card">
            <div>
              <Table
                columns={[
                  { label: 'Report Type', source: 'reportType' },
                  { label: 'Completion Date', source: 'completionDate' },
                  { label: 'Status', source: 'status' },
                ]}
                data={state.model}
                isBusy={state.isBusy}
              />
            </div>
          </div>
          <button
            disabled={!canPurchaseReports}
            onClick={() => setShowModal(true)}
            className="btn statusBtnBase statusGreen"
            style={otherReportsBtn}
            title={!canPurchaseReports ? disabledPurchaseText : null}
            pointerEvents="auto"
          >
            Order Other Reports
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AvailableReports);
