import React, { Component } from 'react';

import TileComponent from '../components/tileComponent';
import ReusableProvenanceTable from './reusableProvenanceTable';
import SeverityCircle from './../../../reusable/severityCircle';
import { scrollToSection } from '../../../../../constants/postern/scrollToSection.js';

import { generateSymbol, generateColor } from '../../../../../constants/postern/riskIconGenerator.js';

import './cyberHygiene.css';

export default class CyberHygiene extends Component {
  render() {
    const { cyberHygiene = {}, cyberHygiene_transport = {}, cyberHygieneSummary = {} } = this.props;
    const chKeys = Object.keys(cyberHygiene);
    const chValues = Object.values(cyberHygiene);
    const chTransportKeys = Object.keys(cyberHygiene_transport);
    const chTransportValues = Object.values(cyberHygiene_transport);
    const domainSecurityTests = ['dnssec', 'nsec', 'dns_encryption', 'wildcards', 'spf', 'dmarc'];
    const applicationSecurityTests = ['xss', 'csp', 'cto', 'sts', 'xfo', 'pkp'];
    const transportSecurityTests = ['sslv2', 'sslv3', 'tlsv1_0', 'tlsv1_1', 'tlsv1_2'];

    const getTlsV12Result = (val) => {
      switch (val) {
        case true:
          return 'Enabled';
        case false:
          return 'Disabled';
        default:
          return 'Not Evaluated';
      }
    };

    return (
      <>
        <p>
          For more information on cyber hygiene tests and scan data, please reference the{' '}
          <a
            className="navLink configurable-link"
            href={`provenance-appendix-cyber-hygiene`}
            onClick={(e) => scrollToSection(e, `provenance-appendix-cyber-hygiene`)}
          >
            appendix
          </a>
          .
        </p>
        <div className="provenance-tile-holder row" id="ch-category-tiles">
          {
            <>
              <TileComponent
                title={'Domain Security'}
                highlight={
                  cyberHygieneSummary.domain_security +
                  ' Finding'.concat(cyberHygieneSummary.domain_security != 1 ? 's' : '')
                }
                color={
                  cyberHygieneSummary.domain_security_colour == 0
                    ? 'var(--posternLowSeverity)'
                    : 'var(--posternHighSeverity)'
                }
              />
              <TileComponent
                title={'Transport Security'}
                highlight={
                  cyberHygieneSummary.transport_security +
                  ' Finding'.concat(cyberHygieneSummary.transport_security != 1 ? 's' : '')
                }
                color={
                  cyberHygieneSummary.transport_security == 0
                    ? 'var(--posternLowSeverity)'
                    : 'var(--posternHighSeverity)'
                }
              />
              <TileComponent
                title={'Application Security'}
                highlight={
                  cyberHygieneSummary.application_security +
                  ' Finding'.concat(cyberHygieneSummary.application_security != 1 ? 's' : '')
                }
                color={
                  cyberHygieneSummary.application_security == 0
                    ? 'var(--posternLowSeverity)'
                    : 'var(--posternHighSeverity)'
                }
              />
              <TileComponent
                title={'Vulnerabilities'}
                highlight={
                  cyberHygieneSummary.vulnerabilities +
                  ' Finding'.concat(cyberHygieneSummary.vulnerabilities != 1 ? 's' : '')
                }
                color={
                  cyberHygieneSummary.vulnerabilities == 0 ? 'var(--posternLowSeverity)' : 'var(--posternHighSeverity)'
                }
              />
            </>
          }
        </div>

        {Object.keys(cyberHygiene).length ? (
          <div id="ch-table">
            <ReusableProvenanceTable
              tableName="CyberHygieneTable-"
              headers={['', 'Test Performed', 'Result', 'Category']}
            >
              {chKeys.map((key, i) => {
                return (
                  <React.Fragment key={'CyberHygieneTable-' + i}>
                    <tr className="provenance-table-row">
                      <th className="row-padding" scope="key">
                        <SeverityCircle
                          symbol={generateSymbol(
                            (chValues[i] === false && key !== 'wildcards') ||
                              (chValues[i] === true && key === 'wildcards'),
                          )}
                          color={generateColor(
                            (chValues[i] === false && key !== 'wildcards') ||
                              (chValues[i] === true && key === 'wildcards'),
                          )}
                        />
                      </th>
                      <td> {key} </td>
                      <td>
                        {' '}
                        {chValues[i] === false ? 'Disabled' : chValues[i] === null ? 'Not Evaluated' : 'Enabled'}{' '}
                      </td>
                      <td>
                        {' '}
                        {domainSecurityTests.includes(key)
                          ? 'Domain Security'
                          : applicationSecurityTests.includes(key)
                          ? 'Application Security'
                          : transportSecurityTests.includes(key)
                          ? 'Transport Security'
                          : ''}{' '}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              {chTransportKeys.map((key, i) => {
                if (cyberHygieneSummary.transport_list.includes(key)) {
                  return (
                    <React.Fragment key={'CyberHygieneTableA-' + i}>
                      <tr className="provenance-table-row">
                        <th className="row-padding" scope="key">
                          <SeverityCircle
                            symbol={generateSymbol(chTransportValues[i])}
                            color={generateColor(chTransportValues[i])}
                          />
                        </th>
                        <td> {key.replaceAll('_', '.')} </td>
                        <td>
                          {' '}
                          {chTransportValues[i] === false
                            ? 'Disabled'
                            : chTransportValues[i] === null
                            ? 'Not Evaluated'
                            : 'Enabled'}{' '}
                        </td>
                        <td>
                          {' '}
                          {domainSecurityTests.includes(key)
                            ? 'Domain Security'
                            : applicationSecurityTests.includes(key)
                            ? 'Application Security'
                            : transportSecurityTests.includes(key)
                            ? 'Transport Security'
                            : ''}{' '}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
              })}
              {
                <React.Fragment key={'tlsv1_2'}>
                  <tr className="provenance-table-row">
                    <th className="row-padding">
                      <SeverityCircle
                        symbol={generateSymbol(
                          cyberHygiene_transport.tlsv1_2 === true || cyberHygiene_transport.tlsv1_2 === null
                            ? false
                            : true,
                        )}
                        color={generateColor(
                          cyberHygiene_transport.tlsv1_2 === true || cyberHygiene_transport.tlsv1_2 === null
                            ? false
                            : true,
                        )}
                      />
                    </th>
                    <td> tlsv1.2 </td>
                    <td> {getTlsV12Result(cyberHygiene_transport.tlsv1_2)} </td>
                    <td>
                      {' '}
                      {domainSecurityTests.includes('tlsv1_2')
                        ? 'Domain Security'
                        : applicationSecurityTests.includes('tlsv1_2')
                        ? 'Application Security'
                        : transportSecurityTests.includes('tlsv1_2')
                        ? 'Transport Security'
                        : ''}{' '}
                    </td>
                  </tr>
                </React.Fragment>
              }
            </ReusableProvenanceTable>
          </div>
        ) : null}

        <footer className="footer-p-wrapper">
          <p className="footer-multi-p">
            <a
              className="grey_link"
              href={'#provenance-appendix-cyber-hygiene'}
              onClick={(e) => scrollToSection(e, 'provenance-appendix-cyber-hygiene')}
            >
              Counts listed are a max aggregate of each best security practice being assessed. Thus, 3 issues would
              indicate that 3 best-practices are not being followed by at least one port/protocol/website of the vendor.
            </a>
          </p>
          <p className="footer-multi-p">
            <a
              className="grey_link"
              href={'#provenance-appendix-cyber-hygiene'}
              onClick={(e) => scrollToSection(e, 'provenance-appendix-cyber-hygiene')}
            >
              Full details and description of findings can be found in the appendix.
            </a>
          </p>
        </footer>
      </>
    );
  }
}
