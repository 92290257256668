import React from 'react';

import config from '../../config';

import { Link } from 'react-router-dom';

import ReactTable from 'react-table';

import { textColumnFilter, numericColumnFilter } from '../../components/reactTable';

import { Tooltip } from '../../tooltips.js';

import Chart from 'chart.js';

class PortfoliosRisk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      barChart: {},
      params: this.props.match.params,
      isTableLoaded: false,
    };
  }

  componentDidMount() {
    this.doChart();
  }

  componentDidUpdate(previosProps) {
    const { barChart } = this.state;

    setTimeout(() => {
      const chartObj = new Chart(barChart.id, barChart.options);
      barChart.chartObj = chartObj;
      return chartObj && 1;
    }, 100);
  }

  doChart() {
    const barChart = {
      id: 'barChart',
      icon: 'fas fa-chart-bar',
      label: 'Portfolios by Risk',
      height: '200',
      onClick: (props) => {
        if (props[0]) {
          var chartData = props[0]['_chart'].config.data;
          var idx = props[0]['_index'];

          var label = chartData.labels[idx];
          var value = chartData.datasets[0].data[idx];

          this.setState({ params: { risk: label, value: value } });
        }
      },
    };

    this.setState({ barChart: barChart });

    this.barChart();
  }

  barChart() {
    const _self = this;

    let options = {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Marginal',
            backgroundColor: '#357ABD',
            borderColor: '#357ABD',
            data: [],
          },
          {
            label: 'Low',
            backgroundColor: '#4CAE4C',
            borderColor: '#4CAE4C',
            data: [],
          },
          {
            label: 'Moderate',
            backgroundColor: '#FDC431',
            borderColor: '#FDC431',
            data: [],
          },
          {
            label: 'High',
            backgroundColor: '#EE9336',
            borderColor: '#EE9336',
            data: [],
          },
          {
            label: 'Critical',
            backgroundColor: '#D43F3A',
            borderColor: '#D43F3A',
            data: [],
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 6,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                max: 14000,
                maxTicksLimit: 5,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
      },
    };

    const getPortfolioNames = (options) => {
      fetch(config.api.urlFor('portfolioNames'))
        .then((response) => response.json())
        .then((data) => {
          options.data.labels = data;
          getProviderCountsByRiskAndPortfolio(options);
        });
    };

    const getProviderCountsByRiskAndPortfolio = (options) => {
      fetch(config.api.urlFor('providerCountsByRiskAndPortfolio'))
        .then((response) => response.json())
        .then((data) => {
          const { barChart } = this.state;

          options.data.datasets = options.data.datasets.map((set, i) => {
            const setLabel = set.label.toLowerCase();
            const setData = [];

            options.data.labels.forEach((label) => {
              const labelData = data.find((i) => i.portfolio.indexOf(label) > -1);

              setData.push(labelData[setLabel]);
            });

            set.data = setData;
            return set;
          });

          let maxY = 0,
            dataLength = options.data.datasets[0].data.length;
          for (let i = 0; i < dataLength; i++) {
            let tempY = 0;

            options.data.datasets.forEach((set) => {
              tempY += Number.parseInt(set.data[i]);
            });

            maxY = maxY >= tempY ? maxY : tempY;
          }

          options.options.scales.yAxes[0].ticks.max = Math.ceil(maxY * 1.15);

          barChart.options = options;

          _self.setState({ barChart: barChart });
        })
        .catch((err) => console.log(err.stack));
    };

    getPortfolioNames(options);
  }

  doTable(page, filter, sort) {
    const _self = this;

    page = parseInt(page, 10, 1);
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];

    _self.setState({ tableIsBusy: true });

    let url = config.api.urlFor('providerScores', { page: page });

    if (filter) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const providers = data.rows.map((provider) => {
          return {
            name: <Link to={`/reports/provider/${provider.id}`}>{provider.name}</Link>,
            portfolio: provider.parent
              ? `4th Party of ${provider.parent}`
              : <Link to={`/portfolios/${provider.portfolio}`}>{provider.portfolio}</Link> || '-',
            risk: provider.avg_risk_score || '-',
            dns: provider.avg_dns_score || '-',
            // doppelgangers    : provider.doppelgangers,
            // dns_security     : provider.avg_dns_security,
            web_app: provider.avg_web_app_score || '-',
            auth_security: provider.avg_auth_security || '-',
            session_security: provider.avg_session_security || '-',
            // web_vulns        : provider.avg_web_vulns,
            // host_vuln_score  : provider.avg_host_vuln_score,
            // platform_score   : provider.avg_service_score,
            // service_score    : provider.avg_service_score,
            ssl: provider.avg_ssl_score || '-',
            cipher: provider.avg_cipher_score || '-',
          };
        });

        _self.setState({
          providers,
          pages: Math.ceil(data.totalRecords / 10),
          tableIsBusy: false,
          isTableLoaded: true,
        });
      })
      .catch((err) => _self.setState({ tableIsBusy: false }));
  }

  portfolioFilter = ({ filter, onChange }) => {
    const { params, isTableLoaded } = this.state;

    if (!params.risk) {
      return textColumnFilter({ filter, onChange });
    }

    var input = document.querySelector('#portfolioInput');
    var select = document.querySelector('#portfolioSelect');

    if (isTableLoaded && select && parseInt(select.value) !== '=') {
      const nativeSelectValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
      nativeSelectValueSetter.call(select, '=');

      const ev1 = new Event('input', { bubbles: true });
      select.dispatchEvent(ev1);
    }

    if (isTableLoaded && input && parseInt(input.value) !== params.risk) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      nativeInputValueSetter.call(input, params.risk);

      const ev2 = new Event('input', { bubbles: true });
      input.dispatchEvent(ev2);
    }

    return (
      <div>
        <select
          id="portfolioSelect"
          onChange={() => onChange({ value: input ? input.value : '', comparator: select ? select.value : '=' })}
          disabled
        >
          <option>=</option>
          <option>&gt;</option>
          <option>&lt;</option>
        </select>
        <input
          id="portfolioInput"
          type="text"
          onChange={() => onChange({ value: input ? input.value : '', comparator: select ? select.value : '=' })}
          disabled
        />
      </div>
    );
  };

  render() {
    const { barChart } = this.state;
    let { providers, pages } = this.state;

    const content = (
      <React.Fragment>
        <h1>Portfolio Risk</h1>
        <div className="row">
          <div className="col-4">
            <div className="card mb-3">
              <div className="card-header">
                <i className={`${barChart.icon} mr-1`}></i>
                {barChart.label}
              </div>
              <div className="card-body text-center align-middle">
                <canvas
                  id={barChart.id}
                  height={barChart.height}
                  style={{ display: barChart.options === undefined ? 'none' : 'block' }}
                  onClick={(event) => {
                    barChart.onClick && barChart.onClick(barChart.chartObj.getElementsAtEvent(event));
                  }}
                ></canvas>
                {barChart.options === undefined && <i className="fas fa-circle-notch fa-spin fa-5x"></i>}
              </div>
              <div className="card-footer small text-muted"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card o-hidden h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <ReactTable
                      manual
                      columns={[
                        { Header: 'Name', accessor: 'name', minWidth: 200, Filter: textColumnFilter },
                        { Header: 'Portfolio', accessor: 'portfolio', Filter: this.portfolioFilter },
                        {
                          Header: <Tooltip message="Cyber Risk Total">Risk</Tooltip>,
                          accessor: 'risk',
                          Cell: ({ row }) => <div className="text-center">{row.risk}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="DNS">DNS</Tooltip>,
                          accessor: 'dns',
                          Cell: ({ row }) => <div className="text-center">{row.dns}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="App Sec">App Sec</Tooltip>,
                          accessor: 'web_app',
                          Cell: ({ row }) => <div className="text-center">{row.web_app}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="Auth">Auth</Tooltip>,
                          accessor: 'auth_security',
                          Cell: ({ row }) => <div className="text-center">{row.auth_security}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="Session">Session</Tooltip>,
                          accessor: 'session_security',
                          Cell: ({ row }) => <div className="text-center">{row.session_security}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="SSL">SSL</Tooltip>,
                          accessor: 'ssl',
                          Cell: ({ row }) => <div className="text-center">{row.ssl}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="Ciphers">Cipher</Tooltip>,
                          accessor: 'cipher',
                          Cell: ({ row }) => <div className="text-center">{row.cipher}</div>,
                          Filter: numericColumnFilter,
                        },
                      ]}
                      multiSort={false}
                      showPageSizeOptions={false}
                      filterable
                      data={providers}
                      defaultSorted={[{ id: 'name', desc: false }]}
                      pageSize={0}
                      pages={pages}
                      onFetchData={(state) => this.doTable(state.page + 1, state.filtered, state.sorted)}
                      loading={this.state.tableIsBusy}
                      className="-striped -highlight hide-arrows"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return content;
  }
}

export default PortfoliosRisk;
