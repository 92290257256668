import React from 'react';
import { withRouter } from 'react-router-dom';
import './layout.css';

import pathToRegexp from 'path-to-regexp';

import { LayoutBase } from '../';
import withPrincipal from '../withPrincipal';
import { withEvents, events } from '../withEvents';
import { adminOptions } from './adminOptions';

import config from '../../config';

import NavItem from './navItem';

const defaultNavOptions = [
  { label: config.routes.home.label, to: config.routes.home.to },
  { label: config.nav.network.label, to: config.nav.network.to },
  { label: config.nav.products.label, to: config.nav.products.to },
];

const optionalNavOptions = [
  { label: config.routes.trustCenter.label, to: config.routes.trustCenter.to },
  { label: config.nav.contactUs.label, to: config.nav.contactUs.to },
];

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // lookerUrls: [],
      options: [],
      active: [],
    };
  }

  async componentDidMount() {
    // await this.getLookerReports();
    this.props.events.emit(events.ALERTS_UPDATED);

    this.setOptions();
    this.setActive(this.state.options);
    this.setTitle();
  }

  componentDidUpdate(prevProps) {
    this.setTitle();
    if (this.props.location !== prevProps.location) {
      this.setActive(this.state.options);
      this.setHistory();
    }
  }

  // getLookerReports() {
  //   return fetch(config.api.urlFor('lookerList'))
  //     .then((response) => response.json())
  //     .then((lookerUrls) => {
  //       this.setState({ lookerUrls });
  //       console.log("lookerUrls = ", lookerUrls)
  //     });
  // }

  setOptions() {
    const { principal, showOnlyTrustCenterContent } = this.props;

    const newNavOptions = !!showOnlyTrustCenterContent
      ? [...optionalNavOptions]
      : [...defaultNavOptions, ...optionalNavOptions];
    const contactIndex = newNavOptions.findIndex((item) => item.label === config.nav.contactUs.label);
    const contactItem = contactIndex >= 0 ? newNavOptions.splice(contactIndex, 1)[0] : undefined;

    const trustCenterIndex = newNavOptions.findIndex((item) => item.label === config.routes.trustCenter.label);
    const trustCenterItem = trustCenterIndex >= 0 ? newNavOptions.splice(trustCenterIndex, 1)[0] : undefined;

    const options =
      principal && !principal.isProvider ? newNavOptions.concat([adminOptions(principal)]).filter(Boolean) : [];

    if (!!showOnlyTrustCenterContent) {
      if (trustCenterItem && principal.isTrustCenterEnabled) options.push(trustCenterItem);
      if (contactItem) options.push(contactItem);
    } else {
      if (contactItem) options.push(contactItem);
      if (trustCenterItem && principal.isTrustCenterEnabled) options.push(trustCenterItem);
    }

    this.setState({ options: options.filter(Boolean) });
  }

  setActive(options) {
    const { location } = this.props;

    let active = options.map((option) => {
      // special rules for home page, since it woud always match otherwise
      if (option.to === '/') {
        return location.pathname === '/';
      } else if (!option.to.map) {
        return location.pathname.indexOf(option.to) === 0;
      } else {
        return option.to.map((opt) => location.pathname.indexOf(opt.to) === 0).includes(true);
      }
    });
    this.setState({ active });
  }

  setTitle() {
    const { location } = this.props;
    let title = this.getLabel(location.pathname);
    title = title === 'Catalog' ? 'Network' : title;
    document.title = title ? title + ' | A2V: Asset to Vendor Network' : 'A2V: Asset to Vendor Network';
  }

  getLabel(url) {
    let regexp = null,
      result = null;

    for (let [key, value] of Object.entries(config.routes)) {
      regexp = pathToRegexp(value.to);

      result = regexp.exec(url);
      if (result) {
        return config.routes[key].label;
      }
    }

    return null;
  }

  setHistory() {
    const { location } = this.props;

    let breadcrumbs = window.$session.getState('breadcrumbs') || [];

    breadcrumbs.push(location.pathname);

    let rootIndex = this.getRootIndex(breadcrumbs);

    breadcrumbs = rootIndex ? breadcrumbs.slice(rootIndex, breadcrumbs.length) : breadcrumbs;

    // This handles trimming the end of the list if a user navigates to a previous screen
    let lastIndex = breadcrumbs.indexOf(breadcrumbs[breadcrumbs.length - 1]);
    breadcrumbs = lastIndex < breadcrumbs.length - 1 ? breadcrumbs.slice(0, lastIndex + 1) : breadcrumbs;

    window.$session.setState({ breadcrumbs });

    this.setState({ breadcrumbs });
  }

  // This monstrosity is looking for the last entry in our breadcrumbs that matches a menu item so we can build the breadcrumbs from a consistents starting point
  getRootIndex(breadcrumbs) {
    const { options } = this.state;

    let roots = [];

    options.forEach((option) => {
      if (!option.to.map) {
        roots.push(option.to);
      } else {
        option.to.forEach((subOption) => {
          roots.push(subOption.to);
        });
      }
    });

    let regexp = null,
      result = null,
      index = null;
    for (let j = roots.length - 1; j > 0; j--) {
      regexp = pathToRegexp(roots[j]);

      for (let i = breadcrumbs.length - 1; i > 0; i--) {
        result = regexp.exec(breadcrumbs[i]);
        if (result !== null) {
          index = i;
          break;
        }
      }

      if (result !== null) {
        break;
      }
    }

    return index;
  }

  render() {
    const { principal, content, children } = this.props;
    const { options, active } = this.state;

    return (
      <>
        <LayoutBase>
          {principal && !principal.isProvider && (
            <React.Fragment>
              <nav id="dl-menu" className="navbar navbar-expand-lg p-0 dl-menuwrapper" style={{ color: 'white' }}>
                <span
                  className="navbar-toggler dl-trigger"
                  data-toggle="collapse"
                  data-target="#navbarCollapse"
                  aria-label="toggle navigation"
                >
                  <i className="fa fa-bars"></i>
                </span>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="nav dl-menu">
                    {options &&
                      options.map((option, i) => {
                        return <NavItem key={i} {...option} active={active && active[i]} />;
                      })}
                  </ul>
                </div>
              </nav>
            </React.Fragment>
          )}
        </LayoutBase>
        <div className="row">
          <div className="col-12">{content || children}</div>
        </div>
      </>
    );
  }
}

export default withEvents(withPrincipal(withRouter(Layout)));
