import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import DashboardTile from './dashboardTile';

export default class DashboardTileGrouping extends Component {
  render() {
    const { values = [], labels = [], displayTokens } = this.props;
    for (let i = 0; i < values.length; i++) {
      values[i] =
        values[i] === null ? (
          <div style={{ marginBottom: '10px', marginTop: '15px' }}>
            <Spinner
              animation="border"
              role="status"
              variant="dark"
              style={{ height: '1.5rem', width: '1.5rem' }}
            ></Spinner>
          </div>
        ) : values[i] === undefined ? (
          'ERROR'
        ) : (
          values[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
    }

    return (
      // only one of these dtg divs will display, depending on screen size
      <>
        <div className="dtg-large">
          <DashboardTile val={values[0]} text={labels[0]} />
          <DashboardTile val={values[1]} text={labels[1]} />
          {displayTokens && <DashboardTile val={values[2]} text={labels[2]} />}
        </div>

        <div className="dtg-medium">
          <div className="dtg-sub-medium">
            <DashboardTile val={values[0]} text={labels[0]} />
          </div>
          <div className="dtg-sub-medium">
            <DashboardTile val={values[1]} text={labels[1]} />
            {displayTokens && <DashboardTile val={values[2]} text={labels[2]} />}
          </div>
        </div>

        <div className="dtg-small">
          <DashboardTile val={values[0]} text={labels[0]} />
          <DashboardTile val={values[1]} text={labels[1]} />
          {displayTokens && <DashboardTile val={values[2]} text={labels[2]} />}
        </div>
      </>
    );
  }
}
