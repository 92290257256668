import React from 'react';
import Modal from 'react-modal';

import Spinner from './spinner';

import { useUpdateState } from '.';

const ModalConfirmation = (props) => {
  const [state, setState] = useUpdateState({
    yesContext: true,
  });

  const { isBusy, isOpen, confirmationText, onClickYes, onClickNo } = props;
  const styleModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const onYes = () => {
    setState({ yesContext: true });

    onClickYes && onClickYes();
  };

  const onNo = () => {
    setState({ yesContext: false });

    onClickNo && onClickNo();
  };

  const { yesContext } = state;

  return (
    <Modal style={styleModal} isOpen={isOpen} onRequestClose={onClickNo} ariaHideApp={false}>
      <div className="modal-header">
        <h5 className="pt-1 modal-title" style={{ color: 'black' }}>
          {confirmationText}
        </h5>
      </div>
      <div className="row" style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <button className="btn btn-light mr-3" onClick={onYes} disabled={isBusy}>
          Yes <Spinner isBusy={yesContext && isBusy} />
        </button>
        <button className="btn btn-danger" onClick={onNo} disabled={isBusy}>
          No <Spinner isBusy={!yesContext && isBusy} />
        </button>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;
