import React from 'react';
import { Link } from 'react-router-dom';

import Flex from '../../components/flex';
import { NAVBLUE } from '../../constants/color';

export default (props) => {
  return (
    <Flex width="100vw" height="60vh" justifyContent="center" alignItems="center">
      <div>
        <Flex justifyContent="center">
          <img alt="Fortress Logo" src="/static/images/Fortress_Square.png" />
        </Flex>
        <div className="text-center py-3" style={{ fontSize: '16px' }}>
          Sorry, something is broken or a page was not found
        </div>
        <Flex className="text-center" justifyContent="center">
          <Link className="btn btn-dark" role="button" style={{ backgroundColor: NAVBLUE }} to="/help/contact">
            Contact
          </Link>
          <Link
            className="btn btn-dark mx-1"
            role="button"
            style={{ backgroundColor: NAVBLUE }}
            to="/help/requestSupport"
          >
            Support
          </Link>
        </Flex>
      </div>
    </Flex>
  );
};
