import React from 'react';

class ChipAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: props.alerts,
      isError: props.isError,
    };

    this.removeChip = this.removeChip.bind(this);
  }

  removeChip(e) {
    if (this.props.onDelete) this.props.onDelete(e);
  }

  render() {
    const { alerts = [], isError, style } = this.props;

    return (
      <React.Fragment>
        {alerts.map((alert, i) => {
          return (
            <div key={i} className={`alert alert-${isError ? 'danger' : 'success'}`} style={style}>
              {alert}
              <button type="button" className="close" onClick={this.removeChip}>
                <i className="fa fa-times"></i>
              </button>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default ChipAlert;
