export const subscriberTypeIDToName = (stID, subTypesList) => {
  const typeObject = subTypesList.find(({ id } = {}) => id === stID);
  return typeObject?.name;
};

export const subscriberTypeNameToID = (stName, subTypesList) => {
  if (!stName || stName === '-') return null;
  const typeObject = subTypesList.find(({ name } = {}) => name === stName);
  return typeObject?.id;
};

export default { subscriberTypeIDToName, subscriberTypeNameToID };
