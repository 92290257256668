import config from '../../config';

export const getAllAssetsByProviderId = async (id) => {
  let data = await fetch(config.api.urlFor('providersAllAssets', { id }));
  data = await data.json();
  return data.data;
};

export const getTargetGroupsByProviderId = async (id) => {
  let data = await fetch(config.api.urlFor('targetGroupsProviders', { id }));
  data = await data.json();
  return data.data;
};

export const addTargetGroup = async (group) => {
  let data = await fetch(config.api.urlFor('targetGroups'), {
    method: 'POST',
    body: group,
  });
  data = await data.json();
  return data;
};

export const addTargetGroupToProvider = async (group) => {
  await fetch(config.api.urlFor('targetGroupsProvider'), {
    method: 'POST',
    body: group,
  });
};

export const deleteTargetGroup = async (id) => {
  await fetch(config.api.urlFor('targetGroupsDelete', { id }));
};

export const addAssetsToTargetGroup = async (assets, targetGroupId) => {
  await fetch(config.api.urlFor('targetGroupsAsset'), {
    method: 'POST',
    body: {
      assets,
      targetGroupId,
    },
  });
};

export const getAssetsAssociatedToTargetGroup = async (id) => {
  let data = await fetch(config.api.urlFor('targetGroupsAssets', { id }));
  data = await data.json();
  return data.data;
};
