import React from 'react';
import config from './config';
import { toastError } from './lib/utils';
import { roleNames, guestSubscription } from './constants/userRoles';

window.mapPath = function (path = '', values = {}) {
  if (!values) {
    return path;
  }

  if (values.push && values.unshift) {
    for (let i = 0; i < values.length; i++) {
      path = path.replace(/:\w*/, values[i]);
    }

    return path;
  }

  const qsp = [];
  for (let i in values) {
    if (path.indexOf(`:${i}`) < 0 && values[i] !== undefined) {
      qsp.push(i);
    }
  }

  for (let i in values) {
    path = path.replace(`:${i}`, values[i]);
  }

  if (qsp.length) {
    path = path + '?' + qsp.map((q) => `${q}=${values[q]}`).join('&');
  }

  return path;
};

const _wrapStorageContainer = (storage) => ({
  getState: (key) => {
    const val = storage.getItem(key);
    return val ? JSON.parse(val) : null;
  },
  setState: (state) => {
    for (let i in state) {
      let val = state[i];

      if (val === undefined) {
        storage.removeItem(i);
        continue;
      }

      storage.setItem(i, JSON.stringify(val));
    }
  },
  cache: {},
});

window.$app = _wrapStorageContainer(window.localStorage);
window.$session = _wrapStorageContainer(window.sessionStorage);

window.$refreshPrincipal = async () => {
  const res = await fetch(config.api.urlFor('principal'));
  const { user: principal, token } = await res.json();

  window.$app.setState({ principal, token });
};

let _fetch = window.fetch.bind(window);
window.fetch = function (url, options) {
  options = options || {};
  const defaults = config.excludeCredentialsFetchPaths.indexOf(url) > -1 ? {} : { credentials: 'include' };

  const combined = {
    ...defaults,
    ...options,
  };

  combined.timeout = combined.timeout !== undefined ? combined.timeout : config.fetchTimeout;
  combined.headers = combined.headers || new Headers();
  const token = window.$app.getState('token');

  if (token && combined.headers.has && !combined.headers.has('Authorization')) {
    combined.headers.append('Authorization', `Bearer ${token}`);
  }

  const isNotFormData = !(combined.body instanceof FormData);
  const isNotURLSearchParams = !(combined.body instanceof URLSearchParams);
  const hasObjectBody = typeof combined.body === 'object';
  const isMissingContentTypeHeader = !combined.headers['Content-Type'];

  if (isNotFormData && isNotURLSearchParams && hasObjectBody && isMissingContentTypeHeader) {
    combined.body = JSON.stringify(combined.body, null, 2);
    combined.headers.append('Content-Type', 'application/json; charset=UTF-8');
  }

  if (!combined.timeout) {
    return _fetch(url, combined);
  } else {
    let controller = new AbortController();

    combined.signal = controller.signal;

    return Promise.race([
      _fetch(url, combined),
      new Promise((_, reject) => setTimeout(() => controller.abort() & reject(new Error('timeout')), combined.timeout)),
    ]);
  }
};

/**
 * @function
 * @description - Takes the fetch being sent from client and intercepts return. If return is 401 removes
 *                 all principal and token data and redirect to login.
 * @returns - If 401 redirects to home page.
 *            If 403 and guest user/user with Guest subscription - shows error toast message and returns fetch data.
 *            Else returns fetch data.
 */

const handle403Response = () => {
  const principal = window.$app.getState('principal');
  const isGuestUser =
    principal.subscription === guestSubscription || principal.roles.find((r) => r.name === roleNames.GUEST_USER);

  const contactSupportDomain =
    config.contactSupport && config.contactSupport.domain && config.contactSupport.domain + '/contact';

  if (isGuestUser) {
    const toastMessage = (
      <div>
        Guests do not have access to this functionality.
        <br />
        Join the A2V Network to gain access!
        <br />
        Please contact support for assistance
        {!!contactSupportDomain && (
          <>
            <br />
            <a href={contactSupportDomain} target={contactSupportDomain}>
              Fortress Information Security
            </a>
          </>
        )}
        .
      </div>
    );

    const toastOptions = { toastId: 'error-guest-user', autoClose: 7000 };
    toastError(toastMessage, toastOptions);
  }
};

(function () {
  var originalFetch = _fetch;
  //Intercept our Fetch
  _fetch = function () {
    //access fetch data by appending our own .then in the promise tree
    return originalFetch.apply(this, arguments).then(function (responseData) {
      // if response is 401 remove principal and token from local storage redirect to home page
      if (responseData.status == 401) {
        window.$app.setState({ principal: undefined, token: undefined, lastSuccessfulLogon: undefined, logout: false });
        window.location = '/';
      } else {
        // if response is 403 and the user is with a guest role or has a guest subscription show error message
        if (responseData.status == 403) {
          handle403Response();
        }
        return responseData;
      }
    });
  };
})();

const { parseInt: _parseInt, parseFloat: _parseFloat } = Number;

window.parseInt = Number.parseInt = function (string, radix = 10, defaultVal) {
  const val = _parseInt(string, radix);

  return this.isNaN(val) ? (defaultVal !== null && defaultVal !== undefined ? defaultVal : this.NaN) : val;
}.bind(Number);

window.parseFloat = Number.parseFloat = function (string, defaultVal) {
  const val = _parseFloat(string);

  return this.isNaN(val) ? (defaultVal !== null && defaultVal !== undefined ? defaultVal : this.NaN) : val;
}.bind(Number);
