import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const query = {};
  for (let [key, value] of params) {
    query[key] = value;
  }

  return query;
};

export default useQuery;
