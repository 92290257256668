import React from 'react';
import { withPrincipal } from '../../components';
import SectionLocked from '../sectionLocked';
import SectionSummary from '../sectionSummary';
import { config } from '../../config';

/** Functional component for displaying the Risk Rank summary section */
const RiskRankSection = (props) => {
  //Props
  const {
    vendorName,
    isUnlockedDDRR,
    ddrrScoresData = {},
    reportTypeDDRR,
    handleUnlockButtonClick,
    viewDashboardOnClick,
  } = props;

  const {
    averageScore,
    totalScore,
    physicalAccess,
    systemAccess,
    dataAccess,
    cloud,
    financial,
    fourthParty,
    offshore,
    reputation,
    strategic,
  } = ddrrScoresData;

  //Render
  return (
    <>
      <div className="section-main-title">Inherent Risk Tier</div>
      {isUnlockedDDRR ? (
        <SectionSummary
          showRankTile={true}
          avgScoreName="Risk Rank"
          avgScoreValue={averageScore}
          tierName="Risk Score"
          tierValue={parseInt(totalScore)}
          tierMaxValue={config.ddrrTierMaxValue}
          scoresData={[
            { label: 'Physical Access', value: physicalAccess },
            { label: 'System Access', value: systemAccess },
            { label: 'Data Access', value: dataAccess },
            { label: 'Cloud Risk', value: cloud },
            { label: 'Financial Risk', value: financial },
            { label: 'Fourth Party Risk', value: fourthParty },
            { label: 'Offshore Presence', value: offshore },
            { label: 'Reputational Risk', value: reputation },
            { label: 'Strategic Risk', value: strategic },
          ]}
          viewDashboardOnClick={viewDashboardOnClick}
          componentContext="ddrr"
        />
      ) : (
        <SectionLocked
          title="Unlock Risk Score"
          buttonCaption="Unlock Data-Driven Risk Rank"
          info={`View Risk Tier and Risk Score for ${vendorName} by purchasing a Data-Driven Risk Rank.`}
          details="The Data-Driven Risk Rank is used to classify a vendor's risk tier by examining risk accross 9 dimensions of a company:"
          bullets={[
            'System Access',
            'Cloud Security',
            'Data Access',
            'Financials',
            'Fourth-Party',
            'Offshore Presence',
            'Physical Access',
            'Reputation',
            'Strategic',
          ]}
          canUnlockSection={props.principal.roles.some((r) =>
            r.permissions.some((p) => p === 'subscriber.purchaseReports'),
          )}
          handleUnlockButtonClick={handleUnlockButtonClick}
          reportType={reportTypeDDRR}
        />
      )}
    </>
  );
};

export default withPrincipal(RiskRankSection);
