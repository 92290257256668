import React from 'react';
import { Icon } from '.';
import { riskRankByScore } from '../../constants';

const IconFormatter = (cell) => {
  const riskRank = isNaN(cell) ? cell : riskRankByScore(parseInt(cell));
  return (
    <React.Fragment>
      <Icon cell={riskRank} />
      {riskRank}
    </React.Fragment>
  );
};

export default IconFormatter;
