import React, { Component } from 'react';

class VendorInformation extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="vendor-info" className="section-header">
          <div id="company-name">DATA| data.vendor.companyName |DATA</div>
        </div>
        <div>
          <div className="text-navy">DATA| data.vendor.scoreIndustryName |DATA</div>
          <p>
            NAICS: DATA| data.vendor.naics |DATA DATA| data.vendor.naicsDescription |DATA
            <br />
            <small>
              <i>Note: actual scope of services consumed may differ from the company&apos;s primary NAICS code.</i>
            </small>
            <br /> Web URL:
            <a href="DATA|  data.vendor.domain  |DATA" target="blank_">
              DATA| data.vendor.domain |DATA
            </a>
            <br />
            <small>
              <i>Last reported: DATA| data.vendor.scoreDate | moment |DATA</i>
            </small>
          </p>
        </div>

        <div>
          <div className="section-header">Company Description</div>
          <p>DATA| data.vendor.companyDescription |DATA</p>
          <br />
          <table className="table table-bordered small white-bg center-text">
            <tbody>
              <tr>
                <th>
                  <b>Revenue</b>
                </th>
                <th>
                  <b>Employees</b>
                </th>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATata.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $D data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.annualrevenDATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vennue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.ann data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA|
                  data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>$DATA| data.vendor.revenue |DATA ualrevenue |DATA</td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.annualrenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.annualrevvenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue
                  |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
              <tr>
                <td>
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                  $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA $DATA| data.vendor.revenue |DATA
                </td>
                <td>DATA| data.vendor.employeeCount |DATA</td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default VendorInformation;
