import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

const WistiaEmbed = (props) => {
  const { id } = props;

  useEffect(() => {
    window._wq = window._wq || [];

    window._wq.push({
      id,
      options: {
        // PLAYBACK
        preload: true,
        muted: false,
        playsinline: false,
        autoPlay: true,
        silentAutoPlay: false,
        endVideoBehavior: 'reset',
        popover: true,

        // LAYOUT
        fitStrategy: 'cover',
        videoFoam: false,

        // TRACKING
        copyLinkAndThumbnailEnabled: false,
        doNotTrack: true,
        googleAnalytics: false,
        seo: false,

        // UI CONTROLS
        controlsVisibleOnLoad: true,
        fullscreenOnRotateToLandscape: true,
        fullscreenButton: true,
        settingsControl: true,
        qualityControl: true,
        playButton: true,
        playbar: true,
        playbackRateControl: false,
        smallPlayButton: true,
      },
    });
  }, []);

  return id ? (
    <>
      <Helmet>
        <script src={`https://fast.wistia.com/embed/medias/${id}.jsonp`} async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <div className={`wistia_embed wistia_async_${id} wistia-div`}></div>
    </>
  ) : (
    <>
      <p>
        <em>No video available.</em>
      </p>
    </>
  );
};

export default WistiaEmbed;
