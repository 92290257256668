import React from 'react';
import { Link } from 'react-router-dom';

import { Table, InviteButton, ProviderButton, Pagination } from '../../components';

import config from '../../config';

const styles = {
  buttonStyle: {
    paddingLeft: '30px',
  },
};

class ProviderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      model: [],
      isBusy: true,
      popovers: {},
      search: '',
      associations: [],
    };
  }

  componentDidMount() {
    const { search } = this.props.location.query;
    const { page } = this.props.match.params;

    this.getData(search, page, 10, 'name', 'asc');
    this.getAssociations();
  }

  componentDidUpdate(prevProps) {
    const { search: cSearch = '' } = this.props.location.query;
    const { page: cPage } = this.props.match.params;

    const { search: pSearch = '' } = prevProps.location.query;
    const { page: pPage } = prevProps.match.params;

    if (cPage === pPage && cSearch === pSearch) {
      return;
    }

    this.getData(cSearch, cPage, 10, 'name', 'asc');
  }

  getData(search, page, pageSize, orderBy, dir) {
    search = search || '';
    page = parseInt(page, 10, 1);
    pageSize = parseInt(pageSize, 10, 10);
    orderBy = orderBy || 'name';

    this.setState({ isBusy: true });

    let url = config.api.urlFor('providers') + `?page=${page}`;
    url = search ? url + `&search=${search}` : url;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          lastSearch: search,
          page,
          model: data.rows.map((row) => ({
            name: row.name,
            domain: row.website,
            portfolio: row.parent ? (
              `4th Party of ${row.parent}`
            ) : (
              <Link to={`/portfolios/${row.portfolio}`}>{row.portfolio}</Link>
            ),
          })),
          totalRecords: data.totalRecords,
          isBusy: false,
        });
      })
      .catch((err) => this.setState({ isBusy: false }));
  }

  getAssociations() {
    let url = config.api.urlFor('providers');
    url = url + `?pageSize=1000`;

    fetch(url, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          associations: data && data.rows ? data.rows.map((row) => ({ label: row.name, value: row.id })) : [],
        });
      });
  }

  render() {
    const { match, history, location } = this.props;
    const { page } = match.params;
    const { model, totalRecords } = this.state;

    let query = this.state.search ? `?search=${this.state.search}` : '';
    let url = query ? location.pathname + query : location.pathname;

    return (
      <div>
        <div className="card mb-3">
          <div className="card-header">
            <i className="fas fa-clipboard-list mr-1"></i>
            Providers
          </div>
          <div className="card-body">
            <nav className="navbar navbar-expand navbar-light bg-light mb-3">
              <div className="collapse navbar-collapse">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                  <li className="nav-item active">
                    <InviteButton
                      buttonTitle="Invite Provider"
                      associations={this.state.associations}
                      context="provider"
                      submitUrl={config.api.urlFor('inviteProvider')}
                    />
                  </li>
                  <li style={styles.buttonStyle} className="nav-item active">
                    <ProviderButton />
                  </li>
                </ul>
                <form className="form-inline" onSubmit={(e) => e.preventDefault() & history.push(url)}>
                  <input
                    id="search"
                    type="text"
                    className="form-control mr-2"
                    placeholder="search"
                    value={this.state.search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                  />
                </form>
              </div>
            </nav>
            <Table columns={['name', 'domain']} data={model} isBusy={this.state.isBusy} />
            <Pagination
              page={page}
              totalRecords={totalRecords}
              onPageChange={(page) => history.push(window.mapPath(match.path, { ...match.params, page }))}
            />
          </div>
          <div className="card-footer small text-muted"></div>
        </div>
      </div>
    );
  }
}

export default ProviderList;
