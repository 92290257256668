import React from 'react';
import { riskRankColorByTier, riskRankColorByGrade } from '../constants';
import './rankTile.css';

const RankTile = (props) => {
  const { caption, chRank, value } = props;
  const bgColor = isNaN(value) ? riskRankColorByGrade(value) : riskRankColorByTier(value);

  return chRank ? (
    <div className="rank-tile" style={{ backgroundColor: bgColor }}>
      <span className="rank-tile-value rank-tile-cyber-hygiene-grade">{value}</span>
    </div>
  ) : (
    <div className="rank-tile" style={{ backgroundColor: bgColor }}>
      {caption}
      <br />
      <span className="rank-tile-value">{value}</span>
    </div>
  );
};

export default RankTile;
