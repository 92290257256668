import React from 'react';

import { toast } from 'react-toastify';
import { withEvents, events } from './withEvents';

import { Spinner } from '../components';

import download from 'downloadjs';

class DownloadManager extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.events.on(events.DOWNLOAD_FILE, this.download.bind(this));
  }

  download(options) {
    const { url, filename, downloadCompletedCallback, callerID } = options;

    const handleCompletion = () => {
      typeof downloadCompletedCallback === 'function' && downloadCompletedCallback(callerID);
    };

    const toastID = toast.info(
      <React.Fragment>
        {`Downloading ${filename}. `}
        <Spinner className="ml-1" isVisible={true} />
      </React.Fragment>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      },
    );

    fetch(url)
      .then((res) => {
        if (res.status !== 200) {
          toast.update(toastID, { render: `Error: ${res.statusText}`, type: toast.TYPE.ERROR, autoClose: 3000 });
          return {
            then: () => {
              handleCompletion();
            },
          };
        }

        return res.blob();
      })
      .then((blob) => {
        toast.update(toastID, { render: 'Done!', type: toast.TYPE.SUCCESS, autoClose: 1000 });
        download(blob, filename, 'application/octet-stream');
      })
      .catch(() => {
        toast.update(toastID, {
          render: 'An error occurred attempting to download the file.',
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      })
      .finally(() => {
        handleCompletion();
      });
  }

  render() {
    return '';
  }
}

export default withEvents(DownloadManager);
