import React, { useEffect, useMemo, useContext } from 'react';
import Modal from 'react-modal';

import config from '../../../config';
import TopModalDisplayVendor from './topModalDisplayVendor';
import TopModalAddVendor from './topModalAddVendor';
import BottomModalStage1 from './bottomModalStage1';
import BottomModalStage2 from './bottomModalStage2';
import BottomModalStage3 from './bottomModalStage3';
import BottomModalStage4 from './bottomModalStage4';
import BottomModalStageQuestionnaire from './bottomModalStageQuestionnaire';
import BottomModalStage1Products from './bottomModalStage1Products';
import { findObjectByInnerKeyValue } from '../../../lib/utils';

import { interpret } from 'xstate';
import ModalStagesXState from './modalStagesXState';

import { useUpdateState } from '../..';
import { CatalogVendorContext } from '../../../routes/vendor/context';

import { ModalContext } from './context';
import { assessmentTypes } from '../../../constants/assessmentTypes';

const checkForProductReports = (reportOfferings, requestedReports) => {
  const { assetReports } = reportOfferings;
  const requestReportsArray = Object.keys(requestedReports);

  for (let index = 0; index < assetReports.length; index++) {
    let reportType = assetReports[index];
    if (requestReportsArray.includes(reportType)) {
      if (requestedReports[reportType] > 0) {
        return true;
      }
    }
  }

  return false;
};

const checkForQuestionnaireReport = (reports, reportDescriptions) => {
  return (
    Object.keys(reports).filter(
      (key) =>
        key === findObjectByInnerKeyValue(reportDescriptions, 'rawType', assessmentTypes.A2VQA.name).name &&
        reports[key] > 0,
    ).length > 0
  );
};

const countRequestedReports = (requestedReports) => {
  let reportsCount = 0;

  Object.keys(requestedReports).map((report) => {
    reportsCount += requestedReports[report];
  });

  return reportsCount;
};

export const BottomModalStage = (props) => {
  const {
    mode,
    topModalState,
    isVendorCreationOnly,
    setParentState,
    predefinedVendorReports,
    modalProductData,
    requestedReports,
    requestedProductReports,
    hasProductReports,
    hasQuestionnaireReport,
    questionnaireFields,
    questionnaireErrors,
    questionnaireTouches,
    vendorID,
    newVendorID,
    vendorName,
    vendorDomain,
    vendorDDRRid,
    errorFields,
    a2vqaReportName,
    currentStepXState,
    selectedSharedReports,
    sharingPropensity,
    setInputError,
    handleCheckbox,
    setBottomModalState,
    setCatalogRequestedVendor,
    setQuestionnaireFields,
    resetCatalogState,
    handleModalClose,
    freeReports,
    downloadableReports,
    shareableReports,
    reportDescriptions,
    reportOfferings,
    linkedReports,
    canPurchaseSharedReports,
    trustCenterReports,
  } = props;

  const hasPredefinedReports = predefinedVendorReports && Object.keys(predefinedVendorReports).length > 0;
  const currentlyOnFirstStep = currentStepXState.value === currentStepXState.context.firstStep;

  switch (currentStepXState.value) {
    case 'reports':
      return (
        <BottomModalStage1
          vendorID={vendorID}
          newVendorID={newVendorID}
          setInputError={setInputError}
          errorFields={errorFields}
          name={vendorName}
          domain={vendorDomain}
          topModalState={topModalState}
          isVendorCreationOnly={isVendorCreationOnly}
          requestedReports={requestedReports}
          requestedProductReports={requestedProductReports}
          hasProductReports={hasProductReports}
          hasQuestionnaireReport={hasQuestionnaireReport}
          handleCheckbox={handleCheckbox}
          setBottomModalState={setBottomModalState}
          reportDescriptions={reportDescriptions}
          reportOfferings={reportOfferings}
          downloadableReports={downloadableReports}
          freeReports={freeReports}
          linkedReports={linkedReports}
          setCatalogRequestedVendor={setCatalogRequestedVendor}
          shareableReports={shareableReports}
          selectedSharedReports={selectedSharedReports}
          canPurchaseSharedReports={canPurchaseSharedReports}
          sharingPropensity={sharingPropensity}
          trustCenterReports={trustCenterReports}
          vendorDDRRid={vendorDDRRid}
          setParentState={setParentState}
        />
      );
    case 'singleProduct':
      return (
        <BottomModalStage1Products
          setInputError={setInputError}
          name={vendorName}
          domain={vendorDomain}
          topModalState={topModalState}
          requestedReports={requestedReports}
          requestedProductReports={requestedProductReports}
          hasProductReports={hasProductReports}
          handleCheckbox={handleCheckbox}
          setBottomModalState={setBottomModalState}
          reportDescriptions={reportDescriptions}
          reportOfferings={reportOfferings}
          downloadableReports={downloadableReports}
          freeReports={freeReports}
          modalProductData={modalProductData}
        />
      );
    case 'questionnaire':
      return (
        <BottomModalStageQuestionnaire
          setBottomModalState={setBottomModalState}
          setQuestionnaireFields={setQuestionnaireFields}
          contactName={questionnaireFields.contactName}
          phoneNumber={questionnaireFields.phoneNumber}
          emailAddress={questionnaireFields.emailAddress}
          errorFields={questionnaireErrors}
          touchedFields={questionnaireTouches}
          allowGoingBackwards={!currentlyOnFirstStep}
          reportName={a2vqaReportName}
          hasProductReports={hasProductReports}
        />
      );
    case 'products':
      return (
        <BottomModalStage2
          requestedReports={requestedReports}
          name={vendorName}
          setBottomModalState={setBottomModalState}
          handleCheckbox={handleCheckbox}
          setParentState={setParentState}
          requestedProductReports={requestedProductReports}
          reportOfferings={reportOfferings}
          reportDescriptions={reportDescriptions}
          hasQuestionnaireReport={hasQuestionnaireReport}
          modalProductData={modalProductData}
        />
      );
    case 'confirmation':
      return (
        <BottomModalStage3
          requestedReports={
            hasPredefinedReports ? { ...predefinedVendorReports } : { ...requestedReports, ...requestedProductReports }
          }
          name={vendorName}
          domain={vendorDomain}
          vendorID={vendorID || newVendorID}
          setBottomModalState={setBottomModalState}
          setParentState={setParentState}
          requestedProductReports={requestedProductReports}
          freeReports={freeReports}
          linkedReports={linkedReports}
          reportOfferings={reportOfferings}
          reportDescriptions={reportDescriptions}
          hasProductReports={hasProductReports}
          hasQuestionnaireReport={hasQuestionnaireReport}
          allowGoingBackwards={!currentlyOnFirstStep}
          questionnaireData={questionnaireFields.contactName ? questionnaireFields : undefined}
          vendorDDRRid={vendorDDRRid}
          selectedSharedReports={selectedSharedReports}
        />
      );
    case 'result':
      return (
        <BottomModalStage4
          setBottomModalState={setBottomModalState}
          vendorID={vendorID}
          resetCatalogState={resetCatalogState}
          onRequestClose={handleModalClose}
          linkToOverviewPage={mode === 'vendorCatalog'}
        />
      );
    default:
      return <p>Untracked bottomModalStage</p>;
  }
};

export const ModalStagesContainerFn = (props) => {
  const {
    fetchData = true,
    fetchReportTypes = true,
    fetchVendorReportData = true,
    showModal,
    mode,
    topModalState,
    modalProductData,
    setParentState,
    predefinedVendorReports,
    onRequestClose,
    modalData = {},
  } = props;

  const {
    reportOfferings: contextReportOfferings,
    reportDescriptions: contextReportDescriptions,
    freeReports: contextFreeReports,
    downloadableReports: contextDownloadableReports,
    shareableReports: contextShareableReports,
    linkedReports: contextLinkedReports,
    canPurchaseSharedReports: contextCanPurchaseSharedReports,
  } = useContext(CatalogVendorContext);

  const [state, setState] = useUpdateState({
    vendorID: '',
    newVendorID: '',
    vendorName: '',
    vendorDomain: '',
    vendorDDRRid: '',
    requestedReports: {},
    requestedProductReports: {
      requestedProductArray: [],
    },
    reportOfferings: {
      vendorReports: [],
      assetReports: [],
    },
    reportDescriptions: {},
    freeReports: undefined,
    downloadableReports: {},
    errorFields: {
      stateReports: {
        name: false,
        domain: false,
        bottomModalButtonDisabled: false,
      },
      stateSingleProduct: {
        name: false,
      },
    },
    questionnaireFields: {
      contactName: '',
      phoneNumber: '',
      emailAddress: '',
    },
    questionnaireErrors: {
      contactName: true,
      phoneNumber: true,
      emailAddress: true,
    },
    questionnaireTouches: {
      contactName: false,
      phoneNumber: false,
      emailAddress: false,
    },
    currentStepXState: ModalStagesXState.initialState,
    linkedReports: [],
    shareableReports: {},
    selectedSharedReports: {},
    canPurchaseSharedReports: false,
    sharingPropensity: null,
    trustCenterReports: undefined,
    questionnaireDataAssociatedVendorID: '',
  });

  const service = useMemo(() => {
    return interpret(ModalStagesXState).onTransition((currentStepXState) => setState({ currentStepXState }));
  }, [interpret, ModalStagesXState]);

  const getSharingStatus = (vendorID) => {
    fetch(config.api.urlFor('vendorSharingPropensity', { id: vendorID }))
      .then((res) => res.json())
      .then((data) => {
        setState({
          sharingPropensity: data.sharingStatus,
        });
      })
      .catch(() => {
        setState({ sharingPropensity: null });
      });
  };

  const getFreeVendorReportsData = (vendorID) => {
    if (!fetchData || !fetchVendorReportData) return;

    fetch(config.api.urlFor('catalogAvailableReports', { id: vendorID, rawType: true, pageSize: 0 }))
      .then((res) => res.json())
      .then((data) => {
        let { rows = [], canPurchaseSharedReports } = data;
        let freeReports = {};
        let downloadableReports = {};
        let shareableReports = {};

        rows = rows.sort((a, b) => {
          let aDate = a.completedDate,
            bDate = b.completedDate,
            aPriority = a.priority,
            bPriority = b.priority;

          switch (true) {
            case !aDate && !bDate: {
              return 0;
            }

            case aDate && !bDate: {
              return -1;
            }

            case !aDate && bDate: {
              return 1;
            }

            default: {
              aDate = new Date(aDate);
              bDate = new Date(bDate);

              return aDate > bDate ? -1 : aDate < bDate ? 1 : aPriority < bPriority ? -1 : 1;
            }
          }
        });

        rows = rows.filter((v, i, a) => a.findIndex((x) => x.rawType === v.rawType) === i).reverse();

        for (let r of rows) {
          const hasDate = r.completedDate === null ? false : true;

          const purchaseDate = r.purchaseDate ? new Date(r.purchaseDate).toDateString() : '';
          const requestedDate = r.requestedDate ? new Date(r.requestedDate).toDateString() : '';
          const completedDate = r.completedDate ? new Date(r.completedDate).toDateString() : '';
          const parentReport = linkedReports && linkedReports.filter((item) => item.report === r.type);
          const actualReportType = parentReport && parentReport.length ? parentReport[0].parentReport : r.type;

          if (r.hasAccess) {
            freeReports[`${actualReportType}`] = purchaseDate ? purchaseDate : requestedDate;
          }

          // VSCA reports could be shared only if the vendor has agreed.
          if (hasDate && (actualReportType !== assessmentTypes.A2VQA.name || modalData.canShareVSCA)) {
            downloadableReports[`${actualReportType}`] = completedDate;

            if (r.canShare) {
              shareableReports[`${actualReportType}`] = 1;
            }
          }
        }

        canPurchaseSharedReports = canPurchaseSharedReports && Object.keys(shareableReports).length;

        setState({
          freeReports,
          downloadableReports,
          shareableReports,
          canPurchaseSharedReports,
        });
      })
      .catch(() => {
        setState({
          freeReports: undefined,
          downloadableReports: {},
          shareableReports: {},
          canPurchaseSharedReports: false,
        });
      });
  };

  const getFreeProductReportsData = (productID) => {
    fetch(config.api.urlFor('catalogAvailableProductReports', { id: productID, distinct: 1, pageSize: 0 }))
      .then((res) => res.json())
      .then((data) => {
        let { rows = [] } = data;
        let freeReports = {};
        let downloadableReports = {};

        rows.forEach((item) => {
          const hasDate = item.completedDate === null ? false : true;
          if (hasDate) {
            downloadableReports[`${item.type}`] = item.completedDate ? new Date(item.completedDate).toDateString() : '';
          }
          if (item.hasAccess) {
            freeReports[`${item.type}`] = item.purchaseDate ? new Date(item.purchaseDate).toDateString() : '';
          }
        });
        setState({ freeReports, downloadableReports });
      })
      .catch(() => {
        setState({ freeReports: undefined, downloadableReports: {} });
      });
  };

  const getAssessmentCatalog = () => {
    if (!fetchData || !fetchReportTypes) return;

    fetch(config.api.urlFor('catalogReportTypes') + '?pageSize=0')
      .then((res) => res.json())
      .then((data) => {
        let { rows = [] } = data;
        let reportOfferings = { vendorReports: [], assetReports: [] };
        let reportDescriptions = {};
        let linkedReports = [];

        rows.forEach((r) => {
          reportDescriptions[r.name] = {
            name: r.name,
            abvName: r.abbreviation,
            rawType: r.rawType,
            price: r.price,
            description: r.description,
            id: r.id,
            parentID: r.parent_id,
            autodeliver: r.autodeliver,
          };

          if (r.class === 'product') {
            reportOfferings.assetReports.push(r.name);
          } else if (!r.parent_id) {
            reportOfferings.vendorReports.push(r.name);
          } else {
            const parentReportName = rows.find((report) => report.id === r.parent_id).name;
            linkedReports.push({ report: r.name, parentReport: parentReportName });
          }
        });

        setState({
          reportOfferings,
          reportDescriptions,
          linkedReports,
        });
      })
      .catch(() => {
        setState({
          reportOfferings: { vendorReports: [], assetReports: [] },
          reportDescriptions: {},
          linkedReports: [],
        });
      });
  };

  const getTrustCenterReports = () => {
    fetch(config.api.urlFor('purchasableFileItemsClasses'))
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, data: classes } = response;
        setState({ trustCenterReports: isSuccess && classes ? classes : [] });
      })
      .catch(() => {
        setState({ trustCenterReports: [] });
      });
  };

  const setBottomModalState = (direction) => {
    changeStepXState(direction);

    if (direction === 'CLOSE') {
      onRequestClose && onRequestClose();
    }
  };

  const setCatalogRequestedVendor = (id, name, domain, ddrrID) => {
    setState({
      newVendorID: id,
      vendorName: name,
      vendorDomain: domain,
      vendorDDRRid: ddrrID,
    });
  };

  const setQuestionnaireFields = (
    contactName = '',
    phoneNumber = '',
    emailAddress = '',
    errorFields = { contactName: true, phoneNumber: true, emailAddress: true },
    touchedFields = { contactName: false, phoneNumber: false, emailAddress: false },
  ) => {
    setState({
      questionnaireFields: {
        contactName: contactName,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
      },
      questionnaireErrors: errorFields,
      questionnaireTouches: touchedFields,
    });
  };

  const resetCatalogState = () => {
    setState({
      requestedReports: {},
      requestedProductReports: {
        requestedProductArray: [],
      },
      errorFields: {
        stateReports: {
          name: false,
          domain: false,
          bottomModalButtonDisabled: false,
        },
        stateSingleProduct: {
          name: false,
        },
      },
    });
  };

  const handleModalClose = () => {
    setBottomModalState('CLOSE');
  };

  const handleInputBox = ({ target: { value } }, keyName) => {
    setState((state) => {
      let val = value.trim();

      let { errorFields } = state;
      if (keyName === 'vendorDomain') {
        val = val.toLowerCase();
        const { stateReports = {}, stateSingleProduct = {} } = errorFields;
        val = val.replace(/^http:\/\/|^https:\/\//, '').replace(/\/.*$/, '');

        errorFields = {
          stateReports: {
            ...stateReports,
            bottomModalButtonDisabled: false,
          },
          stateSingleProduct: stateSingleProduct,
        };
      }

      return {
        ...state,
        errorFields,
        [keyName]: val,
      };
    });
  };

  const setModalData = (modalData) => {
    setParentState && setParentState('modalData', modalData, true);
  };

  const callRemoveMyVendor = (vendorID) => {
    setModalData({ ...modalData, myVendor: undefined });

    fetch(config.api.urlFor('catalog'), { method: 'DELETE', body: { vendorID } })
      .then((res) => res.json())
      .then((data) => {
        if (data.isSuccess) {
          setModalData({ ...modalData, myVendor: false });
        }
      });
  };

  const callMakeMyVendor = (vendorID) => {
    setModalData({ ...modalData, myVendor: undefined });

    fetch(config.api.urlFor('catalog'), { method: 'POST', body: { vendorID } })
      .then((res) => res.json())
      .then((data) => {
        if (data.isSuccess) {
          setModalData({ ...modalData, myVendor: true });
        }
      });
  };

  const {
    requestedReports,
    requestedProductReports,
    questionnaireFields,
    questionnaireErrors,
    questionnaireTouches,
    errorFields,
    currentStepXState,
    selectedSharedReports,
    sharingPropensity,
    trustCenterReports,
    questionnaireDataAssociatedVendorID,
  } = state;

  const reportOfferings = contextReportOfferings || state.reportOfferings,
    reportDescriptions = contextReportDescriptions || state.reportDescriptions,
    freeReports = contextFreeReports || state.freeReports,
    downloadableReports = contextDownloadableReports || state.downloadableReports,
    shareableReports = contextShareableReports || state.shareableReports,
    linkedReports = contextLinkedReports || state.linkedReports,
    canPurchaseSharedReports = contextCanPurchaseSharedReports || state.canPurchaseSharedReports,
    vendorID = props.vendorID || modalData.id || state.vendorID,
    newVendorID = state.newVendorID,
    vendorName = state.vendorName != null ? state.vendorName : props.vendorName || modalData.name,
    vendorDomain = state.vendorDomain != null ? state.vendorDomain : props.vendorDomain || modalData.domain,
    vendorDDRRid = state.vendorDDRRid || props.vendorDDRRid || modalData.ddrrID;

  const setInputError = (keyName, bool) => {
    setState((state) => {
      const errorFieldKeyName =
        'state' + currentStepXState.value.charAt(0).toUpperCase() + currentStepXState.value.slice(1);

      errorFields[errorFieldKeyName][keyName] = bool;

      return {
        ...state,
        errorFields,
      };
    });
  };

  const wipeRequestData = (isAddVendor /*, reportDescriptions */) => {
    setState((state) => {
      let requestedReports = {},
        freeReports;

      if (isAddVendor) {
        freeReports = {};
        // The following code is used for selecting default reports. It is not currently needed.
        // const foundNameRow = findObjectByInnerKeyValue(reportDescriptions, 'rawType', assessmentTypes.CHS.name);
        // const keyName = foundNameRow ? foundNameRow.name : assessmentTypes.CHS.name;
        // requestedReports = keyName ? { [keyName]: 1 } : requestedReports;
      }

      return {
        ...state,
        requestedReports,
        errorFields: { stateReports: {}, stateSingleProduct: {} },
        freeReports,
        downloadableReports: {},
        requestedProductReports: {
          requestedProductArray: [],
        },
        sharingPropensity: null,
        vendorName: undefined,
        vendorDomain: undefined,
        vendorDDRRid: undefined,
        newVendorID: undefined,
      };
    });
  };

  const handleCheckbox = (keyName, purchaseShared) => {
    setState((state) => {
      const { requestedReports } = state;
      let { selectedSharedReports } = state;

      if (purchaseShared && !requestedReports[`${keyName}`]) {
        return state;
      }

      let setValueTo = requestedReports[`${keyName}`] && purchaseShared === undefined ? 0 : 1;

      if (purchaseShared !== undefined && setValueTo === 1) {
        selectedSharedReports = { ...selectedSharedReports, [`${keyName}`]: purchaseShared };
      } else {
        selectedSharedReports = { ...selectedSharedReports, [`${keyName}`]: false };
      }

      return {
        ...state,
        selectedSharedReports,
        requestedReports: { ...requestedReports, [`${keyName}`]: setValueTo },
      };
    });
  };

  const hasProductReports = useMemo(
    () => checkForProductReports(reportOfferings, requestedReports),
    [reportOfferings, requestedReports],
  );

  const hasQuestionnaireReport = useMemo(
    () =>
      (checkForQuestionnaireReport(requestedReports, reportDescriptions) ||
        (predefinedVendorReports && checkForQuestionnaireReport(predefinedVendorReports, reportDescriptions))) == true,
    [requestedReports, reportDescriptions, predefinedVendorReports],
  );

  const isVendorCreationOnly = useMemo(
    () => topModalState === 'addVendor' && countRequestedReports(requestedReports) == 0,
    [topModalState, requestedReports],
  );

  const changeStepXState = (value) => {
    service.send({
      type: 'UPDATE_CONTEXT',
      mode: mode,
      hasQuestionnaireReport,
      hasProductReports,
      isVendorCreationOnly,
    });
    service.send(value);
  };

  const a2vqaReportName = useMemo(() => {
    return (
      findObjectByInnerKeyValue(reportDescriptions, 'rawType', assessmentTypes.A2VQA.name).name ||
      assessmentTypes.A2VQA.name
    );
  }, [reportDescriptions]);

  useEffect(() => {
    service && service.start();
  }, [service]);

  useEffect(() => {
    showModal && changeStepXState('OPEN');
  }, [showModal]);

  useEffect(() => {
    if (!showModal) return;

    if (vendorID) {
      setState({
        freeReports: undefined,
        requestedReports: {},
      });

      getSharingStatus(vendorID);
      getFreeVendorReportsData(vendorID);
      getTrustCenterReports();

      if (vendorID !== questionnaireDataAssociatedVendorID) {
        setState({ questionnaireDataAssociatedVendorID: vendorID });
        setQuestionnaireFields();
      }
    } else {
      setState({
        freeReports: {},
        trustCenterReports: {},
      });
    }
  }, [showModal, vendorID]);

  useEffect(() => {
    if (!vendorID && (!modalProductData || !modalProductData.id)) {
      setState({ freeReports: {}, downloadableReports: {}, sharingPropensity: null });
    }
  }, [vendorID, modalProductData]);

  useEffect(() => {
    if (mode === 'product' && modalProductData && modalProductData.id) {
      getFreeProductReportsData(modalProductData.id);
    }
  }, [mode, modalProductData]);

  useEffect(() => {
    wipeRequestData(topModalState === 'addVendor', reportDescriptions);
  }, [topModalState, reportDescriptions]);

  useEffect(() => {
    getAssessmentCatalog();
  }, []);

  const contextData = {};

  return (
    <ModalContext.Provider value={contextData}>
      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            background: 'none',
            overflow: 'unset',
            zIndex: '5',
          },
        }}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleModalClose}
      >
        <div id="modal-m-vendor">
          {(mode === 'vendorCatalog' || mode === 'product') &&
            (currentStepXState.value === 'reports' || currentStepXState.value === 'singleProduct') &&
            (topModalState === 'addVendor' ? (
              <TopModalAddVendor
                errorFields={errorFields.stateReports}
                domain={vendorDomain}
                name={vendorName}
                id={newVendorID}
                handleInputBox={handleInputBox}
              />
            ) : (
              <TopModalDisplayVendor
                modalData={modalData}
                callRemoveMyVendor={callRemoveMyVendor}
                callMakeMyVendor={callMakeMyVendor}
                onProductModal={mode === 'product'}
              />
            ))}

          <BottomModalStage
            mode={mode}
            topModalState={topModalState}
            isVendorCreationOnly={isVendorCreationOnly}
            setParentState={setParentState}
            predefinedVendorReports={predefinedVendorReports}
            modalProductData={modalProductData}
            requestedReports={requestedReports}
            requestedProductReports={requestedProductReports}
            hasProductReports={hasProductReports}
            hasQuestionnaireReport={hasQuestionnaireReport}
            questionnaireFields={questionnaireFields}
            questionnaireErrors={questionnaireErrors}
            questionnaireTouches={questionnaireTouches}
            vendorID={vendorID}
            newVendorID={newVendorID}
            vendorName={vendorName}
            vendorDomain={vendorDomain}
            vendorDDRRid={vendorDDRRid}
            freeReports={freeReports}
            downloadableReports={downloadableReports}
            shareableReports={shareableReports}
            errorFields={errorFields}
            a2vqaReportName={a2vqaReportName}
            currentStepXState={currentStepXState}
            selectedSharedReports={selectedSharedReports}
            sharingPropensity={sharingPropensity}
            setInputError={setInputError}
            handleCheckbox={handleCheckbox}
            setBottomModalState={setBottomModalState}
            setCatalogRequestedVendor={setCatalogRequestedVendor}
            setQuestionnaireFields={setQuestionnaireFields}
            resetCatalogState={resetCatalogState}
            handleModalClose={handleModalClose}
            reportDescriptions={reportDescriptions}
            linkedReports={linkedReports}
            canPurchaseSharedReports={canPurchaseSharedReports}
            reportOfferings={reportOfferings}
            trustCenterReports={trustCenterReports}
          />
        </div>
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalStagesContainerFn;
