import React, { Component } from 'react';
import { FaUserFriends, FaLaptop, FaSearch, FaCoins } from 'react-icons/fa';
import './footnote.css';
import ReactTooltip from 'react-tooltip';

export default class DashboardTile extends Component {
  setTileLabel(text) {
    switch (text) {
      case 'My Vendors':
        return (
          <h4>
            <FaUserFriends /> {text}
          </h4>
        );
      case 'Monitoring Findings':
        return (
          <h4>
            <FaSearch /> {text}
          </h4>
        );
      case 'Tokens':
        return (
          <h4>
            <FaCoins /> {text}
            <a aria-describedby="footnote-label" data-tip data-for="onHoverTokens"></a>
            <ReactTooltip id="onHoverTokens" place="top" effect="solid">
              <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                Token balances may not reflect activity performed outside of the Marketplace module.<br></br>
                Prior month balances are reconciled 30 days after the month close.
              </p>
            </ReactTooltip>
          </h4>
        );
      default:
        return <h4>{text}</h4>;
    }
  }

  render() {
    const { val = '', text = '', comingSoon = false } = this.props;
    return (
      <div className={`db-t-wrapper ${comingSoon ? 'db-t-coming-soon' : ''}`}>
        {comingSoon ? (
          <h3 className="coming-soon-text">Coming Soon</h3>
        ) : typeof val === 'string' ? (
          <h3>{val}</h3>
        ) : (
          val
        )}
        {this.setTileLabel(text)}
      </div>
    );
  }
}
