import React from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../components/alert';

import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from 'react-bootstrap/Spinner';

import { createGlobalStyle } from 'styled-components';
import config from '../../config';
import './forgotPassword.css';
import { emailIsValid } from '../../lib/utils';

// This is here because the relative path could not be used inside forgotPassword.css, due to create-react-app restriction.
const GlobalStyle = createGlobalStyle`
  body{
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), no-repeat center/100% url('/static/images/login-page-background-1-08_2021.jpg');
  }
`;

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      done: false,
      isBusy: false,
      resetPasswordFailure: false,
      resetPasswordFailureError: '',
      errorEmailField: false,
      touchedEmailField: false,
    };

    this.submit = this.submit.bind(this);
  }

  setFailureState(msg) {
    this.setState({
      resetPasswordFailure: true,
      resetPasswordFailureError: msg || 'Resetting Password Failed.',
      isBusy: false,
    });
  }

  submit(e) {
    e.preventDefault();

    const _self = this;

    const params = new URLSearchParams();
    params.set('emailAddress', this.state.email.trim());

    _self.setState({ isBusy: true, resetPasswordFailure: false });

    fetch(config.api.urlFor('forgotPassword'), { method: 'PUT', body: params })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          this.setState({ done: true, isBusy: false });
        } else {
          this.setFailureState(message);
        }
      })
      .catch(() => {
        this.setFailureState();
      });
  }

  handleEmailInputBox(e) {
    const val = e.target.value;

    this.setState({
      errorEmailField: val.length && !emailIsValid(val),
      email: val,
    });
  }

  handleBlur() {
    this.setState({ touchedEmailField: true });
  }

  render() {
    const alert = this.props.location.state ? this.props.location.state.alert : undefined;

    const { email, errorEmailField, touchedEmailField, resetPasswordFailure, resetPasswordFailureError, isBusy, done } =
      this.state;

    const submitted = (
      <div className="container">
        <div className="row">
          <div style={{ textAlign: 'center', width: '100%' }}>
            <img alt="A2V Logo" className="a2v-logo-heading" src="/static/images/A2V_logo_negative.png" />
          </div>
        </div>
        <div className="card card-login mx-auto">
          <div className="card-header text-center">Reset Password</div>
          <div className="card-body">
            <div className="text-center mb-4">
              <p>
                If you have an active account, you will receive a password reset email. Please check your inbox for
                instructions.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
    const content = (
      <div className="container">
        <div className="row">
          <div style={{ textAlign: 'center', width: '100%' }}>
            <img alt="A2V Logo" className="a2v-logo-heading" src="/static/images/A2V_logo_negative.png" />
          </div>
        </div>
        <div className="card card-login mx-auto">
          <div className="card-header">Reset Password</div>
          <div className="card-body">
            <div className="text-center mb-4">
              <h4>Forgot your password?</h4>
              <p>Enter your email address and we will send you instructions on how to reset your password.</p>
            </div>
            <form onSubmit={this.submit}>
              <div className="form-group">
                <div className="form-label-group">
                  <input
                    type="email"
                    id="inputEmail"
                    className={'form-control' + (errorEmailField && touchedEmailField ? ' is-invalid' : '')}
                    onChange={(e) => this.handleEmailInputBox(e)}
                    onBlur={this.handleBlur.bind(this)}
                    value={email}
                    placeholder="Enter email address"
                    required="required"
                    autoFocus="autofocus"
                  ></input>
                  <label htmlFor="inputEmail">Enter email address</label>
                  {!!(errorEmailField && touchedEmailField) && (
                    <span className="m-error"> Please enter a valid email address </span>
                  )}
                </div>
              </div>
              {alert && alert.message && <Alert message={alert.message} type={alert.type ? alert.type : undefined} />}
              {resetPasswordFailure && <Alert message={resetPasswordFailureError} type="warning" />}
              <button
                type="submit"
                value="go"
                className="btn btn-primary btn-block"
                disabled={isBusy || errorEmailField || !email.trim()}
              >
                {isBusy ? (
                  <Spinner animation="border" role="status" variant="light">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  'Reset Password'
                )}
              </button>
            </form>
            <div className="text-center mt-2">
              <Link to="/login">Login Page</Link>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <GlobalStyle />
        {(() => {
          if (done) {
            return submitted;
          } else {
            return content;
          }
        })()}
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
