import React from 'react';
import SbomHexagon from './sbomHexagon';

export const scoreColorMap = {
  high: '#ff6600',
  critical: '#ff0000',
  moderate: '#f7c341',
  low: '#014f6e',
  na: '#808080',
};

export const getScoreWeight = (path) => {
  switch (path) {
    case 'malware':
      return 'High';
    case 'dependency':
      return 'Moderate';
    default:
      return 'Low';
  }
};

export const pathLabels = {
  integrity: 'Integrity Risk',
  vulnerability: 'Vulnerability Risk',
  overall: 'Overall Score',
  dependency: 'Dependency Risk',
  malware: 'Malware Risk',
  foci: 'FOCI Risk',
};

const getLabel = (path) => {
  let keys = Object.keys(pathLabels);
  if (!keys.includes(path)) return;
  return pathLabels[path];
};

const fetchProps = (path, scores) => {
  const label = getLabel(path);
  if (!label || !scores) return;
  const scoreText = scores[path];
  const { category = 'na' } = scoreText || {};
  const fill = scoreColorMap[category.toLowerCase()];
  const props = { categoryText: label, scoreText: category === 'na' ? 'N/A' : category, fill };
  return props;
};

export function HexagonView({ path = null, scores }) {
  if (!path) return;
  if (path === 'summary') path = 'overall';
  const props = fetchProps(path, scores);
  return props ? <SbomHexagon {...props} /> : null;
}
