import React, { useEffect } from 'react';
import { useUpdateState } from '../../';
import ProductReportBox from './productReportBox';
import config from './../../../config';
import ReactTooltip from 'react-tooltip';
import { Spinner } from '../../../components';
import { toastError } from '../../../lib/utils';

import '../../../components/dashboard/footnote.css';

const BottomModalStage3 = (props) => {
  const [state, setState] = useUpdateState({
    isBusy: false,
    sendRequestObject: {},
    productFiles: [],
    hasErrors: {
      hasReport: false,
      nsf: false,
      noaccess: false,
    },
    balance: 0,
    tokenSum: 0,
    endingTokenBalance: 0,
  });

  /* ----------------------------------- */
  /*          Life Cycle Methods         */
  /* ----------------------------------- */

  useEffect(() => {
    const {
      requestedReports,
      reportOfferings = {},
      requestedProductReports = {},
      name,
      domain,
      vendorID,
      vendorDDRRid,
      linkedReports,
      selectedSharedReports,
    } = props;
    let { vendorReports = [] } = requestedReports;

    vendorReports = reportOfferings.vendorReports;

    const { requestedProductArray = [] } = requestedProductReports;
    let requestObject = requestedReports;
    let requestArray = Object.keys(requestedReports);
    let quantitySum = 0;

    requestArray.map((reportType) => {
      if (requestObject[`${reportType}`] > 0) {
        quantitySum += requestObject[`${reportType}`];
      }
    });

    if (quantitySum != 0) {
      const requestedVendorReports = [];

      vendorReports.forEach((reportType) => {
        if (Object.prototype.hasOwnProperty.call(requestedReports, reportType) && requestedReports[reportType] > 0) {
          const report = selectedSharedReports[`${reportType}`]
            ? linkedReports.filter((item) => item.parentReport === reportType)[0].report
            : reportType;

          requestedVendorReports.push(report);
        }
      });

      const sendRequestObject = {
        name,
        domain,
        vendorID,
        vendorDDRRid,
        requestedProductReports: requestedProductArray.map((i) => {
          return {
            productNumber: i.productNumber,
            productName: i.productName,
            fileNameArray: i.fileArray.map((file) => file.name),
            reportsArray: i.reportsArray,
          };
        }),
        requestedVendorReports,
      };
      let productFiles = [];
      if (requestedProductArray.length) {
        productFiles = requestedProductArray.reduce((p = [], c) => p.concat(c.fileArray), []);
      }

      setState({
        hasErrors: { hasReport: true },
        sendRequestObject,
        productFiles,
      });

      // Prepare the list of reports for the order confirmation email
      makeReportsListForEmail(requestedReports);
    }

    getBalance();
  }, []);

  /* ----------------------------------- */
  /*          API Calls                  */
  /* ----------------------------------- */

  const sendData = async () => {
    let { hasErrors } = state;
    hasErrors = { ...hasErrors, nsf: false, noaccess: false };
    setState({ isBusy: true, hasErrors });
    const productFiles = state.productFiles;
    const formData = new FormData();
    const { questionnaireData, setBottomModalState } = props;

    formData.append(
      'requests',
      JSON.stringify({
        ...state.sendRequestObject,
        questionnaireData,
      }),
    );

    for (let pf of productFiles) {
      formData.append('attachments', pf);
    }

    formData.append('onProductModal', JSON.stringify(props.onProductModal || ''));

    if (state.endingTokenBalance < 0) {
      hasErrors.nsf = true;
      setState({ hasErrors, isBusy: false });
      return;
    }

    try {
      // using the base fetch and also because we are using multer middleware on this
      // particular api endpoint, we dont need to specify content type header
      const res = await fetch(config.api.urlFor('catalogSubmitRequest'), {
        method: 'POST',
        body: formData,
      });

      if (res.status === 200) {
        if (!props.onProductModal) {
          const resData = await res.json();
          if (resData.results && resData.results.vendorID && props.setParentState) {
            props.setParentState('vendor_id', resData.results.vendorID, true);
          }
        }
        setBottomModalState('NEXT');
      } else if (res.status === 402 || res.status === 403) {
        const resData = await res.text();
        if (resData === 'nonsufficient funds') {
          hasErrors.nsf = true;
          setState({ hasErrors });
        } else if (resData === 'no access') {
          hasErrors.noaccess = true;
          setState({ hasErrors });
        }
      } else if (res.status >= 500) {
        toastError(`Unknown Error, please try again later. If this issue persists, please contact our support.`, {
          autoClose: 7000,
        });
      }

      setState({ isBusy: false });
    } catch (err) {
      setState({ isBusy: false });
    }
  };

  const makeReportsListForEmail = (requestObject) => {
    const { reportDescriptions = {}, freeReports = {}, linkedReports, selectedSharedReports } = props;
    let requestArray = Object.keys(requestObject);
    let price = 0;

    const reportsListForEmail = requestArray
      .map((data) => {
        if (requestObject[`${data}`] > 0) {
          const report = selectedSharedReports[`${data}`]
            ? linkedReports.find((item) => item.parentReport === data).report
            : data;

          price =
            freeReports[`${report}`] || freeReports[`${data}`]
              ? 0
              : reportDescriptions[`${report}`]
              ? reportDescriptions[`${report}`].price
              : 'Pricing ERR';

          return { report: report, quantity: requestObject[`${data}`], tokens: price };
        }
      })
      .filter((data) => data !== undefined);

    setState({ reportsListForEmail });
  };

  const countDecimals = (value) => {
    if (Number.isNaN(Number.parseFloat(value)) || Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
  };

  const makeTableRows = (reportsListForEmail) => {
    const quantitySum = reportsListForEmail ? reportsListForEmail.reduce((total, r) => total + r.quantity, 0) : 0;
    let tokenSum = reportsListForEmail ? reportsListForEmail.reduce((total, r) => total + r.quantity * r.tokens, 0) : 0;

    tokenSum = countDecimals(tokenSum) <= 2 ? tokenSum.toFixed(2) : tokenSum.toFixed(3);

    setTokenSum(tokenSum);
    setEndingTokenBalance(tokenSum);

    const reportsFirstTDs = {
      fontSize: '15px',
      fontWeight: '500',
      textAlign: 'start',
      border: '1px solid #A9A9A9',
      padding: '5px 15px',
    };

    const reportsOtherTDs = {
      fontSize: '15px',
      fontWeight: '500',
      textAlign: 'center',
      border: '1px solid #A9A9A9',
      padding: '5px 15px',
    };

    const firstTHTotal = {
      fontSize: '15px',
      fontWeight: '700',
      textAlign: 'start',
      border: '1px solid #A9A9A9',
      padding: '5px 10px',
    };

    const otherTHsTotal = {
      fontSize: '15px',
      fontWeight: '700',
      textAlign: 'center',
      border: '1px solid #A9A9A9',
      padding: '5px 10px',
    };

    return (
      <React.Fragment>
        {reportsListForEmail &&
          reportsListForEmail.map((data, i) => {
            let countDecimalsTokenEa = countDecimals(data.tokens);
            let countDecimalsTokensUtilized = countDecimals(data.tokens * data.quantity);
            const tokenEa = countDecimalsTokenEa <= 2 ? data.tokens.toFixed(2) : data.tokens.toFixed(3);
            const tokensUtilized =
              countDecimalsTokensUtilized <= 2
                ? (data.tokens * data.quantity).toFixed(2)
                : (data.tokens * data.quantity).toFixed(3);

            return (
              <tr key={i}>
                <td style={reportsFirstTDs}>{data.report}</td>
                <td style={reportsOtherTDs}>{data.quantity}</td>
                <td style={reportsOtherTDs}>{tokenEa}</td>
                <td style={reportsOtherTDs}>{tokensUtilized}</td>
              </tr>
            );
          })}
        {quantitySum === 0 ? (
          <tr>
            <td colSpan="4">
              <span className="m-error">No Reports added. Please go press back to request a report.</span>
            </td>
          </tr>
        ) : (
          <tr style={{ backgroundColor: 'rgb(224,224,224)' }}>
            <th className="order-total-text" style={firstTHTotal}>
              Item Total
            </th>
            <td style={otherTHsTotal}>{quantitySum}</td>
            <td style={otherTHsTotal}></td>
            <td style={otherTHsTotal}>{tokenSum}</td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  const getBalance = () => {
    setState({ fetchingBalance: true });
    fetch(config.api.urlFor('tokenBalance'))
      .then((res) => res.json())
      .then((response) => {
        if (response.hasSubscription === false) {
          props.setBottomModalState('CLOSE');

          const contactSupportDomain =
            config.contactSupport && config.contactSupport.domain && config.contactSupport.domain + '/contact';

          const toastMessage = (
            <div>
              You don&apos;t have a subscription and you cannot purchase reports.
              <br />
              Please contact support for assistance
              {!!contactSupportDomain && (
                <>
                  <br />
                  <a href={contactSupportDomain} target={contactSupportDomain}>
                    Fortress Information Security
                  </a>
                </>
              )}
              .
            </div>
          );

          const toastOptions = { toastId: 'error-purchase-reports', autoClose: 7000 };
          toastError(toastMessage, toastOptions);
        } else {
          const data = response.isSuccess ? response.data : {};
          setState({
            balance: data.balance
              ? countDecimals(data.balance) <= 2
                ? data.balance.toFixed(2)
                : data.balance.toFixed(3)
              : 0,
            fetchingBalance: false,
          });
        }
      })
      .catch(() => {
        props.setBottomModalState('CLOSE');
        const toastMessage = (
          <div>
            Unknown Error, please try again later. If this issue persists, please contact our support:
            <br />
            <a
              href={config.contactSupport && config.contactSupport.domain + '/contact'}
              target={config.contactSupport && config.contactSupport.domain + '/contact'}
            >
              Fortress Information Security
            </a>
            .
          </div>
        );

        const toastOptions = { toastId: 'error-purchase-reports', autoClose: 7000 };
        toastError(toastMessage, toastOptions);
      });
  };

  const setTokenSum = (tokenSum) => {
    if (tokenSum !== state.tokenSum) {
      setState({ tokenSum: tokenSum });
    }
  };

  const setEndingTokenBalance = (tokenSum) => {
    const maxCountDecimal = Math.max(countDecimals(state.balance), countDecimals(tokenSum));
    const multiplyingValue = Math.pow(10, maxCountDecimal);
    const endingTokenBalance = (
      (state.balance * multiplyingValue - tokenSum * multiplyingValue) /
      multiplyingValue
    ).toFixed(maxCountDecimal);

    if (endingTokenBalance !== state.endingTokenBalance) {
      setState({ endingTokenBalance: endingTokenBalance });
    }
  };

  const { name, domain, requestedProductReports, setBottomModalState } = props;
  const { hasErrors, reportsListForEmail, balance, endingTokenBalance, fetchingBalance, isBusy } = state;
  const { nsf, noaccess } = hasErrors;
  const allowGoingBackwards = props.allowGoingBackwards === undefined ? true : props.allowGoingBackwards;

  const firstTHBalance = {
    fontSize: '15px',
    fontWeight: '700',
    textAlign: 'start',
    border: '1px solid #A9A9A9',
    padding: '5px 10px',
    width: '88%',
  };

  const otherTHsBalance = {
    fontSize: '15px',
    fontWeight: '700',
    textAlign: 'center',
    border: '1px solid #A9A9A9',
    padding: '5px 10px',
    width: '12%',
  };

  const firstTHReports = {
    fontSize: '15px',
    fontWeight: '700',
    textAlign: 'start',
    border: '1px solid #A9A9A9',
    padding: '5px 10px',
    width: '64%',
  };

  const otherTHReports = { ...otherTHsBalance };

  const table = {
    fontFamily: 'Open Sans, Arial',
    fontSize: '1px',
    padding: '0',
    borderSpacing: '0',
    margin: '0',
    borderCollapse: 'collapse',
    width: '100%',
    height: '35px',
    marginLeft: '-3px',
  };

  const reportsTable = { ...table, marginBottom: '2rem' };

  const balanceTables = {
    ...table,
    ...{
      marginBottom: '1.5rem',
      backgroundColor: 'rgb(224,224,224)',
    },
  };

  return (
    <React.Fragment>
      <div id="catalog-modal-stage-3" className="modal-report-info-box modal-mt-override">
        <p className="m-bold pb-2">Checkout Confirmation</p>
        <p className="pb-4 font-size-medium">Please confirm the token utilization below.</p>

        <div>
          {fetchingBalance ? (
            <div>
              <Spinner isVisible={true} />
            </div>
          ) : (
            <div>
              <table className="table table-bordered" border="0" cellPadding="0" cellSpacing="0" style={balanceTables}>
                <thead>
                  <tr>
                    <th style={firstTHBalance} scope="col">
                      Beginning Token Balance
                      <a
                        aria-describedby="footnote-label"
                        data-tip
                        data-for="onHoverTokens"
                        style={{ fontSize: 'large' }}
                      ></a>
                      <ReactTooltip id="onHoverTokens" place="right" effect="solid">
                        <p style={{ marginTop: '2px', marginBottom: '15px', textAlign: 'center' }}>
                          Token balances may not reflect activity performed outside of the Marketplace module.
                        </p>
                        <p style={{ marginBottom: '2px', textAlign: 'center' }}>
                          Prior month balances are reconciled 30 days after the month close.
                        </p>
                      </ReactTooltip>
                    </th>
                    <th style={otherTHsBalance} scope="col">
                      {balance}
                    </th>
                  </tr>
                </thead>
              </table>

              <div className="m-flex-start">
                <p className="text-with-ellipsis font-size-medium">
                  Organization: <b style={{ marginRight: '8px' }}>{name}</b>
                </p>
                {!!domain && <p className="font-size-medium ml-1">({domain})</p>}
              </div>

              <table className="table table-bordered" border="0" cellPadding="0" cellSpacing="0" style={reportsTable}>
                <thead>
                  <tr style={{ border: '1px solid #dee2e6', backgroundColor: 'rgb(211,211,211)' }}>
                    <th className="m-tal-c" style={firstTHReports} scope="col">
                      Item
                    </th>
                    <th className="m-tal-c" style={otherTHReports} scope="col">
                      Quantity
                    </th>
                    <th className="m-tal-c" style={otherTHReports} scope="col">
                      Token/Ea.
                    </th>
                    <th className="m-tal-c" style={otherTHReports} scope="col">
                      Tokens Utilized
                    </th>
                  </tr>
                </thead>
                <tbody className="m-tal-c">{makeTableRows(reportsListForEmail)}</tbody>
              </table>

              <table className="table table-bordered" border="0" cellPadding="0" cellSpacing="0" style={balanceTables}>
                <thead>
                  <tr>
                    <th style={firstTHBalance} scope="col">
                      Ending Token Balance
                      <a
                        aria-describedby="footnote-label"
                        data-tip
                        data-for="onHoverTokens"
                        style={{ fontSize: 'large' }}
                      ></a>
                    </th>
                    <th style={otherTHsBalance} scope="col">
                      {endingTokenBalance}
                    </th>
                  </tr>
                </thead>
              </table>

              {requestedProductReports.requestedProductArray.length ? (
                <React.Fragment>
                  <div className="input-title-text">Added Products - Included in total above</div>
                  <ProductReportBox requestedProductReports={requestedProductReports} canDelete={false} />
                </React.Fragment>
              ) : (
                ''
              )}

              {!!nsf && <div className="m-error m0">This purchase request exceeds your available funds.</div>}
              {!!noaccess && (
                <div className="m-error m0">You are not allowed to purchase one or more of the requested reports.</div>
              )}
              <div className="m-flex-out">
                {allowGoingBackwards ? (
                  <span style={{ marginTop: '-20px' }}>
                    <button
                      disabled={isBusy}
                      onClick={() => setBottomModalState('PREV')}
                      className="modal-report-info-btn btn"
                    >
                      Back
                    </button>
                  </span>
                ) : (
                  <span></span>
                )}
                {hasErrors.hasReport && (
                  <span style={{ marginTop: '-20px' }}>
                    <button disabled={isBusy} onClick={() => sendData()} className="modal-report-info-btn btn">
                      Confirm <Spinner isVisible={isBusy} />
                    </button>
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BottomModalStage3;
