import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { tabToPath } from '../lib/utils';
import TabItem from './tabItem';

/** Functional component for a tab list that displays tab-specific content when a tab clicked */
const TabList = (props) => {
  //Props
  const { children } = props;

  //State
  const [activeTab, setActiveTab] = React.useState(tabToPath(props.forceSelected || props.children[0].props.label));

  //Refs
  const _isMounted = React.useRef(false);

  //Effects
  React.useEffect(() => {
    if (_isMounted.current) {
      props.history.push(props.pathBase + (props.pathBase.endsWith('/') ? '' : '/') + tabToPath(activeTab));
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (_isMounted.current && props.forceSelected != activeTab) {
      setActiveTab(props.forceSelected || props.children[0].props.label);
    }
  }, [props.forceSelected]);

  React.useEffect(() => {
    //componentDidMount
    _isMounted.current = true;

    //componentWillUnmount
    return () => {
      _isMounted.current = false;
    };
  }, []);

  //Event Handlers
  const onClickTabItem = (tab) => {
    setActiveTab(tabToPath(tab));
  };

  //Render
  return (
    <div className="tabs">
      <ol className="tab-list">
        {children.map((child) => {
          const { label } = child.props;
          return (
            <TabItem
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={() => {
                onClickTabItem(label);
              }}
            />
          );
        })}
      </ol>
      <div>
        {children
          .filter((child) => tabToPath(child.props.label) === activeTab)
          .map((child) => (
            <div key={child.props.label}>{child.props.children}</div>
          ))}
      </div>
    </div>
  );
};

TabList.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  pathBase: PropTypes.string.isRequired,
};

export default withRouter(TabList);
