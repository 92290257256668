import React from 'react';

const boxShadow = {
  boxShadow:
    'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  borderRadius: '4px',
};

const BoxShadow = (props) => {
  const { title, text, children, className, style, ...otherProps } = props;

  return (
    <div {...otherProps} className={`${className} card`} style={{ ...style, ...boxShadow }}>
      {title && (
        <div className="card-header">
          <div className="row">
            <div className="col">{title}</div>
          </div>
        </div>
      )}
      <div className="card-body">
        {text && <p className="card-text">{text}</p>}
        {children}
      </div>
    </div>
  );
};

export default BoxShadow;
