import React, { useEffect, useState, useRef } from 'react';
import CategoryFilterOptions from '../../categoryFilterOptions';
import SectionContainer from '../../sectionContainer';
import ProductManagementMain from './productManagementMain';
import ProductManagementTable, { ProductManagementView } from './productManagementTable';
import { toastError, urlFromPath, uuidIsValid } from '../../../lib/utils';
import config from '../../../config';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router';
import { assessmentTypes } from '../../../constants/assessmentTypes';

import './productManagement.css';

const filterCategoryClassName = {
  Tags: 'rbt-token',
};

const ProductManagement = (props) => {
  //Ref
  const scrollRef = useRef(null);
  const addEditRef = useRef(null);

  //Props
  const { vendorID, vendorName, canDownloadSBOMs, canManageProducts, canAccessProductOverview } = props;
  const { subsection: productID } = useParams();

  //State
  const [isBusy, setIsBusy] = useState(true);
  const [products, setProducts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState('name');
  const [dir, setDir] = useState('asc');
  const [searchText, setSearchText] = useState('');
  const [view, setView] = useState(ProductManagementView.Available);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [editProduct, setEditProduct] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [showProductDeleteModal, setShowProductDeleteModal] = useState(false); //The Product Delete Modal will be created in ticket A2V-1613.
  const [reloadData, setReloadData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentFilters, setCurrentFilters] = useState({
    //We should pull these from somewhere or compile it from the data in the current view?
    // Category: {
    //   'Category 1': false,
    //   'Category 2': false,
    //   'Category 3': false,
    //   'Category 4': false,
    //   'Category 5': false,
    // },
    // Tags: {
    //   'Tag 1': false,
    //   'Tag 2': false,
    //   'Tag 3': false,
    //   'Tag 4': false,
    //   'Tag 5': false,
    // },
    'SBOM Type': {},
  });
  const [availableReports, setAvailableReports] = useState([]);
  const [isAvailableReportsListLoading, setIsAvailableReportsListLoading] = useState(true);
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedSbomHash, setSelectedSbomHash] = useState(null);

  // const tableData = view === ProductManagementView.Upload ? recentlyAdded : products;
  const tableData = products;
  const pageSize = 10;

  //Effects
  useEffect(() => {
    if (vendorID) {
      getData();
      getAvailableReports();
    }
  }, [vendorID]);

  useEffect(() => {
    if (reloadData) {
      getData();
    }
  }, [reloadData]);

  useEffect(() => {
    setShowAddEdit(view === ProductManagementView.Upload || (productID && canManageProducts));
  }, [view]);

  useEffect(() => {
    scrollToAddEditSection();
  }, [showAddEdit]);

  useEffect(() => {
    if (canManageProducts) {
      if (productID && uuidIsValid(productID)) {
        setSelectedProducts([productID]);
        scrollToAddEditSection();
      } else {
        const showingAddEdit = view === ProductManagementView.Upload;
        setShowAddEdit(showingAddEdit);
        setSelectedProducts([]);

        if (showingAddEdit) {
          scrollToAddEditSection();
        }
      }
    }
  }, [productID]);

  // 1) grab state object(s) that should persist on page reload:
  useEffect(() => {
    setEditProduct(JSON.parse(window.localStorage.getItem('editProduct')));
  }, []);

  // 2) store state object(s) so they can persist on page reload:
  useEffect(() => {
    window.localStorage.setItem('editProduct', JSON.stringify(editProduct));
  }, [editProduct]);

  //Searching
  const handleSearch = () => {
    getData(1, orderBy, dir);
  };

  const handleFiltersApply = (newFilters) => {
    const selectedReports = [];
    Object.keys(newFilters['SBOM Type']).forEach((sbomType) => {
      if (newFilters['SBOM Type'][sbomType] === true) {
        const foundSBOM = availableReports.find((sbom) => sbom.name === sbomType);
        if (foundSBOM) {
          selectedReports.push(foundSBOM.rawType);
        }
      }
    });

    setSelectedReports(selectedReports);
    setCurrentFilters(newFilters);
    getData(1, orderBy, dir, selectedReports);
  };

  const getData = (_page = page, _orderBy = orderBy, _dir = dir, reportTypesRaw = selectedReports) => {
    if (vendorID) {
      setIsBusy(true);
      setProducts([]);
      setTotalRecords(0);

      fetch(
        config.api.urlFor('catalogProducts', {
          vendorID,
          search: searchText || '',
          pageSize,
          page: _page,
          orderBy: _orderBy,
          asc: _dir === 'asc' ? 1 : 0,
          productsManagement: true,
          inclProductVersionList: true,
          reportTypesRaw,
        }),
      )
        .then((resp) => resp.json())
        .then((resp) => {
          const rows = (resp.data || {}).rows || [];

          setIsBusy(false);
          setProducts(rows);
          setTotalRecords((resp.data || {}).totalRecords || 0);
          setOrderBy(_orderBy);
          setDir(_dir);
          setPage(_page);

          if (searchText === '') {
            setSearchText(undefined);
          }

          if (selectedProducts.length > 0) {
            if (canManageProducts) {
              const foundRow = rows.find((row) => {
                return selectedProducts.indexOf(row.productID) > -1;
              });
              if (foundRow) {
                setEditProduct(foundRow);
                setSelectedProducts([productID]);
                setShowAddEdit(true);
              } else {
                handleAddNewProductOnClick();
              }
            } else {
              scrollToSelectedRow();
            }
          }
        })
        .catch(() => {
          toastError('An error occurred attempting to load product information.');
          setIsBusy(false);
        })
        .finally(() => {
          setReloadData(false);
          setIsBusy(false);
        });
    }
  };

  //Event Handlers
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchKeydown = (event) => {
    if (searchText !== undefined && event.key === 'Enter') {
      handleSearch();
    } else if (event.key === 'Escape') {
      clearSearchText();
    }
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const handleAddNewProductOnClick = () => {
    setView(ProductManagementView.Upload);
    setEditProduct({});
    setRecentlyAdded([]);
    setSelectedProducts([]);
    setShowAddEdit(true);
    handleProductIDchange(null);
  };

  const swapToAvailableView = () => {
    setView(ProductManagementView.Available);
    setEditProduct({});
    setSelectedProducts([]);
    getData();
  };

  const swapToAvailableViewWithSelectedProduct = (productID) => {
    const foundProduct = products.find((p) => p.productID === productID);
    if (foundProduct) {
      setView(ProductManagementView.Available);
      setEditProduct(foundProduct);
      handleProductIDchange(productID);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleDeleteOnClick = () => {
    if (canManageProducts) {
      if (view === ProductManagementView.Delete) {
        //Call API for deletion
        handleOpenProductDeleteModal(true);
      } else {
        setView(ProductManagementView.Delete);
        setSelectedProducts([]);
      }
    }
  };

  const handleRowOnClick = (row, isSelect) => {
    if (canManageProducts) {
      const productID = row.productID;
      if (view === ProductManagementView.Delete) {
        const idx = selectedProducts.indexOf(productID);

        let newSelected = selectedProducts;

        if (idx > -1) {
          newSelected.splice(newSelected, 1);
        } else {
          newSelected.push(productID);
        }

        setSelectedProducts(newSelected);
      } else {
        if (isSelect) {
          setSelectedProducts([productID]);
          setEditProduct(row);
          row?.productVersions?.[0]?.hash && setSelectedSbomHash(row.productVersions[0].hash);
        } else {
          setSelectedProducts([]);
          setEditProduct({});
          setSelectedSbomHash(undefined);
        }

        if (showAddEdit) {
          scrollToAddEditSection();
        } else {
          setShowAddEdit(isSelect ? true : false);
        }
      }

      handleProductIDchange(isSelect ? productID : null);
    }
  };

  const handleProductIDchange = (selectedNewProductID) => {
    if (selectedNewProductID && uuidIsValid(selectedNewProductID)) {
      if (selectedProducts.indexOf(selectedNewProductID) < 0) {
        props.history.push(urlFromPath(props.match.path, { ...props.match.params, subsection: selectedNewProductID }));
      }
    } else {
      props.history.push(urlFromPath(props.match.path, { ...props.match.params, subsection: undefined }));
    }
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    getData(page, sortField, sortOrder);
  };

  const scrollToAddEditSection = () => {
    if (showAddEdit && addEditRef && addEditRef.current) {
      const domDim = addEditRef.current.getBoundingClientRect();

      if (domDim.bottom > (window.innerHeight || document.documentElement.clientHeight) || domDim.top < 0) {
        setTimeout(() => {
          addEditRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
      }
    }
  };

  const scrollToSelectedRow = () => {
    if (scrollRef && scrollRef.current) {
      const domDim = scrollRef.current.getBoundingClientRect();

      if (domDim.bottom > (window.innerHeight || document.documentElement.clientHeight) || domDim.top < 0) {
        setTimeout(() => {
          scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
      }
    }
  };

  const handleOpenProductDeleteModal = (showProductDeleteModal) => {
    setShowProductDeleteModal(showProductDeleteModal);
  };

  const getAvailableReports = () => {
    setIsAvailableReportsListLoading(true);
    fetch(config.api.urlFor('catalogReportTypes', { shouldHaveSBOMfeature: false }))
      .then((res) => res.json())
      .then((data) => {
        let availableReports = [];
        if (data && data.rows) {
          availableReports = data.rows
            .filter((row) => row.abbreviation === assessmentTypes.SBOM[0].abbreviation)
            .map((row) => {
              return {
                ...row,
                isAvailableImmediately: row.id === assessmentTypes.SBOM[0].id || row.id === assessmentTypes.SBOM[2].id,
              };
            });
        }
        setAvailableReports(availableReports);
        let sbomTypeFilterObj = {};
        availableReports.forEach((item) => (sbomTypeFilterObj = { ...sbomTypeFilterObj, [item.name]: false }));
        setCurrentFilters({ ...currentFilters, 'SBOM Type': sbomTypeFilterObj });
        setIsAvailableReportsListLoading(false);
      })
      .catch(() => {
        toastError('An error occurred attempting to load available reports.');
        setAvailableReports([]);
        setIsAvailableReportsListLoading(false);
      });
  };

  //Render
  return (
    <SectionContainer label="Products" className="row with-header">
      <div className="section-container-header">
        <div className="header-label">{`${vendorName ? `${vendorName}` : 'Company'} Products`}</div>
        <div className="header-search-container">
          <div className="header-search">
            <div className="header-searcher">
              <i
                className={'fas fa-search' + (searchText ? ' clickable-icon' : '')}
                onClick={searchText !== undefined ? handleSearch : undefined}
              ></i>
              <input
                type="text"
                value={searchText || ''}
                placeholder={'Search ' + (vendorName ? vendorName + ' ' : '') + 'Products Inventory'}
                onChange={handleSearchOnChange}
                onKeyDown={handleSearchKeydown}
                maxLength={256}
              />
              {searchText && (
                <button className="icon-btn" onClick={clearSearchText}>
                  <i className="far fa-times-circle"></i>
                </button>
              )}
            </div>
            <CategoryFilterOptions
              currentFilters={currentFilters}
              categoryOptionClassName={filterCategoryClassName}
              applyFiltersFn={handleFiltersApply}
              currentFiltersLoaded={!isAvailableReportsListLoading}
            />
          </div>
        </div>
        {canManageProducts && (
          <div className="header-btns">
            {view === ProductManagementView.Available && (
              <button onClick={handleAddNewProductOnClick}>
                <i className="fa fa-plus-circle"></i>ADD NEW PRODUCT
              </button>
            )}
            {view === ProductManagementView.Upload && (
              <button onClick={swapToAvailableView}>
                <i className="fa fa-edit"></i>EDIT
              </button>
            )}
            {/* Mass delete was removed but leaving in the functionality already completed if we change our mind */}
            {/* {view === ProductManagementView.Delete && (
            <button onClick={handleDeleteOnClick}>
              <i className="fa fa-trash-alt"></i>DELETE
            </button>
          )} */}
            {view !== ProductManagementView.Available && (
              <button className="cancel-btn" onClick={swapToAvailableView}>
                <i className="fa fa-times-circle"></i>CANCEL
              </button>
            )}
          </div>
        )}
      </div>
      <div className="product-management-view-container">
        {canManageProducts && (
          <div
            className={
              'product-upload-edit-section product-management-view-' + view + (showAddEdit ? ' show-upload-edit' : '')
            }
            ref={addEditRef}
          >
            <ProductManagementMain
              editProduct={editProduct}
              canDownloadSBOMs={canDownloadSBOMs}
              setReloadData={setReloadData}
              setEditProductByID={swapToAvailableViewWithSelectedProduct}
              sbomHash={selectedSbomHash}
            />
          </div>
        )}
        <div className="product-management-table-header">
          {/* <strong>{view === ProductManagementView.Upload ? 'Added Products' : 'Available Products'}</strong> */}
          <strong>Available Products</strong>
        </div>
        <div className={'product-management-table-section' + (canManageProducts ? ' rows-actionable' : '')}>
          <ProductManagementTable
            isBusy={isBusy}
            data={tableData}
            view={view}
            selectedProducts={selectedProducts}
            handleRowOnClick={handleRowOnClick}
            totalSize={totalRecords}
            sizePerPage={pageSize}
            page={page}
            onTableChange={handleTableChange}
            scrollRef={scrollRef}
            canManageProducts={canManageProducts}
            setSelectedSbomHash={setSelectedSbomHash}
            canAccessProductOverview={canAccessProductOverview}
          />
        </div>
      </div>
    </SectionContainer>
  );
};

export default withRouter(ProductManagement);
