import React from 'react';
import { useParams } from 'react-router-dom';
import { Busy, Spinner, useUpdateState, withPrincipal } from '../../../components';
import { toastError } from '../../../lib/utils';
import config from '../../../config';
import UploadDetails from './uploadDetails';
import UploadList from './uploadList';

import './imports.css';

//******************//
//   Logo Display   //
//******************//
const VendorLogoDisplay = (props) => {
  const { src } = props;

  return (
    <div className="imports-vendor-logo-container">
      <Busy isBusy={src === undefined}>
        <img
          alt="Logo"
          src={src}
          onError={(e) => {
            e.target.src = '';
            e.target.alt = '';
          }}
        />
      </Busy>
    </div>
  );
};

//*****************//
//   Import List   //
//*****************//
const ImportList = (props) => {
  //Props
  const { vendorLogoSrc } = props;

  //State
  const [state, setState] = useUpdateState({
    isBusy: false,
    model: [],
    page: 1,
    totalRecords: 0,
    counts: undefined,
  });

  const { isBusy, model, page, totalRecords, counts } = state;

  //Effects
  React.useEffect(() => {
    getData();
    getVendorsCount();
  }, []);

  //Functions
  const getData = (page) => {
    page = parseInt(page, 10, 1);

    setState({ isBusy: true, counts: undefined });

    fetch(config.api.urlFor('vendorCSVList', { page }))
      .then((response) => response.json())
      .then((data) =>
        setState({
          page,
          model: data.rows.map((row) => ({
            subscriber: row.subscriber,
            id: row.id,
            name:
              row.status === 3 ? (
                row.name
              ) : (
                <a href={'#' + config.routes.vendorImports.to.replace(':id?', row.id)}>{row.name}</a>
              ),
            status:
              row.status === 3
                ? 'complete'
                : row.status === 2
                ? 'dispositioning'
                : row.status === 1
                ? 'in progress'
                : 'new',
            created: new Date(row.created).toLocaleString(),
            updated: new Date(row.updated).toLocaleString(),
            action: (
              <>
                {row.status === 0 ? (
                  <button className="btn btn-primary" onClick={() => process(row.id)}>
                    Process
                  </button>
                ) : null}
              </>
            ),
          })),
          totalRecords: data.totalRecords || 0,
          isBusy: false,
        }),
      )
      .catch(() => {
        setState({ isBusy: false });
        toastError('An error occurred while attempting to load import list.');
      });
  };

  const getVendorsCount = () => {
    fetch(config.api.urlFor('vendorRisks', { page: 1 }))
      .then((response) => response.json())
      .then((data) => {
        setState({ counts: Object.assign({}, counts, { vendors: data.totalRecords }) });
      })
      .catch(() => {
        setState({ counts: Object.assign({}, counts, { vendors: 0 }) });
      });
  };

  const process = (uploadID) => {
    setState({
      model: model.map((row) => {
        if (row.id !== uploadID) {
          return row;
        }

        row.status = <Spinner isVisible={true} />;
        row.action = '';

        return row;
      }),
    });

    fetch(config.api.urlFor('vendorCSVProcess'), {
      method: 'POST',
      body: { id: uploadID },
    });

    setTimeout(() => {
      setState({
        model: state.model.map((row) => {
          if (row.id !== uploadID) {
            return row;
          }

          row.status = 'in progress';

          return row;
        }),
      });
    }, 1500);
  };

  const handleTableRefresh = () => {
    getData(1);
  };

  const handlePageChange = (page) => {
    getData(page);
  };

  //Render
  return (
    <>
      <div className="imports-header">
        <VendorLogoDisplay src={vendorLogoSrc} />
        <ImportListTiles counts={counts} />
      </div>
      <div className="imports-content">
        <h2>Imports</h2>
        <div className="imports-workqueue">
          <UploadList
            isBusy={isBusy}
            model={model}
            page={page}
            totalRecords={totalRecords}
            handleTableRefresh={handleTableRefresh}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

const ImportListTiles = (props) => {
  const { counts } = props;
  // const { vendors = 0, products = 0, inProgress = 0, completed = 0 } = counts || {}; // eslint mad about unused vars
  const { vendors = 0 } = counts || {};

  return (
    <div className="imports-list-tiles">
      <div className="imports-tile">
        <div className="imports-tile-header">Vendors</div>
        <div className="imports-tile-content">
          <Spinner isVisible={!counts}>{vendors}</Spinner>
        </div>
      </div>
      {/* TODO: Uncomment when adding bulk purchasing and values calculated */}
      {/* TODO: Also remove div.imports-list-tile selector in imports.css */}
      {/* <div className="imports-tile">
        <div className="imports-tile-header">Products</div>
        <div className="imports-tile-content">
          <Spinner isVisible={!counts}>{products}</Spinner>
        </div>
      </div>
      <div className="imports-tile">
        <div className="imports-tile-header">Assessments</div>
        <div className="imports-tile-table-display imports-tile-break">
          <div>
            <div className="imports-tile-subheader">In Progress</div>
            <div className="imports-tile-content">
              <Spinner isVisible={!counts}>{inProgress}</Spinner>
            </div>
          </div>
          <div>
            <div className="imports-tile-subheader">Completed</div>
            <div className="imports-tile-content">
              <Spinner isVisible={!counts}>{completed}</Spinner>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

//*****************//
//   Import File   //
//*****************//
const ImportFile = (props) => {
  //Props
  const { id, vendorLogoSrc } = props;

  //State
  const [state, setState] = useUpdateState({
    isBusy: false,
    model: [],
    page: 1,
    totalRecords: 0,
    counts: undefined,
  });

  const { isBusy, model, page, totalRecords, counts } = state;

  //Effects
  React.useEffect(() => {
    getData(id);
  }, [id]);

  //Functions
  const getData = (id, page) => {
    page = parseInt(page, 10, 1);

    setState({ isBusy: true });

    fetch(config.api.urlFor('vendorCSVResults', { id, page }))
      .then((response) => response.json())
      .then((data) => {
        setState({
          id,
          page,
          data: data.rows,
          model: data.rows.map((row) => ({
            id: row.id,
            name: row.name,
            domain: row.domain,
            committed: row.committed,
            // match:
            //   row.status === 0
            //     ? 'exact'
            //     : row.status === 1
            //     ? 'strong partial'
            //     : row.status === 2
            //     ? 'weak partial'
            //     : 'no match',
            // possible: (
            //   <>
            //     {row.possible.map((p, i) => (
            //       <div key={i}>
            //         <button className="link-button" onClick={() => select(row.id, p.id)}>
            //           {p.name}
            //         </button>
            //       </div>
            //     ))}
            //   </>
            // ),
            // recommended: (row.recommended || {}).name,
            selected: (
              <div className="form-group row mobile-padding col-md-8">
                <select
                  className="form-control"
                  onChange={(event) => {
                    selectionChange(event, row);
                  }}
                  defaultValue={(row.selected || {}).id}
                >
                  <option value={null}>-</option>
                  {row.possibleJSON
                    ? row.possibleJSON.map((option, i) => {
                        return (
                          <option key={i} value={option.id}>
                            {option.name} - {option.domain || '[domain not found]'} -{' '}
                            {Math.round(option.similarity * 100)}%
                          </option>
                        );
                      })
                    : []}
                </select>
              </div>
            ),
            // created: new Date(row.created).toLocaleString(),
            // updated: new Date(row.updated).toLocaleString(),
          })),
          totalRecords: data.totalRecords || 0,
          isBusy: false,
          counts: getCounts(data.rows),
        });
      })
      .catch(() => {
        setState({ isBusy: false });
        toastError('An error occurred while attempting to load import file data.');
      });
  };

  const selectionChange = async (event, row) => {
    setState({ isBusy: true });
    const selectedVendorID = event.target[event.target.selectedIndex].value;
    const importID = row.id;
    const domain = (row.possibleJSON.find((obj) => obj.id === selectedVendorID) || {}).domain || '-';
    if (selectedVendorID == '-') {
      await deselect(importID);
    } else {
      await select(importID, selectedVendorID, domain);
    }

    handleRefreshClick();
  };

  const getCounts = (rows) => {
    let counts = {
      exactMatch: 0,
      strongMatch: 0,
      weakMatches: 0,
      newVendors: 0,
    };

    for (var row of rows) {
      switch (row.status) {
        case 0:
          counts.exactMatch += 1;
          break;
        case 1:
          counts.strongMatch += 1;
          counts.newVendors += 1;
          break;
        case 2:
          counts.weakMatches += 1;
          counts.newVendors += 1;
          break;
        default:
          break;
      }
    }

    return counts;
  };

  const select = async (importID, providerID, domain) => {
    let previousSelected = '';

    setState({
      model: model.map((row) => {
        if (row.id !== importID) {
          return row;
        }

        previousSelected = row.selected;
        row.selected = <Spinner isVisible={true} size={2} />;

        return row;
      }),
    });

    await fetch(config.api.urlFor('selectImportMatch', { importID }), {
      method: 'POST',
      body: { providerID, domain },
    })
      .then((res) => res.json())
      .then(() => {
        setState({
          model: model.map((row) => {
            const rowData = state.data.find((x) => x.id === row.id);

            if (row.id !== importID) {
              return row;
            }

            row.selected = rowData.possible.find((p) => p.id === providerID).name;
            row.selected = (
              <button className="link-button" onClick={() => deselect(row.id)}>
                {row.selected}
              </button>
            );

            return row;
          }),
        });
      })
      .catch(() => {
        setState({
          model: model.map((row) => {
            if (row.id !== importID) {
              return row;
            }

            row.selected = previousSelected;

            return row;
          }),
        });
        toastError('An error occurred while processing selection. Changes not saved.');
      });
  };

  const deselect = async (importID) => {
    let previousSelected = '';

    setState({
      model: model.map((row) => {
        if (row.id !== importID) {
          return row;
        }

        previousSelected = row.selected;
        row.selected = <Spinner isVisible={true} size={2} />;

        return row;
      }),
    });

    await fetch(config.api.urlFor('selectImportMatch', { importID }), {
      method: 'POST',
      body: { providerID: null },
    })
      .then((res) => res.json())
      .then(() => {
        setState({
          model: model.map((row) => {
            if (row.id !== importID) {
              return row;
            }

            row.selected = '';

            return row;
          }),
        });
      })
      .catch(() => {
        setState({
          model: model.map((row) => {
            if (row.id !== importID) {
              return row;
            }

            row.selected = previousSelected;

            return row;
          }),
        });
        toastError('An error occurred while processing deselection. Changes not saved.');
      });
  };

  const handleRefreshClick = () => {
    getData(id, page);
  };

  const handlePageChange = (page) => {
    getData(id, page);
  };

  //Render
  return (
    <>
      <div className="imports-header">
        <VendorLogoDisplay src={vendorLogoSrc} />
        <ImportFileTiles counts={counts} />
      </div>
      <div className="imports-content">
        <h2>Vendor Matches</h2>
        <div className="imports-workqueue">
          <UploadDetails
            id={id}
            page={page}
            isBusy={isBusy}
            model={model}
            totalRecords={totalRecords}
            handleRefreshClick={handleRefreshClick}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

const ImportFileTiles = (props) => {
  const { counts } = props;
  const { exactMatch = 0, strongMatch = 0, weakMatches = 0, newVendors = 0 } = counts || {};

  return (
    <div className="imports-file-tiles">
      <div className="imports-tile">
        <div className="imports-tile-header">Exact Matches</div>
        <div className="imports-tile-content">
          <Spinner isVisible={!counts}>{exactMatch}</Spinner>
        </div>
      </div>
      <div className="imports-tile">
        <div className="imports-tile-header">Strong Matches</div>
        <div className="imports-tile-content">
          <Spinner isVisible={!counts}>{strongMatch}</Spinner>
        </div>
      </div>
      <div className="imports-tile">
        <div className="imports-tile-header">Weak Matches</div>
        <div className="imports-tile-content">
          <Spinner isVisible={!counts}>{weakMatches}</Spinner>
        </div>
      </div>
      <div className="imports-tile">
        <div className="imports-tile-header">New Vendors</div>
        <div className="imports-tile-content">
          <Spinner isVisible={!counts}>{newVendors}</Spinner>
        </div>
      </div>
    </div>
  );
};

//*****************//
//  Imports Page   //
//*****************//
const Import = (props) => {
  //Params
  const { id } = useParams();

  //Props
  const { principal } = props;

  //State
  const [state, setState] = useUpdateState({
    vendorLogoSrc: undefined,
  });

  const { vendorLogoSrc } = state;

  //Effect
  React.useEffect(() => {
    if (principal && principal.claimedPageVendorIds && principal.claimedPageVendorIds.length > 0) {
      const vendorID = principal.claimedPageVendorIds[0];
      fetch(`${config.api.urlFor('vendorScores', { id: vendorID })}`)
        .then((res) => res.json())
        .then((data) => {
          setState({ vendorLogoSrc: data.logoURL });
        })
        .catch(() => {
          setState({ vendorLogoSrc: '' }); //We might want to replace this with a placeholder logo
          toastError('An error occurred while attempting to load logo.');
        });
    }
  }, []);

  //Render
  return (
    <div className="imports-page">
      {id ? <ImportFile id={id} vendorLogoSrc={vendorLogoSrc} /> : <ImportList vendorLogoSrc={vendorLogoSrc} />}
    </div>
  );
};

export default withPrincipal(Import);
