import React, { Component } from 'react';
import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';
import { generateSymbol, generateColor } from '../../../../../constants/postern/riskIconGenerator.js';
import { makeGoogleMapsUrl } from '../../../../../lib/utils';
import SafeHyperlink from '../../../../safeHyperlink';

export default class Manufacturing extends Component {
  render() {
    const { scores = {}, manufacturing = [] } = this.props;
    const { manufacturing: sManufacturing = '' } = scores;

    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          {sManufacturing === 'high'
            ? 'During our review of the manufacturing facilities, some were found to have locations in countries of adversarial presence. Further investigation concludes that the foreign influence from manufacturing is likely significant'
            : 'During our review of the manufacturing facilities, none were found to have locations in countries of adversarial presence. Further investigation concludes that the foreign influence from manufacturing is not likely significant, and thus no finding is raised.'}
        </div>

        {manufacturing.length > 0 ? (
          <ReusableProvenanceTable
            tableName="ManufacturingTable-"
            headers={['', 'Country', 'City', 'Known Address', 'Evidence']}
          >
            {manufacturing.map((row, i) => {
              return (
                <React.Fragment key={'ManufacturingTable-' + i}>
                  <tr className="provenance-table-row">
                    <th className="row-padding" scope="row">
                      {' '}
                      <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                    </th>
                    <td> {row.country} </td>
                    <td> {row.city} </td>
                    <td>
                      {' '}
                      <SafeHyperlink url={makeGoogleMapsUrl(row.country, row.city, row.known_address)}>
                        {row.known_address}
                      </SafeHyperlink>
                    </td>
                    <td className="m-word-break">
                      {' '}
                      <SafeHyperlink url={row.evidence}>{row.evidence}</SafeHyperlink>{' '}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </ReusableProvenanceTable>
        ) : null}

        <footer className="footer-p-wrapper">
          <p>
            During the process of gathering physical presence, a check for manufacturing and production facilities is
            conducted. Countries with these facilities are noted for a vendor. The first-source data is gathered from
            the corporate website. If there is no/limited information about these locations, then a search of
            manufacturing careers is performed on the company and then third-party websites. If no manufacturing is
            confirmed using publicly available tools, then this is noted in the assessment.
          </p>
        </footer>
      </React.Fragment>
    );
  }
}
