import { INFORMATIONAL, MARGINAL, LOW, MODERATE, HIGH, CRITICAL } from '.';
import { TIER_ONE, TIER_TWO, TIER_THREE } from '.';
import { GRADE_A, GRADE_B, GRADE_C, GRADE_D } from '.';

// TODO: whatever color pallete we end up using, we should aim to
// standardize it's usage for all components across the application
export const MARGINALBLUE = '#357ABD';
export const LOWGREEN = '#4CAE4C';
export const MODERATEYELLOW = '#F6BE00';
export const HIGHORANGE = '#CC1818';
export const CRITITCALRED = '#CC1818';
export const PENDINGGREY = '#303030';

export const cyberHygieneVulnerabilityColorByScore = (score) => {
  return score
    ? score >= 9
      ? CRITITCALRED
      : score >= 7 && score < 9
      ? HIGHORANGE
      : score >= 4 && score < 7
      ? MODERATEYELLOW
      : score >= 0 && score < 4
      ? LOWGREEN
      : MARGINALBLUE
    : PENDINGGREY;
};

export const riskRankColorByString = (string) =>
  string === MARGINAL || string === INFORMATIONAL
    ? MARGINALBLUE
    : string === LOW
    ? LOWGREEN
    : string === MODERATE
    ? MODERATEYELLOW
    : string === HIGH
    ? HIGHORANGE
    : string === CRITICAL
    ? CRITITCALRED
    : PENDINGGREY;

// OLD:
// export const riskRankColorByGrade = (score) => {
//     return (
//         score ?
//             score === GRADE_A ? MARGINALBLUE :
//                 score === GRADE_B ? LOWGREEN :
//                     score === GRADE_C ? MODERATEYELLOW :
//                         score === GRADE_D ? HIGHORANGE :
//                             CRITITCALRED :
//             PENDINGGREY
//     )
// }

// NEW - but may be changed still:
export const riskRankColorByGrade = (score) => {
  return score
    ? score === GRADE_A
      ? LOWGREEN
      : score === GRADE_B
      ? LOWGREEN
      : score === GRADE_C
      ? MODERATEYELLOW
      : score === GRADE_D
      ? HIGHORANGE
      : CRITITCALRED
    : PENDINGGREY;
};

export const riskRankColorNameByGrade = (score) => {
  return score
    ? score === GRADE_A
      ? 'low-green'
      : score === GRADE_B
      ? 'low-green'
      : score === GRADE_C
      ? 'moderate-yellow'
      : score === GRADE_D
      ? 'high-orange'
      : 'critical-red'
    : 'pending-grey';
};

export const riskRankColorByTier = (tier) => {
  return tier && tier !== '-'
    ? tier === TIER_ONE
      ? CRITITCALRED
      : tier === TIER_TWO
      ? HIGHORANGE
      : tier === TIER_THREE
      ? MODERATEYELLOW
      : LOWGREEN
    : PENDINGGREY;
};

export const riskRankColorNameByTier = (tier) => {
  return tier && tier !== '-'
    ? tier === TIER_ONE
      ? 'critical-red'
      : tier === TIER_TWO
      ? 'high-orange'
      : tier === TIER_THREE
      ? 'moderate-yellow'
      : 'low-green'
    : 'pending-grey';
};

export const riskRankColorByScore = (score) => {
  return score
    ? score > 89
      ? MARGINALBLUE
      : score > 79
      ? LOWGREEN
      : score > 69
      ? MODERATEYELLOW
      : score > 59
      ? HIGHORANGE
      : CRITITCALRED
    : PENDINGGREY;
};

// approval indicators
export const APPROVEDBLUE = '#377FC7';
export const PENDINGRED = '#EC3E40';
export const COMPLETEDGREEN = '#01A46D';
export const DEFAULTYELLOW = '#F3D600';

export const APPROVALBYSTRING = (string) =>
  string === 'Approved'
    ? APPROVEDBLUE
    : string === 'Pending Remediation'
    ? PENDINGRED
    : string === 'Completed'
    ? COMPLETEDGREEN
    : DEFAULTYELLOW;

// card background colors
export const CARDBLUE = 'rgb(53, 122, 189)';
export const CARDRED = 'rgb(212, 62, 58)';
export const CARDGREEN = 'rgb(76, 174, 76)';
export const CARDDARKBLUE = 'rgb(0, 0, 64)';
export const CARDPURPLE = 'rgb(48, 0, 48)';

// hover color
export const HOVERTAN = '#f7dab7';
export const DARKTAN = '#F39C12';

// nav elements
export const NAVBLUE = '#2C3E50';

// text colors
export const TEXTDARKBLUE = '#3A5364';

// component background colors
export const BACKGROUNDLIGHTGRAY = '#F6F6F6';

// others
export const A2VLOGOBLUE = '#00788A';
