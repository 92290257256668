import React, { Component } from 'react';
import Modal from 'react-modal';
import config from '../config';
import { withRouter } from 'react-router-dom';
import withPrincipal from './withPrincipal';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class DisputeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalConfirmationIsOpen: false,
      user: props.principal,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }
  openConfirmationModal() {
    this.setState({ modalConfirmationIsOpen: !this.state.modalConfirmationIsOpen });
  }

  submitDispute() {
    fetch(config.api.urlFor('providerDisputes'), {
      method: 'POST',
      body: {
        providerId: this.props.match.params.id,
        disputeCategory: this.state.disputeSelect,
        disputeDetail: this.state.disputeContent,
        userEmail: this.state.user.emailAddress,
        userCompany: this.state.user.associateName,
        userId: this.state.user.id,
      },
    }).then(() => {
      return;
    });
  }

  render() {
    return (
      <div>
        <button
          data-test-id="DisputeButton-button-1"
          className="btn btn-outline-primary btn-nav float-right"
          onClick={this.openModal}
        >
          Dispute
        </button>

        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyle}
          contentLabel="Dispute Input"
        >
          <form data-test-id="DisputeButton-modal-1">
            <div>
              <label data-test-id="DisputeButton-modal-label-1">What would you like to dispute?</label>
              <select
                data-test-id="DisputeButton-Select"
                className="form-control"
                onChange={(e) => {
                  this.setState({ disputeSelect: e.target.value });
                }}
                value={this.state.disputeSelect}
              >
                <option value="selected">Select Area</option>
                <option>Cyber Risk Score</option>
                <option>Other</option>
              </select>
            </div>
            <div style={{ marginTop: '15px' }}>
              <label data-test-id="DisputeButton-modal-label-2">
                Please provide details regarding the dispute. <br /> Specify the vendor, and the specific cyber scores:
              </label>
              <textarea
                data-test-id="DisputeButton-textarea"
                className="form-control"
                rows="4"
                cols="50"
                onChange={(e) => {
                  this.setState({ disputeContent: e.target.value });
                }}
                value={this.state.disputeContent}
                style={{ width: '100%' }}
                maxLength={1000}
              ></textarea>
            </div>
          </form>
          <div style={{ paddingTop: '15px' }}>
            <button
              data-test-id="DisputeButton-button-submit"
              className="btn btn-outline-primary btn-nav float-right"
              onClick={(event) => {
                event.preventDefault();
                this.openModal();
                this.openConfirmationModal();
                this.submitDispute();
              }}
            >
              Submit
            </button>
            <button
              data-test-id="DisputeButton-button-cancel"
              className="btn btn-outline-primary btn-nav float-right"
              style={{ marginRight: '15px' }}
              onClick={this.openModal}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalConfirmationIsOpen}
          onAfterOpen={this.afterOpenConfirmationModal}
          onRequestClose={this.closeConfirmationModal}
          style={modalStyle}
          contentLabel="Dispute Confirm"
        >
          <label data-test-id="DisputeButton-Confirm-Label">
            Thank you for the information. We will investigate your dispute and contact you if any additional details
            are needed.
          </label>
          <div style={{ paddingTop: '15px' }}>
            <button
              data-test-id="DisputeButton-button-close"
              className="btn btn-outline-primary btn-nav float-right"
              style={{ marginRight: '15px' }}
              onClick={this.openConfirmationModal}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(withPrincipal(DisputeButton));
