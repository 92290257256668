import React, { Component } from 'react';

import ReusableProvenanceTable from './reusableProvenanceTable';

import './appendixWatchlist.css';

export default class AppendixWatchlist extends Component {
  render() {
    const { watchlistDesc = [] } = this.props;

    return (
      <>
        {watchlistDesc.length > 0 ? (
          <div id="ap-watchlist-table">
            <ReusableProvenanceTable tableName="AppendixWatchlistTable-" headers={['Watchlist', 'Description']}>
              {watchlistDesc.map((row, i) => {
                return (
                  <React.Fragment key={'AppendixWatchlistTable-' + i}>
                    <tr className="provenance-table-row">
                      <td className="row-padding"> {row.watchlist} </td>
                      <td className="row-padding"> {row.description} </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </ReusableProvenanceTable>
          </div>
        ) : null}
      </>
    );
  }
}
