import React, { Component } from 'react';

import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';

import { generateSymbol, generateColor } from '../../../../../constants/postern/riskIconGenerator.js';

// import "./watchlist.css"

export default class Watchlist extends Component {
  render() {
    const { watchlist = [] } = this.props;
    const watchlistHasResults = watchlist.length > 0;

    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          {watchlistHasResults
            ? 'Our review of the watchlist identified some hits on the company, causing the following findings to be raised.'
            : 'Our review of the watchlist has identified no hits on the company, therefore no findings have been raised.'}
        </div>

        {watchlistHasResults ? (
          <ReusableProvenanceTable
            tableName="ManufacturingTable-"
            headers={['', 'Watchlist', 'Notes', 'Company Name', 'Source']}
          >
            {watchlist.map((row, i) => {
              return (
                <React.Fragment key={'ManufacturingTable-' + i}>
                  <tr className="provenance-table-row">
                    <th className="row-padding" scope="row">
                      {' '}
                      <SeverityCircle symbol={generateSymbol(true)} color={generateColor(true)} />{' '}
                    </th>
                    <td> {row.watchlist} </td>
                    <td> {row.notes} </td>
                    <td> {row.company_name} </td>
                    <td className="m-word-break">
                      {' '}
                      <a rel="noreferrer" href={row.source} target="_blank">
                        {row.source}
                      </a>{' '}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </ReusableProvenanceTable>
        ) : null}

        <footer className="footer-p-wrapper">
          <p className="footer-multi-p">
            Evaluation was conducted against 16 core watchlists including OFAC SDN/NSDN <sup>1</sup>, over 25,000 news
            sources tracking entities linked to illicit activities, over 1,000 enforcement lists and court filings{' '}
            <sup>2</sup> including FDA, US HHS, UK FSA, SEC, BOE, FBI, BIS.
          </p>
          <p className="footer-multi-p">
            <sup>1</sup>. The top 16 “core” watchlists is comprised of the list below. Fortress utilizes retail reports
            from companies like LexisNexis / Thompson Reuters to ensure high quality. Screenshot evidence is documented
            in the monitoring report. 1. European Union Consolidated List 2. United Kingdom Her Majesty&apos;s Treasury
            3. United Kingdom Her Majesty&apos;s Treasury Sanctions 4. Office of Foreign Assets Control Non-Specially
            Designated National Entities 5. Office of Foreign Assets Control Sanctions 6. Office of Foreign Assets
            Control Specially Designated Nationals 7. United Nations Consolidated List 8. Bureau of Industry and
            Security 9. Excluded Parties List System 10. FATF Financial Action Task Force 11. Office of the Inspector
            General Exclusions 12. Office of the Superintendent of Financial Institutions Consolidated List—Canada 13.
            Office of the Superintendent of Financial Institutions Country 14. Primary Money Laundering Concern 15.
            Primary Money Laundering Concern—Jurisdictions 16. Unauthorized Banks
          </p>
          <p className="footer-multi-p">
            <sup>2</sup>These enforcement lists represent some of the most notorious terrorists and members of drug
            cartels found anywhere in the world. • FDA – Food &#38; Drug Administratio n• US HHS - United States
            Department of Health and Human Services • UK FSA - Financial Services Authority • SEC – Securities and
            Exchange Commission • BOE – Federal Maritime Commission Bureau of Enforcement • FBI - Federal Bureau of
            Investigation • BIS - Department of Commerce&apos;s Bureau of Industry and Security
          </p>
          <p className="footer-multi-p">
            Corporate entities are assessed for their association with any national or global watchlists, such as lists
            from Office of Foreign Assets Controls (OFAC), European Union (EU) Consolidated List, Foreign Agents
            Registrations, United Nations Consolidated List, and more.
          </p>
        </footer>
      </React.Fragment>
    );
  }
}
