import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import { useUpdateState } from '../..';
import config from '../../../config';
import { emailIsValid, formatPhoneNumber, nameIsValid, toastError, toastSuccess } from '../../../lib/utils';
import Spinner from '../../spinner';

import './unclaimedVendorContactDetailsModal.css';

const UnclaimedVendorContactDetailsModal = (props) => {
  //Props
  const { vendorID, fileClassID, handleModalClose = () => {} } = props;
  const modalIsOpen = !!(vendorID && fileClassID);

  //State
  const [state, setState] = useUpdateState({
    contactName: '',
    phoneNumber: '',
    emailAddress: '',
    errorFields: {
      contactName: false,
      phoneNumber: false,
      emailAddress: false,
    },
    touchedFields: {
      contactName: false,
      phoneNumber: false,
      emailAddress: false,
    },
    isBusy: false,
  });

  const { contactName, phoneNumber, emailAddress, errorFields = {}, touchedFields = {}, isBusy } = state;

  //Helper Functions
  const submitRequest = (event) => {
    event.preventDefault();

    setState({ isBusy: true });

    if (!vendorID || !fileClassID) {
      setState({ isBusy: false });
      toastError('Sending Vendor Contact Information Failed.');
      return;
    }

    const vendorContactInfo = {
      contactName,
      phoneNumber,
      emailAddress,
    };

    fetch(config.api.urlFor('fileItemRequest'), {
      method: 'POST',
      body: {
        vendorID,
        fileClassID,
        vendorContactInfo,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setState({ isBusy: false });

        if (res && res.isSuccess) {
          handleModalClose();
          toastSuccess('Vendor Contact Information Sent, and File Request Submitted.');
        } else {
          toastError(`Vendor Contact Information Failed to Send${res.message ? `: ${res.message}` : '.'}`);
        }
      })
      .catch(() => {
        setState({ isBusy: false });
        toastError('An error occurred. Please try again and contact support if you encounter additional issues.');
      });
  };

  const handleInputBox = (e, keyName) => {
    let val = e.target.value;
    let hasError = false,
      isNameValid = true,
      isNameEmpty = false;

    if (keyName === 'contactName') {
      if (!val.length) {
        isNameEmpty = true;
        hasError = true;
      } else if (!nameIsValid(val)) {
        isNameValid = false;
      }
    } else if (keyName === 'phoneNumber') {
      val = formatPhoneNumber(val);
      e.target.value = val;

      if (val.length > 1 && val.length !== 14) {
        hasError = true;
      }
    } else if (keyName === 'emailAddress') {
      if (val.length && !emailIsValid(val)) {
        hasError = true;
      }
    }

    if (isNameValid || isNameEmpty) {
      setState({
        [keyName]: val,
        errorFields: { ...errorFields, [keyName]: hasError },
      });
    }
  };

  const handleBlur = (e) => {
    setState({
      touchedFields: { ...touchedFields, [e.target.id]: true },
    });
  };

  const chkSubmitEnabled = () => {
    return (
      contactName &&
      contactName.length &&
      emailAddress &&
      emailAddress.length &&
      !Object.keys(errorFields).some((el) => errorFields[el] === true)
    );
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter' && chkSubmitEnabled()) {
      submitRequest(event);
    }
  };

  //Render
  return (
    <Modal
      isOpen={modalIsOpen}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
          background: 'none',
          overflow: 'unset',
          zIndex: '5',
        },
      }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
    >
      <div id="unclaimed-vendor-contact-details-modal" className="modal-report-info-box modal-mt-override">
        <p className="m-tal-c m-bold pb-4 mb-0">
          Please provide the supplier&apos;s contact information if available.{' '}
        </p>
        <div
          className="alert fade show pb-1 unclaimed-vendor-contact-details-modal-alert-div"
          role="alert"
          id="alert"
          style={{ color: 'black' }}
        >
          <div className="unclaimed-vendor-contact-details-modal-alert-icon-div">
            <AiFillInfoCircle className="unclaimed-vendor-contact-details-modal-alert-icon" />
            <p style={{ marginLeft: '10px' }}>Don&apos;t have your vendor&apos;s contact information?</p>
          </div>
          <p style={{ marginLeft: '28px' }}>
            Use the &quot;Need Help?&quot; button in the bottom right to create a helpdesk ticket for us to see if we
            can locate the right contact on your behalf. Be sure to{' '}
            <b>include the company name in the helpdesk request</b>.
          </p>
        </div>
        <form>
          <div className="sa-modal-btn-container">
            <div className="sa-modal-close" onClick={handleModalClose}>
              x
            </div>
            <div className="pb-2">
              <div className="input-title-text">Vendor Contact Name*</div>
              <input
                value={contactName}
                id="contactName"
                className={'form-control' + (errorFields.contactName && touchedFields.contactName ? ' is-invalid' : '')}
                type="text"
                placeholder="Contact Name"
                onChange={(e) => handleInputBox(e, 'contactName')}
                onBlur={handleBlur}
                maxLength={160}
                onKeyPress={handleEnterKeyPress}
              />
              {!!(errorFields.contactName && touchedFields.contactName) && (
                <span className="m-error"> Please enter a valid vendor contact name </span>
              )}
            </div>
            <div className="pb-2">
              <div className="input-title-text">Phone Number</div>
              <input
                value={phoneNumber}
                id="phoneNumber"
                className={'form-control' + (errorFields.phoneNumber && touchedFields.phoneNumber ? ' is-invalid' : '')}
                type="text"
                placeholder="(___) ___-____"
                onChange={(e) => handleInputBox(e, 'phoneNumber')}
                onBlur={handleBlur}
                onKeyPress={handleEnterKeyPress}
              />
              {!!(errorFields.phoneNumber && touchedFields.phoneNumber) && (
                <span className="m-error"> Please enter a valid phone number </span>
              )}
            </div>
            <div className="pb-2">
              <div className="input-title-text">Email*</div>
              <input
                value={emailAddress}
                id="emailAddress"
                className={
                  'form-control' + (errorFields.emailAddress && touchedFields.emailAddress ? ' is-invalid' : '')
                }
                type="text"
                placeholder="example@domain.com"
                onChange={(e) => handleInputBox(e, 'emailAddress')}
                onBlur={handleBlur}
                maxLength={160}
                onKeyPress={handleEnterKeyPress}
              />
              {!!(errorFields.emailAddress && touchedFields.emailAddress) && (
                <span className="m-error"> Please enter a valid email address </span>
              )}
            </div>
          </div>

          <hr></hr>

          <div className="sa-modal-btn-container">
            <div className="sa-modal-btns-right">
              <button className="btn btn-outline-primary btn-nav sa-modal-cancel-btn" onClick={handleModalClose}>
                Cancel
              </button>
              <button
                className="btn btn-outline-primary btn-nav sa-modal-submit-btn"
                onClick={submitRequest}
                disabled={!chkSubmitEnabled() || isBusy}
              >
                Submit
                <Spinner style={{ marginLeft: '5px' }} isVisible={isBusy} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UnclaimedVendorContactDetailsModal;
