import React, { Component } from 'react';

import TileComponent from './tileComponent';
import { scrollToSection } from '../../../../../constants/postern/scrollToSection.js';

import './compliance.css';

export default class Compliance extends Component {
  render() {
    const { compliance = {} } = this.props;
    const { OSHA, WHD, HIPAA, EPA, WARN } = compliance;

    return (
      <React.Fragment>
        <p>
          For more information on compliance findings, please reference the{' '}
          <a
            className="navLink configurable-link"
            href={`provenance-appendix-compliance`}
            onClick={(e) => scrollToSection(e, `provenance-appendix-compliance`)}
          >
            appendix
          </a>
          .
        </p>
        <div className="provenance-tile-holder compliance-tile-holder">
          <TileComponent
            title={'OSHA'}
            highlight={OSHA.findings.length + ' Finding'.concat(OSHA.findings.length != 1 ? 's' : '')}
            color={OSHA.findings.length == 0 ? '#1ab394' : '#ed5565'}
          />
          <TileComponent
            title={'WHD'}
            highlight={WHD.findings.length + ' Finding'.concat(WHD.findings.length != 1 ? 's' : '')}
            color={WHD.findings.length == 0 ? '#1ab394' : '#ed5565'}
          />
          <TileComponent
            title={'HIPAA'}
            highlight={HIPAA.findings.length + ' Finding'.concat(HIPAA.findings.length != 1 ? 's' : '')}
            color={HIPAA.findings.length == 0 ? '#1ab394' : '#ed5565'}
          />
          <TileComponent
            title={'EPA'}
            highlight={EPA.findings.length + ' Finding'.concat(EPA.findings.length != 1 ? 's' : '')}
            color={EPA.findings.length == 0 ? '#1ab394' : '#ed5565'}
          />
          <TileComponent
            title={'WARN'}
            highlight={WARN.findings.length + ' Finding'.concat(WARN.findings.length != 1 ? 's' : '')}
            color={WARN.findings.length == 0 ? '#1ab394' : '#ed5565'}
          />
        </div>
        <footer className="footer-p-wrapper">
          <p>
            The organization is matched against Fortress continuous monitoring database for regulatory compliance
            violations and activity. This includes data related to the Occupational Safety and Health Administration
            (OSHA), Wage and Hour Division (WHD), Health Insurance Portability and Accountability Act (HIPAA),
            Environmental Protection Agency (EPA), and Worker Adjustment and Retraining Notification (WARN).
          </p>
        </footer>
      </React.Fragment>
    );
  }
}
