import React from 'react';

const SectionContainer = (props) => {
  const { id = '', label, children, className = '', loading = false } = props;

  return (
    <div id={id} className={'section-container ' + className + (loading ? ' is-busy-centered' : '')}>
      <div className="section-heading">{label}</div>
      {children}
    </div>
  );
};

export default SectionContainer;
