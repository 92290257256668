import React from 'react';

import PropTypes from 'prop-types';

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DownloadManager from '../src/components/downloadManager';
import LayoutBase from '../src/components/layoutBase';

import config from './config';

import Login from './routes/login/login';
import Logout from './routes/logout/logout';
import Admin from './routes/admin/admin';
import Users from './routes/users';
import SubscriberUsers from './routes/subscribers/users';
import Roles from './routes/roles/roles';
import Subscribers from './routes/subscriptions/subscribers';
import Reports from './routes/reports';
import Providers from './routes/providers';
import Invitations from './routes/invitations';
import Generate from './routes/generate/generate';
import withQueryString from './components/withQueryString';
import withPrincipal from './components/withPrincipal';
import ForgotPassword from './routes/login/forgotPassword';
import RegisterWrapper from './routes/register';
import Integrations from './routes/subscribers/integrations';
import ResetPassword from './routes/reset-password';
import Help from './routes/helpMenu';
import TargetGroups from './routes/admin/targetGroups';
import YourInfo from './routes/account/yourinfo';
import UserManagement from './routes/organization/userManagement';
import PDFViewer from './routes/pdf/pdfviewer';

import PosternReport from './routes/reports/postern';
import ScoreBreakdown from './routes/reports/scoreBreakdown';
import Vulnerabilities from './routes/reports/vulnerabilities';
import ProviderTargets from './routes/reports/providerTargets';
import About from './routes/helpMenu/about';
import Contact from './routes/helpMenu/contact';
import getStarted from './routes/helpMenu/getStarted';
import privacyPolicy from './routes/helpMenu/privacyPolicy';
import termsOfUse from './routes/helpMenu/termsOfUse';
import dcmaNotice from './routes/helpMenu/dcmaNotice';
import releaseNotes from './routes/helpMenu/releaseNotes';
import RequestSupport from './routes/helpMenu/requestSupport';
import RiskRankOrg from './routes/riskRankOrg/riskRankOrg';
import ErrorPage from './routes/error';
import { Home, Catalog, TrustCenterHome } from './routes/vendor';

import CatalogVendorOverview from './routes/vendor/catalogVendorOverview';
import CatalogProductOverview from './routes/product/catalogProductOverview';

import Footer from './components/footer';
import HelpPanel from './components/helpPanel';

import RiskRank from './routes/riskRank/riskRank';
import { Layout } from './components';
import billingHistory from './routes/organization/billingHistory';
import Import from './routes/admin/import/import';
import CatalogProducts from './routes/vendor/catalogProducts';

const DefaultContent = () => <Redirect to="/" />;

let ProtectedContent = (props) => {
  const content = !props.principal ? (
    <Route component={Login} />
  ) : (
    <Layout>
      <Switch>
        <Route exact path={config.routes.admin.to} component={Admin} />
        <Route exact path={config.routes.subscriberIntegrations.to} component={Integrations} />
        <Route exact path={config.routes.posternReport.to} component={PosternReport} />
        <Route path={config.routes.users.to} component={Users} />
        <Route path={config.routes.subscriberUsers.to} component={SubscriberUsers} />
        <Route path={config.routes.roles.to} component={Roles} />
        <Route path={config.routes.subscribers.to} component={Subscribers} />
        <Route path={config.routes.providers.to} component={Providers} />
        <Route path={config.routes.invitations.to} component={Invitations} />
        <Route path={config.routes.targetGroups.to} component={TargetGroups} />
        <Route path={config.routes.reports.to} component={Reports} />
        <Route exact path={config.routes.catalogVendor.to} component={CatalogVendorOverview} />
        <Route exact path={config.routes.catalogProduct.to} component={CatalogProductOverview} />
        <Route exact path={config.routes.catalogShort.to} component={() => <Redirect to="/catalog/name/asc/1" />} />
        <Route exact path={config.routes.catalogFull.to} component={Catalog} />
        <Route
          exact
          path={config.routes.catalogProductsShort.to}
          component={() => <Redirect to="/catalog/products/name/asc/1" />}
        />
        <Route exact path={config.routes.catalogProductsFull.to} component={CatalogProducts} />
        {/* <Route exact path={config.routes.myVendorsShort.to} component={() => <Redirect to="/vendor/my/name/asc/1" />} /> */}
        <Route path={config.routes.vendorHome.to} component={Home} />
        <Route path={config.routes.riskRankOrg.to} component={RiskRankOrg} />
        <Route path={config.routes.riskRank.to} component={RiskRank} />
        <Route path={config.routes.billing.to} component={billingHistory} />
        <Route exact path={config.routes.logout.to} component={Logout} />
        <Route exact path={config.routes.generate.to} component={Generate} />
        <Route exact path={config.routes.yourinfo.to} component={YourInfo} />
        <Route exact path={config.routes.userManagement.to} component={UserManagement} />
        <Route exact path={config.routes.pdfviewer.to} component={PDFViewer} />
        {props.principal.isTrustCenterEnabled && (
          <Route exact path={config.routes.trustCenter.to} component={TrustCenterHome} />
        )}
        <Route path={config.routes.help.to} component={withPrincipal(Help)} />
        <Route path={config.routes.vendorImports.to} component={Import} />
        <Route path="/404" component={ErrorPage} />
        <Route path="/" component={() => <Redirect to="/catalog/name/asc/1" />} />
        <Route path="" component={DefaultContent} />
      </Switch>
    </Layout>
  );

  return content;
};

ProtectedContent = withPrincipal(ProtectedContent);

const GeneralContent = () => (
  <Switch>
    <Route exact path="/forgotPassword" component={ForgotPassword} />
    <Route exact path="/resetPassword/:id" component={ResetPassword} />
    <Route path="/register" component={RegisterWrapper} />
    <Route path="/help" component={withPrincipal(Help)} />
    <Route path="/" component={Login} />
    <Route path="" component={() => <Redirect to="/" />} />
  </Switch>
);

let ProviderContent = (props) => {
  const { principal } = props;

  return (
    <div className="m-lg-3">
      <LayoutBase>
        <Switch>
          <Route
            path="/reports/score/provider/:score"
            component={({ match }) => (
              <ScoreBreakdown tier={`provider`} score={match.params.score} id={principal.associateID} />
            )}
          />
          <Route
            path="/reports/score/provider/:score"
            component={({ match }) => (
              <ScoreBreakdown tier={`provider`} score={match.params.score} id={principal.associateID} />
            )}
          />
          <Route
            path="/reports/vulnerabilities/:tier/:page"
            component={(props) => <Vulnerabilities tier={`provider`} id={principal.associateID} {...props} />}
          />
          <Route
            path="/reports/provider/:id/targets"
            component={(props) => <ProviderTargets id={principal.associateID} {...props} />}
          />
          <Route exact path="/help/contact" component={Contact} />
          <Route exact path="/help/getStarted" component={getStarted} />
          <Route exact path="/help/about" component={About} />
          <Route exact path="/help/privacyPolicy" component={privacyPolicy} />
          <Route exact path="/help/termsOfUse" component={termsOfUse} />
          <Route exact path="/help/dmcaNotice" component={dcmaNotice} />
          <Route exact path="/help/releaseNotes" component={releaseNotes} />
          <Route exact path="/help/requestSupport" component={RequestSupport} />
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </LayoutBase>
    </div>
  );
};

ProviderContent = withPrincipal(ProviderContent);

const RolelessContent = () => {
  return (
    <div className="m-lg-3">
      <LayoutBase />
      <Switch>
        <Route path="/404" component={ErrorPage} />
        <Route exact path="/help/contact" component={Contact} />
        <Route exact path="/help/getStarted" component={getStarted} />
        <Route exact path="/help/about" component={About} />
        <Route exact path="/help/privacyPolicy" component={privacyPolicy} />
        <Route exact path="/help/termsOfUse" component={termsOfUse} />
        <Route exact path="/help/dmcaNotice" component={dcmaNotice} />
        <Route exact path="/help/releaseNotes" component={releaseNotes} />
        <Route exact path="/help/requestSupport" component={RequestSupport} />
        <Route exact path="/logout" component={Logout} />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};

let TrustCenterContent = (props) => {
  return (
    <div className="m-lg-3">
      <Layout showOnlyTrustCenterContent />
      <Switch>
        {props.principal.isTrustCenterEnabled && (
          <Route exact path={config.routes.trustCenter.to} component={TrustCenterHome} />
        )}
        <Route exact path={config.routes.catalogVendor.to} component={CatalogVendorOverview} />
        <Route path={config.routes.help.to} component={withPrincipal(Help)} />
        <Route path="/404" component={ErrorPage} />
        <Route exact path="/logout" component={Logout} />
        <Route
          path="/"
          component={() => (
            <Redirect to={props.principal.isTrustCenterEnabled ? config.routes.trustCenter.to : '/404'} />
          )}
        />
        <Route path="" component={DefaultContent} />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};

TrustCenterContent = withPrincipal(TrustCenterContent);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: true,
    };
  }

  async componentDidMount() {
    const res = await fetch(config.api.urlFor('principal'));
    const { user: principal, token } = await res.json();

    window.$app.setState({ principal, token });

    // If the session has expired and we are not at the login page, we will display a notification to the user.
    if (!principal && window.location.hash !== '#/') {
      const logout = window.$app.getState('logout') || 'false';
      window.$app.setState({ logout, lastSuccessfulLogon: undefined });
    }

    const isRolelessUser = principal && !principal.roles?.length;
    const showOnlyTrustCenterContent = principal?.roles?.some((r) =>
      r.permissions.some((p) => p === 'accessOnlyTrustCenterContent'),
    );

    const component = isRolelessUser
      ? RolelessContent
      : showOnlyTrustCenterContent
      ? TrustCenterContent
      : withQueryString(!principal ? GeneralContent : principal.isProvider ? ProviderContent : ProtectedContent);

    this.setState({
      isBusy: false,
      component,
    });
  }

  render() {
    const { component } = this.state;

    return (
      <Router>
        <React.Fragment>
          {this.state.isBusy ? (
            <div className="text-center">A2V Marketplace Is Loading...</div>
          ) : (
            <Route component={component} />
          )}
          {!!this.props.principal && <Footer />}
          <HelpPanel />
          <ToastContainer />
          <DownloadManager />
        </React.Fragment>
      </Router>
    );
  }
}

ProviderContent.propTypes = {
  principal: PropTypes.object.isRequired,
};

App.propTypes = {
  principal: PropTypes.object.isRequired,
};

export default withPrincipal(App);
