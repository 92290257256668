import React, { Component } from 'react';

import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';

import { generateSymbol, generateColor } from '../../../../../constants/postern/riskIconGenerator.js';

import './cyberPresence.css';

export default class CyberPresence extends Component {
  render() {
    const { scores = {}, cyberPresence = [], vendor = {}, cyberPresenceAggregationCount = [] } = this.props;
    const { cyberPresence: sCyberPresence = '' } = scores;
    let scanDate = '-';
    // this will error if you check both conditions at the same time
    if (cyberPresence.length > 0) {
      if (cyberPresence[0].date_entered !== null) {
        scanDate = new Date(cyberPresence[0].date_entered).toLocaleDateString();
      }
    }
    const ipString = cyberPresence.length === 0 ? 'no IP addresses. ' : `${cyberPresence.length} IP addresses. `;

    return (
      <>
        <div className="postern-section-summary-paragraph">
          <p>
            A scan of {vendor.name} completed on {scanDate} has revealed {ipString}
            {sCyberPresence == 'high'
              ? 'During our review, some IPs were found to be located in countries of adversarial presence.'
              : 'During our review, no IPs were found to be located in countries of adversarial presence.'}
          </p>
        </div>
        {cyberPresence.length > 0 ? (
          <div id="cp-table">
            <ReusableProvenanceTable tableName="CyberPresenceTable-" headers={['', 'Country', 'Number of IPs']}>
              {cyberPresenceAggregationCount.map((row, i) => {
                return (
                  <React.Fragment key={'CyberPresenceTable-' + i}>
                    <tr className="provenance-table-row">
                      <th className="row-padding" scope="row">
                        {' '}
                        <SeverityCircle
                          symbol={generateSymbol(row.highRisk)}
                          color={generateColor(row.highRisk)}
                        />{' '}
                      </th>
                      <td> {row.country} </td>
                      <td> {row.count} </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </ReusableProvenanceTable>
          </div>
        ) : null}
        <footer className="footer-p-wrapper">
          <p>
            Due to the elastic nature of address assignment under Internet Protocol version 4 (IPv4), the individual
            IPv4 addresses identified as part of this procedure may change ownership over time and cause false positives
            or false negatives. Discretion should be applied when reviewing procedure results.
            <br />A corporation’s cyber products (IP addresses) are identified and geolocated to their affiliated city
            and country location. These countries in which products can be found are then compared for global risk
            matches.
          </p>
        </footer>
      </>
    );
  }
}
