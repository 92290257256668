import React from 'react';
import { Card, LabeledListItem, CenteredListItem } from '.';

const CardRow = ({ details, isBusy }) => {
  const cardColumnSize = {
    small: 'col-xl-2 col-lg-4',
    medium: 'col-xl-3 col-lg-4',
    large: 'col-xl-4 col-lg-12',
  };

  const icon = {
    crosshair: 'fa-crosshairs',
    bug: 'fa-bug',
    server: 'fa-server',
    pen: 'fa-pen',
  };

  return (
    <React.Fragment>
      <div className="row mb-5">
        <Card label={details.name} icon={icon.crosshair} colSize={cardColumnSize.large} isBusy={isBusy}>
          <LabeledListItem
            label="Annual Revenue"
            value={
              parseInt(details.annualRevenue)
                ? '$' + parseInt(details.annualRevenue).toLocaleString()
                : details.annualRevenue
            }
          />
          <LabeledListItem
            label="Employees"
            value={
              parseInt(details.employeeSize) ? parseInt(details.employeeSize).toLocaleString() : details.employeeSize
            }
          />
          <LabeledListItem label="Industry" value={details.industryName} />
          <LabeledListItem label="Industry Grouping" value={details.industryGroup} />
          <LabeledListItem label="Description" value={details.companyDescription} />
        </Card>
        <Card label="High Risk Areas" icon={icon.bug} colSize={cardColumnSize.medium} isBusy={isBusy}>
          {details.highRiskAreas &&
            details.highRiskAreas.map &&
            details.highRiskAreas.map((val, i) => <CenteredListItem value={val} key={i} />)}
        </Card>
        <Card label="Risk Level" icon={icon.server} colSize={cardColumnSize.small} isBusy={isBusy}>
          <div className="mh-100 row align-items-center">
            <div className="col-12 text-center">
              <h3>{details.ddrl}</h3>
            </div>
            <div className="col-12 text-center">
              <h4>{details.ddrlScore}</h4>
            </div>
          </div>
        </Card>
        <Card label="Manual Review" icon={icon.pen} colSize={cardColumnSize.medium} isBusy={isBusy}>
          <div className="mh-100 row align-items-center">
            <div className="col-12 text-center">
              <h3>{details.mrl}</h3>
            </div>
            <div className="col-12 text-center">
              <h4>{details.manualReviewText}</h4>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default CardRow;
