import React from 'react';
import ReactTable from 'react-table';
import config from '../../config';
import Busy from '../busy';
import CsvDownloadButton from '../csvDownloadButton';
import { makeCyberMap } from '../postern/reports/ddrr/assets/js/cybermap';

const CyberHygieneDiscovery = (props) => {
  const { vendorName, ddrrID } = props;

  const [geoIPTableData, setGeoIPTableData] = React.useState([]);
  const [isMapBusy, setIsMapBusy] = React.useState(false);
  const [geoTableIsBusy, setGeoTableIsBusy] = React.useState(false);
  const [map, setMap] = React.useState(null);

  React.useEffect(() => {
    return () => {
      if (map) {
        map.dispose();
      }
    };
  }, []);

  React.useEffect(() => {
    if (ddrrID) {
      getGeoIP();
      getGeoIpTableData();
    }
  }, [ddrrID]);

  const getGeoIpTableData = () => {
    setGeoTableIsBusy(true);

    let url = config.api.urlFor('vendorGeoIPTable', { id: ddrrID });

    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        let ipTable = [];
        const { isSuccess, data: rows } = response || {};

        if (isSuccess && rows?.length) {
          ipTable = rows.map((row) => {
            return {
              ip: row.ip,
              city: row.city,
              country: row.countryName,
            };
          });
        }

        setGeoIPTableData(ipTable);
      })
      .catch(() => {
        setGeoIPTableData([]);
      })
      .finally(() => setGeoTableIsBusy(false));
  };

  const getGeoIP = () => {
    setIsMapBusy(true);

    fetch(config.api.urlFor('vendorGeoIP', { id: ddrrID }))
      .then((response) => response.json())
      .then((response) => {
        if (response.isSuccess && response.data) {
          const chart = makeCyberMap(response.data.cyberMap.dataProvider.images, 'mapdiv');
          setMap(chart);
        }
      })
      .catch(() => {
        setMap(null);
      })
      .finally(() => setIsMapBusy(false));
  };

  return (
    <div className="row">
      <div className="col-5">
        <div className="card mb-3">
          <div className="card-header">
            <div className="float-left">
              <i className="fas fa-map-marked-alt mr-1" />
              Target Location Details
            </div>
            <div className="float-right">
              <CsvDownloadButton
                url={config.api.urlFor('vendorGeoIPCsv', { id: ddrrID })}
                filename={`${(vendorName || '').toLowerCase().replace(/ /g, '-')}-geoip`}
              />
            </div>
          </div>
          <div className="card-body">
            <ReactTable
              columns={[
                { Header: 'IP', accessor: 'ip' },
                { Header: 'City', accessor: 'city' },
                { Header: 'Country', accessor: 'country' },
              ]}
              defaultSorted={[{ id: 'ip', desc: false }]}
              multiSort={false}
              showPageSizeOptions={false}
              filterable
              data={geoIPTableData}
              defaultPageSize={10}
              loading={geoTableIsBusy}
              className="-striped -highlight hide-arrows"
            />
          </div>
        </div>
      </div>
      <div className="col-7">
        <div className="card mb-3">
          <div className="card-header">
            <i className="fas fa-map-marked-alt mr-1" />
            Target Locations
          </div>
          <div className="card-body align-middle">
            {isMapBusy ? <Busy isBusy={true} /> : null}
            <div id="mapdiv" style={{ height: '400px' }} />
          </div>
          <div className="card-footer small text-muted" />
        </div>
      </div>
    </div>
  );
};

export default CyberHygieneDiscovery;
