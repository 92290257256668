import React from 'react';
import { generateColor, generateSymbol } from '../../../constants/postern/riskIconGenerator.js';
import Busy from '../../busy';
import SeverityCircle from '../../postern/reusable/severityCircle';
import SectionContainer from '../../sectionContainer';
import ForeignInfluenceTable from '../foreignInfluenceTable';
import SafeHyperlink from '../../safeHyperlink.jsx';

/** Functional component for displaying the mergers and acquisitions portion of Foreign Influence data */
const MergersAcquisitions = (props) => {
  const { mergerAcquisition, mergerAcquisitionScore, isLoading } = props;

  return (
    <Busy isBusy={isLoading}>
      <SectionContainer label="Mergers &amp; Acquisition Findings" className="row fi-section-container">
        <ForeignInfluenceTable
          tableName="ForeignInfluence-MergersAcquisitions"
          headers={['Violation', 'Investor', 'Target', 'Seller', 'Transaction Date']}
        >
          {mergerAcquisition.length > 0 ? (
            mergerAcquisition.map((row, i) => {
              return (
                <tr className="provenance-table-row fi-table-row" key={'ForeignInfluence-MergersAcquisitions-' + i}>
                  <th scope="row">
                    {' '}
                    <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                  </th>
                  <td>
                    {' '}
                    {row.investor_domain ? (
                      <SafeHyperlink url={'https://' + row.investor_domain}> {row.investor} </SafeHyperlink>
                    ) : (
                      row.investor
                    )}
                    <br />
                    <p>{row.investor_country_name}</p>
                  </td>
                  <td>
                    {' '}
                    {row.target_domain ? (
                      <SafeHyperlink url={'https://' + row.target_domain}> {row.target} </SafeHyperlink>
                    ) : (
                      row.target
                    )}
                    <br />
                    <p>{row.target_country_name}</p>
                  </td>
                  <td>
                    {' '}
                    {row.seller_domain ? (
                      <SafeHyperlink url={'https://' + row.seller_domain}> {row.seller} </SafeHyperlink>
                    ) : (
                      row.seller
                    )}
                    <br />
                    {row.seller_country_name}{' '}
                  </td>
                  <td>{row.transaction_date ? new Date(row.transaction_date).toLocaleDateString('en-US') : '-'} </td>
                </tr>
              );
            })
          ) : (
            <tr className="provenance-table-row-empty" key={'ForeignInfluence-MergersAcquisitions-empty'}>
              <td colSpan={5}>
                <p>No Findings Identified</p>
              </td>
            </tr>
          )}
        </ForeignInfluenceTable>
      </SectionContainer>
      <div className="fi-summary-text">
        {mergerAcquisitionScore === 'high' ? (
          <p>
            During our review of the merger and acquisitions, some were found in countries of adversarial presence.
            Further investigation concludes that the foreign influence from merger and acquisitions is likely
            significant.
          </p>
        ) : (
          <p>
            During our review of the merger and acquisitions, none were found in countries of adversarial presence.
            Further investigation concludes that the foreign influence from merger and acquisitions is not likely
            significant, and thus no finding is raised.
          </p>
        )}
      </div>
    </Busy>
  );
};

export default MergersAcquisitions;
