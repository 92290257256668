import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import config from '../../../config.js';
import { IoMdCloudUpload } from 'react-icons/io';
const { fileTypes } = config.trustCenter;

const CatalogDropZone = (props) => {
  //Props
  const {
    singleFileMode,
    currentFiles = [],
    acceptableFileType = fileTypes,
    setCurrentFiles,
    removeAllFiles = false,
    showUploadBox,
    showHelperText,
    additionalMessageOnRejection,
    shouldHandleLongFileData,
  } = props;

  //State
  const [acceptedFiles, setAcceptedFiles] = useState(currentFiles);
  const [fileRejections, setFileRejections] = useState([]);

  useEffect(() => {
    if (removeAllFiles) {
      setAcceptedFiles([]);
      if (typeof setCurrentFiles === 'function') {
        setCurrentFiles([]);
      }
    }
  }, [removeAllFiles]);

  //Functions
  const addFile = (acceptedFiles) => {
    const { setCurrentFiles } = props;
    if (typeof setCurrentFiles === 'function') {
      setCurrentFiles(acceptedFiles);
    }
  };

  const removeFile = (index) => {
    const { setCurrentFiles } = props;
    let updatedAcceptedFiles = [...acceptedFiles];

    if (index > -1) {
      updatedAcceptedFiles.splice(index, 1);
    }

    setAcceptedFiles(updatedAcceptedFiles);
    if (typeof setCurrentFiles === 'function') {
      setCurrentFiles(updatedAcceptedFiles);
    }
  };

  const handleFileData = (file, isRejected = false) => {
    let fileData = file?.name ?? file?.file?.name ?? '';

    if (isRejected) {
      fileData += ` - ${file?.errors?.[0]?.message || 'Rejected file'} 
      ${additionalMessageOnRejection ? ' - ' + additionalMessageOnRejection : ''}`;
    }

    return fileData;
  };

  //Render
  return (
    <Dropzone
      accept={acceptableFileType ? acceptableFileType : null}
      maxFiles={singleFileMode ? 1 : 0}
      multiple={singleFileMode ? false : true}
      onDrop={(acceptedFiles, fileRejections) => {
        addFile(acceptedFiles);
        setAcceptedFiles(acceptedFiles);
        setFileRejections(fileRejections);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <React.Fragment>
          <div className={!!showUploadBox ? 'upload-logo-box' : !!showHelperText ? 'upload-helper-text' : ''}>
            <div
              className={
                'dropzone-outline' +
                (!!showHelperText && !(acceptedFiles?.length || fileRejections?.length) ? ' extended' : '')
              }
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {!!showUploadBox ? (
                <>
                  <div className="upload-logo-vector"></div>
                  <button className="upload-logo-btn">Upload Logo</button>
                </>
              ) : !!showHelperText ? (
                <>
                  <IoMdCloudUpload className="fu-helper-text-icon" />
                  <div>
                    <p className="fu-helper-text">Drop file(s) here or </p>
                    <p className="fu-helper-text font-weight-bold">browse</p>
                  </div>
                </>
              ) : (
                <p>Click or ’Drag and Drop’ files within this box</p>
              )}
            </div>
            {acceptedFiles &&
              acceptedFiles.map((file, i) => {
                const fileData = handleFileData(file);
                return (
                  <div className="upload-file-accepted" key={i}>
                    <p
                      className={
                        'modal-report-title m-cancel-cursor mr-2 mb-0' +
                        (!!shouldHandleLongFileData ? ' file-data-shortened' : '')
                      }
                      title={fileData}
                    >
                      {fileData}
                    </p>
                    <i className="fa fa-trash red" onClick={() => removeFile(i)} title="Remove file"></i>
                  </div>
                );
              })}
            {fileRejections &&
              fileRejections.map((file, i) => {
                const fileData = handleFileData(file, true);
                return (
                  <p
                    className={
                      'modal-report-title m-cancel-cursor upload-file-rejected' +
                      (!!shouldHandleLongFileData ? ' file-data-shortened' : '')
                    }
                    key={i}
                    title={fileData}
                  >
                    {fileData}
                  </p>
                );
              })}
          </div>
        </React.Fragment>
      )}
    </Dropzone>
  );
};

export default CatalogDropZone;
