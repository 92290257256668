import React, { Component } from 'react';

import Nav from './../reusable/nav';
import Content from './../reusable/content';

import './horizontalLayout.css';

class HorizontalLayout extends Component {
  render() {
    const { navOptions, children } = this.props;

    return (
      <div id="postern-horizontal">
        <Nav navOptions={navOptions} />
        <Content>
          <table className="content-level-table">
            <tbody>
              <tr>
                <td>{children}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </Content>
        <div id="postern-print-footer" className="print-footer">
          <p className="p-print-nav-bottom-text">
            <b>
              CONFIDENTIAL - NOT FOR FURTHER REDISTRIBUTION - CONTACT COMPLIANCE@FORTRESSINFOSEC.COM FOR MORE
              INFORMATION
            </b>
          </p>
        </div>
      </div>
    );
  }
}

export default HorizontalLayout;
