import React, { Component } from 'react';

export default class Disclaimer extends Component {
  render() {
    return (
      <React.Fragment>
        <p>
          The usage and distribution of this document is strictly governed by the terms of our agreement with our
          client, and this document may not be relied upon anyone other than our client. Only individuals with a
          specific contractual right to view this document may do so, and if you believe you may have received this
          document in error, please do not read further and contact us via email at{' '}
          <a href="mailto:compliance@fortressinfosec.com">compliance@fortressinfosec.com</a> as soon as possible.
          <br />
          <br />
          To the extent this document is provided to or obtained by a governmental entity, please note that confidential
          treatment of this document is requested under both the Freedom of Information Act, as well as any similar
          applicable state or local laws governing the public disclosure of documentation, and written notice of any
          request for this document is requested to be sent to us via email at{' '}
          <a href="mailto:compliance@fortressinfosec.com">compliance@fortressinfosec.com</a> as soon as possible.
          <br />
          <br />
          The analysis set forth herein is made as of the date listed in this document, and we are under no obligation
          to correct or modify this document on an ongoing basis, unless we have specifically contracted with our client
          to do so in a mutually executed agreement. The usage of critical, high, medium, moderate, nominal, low, or
          other scaled indicators or parlance associated with the rating of risk is based on our subjective
          determinations, and our subjective determinations may deviate from regulatory determination, and
          determinations by others. Our determinations are based on facts and data we receive, and to the extent any
          facts or data are incorrect, our determinations may then be correspondingly incorrect. Any included
          recommendations provided are non-inclusive and are only intended to serve as exemplar actions to address
          security risks, rather than as the sum total of all actions recommended to address security risks.
          <br />
          <br />
          Information security threats change on a daily basis, and no amount of security testing or verification can
          ensure that any systems or hardware are fully secure from unauthorized access. We make no guarantees or
          representations of accuracy beyond those contained in any mutually executed agreement with our client that
          address the contents of this document. This document only provides our findings under the terms of our
          agreement with our client and does not constitute a representation or warranty that the any systems or
          hardware are advisable to use or free of defects or malicious code.
        </p>
      </React.Fragment>
    );
  }
}
