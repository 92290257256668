import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useUpdateState } from '../../components';
import ProductCheckoutModal from '../../components/product/productCheckoutModal';
import ProductOrderReportsModal from '../../components/product/productOrderReportsModal';
import ProductOrderResultModal from '../../components/product/productOrderResultModal';
import config from '../../config';

const ProductModalStagesContainer = (props) => {
  const [state, setState] = useUpdateState({
    showModal: props.showModal || false,
    modalStage: props.modalStage || 'reports',
    productReports: [],
    reportDescriptions: {},
    selectedReports: props.selectedReports || {},
    allowGoingBackwards: props.allowGoingBackwards || false,
    productName: props.productName || '',
    productVersionOrNumber: props.productVersionOrNumber || '',
    requestedProductReports: props.requestedProductReports || {
      requestedProductArray: [],
    },
  });

  const {
    showModal,
    modalStage,
    productReports,
    reportDescriptions,
    selectedReports,
    allowGoingBackwards,
    productName,
    productVersionOrNumber,
    requestedProductReports,
  } = state;

  const {
    productID,
    vendorID,
    vendorDDRRid,
    vendorName,
    allowMultipleProducts = true,
    updateSBOMpurchaseStatus = () => {},
    supplierProvidedSbomFlag = false,
    selectedFullHash = '',
    getSbomAccessStatus = () => {},
  } = props;

  useEffect(() => {
    getProductReports();
  }, []);

  useEffect(() => {
    setModalStage(props.modalStage);
  }, [props.modalStage]);

  useEffect(() => {
    setState({ showModal: props.showModal, productVersionOrNumber: props.productVersionOrNumber });
  }, [props.showModal]);

  useEffect(() => {
    setState({ selectedReports: props.selectedReports });
  }, [props.selectedReports]);

  useEffect(() => {
    if (props.requestedProductReports) {
      setState({ requestedProductReports: props.requestedProductReports });
    }
  }, [props.requestedProductReports]);

  useEffect(() => {
    setState({ productName: props.productName });
  }, [props.productName]);

  useEffect(() => {
    setState({ productVersionOrNumber: props.productVersionOrNumber });
  }, [props.productVersionOrNumber]);

  useEffect(() => {
    setState({ allowGoingBackwards: props.allowGoingBackwards });
  }, [props.allowGoingBackwards]);

  const getProductReports = () => {
    fetch(config.api.urlFor('catalogReportTypes') + '?pageSize=0')
      .then((res) => res.json())
      .then((data) => {
        let { rows = [] } = data;
        let productReports = [];
        let reportDescriptions = {};
        let selectedReports = {};

        rows.forEach((r) => {
          reportDescriptions[r.name] = {
            name: r.name,
            abvName: r.abbreviation,
            rawType: r.rawType,
            price: r.price,
            description: r.description,
            id: r.id,
            parentID: r.parent_id,
            autodeliver: r.autodeliver,
          };

          if (r.class === 'product') {
            productReports.push(r.name);
            selectedReports[r.name] = 0;
          }
        });

        setState({
          reportDescriptions,
          productReports,
          selectedReports,
        });
      })
      .catch(() => {
        setState({
          productReports: [],
          reportDescriptions: {},
          selectedReports: {},
        });
      });
  };

  const setModalStage = (modalStage, allowGoingBackwards = false) => {
    setState({ modalStage, allowGoingBackwards });
  };

  const resetData = () => {
    setState({
      selectedReports: {},
      requestedProductReports: { requestedProductArray: [] },
    });
  };

  const handleModalClose = () => {
    if (typeof props.handleModalClose === 'function') props.handleModalClose();

    setState({
      showModal: false,
      modalStage: '',
      selectedReports: modalStage === 'confirmation' ? {} : selectedReports,
      requestedProductReports: modalStage === 'confirmation' ? { requestedProductArray: [] } : requestedProductReports,
    });
  };

  const updateState = (keyName, value) => {
    setState({ [keyName]: value });
  };

  return (
    <Modal
      isOpen={showModal}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
          background: 'none',
          overflow: 'unset',
          zIndex: '5',
        },
      }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
    >
      <div id="modal-m-product">
        {(() => {
          switch (modalStage) {
            case 'reports':
              return (
                <ProductOrderReportsModal
                  selectedReports={selectedReports}
                  productReports={productReports}
                  reportDescriptions={reportDescriptions}
                  productID={productID}
                  productName={productName}
                  setNextModalStage={setModalStage}
                  allowMultipleProducts={allowMultipleProducts}
                  productVersionOrNumber={productVersionOrNumber}
                  requestedProductReports={requestedProductReports}
                  setParentState={updateState}
                />
              );

            case 'confirmation':
              return (
                <ProductCheckoutModal
                  productID={productID}
                  productName={productName}
                  vendorID={vendorID}
                  vendorDDRRid={vendorDDRRid}
                  vendorName={vendorName}
                  requestedProductReports={requestedProductReports}
                  allowGoingBackwards={allowGoingBackwards}
                  reportDescriptions={reportDescriptions}
                  setNextModalStage={setModalStage}
                  productVersionOrNumber={productVersionOrNumber}
                  allowMultipleProducts={allowMultipleProducts}
                  updateSBOMpurchaseStatus={updateSBOMpurchaseStatus}
                  resetModalData={resetData}
                  supplierProvidedSbomFlag={supplierProvidedSbomFlag}
                  selectedFullHash={selectedFullHash}
                  getSbomAccessStatus={getSbomAccessStatus}
                />
              );

            case 'result':
              return <ProductOrderResultModal handleModalClose={handleModalClose} />;

            default:
              return <p>Untracked Product Modal Stage</p>;
          }
        })()}
      </div>
    </Modal>
  );
};

export default ProductModalStagesContainer;
