import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import TabList from '../tabList';
import VulnerabilityFindings from '../vulnerabilityFindings';
import FindingsCategory from './findingsCategory';
import ScanProgress from './scanProgress';
import CyberHygieneDiscovery from './cyberHygieneDiscovery';
import SectionLocked from '../sectionLocked';
import withPrincipal from '../withPrincipal';
import Busy from '../busy';
import { CatalogVendorContext } from '../../routes/vendor/context';

/** Functional component for displaying Cyvber Hygiene data */
const CyberHygieneContent = (props) => {
  //Props
  const {
    isLoadingData,
    vendorData = {},
    dnsFindings = {},
    sslFindings = {},
    appSecFindings = {},
    fullScanTasks,
    doingQuickScan,
    history,
    pathBase,
    hasScanData,
    isUnlockedCyberHygiene,
    reportTypeCyberHygiene,
    handleUnlockButtonClick,
    vulnsFindings,
  } = props;

  //Context
  const { cyberHygieneScore } = useContext(CatalogVendorContext);

  //Prop-Dependant Consts
  const CHPathAppend = 'CyberHygiene';
  const CHPathBase = pathBase + (pathBase.endsWith('/') ? '' : '/') + CHPathAppend;
  const { id: vendorID, name: vendorName, ddrrID } = vendorData;

  //State
  const [forceSelected, setForceSelected] = React.useState(
    props.match.params.selectedTab === CHPathAppend ? props.match.params.subsection : undefined,
  );

  /* ----------------------------------- */
  /*              Effects                */
  /* ----------------------------------- */
  // Note: Both effects relate to updating and mainting the URL and selected tab for this content display

  React.useEffect(() => {
    const subsection = props.match.params.subsection;

    if (props.match.params.selectedTab === CHPathAppend) {
      if (subsection && subsection !== forceSelected) {
        setForceSelected(subsection);
      } else if (!props.match.params.subsection) {
        history.replace(CHPathBase + '/' + (forceSelected || 'Discovery'));
      }
    }
  }, [props.match.params.selectedTab]);

  React.useEffect(() => {
    const subsection = props.match.params.subsection;

    if (props.match.params.selectedTab === CHPathAppend && subsection && subsection !== forceSelected) {
      setForceSelected(subsection);
    }
  }, [props.match.params.subsection]);

  /* ----------------------------------- */
  /*             Render                  */
  /* ----------------------------------- */

  return (
    <Busy isBusy={isLoadingData}>
      {isUnlockedCyberHygiene ? (
        <div className="cyber-hygiene-content">
          <ScanProgress
            doingQuickScan={doingQuickScan}
            finishedFullScan={hasScanData}
            fullScanTasks={fullScanTasks}
            hasScanData={hasScanData}
          />
          <TabList forceSelected={forceSelected} history={history} pathBase={CHPathBase}>
            <div label="Discovery">
              <CyberHygieneDiscovery vendorName={vendorName} ddrrID={ddrrID} />
            </div>
            <div label="DNS">
              <FindingsCategory
                category={'DNS'}
                findingsData={dnsFindings}
                findingsAvgScore={cyberHygieneScore?.dns}
                targetsToggleEnabled={false}
                tagId="1000"
              />
            </div>
            <div label="SSL/TLS">
              <FindingsCategory
                category={'SSL/TLS'}
                findingsData={sslFindings}
                findingsAvgScore={cyberHygieneScore?.ssl}
                tagId="2000"
              />
            </div>
            <div label="App Security">
              <FindingsCategory
                category={'App Security'}
                findingsData={appSecFindings}
                findingsAvgScore={cyberHygieneScore?.appSec}
                tagId="3000"
              />
            </div>
            <div label="Vulnerability">
              <VulnerabilityFindings
                vendorID={vendorID}
                vulnsFindings={vulnsFindings}
                avg_score={cyberHygieneScore?.vulnerabilities}
                finishedFullScan={hasScanData}
              />
            </div>
          </TabList>
        </div>
      ) : (
        <SectionLocked
          title="Unlock Cyber Hygiene Results"
          buttonCaption="Unlock Cyber Hygiene Scan"
          info={`View Cyber Hygiene results for ${vendorName} by purchasing a Cyber Scan.`}
          details="The Cyber Hygiene Scan identifies risk from a company's public web presence by identifying:"
          bullets={[
            'Domain Name Security',
            'Doppelgangers',
            'Sender Policy Framework',
            'Transport Layer Security',
            'Cipher Strength',
            'Deprecated Protocols',
            'Expired Certificates',
            '+Application Security',
            '+Vulnerabilities',
          ]}
          canUnlockSection={props.principal.roles.some((r) =>
            r.permissions.some((p) => p === 'subscriber.purchaseReports'),
          )}
          handleUnlockButtonClick={handleUnlockButtonClick}
          reportType={reportTypeCyberHygiene}
        />
      )}
    </Busy>
  );
};

export default withPrincipal(withRouter(CyberHygieneContent));
