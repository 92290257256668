// External Package Imports
import React, { Component } from 'react';

// Internal Package and Component Imports
import { toast } from 'react-toastify';
import { withPrincipal } from '../../components';
import config from '../../config';

class RequestSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: false,
      details: '',
      user: this.props.principal,
    };
  }

  submit = (e) => {
    e.preventDefault();

    const { details, user } = this.state;

    this.setState({ isBusy: true });
    fetch(config.api.urlFor('requestSupport'), {
      method: 'POST',
      body: {
        requestDetail: details,
        userEmail: user.emailAddress,
        userName: user.firstName + ' ' + user.lastName,
        userId: user.id,
      },
    }).then(() => this.setState({ isBusy: false }));

    toast.success('Support Request Submitted', { position: toast.POSITION.BOTTOM_RIGHT });
  };

  render() {
    const { isBusy, details } = this.state;
    return (
      <div className="row justify-content-md-center">
        <div className="col-5">
          <div className="row">
            <div className="col-12 justify-content-md-center">
              <h4 data-test-id="RequestSupport-Title" style={{ color: '#18bc9c' }}>
                Fortress Information Security Support Request
              </h4>
            </div>
          </div>
          <hr />
          <form>
            <div className="controls">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label data-test-id="RequestSupport-FormTitle" htmlFor="form_message">
                      Please provide us with details about your support request so we can best support you.
                    </label>
                    <textarea
                      id="form_message"
                      data-test-id="RequestSupport-FormTextArea"
                      name="message"
                      className="form-control"
                      placeholder="Request Details"
                      rows="4"
                      required="required"
                      data-error="Please, leave us a message."
                      onChange={(e) => this.setState({ details: e.target.value })}
                      value={details}
                      maxLength={500}
                    />
                    <div className="help-block with-errors" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    onClick={this.submit}
                    data-test-id="RequestSupport-SubmitButton"
                    type="submit"
                    className="btn btn-outline-primary"
                    disabled={isBusy}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withPrincipal(RequestSupport);
