import React, { useEffect } from 'react';
import ModalConfirmation from './modalConfirmation';
import { useUpdateState } from '../components';
import { toastError, toastSuccess } from '../lib/utils';
import InviteButton from './inviteButton';
import { Link } from 'react-router-dom';
import config from '../config';

const ActionButton = ({ onAction = () => null, ...props }) => {
  const [state, setState] = useUpdateState({
    modalShow: false,
    isBusy: false,
  });

  useEffect(() => {
    setState({
      subject: {
        id: props.userID || props.subscriberID || '',
        emailAddress: props.userEmailAddress || '',
      },
      action: props.action,
    });
  }, [props.userID, props.userEmailAddress, props.subscriberID, props.action]);

  const performAction = async (subject, action) => {
    setState({ isBusy: true });
    let response = {};

    switch (action) {
      case 'deleteUser':
        response = await fetch(config.api.urlFor('userDetails', { id: subject.id }), { method: 'DELETE' });
        break;

      case 'resetUser':
        response = await fetch(config.api.urlFor('resendInvitation', { userID: subject.id }));
        break;

      case 'resetUserPassword':
        response = await fetch(config.api.urlFor('forgotPassword'), {
          method: 'PUT',
          body: { emailAddress: subject.emailAddress },
        });
        break;

      case 'deleteSubscriber':
        response = await fetch(config.api.urlFor('deleteSubscriber'), {
          method: 'POST',
          body: { uuid: subject.id },
        });
        break;

      default:
        break;
    }

    const { isSuccess, message } = await response.json();

    if (!isSuccess) {
      showMessage(action, false, message);
    } else {
      showMessage(action, true);
    }

    setState({ modalShow: false, isBusy: false });

    if (onAction) {
      onAction();
    }
  };

  const showMessage = (action, isSuccess, externalMessage) => {
    let message = '';
    externalMessage = externalMessage ? ': ' + externalMessage : '.';

    if (isSuccess) {
      switch (action) {
        case 'deleteUser':
          message = 'Deleting User Succeeded';
          break;

        case 'resetUser':
          message = 'Resending Invitation Succeeded';
          break;

        case 'resetUserPassword':
          message = 'Sending Reset User Password Email Succeeded';
          break;

        case 'deleteSubscriber':
          message = 'Deleting Subscriber Succeeded';
          break;

        default:
          message = 'Operation Succeeded';
          break;
      }

      toastSuccess(message + externalMessage);
    } else {
      switch (action) {
        case 'deleteUser':
          message = 'Deleting User Failed';
          break;

        case 'resetUser':
          message = 'Resending Invitation Failed';
          break;

        case 'resetUserPassword':
          message = 'Sending Reset User Password Email Failed';
          break;

        case 'deleteSubscriber':
          message = 'Deleting Subscriber Failed';
          break;

        default:
          message = 'Operation Failed';
          break;
      }

      toastError(message + externalMessage);
    }
  };

  const closeModal = () => {
    setState({ modalShow: false, isBusy: false });
  };

  const { subject, action, modalShow, isBusy } = state;
  let buttonClassName = '',
    buttonTitle = '',
    confirmationText = '',
    btnIconClassName = '';

  switch (action) {
    case 'deleteUser':
      buttonClassName = 'btn btn-danger ml-1';
      buttonTitle = 'Delete user';
      confirmationText = 'Confirm deleting this user?';
      btnIconClassName = 'fas fa-times fa-fw';
      break;

    case 'resetUser':
      buttonClassName = 'btn btn-info ml-1';
      buttonTitle = 'Resend invitation';
      confirmationText = 'Confirm resending invitation to this user?';
      btnIconClassName = 'fas fa-redo fa-fw';
      break;

    case 'resetUserPassword':
      buttonClassName = 'btn btn-warning ml-1';
      buttonTitle = 'Reset user password';
      confirmationText = 'Confirm sending reset password email to this user?';
      btnIconClassName = 'fas fa-unlock-alt';
      break;

    case 'deleteSubscriber':
      buttonClassName = 'btn btn-danger ml-1';
      buttonTitle = 'Delete subscriber';
      confirmationText = 'Confirm deleting this subscriber?';
      btnIconClassName = 'fas fa-times fa-fw';
      break;

    case 'editSubscriber':
      buttonClassName = 'btn btn-success';
      buttonTitle = 'Modify subscriber';
      btnIconClassName = 'fas fa-pencil-alt fa-fw';
      break;

    default:
      break;
  }

  return (
    <React.Fragment>
      {action === 'editUser' && (
        <InviteButton
          associations={props.associations}
          mode="edit"
          context="role"
          inUserData={props.inUserData}
          submitUrl={config.api.urlFor('saveUser')}
          reloadDataInParent={props.reloadDataInParent}
        />
      )}
      {action === 'editSubscriber' && (
        <Link to={`/admin/subscriptions/${subject.id}`} className={buttonClassName} title={buttonTitle}>
          <i className={btnIconClassName}></i>
        </Link>
      )}
      {action !== 'editUser' && action !== 'editSubscriber' && (
        <button
          type="button"
          className={buttonClassName}
          title={buttonTitle}
          onClick={() => setState({ modalShow: true })}
        >
          <i className={btnIconClassName}></i>
        </button>
      )}

      <ModalConfirmation
        isBusy={isBusy}
        isOpen={modalShow}
        confirmationText={confirmationText}
        onClickYes={() => performAction(subject, action)}
        onClickNo={closeModal}
      />
    </React.Fragment>
  );
};

export default ActionButton;
