import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
am4core.addLicense('CH91205152');

const makeGauge = function (totalScore, chartID) {
  am4core.addLicense('CH91205152');
  const makeInt = (string) => {
    let res = string;
    if (typeof string === 'string') {
      res = string.replace(',', '');
    }
    return parseInt(res) > 1500 ? 1500 : parseInt(res);
  };

  const normalizedScore = makeInt(totalScore);

  var chartMin = 0;
  var chartMax = 1500;
  var data = {
    score: normalizedScore,
    gradingData: [
      {
        title: 'Low',
        color: '#53c653',
        lowScore: 0,
        highScore: 500,
      },
      {
        title: 'Medium',
        color: '#ffcc66',
        lowScore: 500,
        highScore: 750,
      },
      {
        title: 'High',
        color: '#ef5a43',
        lowScore: 750,
        highScore: 1000,
      },
      {
        title: 'Critical',
        color: '#bc2710',
        lowScore: 1000,
        highScore: 1500,
      },
    ],
  };

  // Grading Lookup
  function lookUpGrade(lookupScore, grades) {
    // Only change code below this line
    const normalizedLookupScore = makeInt(lookupScore);
    for (var i = 0; i < grades.length; i++) {
      if (grades[i].lowScore <= normalizedLookupScore && grades[i].highScore >= normalizedLookupScore) {
        return grades[i];
      }
    }
    return null;
  }

  // create chart
  var chart = am4core.create(chartID, am4charts.GaugeChart);
  chart.hiddenState.properties.opacity = 0;
  chart.fontSize = 11;
  chart.innerRadius = am4core.percent(80);
  chart.resizable = false;

  // Axis for ranges
  var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
  axis2.min = chartMin;
  axis2.max = chartMax;
  axis2.strictMinMax = true;
  axis2.renderer.labels.template.disabled = true;
  axis2.renderer.ticks.template.disabled = true;
  axis2.renderer.grid.template.disabled = false;
  axis2.renderer.grid.template.opacity = 0.5;
  axis2.renderer.labels.template.bent = true;
  axis2.renderer.labels.template.fill = am4core.color('#000');
  axis2.renderer.labels.template.fontWeight = 'bold';
  axis2.renderer.labels.template.fillOpacity = 0.7;

  // Ranges
  for (let grading of data.gradingData) {
    var range = axis2.axisRanges.create();
    range.axisFill.fill = am4core.color(grading.color);
    range.axisFill.fillOpacity = 0.8;
    range.axisFill.zIndex = -1;
    range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
    range.endValue = grading.highScore < chartMax ? grading.highScore : chartMax;
    range.grid.strokeOpacity = 0;
    range.stroke = am4core.color(grading.color).lighten(-0.1);
    range.label.inside = true;
    range.label.text = grading.title.toUpperCase();
    range.label.inside = true;
    range.label.location = 0.5;
    range.label.inside = true;
    range.label.radius = am4core.percent(10);
    range.label.paddingBottom = -5; // ~half font size
    range.label.fontSize = '0.9em';
  }

  var matchingGrade = lookUpGrade(data.score, data.gradingData);

  // Label 1
  var label = chart.radarContainer.createChild(am4core.Label);
  label.isMeasured = false;
  label.fontSize = '3em';
  label.x = am4core.percent(50);
  label.paddingBottom = 15;
  label.horizontalCenter = 'middle';
  label.verticalCenter = 'bottom';
  label.text = normalizedScore;
  label.fill = am4core.color(matchingGrade.color);

  // Label 2
  var label2 = chart.radarContainer.createChild(am4core.Label);
  label2.isMeasured = false;
  label2.fontSize = '1.5em';
  label2.horizontalCenter = 'middle';
  label2.verticalCenter = 'bottom';
  label2.text = matchingGrade.title.toUpperCase();
  label2.fill = am4core.color(matchingGrade.color);

  // Hand
  var hand = chart.hands.push(new am4charts.ClockHand());
  hand.axis = axis2;
  hand.innerRadius = am4core.percent(55);
  hand.startWidth = 8;
  hand.pin.disabled = true;
  hand.value = data.score;
  hand.fill = am4core.color('#444');
  hand.stroke = am4core.color('#000');

  hand.events.on('positionchanged', function () {
    label.text = normalizedScore;
    var matchingGrade = lookUpGrade(axis2.positionToValue(hand.currentPosition), data.gradingData);
    label2.text = matchingGrade.title.toUpperCase();
    label2.fontWeight = 700;
    label2.fill = am4core.color('#000');
    label.fill = am4core.color('#000');
  });

  return chart;
};

const removeGauge = function (chart) {
  if (chart instanceof Array) {
    return;
  } // Don't dispose if disposal has already occurred
};

export { makeGauge, removeGauge };
