/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import config from '../../config';
import { Busy, useUpdateState, withPrincipal } from '..';
import BlurryButton from '../blurryButton';
import { toastError, toastSuccess, formatNumber, formatDate } from '../../lib/utils';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { SortCarets } from '../assessmentList';
import { round } from 'lodash';
import { MdDeleteForever } from 'react-icons/md';

import '../vendor/bootstrapTable.css';

const CellEditor = (props) => {
  const { inputRef, mode, updateTransaction = () => {}, transactionID } = props;

  const [value, setValue] = useState(props.val);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (transactionID && inputRef && inputRef.current) {
        updateTransaction(transactionID, round(inputRef.current.value, 3));
      }
    }
  };

  useEffect(() => {
    setValue(props.val);

    if (props.mode == 1 && inputRef) {
      inputRef.current.focus();
    }
  }, [props.val, props.mode]);

  const handleValueChange = (event) => {
    setValue(formatNumber(event.target.value, false));
  };

  return (
    <React.Fragment>
      {!mode && <div>{value}</div>}
      {mode == 1 && (
        <input
          type="number"
          step="0.001"
          min="0"
          value={value}
          style={{ width: '100%' }}
          ref={inputRef}
          onChange={(e) => handleValueChange(e)}
          onKeyPress={(e) => handleKeyPress(e)}
        />
      )}
      {mode == 2 && <Busy isBusy={true} small={true}></Busy>}
    </React.Fragment>
  );
};

const TransactionTable = (props) => {
  const {
    data = [],
    modifyDataRow,
    isBusy,
    updateTransaction,
    handleDeletion,
    loadHistoryData,
    isExternalSaving,
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    if (isExternalSaving) {
      const rowToSave = data.find((row) => {
        return row.mode > 0;
      });

      if (rowToSave && inputRef && inputRef.current) {
        updateTransaction(rowToSave.id, round(inputRef.current.value, 3));
      }
    }
  }, [isExternalSaving]);

  const handleSelection = (transactionID) => {
    if (transactionID) {
      modifyDataRow(transactionID, 1);
    } else {
      modifyDataRow(transactionID, 0);
    }
  };

  const showTransactionHistory = (transactionID) => {
    if (!transactionID || !loadHistoryData) return;

    loadHistoryData(transactionID);
  };

  const columnFormatter = (cell) => {
    return <span title={cell}>{cell}</span>;
  };

  const columns = [
    {
      dataField: 'vendor',
      text: 'Vendor',
      sort: true,
      sortCaret: SortCarets,
      filter: textFilter(),
      formatter: columnFormatter,
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'productName',
      text: 'Product Name',
      sort: true,
      sortCaret: SortCarets,
      filter: textFilter(),
      formatter: columnFormatter,
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'transactionType',
      text: 'Transaction Type',
      sort: true,
      sortCaret: SortCarets,
      filter: textFilter(),
      formatter: columnFormatter,
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'dateOrdered',
      text: 'Date Ordered',
      sort: true,
      sortCaret: SortCarets,
      filter: dateFilter(),
      formatter: (cell) => {
        const dateOrdered = formatDate(cell);
        return <span title={dateOrdered}>{dateOrdered}</span>;
      },
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'tokenCount',
      text: 'Token Count',
      sort: true,
      sortCaret: SortCarets,
      filter: numberFilter(),
      formatter: (cell, row) => (
        <CellEditor
          inputRef={row.mode == 1 ? inputRef : null}
          val={round(row.tokenCount, 3)}
          transactionID={row.id}
          mode={row.mode}
          updateTransaction={updateTransaction}
        />
      ),
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'createdBy',
      text: 'Created By',
      sort: true,
      sortCaret: SortCarets,
      filter: textFilter(),
      formatter: columnFormatter,
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'countChanges',
      text: 'Changes History',
      sort: true,
      sortCaret: SortCarets,
      formatter: (cell, row) => {
        if (row.countChanges) {
          return (
            <span className="link-text" onClick={() => showTransactionHistory(row.id)}>
              View {row.countChanges} change{row.countChanges > 1 ? 's' : ''}
            </span>
          );
        }
      },
      headerStyle: { width: '9%' },
      classes: 'subscriber-details-table-columns',
    },
    {
      dataField: 'isDeleted',
      text: 'Is Deleted',
      sort: true,
      sortCaret: SortCarets,
      formatter: (cell, row) => {
        return row.isDeleted ? (
          <MdDeleteForever className="subscriber-details-table-deleted-transaction-icon" title="Deleted" />
        ) : (
          ''
        );
      },
      headerStyle: { width: '7%' },
      classes: 'subscriber-details-table-columns subscriber-details-table-is-deleted-column',
    },
    {
      dataField: 'none',
      text: 'Actions',
      formatter: (cell, row) => {
        return !row.isDeleted ? (
          <div className="btn-group">
            {row.mode > 0 ? (
              <React.Fragment>
                <BlurryButton
                  className="btn btn-sm btn-light border-primary"
                  onClick={() => handleSelection(null)}
                  disabled={row.mode == 2}
                >
                  Cancel
                </BlurryButton>
                <BlurryButton
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => updateTransaction(row.id, round(inputRef.current.value, 3))}
                  disabled={row.mode == 2}
                >
                  Save Changes
                </BlurryButton>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <BlurryButton
                  className="btn-sm btn-primary"
                  onClick={() => handleSelection(row.id)}
                  title="Edit transaction"
                >
                  <i className={'fas fa-pencil-alt fa-fw'}></i>
                </BlurryButton>
                <BlurryButton
                  className="btn-sm btn-primary ml-2"
                  onClick={() => handleDeletion(row.id)}
                  title="Delete transaction"
                >
                  <i className={'fas fa-times fa-fw'}></i>
                </BlurryButton>
              </React.Fragment>
            )}
          </div>
        ) : (
          ''
        );
      },
      headerStyle: { width: '12%' },
      classes: 'subscriber-details-table-columns',
    },
  ];

  const rowStyle = (row) => {
    return row.isDeleted ? { backgroundColor: '#ff8080' } : {};
  };

  return (
    <Busy isBusy={isBusy}>
      <div className="subscriber-details-table mb-5 mt-2">
        <BootstrapTable
          keyField="id"
          columns={columns}
          data={data}
          noDataIndication={'no transactions found'}
          rowStyle={rowStyle}
          defaultSorted={[{ dataField: 'dateOrdered', order: 'desc' }]}
          pagination={paginationFactory({ page: 1, sizePerPage: 10, hideSizePerPage: true })}
          filter={filterFactory()}
          filterPosition="top"
          hover={true}
          striped={true}
        />
      </div>
    </Busy>
  );
};

const TransactionHistoryModal = (props) => {
  const { data = [], title, isBusy, isOpen, toggleHistoryModal } = props;

  const historyColumns = [
    {
      dataField: 'action',
      text: 'Action',
    },
    {
      dataField: 'tokenCount',
      text: 'Token Count',
      formatter: (cell) => {
        return round(cell, 3);
      },
    },
    {
      dataField: 'dateModified',
      text: 'Date Modified',
      formatter: (cell) => {
        const dateModified = formatDate(cell);
        return <span title={dateModified}>{dateModified}</span>;
      },
    },
    {
      dataField: 'updatedBy',
      text: 'Updated By',
      formatter: (cell) => {
        return <span title={cell}>{cell}</span>;
      },
      classes: 'subscriber-details-table-columns',
    },
  ];

  const rowStyle = (row) => {
    return row.action === 'delete' ? { backgroundColor: '#ff8080' } : {};
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className="sa-modal-style sa-file-history-modal"
      contentLabel="View History Log"
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => toggleHistoryModal(false)}
    >
      <h5>{title}</h5>
      <div className="sa-modal-btn-container">
        <div className="sa-modal-close" onClick={() => toggleHistoryModal(false)}>
          x
        </div>
      </div>
      <Busy isBusy={isBusy}>
        <BootstrapTable
          keyField="id"
          data={data}
          columns={historyColumns}
          pagination={paginationFactory({ page: 1, sizePerPage: 10, hideSizePerPage: true })}
          rowStyle={rowStyle}
        />
      </Busy>
      <div className="sa-modal-btn-container">
        <div className="sa-modal-btns-right">
          <BlurryButton
            className="btn btn-outline-primary btn-nav sa-modal-cancel-btn"
            onClick={() => toggleHistoryModal(false)}
          >
            Close
          </BlurryButton>
        </div>
      </div>
    </Modal>
  );
};

const ConfirmDeletionModal = (props) => {
  const { title, transactionID, isOpen, toggleThisModal, performDeletion, deleting } = props;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className="sa-modal-style sa-modal-style-mini"
      contentLabel="Confirm transaction deletion"
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => toggleThisModal(null)}
    >
      <h5>{title}</h5>
      <div className="sa-modal-btn-container">
        <div className="sa-modal-close" onClick={() => toggleThisModal(null)}>
          x
        </div>
      </div>
      <div className="sa-modal-delete-confirm">
        {deleting ? 'Deleting transaction...' : 'Are you sure you want to delete this transaction?'}
      </div>
      <Busy isBusy={deleting}>
        <div className="sa-modal-btn-container">
          <div className="sa-modal-btns-right">
            <BlurryButton
              data-test-id="deleteTransaction-button-cancel"
              className="btn btn-outline-primary btn-nav
            sa-modal-cancel-btn"
              onClick={() => toggleThisModal(null)}
            >
              Cancel
            </BlurryButton>
            <BlurryButton
              data-test-id="deleteTransaction-button-delete"
              className="btn btn-outline-primary btn-nav
            sa-modal-delete-btn"
              onClick={() => performDeletion(transactionID)}
            >
              Confirm and Delete
            </BlurryButton>
          </div>
        </div>
      </Busy>
    </Modal>
  );
};

const TokensAdjustmentModal = (props) => {
  const { isOpen, toggleThisModal, currentTokens, performAdjustment, subscriberName, adjusting } = props;

  const [adjustmentValue, setAdjustmentValue] = useState(0.0);

  useEffect(() => {
    if (isOpen) {
      setAdjustmentValue(0);
    }
  }, [isOpen]);

  const handleValueChange = (event) => {
    setAdjustmentValue(formatNumber(event.target.value));
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className="sa-modal-style sa-file-history-modal"
      contentLabel="View History Log"
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        toggleThisModal(false);
      }}
    >
      <div className="sa-modal-btn-container">
        <div
          className="sa-modal-close"
          onClick={() => {
            toggleThisModal(false);
          }}
        >
          x
        </div>
      </div>
      <h4 className="font-weight-bold">{subscriberName}</h4>
      <div className="row mb-3 col-6 font-weight-bold">{adjusting ? 'Adjusting...' : 'Adjust Tokens'}</div>
      <Busy isBusy={adjusting}>
        <div className="row mb-3">
          <div className="col-6">Current Token Balance</div>
          <div className="col-6">{round(currentTokens, 3)}</div>
        </div>
        <hr />
        <div className="row mb-3">
          <div className="col-6">Adjust Tokens</div>
          <div className="col-6 pl-0">
            <input
              id="adjust-tokens-input"
              type="number"
              step="0.001"
              className="form-control adjust-tokens-modal-tokens-input"
              value={adjustmentValue}
              onChange={(e) => handleValueChange(e)}
            />
          </div>
        </div>
        <hr />
        <div className="row mb-3">
          <div className="col-6">Token Balance After Change</div>
          <div className="col-6">{round(Number(currentTokens) + Number(adjustmentValue), 3)}</div>
        </div>
        <hr />
        <div>
          <div className="sa-modal-btn-container">
            <div className="sa-modal-btns-right">
              <BlurryButton
                className="btn btn-primary"
                onClick={() => {
                  performAdjustment(round(adjustmentValue, 3));
                }}
                disabled={!adjustmentValue || adjustmentValue == 0}
              >
                Confirm
              </BlurryButton>
            </div>
          </div>
        </div>
      </Busy>
    </Modal>
  );
};

const SubscriberTransactions = (props) => {
  // props based constants
  const { principal, subscriberID, subscriberName, isExternalSaving } = props;
  const canManageTokenBalance = principal.roles.some((r) =>
    r.permissions.some((p) => p === 'subscription.manageTokenBalance'),
  );

  // state
  const [state, setState] = useUpdateState({
    data: [],
    dataHistory: [],
    isBusy: true,
    isBusyHistory: false,
    isBusyTokens: false,
    isBusyAdjusting: false,
    isBusyDeleting: false,
    isOpenHistory: false,
    isOpenTokensAdjustment: false,
    transactionIDForDeletion: null,
    currentTokens: 0,
    selectedTransactionTitle: '',
  });

  const {
    data,
    dataHistory,
    isBusy,
    isBusyHistory,
    isBusyTokens,
    isBusyAdjusting,
    isBusyDeleting,
    isOpenHistory,
    isOpenTokensAdjustment,
    transactionIDForDeletion,
    currentTokens,
    selectedTransactionTitle,
  } = state;

  // effects
  useEffect(() => {
    if (subscriberID) {
      getTransactionsData(subscriberID);
      getTokensBalance(subscriberID);
    }
  }, [subscriberID]);

  // functions
  const getTransactionsData = (subscriberID) => {
    setState({ isBusy: true });

    fetch(config.api.urlFor('transactionsBySubscriber', { subscriberID, showDeleted: true }))
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message = 'Error loading transactions data.', data } = response;

        if (isSuccess) {
          setState({ data: data.transactions });
        } else {
          toastError(message);
        }
      })
      .catch(() => {
        setState({ data: [] });
        toastError('Failed to load transactions data.');
      })
      .finally(() => setState({ isBusy: false }));
  };

  const updateTransaction = (transactionID, newValue) => {
    if (!transactionID) return;

    modifyDataRow(transactionID, 2);

    fetch(config.api.urlFor('transaction', { transactionID }), {
      method: 'PUT',
      body: { amount: newValue },
    })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          toastSuccess('Transaction successfully modified.');
          getTokensBalance(subscriberID);
          modifyDataRow(transactionID, 0, newValue);
        } else {
          toastError(message);
          modifyDataRow(transactionID, 1);
        }
      })
      .catch(() => {
        toastError('Error updating transaction.');
        modifyDataRow(transactionID, 1);
      });
  };

  const deleteTransaction = (transactionID) => {
    if (!transactionID) return;

    setState({ isBusyDeleting: true });

    fetch(config.api.urlFor('transaction', { transactionID }), { method: 'DELETE' })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          toastSuccess('Transaction successfully deleted.');
          getTokensBalance(subscriberID);
          modifyDataRow(transactionID, -1);
        } else {
          toastError(message);
        }
      })
      .catch(() => {
        toastError('Error deleting transaction.');
      })
      .finally(() => setState({ transactionIDForDeletion: null, isBusyDeleting: false }));
  };

  const getTransactionHistory = (transactionID) => {
    if (!transactionID) return;

    prepareSelectedTransactionTitle(transactionID);

    setState({ isBusyHistory: true, isOpenHistory: true });

    fetch(config.api.urlFor('transactionHistory', { transactionID }))
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message, data } = response;
        const { rows } = data;

        if (isSuccess) {
          setState({ dataHistory: rows });
        } else {
          toastError(message);
        }
      })
      .catch(() => {
        toastError('Failed to load transaction history.');
      })
      .finally(() => setState({ isBusyHistory: false }));
  };

  const adjustTokens = (newBalance) => {
    if (isNaN(newBalance)) return;

    setState({ isBusyAdjusting: true });

    fetch(config.api.urlFor('subscriberAdjustTokens', { id: subscriberID }), {
      method: 'POST',
      body: { tokens: round(newBalance, 3) },
    })
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message } = response;

        if (isSuccess) {
          toastSuccess('Token balance successfully updated.');
          toggleTokensAdjustmentModal(false);
          getTransactionsData(subscriberID);
          getTokensBalance(subscriberID);
        } else {
          toastError(message);
        }
      })
      .catch(() => {
        toastError('Error adjusting tokens.');
      })
      .finally(() => setState({ isBusyAdjusting: false }));
  };

  const getTokensBalance = (subscriberID) => {
    setState({ isBusyTokens: true });

    fetch(config.api.urlFor('tokenBalanceBySubscriber', { subscriberID }))
      .then((result) => result.json())
      .then((response) => {
        const { isSuccess, message, data } = response;

        if (isSuccess) {
          setState({ currentTokens: data.balance });
        } else {
          toastError(message);
        }
      })
      .catch(() => {
        toastError('Error getting tokens balance.');
      })
      .finally(() => setState({ isBusyTokens: false }));
  };

  // mode:
  //    0 or undefined - regular row
  //    1 - editing
  //    2 - waiting for response from the API
  //   -1 - row was just deleted
  const modifyDataRow = (transactionID, mode, newTokenCount) => {
    setState({
      data: data.map((row) => {
        if (row.id === transactionID) {
          return {
            ...row,
            mode: mode >= 0 ? mode : 0,
            tokenCount: newTokenCount != null ? newTokenCount : row.tokenCount,
            countChanges: row.countChanges + (newTokenCount != null || mode == -1 ? 1 : 0),
            isDeleted: mode == -1 ? true : row.isDeleted,
          };
        } else {
          return { ...row, mode: 0 };
        }
      }),
    });
  };

  const toggleHistoryModal = (flag) => {
    setState({ isOpenHistory: flag });

    if (!flag) {
      setState({ dataHistory: [] });
    }
  };

  const toggleConfirmDeletionModal = (transactionID) => {
    prepareSelectedTransactionTitle(transactionID);
    setState({ transactionIDForDeletion: transactionID });
  };

  const toggleTokensAdjustmentModal = (flag) => {
    setState({ isOpenTokensAdjustment: flag });
  };

  const prepareSelectedTransactionTitle = (transactionID) => {
    if (!transactionID) return;

    const trnData = data.find((row) => row.id === transactionID);

    setState({
      selectedTransactionTitle: `${trnData.vendor} / ${trnData.transactionType} / ${formatDate(trnData.dateOrdered)}`,
    });
  };

  return (
    <React.Fragment>
      <Busy isBusy={isBusyTokens} small>
        <div className="row mb-3 col-12">
          <div className="col-11">
            <span>
              Balance: <b>{currentTokens && round(currentTokens, 3)}</b> tokens
            </span>
          </div>
          <div className="col-1">
            {canManageTokenBalance && (
              <BlurryButton className="btn btn-primary" onClick={() => toggleTokensAdjustmentModal(true)}>
                Adjust Tokens
              </BlurryButton>
            )}
          </div>
        </div>
      </Busy>
      <TransactionTable
        data={data}
        isBusy={isBusy}
        isExternalSaving={isExternalSaving}
        modifyDataRow={modifyDataRow}
        updateTransaction={updateTransaction}
        handleDeletion={toggleConfirmDeletionModal}
        loadHistoryData={getTransactionHistory}
        toggleHistoryModal={toggleHistoryModal}
      />
      <TransactionHistoryModal
        isOpen={isOpenHistory}
        isBusy={isBusyHistory}
        data={dataHistory}
        title={selectedTransactionTitle}
        className="sa-modal-style sa-file-history-modal"
        contentLabel="View Transaction History"
        toggleHistoryModal={toggleHistoryModal}
      />
      <ConfirmDeletionModal
        isOpen={!!transactionIDForDeletion}
        deleting={isBusyDeleting}
        transactionID={transactionIDForDeletion}
        title={selectedTransactionTitle}
        toggleThisModal={toggleConfirmDeletionModal}
        performDeletion={deleteTransaction}
      />
      <TokensAdjustmentModal
        isOpen={isOpenTokensAdjustment}
        adjusting={isBusyAdjusting}
        currentTokens={currentTokens}
        toggleThisModal={toggleTokensAdjustmentModal}
        performAdjustment={adjustTokens}
        subscriberName={subscriberName}
      />
    </React.Fragment>
  );
};

export default withPrincipal(SubscriberTransactions);
