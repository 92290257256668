import React, { Component } from 'react'

import Cybermap from './components/cybermap'
import ScoreRiskMethodology from './components/scoreRiskMethodology'
import VendorInformation from './components/vendorInformation'
import Section from '../../reusable/section'
import HorizontalLayout from '../../layouts/horizontalLayout'
import PrintCoverPageHorizontal from '../../layouts/printCoverPageHorizontal'

import './posternDemo.css'

class posternDemo extends Component {

  render() {

    const navOptions = [
      {
        sectionNumber: "I",
        name: "Vendor Information",
        idToScrollTo: "vendor-info",
        hasSubOptions: true,
        subOptionArray: ["a","b","c","d"],
        subOptionGroupNumber: 1,
        isExpanded: false
      },
      {
        sectionNumber: "II",
        name: "Risk Methodology",
        idToScrollTo: "score-risk-methodology",
        hasSubOptions: true,
        subOptionArray: ["a","b","c","d"],
        subOptionGroupNumber: 2,
        isExpanded: false
      },
      {
        sectionNumber: "III",
        name: "Cybermap",
        idToScrollTo: "cybermap",
        hasSubOptions: true,
        subOptionArray: ["a","b","c","d"],
        subOptionGroupNumber: 3,
        isExpanded: false
      },
    ]

    return (<React.Fragment>
      <div id="postern-demo">

      <HorizontalLayout
        navOptions={navOptions}
      >

        <PrintCoverPageHorizontal
          navOptions={navOptions}
        >
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
          <p>I am content</p>
        </PrintCoverPageHorizontal>

        <Section
          sectionID="vendor-info"
          sectionName="Vendor Information"
          sectionNumber="I"
        >
          <VendorInformation />
        </Section>

        <Section
          sectionID="score-risk-methodology"
          sectionName="Score Risk Methodology"
          sectionNumber="II"
        >
          <ScoreRiskMethodology />
        </Section>

        <Section
          sectionID=""
          sectionName="cybermap"
          sectionNumber="III"
        >
          <Cybermap />
        </Section>

      </HorizontalLayout>
        
      </div>

    </React.Fragment>)
  }
}

export default posternDemo;
