import React from 'react';
import Tooltip from 'rc-tooltip';
import config from '../../../../src/config';

const Card = (props) => {
  const { data, onClick } = props;

  const vendorOverviewLink =
    '/#' +
    config.routes.catalogVendor.to
      .replace(':id', data.id)
      .replace(':selectedTab', 'Overview')
      .replace('?/:subsection?', '');

  return (
    <div className="vendor-cata-card" onClick={onClick}>
      <div className="vendor-logo-box">
        {!!(data && data.logoURL && data.name) && (
          <img
            src={data.logoURL}
            alt={data.name.length > 18 ? data.name.substring(0, 15) + '...' : data.name}
            className="vendor-logo"
            onError={(currentTarget) => {
              currentTarget.onerror = null;
              currentTarget.src = '';
              currentTarget.alt = '';
            }}
          />
        )}
      </div>
      <p className="vendor-cata-card-title">{data.name}</p>
      <div className="vendor-cata-card-icons">
        {data.isMember && (
          <img
            alt="A2V Member"
            src="/static/images/a2v-badge.png"
            title="This company is an A2V member and has completed an A2V Assessment"
          />
        )}
        {data.myVendor && (
          <div className="sm-icon-tag my-vendors-color">
            <i className="sm-icon-fa fas fa-users"></i>
          </div>
        )}
      </div>
      <div>
        <Tooltip placement="top" trigger={['hover']} overlay={<span>{data.domain}</span>}>
          <p className="vendor-cata-card-url">{data.domain}</p>
        </Tooltip>
      </div>
      <div className="vendor-cata-card-buttons-list">
        <button className="btn-warning vendor-cata-card-button-order">Order Reports</button>
        <a href={vendorOverviewLink} className="btn-info vendor-cata-card-button-info">
          View Profile
        </a>
      </div>
    </div>
  );
};

export default Card;
