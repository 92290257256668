/* eslint-disable react/display-name */
import React from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import InviteButton from './invite-button';
import config from '../../config';
import { toastError, toastSuccess } from '../../lib/utils';

import { MdDeleteForever } from 'react-icons/md';
import '../subscriberAndUserLists.css';

import {
  Table,
  Pagination,
  withPrincipal,
  useUpdateState,
  useQuery,
  useSignalEffect,
  debounce,
  Spinner,
} from '../../components';

import ActionButton from '../../components/actionButton';

const SubscriberSelect = ({ val, onChange }) => {
  const [state, setState] = useUpdateState({
    mode: 0,
    subscriptions: [],
  });

  const { mode, subscriptions } = state;

  const canChange = !val.isDeleted;

  useSignalEffect(
    async (signal) => {
      if (mode !== 1) return;

      const subscriptions = await getSubscribers({ signal });

      setState({ subscriptions });
    },
    [mode],
  );

  const getSubscribers = async ({ signal, search }) => {
    const response = await fetch(config.api.urlFor('subscribers', { pageSize: 10, search }), {
      signal,
    });

    const { rows } = await response.json();

    return rows;
  };

  const onSelectInputChange = async (subscriber) => {
    try {
      setState({ mode: 2 });

      const response = await fetch(config.api.urlFor('subscribe'), {
        method: 'POST',
        body: {
          userID: val.id,
          subscriberID: subscriber.id,
        },
      });

      const { isSuccess, message } = await response.json();

      if (!isSuccess) throw new Error(message);

      canChange && onChange && onChange(val, subscriber);

      toastSuccess('Subscription changed successfully.');
    } catch (err) {
      toastError(err.message);
    }

    setState({ mode: 0 });
  };

  const onLoadOptions = debounce(async (search, callback) => {
    const subscriptions = await getSubscribers({ search });

    callback(subscriptions);
  }, 300);

  switch (state.mode) {
    case 1: {
      return (
        !!canChange && (
          <div className="form-inline">
            <button className="link-button fas fa-angle-left mr-2" onClick={() => setState({ mode: 0 })}></button>
            <AsyncSelect
              className="w-75"
              cacheOptions
              defaultOptions={subscriptions}
              onChange={onSelectInputChange}
              loadOptions={onLoadOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>
        )
      );
    }

    case 2: {
      return <Spinner isVisible={true} />;
    }

    default: {
      return (
        <React.Fragment>
          {!!canChange && (
            <button className="link-button fas fa-angle-right mr-2" onClick={() => setState({ mode: 1 })}></button>
          )}
          <span>{val?.subscription || <i>** no subscription **</i>}</span>
        </React.Fragment>
      );
    }
  }
};

const UserTable = ({ data, page, pageSize, totalRecords, sort, isBusy, onDeleted, onSubscriptionChange, search }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const updateIsSSO = (e, userID) => {
    fetch(config.api.urlFor('saveUser'), {
      method: 'POST',
      body: {
        id: userID,
        isSSO: e.target.checked,
      },
    }).catch(() => toastError());
  };

  const columns = [
    {
      source: 'emailAddress',
      label: 'email address',
      sortable: true,
      class: 'admin-users-table-columns',
    },
    {
      source: 'firstName',
      label: 'first name',
      sortable: true,
      class: 'admin-users-table-columns',
    },
    {
      source: 'lastName',
      label: 'last name',
      sortable: true,
      class: 'admin-users-table-columns',
    },
    {
      source: 'created',
      label: 'created',
      sortable: true,
      class: 'admin-users-table-columns',
    },
    {
      source: 'subscription',
      label: 'subscription',
      // eslint-disable-next-line react/display-name
      formatter: (row) => <SubscriberSelect val={row} onChange={onSubscriptionChange} />,
      style: { verticalAlign: 'middle' },
    },
    {
      source: 'isSSO',
      label: 'single-sign on',
      // eslint-disable-next-line react/display-name
      formatter: (row) => {
        return (
          <input
            id={'sso-' + row.id}
            type="checkbox"
            className="user-details-sso-checkbox"
            defaultChecked={row.isSSO}
            onChange={(e) => !row.isDeleted && updateIsSSO(e, row.id)}
            disabled={row.isDeleted}
          />
        );
      },
      class: 'admin-users-table-columns admin-users-table-is-sso-column',
    },
    {
      source: 'isDeleted',
      label: 'is deleted',
      sortable: true,
      formatter: (row) => {
        return row.isDeleted ? <MdDeleteForever className="deleted-instance-icon" title="Deleted" /> : '';
      },
      class: 'admin-users-table-columns admin-users-table-is-deleted-column',
    },
    {
      source: 'actions',
      label: 'Action',
      // eslint-disable-next-line react/display-name
      formatter: (row) => {
        return !row.isDeleted ? (
          <div className="btn-group">
            <ActionButton userID={row.id} action="resetUser" />
            <ActionButton userEmailAddress={row.emailAddress.props.children} action="resetUserPassword" />
            <ActionButton userID={row.id} action="deleteUser" onAction={onDeleted} />
          </div>
        ) : (
          ''
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Table
        className="admin-users-table"
        columns={columns}
        data={data}
        isBusy={isBusy}
        sort={sort.orderBy}
        sortDirection={sort.dir}
        onSorted={(orderBy, dir) =>
          history.push(
            window.mapPath(match.path, { ...match.params, orderBy, dir }) + (search ? `?search=${search}` : ''),
          )
        }
      />
      <Pagination
        page={page}
        pageSize={pageSize}
        totalRecords={totalRecords}
        onPageChange={(page) =>
          history.push(window.mapPath(match.path, { ...match.params, page }) + (search ? `?search=${search}` : ''))
        }
      />
    </React.Fragment>
  );
};

const UserList = (props) => {
  const [state, setState] = useUpdateState({
    model: [],
    isBusy: true,
    search: '',
    pageSize: 10,
    userToBeDeleted: '',
  });

  const match = useRouteMatch();
  const history = useHistory();
  const queryParams = useQuery();

  const { orderBy, dir, page } = match.params;
  const { isBusy, model, totalRecords, pageSize, subscriptions, searchInput } = state;
  const { search } = queryParams;

  useSignalEffect(
    async (signal) => await getUsers({ search, page, pageSize, orderBy, dir, signal }),
    [search, page, pageSize, orderBy, dir],
  );

  const getUsers = async ({ search, page, pageSize, orderBy, dir, signal }) => {
    setState({ isBusy: true });

    const params = {
      search: search || '',
      page: parseInt(page || 1, 10),
      pageSize: parseInt(pageSize, 10),
      orderBy: orderBy || 'username',
      asc: dir === 'desc' ? 0 : 1,
      showDeleted: true,
    };

    const res = await fetch(config.api.urlFor('users', params), { signal });

    if (res.status !== 200) {
      toastError(res.statusText);
      return {};
    }
    const response = await res.json();

    if (response.isSuccess) {
      setState({
        ...params,
        model: response.data.rows.map((row) => ({
          id: row.id,
          emailAddress: !row.isDeleted ? (
            <Link to={`/admin/users/${row.id}`}>{row.emailAddress}</Link>
          ) : (
            row.emailAddress
          ),
          firstName: row.firstName || '-',
          lastName: row.lastName || '-',
          created: new Date(row.created).toLocaleDateString(),
          subscription: row.subscription,
          isSSO: row.isSSO,
          isDeleted: row.isDeleted,
        })),
        totalRecords: response.data.totalRecords || 0,
        search: search,
        isBusy: false,
      });
    } else {
      setState({ isBusy: false });
      if (response.message) {
        toastError(response.message);
      }
    }

    return response;
  };

  const reloadData = async () => await getUsers({ page, pageSize, orderBy, dir, search });

  const onSubscriptionChange = async (user, subscription) => {
    const modelUser = model.find((u) => u.id === user.id);

    modelUser.subscription = subscription.name;

    setState({ model });

    if (props.principal.id === user.id) window.$refreshPrincipal();
  };

  return (
    <div>
      <nav className="navbar navbar-expand navbar-light bg-light mb-3">
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <InviteButton reloadDataInParent={reloadData} />
            </li>
          </ul>
          <form
            className="form-inline"
            onSubmit={(e) => {
              e.preventDefault();
              history.push(
                window.mapPath(match.path, { ...match.params, page: 1 }) +
                  (searchInput ? `?search=${searchInput}` : ''),
              );
            }}
          >
            <input
              id="search"
              type="text"
              className="form-control mr-2"
              placeholder="search"
              value={searchInput}
              onChange={(e) => setState({ searchInput: e.target.value })}
            />
          </form>
        </div>
      </nav>
      <UserTable
        data={model}
        page={parseInt(page, 10)}
        pageSize={pageSize}
        totalRecords={totalRecords}
        subscriptions={subscriptions}
        sort={{ orderBy, dir }}
        updateSubscriptions={props.principal.roles.some((r) =>
          r.permissions.some((p) => p === 'admin.subscriptions.subscribe'),
        )}
        isBusy={isBusy}
        onDeleted={() => getUsers({ search, page, pageSize, orderBy, dir })}
        search={state.search}
        onSubscriptionChange={onSubscriptionChange}
      />
    </div>
  );
};

export default withPrincipal(UserList);
