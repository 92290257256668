import React from 'react';

import config from '../../config';

import { Link } from 'react-router-dom';

import ReactTable from 'react-table';

import { textColumnFilter, numericColumnFilter } from '../../components/reactTable';

import { Tooltip } from '../../tooltips.js';

import Chart from 'chart.js';

class VendorsRisk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
      pieChart: {},
      params: this.props.match.params,
      isTableLoaded: false,
    };
  }

  componentDidMount() {
    this.getVendorRisk();
    this.doChart();
  }

  componentDidUpdate(prevProps) {
    const { pieChart } = this.state;

    setTimeout(() => {
      const chartObj = new Chart(pieChart.id, pieChart.options);
      pieChart.chartObj = chartObj;
      return chartObj && 1;
    }, 100);
  }

  getVendorRisk() {
    const _self = this;
    _self.setState({ isBusy: true });

    fetch(config.api.urlFor('providerCountsByRisk'))
      .then((response) => response.json())
      .then((data) => {
        const result = [data.marginal, data.low, data.moderate, data.high, data.critical];

        _self.setState({ vendorRisk: [result], isBusy: false });
      })
      .catch((err) => console.log(err.stack));
  }

  doChart() {
    const pieChart = {
      id: 'pieChart',
      icon: 'fas fa-chart-pie',
      label: 'Vendors by Risk',
      height: '200',
      onClick: (props) => {
        if (props[0]) {
          var chartData = props[0]['_chart'].config.data;
          var idx = props[0]['_index'];

          var label = chartData.labels[idx];
          var value = chartData.datasets[0].data[idx];

          this.setState({ params: { risk: label, value: value } });
        }
      },
    };

    // Bar chart doesn't make sense without the AARP portfolios.
    // delete charts.barChart;

    this.setState({ pieChart: pieChart });

    this.doughnutChart();
  }

  doughnutChart() {
    const options = {
      type: 'doughnut',
      data: {
        labels: ['Marginal (> 90)', 'Low (80 - 89)', 'Moderate (70 - 79)', 'High (60 - 69)', 'Critical (< 60)'],
        datasets: [
          {
            data: [14, 22, 34, 18, 2],
            backgroundColor: ['#357abd', '#4cae4c', '#FDC431', '#EE9336', '#D43F3A'],
          },
        ],
      },
    };

    const _self = this;

    fetch(config.api.urlFor('providerCountsByRisk'))
      .then((response) => response.json())
      .then((data) => {
        const { pieChart } = this.state;

        options.data.datasets[0].data = [data.marginal, data.low, data.moderate, data.high, data.critical];

        pieChart.options = options;

        _self.setState({ pieChart: pieChart });
      })
      .catch((err) => console.log(err.stack));
  }

  riskFilter = ({ filter, onChange }) => {
    const { params, isTableLoaded } = this.state;

    if (!params.risk) {
      return numericColumnFilter({ filter, onChange });
    }

    let selectValue;
    let inputValue;

    switch (params.risk) {
      case 'Marginal (> 90)':
        selectValue = '>';
        inputValue = 90;
        break;
      case 'Low (80 - 89)':
        selectValue = '>';
        inputValue = 80;
        break;
      case 'Moderate (70 - 79)':
        selectValue = '>';
        inputValue = 70;
        break;
      case 'High (60 - 69)':
        selectValue = '>';
        inputValue = 60;
        break;
      case 'Critical (< 60)':
        selectValue = '<';
        inputValue = 59;
        break;
      default:
        inputValue = null;
        selectValue = '=';
    }

    var input = document.querySelector('#riskInput');
    var select = document.querySelector('#riskSelect');

    if (isTableLoaded && select && parseInt(select.value) !== selectValue) {
      const nativeSelectValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;
      nativeSelectValueSetter.call(select, selectValue);

      const ev1 = new Event('input', { bubbles: true });
      select.dispatchEvent(ev1);
    }

    if (isTableLoaded && input && parseInt(input.value, 10) !== inputValue) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      nativeInputValueSetter.call(input, inputValue);

      const ev2 = new Event('input', { bubbles: true });
      input.dispatchEvent(ev2);
    }

    return (
      <div>
        <select
          id="riskSelect"
          onChange={() => onChange({ value: input ? input.value : '', comparator: select ? select.value : '=' })}
          disabled
        >
          <option>=</option>
          <option>&gt;</option>
          <option>&lt;</option>
        </select>
        <input
          id="riskInput"
          type="text"
          onChange={() => onChange({ value: input ? input.value : '', comparator: select ? select.value : '=' })}
          disabled
        />
      </div>
    );
  };

  doTable(page, filter, sort) {
    const _self = this;

    page = parseInt(page || 1, 10);
    filter = filter && filter.push ? filter.slice() : [];
    sort = sort || [];

    _self.setState({ tableIsBusy: true });

    let url = config.api.urlFor('providerScores', { page: page });

    if (filter) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort && sort.length) {
      url += `&orderBy=${sort[0].id}&asc=${!sort[0].desc ? 1 : 0}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const providers = data.rows.map((provider) => {
          return {
            name: <Link to={`/reports/provider/${provider.id}`}>{provider.name}</Link>,
            portfolio: provider.parent
              ? `4th Party of ${provider.parent}`
              : <Link to={`/portfolios/${provider.portfolio}`}>{provider.portfolio}</Link> || '-',
            risk: provider.avg_risk_score || '-',
            dns: provider.avg_dns_score || '-',
            // doppelgangers    : provider.doppelgangers,
            // dns_security     : provider.avg_dns_security,
            web_app: provider.avg_web_app_score || '-',
            auth_security: provider.avg_auth_security || '-',
            session_security: provider.avg_session_security || '-',
            // web_vulns        : provider.avg_web_vulns,
            // host_vuln_score  : provider.avg_host_vuln_score,
            // platform_score   : provider.avg_service_score,
            // service_score    : provider.avg_service_score,
            ssl: provider.avg_ssl_score || '-',
            cipher: provider.avg_cipher_score || '-',
          };
        });

        _self.setState({
          providers,
          pages: Math.ceil(data.totalRecords / 10),
          tableIsBusy: false,
          isTableLoaded: true,
        });
      })
      .catch((err) => _self.setState({ tableIsBusy: false }));
  }

  render() {
    const { pieChart } = this.state;
    let { providers, pages } = this.state;

    const content = (
      <React.Fragment>
        <h1>Vendor Risk</h1>
        <div className="row">
          <div className="col-4">
            <div className="card mb-3">
              <div className="card-header">
                <i className={`${pieChart.icon} mr-1`}></i>
                {pieChart.label}
              </div>
              <div className="card-body text-center align-middle">
                <canvas
                  id={pieChart.id}
                  height={pieChart.height}
                  style={{ display: pieChart.options === undefined ? 'none' : 'block' }}
                  onClick={(event) => {
                    pieChart.onClick && pieChart.onClick(pieChart.chartObj.getElementsAtEvent(event));
                  }}
                ></canvas>
                {pieChart.options === undefined && <i className="fas fa-circle-notch fa-spin fa-5x"></i>}
              </div>
              <div className="card-footer small text-muted"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card o-hidden h-100">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <ReactTable
                      manual
                      columns={[
                        { Header: 'Name', accessor: 'name', minWidth: 200, Filter: textColumnFilter },
                        { Header: 'Portfolio', accessor: 'portfolio', Filter: textColumnFilter },
                        {
                          Header: <Tooltip message="Cyber Risk Total">Risk</Tooltip>,
                          accessor: 'risk',
                          Cell: ({ row }) => <div className="text-center">{row.risk}</div>,
                          Filter: this.riskFilter,
                        },
                        {
                          Header: <Tooltip message="DNS">DNS</Tooltip>,
                          accessor: 'dns',
                          Cell: ({ row }) => <div className="text-center">{row.dns}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="App Sec">App Sec</Tooltip>,
                          accessor: 'web_app',
                          Cell: ({ row }) => <div className="text-center">{row.web_app}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="Auth">Auth</Tooltip>,
                          accessor: 'auth_security',
                          Cell: ({ row }) => <div className="text-center">{row.auth_security}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="Session">Session</Tooltip>,
                          accessor: 'session_security',
                          Cell: ({ row }) => <div className="text-center">{row.session_security}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="SSL">SSL</Tooltip>,
                          accessor: 'ssl',
                          Cell: ({ row }) => <div className="text-center">{row.ssl}</div>,
                          Filter: numericColumnFilter,
                        },
                        {
                          Header: <Tooltip message="Ciphers">Cipher</Tooltip>,
                          accessor: 'cipher',
                          Cell: ({ row }) => <div className="text-center">{row.cipher}</div>,
                          Filter: numericColumnFilter,
                        },
                      ]}
                      multiSort={false}
                      showPageSizeOptions={false}
                      filterable
                      data={providers}
                      defaultSorted={[{ id: 'risk', desc: false }]}
                      pageSize={0}
                      pages={pages}
                      onFetchData={(state) => this.doTable(state.page + 1, state.filtered, state.sorted)}
                      loading={this.state.tableIsBusy}
                      className="-striped -highlight hide-arrows"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return content;
  }
}

export default VendorsRisk;
