import { createMachine } from 'xstate';

const ModalStagesXState = createMachine(
  {
    id: 'modalStagesContainer',
    initial: 'idle',
    context: {
      mode: undefined,
      hasQuestionnaireReport: undefined,
      hasProductReports: undefined,
      isVendorCreationOnly: undefined,
      firstStep: undefined,
    },
    on: {
      CLOSE: { target: 'idle', actions: ['resetContext'] },
    },
    states: {
      idle: {
        on: {
          OPEN: [
            {
              target: 'singleProduct',
              cond: (context) => context.mode === 'product',
              actions: (context) => {
                context.firstStep = 'singleProduct';
              },
            },
            {
              target: 'questionnaire',
              cond: (context) => context.mode === 'purchaseOnly' && context.hasQuestionnaireReport,
              actions: (context) => {
                context.firstStep = 'questionnaire';
              },
            },
            {
              target: 'confirmation',
              cond: (context) => context.mode === 'purchaseOnly',
              actions: (context) => {
                context.firstStep = 'confirmation';
              },
            },
            {
              target: 'reports',
              actions: (context) => {
                context.firstStep = 'reports';
              },
            },
          ],
          UPDATE_CONTEXT: { internal: true, actions: ['updateMode', 'updateFlags'] },
        },
      },
      reports: {
        on: {
          NEXT: [
            { target: 'questionnaire', cond: 'hasQuestionnaireReport' },
            { target: 'products', cond: 'hasProductReports' },
            { target: 'idle', cond: 'isVendorCreationOnly', actions: ['resetContext'] },
            { target: 'confirmation' },
          ],
          UPDATE_CONTEXT: { internal: true, actions: ['updateFlags'] },
        },
      },
      questionnaire: {
        on: {
          NEXT: [{ target: 'products', cond: 'hasProductReports' }, { target: 'confirmation' }],
          PREV: { target: 'reports', cond: 'isNotPurchaseOnly' },
        },
      },
      products: {
        on: {
          NEXT: { target: 'confirmation' },
          PREV: [
            { target: 'questionnaire', cond: 'hasQuestionnaireReport' },
            { target: 'singleProduct', cond: 'isSingleProductMode' },
            { target: 'reports' },
          ],
          UPDATE_CONTEXT: { internal: true, actions: ['updateFlags'] },
        },
      },
      confirmation: {
        on: {
          NEXT: { target: 'result' },
          PREV: [
            { target: 'products', cond: (context) => context.hasProductReports === true || context.mode === 'product' },
            { target: 'questionnaire', cond: 'hasQuestionnaireReport' },
            { target: 'reports', cond: 'isNotPurchaseOnly' },
          ],
        },
      },
      singleProduct: {
        on: {
          NEXT: { target: 'products' },
          UPDATE_CONTEXT: { internal: true, actions: ['updateFlags'] },
        },
      },
      result: {},
    },
  },
  {
    guards: {
      hasProductReports: (context) => {
        return context.hasProductReports === true;
      },
      hasQuestionnaireReport: (context) => {
        return context.hasQuestionnaireReport === true;
      },
      isNotPurchaseOnly: (context) => {
        return context.mode !== 'purchaseOnly';
      },
      isSingleProductMode: (context) => {
        return context.mode === 'product';
      },
      isVendorCreationOnly: (context) => {
        return context.isVendorCreationOnly === true;
      },
    },
    actions: {
      updateMode: (context, event) => {
        if (event.mode !== undefined) {
          context.mode = event.mode;
        }
      },
      updateFlags: (context, event) => {
        if (event.hasQuestionnaireReport !== undefined) {
          context.hasQuestionnaireReport = event.hasQuestionnaireReport;
        }
        if (event.hasProductReports !== undefined) {
          context.hasProductReports = event.hasProductReports;
        }
        if (event.isVendorCreationOnly !== undefined) {
          context.isVendorCreationOnly = event.isVendorCreationOnly;
        }
      },
      resetContext: (context) => {
        context.mode = undefined;
        context.hasQuestionnaireReport = undefined;
        context.hasProductReports = undefined;
        context.isVendorCreationOnly = undefined;
        context.firstStep = undefined;
      },
    },
  },
);

export default ModalStagesXState;
