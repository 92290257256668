import React from 'react';
import { useUpdateState, withPrincipal } from '../../components';
import config from '../../config';
import { emailIsValid, nameIsValid, toastError, toastSuccess } from '../../lib/utils';

const Contact = (props) => {
  //Props
  const { principal = {} } = props;
  const { showAssessmentRequest, features = [] } = principal;
  const hasSBOMfeature = features.indexOf('SBOM') > -1;

  //localStorage
  const requestProductDeletionData = localStorage.getItem('requestProductDeletion');
  const productDetails = requestProductDeletionData ? requestProductDeletionData.split('|') : [];
  const isProductDeletionRequest = !!(productDetails && productDetails.length);

  //State
  const [state, setState] = useUpdateState({
    isBusy: false,
    subtitle: isProductDeletionRequest
      ? 'Purchasable reports are associated with this product. Your deletion request will be reviewed by a Fortress Analyst before its completion.'
      : 'For sales inquiries, product demos and all other purposes, please contact us using this form, by phone or at sales@assettovendor.com.',
    firstName: principal.firstName || '',
    lastName: principal.lastName || '',
    email: principal.emailAddress || '',
    need:
      localStorage.getItem('requestAssessment') === 'true'
        ? 'Request an Assessment'
        : isProductDeletionRequest
        ? 'Product Deletion'
        : localStorage.getItem('requestSBOMsubscription') === 'true'
        ? 'SBOM Inquiry'
        : '',
    message:
      localStorage.getItem('requestAssessment') === 'true'
        ? 'I would like to request an assessment on my company.\n\n'
        : isProductDeletionRequest
        ? `I would like to delete the product '${productDetails[1]}' with ID '${productDetails[0]}'.\n\n`
        : localStorage.getItem('requestSBOMsubscription') === 'true'
        ? `I would like to get more information on SBOM subscriptions.`
        : '',
    errorEmailField: false,
    touchedEmailField: false,
  });

  const { isBusy, subtitle, firstName, lastName, email, need, message, errorEmailField, touchedEmailField } = state;

  //Effects
  React.useEffect(() => {
    localStorage.setItem('requestAssessment', 'false');
    localStorage.setItem('requestProductDeletion', '');
    localStorage.setItem('requestSBOMsubscription', 'false');
  }, []);

  //Helper Funcs
  const submit = (e) => {
    e.preventDefault();

    setState({ isBusy: true });

    fetch(config.api.urlFor('requestSupport'), {
      method: 'POST',
      body: {
        requestDetail: {
          Need: need,
          'User input email': email.trim(),
          'User input first name': firstName.trim(),
          'User input last name': lastName.trim(),
          Message: message.trim(),
        },
        userEmail: principal.emailAddress,
        userName: principal.firstName + ' ' + principal.lastName,
        userId: principal.id,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          toastSuccess('Thank you!');
        } else {
          toastError('Something went wrong!');
        }
      })
      .catch(() => toastError('Something went wrong!'))
      .then(() => setState({ isBusy: false }));
  };

  const moveTextareaFocusAtTheEnd = (e) => {
    let tempValue = e.target.value;
    e.target.value = '';
    e.target.value = tempValue;
  };

  const handleInputBox = (e, keyName) => {
    const val = e.target.value;
    let hasNameError = false;

    if (keyName === 'firstName' || keyName === 'lastName') {
      if (val.length && !nameIsValid(val)) {
        hasNameError = true;
      }
    } else if (keyName === 'email') {
      setState({ errorEmailField: val.length && !emailIsValid(val) });
    }

    if (!hasNameError) {
      setState({ [keyName]: val });
    }
  };

  const handleBlur = () => {
    setState({ touchedEmailField: true });
  };

  //Render
  return (
    <div className="row justify-content-md-center">
      <div className="col-5">
        <div className="row">
          <div className="col-12">
            <h4>
              <a
                href={config.contactSupport && config.contactSupport.domain}
                target={config.contactSupport && config.contactSupport.domain}
              >
                Fortress Information Security
              </a>
            </h4>
            <h5>{config.contactSupport && config.contactSupport.telephoneUI}</h5>
            <small>{subtitle}</small>
          </div>
        </div>
        <hr />
        <form onSubmit={submit}>
          <div className="controls">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="form_name">First Name *</label>
                  <input
                    id="form_name"
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Please enter your first name *"
                    required="required"
                    data-error="Firstname is required."
                    onChange={(e) => handleInputBox(e, 'firstName')}
                    value={firstName}
                    maxLength={64}
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="form_lastname">Last Name *</label>
                  <input
                    id="form_lastname"
                    type="text"
                    name="surname"
                    className="form-control"
                    placeholder="Please enter your last name *"
                    required="required"
                    data-error="Lastname is required."
                    onChange={(e) => handleInputBox(e, 'lastName')}
                    value={lastName}
                    maxLength={64}
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="form_email">Email *</label>
                  <input
                    id="form_email"
                    type="email"
                    name="email"
                    className={'form-control' + (errorEmailField && touchedEmailField ? ' is-invalid' : '')}
                    placeholder="Please enter your email *"
                    required="required"
                    data-error="Valid email is required."
                    onChange={(e) => handleInputBox(e, 'email')}
                    onBlur={handleBlur}
                    value={email}
                    maxLength={256}
                  />
                  {!!(errorEmailField && touchedEmailField) && (
                    <span className="m-error"> Please enter a valid email address </span>
                  )}
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="form_need">Please specify your need</label>
                  <select
                    id="form_need"
                    name="need"
                    value={need}
                    className="form-control"
                    data-error="Please specify your need."
                    onChange={(e) => handleInputBox(e, 'need')}
                  >
                    <option value="" />
                    <option value="Product Demo">Product Demo</option>
                    {showAssessmentRequest && <option value="Request an Assessment">Request an Assessment</option>}
                    <option value="Product Deletion">Product Deletion</option>
                    {!hasSBOMfeature && <option value="SBOM Inquiry">SBOM Inquiry</option>}
                    <option value="Other">Other</option>
                  </select>
                  <div className="help-block with-errors" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="form_message">Message *</label>
                  <textarea
                    autoFocus={message ? true : false}
                    onFocus={moveTextareaFocusAtTheEnd}
                    id="form_message"
                    name="message"
                    className="form-control"
                    placeholder="Message*"
                    rows="4"
                    required="required"
                    data-error="Please, leave us a message."
                    onChange={(e) => handleInputBox(e, 'message')}
                    value={message}
                    maxLength={500}
                  />
                  <div className="help-block with-errors" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-outline-primary"
                  disabled={
                    isBusy ||
                    errorEmailField ||
                    !firstName.trim() ||
                    !lastName.trim() ||
                    !message.trim() ||
                    !email.trim()
                  }
                >
                  submit
                  {isBusy && <i className="ml-2 fas fa-fw fa-circle-notch fa-spin" />}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withPrincipal(Contact);
