import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import BootstrapTable from 'react-bootstrap-table-next';

import Flex from '../../components/flex';
import Busy from '../../components/busy';
import config from '../../config';

const getTargetGroups = async () => {
  let data = await fetch(config.api.urlFor('targetGroups'));
  data = await data.json();
  return data.data;
};

const addTargetGroup = async (group) => {
  await fetch(config.api.urlFor('targetGroups'), {
    method: 'POST',
    body: group,
  });
};

const editTargetGroup = async (group) => {
  await fetch(config.api.urlFor('targetGroupsEdit', { id: group.id }), {
    method: 'POST',
    body: group,
  });
};

const deleteTargetGroup = async (id) => {
  await fetch(config.api.urlFor('targetGroupsDelete', { id }));
};

const style = {
  modalStyle: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  },
};
const defaultCols = [
  {
    dataField: 'name',
    text: 'Name',
    headerStyle: {
      border: 'none',
    },
  },
];
export default (props) => {
  const [data, setData] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [inputName, setInputName] = useState('');
  const [editTargetGroupName, setEditTargetGroupName] = useState('');
  const [editedRow, setEditedRow] = useState(null);

  const openEditModal = (rowData) => {
    setShowEditModal(true);
    setEditTargetGroupName(rowData.name);
    setEditedRow(rowData);
  };

  const saveEdit = async () => {
    setShowEditModal(false);
    await editTargetGroup({
      name: editTargetGroupName,
      id: editedRow.id,
    });
    setData(null);
    let data = await getTargetGroups();
    setData(data);
    setEditedRow(null);
    setEditTargetGroupName('');
  };

  const addModal = async () => {
    setShowAddModal(false);
    await addTargetGroup({ name: inputName });
    setData(null);
    let data = await getTargetGroups();
    setData(data);
  };

  const deleteRow = async (rowData) => {
    await deleteTargetGroup(rowData.id);
    setData(null);
    let data = await getTargetGroups();
    setData(data);
  };

  const cols = [
    ...defaultCols,
    {
      dataField: 'edit',
      text: '',
      headerStyle: {
        border: 'none',
      },
      formatter: (cell, row) => {
        return (
          <div>
            <button className="btn btn-link" onClick={() => openEditModal(row)}>
              <i className="fas fa-edit"></i>
            </button>
            <button className="btn btn-link" onClick={() => deleteRow(row)}>
              <i className="fas fa-minus"></i>
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    // had to do this because of react warning
    async function get() {
      let data = await getTargetGroups();
      setData(data);
    }
    get();
  }, []);
  return (
    <React.Fragment>
      <Flex>
        <button className="btn btn-dark" onClick={() => setShowAddModal(true)}>
          Add
        </button>
      </Flex>
      <Busy isBusy={data === null}>
        <div className="card">
          <div className="card-body">
            <BootstrapTable keyField="name" data={data} columns={cols} bordered={false} />
          </div>
        </div>
      </Busy>
      <Modal
        isOpen={showAddModal}
        ariaHideApp={false}
        style={style.modalStyle}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setShowAddModal(false)}
      >
        <label>Target Group Name</label>
        <input type="text" className="form-control" onChange={(e) => setInputName(e.target.value)} value={inputName} />
        <button className="btn btn-dark" onClick={() => addModal()}>
          Add
        </button>
        <button className="btn btn-dark" onClick={() => setShowAddModal(false)}>
          Close
        </button>
      </Modal>
      <Modal
        isOpen={showEditModal}
        ariaHideApp={false}
        style={style.modalStyle}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setShowEditModal(false);
          setEditedRow(null);
          setEditTargetGroupName('');
        }}
      >
        <label>Target Group Name</label>
        <input
          type="text"
          className="form-control"
          onChange={(e) => setEditTargetGroupName(e.target.value)}
          value={editTargetGroupName}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <button className="btn btn-dark" onClick={() => saveEdit()}>
            Edit
          </button>
          <button className="btn btn-dark" onClick={() => setShowEditModal(false)}>
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
