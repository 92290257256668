import React, { Component } from 'react';
import { prependHTTPStoLink } from '../../../../../lib/utils';

import './vendorInfo.css';

class VendorInfo extends Component {
  render() {
    const { vendor = {} } = this.props;
    const { companyLogo, scoreDate, scoreIndustryName, naics, naicsDescription, domain } = vendor;
    const omitLogo = companyLogo == '' || companyLogo == null;
    const lastReportDateFormatted = new Date(scoreDate).toLocaleDateString();

    return (
      <>
        <div className="p-ddrr-vendor-text">
          {omitLogo ? null : <img className="p-ddrr-vendor-logo" src={companyLogo} alt="Vendor Logo" />}
          <div className="p-ddrr-industry">{scoreIndustryName}</div>
          <p>
            NAICS: {naics} {naicsDescription}
            <br />
            <small>
              <i>Note: Actual scope of services consumed may differ from the company&apos;s primary NAICS code.</i>
            </small>
            <br />
            Web Url:{' '}
            <a href={prependHTTPStoLink(domain)} target="_blank" rel="noopener noreferrer">
              {domain}
            </a>
            <br />
            <small>
              <i>Last Reported: {lastReportDateFormatted}</i>
            </small>
          </p>
        </div>
      </>
    );
  }
}

export default VendorInfo;
