import React from 'react';
import { CgCopy } from 'react-icons/cg';
import { toast } from 'react-toastify';

const CopyToClipboard = (props) => {
  const copyText = () => {
    const { text, successMessage, failureMessage, timeout } = props;

    if (text) {
      navigator.clipboard.writeText(text);

      toast.success(successMessage ? successMessage : 'The text is copied to Clipboard', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: timeout ? timeout : 2000,
      });
    } else {
      toast.info(failureMessage ? failureMessage : 'There is nothing to be copied', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: timeout ? timeout : 2000,
      });
    }
  };

  const { text, emptyText } = props;
  const displayText = text ? text : emptyText ? emptyText : '<EMPTY>';

  const styleOutline = {
    border: '1px solid black',
    borderStyle: 'dashed',
    margin: '20px 0',
    textAlign: 'center',
    padding: '20px 0',
    fontSize: 'x-large',
    minHeight: '80px',
    wordWrap: 'break-word',
  };

  const styleCopyButton = {
    float: 'right',
    cursor: 'pointer',
    margin: '10px',
  };

  return (
    <div style={styleOutline}>
      <b>{displayText}</b>
      <span title="Copy to Clipboard" style={styleCopyButton} onClick={copyText}>
        <CgCopy />
      </span>
    </div>
  );
};

export default CopyToClipboard;
