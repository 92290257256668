import React from 'react';
import { Link } from 'react-router-dom';

const termsOfUse = () => (
  <React.Fragment>
    <div className="row justify-content-center mb-lg-2">
      <div className="col-8">
        <h5>
          <h3 className="pb-5">Terms of Use </h3>
          <p>
            <b className="pl-5">These Terms of Use were last updated on December 20, 2021.</b>
          </p>
          <p>
            These Terms of Use (including any future modifications, the &quot;Terms&quot;) govern your use of this
            website, as well as any other websites, applications, products, or services that include these Terms or a
            link to these Terms (collectively, the &quot;Services&quot;). The Services are made available to you by
            Fortress Information Security LLC and its subsidiaries, divisions, and affiliates (collectively,
            &quot;we,&quot; &quot;us,&quot; or &quot;Fortress&quot;). These Terms set out the agreement between us and
            you regarding how you can use the Services and what responsibilities you and we have to each other.
          </p>
          <p>
            Your use of this website, as well as the Services, are governed by these Terms of Use, regardless of how you
            access them (including but not limited to through the Internet, through a mobile network, or in any other
            manner). By using the Services, you are agreeing to these Terms of Use, both on your behalf, and on behalf
            of the company, government agency, or juridical entity that you are affiliated or associated with, and you
            represent and warrant that you have the authority to bind such entity to these Terms of Use - if you do not
            have such authority, you are not allowed to utilize the Services. If you do not agree to these Terms of Use,
            then you are not allowed to use the Services.{' '}
          </p>
          <p>
            These Terms of Use are written in the English language. We do not guarantee the accuracy of any translated
            versions. To the extent any translated versions conflict with the English language version, the English
            language version shall control.
          </p>
          <p>
            PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICES. THESE TERMS CONTAIN IMPORTANT INFORMATION
            REGARDING YOUR LEGAL RIGHTS, INCLUDING MANDATORY ARBITRATION, NO CLASS RELIEF AND WAIVER OF YOUR RIGHT TO A
            JURY TRIAL.
          </p>
          <p>
            <b className="pl-5"> Acceptance of Terms; Requirements of Use; Export Controls</b>
          </p>
          <p>
            By applying to use the Services, using the Services, or submitting data or information via the Services, you
            agree to follow any additional guidelines or rules provided by us, as may change from time to time. We may
            make changes to these Terms of Use, and it is your responsibility to check these Terms of Use to determine
            when it was last updated and to ensure your continued compliance with any revised terms.
          </p>
          <p>
            You represent that you have reached the age of legal age to agree to these Terms of Use in the jurisdiction
            where you reside, and if you are not that you have obtained parental consent. If you are under the age of
            eighteen you are not permitted to use the Services. You represent and warrant that (i) you are not located
            in a country that is (a) subject to a U.S. government embargo (for example, Crimea, Cuba, Iran, North Korea
            or Syria) or (b) on Title 15, Part 740 Supplement 1 Country Group E of the U.S. Code of Federal Regulations,
            (ii) you are not listed on any U.S. or United Nations Security Council (UNSC) list of prohibited or
            restricted parties, including the list of Specially Designated Nationals and Blocked Persons administered by
            the U.S. Treasury Department or the U.S. Commerce Department&apos;s Denied Persons List, and (iii) you are
            not otherwise the target of U.S. or UNSC economic sanctions. You may not access, download or otherwise use
            any Services in violation of United States or UNSC export control or economic sanctions laws and
            regulations. Nothing related to the Services may be downloaded or otherwise exported or re-exported in
            violation of applicable laws, including without limitation to any end user in a U.S. embargoed country or
            territory or an end user included on any U.S., or UNSC government list of prohibited or restricted parties.
          </p>
          <p>
            Your use of the Services must comply with all applicable laws, rules, and regulations. You agree that you
            will not use any robot, spider, site search/retrieval application or other manual or automatic device to
            retrieve, index, &quot;scrape,&quot; &quot;data mine,&quot; or in any way gather content from the Services,
            any websites associated with the Services, or reproduce or circumvent the navigational structure or
            presentation of the Services without our express prior written consent. Notwithstanding the foregoing, we
            grant the operators of public online search engines limited permission to use search retrieval applications
            to reproduce materials from publicly viewable areas associated with the Services for the sole purpose of and
            solely to the extent necessary for creating publicly available searchable indices of such materials solely
            in connection with each operator&apos;s public online search service. We reserve the right to revoke these
            exceptions either generally or in specific instances.
          </p>
          <p>
            We may monitor your use of the Services but have no obligation to do so. any monitoring we may conduct will
            be done so in accordance with applicable law. We may use information or data collected during your use of
            the Services in accordance with our Privacy Policy, <Link to="/help/privacyPolicy">linked here</Link>. These
            Terms of Use, as amended from time to time, and including the
            <Link className="pl-2" to="/help/privacyPolicy">
              Privacy Policy
            </Link>
            , constitute the entire agreement between us and you with respect to your use of the Services, unless you
            have entered into a separate Master Services Agreement or other contract directly with us previously, in
            which case the previously entered-into contract controls wherever there is a conflict between these Terms of
            Use and the prior contract.
          </p>
          <p>
            We may, in our sole discretion and at any time without notice to you, terminate, suspend, or restrict your
            use of the Services for any reason, including your failure to comply with such rules as listed in this
            section. We may also charge for the Services, in which case you agree to pay all amounts owed to us under
            the prevailing rates provided to you by us associated with the use of the Services.
          </p>
          <p>
            <b className="pl-5"> Informational Purposes Only</b>
          </p>
          <p>
            The Services offer a range of content and features containing information for general guidance only, such as
            to assist you in your independent evaluation of vendors or other service providers. While we take reasonable
            efforts to offer current and accurate information where possible, the Services may contain references to
            certain laws and regulations which may have changed over time and may contain inaccurate information,
            including but not limited to information self-posted by various vendors that is not subject to independent
            evaluation by us for accuracy. You also agree that any evaluation, or rating, of any companies listed on the
            Services may be inaccurate, and that you will not rely upon any information from the Services to form a
            basis to take, or refrain from taking, any action. If you are posting information online through the
            Services, you represent and warrant that, after a reasonably diligence investigation, you have determined
            that all such information is true and accurate.
          </p>
          <p>
            <b className="pl-5"> Intellectual Property</b>
          </p>
          <p>
            Unless noted otherwise, the Services and all content posted via the Services are protected by copyright,
            trademark, and other laws of the United States. We reserve all rights not expressly granted in these Terms
            of Use. No part of the materials posted and available via the Services, including but not limited to the
            text, graphics, and html code, may be reproduced, or transmitted in any form, or by any means without our
            written permission. We comply with the copyright notice-and-takedown procedures set out in the United States
            Digital Millennium Copyright Act (DMCA), which applies to content reported and removed for violating U.S.
            copyrights, and please see our DMCA policy available at this link to submit a takedown request.
          </p>
          <p>
            Any downloadable information you may access and use on the Services must be used as they appear and must
            maintain all copyright or other notices or disclaimers precisely as posted by us, without modification,
            removal, or white-labeling by you. We expressly prohibit any redistribution, retransmission, commercial
            exploitation, linking, or other uses without our express written consent.{' '}
          </p>
          <p>
            &quot;Fortress&quot;, &quot;A2V&quot;, &quot;Fortress Information Security, LLC&quot;, and the Fortress
            logo, and certain product names that appear or are displayed on the Services (collectively, the
            &quot;Fortress Marks&quot;), are our trademarks or registered trademarks.
          </p>
          <p>
            <b className="pl-5"> User Content</b>
          </p>
          <p>
            The Services may include forums and other opportunities for you and other users to upload, post, transmit,
            or otherwise distribute (collectively, to &quot;Upload&quot;) content and materials (upon Upload, &quot;User
            Content&quot;). When you Upload any User Content, you hereby grant us and our licensees a perpetual,
            irrevocable, worldwide, royalty-free, fully paid up, sub-licensable through multiple tiers, transferable,
            non-exclusive license to use, reproduce, adapt, publicly display, publicly perform, synchronize and
            otherwise exploit that User Content, including any Personal Elements (as defined below) in your User
            Content, in any manner and any media, formats, and channels now known or later developed or discovered
            throughout the universe in perpetuity, including in connection with advertising, promotions or Services,
            without notice or payment to you. In some circumstances, you may also designate User Content as confidential
            upon Upload (upon Upload, &quot;Confidential User Content&quot;), and you may also request Confidential User
            Content that has been Uploaded by others. If you upload Confidential User Content, you will have the ability
            to approve or disapprove requests by other Users for the Confidential User Content, as well as to add any
            confidentiality terms or restrictions on use for the User requesting the Confidential User Content. If you
            request Confidential User Content, you agree that the User that uploaded the Confidential User Content may
            exercise their sole discretion in approving or denying any requests for the Confidential User Content, and
            that Fortress is not liable or in any way responsible or associated with any User&apos;s decisions to
            approve or deny any specific request for any Confidential User Content, and you agree not to challenge or
            otherwise contest any User decision to approve or deny access to any Confidential User Content.
          </p>

          <p>
            When you Upload any User Content or Confidential User Content, you represent and warrant that you own that
            User Content or Confidential User Content or have sufficient intellectual property and proprietary rights in
            order to make the grants in these Terms. You agree to pay any monies owed to any party based on our and our
            licensees&apos; use of your User Content or Confidential User Content.
          </p>
          <p>
            You acknowledge that you have no expectation of privacy or confidentiality with respect to any User Content,
            unless designated as Confidential User Content. While we may offer you the ability to Upload User Content
            anonymously, we may still store your account information. For some of our features, other members may be
            able to publish their own comments to your comments. We may use User Content for any purpose, including to
            develop aggregate ratings, personalize site views, or market Content or other products.
          </p>
          <p>
            If you Upload any User Content, you may not be able to remove it from the Services. We make no guarantees to
            remove User Content from the Services. Even if the Services give you an opportunity to delete User Content,
            we may retain the User Content in our backup files, which are not publicly available. We retain the right to
            make use of your Content in accordance with these Terms of Use even after your User Content is deleted. You
            acknowledge that (i) deletion of your User Content from the Services will not result in, and we are not
            responsible for, the deletion of the User Content by third parties who previously had access to that User
            Content and (ii) termination of your account will not automatically delete User Content you Uploaded.
          </p>
          <p>
            We also reserve the right to limit the storage capacity of your User Content or Confidential User Content.
            You assume full responsibility for maintaining backup copies of your User Content or Confidential User
            Content and we assume no responsibility for any loss of your User Content or Confidential User Content, for
            instance, due to its removal by us.
          </p>
          <p>
            We are not responsible or liable for any User Content or Confdential User Content, or any decisions made
            based on User Content or Confidential User Content. You are solely responsible for the User Content or
            Confidential User Content that you Upload and for any claims, losses or damages relating thereto.
          </p>
          <p>
            We do not endorse any User Content or Confidential User Content that you or other users Upload, and we may
            remove or refuse to post any User Content or Confidential User Content that, in our sole discretion, is
            objectionable or violates these Terms of Use. You acknowledge that you may encounter User Content or
            Confidential User Content on or through the Services that you find objectionable, offensive or otherwise
            inappropriate and you shall have no right against us based on User Content or Confidential User Content.
          </p>
          <p>
            For User Content not designated Confidential User Content, we and our partners may display advertising,
            promotions and other content in connection with your User Content and you will not be entitled to any
            associated revenue. You agree that we and our licensees may give you credit for your User Content, but are
            not required to so. To the extent permitted by applicable law, you hereby waive and agree not to assert any
            &quot;moral rights&quot; or other proprietary rights in your User Content or Confidential User Content
            against us, our licensees, our representatives or other users. When you Upload any User Content, you also
            consent to the recording, use and reuse by us and our licensees of your voice, actions, likeness, name,
            appearance, profile photograph, performance, biographical material, and any other identifying information in
            your User Content as used or modified by us (collectively, &quot;Personal Elements&quot;).
          </p>
          <p>
            <b className="pl-5"> Unsolicited Submissions</b>
          </p>
          <p>
            We do not accept unsolicited submissions for any media, products or services. Please do not make unsolicited
            submissions to us through the Services, including submissions by e-mail, text messages or other means
            (collectively, &quot;Submissions&quot;). We are not responsible for any similarity, in any media, of the
            Services to your Submissions. Any Submissions will be deemed User Content and subject to the grants by you
            applicable to User Content in these Terms of Use.
          </p>
          <p>
            <b className="pl-5"> </b>
          </p>
          <p>
            We have not agreed to and do not agree to treat as confidential any comments, information, ideas, concepts,
            reviews, techniques or other communication you may send to us, including via responses to questionnaires and
            other methods (&quot;Feedback&quot;). We shall be free to use, profit from, disclose, publish, or otherwise
            exploit any Feedback without compensation to you. Our receipt of your Submissions or Feedback is not an
            admission by us of their novelty, priority or originality and does not limit our right to contest
            intellectual property rights related to your Submissions or Feedback. Any Feedback will be deemed User
            Content and subject to the grants by you applicable to User Content in these Terms of Use.
          </p>
          <p>
            You hereby appoint us as your agent with full authority to execute any document or take any action we may
            consider appropriate in order to confirm the rights granted by you to us in this Agreement associated with
            Submissions and Feedback.
          </p>
          <p>
            <b className="pl-5"> Disclaimers and Limitations of Liability; Indemnity</b>
          </p>
          <p>
            <b className="pl-5"> </b>
          </p>
          <p>
            THE SERVICES (INCLUDING, WITHOUT LIMITATION, ANY CONTENT OR OTHER PART THEREOF) ARE PROVIDED FOR
            INFORMATIONAL PURPOSES ONLY, AND WE DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES REGARDING ACCURACY. WE
            ALSO MAKE NO REPRESENTATION OR WARRANTIES WITH RESPECT TO THE FUNCTIONALITY OR AVIALABILITY OF THE SERVICES.
            THE SERVICES ARE MADE AVAILABLE ON AN &quot;AS-IS&quot; BASIS AND WE MAKE NO EXPRESS OR IMPLIED
            REPRESENTATIONS OR WARRANTIES REGARDING THE SERVICES. WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY FOR ANY
            ERRORS OR OMISSIONS IN THE CONTENT DISPLAYED OR PROVIDED THROUGH THE SERVICES. WITHOUT LIMITING THE
            FOREGOING, WE DO NOT WARRANT THE SERVICES WILL BE ERROR-FREE, SECURE, FREE FROM VIRUSES, OR FREE FROM
            MALICIOUS CODE.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL LIABILITY AND SHALL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOST
            PROFITS OR REVENUES, GOODWILL, WORK STOPPAGE, SECURITY BREACHES, VIRUSES, COMPUTER FAILURE OR MALFUNCTION,
            USE, DATA OR OTHER INTANGIBLE LOSSES OR COMMERCIAL DAMAGES, EVEN IF ANY OF SUCH PARTIES ARE ADVISED OF THE
            POSSIBILITY OF SUCH LOSSES, ARISING UNDER OR IN CONNECTION WITH THESE TERMS, THE SERVICES, THE USE OF OR
            INABILITY TO USE THE SAME, OR ANY OTHER SUBJECT MATTER HEREOF.
          </p>
          <p>
            IN NO EVENT WILL OUR AGGREGATE LIABILITY TO YOU IN CONNECTION WITH THE SERVICES OR THESE TERMS OF USE EXCEED
            (A) THE AMOUNT (IF ANY) PAID BY YOU TO US IN THE SIX MONTHS IMMEDIATELY PRECEDING THE EVENT WHICH GAVE RISE
            TO THE LIABILITY OR (B) ONE HUNDRED DOLLARS ($100), WHICHEVER IS LESS.
          </p>
          <p>
            You agree, to the maximum extent permitted by applicable law, to defend, indemnify, and hold us and our
            insurers and affiliates harmless from and against any and all losses, damages, liabilities, costs (including
            reasonable attorneys&apos; fees) and expenses in connection with any claim arising out of or in connection
            with your use of the Services or your violation of these Terms of Use.{' '}
          </p>
          <p>
            <b className="pl-5"> Agreement to Arbitrate All Claims on Individualized Basis</b>
          </p>
          <p>
            You agree that any and all disputes or claims that have arisen or may arise between you and us that relate
            in any way to your use of the Services, our actions or omissions, or our agents&apos; actions or omissions,
            or any products or services sold, offered, or purchased from us shall be resolved through confidential,
            final and binding arbitration rather than in court, with the exception of any dispute relating to the
            enforcement or validity of our intellectual property rights. The Federal Arbitration Act (&quot;FAA&quot;)
            governs the interpretation and enforcement of this Agreement to Arbitrate.
          </p>
          <p>
            UNLESS WE AGREE OTHERWISE IN WRITING, EACH OF YOU MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
            BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE ACTION OR PRIVATE
            ATTORNEY GENERAL PROCEEDING. ALSO, TO THE EXTENT AVAILABLE BY LAW, AND SUBJECT TO THE DAMAGE LIMITATIONS
            DISCUSSED HEREIN, THE ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR, AND FOR THE BENEFIT OF, THE INDIVIDUAL
            PARTY SEEKING RELIEF. If a court decides that any part of this agreement to arbitrate is invalid or
            unenforceable, the other parts of this Section shall still apply. Specifically, if a court decides that
            applicable law precludes enforcement of any of this paragraph&apos;s limitations as to a particular claim or
            a particular request for a remedy (such as a request for public injunctive relief), then that claim or that
            remedy request (and only that claim or that remedy request) may be severed from the arbitration and may be
            brought in court, subject to your and our right to appeal the court&apos;s decision. All other claims shall
            be arbitrated.
          </p>
          <p>
            The arbitration will be conducted by the American Arbitration Association (&quot;AAA&quot;) under its rules
            and procedures, including the AAA&apos;s Supplementary Procedures for Consumer-Related Disputes (as
            applicable), as modified by this Section. The AAA&apos;s rules are available at www.adr.org. A form for
            initiating arbitration proceedings is available on the AAA&apos;s website at http://www.adr.org. The
            arbitration shall be held in Orange County, Florida, or at another mutually agreed location.{' '}
          </p>
          <p>
            The arbitrator&apos;s award shall be confidential, final and binding, and judgment on the award rendered by
            the arbitrator may be entered in any court having jurisdiction thereof. Payment of all filing,
            administration, and arbitrator fees will be governed by the AAA&apos;s rules.
          </p>
          <p>
            <b className="pl-5"> Applicable Law & Waiver of Jury Trial</b>
          </p>
          <p>
            You agree that the laws of the State of Florida, without regard to principles of conflict of laws, will
            govern these Terms of Use and any claim or dispute that has arisen or may arise between us, except as
            otherwise stated in these Terms. Where arbitration does not apply, you consent to submit to the exclusive
            jurisdiction of the federal and state courts located in Orange County, Florida, USA, and waive any
            jurisdictional, venue, or inconvenient forum objections thereto, and you specifically waive the right to a
            jury trial and acknowledge and agree that under no circumstances will you have the right to have a jury
            decide any claims or dispute regarding the Services. You understand that the Services are directed only to
            residents of the United States.
          </p>
          <p>
            <b className="pl-5"> Claims and Disputes Must Be Filed Within One Year & Waiver.</b>
          </p>
          <p>
            To the extent permitted by law, and without limiting the effect of any disclaimer contained herein, any
            cause of action or claim you may have with respect to your use of the Services, including, without
            limitation, any related product, services, or other content offered or provided by us must be commenced
            within one (1) year after the claim or cause of action arises. This section applies to you and your heirs,
            successors, and assigns.
          </p>
          <p>
            Our failure to exercise or enforce any right or provision of these Terms of Use shall not constitute a
            waiver of such right or provision. If any provision of these Terms of Use is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to
            the parties&apos; intentions as reflected in the provision, and the other provisions of these Terms of Use
            remain in full force and effect.{' '}
          </p>
          <p>
            We may at any time assign our rights and obligations under these Terms of Use, in whole or in part, without
            notice to you. You may not assign your rights or obligations under these Terms of Use without our prior
            written consent. We may make changes to these Terms of Use from time to time, and it is your responsibility
            to check these Terms of Use to determine when it was last updated.
          </p>
        </h5>
      </div>
    </div>
  </React.Fragment>
);

export default termsOfUse;
