import React from 'react';

const textColumnFilter = ({ filter, onChange }) => <TextColumnFilter onChange={onChange} />;

class TextColumnFilter extends React.Component {
  constructor(props) {
    super(props);

    this.debouncing = false;

    this.state = {};
  }

  onChangeDelegate = (filter, comparator, override) => {
    if (this.debouncing) return;
    this.debouncing = true;

    setTimeout(() => {
      if (override || filter) {
        this.props.onChange({ value: filter, comparator });
      }

      this.debouncing = false;
    }, 500);
  };

  render() {
    const { filter = this.state.filter || '', comparator = '~*' } = this.props;

    return (
      <div>
        {/* <input type="text" value={filter} onChange={(e) => this.setState({ filter: e.target.value }) | this.onChangeDelegate(e.target.value, comparator, true)} /> */}
        <input
          onChange={(e) => this.setState({ filter: e.target.value })}
          type="text"
          value={filter}
          onKeyPress={(event) => {
            if (event.keyCode === 13 || event.which === 13) {
              this.onChangeDelegate(event.target.value, comparator, true);
            }
          }}
        />
      </div>
    );
  }
}

const numericColumnFilter = ({ filter, onChange }) => {
  const inputRef = React.createRef();

  let debouncing = false;
  const onChangeDelegate = (onChange, override) => {
    if (debouncing) return;
    debouncing = true;

    setTimeout(() => {
      if (!inputRef || !inputRef.current) {
        return;
      }

      if (override || inputRef.current.value) {
        onChange({ value: inputRef.current.value, comparator: '=' });
      }

      debouncing = false;
    }, 500);
  };

  return (
    <div>
      <input ref={inputRef} type="text" onChange={(e) => onChangeDelegate(onChange, true)} />
    </div>
  );
};

const dateColumnFilter = ({ filter, onChange }) => {
  const selectRef = React.createRef();

  return (
    <div>
      <select
        reg={selectRef}
        className="form-control"
        onChange={(e) => onChange(e.target.value)}
        value={filter ? filter.value : 12}
      >
        <option value={12}>All</option>
        <option value={0}>January</option>
        <option value={1}>February</option>
        <option value={2}>March</option>
        <option value={3}>April</option>
        <option value={4}>May</option>
        <option value={5}>June</option>
        <option value={6}>July</option>
        <option value={7}>August</option>
        <option value={8}>September</option>
        <option value={9}>October</option>
        <option value={10}>November</option>
        <option value={11}>December</option>
      </select>
    </div>
  );
};

const dateFilterMethod = (filter, row) => {
  if (filter.value === 12) {
    return true;
  }

  return filter.value === new Date(row[filter.id]).getMonth();
};

const CenterContent = (props) => <div className="text-center">{props.children}</div>;

const SuccessBubble = (props) => (
  <span className="fa-stack fa-1x">
    <i className="fas fa-circle fa-stack-2x text-success" style={{ opacity: 0.75 }}></i>
    <strong className="fa-stack-1x fa-inverse">{props.children}</strong>
  </span>
);

const DangerBubble = (props) => (
  <span className="fa-stack fa-1x">
    <i className="fas fa-circle fa-stack-2x text-danger" style={{ opacity: 0.75 }}></i>
    <strong className="fa-stack-1x fa-inverse">{props.children}</strong>
  </span>
);

export {
  textColumnFilter,
  TextColumnFilter,
  numericColumnFilter,
  dateColumnFilter,
  dateFilterMethod,
  CenterContent,
  SuccessBubble,
  DangerBubble,
};
