import React from 'react';
import Modal from 'react-modal';
import withPrincipal from '../../withPrincipal';
import FilesHistoryLogTable from '../filesHistoryLogTable';
import * as TrustCenterAction from './actions';
import { useTrustCenterStore } from './context';
import config from '../../../config';
import Busy from '../../busy';
import { toastError } from '../../../lib/utils';

const FileHistoryLogModal = () => {
  //Context
  const { store, dispatch } = useTrustCenterStore();
  const { historyLogModalFileID } = store;

  //State
  const [isBusy, setIsBusy] = React.useState(false);
  const [historyData, setHistoryData] = React.useState([]);

  //Effects
  React.useEffect(() => {
    if (historyLogModalFileID) {
      setIsBusy(true);

      //Load history data
      fetch(config.api.urlFor('fileItemViews', { fileID: historyLogModalFileID }))
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, data, message = 'Error loading file history.' } = response;

          if (!isSuccess) {
            setHistoryData([]);
            toastError(message);
            return;
          }

          const { viewers } = (data || {}).data;

          viewers.forEach((entry) => {
            entry.user = entry.firstName + (entry.firstName && entry.lastName ? ' ' : '') + entry.lastName;
            entry.date = new Date(entry.date).toLocaleString();
            entry.id = entry.user + '|' + entry.date;
          });

          setHistoryData(viewers || []);
        })
        .catch(() => {
          setHistoryData([]);
          toastError('An error occurred while trying to load the file history.');
        })
        .finally(() => setIsBusy(false));
    }
  }, [historyLogModalFileID]);

  //Functions
  const handleModalClose = () => {
    dispatch(TrustCenterAction.toggleHistoryLogModal());
  };

  //Render
  return (
    <Modal
      ariaHideApp={false}
      isOpen={!!historyLogModalFileID}
      className="sa-modal-style sa-file-history-modal"
      contentLabel="View History Log"
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
    >
      <Busy isBusy={isBusy}>
        <div className="sa-modal-btn-container">
          <div className="sa-modal-close" onClick={handleModalClose}>
            x
          </div>
        </div>
        <FilesHistoryLogTable data={historyData} />
        <div className="sa-modal-btn-container">
          <div className="sa-modal-btns-right">
            <button
              data-test-id="UploadFile-button-cancel"
              className="btn btn-outline-primary btn-nav sa-modal-cancel-btn"
              onClick={handleModalClose}
            >
              Close
            </button>
          </div>
        </div>
      </Busy>
    </Modal>
  );
};

export default withPrincipal(FileHistoryLogModal);
