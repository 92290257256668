import React from 'react';
import { IMAGE_NOT_AVAILABLE } from '../../../lib/utils';

const ProductCard = (props) => {
  //Props
  const { data = {}, onClick = () => {} } = props;
  const numOfSbomVers = data.versions ? data.versions.filter((ver) => ver.hasSBOM).length : 0;

  //Render
  return (
    <div className="product-cata-card" onClick={onClick}>
      <div className="product-logo-box">
        <img
          src={data.logoURL || IMAGE_NOT_AVAILABLE}
          alt={data.name?.length > 18 ? data.name?.substring(0, 15) + '...' : data.name}
          className="vendor-logo"
          onError={(currentTarget) => {
            currentTarget.onerror = null;
            currentTarget.src = IMAGE_NOT_AVAILABLE;
          }}
        />
      </div>
      <p className="product-cata-card-title">{data.name}</p>
      <div className="product-cata-card-versions">
        {numOfSbomVers > 0 && <div className="product-cata-card-ver-num">{numOfSbomVers + ' versions'}</div>}
      </div>
      <p className="product-cata-card-description">{data.description}</p>
    </div>
  );
};

export default ProductCard;
