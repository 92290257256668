import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { riskRankColorByString } from '../constants';

// empty chart is created if there are no POTENTIAL findings
const makeEmptyPieChart = (chart) => {
  chart.data = [
    {
      label: '-',
      disabled: true,
      value: 1,
      color: am4core.color('#dadada'),
      opacity: 0.3,
      strokeDasharray: '4,4',
      tooltip: '',
    },
  ];

  // Create series
  var series = chart.series.push(new am4charts.PieSeries());
  series.dataFields.value = 'value';
  series.dataFields.category = 'label';

  // Set up slice appearance
  var slice = series.slices.template;
  slice.states.getKey('active').properties.shiftRadius = 0;
  slice.propertyFields.fill = 'color';
  slice.propertyFields.fillOpacity = 'opacity';
  slice.propertyFields.stroke = 'color';
  slice.propertyFields.strokeDasharray = 'strokeDasharray';
  slice.propertyFields.tooltipText = 'tooltip';

  series.labels.template.propertyFields.disabled = 'disabled';
  series.ticks.template.propertyFields.disabled = 'disabled';

  // Dispose Chart
  am4core.options.autoDispose = true;
};

const makeSummaryPieChart = (id = '', severity = '', findingsObj = {}) => {
  // Create chart instance
  let chart = am4core.create(id, am4charts.PieChart);

  if (!findingsObj.totalPotentialFindings) {
    makeEmptyPieChart(chart);
    // show an empty greyed-out chart when there were no potential findings
  } else {
    chart.data = [
      {
        label: 'Findings',
        amount: findingsObj.findings,
        color: am4core.color(riskRankColorByString(severity)),
      },
      {
        label: 'Non-Findings',
        amount: findingsObj.findings == 0 ? findingsObj.totalPotentialFindings : findingsObj.nonFindings,
        // ^ we use this conditional to show an all-white chart if there are no findings, but there were some potential findings
        color: am4core.color('#fff'),
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'amount';
    pieSeries.dataFields.category = 'label';

    // Disable ticks and labels
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Disable tooltips
    pieSeries.slices.template.tooltipText = '{category}: {value.value}';

    // Apply Styling
    pieSeries.slices.template.stroke = am4core.color('#000');
    pieSeries.slices.template.strokeOpacity = 0.2;
    pieSeries.slices.template.propertyFields.fill = 'color';

    // Dispose Chart
    am4core.options.autoDispose = true;
  }
};

const makeSummaryColumnChart = (id = '', findings = {}) => {
  // Create chart instance
  var chart = am4core.create(id, am4charts.XYChart);

  // Add data
  chart.data = [
    {
      severity: 'Low',
      low: findings.low,
    },
    {
      severity: 'Moderate',
      moderate: findings.moderate,
    },
    {
      severity: 'High',
      high: findings.high,
    },
    {
      severity: 'Critical',
      critical: findings.critical,
    },
  ];

  // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'severity';
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.fontSize = '.75em';
  categoryAxis.renderer.minGridDistance = 0;
  categoryAxis.renderer.grid.template.strokeWidth = 0;
  categoryAxis.renderer.labels.template.dy = -5;
  categoryAxis.renderer.labels.template.fill = am4core.color('gray');

  var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.inside = true;
  valueAxis.renderer.labels.template.disabled = true;
  valueAxis.min = 0;
  valueAxis.renderer.grid.template.strokeWidth = 0;

  // Create series
  function createSeries(field, name) {
    // Set up series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.categoryX = 'severity';
    series.sequencedInterpolation = true;

    switch (field) {
      case 'low':
        series.columns.template.fill = am4core.color('#4CAE4C');
        break;
      case 'moderate':
        series.columns.template.fill = am4core.color('#f6be00');
        break;
      case 'high':
        series.columns.template.fill = am4core.color('#cc1818');
        break;
      case 'critical':
        series.columns.template.fill = am4core.color('#cc1818');
        break;
    }

    // Make it stacked
    series.stacked = true;

    // Configure columns
    series.columns.template.width = am4core.percent(90);
    series.columns.template.tooltipText = 'Findings: [bold]{valueY}[/]';

    // Add label - leaving this here in case we want to add it in, although it needs adjustments
    // var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    // labelBullet.label.text = "{valueY}";
    // labelBullet.locationY = 0.5;
    // labelBullet.label.hideOversized = false;
    // labelBullet.scale = 1.2;
    // labelBullet.label.fill = am4core.color("#FFFFFF");

    return series;
  }

  createSeries('low', 'Low');
  createSeries('moderate', 'Moderate');
  createSeries('high', 'High');
  createSeries('critical', 'Critical');

  // Dispose Chart
  am4core.options.autoDispose = true;
};

export { makeSummaryPieChart, makeSummaryColumnChart };
