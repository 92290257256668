import React from 'react';

const Busy = (props) => {
  if (props.isBusy) {
    return (
      <div className="text-center">
        <h2>
          <i className="fas fa-circle-notch fa-spin"></i>
        </h2>
      </div>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

export default Busy;
