import React from 'react';

const Spinner = (props) => {
  const {
    isVisible,
    isBusy,
    className,
    style,
    content,
    children,
    pulse,
    size,
    src,
    height = '100%',
    width = '100%',
  } = props;

  return (
    <React.Fragment>
      {isVisible || isBusy ? (
        <span className={className} style={style}>
          {src ? (
            <img src={src} alt="loading..." height={height} witdth={width} />
          ) : (
            <i className={`fas fa-circle-notch fa-${pulse ? 'pulse' : 'spin'} fa-${size || 1}x`}></i>
          )}
        </span>
      ) : (
        content || children
      )}
    </React.Fragment>
  );
};

export default Spinner;
