import React from 'react';
import { generateColor, generateSymbol } from '../../../constants/postern/riskIconGenerator.js';
import Busy from '../../busy';
import SeverityCircle from '../../postern/reusable/severityCircle';
import SectionContainer from '../../sectionContainer';
import ForeignInfluenceMap from '../foreignInfluenceMap';
import ForeignInfluenceTable from '../foreignInfluenceTable';

/** Functional component for displaying the corporate families portion of Foreign Influence data */
const CorporateFamilies = (props) => {
  //Props
  const { corporateFamilies, corporateFamiliesScore, isLoading, isActive } = props;

  //Render
  return (
    <Busy isBusy={isLoading}>
      <SectionContainer label="Corporate Families Findings" className="row fi-section-container">
        <ForeignInfluenceTable
          tableName="ForeignInfluence-CorporateFamilies"
          headers={['Violation', 'Company Name', 'Relationship', 'Country']}
        >
          {corporateFamilies.length > 0 ? (
            corporateFamilies.map((row, i) => {
              return (
                <tr className="provenance-table-row fi-table-row" key={'CorporateFamiliesTable-inner-' + i}>
                  {/* <th className="row-padding" scope="row"> s </th> */}
                  <th className="row-padding" scope="row">
                    {' '}
                    <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                  </th>
                  <td> {row.name} </td>
                  <td> {row.corporate_family_member} </td>
                  <td className="country-display">{row.country}</td>
                </tr>
              );
            })
          ) : (
            <tr className="provenance-table-row-empty" key={'CorporateFamiliesTable-empty'}>
              <td colSpan={5}>
                <p>No Findings Identified</p>
              </td>
            </tr>
          )}
        </ForeignInfluenceTable>
      </SectionContainer>

      <div className="fi-summary-text">
        {corporateFamiliesScore === 'high' ? (
          <p>
            During our review of the corporate families, some were found in countries of adversarial presence. Further
            investigation concludes that the foreign influence from corporate families is likely significant.
          </p>
        ) : (
          <p>
            During our review of the corporate families, none were found in countries of adversarial presence. Further
            investigation concludes that the foreign influence from corporate families is not likely significant, and
            thus no finding is raised.
          </p>
        )}
      </div>

      <SectionContainer label="Corporate Families" className="fi-section-container fi-map">
        <ForeignInfluenceMap corporateFamilies={corporateFamilies} displayIfEmpty={!isLoading && isActive} />
      </SectionContainer>
    </Busy>
  );
};

export default CorporateFamilies;
