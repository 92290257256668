import React, { useMemo } from 'react';
import { RiLock2Line } from 'react-icons/ri';
import { withPrincipal } from '../';
import ClaimPageButton from '../claimPageButton';
import FileGroupDownload from '../fileGroupDownload';
import * as TrustCenterAction from './trustCenter/actions';
import AddModifyModal from './trustCenter/addModifyModal';
import ShareFileModal from './trustCenter/shareFileModal';
import { useTrustCenterStore } from './trustCenter/context';
import FileAccessRequestManagerModal from './trustCenter/fileAccessRequestManager';
import FileHistoryLogModal from './trustCenter/fileHistoryLogModal';
import RequestFileAccessModal from './trustCenter/requestFileAccessModal';
import config from '../../config';
import Busy from '../busy';
import { toastError } from '../../lib/utils';
import InfoBox from '../layout/infoBox';

import './securityAttestation.css';

const SecurityAttestation = (props) => {
  //Context
  const { store, dispatch } = useTrustCenterStore();
  const { fileCategories, fileItemsByCategories } = store;

  const isLoaded = useMemo(() => {
    return fileCategories && fileItemsByCategories;
  }, [fileCategories, fileItemsByCategories]);

  //Props
  const { isPageClaimed, canModify, vendorID, vendorName, vendorDDRRid, principal, setReloadFilesFlag } = props;
  const canRequestAccess = props.principal.roles.some((r) =>
    r.permissions.some((p) => p === 'trustCenter.fileItems.requestAccess'),
  );
  const canShare =
    canModify && props.principal.roles.some((r) => r.permissions.some((p) => p === 'trustCenter.fileItems.share'));

  const canEdit =
    canModify && props.principal.roles.some((r) => r.permissions.some((p) => p === 'trustCenter.fileItems.edit'));

  const canCreate =
    canModify && props.principal.roles.some((r) => r.permissions.some((p) => p === 'trustCenter.fileItems.create'));

  //Functions
  const openPendingRequestsModal = (file_category_id, id) => {
    dispatch(TrustCenterAction.togglePendingRequestsModal(file_category_id, id));
  };

  const openRequestAccessModal = (file_category_id, id) => {
    if (!canRequestAccess) {
      handleRequestAccessError();
    } else {
      dispatch(TrustCenterAction.toggleRequestAccessModal(file_category_id, id));
    }
  };

  const openHistoryLogModal = (id) => {
    dispatch(TrustCenterAction.toggleHistoryLogModal(id));
  };

  const openAddModifyModal = (file_category_id, id) => {
    dispatch(TrustCenterAction.toggleAddModifyModal(file_category_id, id));
  };

  const openShareFileModal = (id) => {
    dispatch(TrustCenterAction.toggleShareFileModal(id));
  };

  const openUploadRequestModal = (file_category_id, id) => {
    dispatch(TrustCenterAction.toggleUploadRequestModal(file_category_id, id));
  };

  const handleFileDownload = (fileData) => {
    const { id } = fileData;
    const downloadUrl = config.api.urlFor('fileItemDownload', {
      fileID: id,
    });
    window.open(downloadUrl, '_top');
  };

  const handleRequestAccessError = () => {
    const toastOptions = {
      toastId: 'error-trust-center-file-item-access-request',
      autoClose: 7000,
    };

    toastError(
      'Your current user role does not have access to this functionality. Please contact your administrator.',
      toastOptions,
    );
  };

  //Render
  return (
    <div className="trust-center-doc-section">
      <div className="sa-container">
        <Busy isBusy={isPageClaimed === undefined}>
          {isPageClaimed ? (
            <Busy isBusy={!isLoaded}>
              {canEdit && (
                <InfoBox>
                  <p>
                    <strong>How to use?</strong>
                    <br />
                    Click in the box in the bottom left of each card where the text shows 0 P / 0 A / 0 D which
                    correspond to pending, accepted and denied requests.
                    <br />
                    After clicking, there will be a popup window where you can drag-and-drop request cards from pending
                    to either approved or denied columns.
                  </p>
                </InfoBox>
              )}
              {fileCategories &&
                Object.keys(fileCategories).map((categoryID) => {
                  const groupName = fileCategories[categoryID];
                  const file_category_id = categoryID;
                  const fileList = (fileItemsByCategories && fileItemsByCategories[categoryID]) || [];

                  return (
                    <React.Fragment key={groupName}>
                      <FileGroupDownload
                        canEdit={canEdit}
                        canCreate={canCreate}
                        canShare={canShare}
                        canRequestAccess={canRequestAccess}
                        groupName={groupName + ':'}
                        filesList={[...fileList]}
                        onDownloadFileClick={(fileData) => handleFileDownload(fileData)}
                        onPendingRequestsClick={(id) => openPendingRequestsModal(file_category_id, id)}
                        onRequestAccessClick={(id) => openRequestAccessModal(file_category_id, id)}
                        onDownloadsClickHandler={(id) => openHistoryLogModal(id)}
                        onModifyClickHandler={(id) => openAddModifyModal(file_category_id, id)}
                        onPlusSignClickHandler={() => openAddModifyModal(file_category_id)}
                        onShareFileClick={(id) => openShareFileModal(id)}
                        onUploadRequestClick={(id) => openUploadRequestModal(file_category_id, id)}
                      />
                    </React.Fragment>
                  );
                })}
              <FileAccessRequestManagerModal />
              <FileHistoryLogModal />
              <AddModifyModal vendorID={vendorID} onDownloadFileClick={(fileData) => handleFileDownload(fileData)} />
              <RequestFileAccessModal setReloadFilesFlag={setReloadFilesFlag} />
              {canShare && <ShareFileModal setReloadFilesFlag={setReloadFilesFlag} />}
            </Busy>
          ) : (
            <>
              <div className="sa-section-locked">
                <h5 className="sa-section-locked-text">
                  <span className="sa-section-locked-icon">
                    <RiLock2Line />
                  </span>
                  This profile has not been claimed.
                  <br />
                  If this is your company, please <b>Claim your Page</b> to activate the trust center for file sharing.
                </h5>
              </div>
              {principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.claimVendorPage')) && (
                <div className="sa-claim-page-button">
                  <ClaimPageButton vendorID={vendorID} vendorName={vendorName} vendorDDRRid={vendorDDRRid} />
                </div>
              )}
            </>
          )}
        </Busy>
      </div>
    </div>
  );
};

export default withPrincipal(SecurityAttestation);
