import React from 'react';
import { useParams } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { useSignalEffect, useUpdateState, Busy } from '../../components';
import { uuidIsValid } from '../../lib/utils';
import SetPasswordForm from './setPasswordForm';
import config from '../../config';

const GlobalStyle = createGlobalStyle`
  body{
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), no-repeat center/100% url('/static/images/login-page-background-1-08_2021.jpg');
  }
`;

export const RegisterGuest = () => {
  const [state, setState] = useUpdateState({ isInvited: true, isBusy: true, isInvalidLink: false, isGuest: true });
  const { isInvited, isBusy, isInvalidLink, isGuest } = state;

  const { id, vendorID } = useParams();

  useSignalEffect(
    async (signal) => {
      if (!id || !uuidIsValid(id)) {
        return setState({ isInvited: false, isInvalidLink: true });
      }

      const response = await fetch(config.api.urlFor('verifyInviteToken'), {
        signal,
        method: 'POST',
        body: { id },
      });

      const result = await response.json();

      setState({ isInvited: result.isValid && result.isGuest, isGuest: result.isGuest, isBusy: false });
    },
    [id],
  );

  return (
    <div className="container">
      <GlobalStyle />
      <div className="row">
        <div className="col-4 offset-4 text-center">
          <img alt="A2V Logo" className="a2v-logo-heading" src="/static/images/A2V_logo_negative.png" />
        </div>
      </div>
      {isInvited ? (
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card">
              <div className="card-header">
                <h5 className="text-center">Setup Credentials</h5>
              </div>
              <div className="card-body">
                <Busy isBusy={isBusy}>
                  <SetPasswordForm config={config} id={id} vendorID={vendorID} isInvited={isInvited} isGuest={true} />
                </Busy>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="card card-login mx-auto mt-1">
            <div className="card-header text-center" style={{ color: 'red' }}>
              Invalid Invitation
            </div>
            <div className="card-body">
              <div className="text-center mb-1">
                <p>
                  The registration link {isInvalidLink || !isGuest ? 'is invalid' : 'has expired'}.
                  <br /> Please reach out to your admin to request a new registration link.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterGuest;
