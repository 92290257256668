import React, { Component } from 'react';

import TableCeption from './tableCeption';

import './section.css';

export const Section = ({ children, sectionID, sectionName, sectionNumber, ddrrHeaderColor }) => {
  return (
    <div className="postern-section">
      <TableCeption>
        <span id={sectionID} style={ddrrHeaderColor ? { background: '#004d70' } : {}} className="p-section-header">
          {sectionNumber ? sectionNumber + '.' : ''} {sectionName}
        </span>
        {children}
      </TableCeption>
    </div>
  );
};

export default Section;
