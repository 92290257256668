import { assessmentTypes } from "../../constants/assessmentTypes";

export const vendorAssessmentEndpointHandler = ({ reportTypeID = '', reportRawType = '', vendorID = '' }) => {
  let reportURL = '/reports/postern/';
  switch (true) {
    case reportTypeID == assessmentTypes.CMRE.id || reportRawType == assessmentTypes.CMRE.name:
      reportURL += `provenance?vendorID=${vendorID}`;
      break;

    case reportTypeID == assessmentTypes.DDRR.id || reportRawType == assessmentTypes.DDRR.name:
      reportURL += `ddrr?vendorID=${vendorID}`;
      break;

    default:
      break;
  }

  return reportURL;
};
