import React from 'react';
import LabelWithUrl from './labelWithUrl';
import { IoMdFlag } from 'react-icons/io';

const SectionDetailsWithFlags = (props) => {
  const { title, data, isLoading = true } = props;
  const isDataEmpty = !data || (data && Object.keys(data).length == 0);

  return (
    <div className="details-with-flags-section-container">
      {isLoading ? (
        <div className="section-inner-title animated-ellipsis red-findings">Currently Processing</div>
      ) : (
        <>
          <div className="ml-2 pb-2">
            <b>
              <u>{title}</u>
            </b>
          </div>

          {isDataEmpty ? (
            <h2 className="text-center mt-3">No findings identified</h2>
          ) : (
            Object.keys(data).map((item, index) => {
              return (
                <div key={index} className="row ml-2 mt-2">
                  <div className="col-3 text-primary">
                    <IoMdFlag className="text-warning mx-2" />
                    <b>{item}</b>
                  </div>
                  <div className="col-9">
                    {'Possible relationship with '}
                    {data[item].length &&
                      data[item]
                        .map((innerItem, index) => {
                          return <LabelWithUrl text={innerItem.bannedCompany} url={innerItem.evidence} key={index} />;
                        })
                        .reduce((prev, curr) => [prev, ', ', curr])}
                    {'.'}
                  </div>
                </div>
              );
            })
          )}
        </>
      )}
    </div>
  );
};

export default SectionDetailsWithFlags;
