import React from 'react';
import config from '../../../config';
import { toastError } from '../../../lib/utils';
import SectionContainer from '../../sectionContainer';
import withPrincipal from '../../withPrincipal';
import InfoHygiene from '../infoHygiene';
import SecurityAttestation from '../securityAttestation';
import ProductManagement from '../productManagement/productManagement';
import * as TrustCenterAction from './actions';
import { useTrustCenterStore } from './context';
import FileAccessRequestTable from './fileAccessRequestTable';
import ProductModalStagesContainer from '../../product/productModalStagesContainer';

/** Functional component for displaying Trust Center data */
const TrustCenterContent = (props) => {
  //Context
  const { dispatch } = useTrustCenterStore();

  //Refs
  const loadRef = React.useRef(null);

  //Props
  const {
    isLoadingVendorRisk = true,
    vendorData,
    ddrrData,
    canModify,
    canViewFileAccessRequests,
    principal,
    showOnlyTrustCenterContent,
  } = props;

  const { roles, features } = principal;
  const { isPageClaimed } = vendorData;
  const showAccessRequestTable = canViewFileAccessRequests && canModify;
  const canViewFileItems = roles.some((r) => r.permissions.some((p) => p === 'trustCenter.fileItems.list'));
  const canManageAccess =
    canModify && roles.some((r) => r.permissions.some((p) => p === 'trustCenter.fileItems.manageAccess'));
  const canPurchaseReports = roles.some((r) => r.permissions.some((p) => p === 'subscriber.purchaseReports'));
  const canDownloadOwnSBOMs = roles.some((r) => r.permissions.some((p) => p === 'downloadOwnSBOMsForFree'));
  const hasSBOMfeature = features.indexOf('SBOM') > -1;
  const canManageProducts = canModify && roles.some((r) => r.permissions.some((p) => p === 'manageProducts'));

  //State
  const [reloadFilesFlag, setReloadFilesFlag] = React.useState(false);
  const [productModalStage, setProductModalStage] = React.useState('');

  //Effect
  React.useEffect(() => {
    return () => {
      if (loadRef.current) {
        clearInterval(loadRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (!vendorData.id) {
      return;
    }

    // Load file categories and file classes data
    const categoriesFetch = fetch(config.api.urlFor('fileItemsCategories')).then((res) => res.json());
    const classesFetch = fetch(config.api.urlFor('fileItemsClasses') + `?vendorID=${vendorData.id}`).then((res) =>
      res.json(),
    );

    Promise.all([categoriesFetch, classesFetch])
      .then((response) => {
        // Check if both queries returned valid results
        if (response.findIndex((e) => !e.isSuccess) >= 0) {
          toastError('Initial data not fully loaded.');
          dispatch(TrustCenterAction.initFileCategoriesAndClasses());
          return;
        }

        const [{ data: fileCategoriesArray }, { data: fileClasses }] = response;
        const fileCategories = {};
        const fileClassesByCategories = {};

        fileCategoriesArray.forEach((category) => {
          fileCategories[category.id] = category.name;
          fileClassesByCategories[category.id] = [];
        });

        // Add the file classes to their corresponding categories or to all of them, if they do not belong to only one.
        fileClasses.forEach((classItem) => {
          if (classItem.fileCategoryID) {
            fileClassesByCategories[classItem.fileCategoryID].push(classItem);
          } else {
            fileCategoriesArray.forEach((category) => {
              fileClassesByCategories[category.id].push(classItem);
            });
          }
        });

        dispatch(TrustCenterAction.initFileCategoriesAndClasses(fileCategories, fileClassesByCategories));
      })
      .catch(() => {
        dispatch(TrustCenterAction.initFileCategoriesAndClasses());
        toastError('Error loading initial data.');
      });
  }, [vendorData.id, canModify]);

  React.useEffect(() => {
    loadFileRequests();
  }, [vendorData.id, isPageClaimed]);

  React.useEffect(() => {
    if (!canViewFileItems) {
      ErrorLoadingData('Your user does not have permission to view file items. Contact your administrator.');
      return;
    }

    if (vendorData.id && isPageClaimed) {
      if (loadRef.current) {
        clearInterval(loadRef.current);
      }

      loadRef.current = setInterval(intervalLoad, 30 * 1000); // Reload every 30 seconds to check for scan updates / new files / etc.
      setReloadFilesFlag(false); // Clear the reload files flag
    }

    loadFiles();
  }, [vendorData.id, isPageClaimed, reloadFilesFlag]);

  const intervalLoad = () => {
    loadFiles();
    loadFileRequests();
  };

  const loadFiles = () => {
    const vendorID = vendorData.id;

    if (vendorID && isPageClaimed) {
      //Load data based off vendor ID
      fetch(config.api.urlFor('fileItemsByVendor', { vendorID }))
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, message = 'Error loading file items data.', data = {} } = response;

          if (!isSuccess) {
            ErrorLoadingData(message);
            return;
          }

          const { rows } = data;
          const fileItemsByCategories = {};

          for (let row of rows) {
            const { fileCategoryID } = row;

            if (!fileCategoryID) {
              continue;
            }

            if (!fileItemsByCategories[fileCategoryID]) {
              fileItemsByCategories[fileCategoryID] = [];
            }

            fileItemsByCategories[fileCategoryID].push(row);
          }

          dispatch(TrustCenterAction.setData(fileItemsByCategories));
        })
        .catch(() => {
          dispatch(TrustCenterAction.setData({}));
          ErrorLoadingData('Failed to load file items data.');
        });
    }
  };

  const loadFileRequests = () => {
    const vendorID = vendorData.id;

    if (vendorID && isPageClaimed && canModify) {
      fetch(config.api.urlFor('fileItemAccessRequestsByVendorID', { vendorID }))
        .then((result) => result.json())
        .then((response) => {
          const { isSuccess, message = 'Error loading file items access requests.', data } = response;

          if (isSuccess) {
            const accessRequests = (!data || (data && data.error) ? [] : data).map((x, idx) =>
              Object.assign(x, { access_request_num: idx }),
            );
            dispatch(TrustCenterAction.setAccessRequests(accessRequests));
          } else {
            ErrorLoadingData(message);
          }
        })
        .catch(() => {
          dispatch(TrustCenterAction.setAccessRequests([]));
          ErrorLoadingData('Failed to load file items access requests.');
        });
    }
  };

  const ErrorLoadingData = (errorMsg) => {
    toastError(errorMsg, { toastId: 'error-trust-center-load-data' });
  };

  const handleSBOMRequestClick = () => {
    if (canPurchaseReports) {
      setProductModalStage('reports');
    }
  };

  const handleProductModalClose = () => {
    setProductModalStage('');
  };

  //Render
  return (
    <div className="trust-center-content">
      <div id="info-and-scan">
        <InfoHygiene isLoadingVendorRisk={isLoadingVendorRisk} vendorData={vendorData} ddrrData={ddrrData}>
          {showAccessRequestTable ? (
            <SectionContainer label="Trust Center Access Requests" className="row">
              <FileAccessRequestTable canEdit={canManageAccess} canAccessVendorOverview={!showOnlyTrustCenterContent} />
            </SectionContainer>
          ) : (
            <>
              <SectionContainer label="Documents" className="row">
                <SecurityAttestation
                  isPageClaimed={isPageClaimed}
                  canModify={canModify}
                  vendorID={vendorData.id}
                  vendorName={vendorData.name}
                  vendorDDRRid={vendorData.ddrrID}
                  setReloadFilesFlag={setReloadFilesFlag}
                />
              </SectionContainer>
              <ProductManagement
                vendorID={vendorData.id}
                vendorName={vendorData.name}
                canDownloadSBOMs={(canModify && canDownloadOwnSBOMs) || hasSBOMfeature}
                canManageProducts={canManageProducts}
                canAccessProductOverview={!showOnlyTrustCenterContent}
              />
            </>
          )}
          {isPageClaimed && !canModify && (
            <>
              <button className="btn btn-primary" onClick={handleSBOMRequestClick} disabled={!canPurchaseReports}>
                Request SBOM
              </button>
              <ProductModalStagesContainer
                showModal={!!productModalStage}
                modalStage={productModalStage}
                handleModalClose={handleProductModalClose}
              />
            </>
          )}
        </InfoHygiene>
        {showAccessRequestTable ? (
          <>
            <SectionContainer label="Documents" className="row">
              <SecurityAttestation
                isPageClaimed={isPageClaimed}
                canModify={canModify}
                vendorID={vendorData.id}
                vendorName={vendorData.name}
                vendorDDRRid={vendorData.ddrrID}
                setReloadFilesFlag={setReloadFilesFlag}
              />
            </SectionContainer>
            <ProductManagement
              vendorID={vendorData.id}
              vendorName={vendorData.name}
              canDownloadSBOMs={(canModify && canDownloadOwnSBOMs) || hasSBOMfeature}
              canManageProducts={canManageProducts}
              canAccessProductOverview={!showOnlyTrustCenterContent}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default withPrincipal(TrustCenterContent);
