import React, { Component } from 'react';
import parse from 'html-react-parser';

import ReusableDdrrTable from './reusableDdrrTable';
import SeverityPill from './../../../reusable/severityPill';

import './riskObservations.css';

export default class RiskObservations extends Component {
  render() {
    const { category = [], vendor = {}, questionData = [] } = this.props;
    const { totalScoreLabel, totalScore, manualReviewResult } = vendor;

    return (
      <div id="risk-observations">
        <ReusableDdrrTable tableName="DdrrTable-" headers={['Risk Category', 'Risk', 'Score', 'Observations']}>
          {category.map((row, i) => {
            const rowData = row[0] || {};
            const { riskCategory = '', riskscoretext = '', riskScore = '', riskdescription = '' } = rowData;
            return (
              <React.Fragment key={'DdrrTable-' + i}>
                <tr className="ddrr-table-row">
                  <td> {riskCategory === 'Application/Hardware' ? 'System Access' : riskCategory} </td>
                  <td className="horiz-centered-cell">
                    {' '}
                    <SeverityPill severity={riskscoretext} />{' '}
                  </td>
                  <td className="horiz-centered-cell"> {riskScore} </td>
                  <td>
                    {riskdescription}
                    <ul>
                      {questionData.map((question, index) => {
                        const arr = [4, 10, 42, 44];
                        if (question.categoryName === riskCategory && !arr.includes(question.methodID)) {
                          return <li key={riskCategory + i + index}>{parse(question.methodExternal)}</li>;
                        }
                      })}
                    </ul>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          {
            <tr>
              <td className="bold-footer">Total Risk</td>
              <td className="bold-footer horiz-centered-cell ">
                {' '}
                <SeverityPill severity={totalScoreLabel} />{' '}
              </td>
              <td className="bold-footer">{totalScore}</td>
              <td>{manualReviewResult}</td>
            </tr>
          }
        </ReusableDdrrTable>
      </div>
    );
  }
}
