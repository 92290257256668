import React from 'react';
import '../../routes/vendor/catalogVendor.css';
import './scoreColors.css';
import { severityByScore } from '../../constants/string';

const SeverityLabel = (props) => {
  const { smaller } = props;
  let label = props.label;

  const base = {
    color: '#fff',
    fontSize: smaller ? '0.7rem' : '1rem',
    minWidth: smaller ? '50px' : '100px',
    padding: '0.25em 1em',
    marginLeft: smaller ? '0.5em' : '1em',
  };

  // If the label is a number, convert it to a grade
  if (!isNaN(label)) {
    label = severityByScore(label);
  }

  if (props.detailedFindingsScan) {
    switch (String(label).toLowerCase()) {
      case 'complete':
        return (
          <span className="badge sl-complete" style={{ ...base }}>
            Complete
          </span>
        );
      case 'scanning':
        return (
          <span className="badge sl-in-prog" style={{ ...base }}>
            In Progress
          </span>
        );
      default:
        return (
          <span className="badge sl-pending" style={{ ...base }}>
            Pending
          </span>
        );
    }
  } else {
    switch (String(label).toLowerCase()) {
      case 'in-prog':
        return (
          <span className="badge" style={{ ...base }}>
            In Progress
          </span>
        );
      case 'critical':
        return (
          <span className="badge sl-critical" style={{ ...base }}>
            Critical
          </span>
        );
      case 'high':
        return (
          <span className="badge sl-high" style={{ ...base }}>
            High
          </span>
        );
      case 'moderate':
        return (
          <span className="badge sl-moderate" style={{ ...base }}>
            Moderate
          </span>
        );
      case 'low':
        return (
          <span className="badge sl-low" style={{ ...base }}>
            Low
          </span>
        );
      case 'informational':
        return (
          <span className="badge sl-informational" style={{ ...base }}>
            Informational
          </span>
        );
      default:
        return (
          <span className="badge sl-na" style={{ ...base }}>
            -
          </span>
        );
    }
  }
};

export default SeverityLabel;
