import React from 'react';

const privacyPolicy = () => (
  <React.Fragment>
    <div className="row justify-content-center mb-lg-2">
      <div className="col-8">
        <h5>
          <h3 className="pb-5">Privacy Policy</h3>

          <p>
            <b className="pl-5">This Privacy Policy was last updated on August 5, 2021.</b>
          </p>
          <p>
            Fortress Information Security LLC and its subsidiaries, divisions, and affiliates (collectively, “we,” “us,”
            or “Fortress”) wants you to be familiar with how we collect, use and disclose the information you provide by
            utilizing our website, as well as any other websites, applications, products, or services that include this
            Privacy Policy or a link to this Privacy Policy (collectively, the “Services”).
          </p>
          <p>
            Your use of this website, as well as the Services, are governed by this Privacy Policy, regardless of how
            you access them (including but not limited to through the Internet, through a mobile network, or in any
            other manner). By using the Services, you are agreeing to the terms in this Privacy Policy, both on your
            behalf, and on behalf of the company, government agency, or juridical entity that you are affiliated or
            associated with, and you represent and warrant that you have the authority to bind such entity to this
            Privacy Policy- if you do not have such authority, you are not allowed to utilize the Services. If you do
            not agree to the Privacy Policy, then you are not allowed to use the Services.
          </p>
          <p>
            This Privacy Policy is written in the English language. We do not guarantee the accuracy of any translated
            versions. To the extent any translated versions conflict with the English language version, the English
            language version shall control.
          </p>
          <p>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. BY USING THE
            SERVICES, YOU ACKNOWLEDGE YOU HAVE READ AND UNDERSTAND THE PRIVACY POLICY AND THAT WE WILL COLLECT, USE AND
            SHARE YOUR INFORMATION AS SET FORTH BELOW.
          </p>

          <p>
            <b className="pl-5">Collection of Personal Information</b>
          </p>
          <p>
            Fortress will collect personal information from you directly when you apply to use any of the Services,
            interact with our Services, contact us for any reason, use our Services, create an account, or retain our
            Services.
          </p>
          <p>
            We may receive personal information about you from information provided to us by our customers, as well as
            third-party affiliates or partners and from marketing companies that provide us with such information as a
            part of their relationship with us.
          </p>
          <p>
            We may combine this with information that we already have collected about you. Such collected information
            could include contact details (such as email address) and previous purchase history or interests.
          </p>
          <p>
            When you use our Services, we collect certain information about you automatically through our use of cookies
            (more information about our use of cookies follows later in this Privacy Policy) and similar technologies
            such as standard internet log information and usage information, including your IP address, browser type and
            language, access times, location, usage data, and referring website addresses.
          </p>
          <p>
            We may collect the following categories of personal information: (1) contact information including your
            name, email address, street address, city, state, zip code; (2) authentication information, including the
            user name and password that you use to register an account; and (3) IP address or mobile network
            information.
          </p>
          <p>
            We take commercially reasonable measure to ensure we collect and process the minimum amount of personal
            information from you that is necessary to conduct our business, provide you with web-based and mobile
            applications, communicate with you, customer support, user verification, provide you with information on our
            services and content, website maintenance and improvements, and comply with legal requirements.
          </p>

          <p>
            <b className="pl-5">Use and Disclosure of Information</b>
          </p>
          <p>
            Fortress may store and use your personally identifiable information for (i) product or Services fulfillment,
            (ii) voluntary marketing, promotional, and advertising purposes, such as to inform you of products or
            services available from us, (iii) internal operations, such as improving your customer experience – this
            includes auditing current interactions and optimizing user experience, (iv) detecting security incidents,
            protecting against malicious, deceptive activity, and taking all necessary and appropriate steps to mitigate
            current and future risk; (v) debugging and repairing internal information technology as necessary; (vi)
            undertaking internal research for technological development and demonstration; and (vii) to share with
            trusted third party service providers to help us perform activities to improve your customer experience.
          </p>
          <p>
            Third parties are prohibited from using your personally identifiable information beyond providing services
            to us and are required to maintain the information’s confidentiality and privacy. Fortress does not trade,
            rent, or sell your personal information to third parties.
          </p>
          <p>We may also share or disclose your personal information for the following limited purposes: </p>
          <ul>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              With third party service providers who perform services and functions on our behalf to support our
              interactions with you. These service providers may assist with technical operation of our Services,
              provide analytics, process orders, transactions and payments, or provide customer service. We may also
              share non-identifying information, such as aggregate statistics or usage information, with third parties.{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              As required by applicable law, statute, rule, regulation or professional standard, or through subpoena,
              search warrant or other legal process.{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>For regulatory compliance purposes. </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              When explicitly requested or consented to by you.{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              When required to deliver publications or reference materials requested by you.{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              For administrative or maintenance purposes related to the Services.{' '}
            </li>
          </ul>
          <p>
            Unless otherwise required by law, the personal information we collect will only be retained for so long as
            the information is needed for our professional, marketing, or analytic purposes or to comply with your
            request, or until you ask for the information be deleted.{' '}
          </p>
          <p>
            As with any other business, Fortress may merge with or be purchased by another company. If Fortress is
            acquired, the company that acquires us would have access to the information maintained by us, including
            personally identifiable information, but would continue to be bound by this Privacy Policy unless and until
            it is amended.
          </p>
          <p>
            <b className="pl-5">Children’s Privacy: No Users Under Eighteen Years of Age</b>
          </p>
          <p>
            Our Services are not targeted for use by children. We will never knowingly request or collect personal
            information from any child. If you are under eighteen years old, please do not provide your information
            through our Service. Upon notification that a child has provided us with personally identifiable
            information, we will delete the child’s personally identifiable information from our records. If you believe
            we might have any information from a child, please contact us at Compliance@FortressInfoSec.com.{' '}
          </p>
          <p>
            In addition, if you are a California resident under the age of eighteen, while we do not allow users under
            the age of eighteen and you should notify us so we may delete your information, specifically, you may
            request us to remove content or information posted on our websites or stored on our servers by (a)
            submitting a request in writing to Compliance@FortressInfoSec.com,and (b) clearly identifying the content or
            information you wish to have removed and providing sufficient information to allow us to locate the content
            or information to be removed. However, please note that we are not required to erase or otherwise eliminate
            content or information if (i) other state or federal laws require us or a third party to maintain the
            content or information; (ii) the content or information was posted, stored, or republished by another user;
            (iii) the content or information is anonymized so that the minor cannot be individually identified; (iv) the
            minor does not follow the instructions posted herein on how to request removal of such content or
            information; or (v) the minor has received compensation or other consideration for providing the content.
            Further, nothing in this provision shall be construed to limit the authority of a law enforcement agency to
            obtain such content or information.
          </p>
          <p>
            <b className="pl-5">Security</b>
          </p>
          <p>
            The security and confidentiality of your personal information is important to us. We follow reasonable
            commercial standards for organizational, technical, and administrative measures to protect the personal
            information submitted to us, both during transmission and once it is received.{' '}
          </p>
          <p>
            Please be advised, however, that while we take reasonable security measures to protect your personal
            information, such measures cannot be guaranteed to be secure. We cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly
            collect, access, steal, or modify your personal information. The security of your account relies on your
            protection of your user profile information.{' '}
          </p>
          <p>
            You are responsible for maintaining the security of your user profile information, including your password
            and for all activities that occur under your account. You may not share your password with anyone.{' '}
          </p>
          <p>
            We will never ask you to send your password or other sensitive information to us in an email, though we may
            ask you to enter this type of information to login to your account. Any email or other communication
            purporting to be from one of our websites requesting your password or asking you to provide sensitive
            account information via email, should be treated as unauthorized and suspicious and should be reported to us
            immediately. If you believe someone else has obtained access to your password, please change it immediately.
          </p>
          <p>
            If you believe any personal information you have submitted to us is unsecure or that someone has accessed
            your account, please notify us immediately at Compliance@FortressInfoSec.com.
          </p>
          <p>
            <b className="pl-5">Unsubscribe </b>
          </p>
          <p>
            If you no longer wish to receive marketing emails from us, you may opt-out of receiving such emails by
            following the unsubscribe instructions contained in any such email or by contacting us at
            Compliance@FortressInfoSec.com with the subject “unsubscribe.” Please note we still may need to send you
            transactional emails associated with the operation of the Services.
          </p>
          <p>
            <b className="pl-5">Request to Access, Know, and Delete</b>
          </p>
          <p>
            You may obtain certain information and access upon request, and you may also request deletion of certain
            information (any such request is hereinafter defined as a “Consumer Request”). This Privacy Policy outlines
            how you can request the information and what you can receive.
          </p>
          <p>
            If you would like to submit a Consumer Request, you can contact Fortress at Compliance@FortressInfoSec.com,
            write to us at 1 S. Orange Avenue, Suite 306, ATTN: COMPLIANCE, Orlando, Florida 32801.
          </p>
          <p>
            If you choose to submit a Consumer Request, you must provide us with enough information to identify you and
            enough specificity on the requested data. We will only use the information we receive to respond to your
            request and for compliance and legal purposes. Fortress will not be able to disclose information if it
            cannot verify that the person making the Consumer Request is the person about whom we collected information,
            or someone authorized to act on such person’s behalf.{' '}
          </p>
          <p>
            “Personal information” means information that identifies, relates to, describes, is capable of being
            associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or
            household, or as otherwise defined by applicable law.
          </p>
          <p>
            <b>Request to Access.</b> You may submit a Consumer Request to obtain a copy of or access to the personal
            information that Fortress has collected on you.{' '}
          </p>
          <p>
            <b>Request to Know.</b> You may submit a Consumer Request to receive information about Fortress’s data
            collection practices. You may request information on the categories of personal information (as defined by
            law) Fortress has collected about you; the categories of data collection sources; Fortress’s business or
            commercial purpose for collecting or selling personal information; the categories of third parties with whom
            Fortress shares personal information, if any; and the specific pieces of personal information we have
            collected about you
          </p>
          <p>
            Please note that the categories of personal information and sources will not exceed what is contained in
            this Privacy Policy. Additionally, Fortress is not required to retain any information about you if it is
            only used for a one-time transaction and would not be maintained in the ordinary course of business.
            Fortress is also not required to reidentify personal information if it is not stored in that manner already,
            nor is it required to provide the personal information to you more than twice in a twelve-month period.
          </p>
          <p>
            <b>Request to Delete.</b> You may request that Fortress delete your personal information. Subject to certain
            exceptions set out below we will, on receipt of a verifiable Consumer Request, delete your personal
            information from our records and direct any service providers to do the same.
          </p>
          <p>Please note that we may not delete your personal information if it is necessary to:</p>
          <ul>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              complete the transaction for which the personal information was collected;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              provide a good or service requested by you, or reasonably anticipated within the context of our ongoing
              business relationship with you, or otherwise perform a contract between you and us;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              detect security incidents, protect against malicious, deceptive activity, and take all necessary and
              appropriate steps to mitigate current and future risk;{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              debug and repair internal information technology as necessary;{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              undertake internal research for technological development and demonstration;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or
              exercise another right provided for by law;{' '}
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              comply with the California Electronic Communications Privacy Act or any other law;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
              that adheres to all other applicable ethics and privacy laws, when our deletion of the information is
              likely to render impossible or seriously impair the achievement of such research, provided we have
              obtained your informed consent;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              enable solely internal uses that are reasonably aligned with your expectations based on your relationship
              with us;
            </li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>comply with an existing legal obligation; or</li>
            <li style={{ marginLeft: '3rem', marginRight: '6rem' }}>
              otherwise use your personal information, internally, in a lawful manner that is compatible with the
              context in which you provided the information.
            </li>
          </ul>
          <p>
            Fortress will not treat you differently because of your Consumer Request activity. As a result of your
            Consumer Request activity, we may not and will not deny goods or services to you; charge different rates for
            goods or services; provide a different level quality of goods or services; or suggest any of the preceding
            will occur. However, we can and may charge you a different rate, or provide a different level of quality, if
            the difference is reasonably related to the value provided by your personal information.
          </p>
          <p>
            <b className="pl-5">Do Not Track Signals & Cookies </b>
          </p>
          <p>Currently, we do not respond to “Do Not Track,” “DNT,” or similar signals. </p>
          <p>
            We may use cookies, web beacons, pixel tags, mobile analytics software, log files, or other technologies to
            collect certain information about your online activity and interactions with our Services which allows us to
            keep track of analytics and certain statistical information that enables us to improve our services and
            provide you with more relevant content and advertising.{' '}
          </p>
          <p>
            Some of the cookies we use are necessary to enable you to move around the Services and use its features. We
            also use functional cookies to record information about the choices you have made and to allow us to tailor
            the Services to our users; for example, to remember your language or region or that you have already
            completed a survey. This information is usually anonymized and is not used for any other purpose. We may
            also use analytic services to help us understand how effective our content is, what interests our users
            have, and to improve how the Service works. In addition, we use web beacons or tracking pixels to count
            visitor numbers and performance cookies to track how many individual users access the Services and how
            often. This information is used for statistical purposes only and it is not our intention to use such
            information to personally identify any user.{' '}
          </p>
          <p>
            Most web browsers are set to accept cookies by default but will allow you to see what cookies you have,
            delete them on an individual basis, or block cookies from particular or all websites (“opting out”). Please
            note that if you choose to remove, reject, or opt-out from cookies, this could affect the availability and
            functionality of the Services.
          </p>
          <p>
            <b className="pl-5"> Special Notice for Nevada Residents</b>
          </p>
          <p>
            Fortress does not sell, rent, or lease your personally identifiable information to third parties. However,
            if you are a resident of Nevada and would like to submit a request not to sell your personally identifiable
            information, you may do so by contacting us by email at Compliance@FortressInfoSec.com or writing to us at 1
            S. Orange Avenue, Suite 306, ATTN: COMPLIANCE, Orlando, Florida 32801 or calling us at 855-FORTRESS.
          </p>
          <p>
            <b className="pl-5"> Third Party Links</b>
          </p>
          <p>
            The Services may link to third party owned or operated websites including, but not limited to social media
            websites, as a convenient method of accessing information that may be useful or of interest to you. This
            Privacy Policy and the practices we follow do not apply to the linked websites. We are not responsible for
            the content, accuracy, or opinions expressed on any linked website or for the privacy practices or security
            standards used by third parties on such linked websites.{' '}
          </p>
          <p>
            <b className="pl-5"> Consent to Processing and International Transfer </b>
          </p>
          <p>
            The Services are controlled and operated by us from the United States. Fortress may process, transfer, and
            store your information on servers located in the United States. As a result, your personal information may
            be subject to data protection and other laws that may differ from your country of residence. Your personal
            information may be disclosed in response to inquiries or requests from government authorities or to respond
            to judicial process in the countries in which we operate. By using the Services, or by providing us with any
            information, you consent to the collection, processing, maintenance, and transfer of such information in and
            to the United States and other applicable territories in which the privacy laws may not be as comprehensive
            as, or equivalent to, those in the country where you reside or are a citizen.
          </p>
          <p>
            <b className="pl-5"> Updates</b>
          </p>
          <p>
            We may update this Privacy Policy at any time by publishing an updated version here. If we make changes in
            the way we collect or use information, we will notify you by posting an announcement on the website or
            sending you an email. You are bound by any changes to our Privacy Policy once you use the Services after
            such changes have been posted. Please review the “Last Updated” legend at the top of this page to see when
            this Privacy Policy was last revised.
          </p>
        </h5>
      </div>
    </div>
  </React.Fragment>
);

export default privacyPolicy;
