import React, { Component } from 'react';
import SeverityCircle from './../../../reusable/severityCircle';
import ReusableProvenanceTable from './reusableProvenanceTable';
import { generateSymbol, generateColor } from '../../../../../constants/postern/riskIconGenerator.js';
import SafeHyperlink from '../../../../safeHyperlink';

export default class MergerAcquisition extends Component {
  render() {
    const { scores = {}, mergerAcquisition = [], template = '' } = this.props;
    const { mergerAcquisition: sMergerAcquisition = '' } = scores;

    let ninetyDayString = template === 'cmrs' ? 'over the previous 90 days' : '';

    return (
      <React.Fragment>
        <div className="postern-section-summary-paragraph">
          {sMergerAcquisition === 'high'
            ? `During our review of the merger and acquisitions, some were found in countries of adversarial presence. Further investigation concludes that the foreign influence from merger and acquisitions is likely significant ${ninetyDayString}.`
            : `During our review of the merger and acquisitions, none were found in countries of adversarial presence.  Further investigation concludes that the foreign influence from merger and acquisitions is not likely significant, and thus no finding is raised ${ninetyDayString}.`}
        </div>
        {mergerAcquisition.length > 0 ? (
          <ReusableProvenanceTable
            tableName="ManufacturingTable-"
            headers={['', 'Investor', 'Target', 'Seller', 'Transaction Date']}
          >
            {mergerAcquisition.map((row, i) => {
              return (
                <React.Fragment key={'ManufacturingTable-' + i}>
                  <tr className="provenance-table-row">
                    <th scope="row">
                      {' '}
                      <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                    </th>
                    <td>
                      {' '}
                      {row.investor_domain ? (
                        <SafeHyperlink url={'https://' + row.investor_domain}> {row.investor} </SafeHyperlink>
                      ) : (
                        row.investor
                      )}
                      <br />
                      {row.investor_country_name}{' '}
                    </td>
                    <td>
                      {' '}
                      {row.target_domain ? (
                        <SafeHyperlink url={'https://' + row.target_domain}> {row.target} </SafeHyperlink>
                      ) : (
                        row.target
                      )}
                      <br />
                      {row.target_country_name}{' '}
                    </td>
                    <td>
                      {' '}
                      {row.seller_domain ? (
                        <SafeHyperlink url={'https://' + row.seller_domain}> {row.seller} </SafeHyperlink>
                      ) : (
                        row.seller
                      )}
                      <br />
                      {row.seller_country_name}{' '}
                    </td>
                    <td> {row.transaction_date} </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </ReusableProvenanceTable>
        ) : null}

        <footer className="footer-p-wrapper">
          <p>
            The organization’s history of Merger and Acquisition (M&#38;A) activity is scanned through Fortress
            continuous monitoring database for all. Country of all affiliated parties for M&#38;A activity is included.
            All results are aggregated, with specific notes for any high-risk country affiliations.
          </p>
        </footer>
      </React.Fragment>
    );
  }
}
