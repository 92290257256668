import React, { Component } from 'react';

import './tableCeption.css';

class TableCeption extends Component {
  render() {
    const { children } = this.props;
    return (
      <table className="table-ception-table">
        <thead>
          <tr>
            <td>{children[0]}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{children[1]}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }
}

export default TableCeption;
