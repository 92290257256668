import React, { useState } from 'react';

const RequestFileAccess = (props) => {
  const {
    userFirstName,
    userLastName,
    fileName,
    docusignNDAData,
    closeButtonHandler = () => {},
    agreeButtonHandler = () => {},
  } = props;

  const [userHasAgreed, setUserHasAgreed] = useState(false);

  if ((!userFirstName && !userLastName) || !fileName || !docusignNDAData) {
    if (!docusignNDAData) {
      //TBD: Should we just have this auto-trigger the agreeButtonHandler?
      return <div>NDA is currently unavailable. Requests cannot be submitted at this time.</div>;
    } else {
      return <div>Insufficient data</div>;
    }
  }

  return (
    <div>
      <div className="x-close-button-top-right" onClick={closeButtonHandler}>
        x
      </div>
      <p>
        Hi {userFirstName} {userLastName},
      </p>
      <p>{'You have requested access for the ' + fileName + ' file.'}</p>
      <p>
        This company has requested confidentiality of this file. Please review and agree to the NDA below in order to
        view and download this file.
      </p>
      <p>
        Please confirm you have agreed to the
        <span>
          {' '}
          <a href="#/help/termsOfUse" target="_blank" rel="noopener noreferrer">
            terms of use
          </a>{' '}
        </span>
        and you agree to the confidentiality of this file.
        {/* {docusignNDAData.x} */}
      </p>
      <div
        className="pt-2"
        role="button"
        onClick={() => {
          setUserHasAgreed(!userHasAgreed);
        }}
      >
        <input className="modal-report-tick-box" type="checkbox" checked={userHasAgreed} readOnly />
        <span className="pl-2">
          By checking this box, I agree to the terms of use and confidentiality of this file.
        </span>
      </div>
      <div className="sa-modal-btn-container mt-2">
        <div className="sa-modal-btns-right">
          <button className="btn btn-outline-primary btn-nav sa-modal-cancel-btn" onClick={closeButtonHandler}>
            Close
          </button>
          <button className="btn btn-success" disabled={!userHasAgreed} onClick={agreeButtonHandler}>
            Agree
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestFileAccess;
