import React from 'react';
import { EventEmitter } from 'events';
const emitter = new EventEmitter();

const withEvents = (Wrapped) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const newProps = { ...props };

    newProps.events = newProps.events || emitter;

    return React.createElement(Wrapped, newProps);
  };
};

const events = {
  ALERTS_UPDATED: 0,
  DOWNLOAD_FILE: 1,
  IMPORT_UPLOADED: 2,
};

export { withEvents, events };
export default withEvents;
