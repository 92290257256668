import React from 'react';
import SafeHyperlink from './safeHyperlink';

const LabelWithUrl = (props) => {
  const { text, url } = props;

  return (
    <span>
      {text} [<SafeHyperlink url={url}>link</SafeHyperlink>]
    </span>
  );
};

export default LabelWithUrl;
