import ACTION_TYPE from './actions';
import { RequestStatus } from './lib';

/* --------------------------------- */
/*            Reducer                */
/* --------------------------------- */
//Separated this out into its own file as it is prone to become a long switch statement

const trustCenterReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.PEND_REQ_MODAL:
      return pendingRequestsModal(state, action.payload);
    case ACTION_TYPE.REQUEST_ACCESS_MODAL:
      return requestFileAccessModal(state, action.payload);
    case ACTION_TYPE.HISTORY_LOG_MODAL:
      return historyLogModal(state, action.payload);
    case ACTION_TYPE.ADDMODIFY_MODAL:
      return addModifyModal(state, action.payload);
    case ACTION_TYPE.UPLOAD_REQUEST_MODAL:
      return addModifyModal(state, action.payload, true);
    case ACTION_TYPE.ADD_UPDATE_FILE:
      return addupdateFileFromList(state, action.payload);
    case ACTION_TYPE.REMOVE_FILE:
      return removeFileFromList(state, action.payload);
    case ACTION_TYPE.SET_DATA:
      return setDataForCategories(state, action.payload);
    case ACTION_TYPE.SET_ACCESS_REQUESTS:
      return setFileAccessRequests(state, action.payload);
    case ACTION_TYPE.ADJUST_PEND_COUNT:
      return setRequestCount(state, action.payload);
    case ACTION_TYPE.INIT_CATEGORIES_AND_CLASSES:
      return initFileCategoriesAndClassesObjects(state, action.payload);
    case ACTION_TYPE.SHARE_FILE_MODAL:
      return shareFileModal(state, action.payload);
    // case ACTION_TYPE.SET_SCROLL_TO_SECTION_REF:
    //   return setScrollToSectionRef(state, action.payload);
    default:
      return state;
  }
};

/* --------------------------------- */
/*   Action Functions for Reducer    */
/* --------------------------------- */

const pendingRequestsModal = (state, payload) => {
  return {
    ...state,
    pendRequestsFileData: {
      fileID: payload.id,
      fileCategoryID: payload.fileCategoryID,
    },
  };
};

const requestFileAccessModal = (state, payload) => {
  let fileItem = undefined;
  const { fileCategoryID, id } = payload;

  if (id) {
    const { fileItemsByCategories: fileList } = state;
    fileItem = fileList[fileCategoryID].find((file) => file.id === id);
  }
  return {
    ...state,
    requestAccessFileID: id,
    fileItem: fileItem,
  };
};

const historyLogModal = (state, payload) => {
  return {
    ...state,
    historyLogModalFileID: payload.id,
  };
};

const addModifyModal = (state, payload, isUploadRequest = false) => {
  let fileItem = undefined;
  const { fileCategoryID, id } = payload;

  if (id) {
    const { fileItemsByCategories: fileList } = state;
    fileItem = fileList[fileCategoryID].find((file) => file.id === id);
  }

  return {
    ...state,
    addModifyModalFileCatID: fileCategoryID,
    fileItem: fileItem,
    isUploadRequest: fileCategoryID === undefined ? undefined : isUploadRequest,
  };
};

const shareFileModal = (state, payload) => {
  return {
    ...state,
    shareFileModalFileID: payload.id,
  };
};

const addupdateFileFromList = (state, payload) => {
  const { newFile, fileClassData } = payload;
  const { fileCategoryID } = newFile;
  const { fileItemsByCategories: fileList } = state;

  if (!(fileCategoryID in fileList)) {
    fileList[fileCategoryID] = [];
  }
  const idx = fileList[fileCategoryID].map((file) => file.id).indexOf(newFile.id);

  if (idx > -1) {
    fileList[fileCategoryID][idx] = { ...fileList[fileCategoryID][idx], ...newFile };
  } else {
    fileList[fileCategoryID].push(newFile);

    // Update file classes data in the context
    // Currently the file class is not editable so we need to do it only when adding new files
    if (fileClassData && !fileClassData.canMatchMany && fileClassData.canBeUsed) {
      const { fileClassesByCategories } = state;
      const idx2 = fileClassesByCategories[fileCategoryID].findIndex(
        (classData) => classData.fileClassID === fileClassData.fileClassID,
      );

      if (idx2 >= 0) {
        fileClassesByCategories[fileCategoryID][idx2].canBeUsed = false;
      }
    }
  }

  return state;
};

const removeFileFromList = (state, payload) => {
  const { fileCategoryID, id, fileClassData } = payload;
  const { fileItemsByCategories: fileList } = state;
  const idx = fileList[fileCategoryID].map((file) => file.id).indexOf(id);

  if (idx > -1) {
    fileList[fileCategoryID].splice(idx, 1);

    // Update file classes data in the context
    if (fileClassData && !fileClassData.canMatchMany && !fileClassData.canBeUsed) {
      const { fileClassesByCategories } = state;
      const idx2 = fileClassesByCategories[fileCategoryID].findIndex(
        (classData) => classData.fileClassID === fileClassData.fileClassID,
      );

      if (idx2 >= 0) {
        fileClassesByCategories[fileCategoryID][idx2].canBeUsed = true;
      }
    }
  }
  return state;
};

const setDataForCategories = (state, payload) => {
  //const { fileItemsByCategories: oldFileItemsData } = state;
  const { fileItemsByCategories } = payload;
  return {
    ...state,
    fileItemsByCategories: fileItemsByCategories, //{ ...oldFileItemsData, ...fileItemsByCategories },
  };
};

const setFileAccessRequests = (state, payload) => {
  const { fileRequests } = payload;
  return {
    ...state,
    fileRequests,
  };
};

const setRequestCount = (state, payload) => {
  const { oldStatus, newStatus, countAdjustment, fileData } = payload;
  const { fileID, fileCategoryID } = state.pendRequestsFileData || fileData;
  const { fileItemsByCategories: fileList } = state;
  const idx = fileCategoryID in fileList ? fileList[fileCategoryID].map((file) => file.id).indexOf(fileID) : -1;

  const statusToProp = {
    [RequestStatus.PENDING]: 'countPendingRequests',
    [RequestStatus.APPROVED]: 'countApprovedRequests',
    [RequestStatus.DENIED]: 'countDeniedRequests',
  };

  if (idx > -1 && oldStatus in statusToProp && newStatus in statusToProp) {
    fileList[fileCategoryID][idx][statusToProp[oldStatus]] = Math.max(
      fileList[fileCategoryID][idx][statusToProp[oldStatus]] - countAdjustment,
      0,
    );
    fileList[fileCategoryID][idx][statusToProp[newStatus]] += countAdjustment;
  }

  const fileRequests = state.fileRequests;

  if (state.pendRequestsFileData && fileData && fileData.userID && fileData.subscriberID) {
    const { userID, subscriberID } = fileData;

    const requestIdx = fileRequests.findIndex(
      (x) => x.fileID === fileID && x.userID === userID && x.subscriberID === subscriberID,
    );

    if (requestIdx > -1) {
      fileRequests[requestIdx] = Object.assign(fileRequests[requestIdx], { status: newStatus });
    }
  }

  return state;
};

const initFileCategoriesAndClassesObjects = (state, payload) => {
  const { fileCategories, fileClassesByCategories } = payload;
  return { ...state, fileCategories, fileClassesByCategories };
};

export default trustCenterReducer;
