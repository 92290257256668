import React, { Component } from 'react';

import './companyDescription.css';

export default class CompanyDescription extends Component {
  render() {
    const { vendor = {} } = this.props;
    const { companyDescription, revenue, employeeCount } = vendor;

    return (
      <div>
        <div className="p-ddrr-company-description-text">{companyDescription}</div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Revenue</th>
              <th>Employees</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${revenue}</td>
              <td>{employeeCount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
