export const buildDdrrNavOptions = (choice) => {
  // basic "vanilla" options - no map, no drill down
  let navOptions = [
    {
      name: 'Company Summary',
      idToScrollTo: 'vendor-name',
      hasSubOptions: false,
    },
    {
      name: 'Compliance Alerts',
      idToScrollTo: 'compliance-alerts',
      hasSubOptions: false,
    },
    {
      name: 'Risk Observations',
      idToScrollTo: 'risk-observations',
      hasSubOptions: false,
    },
    {
      name: 'Scoring Overview',
      idToScrollTo: 'scoring-overview',
      hasSubOptions: false,
    },
    {
      name: 'DDRR History',
      idToScrollTo: 'ddrr-history',
      hasSubOptions: false,
    },
    {
      name: 'Disclaimer',
      idToScrollTo: 'ddrr-disclaimer',
      hasSubOptions: false,
    },
  ];

  const cyberScannerMapOption = {
    name: 'Cyber Scanner Map',
    idToScrollTo: 'cyber-scanner-map',
    hasSubOptions: false,
  };

  const scoreDrillDownOption = {
    name: 'Score Drill Down',
    idToScrollTo: 'score-drill-down',
    hasSubOptions: false,
  };

  let scoringOverviewIndex = navOptions.findIndex((option) => option.idToScrollTo == 'scoring-overview'); // splice for cyber scanner map here
  let ddrrHistoryIndex = navOptions.findIndex((option) => option.idToScrollTo == 'ddrr-history'); // splice for score drill down here

  const romanNumeralConversions = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
    5: 'V',
    6: 'VI',
    7: 'VII',
    8: 'VIII',
    9: 'IX',
    10: 'X',
  };

  switch (choice) {
    case 'mapAndDrilldown':
      navOptions.splice(scoringOverviewIndex, 0, cyberScannerMapOption);
      ddrrHistoryIndex = navOptions.findIndex((option) => option.idToScrollTo == 'ddrr-history'); // update this index
      navOptions.splice(ddrrHistoryIndex, 0, scoreDrillDownOption);
      break;

    case 'onlyMap':
      navOptions.splice(scoringOverviewIndex, 0, cyberScannerMapOption);
      break;

    case 'onlyDrilldown':
      navOptions.splice(ddrrHistoryIndex, 0, scoreDrillDownOption);
      break;

    default:
      // no additional options
      break;
  }

  // add section numbers
  navOptions.forEach((option, index) => {
    option.sectionNumber = romanNumeralConversions[index + 1];
  });

  return navOptions;
};
