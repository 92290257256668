import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../flex';

const MediaCard = ({ img, imgDescription, imgBottom, title, text, border = true, justify = 'center' }) => {
  return (
    <React.Fragment>
      <div className="card" style={!border ? { border: 'none' } : null}>
        {img ? (
          <Flex justifyContent={justify} alignItems={justify}>
            <div style={{ width: '65%' }}>
              {imgBottom ? null : <img src={img} alt={imgDescription} className="card-img-top" />}
            </div>
          </Flex>
        ) : null}
        <div className="card-body">
          <h4 className={'card-title text-' + justify}>{title}</h4>
          {text &&
            text.map &&
            text.map((line, i) => (
              <p key={i} className={'card-title text-' + justify}>
                {line}
              </p>
            ))}
        </div>
        <Flex justifyContent={justify} alignItems={justify}>
          <div style={{ width: '65%' }}>
            {imgBottom ? <img src={img} alt={imgDescription} className="card-img-top" /> : null}
          </div>
        </Flex>
      </div>
    </React.Fragment>
  );
};

MediaCard.propTypes = {
  imgBottom: PropTypes.bool,
  text: PropTypes.arrayOf(PropTypes.string),
};

export default MediaCard;
