import React, { useState, useEffect } from 'react';
import config from '../../config';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import MediaCard from '../../components/asset/mediaCard';
import CallToAction from '../../components/callToAction';
import withPrincipal from '../../components/withPrincipal';

const BillingHistory = (props) => {
  const {
    principal: { subscription, subscriptionID },
  } = props;
  const [tokenTotal, setTokenTotal] = useState(0);
  const [tokenHistory, setTokenHistory] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);

  const title = subscription || 'Your Organization';

  const updateTable = () => {
    let url1 = config.api.urlFor('tokenTotal', { id: subscriptionID });
    let url2 = config.api.urlFor('accountHistory', { id: subscriptionID });

    let p1 = fetch(url1).then((res) => res.json());
    let p2 = fetch(url2).then((res) => res.json());

    Promise.all([p1, p2]).then((data) => {
      if (data[0].rows[0].tokenTotal) {
        setTokenTotal(data[0].rows[0].tokenTotal);
      }
      if (data[1]) {
        setTokenHistory(data[1].rows || []);
        setTotalRecords(data[1].totalRecords || 0);
      }
    });
  };

  useEffect(updateTable, []);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    updateTable();
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h2>{title}</h2>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3">
            <MediaCard title={tokenTotal} text={['Tokens']}></MediaCard>
          </div>
          <div className="col-3">
            <CallToAction action={'ADD TOKENS'} link={'/'}></CallToAction>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <i className="fas fa-list mr-1"></i>
                Transaction History
              </div>
              <div className="card-body">
                <RemotePagination
                  data={tokenHistory}
                  page={page}
                  sizePerPage={sizePerPage}
                  totalSize={totalRecords}
                  onTableChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => {
  const columns = [
    {
      dataField: 'payer',
      text: 'From',
      sort: true,
    },
    {
      dataField: 'recipient',
      text: 'To',
      sort: true,
    },
    {
      dataField: 'amount',
      text: 'amount',
      sort: true,
    },
    {
      dataField: 'time',
      text: 'time',
    },
  ];

  return (
    <div>
      <BootstrapTable
        remote
        keyField="id"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default withPrincipal(BillingHistory);
