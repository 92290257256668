import React from 'react';

const RangeLegend = (props) => {
  const legendFlagStyle = {
    display: 'inline-block',
    background: props.color,
    height: '15px',
    width: '35px',
    borderRadius: '3px',
  };

  const legendTextStyle = {
    display: 'inline-block',
    marginLeft: '0.5em',
    color: '#828282',
  };

  return (
    <div className="mr-5">
      <div style={legendFlagStyle}></div>
      <p style={legendTextStyle}>{props.legendText}</p>
    </div>
  );
};

export default RangeLegend;
