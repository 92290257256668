import React from 'react';
import { Route } from 'react-router-dom';

import About from './about';
import privacyPolicy from './privacyPolicy';
import termsOfUse from './termsOfUse';
import dcmaNotice from './dcmaNotice';
import releaseNotes from './releaseNotes';
import getStarted from './getStarted';
import Contact from './contact';
import RequestSupport from './requestSupport';
import Login from '../login/login';

class Help extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: false,
    };
  }

  render() {
    const { principal } = this.props;

    return (
      <React.Fragment>
        <Route exact path={`${this.props.match.path}/requestSupport`} component={principal ? RequestSupport : Login} />
        <Route
          path={`${this.props.match.path}/contact`}
          exact
          component={principal ? () => <Contact principal={principal} /> : Login}
        />
        <Route exact path={`${this.props.match.path}/about`} component={principal ? About : Login} />
        <Route exact path={`${this.props.match.path}/getStarted`} component={principal ? getStarted : Login} />
        <Route exact path={`${this.props.match.path}/privacyPolicy`} component={privacyPolicy} />
        <Route exact path={`${this.props.match.path}/termsOfUse`} component={termsOfUse} />
        <Route exact path={`${this.props.match.path}/dmcaNotice`} component={dcmaNotice} />
        <Route exact path={`${this.props.match.path}/releaseNotes`} component={releaseNotes} />
      </React.Fragment>
    );
  }
}

export default Help;
