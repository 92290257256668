import React, { Component } from 'react';
import Modal from 'react-modal';
import config from '../config';
import withPrincipal from './withPrincipal';

const style = {
  modalStyle: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  inputStyle: {
    paddingBottom: '10px',
  },
};

class ProviderButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalConfirmationIsOpen: false,
      user: props.principal,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };
  openConfirmationModal = () => {
    this.setState({ modalConfirmationIsOpen: !this.state.modalConfirmationIsOpen });
  };

  submitRequest = () => {
    fetch(config.api.urlFor('providerRequests'), {
      method: 'POST',
      body: {
        providerName: this.state.providerName,
        providerUrl: this.state.providerWebsite,
        providerContactName: this.state.providerContactName,
        providerContactPhone: this.state.providerContactPhone,
        providerContactEmail: this.state.providerContactEmail,
        userId: this.state.user.id,
        subscriberCompany: this.state.user.associateName,
        subscriberUser: this.state.user.firstName + ' ' + this.state.user.lastName,
        subscriberEmail: this.state.user.emailAddress,
      },
    }).then(() => {
      return;
    });
  };

  render() {
    return (
      <div>
        <button
          data-test-id="ProvderButton-button-1"
          className="btn btn-outline-primary btn-nav float-right"
          onClick={this.openModal}
        >
          Add Provider
        </button>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={style.modalStyle}
          contentLabel="Dispute Input"
        >
          <form data-test-id="ProviderButton-modal-1">
            <div>
              <label data-test-id="ProviderButton-modal-label-1" style={style.inputStyle}>
                Please provide the following information to request that a new Provider be added to your account.
              </label>
            </div>
            <div className="form-group">
              <div className="form-label-group" style={style.inputStyle}>
                <input
                  type="text"
                  id="ProviderButton-providerName"
                  className="form-control"
                  onChange={(e) => this.setState({ providerName: e.target.value })}
                  value={this.state.providerName}
                  placeholder="Enter email address"
                  required="required"
                  autoFocus="autofocus"
                ></input>
                <label htmlFor="ProviderButton-providerName">Provider Name</label>
              </div>
              <div className="form-label-group" style={style.inputStyle}>
                <input
                  type="url"
                  id="ProviderButton-providerWebsite"
                  className="form-control"
                  onChange={(e) => this.setState({ providerWebsite: e.target.value })}
                  value={this.state.providerWebsite}
                  placeholder="Provider Website"
                  required="required"
                ></input>
                <label htmlFor="ProviderButton-providerWebsite">Provider Website</label>
              </div>
              <label style={style.inputStyle}>ProviderContact (if known):</label>
              <div className="form-label-group" style={style.inputStyle}>
                <input
                  type="text"
                  id="ProviderButton-providerContactName"
                  className="form-control"
                  onChange={(e) => this.setState({ providerContactName: e.target.value })}
                  value={this.state.providerContactName}
                  placeholder="Provider Website"
                ></input>
                <label htmlFor="ProviderButton-providerContactName">Contact Name</label>
              </div>

              <div className="form-label-group" style={style.inputStyle}>
                <input
                  type="text"
                  id="ProviderButton-providerContactNumber"
                  className="form-control"
                  onChange={(e) => this.setState({ providerContactPhone: e.target.value })}
                  value={this.state.providerContactPhone}
                  placeholder="Provider Website"
                ></input>
                <label htmlFor="ProviderButton-providerContactNumber">Contact Phone Number</label>
              </div>
              <div className="form-label-group" style={style.inputStyle}>
                <input
                  type="email"
                  id="ProviderButton-providerContactEmail"
                  className="form-control"
                  onChange={(e) => this.setState({ providerContactEmail: e.target.value })}
                  value={this.state.providerContactEmail}
                  placeholder="Provider Website"
                ></input>
                <label htmlFor="ProviderButton-providerContactEmail">Contact Email</label>
              </div>
              <label>Once submitted, the request will be reviewed and you will be notified when it is approved</label>
            </div>
          </form>
          <div style={{ paddingTop: '15px' }}>
            <button
              data-test-id="ProviderButton-button-submit"
              className="btn btn-outline-primary btn-nav float-right"
              onClick={(e) => {
                e.preventDefault();
                this.openModal();
                this.openConfirmationModal();
                this.submitRequest();
              }}
            >
              Submit
            </button>
            <button
              data-test-id="ProviderButton-button-cancel"
              className="btn btn-outline-primary btn-nav float-right"
              style={{ marginRight: '15px' }}
              onClick={this.openModal}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.modalConfirmationIsOpen}
          onAfterOpen={this.afterOpenConfirmationModal}
          onRequestClose={this.closeConfirmationModal}
          style={style.modalStyle}
          contentLabel="Dispute Confirm"
        >
          <label data-test-id="ProviderButton-Confirm-Label">
            Thank you for the information. We will process your request and contact you if any additional details are
            needed.
          </label>
          <div style={{ paddingTop: '15px' }}>
            <button
              data-test-id="ProviderButton-button-close"
              className="btn btn-outline-primary btn-nav float-right"
              style={{ marginRight: '15px' }}
              onClick={this.openConfirmationModal}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withPrincipal(ProviderButton);
