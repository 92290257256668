import React from 'react';

const sortCaret = (order, column) => {
  const focusColor = '#FF0000';
  if (!order)
    return (
      <span>
        <i className="fa fa-caret-down"></i>
        <i className="fa fa-caret-up"></i>
      </span>
    );
  else if (order === 'asc')
    return (
      <span>
        <i className="fa fa-caret-down"></i>
        <i className="fa fa-caret-up" style={{ color: focusColor }}></i>
      </span>
    );
  else if (order === 'desc')
    return (
      <span>
        <i className="fa fa-caret-down" style={{ color: focusColor }}></i>
        <i className="fa fa-caret-up"></i>
      </span>
    );
  return null;
};

export default sortCaret;
