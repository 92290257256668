import React from 'react';
import { CARDGREEN } from '../../constants';
import { CardIcon, Busy } from '.';

const Card = (props) => (
  <div className="col mb-3">
    <div className="card text-white o-hidden h-100" style={{ backgroundColor: CARDGREEN }}>
      <h3 className="card-header text-center">{props.label}</h3>
      <div className="card-body">
        <CardIcon icon={props.icon} />
        <Busy isBusy={props.isBusy}>
          <ul className="list-group list-group-flush">{props.children}</ul>
        </Busy>
      </div>
    </div>
  </div>
);

export default Card;
