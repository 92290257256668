import React from 'react';

import { ColorizedCard, CsvDownloadButton, Findings } from '../../components';
import TargetGroupModal from '../../components/providerTargets/targetGroupModal';

import config from '../../config';

import Chart from 'chart.js';

import { _generateDNSFindings, _generateSSLFindings, _generateAppSecFindings } from '../reports/generateFindings';

class ProviderTargets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusy: true,
      lineChartReady: false,
      model: {},
      map: {},
      scannedAssets: {
        data: [],
      },
      fourthParties: {
        data: [],
      },
      showModal: false,
      targetAnalysis: 'dns',
    };
  }

  componentDidMount() {
    const id = this.props.id || this.props.match.params.id;

    this.doScores(id);
    this.doLineChart(id);
    this.doScanData(id);
    this.doScanDataStats();
  }

  componentDidUpdate(prevProps) {
    const cID = this.props.id || this.props.match.params.id;
    const pID = prevProps.id || prevProps.match.params.id;

    if (cID === pID) {
      return;
    }

    this.doScores(cID);
    this.doLineChart(cID);
    this.doScanData(cID);
  }

  doScores(id) {
    let url = `${config.api.urlFor('scores', { tier: 'provider' })}?id=${id}${
      this.props.location.query.targetGroupId ? `&targetGroupId=${this.props.location.query.targetGroupId}` : ``
    }`;

    fetch(url)
      .then((response) => response.json())
      .then((data = {}) => {
        data = {
          name: data.name || 'Provider',
          description: data.description,
          logo_url: data.logo_url,
          avg_risk_score: data.avg_risk_score || 0,
          avg_dns_score: data.avg_dns_score || 0,
          avg_ssl_score: data.avg_ssl_score || 0,
          avg_web_app_score: data.avg_web_app_score || 0,
          avg_dns_security: data.avg_dns_security || 0,
        };

        this.setState({ model: data, isBusy: false });
      })
      .catch(() => this.setState({ isBusy: false }));
  }

  doLineChart(id) {
    const ctx = document.getElementById('lineChart');
    const chart = {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            fill: 'disabled',
            label: 'Provider',
            backgroundColor: '#155a7d',
            borderColor: '#357abd',
            pointRadius: 5,
            pointBackgroundColor: '#357abd',
            pointBorderColor: 'rgba(255,255,255,0.8)',
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#357abd',
            pointHitRadius: 50,
            pointBorderWidth: 2,
            data: [],
          },
          {
            fill: 'disabled',
            label: 'Global',
            backgroundColor: '#2c5e2c',
            borderColor: '#4cae4c',
            pointRadius: 5,
            pointBackgroundColor: '#4cae4c',
            pointBorderColor: 'rgba(255,255,255,0.8)',
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#4cae4c',
            pointHitRadius: 50,
            pointBorderWidth: 2,
            data: [],
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
              gridLines: {
                color: 'rgba(255, 255, 255, 1)',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
              },
              gridLines: {
                color: 'rgba(255, 255, 255, .5)',
              },
            },
          ],
        },
        legend: {
          display: true,
        },
      },
    };

    const _self = this;

    let url = `${config.api.urlFor('riskOverTime', { tier: 'provider' })}?id=${id}`;

    fetch(url)
      .then((response) => response.json())
      .then((portfolioScores) => {
        fetch(config.api.urlFor('riskOverTime', { tier: 'global' }))
          .then((response) => response.json())
          .then((globalScores) => {
            chart.data.datasets[0].data = portfolioScores.map((row) => ({
              y: row.risk,
              t: new Date(row.date_finished),
            }));
            chart.data.datasets[1].data = globalScores.map((row) => ({ y: row.risk, t: new Date(row.date_finished) }));

            new Chart(ctx, chart);
            _self.setState({ lineChartReady: true });
          })
          .catch((err) => console.log(err.stack));
      })
      .catch((err) => console.log(err.stack));
  }

  doScanData(id) {
    fetch(config.api.urlFor('scanDataStats'))
      .then((res) => res.json())
      .then((stats) => {
        fetch(config.api.urlFor('scanDatafindings', { id }))
          .then((res) => res.json())
          .then((data) => {
            const dnsFindings = _generateDNSFindings(data.dns, stats.dns);
            const sslFindings = _generateSSLFindings(data.ssl, stats.ssl);
            const appSecFindings = _generateAppSecFindings(data.appSec, stats.appSec);

            this.setState({
              dnsFindings,
              sslFindings,
              appSecFindings,
              dnsStats: stats.dns,
              sslStats: stats.ssl,
              appSecStats: stats.appSec,
            });
          });
      });
  }

  doScanDataStats() {
    fetch(config.api.urlFor('scanDataStats'))
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          dnsStats: data.dns,
          sslStats: data.ssl,
          appSecStats: data.appSec,
        });
      });
  }

  toggleTargetGroupsModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const {
      showStacked,
      model = {},
      targetAnalysis,
      dnsFindings = {},
      sslFindings = {},
      appSecFindings = {},
    } = this.state;
    const {
      match: {
        params: { id = '' },
      },
      location,
    } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <h1>
              {model.logo_url != null ? (
                <img src={model.logo_url} alt={model.name} style={{ height: '100px' }} />
              ) : (
                model.name
              )}
              {location.query.targetGroupName ? ` - ${location.query.targetGroupName} ` : ''}
            </h1>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-3">
            <div className="row">
              <div className="col">
                <ColorizedCard
                  label={<h5>Cyber Risk Total:</h5>}
                  icon="fas fa-balance-scale"
                  value={model.avg_risk_score}
                />
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="card">
              <div className="card-header">
                <i className="fas fa-chart-area mr-1"></i>
                Risk History
              </div>
              <div className="card-body text-center align-middle">
                <canvas
                  id="lineChart"
                  height="60"
                  style={{ display: this.state.lineChartReady === true ? 'block' : 'none' }}
                ></canvas>
                {!this.state.lineChartReady && <i className="fas fa-circle-notch fa-spin fa-5x"></i>}
              </div>
              <div className="card-footer small text-muted"></div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <ColorizedCard
              label={<h4>DNS:</h4>}
              icon="fas fa-server"
              value={model.avg_dns_score}
              link={`/reports/score/provider/dns?id=${id}`}
            />
          </div>
          <div className="col">
            <ColorizedCard
              label={<h4>SSL:</h4>}
              icon="fab fa-expeditedssl"
              value={model.avg_ssl_score}
              link={`/reports/score/provider/ssl?id=${id}`}
            />
          </div>
          <div className="col">
            <div className="col">
              <ColorizedCard
                label={<h4>App Security:</h4>}
                icon="fas fa-globe"
                value={model.avg_web_app_score}
                link={`/reports/score/provider/appsec?id=${id}`}
              />
            </div>
          </div>
          <div className="col">
            <ColorizedCard
              label={<h4>Domain Security:</h4>}
              icon="fas fa-lock"
              value={model.avg_dns_security}
              link={`/reports/score/provider/domainsec?id=${id}`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card mb-3">
              <div className="card-header">
                <div className="float-left">
                  <i className="fas fa-list mr-1"></i>
                  Target Analysis
                </div>
                <div className="float-right">
                  <TargetGroupModal
                    toggleTargetGroupsModal={this.toggleTargetGroupsModal}
                    show={this.state.showModal}
                    toggleModal={this.toggleTargetGroupsModal}
                    providerId={this.props.match.params.id}
                  />
                  <CsvDownloadButton
                    url={config.api.urlFor('providersAssetsCsv', { id })}
                    filename={`${(model.name || '').toLowerCase().replace(/ /g, '-')}-products`}
                  />
                </div>
              </div>
              <div className="card-body align-left">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3">
                      {showStacked ? (
                        <button className="btn btn-link" onClick={() => this.setState({ showStacked: false })}>
                          <i className="fas fa-chevron-down mr-2"></i> show less
                        </button>
                      ) : (
                        <button className="btn btn-link" onClick={() => this.setState({ showStacked: true })}>
                          <i className="fas fa-chevron-up mr-2"></i> show all
                        </button>
                      )}
                    </div>
                    {!showStacked && (
                      <ul className="nav nav-tabs mb-3">
                        <li className="nav-item">
                          <button
                            className={`btn btn-link nav-link${targetAnalysis === 'dns' ? ' active' : ''}`}
                            onClick={() => this.setState({ targetAnalysis: 'dns' })}
                          >
                            <h4 className="card-title">DNS</h4>
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className={`btn btn-link nav-link${targetAnalysis === 'ssl' ? ' active' : ''}`}
                            onClick={() => this.setState({ targetAnalysis: 'ssl' })}
                          >
                            <h4 className="card-title">SSL</h4>
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className={`btn btn-link nav-link${targetAnalysis === 'appsec' ? ' active' : ''}`}
                            onClick={() => this.setState({ targetAnalysis: 'appsec' })}
                          >
                            <h4 className="card-title">App Security</h4>
                          </button>
                        </li>
                      </ul>
                    )}
                    {(showStacked || targetAnalysis === 'dns') && (
                      <Findings
                        chartjs={dnsFindings.chartjs}
                        stats={dnsFindings.stats}
                        title={dnsFindings.title}
                        description={dnsFindings.description}
                        targets={dnsFindings.targets}
                        sections={dnsFindings.sections}
                        flatten={showStacked}
                        avg_score={model.avg_dns_score}
                        tagId="1000"
                      />
                    )}
                    {(showStacked || targetAnalysis === 'ssl') && (
                      <Findings
                        chartjs={sslFindings.chartjs}
                        stats={sslFindings.stats}
                        title={sslFindings.title}
                        description={sslFindings.description}
                        targets={sslFindings.targets}
                        sections={sslFindings.sections}
                        flatten={showStacked}
                        avg_score={model.avg_ssl_score}
                        tagId="2000"
                      />
                    )}
                    {(showStacked || targetAnalysis === 'appsec') && (
                      <Findings
                        chartjs={appSecFindings.chartjs}
                        stats={appSecFindings.stats}
                        title={appSecFindings.title}
                        description={appSecFindings.description}
                        targets={appSecFindings.targets}
                        sections={appSecFindings.sections}
                        flatten={showStacked}
                        avg_score={model.avg_web_app_score}
                        tagId="3000"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer small text-muted"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProviderTargets;
