import React from 'react';

class Busy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isBusy, small, smallest, content, children } = this.props;
    const marginTop = !!small || !!smallest ? '' : '20px';
    const faClass = !!smallest ? 'fa-lg' : !!small ? 'fa-3x' : 'fa-5x';

    return (
      <React.Fragment>
        {isBusy ? (
          <div style={{ textAlign: 'center', marginTop, width: '100%' }}>
            <i className={`fas fa-circle-notch fa-spin ${faClass}`}></i>
          </div>
        ) : (
          content || children
        )}
      </React.Fragment>
    );
  }
}

export default Busy;
