import React from 'react';
import BlurryButton from './blurryButton';

class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  changePage(page) {
    return this.props.onPageChange && this.props.onPageChange(page);
  }

  render() {
    let { page, pageSize, totalRecords, totalPages, visible } = this.props;
    page = this.state.page || page;

    page = parseInt(page || 1, 10);
    pageSize = parseInt(pageSize || 10, 10);
    totalRecords = totalRecords || 0;
    totalPages = totalPages || 1;
    visible = visible || 7;
    const halfVisible = Math.floor(visible / 2);

    if (pageSize && totalRecords) {
      totalPages = Math.ceil(totalRecords / pageSize);
    }

    const lowerBound = page - halfVisible > 1 ? page - halfVisible : 1;
    const upperBound = page + halfVisible < totalPages ? page + halfVisible : totalPages;

    const pages = [];
    for (let i = lowerBound; i <= upperBound; i++) {
      pages.push(i);
    }

    if (pages.length < visible) {
      if (page - lowerBound < halfVisible && upperBound - page === halfVisible) {
        let last = pages[pages.length - 1] + 1;

        for (let i = pages.length; i < visible && last <= totalPages; i++) {
          pages.push(last++);
        }
      }

      if (upperBound - page < halfVisible && page - lowerBound === halfVisible) {
        let first = pages[0] - 1;

        for (let i = pages.length; i <= visible && first >= 1; i++) {
          pages.unshift(first--);
        }
      }
    }

    return (
      <>
        {pages.length ? (
          <>
            {pages[0] !== 1 ? (
              <span className="mr-1">
                <BlurryButton className="btn btn-primary mr-1" onClick={() => this.changePage(1)}>
                  {1}
                </BlurryButton>
                &laquo;
              </span>
            ) : (
              ''
            )}
            {page + 1 >= 5 && pages[0] - 5 >= 5 ? (
              <span className="mr-1">
                <BlurryButton className="btn btn-primary mr-1" onClick={() => this.changePage(pages[0] - 5)}>
                  {pages[0] - 5}
                </BlurryButton>
                &laquo;
              </span>
            ) : (
              ''
            )}
            {pages.map((p, i) => (
              <span key={i} className={`mr-1`}>
                {page === p ? (
                  <BlurryButton className="btn btn-primary" disabled>
                    {totalRecords === 0 ? 1 : p}
                  </BlurryButton>
                ) : (
                  <BlurryButton className="btn btn-primary" onClick={() => this.changePage(p)}>
                    {p}
                  </BlurryButton>
                )}
              </span>
            ))}
            {totalPages - 5 >= page && totalPages - 5 >= pages[pages.length - 1] + 5 ? (
              <span className="mr-1">
                &raquo;
                <BlurryButton
                  className="btn btn-primary ml-1"
                  onClick={() => this.changePage(pages[pages.length - 1] + 5)}
                >
                  {pages[pages.length - 1] + 5}
                </BlurryButton>
              </span>
            ) : (
              ''
            )}
            {pages[pages.length - 1] !== totalPages ? (
              <span>
                &raquo;
                <BlurryButton className="btn btn-primary ml-1" onClick={() => this.changePage(totalPages)}>
                  {totalPages}
                </BlurryButton>
              </span>
            ) : (
              ''
            )}
          </>
        ) : (
          <BlurryButton className="btn btn-primary" disabled>
            {1}
          </BlurryButton>
        )}
      </>
    );
  }
}

export default Pagination;
