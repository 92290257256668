import React from 'react';
import { generateColor, generateSymbol } from '../../../constants/postern/riskIconGenerator';
import Busy from '../../busy';
import SeverityCircle from '../../postern/reusable/severityCircle';
import SectionContainer from '../../sectionContainer';
import ForeignInfluenceMap from '../foreignInfluenceMap';
import ForeignInfluenceTable from '../foreignInfluenceTable';
import SafeHyperlink from '../../safeHyperlink';

/** Functional component for displaying the manufacturing portion of Foreign Influence data */
const Manufacturing = (props) => {
  //Props
  const { manufacturing = [], manufacturingScores, isLoading, isActive } = props;

  //Render
  return (
    <Busy isBusy={isLoading}>
      <SectionContainer label="Manufacturing Locations" className="row fi-section-container">
        <ForeignInfluenceTable tableName="ManufacturingTable-" headers={['Violation', 'Country', 'Cities', 'Evidence']}>
          {manufacturing.length ? (
            manufacturing.map((row, i) => {
              return (
                <tr className="provenance-table-row" key={'ManufacturingTable-' + i}>
                  <td className="row-padding" scope="row">
                    {' '}
                    <SeverityCircle symbol={generateSymbol(row.highRisk)} color={generateColor(row.highRisk)} />{' '}
                  </td>
                  <td className="country-display">{row.country}</td>
                  <td>{row.city}</td>
                  <td>
                    <SafeHyperlink url={row.evidence}>{row.evidence}</SafeHyperlink>{' '}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="provenance-table-row-empty" key={'ManufacturingTable-empty'}>
              <td colSpan={5}>
                <p>No Findings Identified</p>
              </td>
            </tr>
          )}
        </ForeignInfluenceTable>
      </SectionContainer>
      <div className="fi-summary-text">
        {manufacturingScores === 'high' ? (
          <p>
            During our review of the manufacturing facilities, some were found to have locations in countries of
            adversarial presence. Further investigation concludes that the foreign influence from manufacturing is
            likely significant
          </p>
        ) : (
          <p>
            During our review of the manufacturing facilities, none were found to have locations in countries of
            adversarial presence. Further investigation concludes that the foreign influence from manufacturing is not
            likely significant, and thus no finding is raised.
          </p>
        )}
      </div>
      <SectionContainer label="Manufacturing Locations" className="fi-section-container fi-map">
        <ForeignInfluenceMap manufacturing={manufacturing} displayIfEmpty={!isLoading && isActive} />
      </SectionContainer>
    </Busy>
  );
};

export default Manufacturing;
