import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Busy, useUpdateState, withPrincipal } from '../../components';
import config from '../../config';
import { assessmentTypes, sbomTypeIDToIconCSSClassTitle } from '../../constants/assessmentTypes';
import { IMAGE_NOT_AVAILABLE, toastError, uuidIsValid, urlFromPath } from '../../lib/utils';
import { pathLabels, getScoreWeight } from './hexagonView';
import OverallScoresByCategory from './overallScoresByCategory';
import ProductModalStagesContainer from '../../components/product/productModalStagesContainer';
import Select, { components } from 'react-select';
import { withEvents, events } from '../../components/withEvents';
import ContactFormButton from '../../components/contactFormButton';
import ReactTooltip from 'react-tooltip';

import './catalogProduct.css';

const SBOMOption = (props) => {
  const { label, cssClass, titleText, customLabel, action } = props;

  return (
    <div className="sbom-option">
      {customLabel ? customLabel(action) : ''}
      <span className={cssClass} title={titleText}></span>
      {label}
    </div>
  );
};

export const ProductVersionSelect = (props) => {
  const {
    versions = [],
    handleVersionChange = () => {},
    defaultValueKey,
    selectCSSClass,
    placeholderText,
    isClearable,
    alwaysShowDropdown,
    customLabel,
  } = props;

  let hashKeyToTypeClassName = {},
    hashKeyToAction = {};

  const options = versions.map((ver) => {
    const { typeID, version, hash, date, isPurchased } = ver;
    const hashKey = hash.substring(0, 8);
    const jsDate = new Date(date);

    const productVersionDisplayName =
      jsDate.getFullYear() +
      '-' +
      ((jsDate.getMonth() + 1).toString().length > 1 ? jsDate.getMonth() + 1 : '0' + (jsDate.getMonth() + 1)) +
      '-' +
      (jsDate.getDate().toString().length > 1 ? jsDate.getDate() : '0' + jsDate.getDate()) +
      '-' +
      hashKey +
      '-v' +
      version;

    hashKeyToTypeClassName[hashKey] = sbomTypeIDToIconCSSClassTitle(typeID);
    hashKeyToAction[hashKey] = isPurchased ? 'download' : 'redirect';

    return {
      label: productVersionDisplayName,
      value: hashKey,
    };
  });

  const DisplayOptionComponent = (props) => {
    const { label, value, ...innerProps } = props;
    const { cssClass, titleText } = hashKeyToTypeClassName[value];

    return (
      <components.Option {...innerProps}>
        <SBOMOption
          label={label}
          value={value}
          cssClass={cssClass}
          titleText={titleText}
          customLabel={customLabel}
          action={hashKeyToAction[value]}
        />
      </components.Option>
    );
  };

  const DisplaySingleValueComponent = (props) => {
    const { data, ...innerProps } = props;
    const { label, value } = data;
    const { cssClass, titleText } = hashKeyToTypeClassName[value];

    return (
      <components.SingleValue {...innerProps}>
        <SBOMOption
          label={label}
          value={value}
          cssClass={cssClass}
          titleText={titleText}
          customLabel={customLabel}
          action={hashKeyToAction[value]}
        />
      </components.SingleValue>
    );
  };

  const onChangeHandler = (optionSelected) => {
    handleVersionChange(optionSelected ? optionSelected.value : '');
  };

  return versions.length > 1 || alwaysShowDropdown ? (
    <Select
      className={selectCSSClass}
      options={options}
      components={{ SingleValue: DisplaySingleValueComponent, Option: DisplayOptionComponent }}
      onChange={onChangeHandler}
      defaultValue={
        //if it's not clearable, it should have a default val if not specified}
        defaultValueKey
          ? options.find((v) => v.value === defaultValueKey)
          : !isClearable && options && options.length > 0
          ? options[0]
          : undefined
      }
      placeholder={placeholderText}
      isClearable={isClearable}
    />
  ) : (
    <i className="mt-2">{versions.length === 1 ? options[0].label : 'Not Applicable'}</i>
  );
};

const CatalogProductOverview = (props) => {
  const { id: productID, hash } = useParams();

  //Props
  const { principal, history } = props;

  //State
  const [state, setState] = useUpdateState({
    isLoadingProductInfo: true,
    isLoadingSBOMData: true,
    isLoadingVersions: true,
    productInfo: undefined,
    sbomData: undefined,
    productVersions: [],
    purchaseStatus: {},
    isButtonStatusLoading: false,
  });

  const {
    isLoadingProductInfo,
    isLoadingSBOMData,
    isLoadingVersions,
    productInfo,
    sbomData,
    productVersions,
    purchaseStatus,
    isButtonStatusLoading,
  } = state;

  const { scores = {} } = sbomData && sbomData.output ? sbomData.output : {};
  const { vendorID, ddrrID: vendorDDRRid, vendor: vendorName, name: productName } = productInfo || {};

  const canPurchaseReports = principal.roles.some((r) => r.permissions.some((p) => p === 'subscriber.purchaseReports'));
  const hasSBOMfeature = principal.features.indexOf('SBOM') > -1;

  const preferredProductVersionHashes = JSON.parse(localStorage.getItem('preferredProductVersionHashes') || '[]');
  const preferredHash = (preferredProductVersionHashes.find((p) => p.productID === productID) || {}).preferredHash;

  const hashKey =
    hash || preferredHash || (productVersions && productVersions.length && productVersions[0].hash.substring(0, 8));

  const numOfVers = productVersions.length;
  const selectedProduct = productVersions ? productVersions.find((v) => v.hash.substring(0, 8) === hashKey) : undefined;

  const selectedVersion =
    numOfVers > 0
      ? numOfVers === 1 || !selectedProduct
        ? productVersions[0].version
        : (selectedProduct || {}).version
      : undefined;

  const sbomTypeID =
    numOfVers > 0
      ? productVersions.length === 1 || !selectedProduct
        ? productVersions[0].typeID
        : (selectedProduct || {}).typeID
      : undefined;

  const sbomHash =
    numOfVers > 0
      ? productVersions.length === 1 || !selectedProduct
        ? productVersions[0].hash
        : (selectedProduct || {}).hash
      : undefined;

  //Effects
  useEffect(() => {
    if (productID) {
      getProductInfo();
      getProductVersions();
    }
  }, [productID]);

  useEffect(() => {
    if (numOfVers && !selectedProduct) {
      handleVersionChange('');
    }
  }, [productVersions, hashKey]);

  useEffect(() => {
    if (productVersions && (!hashKey || (hashKey && selectedProduct))) {
      getSBOMdata();
    }
  }, [productID, hashKey, productVersions]);

  useEffect(() => {
    productInfo && getContributors();
  }, [productInfo]);

  const getContributors = () => {
    fetch(config.api.urlFor('productContributors', { id: productID }))
      .then((res) => res.json())
      .then((res) => {
        const { isSuccess = false, rows = [] } = res;
        if (isSuccess && rows && rows.length > 0) {
          const newProductInfo = productInfo;
          newProductInfo.contributors = rows.map((contributor) => contributor.name);
          setState({ productInfo: newProductInfo });
        }
      });
  };

  const getProductInfo = () => {
    if (productID && uuidIsValid(productID)) {
      setState({ isLoadingProductInfo: true });

      fetch(config.api.urlFor('catalogProducts', { productID }))
        .then((resp) => resp.json())
        .then((response) => {
          const { isSuccess = false, data = {} } = response;
          if (isSuccess && data.totalRecords > 0 && data.rows && data.rows.length > 0) {
            setState({ productInfo: data.rows.find((x) => x.productID === productID) });
          } else {
            toastError('An error occurred attempting to load product information.');
          }
        })
        .catch(() => {
          toastError('An error occurred attempting to load product information.');
        })
        .finally(() => {
          setState({ isLoadingProductInfo: false });
        });
    }
  };

  const getProductVersions = () => {
    if (productID && uuidIsValid(productID)) {
      setState({ isLoadingVersions: true });

      fetch(config.api.urlFor('productVersions', { productID }))
        .then((resp) => resp.json())
        .then((response) => {
          const { isSuccess = false, data = {} } = response;

          setState({
            productVersions: isSuccess && data.totalRecords > 0 && data.rows && data.rows.length > 0 ? data.rows : [],
          });
        })
        .catch(() => {
          setState({ productVersions: [] });
          toastError('An error occurred attempting to load product versions.');
        })
        .finally(() => {
          setState({ isLoadingVersions: false });
        });
    }
  };

  const getPurchaseStatusByType = (sbomDataArray) => {
    const purchaseStatus = sbomDataArray.reduce(
      (result, item) => ({
        ...result,
        [item.typeID]: [...(result[item.typeID] || []), item.isPurchased],
      }),
      {},
    );

    Object.keys(purchaseStatus).map((typeID) => {
      purchaseStatus[typeID] = purchaseStatus[typeID].filter(Boolean).length ? true : false;
      return purchaseStatus;
    });

    return purchaseStatus;
  };

  const getSBOMdata = (reloadStatusOnly = false) => {
    if (productID && uuidIsValid(productID)) {
      setState({ isLoadingSBOMData: !reloadStatusOnly, isButtonStatusLoading: true, purchaseStatus: {} });

      fetch(config.api.urlFor('productScores', { productID, hash: sbomHash }))
        .then((resp) => resp.json())
        .then((response) => {
          const { isSuccess = false, data = {} } = response;

          if (isSuccess) {
            if (data.totalRecords > 0 && data.rows && data.rows.length) {
              setState({
                sbomData: data.rows.find((x) => x.hash === sbomHash),
                purchaseStatus: getPurchaseStatusByType(data.rows),
              });
            } else {
              setState({ sbomData: {} });
            }
          } else {
            toastError('An error occurred attempting to load scores data.');
          }
        })
        .catch(() => {
          toastError('An error occurred attempting to load scores data.');
        })
        .finally(() => {
          setState({ isLoadingSBOMData: false, isButtonStatusLoading: false });
        });
    }
  };

  const handleVersionChange = (selectedNewHash) => {
    localStorage.setItem(
      'preferredProductVersionHashes',
      JSON.stringify(preferredProductVersionHashes.concat([{ productID, preferredHash: selectedNewHash }])),
    );
    props.history.push(urlFromPath(props.match.path, { id: productID, hash: selectedNewHash }));
  };

  //Render CatalogProductOverview
  return (
    <Busy isBusy={isLoadingProductInfo || isLoadingSBOMData || isLoadingVersions}>
      <div className="page-container">
        <div className="product-header">
          <ProductProfileHeader productName={productName} history={history} />
          <div className="version-wrapper">
            <p className="version-label">Version</p>
            <ProductVersionSelect
              versions={productVersions}
              defaultValueKey={hashKey}
              handleVersionChange={handleVersionChange}
              selectCSSClass="w-75"
              isClearable={true}
              placeholderText="Product Version"
            />
          </div>
        </div>
        <ProductInfo productInfo={productInfo} />
        <div className="overview-data-display">
          <div className="overall-content">
            <ProductOverallScoreCard scoresData={scores} />
            <ProductOverallScoresByCatCard scoresData={scores} />
          </div>
          <SBOMDocumentDataCard sbomData={sbomData ? sbomData.data : undefined} />
          <ProductAssessmentAndReports
            selectedVersion={selectedVersion}
            sbomTypeID={sbomTypeID}
            productID={productID}
            productName={productName}
            vendorID={vendorID}
            vendorName={vendorName}
            vendorDDRRid={vendorDDRRid}
            canPurchase={!!(sbomData && sbomData.data)}
            canPurchaseReports={canPurchaseReports}
            hasSBOMsubscription={hasSBOMfeature}
            purchaseStatus={purchaseStatus}
            getSBOMdata={() => getSBOMdata(true)}
            isStatusLoading={isButtonStatusLoading}
            selectedFullHash={sbomHash}
          />
        </div>
      </div>
    </Busy>
  );
};

const ProductProfileHeader = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { productName, history } = props; //We'll need history to push the version dropdown updates

  return (
    <div className="overview-head">
      <div className="overview-wrapper">
        <h3 className="overview-label">
          {productName ? <span title={productName}>{productName}</span> : ''} Overview{' '}
        </h3>
      </div>
    </div>
  );
};

const ProductInfo = (props) => {
  const { productInfo = {} } = props;
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  return (
    <Busy isBusy={props.productInfo === undefined}>
      <div className="product-info-container">
        <div className="container-row">
          <img
            className="logo product-info-logo"
            alt="Logo"
            src={productInfo.logoURL || IMAGE_NOT_AVAILABLE}
            onError={(currentTarget) => {
              currentTarget.onerror = null;
              currentTarget.src = IMAGE_NOT_AVAILABLE;
            }}
          />
          <div>
            <div className="container-row id-row">
              {!!productInfo.vendor && (
                <div>
                  <b>Vendor Name:&nbsp;</b>
                  {productInfo.vendorID ? (
                    <a
                      href={
                        '/#' +
                        config.routes.catalogVendor.to
                          .replace(':id', productInfo.vendorID)
                          .replace('/:selectedTab?/:subsection?', '/TrustCenter')
                      }
                    >
                      {productInfo.vendor}
                    </a>
                  ) : (
                    <>{productInfo.vendor}</>
                  )}
                </div>
              )}
              {!!productInfo.contributors && (
                <div>
                  <b>Contributors:&nbsp;</b>
                  {productInfo.contributors.slice(0, 3).join(', ')}
                  {productInfo.contributors.length > 3 && (
                    <>
                      <span data-tip data-for="contributorsTooltip" className="expand-contributors">
                        <b>+{productInfo.contributors.length - 3}</b>
                      </span>
                      <ReactTooltip id="contributorsTooltip" className="on-hover-tooltip" place="right" effect="solid">
                        <p className="on-hover-text">{productInfo.contributors.slice(3).join(', ')}</p>
                      </ReactTooltip>
                    </>
                  )}
                </div>
              )}
              <div>
                <b>Website:&nbsp;</b>
                {productInfo.domain || '-'}
              </div>
            </div>
            <div>
              <b>Product Overview:&nbsp;</b>
              {productInfo.description && productInfo.description.length >= 100 ? (
                <>
                  {isDescriptionExpanded
                    ? productInfo.description
                    : productInfo.description.substring(0, 99).concat('...')}
                  <button
                    className="btn btn-view-less"
                    onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                  >
                    {isDescriptionExpanded ? <span>View Less</span> : <span>View More</span>}
                  </button>
                </>
              ) : (
                <>{productInfo.description || '-'}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </Busy>
  );
};

const ProductOverallScoreCard = (props) => {
  const { scoresData = {} } = props;
  const { label: overallGrade, value: overallScore, category: overallCategory } = scoresData.overall || {};

  return (
    <div className="display-card">
      <div className="display-card-header">Overall Score</div>
      <div className="display-card-content">
        <div className={'overall-summary score-cat-' + (overallCategory || '').toLowerCase()}>
          <div className="overall-severity-score">{overallCategory || 'N/A'}</div>
          <div className="overall-grade-score">{overallGrade || 'N/A'}</div>
          <div className="overall-num-score">{overallScore || 'N/A'}</div>
        </div>
        <div className="overall-score-breakdown">
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Score Weight</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(pathLabels).map((propName, i) => {
                if (propName !== 'overall') {
                  const { category } = scoresData[propName] || {};

                  return (
                    <tr key={i}>
                      <td>{pathLabels[propName]}</td>
                      <td>{getScoreWeight(propName)}</td>
                      <td>{category || 'N/A'}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ProductOverallScoresByCatCard = (props) => {
  const { scoresData = [] } = props;

  return (
    <div className="display-card overall-scores-by-cat">
      <div className="display-card-header">Overall Score by Category</div>
      <div className="display-card-content">
        <OverallScoresByCategory scores={scoresData} />
      </div>
    </div>
  );
};

const SBOMDocumentDataCard = (props) => {
  const { sbomData } = props;

  let dataToShow = '';

  //If the report has not been purchased, then a shorter version will be returned from the backend as a string instead of a json
  if (sbomData) {
    dataToShow = typeof sbomData === 'string' ? sbomData : JSON.stringify(sbomData, null, '\t');
  } else {
    dataToShow = 'SBOM Document Data Unavailable';
  }

  return (
    <div className="display-card">
      <div className="display-card-header">SBOM Document Data</div>
      <div className={'display-json-content' + (sbomData ? '' : ' no-json')}>{dataToShow}</div>
    </div>
  );
};

const ProductAssessmentAndReports = withEvents((props) => {
  const sbomStatusesUI = {
    NOTFOUND: 'not found',
    APPROVED: 'approved',
    DENIED: 'denied',
    PENDING: 'pending',
  };

  //Props
  const {
    productID,
    sbomTypeID,
    vendorID,
    vendorDDRRid,
    vendorName,
    productName,
    canPurchase,
    selectedVersion,
    canPurchaseReports,
    hasSBOMsubscription,
    purchaseStatus,
    getSBOMdata = () => {},
    isStatusLoading,
    selectedFullHash,
  } = props;

  //State
  const [state, setState] = useUpdateState({
    isLoading: true,
    reports: [],
    downloading: [],
    selectedReports: {},
    requestedProductReports: {
      requestedProductArray: [],
    },
    allowGoingBackwards: false,
    showModal: false,
    modalStage: '',
    allowPurchaseMultipleProductReports: false,
    supplierProvidedSbomFlag: false,
    sbomAccessStatus: '',
    sbomHTML: undefined,
    showSBOMHTML: false,
    isLoadingSBOMHTML: false,
  });

  const {
    isLoading,
    reports,
    downloading,
    selectedReports,
    requestedProductReports,
    allowGoingBackwards,
    showModal,
    modalStage,
    allowPurchaseMultipleProductReports,
    supplierProvidedSbomFlag,
    sbomAccessStatus,
    sbomHTML,
    showSBOMHTML,
    isLoadingSBOMHTML,
  } = state;

  //Effects
  useEffect(() => {
    if (productID) {
      getSBOMReportData();
    }
  }, [productID, sbomTypeID]);

  useEffect(() => {
    setState({ sbomHTML: undefined });
  }, [productID, selectedVersion, selectedFullHash]);

  useEffect(() => {
    if (sbomHTML && showSBOMHTML) {
      const newWindow = window.open('about:blank', '_blank'); // If using 'noopener,noreferrer' here, the document is not created.
      newWindow?.document?.write(sbomHTML);
      newWindow?.document?.close(); // To finish loading the page. Without it, the in-page navigation does not work.

      setState({ showSBOMHTML: false });
    }
  }, [sbomHTML, showSBOMHTML]);

  //Functions
  const getSBOMReportData = () => {
    if (sbomTypeID) {
      fetch(config.api.urlFor('catalogReportTypes') + '?id=' + sbomTypeID + '&shouldHaveSBOMfeature=false')
        .then((res) => res.json())
        .then((data) => {
          let reports = [];
          if (data && data.rows) {
            const sbomReport = data.rows.find((x) => x.id === sbomTypeID);
            if (sbomReport) {
              reports.push(sbomReport);
            }
          }
          setState({
            isLoading: false,
            reports: reports,
          });
        })
        .catch(() => {
          toastError('An error occurred attempting to load available reports.');
        })
        .finally(() => {
          getSbomAccessStatus();
        });
    } else {
      setState({
        isLoading: false,
      });
    }
  };

  const getSBOMHTMLData = () => {
    if (productID) {
      setState({ showSBOMHTML: true });

      if (!sbomHTML && !isLoadingSBOMHTML) {
        setState({ isLoadingSBOMHTML: true });
        fetch(
          config.api.urlFor('posternProxySBOMHTML', {
            id: productID,
            version: selectedVersion || '',
            hash: selectedFullHash,
          }),
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }

            return res.text();
          })
          .then((html) => {
            setState({ sbomHTML: html });
          })
          .catch(() => {
            setState({ showSBOMHTML: false });
            toastError('An error occurred attempting to load SBOM preview.');
          })
          .finally(() => setState({ isLoadingSBOMHTML: false }));
      }
    }
  };

  const getSbomAccessStatus = () => {
    if (selectedFullHash) {
      fetch(config.api.urlFor('getSbomAccessRecord', { hash: selectedFullHash, productID, sbomTypeID }))
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            toastError(data.error);
          }
          setState({ sbomAccessStatus: data && data.sbomAccessStatus ? data.sbomAccessStatus : 'error' });
        });
    }
  };

  const handleSupplierProvidedSbomRequest = (report) => {
    setState({ supplierProvidedSbomFlag: true });
    handleAddToCart(report);
  };

  const setDownloadBusyState = (id) => {
    const idx = downloading.indexOf(id);

    if (idx > -1) {
      let downloadList = downloading;
      downloadList.splice(idx, 1);

      setState({ downloading: downloadList });
    }
  };

  const handleDownload = (reportID, reportName) => {
    if (productID && !downloading.includes(reportID)) {
      let downloadList = downloading;

      downloadList.push(reportID);
      setState({ downloading: downloadList });

      const downloadURL = config.api.urlFor('posternProxySBOM', {
        id: productID,
        version: selectedVersion || '',
        hash: selectedFullHash,
      });
      const todayStr = new Date().toISOString().substring(0, 10);
      const fileName = `${reportName}${selectedVersion ? `_v${selectedVersion}` : ''}-${todayStr}.zip`.replace(
        / /g,
        '_',
      );

      props.events.emit(events.DOWNLOAD_FILE, {
        url: downloadURL,
        filename: fileName,
        callerID: reportID,
        downloadCompletedCallback: setDownloadBusyState,
      });
    }
  };

  const handleAddToCart = (report) => {
    if (report && canPurchaseReports) {
      setState({
        requestedProductReports: {
          [report.name]: 1,
          requestedProductArray: [report.name],
        },
        allowGoingBackwards: false,
        showModal: true,
        modalStage: 'confirmation',
        allowPurchaseMultipleProductReports: false,
      });
    }
  };

  const handleOrderOtherReports = () => {
    setState({ showModal: true, modalStage: 'reports', allowPurchaseMultipleProductReports: true });
  };

  const handleModalClose = () => {
    setState({
      showModal: false,
      modalStage: '',
    });
  };

  const renderActionButton = (id, name, report) => {
    if (id == assessmentTypes.SBOM[1].id) {
      const grayBtnText = () => {
        switch (sbomAccessStatus) {
          case sbomStatusesUI.DENIED:
            return 'Denied';
          case sbomStatusesUI.PENDING:
            return 'Request Pending';
          default:
            return 'Error';
        }
      };

      return (
        <Busy isBusy={!sbomAccessStatus}>
          {sbomAccessStatus == sbomStatusesUI.NOTFOUND ? (
            <>
              <button
                className="blue-report-btn"
                data-tip
                data-for="supplierRequestBtn"
                onClick={() => handleSupplierProvidedSbomRequest(report)}
              >
                Request
              </button>
              <ReactTooltip id="supplierRequestBtn" effect="solid">
                Supplier Provided SBOMs&apos; availability is subject to supplier&apos;s approval.
              </ReactTooltip>
            </>
          ) : (
            <>
              {sbomAccessStatus == sbomStatusesUI.APPROVED ? ( // sbom access has been approved
                <>
                  <button className="blue-report-btn mr-3" onClick={() => getSBOMHTMLData()}>
                    <Busy isBusy={isLoadingSBOMHTML}>View SBOM</Busy>
                  </button>
                  <button className="orange-report-btn" onClick={() => handleDownload(id, name)}>
                    <Busy isBusy={downloading.includes(id)}>Download</Busy>
                  </button>
                </>
              ) : (
                <div data-tip data-for="supplierRequestPendingOrDenied">
                  <button disabled className="gray-report-btn">
                    {grayBtnText()}
                  </button>
                  <ReactTooltip id="supplierRequestPendingOrDenied" effect="solid">
                    Please reach out to your Account Lead to know more about this request status.
                  </ReactTooltip>
                </div>
              )}
            </>
          )}
        </Busy>
      );
    } else {
      const isPurchased = purchaseStatus[id] || false;

      return (
        <Busy isBusy={isPurchased === undefined || isStatusLoading}>
          {hasSBOMsubscription ? (
            isPurchased ? (
              <>
                <button className="blue-report-btn mr-3" onClick={() => getSBOMHTMLData()}>
                  <Busy isBusy={isLoadingSBOMHTML}>View SBOM</Busy>
                </button>
                <button className="orange-report-btn" onClick={() => handleDownload(id, name)}>
                  <Busy isBusy={downloading.includes(id)}>Download</Busy>
                </button>
              </>
            ) : canPurchase ? (
              <button
                className="blue-report-btn"
                onClick={() => handleAddToCart(report)}
                disabled={!canPurchaseReports}
              >
                Add to Cart
              </button>
            ) : (
              <>Unavailable</>
            )
          ) : (
            <div data-tip data-for="sbomSubscription">
              <ContactFormButton caption="Contact Sales" storageKey="requestSBOMsubscription" storageValue="true" />
              <ReactTooltip id="sbomSubscription" className="on-hover-tooltip" place="right" effect="solid">
                <p className="on-hover-text">Request information on SBOM subscriptions.</p>
              </ReactTooltip>
            </div>
          )}
        </Busy>
      );
    }
  };

  //Render ProductAssessmentAndReports
  return (
    <>
      {canPurchaseReports && (
        <ProductModalStagesContainer
          showModal={showModal}
          modalStage={modalStage}
          selectedReports={selectedReports}
          requestedProductReports={requestedProductReports}
          purchaseStatus={purchaseStatus}
          productID={productID}
          productName={productName}
          vendorID={vendorID}
          vendorDDRRid={uuidIsValid(vendorDDRRid) ? vendorDDRRid : undefined}
          vendorName={vendorName}
          allowGoingBackwards={allowGoingBackwards}
          handleModalClose={handleModalClose}
          allowMultipleProducts={allowPurchaseMultipleProductReports}
          productVersionOrNumber={selectedVersion}
          updateSBOMpurchaseStatus={getSBOMdata}
          supplierProvidedSbomFlag={supplierProvidedSbomFlag}
          selectedFullHash={selectedFullHash}
          getSbomAccessStatus={getSbomAccessStatus}
        />
      )}

      <div className="product-assessment-reports">
        <h4>Assessments &amp; Reports</h4>
        <Busy isBusy={isLoading}>
          <p>
            Disclaimer: The turnaround time of assessments and reports is dependent on the availability of the
            assessments and reports.
          </p>
          <div className="product-assessment-reports-list">
            <table className="product-assessment-reports-table">
              <thead>
                <tr>
                  <th>Product Reports</th>
                  <th>Version</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {reports.length > 0 ? (
                  reports.map((report, i) => {
                    const { id, name } = report;
                    return (
                      <tr key={i}>
                        <td>{name}</td>
                        <td>{selectedVersion}</td>
                        <td>{renderActionButton(id, name, report)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-reports" colSpan={4}>
                      No Assessments or Reports Available
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="order-other-reports-btn" colSpan={4}>
                    <button
                      disabled={!canPurchaseReports}
                      onClick={() => handleOrderOtherReports()}
                      className="btn statusBtnBase statusGreen"
                      pointerEvents="auto"
                    >
                      Order Other Reports
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Busy>
      </div>
    </>
  );
});

export default withPrincipal(CatalogProductOverview);
