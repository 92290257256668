// The role names must be the same as defined in DB table [role]
export const roleNames = {
  ADMIN: 'admin',
  OPERATIONAL_ADMIN: 'operational.admin',
  SUBSCRIBER_ADMIN: 'subscriber.admin',
  SUBSCRIBER_USER: 'subscriber.user',
  SUBSCRIBER_VIEWER: 'subscriber.viewer',
  GUEST_USER: 'guest.user',
  SUPPLIER: 'supplier',
};

// The guest subscription must be the same as defined in DB table [subscriber]
export const guestSubscription = 'Guest';
